import { FC, ReactNode } from "react"
import { useTheme } from "@emotion/react"

import { Theme } from "@fitnesspilot/components-common/dist/atoms/ThemeProvider/Theme"

export type MuscleLevelColourProps = {
  level: number | false
  className?: string
  children: ReactNode
}

export const muscleLevelColour = ({
  theme,
  level,
}: {
  theme: Theme
  level: number | false | undefined
}) =>
  (level === false
    ? theme.colours.grey[300]
    : level == null
    ? theme.colours.primary
    : level < 0.5
    ? theme.colours.red[550].mix(theme.colours.yellow[450], level / 0.5)
    : theme.colours.yellow[450].mix(
        theme.colours.green[600],
        (level - 0.5) / 0.5,
      )
  ).toString()

export const MuscleLevelColour: FC<MuscleLevelColourProps> = ({
  level,
  className,
  children,
}) => {
  const theme = useTheme()
  const colour = muscleLevelColour({ theme, level })

  return (
    <div {...{ className }} style={{ color: colour }}>
      {children}
    </div>
  )
}

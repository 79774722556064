import * as E from "fp-ts/es6/Either"
import { flow, identity } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Str from "fp-ts/es6/string"
import { Prism } from "monocle-ts"

export const stringAsUrl = new Prism<string, URL>(
  flow((v) => E.tryCatch(() => new URL(v), identity), Opt.fromEither),
  String,
)
export const url = Ord.fromCompare<URL>((a, b) =>
  Str.Ord.compare(a.href, b.href),
)

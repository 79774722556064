import { Lens } from "monocle-ts";
import { on } from "../internal";
export const _value = () => Lens.fromProp()("value");
export const _timeZone = () => Lens.fromProp()("timeZone");
const liftToZoned = () => on(_value().get);
export const mkZoned = (timeZone) => (value) => ({
    value,
    timeZone,
});
// @TODO Use time zone in comparison
export const zoned = (ord) => ({
    equals: liftToZoned()(ord.equals),
    compare: liftToZoned()(ord.compare),
});
export const equals = (ord) => {
    const zoned_ = zoned(ord);
    return (d2) => (d) => zoned_.equals(d, d2);
};
export const compare = (ord) => {
    const zoned_ = zoned(ord);
    return (d2) => (d) => zoned_.compare(d, d2);
};
export { currentTimeZone } from "../Local";

import { FC, FormEvent, useRef } from "react"
import { Button as BSButton, ButtonProps } from "reactstrap"

import { flow, pipe } from "fp-ts/es6/function"
import * as Func from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"

export type FileButtonProps = {
  onChange: (fs: Array<File>) => void
  accept: Array<string>
  Button?: FC<Omit<ButtonProps, "type">>
} & Omit<ButtonProps, "type" | "onClick">

// Uses internal state as we can't alter the browser's
// (on the file input) anyway and refs mustn't be leaked.
export const FileButton: FC<FileButtonProps> = ({
  onChange,
  accept,
  Button = (props) => <BSButton type="button" {...props} />,
  ...props
}) => {
  const input = useRef<HTMLInputElement | null>(null)
  const changeFile = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault()
    const inp = Opt.fromNullable(input.current)
    pipe(
      inp,
      Opt.chain(
        flow(
          (v) => v.files,
          Opt.fromNullable,
          Opt.map<FileList, Array<File>>(Array.from.bind(Array)),
        ),
      ),
      Opt.fold(() => [], Func.identity),
      onChange,
    )
    pipe(
      inp,
      Opt.map((inp) => {
        inp.value = null as any
      }),
    )
    return false
  }

  return (
    <>
      <input
        required
        type="file"
        hidden
        onChange={changeFile}
        accept={accept.join(", ")}
        ref={input}
      />
      <Button
        onClick={() =>
          pipe(
            input.current,
            Opt.fromNullable,
            Opt.map((inp: HTMLInputElement) => inp.click()),
          )
        }
        {...props}
      />
    </>
  )
}

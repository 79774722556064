import * as Eq from "fp-ts/es6/Eq"
import { flow } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import { Lens, Prism } from "monocle-ts"
import { explicitOrder } from "@fitnesspilot/data-common"

import { _value } from "@fitnesspilot/data-activity/dist/activity/WithId"

import * as Event from "./Event"
import * as Recommendation from "./Recommendation"

export enum EventOrRecommendationType {
  event = "event",
  recommendation = "recommendation",
}
export const eventOrRecommendationType: Ord.Ord<EventOrRecommendationType> =
  explicitOrder([
    EventOrRecommendationType.event,
    EventOrRecommendationType.recommendation,
  ])

export type EventOrRecommendation =
  | { type: EventOrRecommendationType.event; value: Event.EventWithId }
  | {
      type: EventOrRecommendationType.recommendation
      value: Recommendation.Recommendation
    }
export const eventOrRecommendation: Eq.Eq<EventOrRecommendation> =
  Eq.fromEquals((a, b) =>
    a.type === EventOrRecommendationType.event &&
    b.type === EventOrRecommendationType.event
      ? Event.eventWithId.equals(a.value, b.value)
      : a.type === EventOrRecommendationType.recommendation &&
        b.type === EventOrRecommendationType.recommendation
      ? Recommendation.recommendation.equals(a.value, b.value)
      : false,
  )
export const isEventWithId = (
  v: EventOrRecommendation,
): v is EventOrRecommendation & { type: EventOrRecommendationType.event } =>
  v.type === EventOrRecommendationType.event
export const _EventWithId = new Prism<EventOrRecommendation, Event.EventWithId>(
  (v) => (isEventWithId(v) ? Opt.some(v.value) : Opt.none),
  (v) => ({ type: EventOrRecommendationType.event, value: v }),
)
export const isRecommendation = (
  v: EventOrRecommendation,
): v is EventOrRecommendation & {
  type: EventOrRecommendationType.recommendation
} => v.type === EventOrRecommendationType.recommendation
export const _Recommendation = new Prism<
  EventOrRecommendation,
  Recommendation.Recommendation
>(
  (v) => (isRecommendation(v) ? Opt.some(v.value) : Opt.none),
  (v) => ({ type: EventOrRecommendationType.recommendation, value: v }),
)
const mkLens = <a>(
  event: Lens<Event.Event, a>,
  recommendation: Lens<Recommendation.Recommendation, a>,
) => {
  const eventWithId = _value<Event.EventId, Event.Event>().compose(event)

  return new Lens<EventOrRecommendation, a>(
    (s) =>
      s.type === EventOrRecommendationType.event
        ? eventWithId.get(s.value)
        : recommendation.get(s.value),
    (a) =>
      flow(
        _EventWithId.modify(eventWithId.set(a)),
        _Recommendation.modify(recommendation.set(a)),
      ),
  )
}
export const _between = mkLens(Event._between, Recommendation._between)
export const _start = mkLens(Event._start, Recommendation._start)
export const _end = mkLens(Event._end, Recommendation._end)
// export const _alignment = mkLens(Event._alignment, Recommendation._alignment)
export const _recurrence = mkLens(Event._recurrence, Recommendation._recurrence)
export const foldEventOrRecommendation =
  <a>(
    event: (v: Event.EventWithId) => a,
    recommendation: (v: Recommendation.Recommendation) => a,
  ) =>
  (v: EventOrRecommendation) =>
    v.type === EventOrRecommendationType.event
      ? event(v.value)
      : recommendation(v.value)

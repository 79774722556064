import * as Eq from "fp-ts/es6/Eq"
import * as Foldable from "fp-ts/es6/Foldable"
import { flow, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as St from "fp-ts/es6/ReadonlySet"
import * as Str from "fp-ts/es6/string"

import { muscleGroupId } from "@fitnesspilot/data-human-body/dist/muscleGroups"

import { activityTag } from "../activity/ActivityTag"
import { equipmentType } from "../activity/Equipment"
import { ActivityFilters, foldActivityFilterValue } from "./ActivityFilters"

export const setToString = <a>(ord: Ord.Ord<a>) =>
  flow(
    St.toReadonlyArray(ord),
    NonEmpty.fromReadonlyArray,
    Opt.fold(
      () => JSON.stringify([]),
      (as) => JSON.stringify(as),
    ),
  )
export const stringToSet =
  <a>(eq: Eq.Eq<a>) =>
  (v: string | undefined) =>
    pipe(
      v,
      Opt.fromNullable,
      Opt.chain(
        flow(JSON.parse, (as) =>
          as instanceof Array ? Opt.some(as) : Opt.none,
        ),
      ),
      Opt.fold(() => St.empty, St.fromReadonlyArray(eq)),
    )

export const boolToString = (v: boolean) => (v ? String(v) : "false")
export const stringToBool = (v: string | undefined) => v === "true"

export const filtersToSearch = (filters: Partial<ActivityFilters>): string =>
  pipe(
    filters as Record<keyof ActivityFilters, any>,
    Rec.reduceWithIndex(Str.Ord)([] as ReadonlyArray<string>, (k, r, v) => [
      ...r,
      `${k}=${foldActivityFilterValue(
        setToString(activityTag),
        setToString(muscleGroupId),
        setToString(equipmentType),
        boolToString,
      )(k, v as ActivityFilters[typeof k])}`,
    ]),
    (vs) => Foldable.intercalate(Str.Monoid, Arr.Foldable)("&", vs),
  )

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UnitDistanceFromJSON, UnitDistanceToJSON, } from "./UnitDistance";
import { UnitHeightFromJSON, UnitHeightToJSON, } from "./UnitHeight";
import { UnitMassFromJSON, UnitMassToJSON, } from "./UnitMass";
/**
 * Check if a given object implements the UnitPreferences interface.
 */
export function instanceOfUnitPreferences(value) {
    let isInstance = true;
    isInstance = isInstance && "mass" in value;
    isInstance = isInstance && "height" in value;
    isInstance = isInstance && "distance" in value;
    return isInstance;
}
export function UnitPreferencesFromJSON(json) {
    return UnitPreferencesFromJSONTyped(json, false);
}
export function UnitPreferencesFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        mass: UnitMassFromJSON(json.mass),
        height: UnitHeightFromJSON(json.height),
        distance: UnitDistanceFromJSON(json.distance),
    };
}
export function UnitPreferencesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        mass: UnitMassToJSON(value.mass),
        height: UnitHeightToJSON(value.height),
        distance: UnitDistanceToJSON(value.distance),
    };
}

import { ReactNode, useMemo } from "react"
import Range_, { SliderProps } from "react-rangeslider"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Rec from "fp-ts/es6/Record"

export type RangeProps = Omit<SliderProps, "labels" | "orientation"> & {
  orientation?: "horizontal" | "vertical"
  size?: RangeSize
  valid?: boolean
  invalid?: boolean
  format?: (value: number) => string | number | undefined
  labels?: Record<number, ReactNode>
}

export type RangeSize = "small" | "medium" | "large"

const sizeMap: Record<RangeSize, string> = {
  small: "8px",
  medium: "10px",
  large: "12px",
}

export const Range = styled(({ labels: labels_, ...props }: RangeProps) => {
  // make sure labels is object instead of array
  const labels = useMemo(
    () =>
      pipe(
        labels_,
        Opt.fromNullable,
        Opt.map((vs) => Object.assign({}, vs)),
        Opt.toUndefined,
        (vs) => vs as Record<number, string> | undefined,
      ),
    [labels_],
  )

  return (
    <div data-non-draggable>
      <Range_ {...{ labels }} {...props} />
    </div>
  )
})`
  /**
   * Rangeslider
   */
  margin: 12.5px 0;
  ${({ labels }: RangeProps) =>
    labels && !Rec.isEmpty(labels) && "margin-bottom: 32.5px;"}

  position: relative;
  background: #e6e6e6;
  touch-action: none;

  font-size: ${({ size }: RangeProps) => sizeMap[size ?? "medium"]};

  &,
  & .rangeslider__fill {
    display: block;
  }
  .rangeslider__fill {
    background: linear-gradient(
      ${({ theme }) =>
        ((light: string, dark: string) =>
          `90deg, ${dark} 0, ${light} 99%, ${dark}`)(
          theme.colours.primary.lighten(0.1).toString(),
          theme.colours.primary.toString(),
        )}
    );
  }
  .rangeslider__handle {
    background-color: ${({ theme }) => theme.colours.primary.toString()};
    cursor: pointer;
    display: inline-block;
    position: absolute;
  }
  .rangeslider__handle .rangeslider__active {
    opacity: 1;
  }
  .rangeslider__handle-tooltip {
    width: 40x;
    height: 30px;
    padding: 5px 10px;
    text-align: center;
    position: absolute;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colours.primary.toString()};
    font-weight: normal;
    font-size: 14px;
    transition: all 100ms ease-in;
    display: inline-block;
    color: white;
    margin-bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
  .rangeslider__handle-tooltip span {
    display: inline-block;
    line-height: 100%;
  }
  .rangeslider__handle-tooltip:after {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
  }
  /**
   * Rangeslider - Horizontal slider
   */
  &.rangeslider-horizontal {
    height: 1em;
    border: 1px solid #ccc;
    border-radius: 1em;
  }
  &.rangeslider-horizontal .rangeslider__fill {
    height: 100%;
    top: 0;
    border-radius: 1em;
  }
  &.rangeslider-horizontal .rangeslider__handle {
    width: 2em;
    height: 2em;
    top: calc(1em / 2 - 2em / 2 - 1px);
    margin-left: calc(-2em / 2);
    border-radius: 50%;
  }
  &.rangeslider-horizontal .rangeslider__handle-tooltip {
    top: -35px;
    user-select: none;
  }
  &.rangeslider-horizontal .rangeslider__handle-tooltip:after {
    content: none;
  }
  /**
   * Rangeslider - Vertical slider
   */
  &.rangeslider-vertical {
    margin: 20px auto;
    height: 150px;
    max-width: 1em;
    border: 1px solid #ccc;
    border-radius: 1em;
  }
  &.rangeslider-vertical .rangeslider__fill,
  &.rangeslider-vertical .rangeslider__handle {
    position: absolute;
  }
  &.rangeslider-vertical .rangeslider__fill {
    width: 100%;
    bottom: 0;
  }
  &.rangeslider-vertical .rangeslider__handle {
    width: 2em;
    height: 2em;
    left: calc(1em / 2 - 2em / 2 - 1px);
    border-radius: 50%;
  }
  &.rangeslider-vertical .rangeslider__handle-tooltip {
    left: -100%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    user-select: none;
  }
  &.rangeslider-vertical .rangeslider__handle-tooltip:after {
    content: none;
  }
  /**
   * Rangeslider - Reverse
   */
  &.rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
    right: 0;
  }
  &.rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
    top: 0;
    bottom: inherit;
  }
  /**
   * Rangeslider - Labels
   */
  .rangeslider__labels {
    all: unset;
    display: flex;
    justify-content: space-between;
    user-select: none;
  }
  &.rangeslider-horizontal .rangeslider__labels {
    flex-direction: row-reverse;
    margin-top: 10px;
    padding: 0 10px;
  }
  &.rangeslider-vertical .rangeslider__labels {
    margin-left: 40px;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
  }
  .rangeslider__labels .rangeslider__label-item {
    position: relative;
    left: 0 !important;
    top: 0 !important;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
  }
  &.rangeslider-vertical .rangeslider__label-item {
    white-space: nowrap;
  }
  &.rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
    content: "";
    width: 10px;
    height: 2px;
    background: black;
    position: absolute;
    left: -14px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      filter: grayscale(100%);
      pointer-events: none;
    `}
`

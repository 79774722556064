import { useEffect } from "react"
import { FaUser } from "react-icons/fa"
import { FormattedMessage, useIntl } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import { useParams } from "react-router-dom"

import * as Foldable from "fp-ts/es6/Foldable"
import { constNull, constVoid, flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import * as Tree from "fp-ts/es6/Tree"

import { MuscleFormData } from "@fitnesspilot/components-activity/dist/molecules/MusclePreferenceFieldset/MusclePreferenceFieldset"
import {
  FormData,
  MusclePreferencesForm,
} from "@fitnesspilot/components-activity/dist/organisms/MusclePreferencesForm/MusclePreferencesForm"
import { Content } from "@fitnesspilot/components-common/dist/molecules/Content/Content"
import { Header } from "@fitnesspilot/components-common/dist/molecules/Header/Header"
import { muscleGroupName } from "@fitnesspilot/components-human-body/dist/atoms/MuscleGroupName/MuscleGroupName"
import {
  Action,
  fetchActivitiesWithSettings,
  ParentState,
  selectors,
  setMuscleSettings,
} from "@fitnesspilot/data-activity/dist/store"
import { MuscleId_ } from "@fitnesspilot/data-human-body/dist/Muscle"
import * as MuscleGroups from "@fitnesspilot/data-human-body/dist/muscleGroups"

import { MainTemplate } from "../../templates/Main/Main"

import mem from "memoize-one"
import { Dispatch } from "redux"

const mapState = (state: ParentState) => ({
  defaultValues: pipe(
    state,
    selectors.state.compose(selectors.muscleSettings).get,
    mem(
      flow(
        Arr.chain((t) => Foldable.toReadonlyArray(Tree.Foldable)(t)),
        Arr.filter((ms) =>
          Object.values(MuscleId_).includes(ms.id as MuscleId_),
        ),
        NonEmpty.fromReadonlyArray,
        Opt.map(
          NonEmpty.reduce({} as FormData, (as, { id, enabled, priority }) => ({
            ...as,
            [id as MuscleId_]: { enabled, priority } as MuscleFormData,
          })),
        ),
      ),
    ),
  ),
})

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ =
    (act: Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, constVoid)

  return {
    setMuscleSettings: flow(setMuscleSettings, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

export type MusclePreferencesPageProps = PropsFromRedux

export const MusclePreferencesPage = ({
  defaultValues,
  setMuscleSettings,
}: MusclePreferencesPageProps) => {
  const params = useParams<{ muscleGroup: MuscleGroups.MuscleGroups }>()
  const muscleGroupId = pipe(
    params.muscleGroup,
    Opt.fromNullable,
    Opt.map(MuscleGroups.muscleGroupIdAsMuscleGroups.reverseGet),
    Opt.toUndefined,
  )

  useEffect(() => {
    if (Opt.isNone(defaultValues)) {
      fetchActivitiesWithSettings()
    }
  }, [defaultValues])

  const intl = useIntl()

  if (muscleGroupId === undefined) {
    return null
  }

  return (
    <MainTemplate
      header={
        <Header
          icon={<FaUser />}
          title={
            <FormattedMessage
              defaultMessage="{name} Preferences"
              values={{
                name: muscleGroupName(intl)({ id: muscleGroupId }),
              }}
            />
          }
        />
      }
    >
      <Content>
        {pipe(
          defaultValues,
          Opt.fold(constNull, (defaultValues) => (
            <MusclePreferencesForm
              id="musclePreferences"
              muscleGroupId={muscleGroupId}
              {...{ defaultValues }}
              onSubmit={(values) => pipe(values, setMuscleSettings)()}
            />
          )),
        )}
      </Content>
    </MainTemplate>
  )
}

export const MusclePreferencesPageContainer = connector(MusclePreferencesPage)

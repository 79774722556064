import {
  FC,
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  LIFECYCLE,
  Props as JoyrideProps,
  STATUS,
  StoreHelpers,
} from "react-joyride"
import { connect, ConnectedProps } from "react-redux"
import { Rect, useRect, useWindowOn } from "react-use-rect"
import { PropsOf } from "@emotion/react"

import * as Bool from "fp-ts/es6/boolean"
import { flow, pipe } from "fp-ts/es6/function"
import * as Func from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { Prism } from "monocle-ts"

import {
  _StepStateStep,
  _StepStateStepOverview,
  Action,
  allKnownStepSets,
  close,
  gotoStep,
  gotoStepSet,
  ParentState,
  selectors,
  StepMode,
  StepSelection,
  StepSet,
  stepSetsToArray,
  StepState,
} from "@fitnesspilot/data-help"

import { stepSets } from "../../help"
import { HelpBeacon } from "../../molecules/HelpBeacon/HelpBeacon"
import { TooltipContainer } from "../Tooltip/Tooltip"

import { Dispatch } from "redux"

const mapState = (state: ParentState) => ({
  step: pipe(state, selectors.state.composeLens(selectors.step).get),
  status: pipe(state, selectors.state.composeLens(selectors.status).get),
})

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ =
    (act: Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, Func.constVoid)

  return {
    gotoStep: flow(gotoStep, dispatch_),
    gotoStepSet: flow(gotoStepSet, dispatch_),
    close: pipe(close(), dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

export type HelpContainerProps = Omit<
  JoyrideProps,
  | "tooltipComponent"
  | "steps"
  | "continuous"
  | "run"
  | "spotlightClick"
  | "styles"
>

export type HelpProps = PropsFromRedux & HelpContainerProps

const align =
  <a,>(
    as: Partial<Record<"top" | "right" | "bottom" | "left", a>>,
    fallback: a,
  ) =>
  (alignment: "top" | "right" | "bottom" | "left") =>
    as[alignment] ?? fallback
const PlaceAt = ({
  target: targetRaw,
  alignment = "bottom",
  ...props
}: {
  target: Element | string
  alignment?: "top" | "right" | "bottom" | "left"
} & HTMLAttributes<HTMLDivElement>) => {
  const target = useMemo(
    () =>
      typeof targetRaw === "string"
        ? document.querySelector<HTMLElement>(targetRaw) ?? undefined
        : (targetRaw as HTMLElement),
    [],
  )

  // keep position up-to-date
  const [rect, setRect] = useState<Rect | null>(null)
  const [rectRef, revalidateRect] = useRect(setRect, { resize: true })
  useWindowOn("scroll", () => revalidateRect())
  useWindowOn("resize", () => revalidateRect())
  useEffect(() => rectRef(target ?? null), [target])

  return rect ? (
    <div
      style={{
        position: "absolute",
        top: align(
          {
            top: rect.top,
            bottom: rect.bottom,
          },
          (rect.top + rect.bottom) / 2,
        )(alignment),
        left: align(
          {
            left: rect.left,
            right: rect.right,
          },
          (rect.left + rect.right) / 2,
        )(alignment),
        transform: "translateX(-50%) translateY(-50%)",
        zIndex: 100,
      }}
      {...props}
    />
  ) : null
}

export const Help: FC<HelpProps> = ({
  step,
  status,
  gotoStep,
  gotoStepSet,
  close,
  ...props
}) => {
  const steps = useMemo(
    () =>
      pipe(
        stepSets,
        stepSetsToArray,
        (arr) =>
          arr.flatMap((set, setI, sets) =>
            pipe(
              set.steps,
              Arr.mapWithIndex((i, s) => ({
                ...s,
                indexInSet: i,
                set,
                nextSet: pipe(sets, Arr.lookup(setI + 1)),
              })),
            ),
          ),
        Arr.toArray,
      ),
    [],
  )

  const ref = useRef<Joyride>(null)

  const isStepOverview = useMemo(
    () =>
      pipe(
        step,
        Opt.chain(
          flow(
            _StepStateStepOverview.getOption,
            Opt.map(() => true),
          ),
        ),
        Opt.getOrElse(() => false),
      ),
    [step],
  )

  const stepIndex = useMemo(
    () =>
      pipe(
        step,
        Prism.some<StepState>().composePrism(_StepStateStep).getOption,
        Opt.chain((step) =>
          pipe(
            steps,
            Arr.findIndex(
              (s) => s.set.id === step.set && s.indexInSet === step.step,
            ),
          ),
        ),
      ),
    [step, steps],
  )

  // joyride doesn't always respect stepIndex changes,
  // so we call the API manually as well
  useEffect(() => {
    pipe(
      stepIndex,
      Opt.fold(
        IO.of(undefined),
        // TODO this fixes the navigation issues but is hacky and causes some delay.
        (index) =>
          pipe(
            steps,
            Arr.lookup(index),
            Opt.fold(
              () => false,
              (step) => step.indexInSet === 0,
            ),
            Bool.fold(IO.of(undefined), () => helpers?.open()),
          ),
      ),
    )
  }, [steps, stepIndex])

  useEffect(() => {
    if (Opt.isSome(stepIndex)) {
      setTimeout(
        () =>
          (ref?.current as any)?.store?.update?.({
            lifecycle: LIFECYCLE.TOOLTIP,
          }),
        1000,
      )
    }
  }, [ref, step])

  const callback = useCallback(
    ({ action, index, type, status, controlled }: CallBackProps) => {
      if (
        (
          [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as Array<typeof type>
        ).includes(type) &&
        ([ACTIONS.NEXT, ACTIONS.PREV] as Array<typeof action>).includes(action)
      ) {
        const step = steps[index + (action === ACTIONS.PREV ? -1 : 1)]
        if (step !== undefined) {
          const stepSelection: StepSelection = {
            set: step.set.id,
            step: step.indexInSet,
          }
          console.log(stepSelection)
          gotoStep(stepSelection)()
        }
      } else if (
        ([STATUS.FINISHED, STATUS.SKIPPED] as Array<typeof status>).includes(
          status,
        )
      ) {
        close()
      }
    },
    [steps, gotoStep, close],
  )

  const [helpers, setHelpers] = useState<StoreHelpers>()

  return (
    <>
      <Joyride
        ref={ref}
        getHelpers={setHelpers}
        tooltipComponent={TooltipContainer as any}
        {...props}
        continuous
        run={Opt.isSome(stepIndex)}
        callback={callback}
        steps={steps}
        stepIndex={pipe(
          stepIndex,
          Opt.getOrElse(() => 0),
        )}
        spotlightClicks
        beaconComponent={(props) => <HelpBeacon {...props} isActive />}
        styles={{
          options: {
            zIndex: 100,
          },
        }}
      />

      {pipe(
        // If help mode is active show all steps in case of overview mode
        // or in case of step set mode show all steps except for active step
        // since it's rendered by react-joyride.
        step,
        Opt.fold(
          () => [],
          () => steps,
        ),
        Arr.mapWithIndex((i, step) =>
          pipe(
            isStepOverview ||
              pipe(
                stepIndex,
                Opt.fold(
                  () => true,
                  (index) => i !== index,
                ),
              ),
            // true,
            Bool.fold(
              () => null,
              // () => <div>null</div>,
              () => (
                <PlaceAt
                  key={
                    typeof step.target === "string"
                      ? step.target
                      : step.target.id ?? i
                  }
                  target={step.target}
                  alignment={step.alignment}
                  onClick={gotoStep({
                    set: step.set.id,
                    step: step.indexInSet,
                  })}
                >
                  <HelpBeacon
                    continuous
                    index={i}
                    isLastStep={i === steps.length - 1}
                    isActive={pipe(
                      stepIndex,
                      Opt.fold(
                        () => false,
                        (index) => i === index,
                      ),
                    )}
                    setTooltipRef={IO.of(undefined)}
                    size={1}
                    step={step}
                  />
                </PlaceAt>
              ),
            ),
          ),
        ),
      )}
    </>
  )
}

export const HelpContainer = connector(Help)

import { FC, Fragment, ReactNode, useState } from "react"
import { useFormContext } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import Media from "react-media"
import { Button, Collapse } from "reactstrap"
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"

import * as IO from "fp-ts/es6/IO"
/* import styled from "@emotion/styled" */
import {
  Dimension,
  UnitLengthDistance,
  UnitLengthHeight,
  UnitMass,
} from "@fitnesspilot/data-common"

import { unitName } from "../../atoms/UnitName/UnitName"
import {
  ControllerPlus,
  formGroupRender,
  noTransform,
  selectRender,
} from "../Field/Controller"

export type FormData = {
  unitMass: UnitMass
  unitLengthHeight: UnitLengthHeight
  unitLengthDistance: UnitLengthDistance
}

export type UnitFieldsetProps = {
  id: string
  expandable?: boolean
}

type HeaderProps = {
  className?: string
  title: ReactNode
  expandable?: boolean
  children?: ReactNode
  onClick: IO.IO<void>
}

const Heading = styled.h2`
  width: 100%;
  cursor: pointer;
`

const Header = styled(
  ({ className, title, expandable, children, onClick }: HeaderProps) =>
    expandable ? (
      <Heading {...{ className, onClick }}>
        <span>{title}</span>
        {children}
        <Button type="button" size="sm" color="inline">
          <FormattedMessage defaultMessage="Edit" />
        </Button>
      </Heading>
    ) : (
      <h2>{title}</h2>
    ),
)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Span = styled.span`
  font-size: 16px;
  font-weight: normal;
`

type UnitsListProps = {
  unitMass: UnitMass
  unitLengthHeight: UnitLengthHeight
  unitLengthDistance: UnitLengthDistance
}

const UnitsList = ({
  unitMass,
  unitLengthHeight,
  unitLengthDistance,
}: UnitsListProps) => {
  const intl = useIntl()
  return (
    <Span>
      {unitName(intl)({
        dimension: Dimension.mass,
        unit: unitMass,
      })}
      ,{" "}
      {unitName(intl)({
        dimension: Dimension.lengthHeight,
        unit: unitLengthHeight,
      })}
      ,{" "}
      {unitName(intl)({
        dimension: Dimension.lengthDistance,
        unit: unitLengthDistance,
      })}
    </Span>
  )
}

export const UnitFieldset: FC<UnitFieldsetProps> = ({ id, expandable }) => {
  const intl = useIntl()
  const theme = useTheme()
  const form = useFormContext<FormData>()

  const units = form.getValues()

  const [unitsIsOpen, setUnitsOpen] = useState(expandable ? false : true)

  return (
    <Fragment>
      <Header
        {...{ expandable }}
        title={<FormattedMessage defaultMessage="Units" />}
        onClick={() => setUnitsOpen(!unitsIsOpen)}
      >
        <Media queries={theme.media}>
          {({ sm }) => sm && <UnitsList {...units} />}
        </Media>
      </Header>

      <Collapse isOpen={unitsIsOpen}>
        <p>
          <FormattedMessage defaultMessage="Tell us which units you'd like to see." />
        </p>

        <ControllerPlus<FormData, "unitMass", UnitMass>
          render={formGroupRender(selectRender<UnitMass>())({
            id: `${id}-mass`,
            type: "select",
            label: <FormattedMessage defaultMessage="Weight" />,
            values: {
              [UnitMass.kilogram]: unitName(intl)({
                dimension: Dimension.mass,
                unit: UnitMass.kilogram,
              }),
              [UnitMass.pound]: unitName(intl)({
                dimension: Dimension.mass,
                unit: UnitMass.pound,
              }),
            },
          })}
          name="unitMass"
          transform={noTransform<FormData, "unitMass">()}
          rules={{
            required: true,
          }}
        />

        <ControllerPlus<FormData, "unitLengthHeight", UnitLengthHeight>
          render={formGroupRender(selectRender<UnitLengthHeight>())({
            id: `${id}-lengthHeight`,
            type: "select",
            label: <FormattedMessage defaultMessage="Height" />,
            values: {
              [UnitLengthHeight.centimetre]: unitName(intl)({
                dimension: Dimension.lengthHeight,
                unit: UnitLengthHeight.centimetre,
              }),
              [UnitLengthHeight.footAndInch]: unitName(intl)({
                dimension: Dimension.lengthHeight,
                unit: UnitLengthHeight.footAndInch,
              }),
            },
          })}
          name="unitLengthHeight"
          transform={noTransform<FormData, "unitLengthHeight">()}
          rules={{
            required: true,
          }}
        />

        <ControllerPlus<FormData, "unitLengthDistance", UnitLengthDistance>
          render={formGroupRender(selectRender<UnitLengthDistance>())({
            id: `${id}-lengthDistance`,
            type: "select",
            label: <FormattedMessage defaultMessage="Distance" />,
            values: {
              [UnitLengthDistance.kilometre]: unitName(intl)({
                dimension: Dimension.lengthDistance,
                unit: UnitLengthDistance.kilometre,
              }),
              [UnitLengthDistance.mile]: unitName(intl)({
                dimension: Dimension.lengthDistance,
                unit: UnitLengthDistance.mile,
              }),
            },
          })}
          name="unitLengthDistance"
          transform={noTransform<FormData, "unitLengthDistance">()}
          rules={{
            required: true,
          }}
        />
      </Collapse>

      {!unitsIsOpen && (
        <Media queries={theme.media}>
          {({ sm }) => !sm && <UnitsList {...units} />}
        </Media>
      )}
    </Fragment>
  )
}

import { FC } from "react"
import styled from "@emotion/styled"

import { DjIcon, IconType } from "../../atoms/DjIcon/DjIcon"

const CircleIcon = styled(DjIcon)`
  position: absolute;
  color: ${({ theme }) => theme.colours.primary.toString()};
`

export type IconCoachProps = {
  className?: string
}

const InnerIconCoach = ({ className }: IconCoachProps) => (
  <div className={`c-iconCoach ${className ?? ""}`}>
    <DjIcon icon={IconType.userClock} />
    <CircleIcon icon={IconType.userClockCircle} />
  </div>
)

export const IconCoach = styled(InnerIconCoach)`
  position: relative;
  display: flex;
`

import { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { FormattedMessage } from "react-intl"

import * as Bool from "fp-ts/boolean"
import * as Eq from "fp-ts/Eq"
import * as Num from "fp-ts/number"

import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import { CancelButton } from "@fitnesspilot/components-common/dist/molecules/CancelButton/CancelButton"
import { SaveButton } from "@fitnesspilot/components-common/dist/molecules/SaveButton/SaveButton"
import {
  ControllerPlus,
  formGroupRender,
  noTransform,
  rangeRender,
  switchRender,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"
import { useFormChangeEffect } from "@fitnesspilot/components-common/dist/react"

export type FormData = {
  recommendations: boolean
  maxExerciseDays: number
  maxCardioUnits: number
}

export const formData = Eq.struct<FormData>({
  recommendations: Bool.Eq,
  maxExerciseDays: Num.Eq,
  maxCardioUnits: Num.Eq,
})

export type GeneralPreferencesFormProps = {
  id: string
  className?: string
  defaultValues: Partial<FormData>
  onSubmit: (v: FormData) => void
}

export const GeneralPreferencesForm = ({
  id,
  className,
  defaultValues,
  onSubmit,
}: GeneralPreferencesFormProps) => {
  const { handleSubmit, ...form } = useForm<FormData>({
    defaultValues,
  })

  useEffect(() => {
    if (!form.formState.isDirty) {
      form.reset(defaultValues)
    }
  }, [defaultValues])

  const submit = handleSubmit(onSubmit)
  useFormChangeEffect(form, formData, submit)

  return (
    <FormProvider {...{ handleSubmit }} {...form}>
      <form onSubmit={submit} {...{ className }}>
        <Fieldset>
          <h1>
            <FormattedMessage defaultMessage="Include exercise recommendations" />
          </h1>
          <FormattedMessage
            defaultMessage={`Enable this option if you want to

- receive individually recommended sports sessions
- get reminded of sports related tasks
- receive sport related information which are relevant for you
`}
          />
          <ControllerPlus<FormData, "recommendations", boolean>
            render={formGroupRender(switchRender)({
              id: `${id}-recommendations`,
              name: "recommendations",
              label: (
                <FormattedMessage defaultMessage="Include exercise recommendations" />
              ),
            })}
            name="recommendations"
            transform={noTransform<FormData, "recommendations">()}
          />
        </Fieldset>
        <Fieldset>
          <h1>
            <FormattedMessage defaultMessage="Maximum exercising days per week" />
          </h1>
          <FormattedMessage defaultMessage="Let us know how many days max. you want to exercise. Based on your recovery and your goals we'll recommend best workout slots up to that number including your manually planned exercises." />
          <ControllerPlus<FormData, "maxExerciseDays", number>
            render={formGroupRender(rangeRender)({
              id: `${id}-maxExerciseDays`,
              min: 0,
              max: 7,
              step: 1,
              label: (
                <FormattedMessage defaultMessage="Maximum exercising days per week" />
              ),
            })}
            name="maxExerciseDays"
            transform={noTransform<FormData, "maxExerciseDays">()}
            rules={{
              required: true,
            }}
          />
        </Fieldset>
        <Fieldset>
          <h1>
            <FormattedMessage defaultMessage="Maximum cardio units per week" />
          </h1>
          <FormattedMessage
            defaultMessage={`If you want to focus more on muscle training you can limit the maximum amount of cardio units which should be included in your exercises (can be max as much as "maximum exercising days"). Consider that this can reduce your goal progress velocity for fat burning goals.`}
          />
          <ControllerPlus<FormData, "maxCardioUnits", number>
            render={formGroupRender(rangeRender)({
              id: `${id}-maxCardioUnits`,
              min: 0,
              max: 7,
              step: 1,
              label: (
                <FormattedMessage defaultMessage="Maximum cardio units per week" />
              ),
            })}
            name="maxCardioUnits"
            transform={noTransform<FormData, "maxCardioUnits">()}
            rules={{
              required: true,
            }}
          />
        </Fieldset>
      </form>
    </FormProvider>
  )
}

import { FC, useMemo } from "react"
import { connect, ConnectedProps } from "react-redux"

import * as Func from "fp-ts/es6/function"
import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as CommonData from "@fitnesspilot/data-common"

import { LoginForm } from "@fitnesspilot/components-user/dist/organisms/LoginForm/LoginForm"
import { Action, login, UserLogin } from "@fitnesspilot/data-user"

import { LoginTemplate } from "../../templates/Login/Login"

import { Dispatch } from "redux"

const mapState = (state: CommonData.ParentState) => ({
  errors: pipe(
    CommonData.selectors.state.compose(CommonData.selectors.errors).get(state),
  ),
})

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ =
    (act: Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, Func.constVoid)

  return {
    onSubmit: (v: UserLogin) => pipe(v, login, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

export type LoginPageContainerProps = {
  id: string
  className?: string
}

export type LoginPageProps = PropsFromRedux & LoginPageContainerProps

export const LoginPage: FC<LoginPageProps> = ({
  id,
  className,
  errors: errors_,
  onSubmit,
}) => {
  const errors = useMemo(
    () =>
      pipe(
        errors_,
        Arr.map((e) => e.error),
      ),
    [errors_],
  )

  return (
    <LoginTemplate {...{ className }}>
      <LoginForm
        onSubmit={(values: UserLogin) => onSubmit(values)()}
        {...{ id, errors }}
      />
    </LoginTemplate>
  )
}

export const LoginPageContainer = connector(LoginPage)

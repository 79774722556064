import { ReactNode } from "react"
import { FaBan } from "react-icons/fa"
import styled from "@emotion/styled"

import * as IO from "fp-ts/es6/IO"

import { ClickableWithIcon } from "../ClickableWithIcon/ClickableWithIcon"

export type DenylistButtonProps = {
  className: string
  children: ReactNode
  isActive: boolean
  onSetActive: (isActive: boolean) => IO.IO<void>
}

export const DenylistButton = styled(
  ({ className, children, isActive, onSetActive }: DenylistButtonProps) => (
    <ClickableWithIcon
      icon={<FaBan />}
      onClick={() => onSetActive(!isActive)()}
      {...{ className, children }}
    />
  ),
)`
  .c-icon {
    color: ${({ theme, isActive }) =>
      isActive
        ? theme.colours.danger.toString()
        : theme.colours.grey["900"].toString()};
  }
`

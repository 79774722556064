import { MessageDescriptor } from "react-intl"

import { Locale } from "@fitnesspilot/data-common/dist/Locale"

import { ActionType, createAction, createAsyncAction } from "typesafe-actions"

export const setLocale = createAction(
  "clientsWeb/setLocale",
  (locale: Locale) => locale,
)()

export const loadTranslationsAsync = createAsyncAction(
  "clientsWeb/loadTranslations/request",
  "clientsWeb/loadTranslations/success",
  "clientsWeb/loadTranslations/failure",
)<void, Record<string, MessageDescriptor>, Error>()

export type Action = ActionType<
  | typeof setLocale
  | (typeof loadTranslationsAsync)["request" | "success" | "failure"]
>

import * as Bool from "fp-ts/es6/boolean"
import * as Eq from "fp-ts/es6/Eq"
import * as F from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Str from "fp-ts/es6/string"
import { Prism } from "monocle-ts"

export enum UserRole {
  preregistreredUser = "preregistered-user",
  admittedUser = "admitted-user",
  user = "user",
  internalService = "internalService",
}
export const userRole: Eq.Eq<UserRole> = Str.Eq as any
export const stringAsUserRole = new Prism(
  // @TODO
  Opt.some as (v: string) => Opt.Option<UserRole>,
  F.identity as (v: UserRole) => string,
)

export type UserClaims = {
  role: Opt.Option<UserRole>
  admin: boolean
}
export const userClaims: Eq.Eq<UserClaims> = Eq.struct({
  role: Opt.getEq(userRole),
  admin: Bool.Eq,
})

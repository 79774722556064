/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the Option1String interface.
 */
export function instanceOfOption1String(value) {
    let isInstance = true;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    return isInstance;
}
export function Option1StringFromJSON(json) {
    return Option1StringFromJSONTyped(json, false);
}
export function Option1StringFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        value: json.value,
        name: json.name,
        description: json.description,
    };
}
export function Option1StringToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        value: value.value,
        name: value.name,
        description: value.description,
    };
}

import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"

import * as config from "./config"
import { handleMessage } from "./notification"
import { firebaseApp } from "./reduxStore"

import {
  getMessaging,
  getToken as getToken_,
  onMessage,
} from "firebase/messaging"

const messaging = navigator.serviceWorker ? getMessaging(firebaseApp) : null

const displayNotification = async (
  title: string,
  options?: NotificationOptions,
) => {
  if (Notification.permission === "granted") {
    const reg = await navigator.serviceWorker.getRegistration()

    if (reg) {
      void reg.showNotification(title, options)
    }
  }
}

let serviceWorkerRegistration: Opt.Option<ServiceWorkerRegistration> = Opt.none
let unsubscribe: (() => void) | undefined = undefined

export const registerSW = (registration: ServiceWorkerRegistration) => {
  serviceWorkerRegistration = Opt.some(registration)
}

export const getToken = async (): Promise<string> => {
  // sometimes the service worker registration is slow
  const wait = 50
  let waited = 0
  while (Opt.isNone(serviceWorkerRegistration) && waited < 3000) {
    await new Promise((resolve) => setTimeout(resolve, wait))
    waited += wait
  }

  if (messaging == null) {
    throw new Error("`navigator.serviceWorker` isn't defined")
  }

  const token = await getToken_(messaging, {
    serviceWorkerRegistration: pipe(serviceWorkerRegistration, Opt.toUndefined),
    vapidKey: config.fcm.vapidKey,
  })

  unsubscribe?.()
  unsubscribe = onMessage(messaging, handleMessage(displayNotification))

  return token
}

import { Lens, Optional } from "monocle-ts"

import { ParentState, State, stateKey } from "./state"

export const state = Lens.fromProp<ParentState>()(stateKey)

export const availableUpdate = Lens.fromProp<State>()("availableUpdate")

export const errors = Lens.fromProp<State>()("errors")

export const shownError = Lens.fromProp<State>()("shownError")
export const shownErrorOpt = Optional.fromOptionProp<State>()("shownError")

export const errorDetailsAreOpen = Lens.fromProp<State>()("errorDetailsAreOpen")

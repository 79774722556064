/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AboutMeSummaryResponseFromJSON, EventResponseFromJSON, SportsSummaryResponseFromJSON, } from "../models";
import * as runtime from "../runtime";
/**
 *
 */
export class SummaryApi extends runtime.BaseAPI {
    /**
     */
    async activitiesApiUsersUserIdSummaryGetRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling activitiesApiUsersUserIdSummaryGet.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activities/api/users/{userId}/summary`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SportsSummaryResponseFromJSON(jsonValue));
    }
    /**
     */
    async activitiesApiUsersUserIdSummaryGet(requestParameters, initOverrides) {
        const response = await this.activitiesApiUsersUserIdSummaryGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Handles GET requests for Calendar.Controllers.Calendars.SummaryController.
     */
    async calendarApiUsersUserIdSummaryGetRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling calendarApiUsersUserIdSummaryGet.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/calendar/api/users/{userId}/summary`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => EventResponseFromJSON(jsonValue));
    }
    /**
     * Handles GET requests for Calendar.Controllers.Calendars.SummaryController.
     */
    async calendarApiUsersUserIdSummaryGet(requestParameters, initOverrides) {
        const response = await this.calendarApiUsersUserIdSummaryGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Handles GET requests for UserManagement.Controllers.SummaryController.
     */
    async userManagementApiUsersUserIdSummaryGetRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling userManagementApiUsersUserIdSummaryGet.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/users/{userId}/summary`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AboutMeSummaryResponseFromJSON(jsonValue));
    }
    /**
     * Handles GET requests for UserManagement.Controllers.SummaryController.
     */
    async userManagementApiUsersUserIdSummaryGet(requestParameters, initOverrides) {
        const response = await this.userManagementApiUsersUserIdSummaryGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
}

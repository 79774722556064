import * as Opt from "fp-ts/es6/Option";
import { Iso, Lens, Prism } from "monocle-ts";
import { ioTsPrism } from "../internal";
import { Temporal } from "@js-temporal/polyfill";
import * as t from "io-ts";
export const localTime = {
    equals: (a, b) => a.equals(b),
    compare: (a, b) => Temporal.PlainDateTime.compare(a, b),
};
export const equals = (d2) => (d) => localTime.equals(d, d2);
export const compare = (d2) => (d) => localTime.compare(d, d2);
export const localNow = (timeZone) => () => Temporal.Now.plainDateTimeISO(timeZone);
export const localNowInCurrentTimeZone = () => Temporal.Now.plainDateTimeISO();
export const isoStringAsLocalTime = new Prism((s) => Opt.tryCatch(() => Temporal.PlainDateTime.from(s)), (s) => s.toString());
export const localTimeT = t.string.pipe(ioTsPrism("LocalTime", (a) => a instanceof Temporal.PlainDateTime, isoStringAsLocalTime));
export const timeAsLocalTime = (timeZone) => new Iso((s) => s.toZonedDateTimeISO(timeZone).toPlainDateTime(), (s) => s.toZonedDateTime(timeZone).toInstant());
export const dayOfLocalTime = new Lens((s) => s.toPlainDate(), (a) => (s) => s.withPlainDate(a));

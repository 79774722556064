import * as Bool from "fp-ts/es6/boolean"
import * as Eq from "fp-ts/es6/Eq"
import * as Num from "fp-ts/es6/number"
import { Lens } from "monocle-ts"

/**
 * Should actually be called GeneralActivitySettings or something, but FelschR insisted.
 */
export type GeneralSportsSettings = {
  enableExerciseRecommendations: boolean
  maxExerciseDaysPerP1W: number
  maxCardioDaysPerP1W: number
}
export const GeneralSportsSettings = Eq.struct<GeneralSportsSettings>({
  enableExerciseRecommendations: Bool.Eq,
  maxExerciseDaysPerP1W: Num.Eq,
  maxCardioDaysPerP1W: Num.Eq,
})
export const _enableExerciseRecommendations =
  Lens.fromProp<GeneralSportsSettings>()("enableExerciseRecommendations")
export const _maxExerciseDaysPerP1W = Lens.fromProp<GeneralSportsSettings>()(
  "maxExerciseDaysPerP1W",
)
export const _maxCardioDaysPerP1W = Lens.fromProp<GeneralSportsSettings>()(
  "maxCardioDaysPerP1W",
)
export const empty: GeneralSportsSettings = {
  enableExerciseRecommendations: true,
  maxExerciseDaysPerP1W: 3,
  maxCardioDaysPerP1W: 3,
}

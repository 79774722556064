/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from "../runtime";
import { ActivityExerciseFromJSONTyped, ActivityJobFromJSONTyped, ActivityMusclesFromJSONTyped, ActivitySleepFromJSONTyped, } from "./";
import { ActivityExerciseToJSON } from "./ActivityExercise";
import { ActivityJobToJSON } from "./ActivityJob";
import { ActivityMusclesToJSON } from "./ActivityMuscles";
import { ActivitySleepToJSON } from "./ActivitySleep";
import { ActivityType, ActivityTypeFromJSON, ActivityTypeToJSON, } from "./ActivityType";
/**
 * Check if a given object implements the Activity interface.
 */
export function instanceOfActivity(value) {
    let isInstance = true;
    isInstance = isInstance && "$type" in value;
    isInstance = isInstance && "activityId" in value;
    isInstance = isInstance && "title" in value;
    return isInstance;
}
export function ActivityFromJSON(json) {
    return ActivityFromJSONTyped(json, false);
}
export function ActivityFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json.$type === "exercise") {
            return ActivityExerciseFromJSONTyped(json, true);
        }
        if (json.$type === "job") {
            return ActivityJobFromJSONTyped(json, true);
        }
        if (json.$type === "muscles") {
            return ActivityMusclesFromJSONTyped(json, true);
        }
        if (json.$type === "sleep") {
            return ActivitySleepFromJSONTyped(json, true);
        }
    }
    return {
        $type: ActivityTypeFromJSON(json.$type),
        activityId: json.activityId,
        title: json.title,
        createdBy: !exists(json, "createdBy") ? undefined : json.createdBy,
        instructions: !exists(json, "instructions") ? undefined : json.instructions,
    };
}
export function ActivityToJSON(value, ignoreDiscriminator) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    if (!ignoreDiscriminator) {
        if (value.$type === ActivityType.Exercise) {
            return ActivityExerciseToJSON(value);
        }
        if (value.$type === ActivityType.Job) {
            return ActivityJobToJSON(value);
        }
        if (value.$type === ActivityType.Muscles) {
            return ActivityMusclesToJSON(value);
        }
        if (value.$type === ActivityType.Sleep) {
            return ActivitySleepToJSON(value);
        }
    }
    return {
        $type: ActivityTypeToJSON(value.$type),
        activityId: value.activityId,
        title: value.title,
        createdBy: value.createdBy,
        instructions: value.instructions,
    };
}

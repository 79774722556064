import { FC, ReactElement, useState } from "react"
import {
  FaCamera,
  FaChevronDown,
  FaCog,
  FaSignOutAlt,
  FaUser,
} from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import Media from "react-media"
import { connect, ConnectedProps } from "react-redux"
import { Link } from "react-router-dom"
import { withTheme } from "@emotion/react"
import styled from "@emotion/styled"

import { flow, pipe } from "fp-ts/es6/function"
import * as Func from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import { optionLensToOptional } from "@fitnesspilot/data-common"

import { Clickable } from "@fitnesspilot/components-common/dist/atoms/Clickable/Clickable"
import {
  Popover,
  PopoverBody,
} from "@fitnesspilot/components-common/dist/atoms/Popover/Popover"
import { Theme } from "@fitnesspilot/components-common/dist/atoms/ThemeProvider/Theme"
import { FileButton } from "@fitnesspilot/components-common/dist/molecules/FileButton/FileButton"
import {
  Action,
  logout,
  ParentState,
  selectors,
  uploadProfilePhoto,
} from "@fitnesspilot/data-user"
import * as User from "@fitnesspilot/data-user/dist/User"

import {
  ProfilePhoto,
  ProfilePhotoProps,
} from "../../molecules/ProfilePhoto/ProfilePhoto"

import { Dispatch } from "redux"

const StyledProfilePhoto = styled(
  ({ className, ...props }: ProfilePhotoProps) => (
    <ProfilePhoto
      className={`c-styledProfilePhoto ${className ?? ""}`}
      {...props}
    />
  ),
)`
  margin-right: 10px;
`

const StyledClickable = styled(Clickable)`
  display: flex;
  align-items: center;

  .c-styledProfilePhoto {
    width: 48px;
    height: 48px;
  }
`

const UserNavItem = styled.button`
  position: relative;

  & > svg {
    position: absolute;
    transform: translateX(-100%) translateY(-50%);
    margin-left: -0.5em;
    top: 50%;
  }
`

const UserNavItemLink = UserNavItem.withComponent(Link)
const UserNavItemFileButton = UserNavItem.withComponent(FileButton)

const Divider = styled.div``

const mapState = (state: ParentState) => ({
  profilePhoto: selectors.state.compose(selectors.profilePhoto).get(state),
  name: optionLensToOptional(selectors.state.compose(selectors.user))
    .composeOptional(optionLensToOptional(User._name))
    .getOption(state),
})

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ =
    (act: Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, Func.constVoid)
  return {
    onLogout: flow(logout, dispatch_),
    onUploadProfilePhoto: flow(uploadProfilePhoto, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

export type UserNavContainerProps = {
  className?: string
}

export type UserNavProps = PropsFromRedux & UserNavContainerProps

export const UserNav: FC<UserNavProps> = withTheme(
  ({
    profilePhoto,
    name,
    className,
    onLogout,
    onUploadProfilePhoto,
    theme,
  }: UserNavProps & { theme: Theme }) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
      <nav className={className ?? ""}>
        <StyledClickable onClick={() => setIsOpen(!isOpen)}>
          <StyledProfilePhoto profilePhoto={profilePhoto} />
          <Media queries={theme.media}>
            {({ lg }) =>
              lg && (
                <div>
                  <FormattedMessage
                    defaultMessage="Hello <b>{name}</b>"
                    values={{
                      b: (chunks) => <strong>{chunks}</strong>,
                      name: pipe(
                        name,
                        Opt.getOrElse(() => "Anonymous"),
                      ),
                    }}
                  />
                </div>
              )
            }
          </Media>
          <FaChevronDown id="userNav-chevronDown" size="0.75em" />
        </StyledClickable>

        <Popover
          placement="bottom"
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          target="userNav-chevronDown"
        >
          <PopoverBody className="dropdown open">
            <UserNavItemLink to="/aboutMe/body" className="dropdown-item">
              <FaUser />

              <FormattedMessage defaultMessage="Me & my body" />
            </UserNavItemLink>

            <UserNavItemLink to="/aboutMe" className="dropdown-item">
              <FaCog />

              <FormattedMessage defaultMessage="My account" />
            </UserNavItemLink>

            <UserNavItemFileButton
              color="primary"
              onChange={(v) => onUploadProfilePhoto(v[0])()}
              accept={["image/*"]}
              className="dropdown-item"
              Button={(props) => <button type="button" {...props} />}
            >
              <FaCamera />

              <FormattedMessage defaultMessage="Set profile picture" />
            </UserNavItemFileButton>

            <hr />

            <UserNavItem
              type="button"
              onClick={onLogout()}
              className="dropdown-item"
            >
              <FaSignOutAlt />
              <FormattedMessage defaultMessage="Logout" />
            </UserNavItem>
          </PopoverBody>
        </Popover>
      </nav>
    )
  },
)

export const UserNavContainer = connector(UserNav)

import { FC, HTMLAttributes } from "react"
import { BeaconRenderProps } from "react-joyride"
import { useTheme } from "@emotion/react"

export type HelpBeaconProps = BeaconRenderProps & {
  isActive?: boolean
}

export const HelpBeacon: FC<HelpBeaconProps> = ({
  continuous,
  isLastStep,
  isActive,
  setTooltipRef,
  ...props
}) => {
  const theme = useTheme()

  return (
    <button
      key="JoyrideBeacon"
      className="react-joyride__beacon"
      type="button"
      data-test-id="button-beacon"
      style={{
        backgroundColor: "transparent",
        border: 0,
        borderRadius: 0,
        color: "#555",
        cursor: "pointer",
        fontSize: 16,
        lineHeight: 1,
        padding: 8,
        WebkitAppearance: "none",
        display: "inline-block",
        height: 36,
        position: "relative",
        width: 36,
        zIndex: 100,
      }}
      {...props}
    >
      <span
        style={{
          animation: "joyride-beacon-inner 1.2s infinite ease-in-out",
          backgroundColor: isActive
            ? theme.colours.warning.toString()
            : "#309edb",
          borderRadius: "50%",
          display: "block",
          height: "50%",
          left: "50%",
          opacity: 0.7,
          position: "absolute",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
        }}
      />
      <span
        style={{
          animation: "joyride-beacon-outer 1.2s infinite ease-in-out",
          backgroundColor: isActive
            ? theme.colours.warning.alpha(0.2).toString()
            : "rgba(48, 158, 219, 0.2)",
          border: `2px solid #309edb`,
          borderColor: isActive ? theme.colours.warning.toString() : "#309edb",
          borderRadius: "50%",
          boxSizing: "border-box",
          display: "block",
          height: "100%",
          left: 0,
          opacity: 0.9,
          position: "absolute",
          top: 0,
          transformOrigin: "center",
          width: "100%",
        }}
      />
    </button>
  )
}

import * as Eq from "fp-ts/es6/Eq"
import { Lens } from "monocle-ts"
import { Duration, duration } from "time-ts/es6"

import { ActivityId, activityId } from "../activity/ActivityId"
import { ActivityJob, activityJob } from "../activity/ActivityJob"
import { WithId, withIdEq } from "../activity/WithId"

export type ActivityInstanceJob = {
  activity: WithId<ActivityId, ActivityJob>
  duration: Duration
}
export const activityInstanceJob: Eq.Eq<ActivityInstanceJob> = Eq.struct({
  activity: withIdEq(activityId, activityJob),
  duration: duration,
})
export const fromActivityJob = (
  activity: WithId<ActivityId, ActivityJob>,
  duration: Duration,
): ActivityInstanceJob => ({
  activity,
  duration,
})
export const _activity = Lens.fromProp<ActivityInstanceJob>()("activity")
export const _duration = Lens.fromProp<ActivityInstanceJob>()("duration")

import * as Func from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Ordering from "fp-ts/es6/Ordering"
import { Iso, Lens, Prism } from "monocle-ts"
import {
  Dimension,
  explicitOrder,
  UnitMass,
  validNumber,
  valueWithAnyUnit,
  ValueWithUnit,
} from "@fitnesspilot/data-common"

export enum EquipmentType {
  barbell = "equipment-barbell",
  dumbbell = "equipment-dumbbell",
  cableMachine = "equipment-cableMachine",
  butterflyMachine = "equipment-butterflyMachine",
  inclineBench = "equipment-inclineBench",
  treadmill = "equipment-treadmill",
  rowingMachine = "equipment-rowingMachine",
  stationaryBicycle = "equipment-stationaryBicycle",
  pullUpBar = "equipment-pullUpBar",
  rope = "equipment-rope",
  skippingRope = "equipment-skippingRope",
  box = "equipment-box",
  sledgehammer = "equipment-sledgehammer",
  theraband = "equipment-theraband",
  rollerskates = "equipment-rollerskates",
  medicineBalls = "equipment-medicineBalls",
  exerciseBall = "equipment-exerciseBall",
  kettlebell = "equipment-kettlebell",
  foamRoll = "equipment-foamRoll",
  trx = "equipment-trx",
  sandbag = "equipment-sandbag",
  bodybar = "equipment-bodybar",
  tire = "equipment-tire",
  punchingBag = "equipment-punchingBag",
  smithMachine = "equipment-smithMachine",
  bodybarBroom = "equipment-bodybarBroom",
  prowler = "equipment-prowler",
  bicycle = "equipment-bicycle",
  machine = "equipment-machine",
  bands = "equipment-bands",
  other = "equipment-other",
  cable = "equipment-cable",
  medicineBall = "equipment-medicineBall",
  trainingPlate = "equipment-trainingPlate",
  kettlebells = "equipment-kettlebells",
  eZCurlBar = "equipment-eZCurlBar",
  beltRopeOrBand = "equipment-beltRopeOrBand",
  sledgehammerTire = "equipment-sledgehammerTire",
  "2BoxesOrBenches" = "equipment-2BoxesOrBenches",
  bar = "equipment-bar",
  chains = "equipment-chains",

  anyWeight = "equipment-anyWeight",
}
export const equipmentType = explicitOrder([
  EquipmentType.barbell,
  EquipmentType.dumbbell,
  EquipmentType.cableMachine,
  EquipmentType.butterflyMachine,
  EquipmentType.inclineBench,
  EquipmentType.treadmill,
  EquipmentType.rowingMachine,
  EquipmentType.stationaryBicycle,
  EquipmentType.pullUpBar,
  EquipmentType.rope,
  EquipmentType.skippingRope,
  EquipmentType.box,
  EquipmentType.sledgehammer,
  EquipmentType.theraband,
  EquipmentType.rollerskates,
  EquipmentType.medicineBalls,
  EquipmentType.exerciseBall,
  EquipmentType.kettlebell,
  EquipmentType.foamRoll,
  EquipmentType.trx,
  EquipmentType.sandbag,
  EquipmentType.bodybar,
  EquipmentType.tire,
  EquipmentType.punchingBag,
  EquipmentType.smithMachine,
  EquipmentType.bodybarBroom,
  EquipmentType.prowler,
  EquipmentType.bicycle,
  EquipmentType.machine,
  EquipmentType.bands,
  EquipmentType.other,
  EquipmentType.cable,
  EquipmentType.medicineBall,
  EquipmentType.trainingPlate,
  EquipmentType.kettlebells,
  EquipmentType.eZCurlBar,
  EquipmentType.beltRopeOrBand,
  EquipmentType.sledgehammerTire,
  EquipmentType["2BoxesOrBenches"],
  EquipmentType.bar,
  EquipmentType.chains,
  EquipmentType.anyWeight,
])
export const stringAsEquipmentType = new Prism<string, EquipmentType>(
  (m) => Opt.some(m as EquipmentType),
  Func.identity,
)

const mass = valueWithAnyUnit(Dimension.mass, validNumber)
type WeightBase = {
  mass: ValueWithUnit<Dimension.mass, UnitMass>
}
const weightBase: Ord.Ord<WeightBase> = Ord.fromCompare((a, b) =>
  mass.compare(a.mass, b.mass),
)
export type Dumbbell = { type: EquipmentType.dumbbell } & WeightBase
export const dumbbell: Ord.Ord<Dumbbell> = weightBase
export type Barbell = { type: EquipmentType.barbell } & WeightBase
export const barbell: Ord.Ord<Barbell> = weightBase
export type AnyWeight = { type: EquipmentType.anyWeight } & WeightBase
export const anyWeight: Ord.Ord<AnyWeight> = weightBase
export type Weight = Dumbbell | Barbell | AnyWeight
export const weight: Ord.Ord<Weight> = Ord.fromCompare((a, b) =>
  Ordering.semigroupOrdering.concat(
    mass.compare(a.mass, b.mass),
    equipmentType.compare(a.type, b.type),
  ),
)
export const _mass = Lens.fromProp<Weight>()("mass")

export type PullUpBar = {
  type: EquipmentType.pullUpBar
}
export const pullUpBar: Ord.Ord<PullUpBar> = Ord.fromCompare(Func.constant(0))

export type Equipment = AnyWeight // TODO Weight | PullupBar
export const equipment: Ord.Ord<Equipment> = Ord.fromCompare((a, b) =>
  weight.compare(a, b),
)
// export const equipment: Ord.Ord<Equipment> = Ord.fromCompare((a, b) =>
//   a.type === EquipmentType.pullupBar || b.type === EquipmentType.pullupBar
//     ? equipmentType.compare(a.type, b.type)
//     : weight.compare(a, b),
// )
// export const _Dumbbell = Prism.fromPredicate(
//   (v: Equipment): v is Dumbbell => v.type === EquipmentType.dumbbell,
// )
// export const _Kettlebell = Prism.fromPredicate(
//   (v: Equipment): v is Kettlebell => v.type === EquipmentType.kettlebell,
// )
// export const _Barbell = Prism.fromPredicate(
//   (v: Equipment): v is Barbell => v.type === EquipmentType.barbell,
// )
export const _AnyWeight = Prism.fromPredicate(
  (v: Equipment): v is AnyWeight => v.type === EquipmentType.anyWeight,
)
export const _AnyWeightMass = _AnyWeight.composeIso(
  new Iso<AnyWeight, AnyWeight["mass"]>(
    (s) => s.mass,
    (a) => ({
      type: EquipmentType.anyWeight,
      mass: a,
    }),
  ),
)
// export const _PullupBar = Prism.fromPredicate(
//   (v: Equipment): v is PullupBar => v.type === EquipmentType.pullupBar,
// )

import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import { Index } from "monocle-ts"

import * as ActivityId from "../activity/ActivityId"
import * as WithId from "../activity/WithId"
import * as ActivitySettingsExercise from "../activitySettings/ActivitySettingsExercise"
import * as ActivityWithSettings from "../activitySettings/ActivityWithSettings"
import * as Sport from "../sport/Sport"
import * as SportId from "../sport/SportId"
import * as actions from "./actions"
import { Action } from "./actions"
import * as ExerciseList from "./ExerciseList"
import * as selectors from "./selectors"
import { initialState, State } from "./state"

import { createReducer } from "typesafe-actions"

export default createReducer<State, Action>(initialState)
  .handleAction(actions.resetState, () => initialState)
  .handleAction(
    actions.fetchMuscleSettingsAsync.success,
    (state, { payload }) => pipe(state, selectors.muscleSettings.set(payload)),
  )
  // .handleAction(actions.fetchMuscleSettingsAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(
    actions.fetchActivitiesWithSettingsAsync.success,
    (state, { payload }) =>
      pipe(state, selectors.activitiesWithSettings.set(payload)),
  )
  // .handleAction(actions.fetchActivitiesWithSettingsAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(actions.fetchSportsAsync.success, (state, { payload }) =>
    pipe(state, selectors.sports.set(payload)),
  )
  // .handleAction(actions.fetchSportsAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(
    actions.saveMuscleSettingsAsync.success,
    (state, { payload: muscleSettings }) =>
      pipe(state, selectors.muscleSettings.set(muscleSettings)),
  )
  // .handleAction(actions.saveMuscleSettingsAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(
    actions.saveActivitySettingsAsync.success,
    (state, { payload: activitySettings }) =>
      pipe(
        state,
        selectors.activitiesWithSettings
          .composeOptional(
            Index.fromAt(
              WithId._withIdAt<
                ActivityId.ActivityId,
                ActivityWithSettings.ActivityWithSettings
              >(ActivityId.activityId),
            ).index(activitySettings.id),
          )
          .composeLens(ActivityWithSettings._settings)
          .set(activitySettings.value),
      ),
  )
  // .handleAction(actions.saveActivitySettingsAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(actions.saveSportAsync.success, (state, { payload: sport }) =>
    pipe(
      state,
      selectors.sports
        .composeOptional(
          Index.fromAt(
            WithId._withIdAt<SportId.SportId, Sport.Sport>(SportId.sportId),
          ).index(sport.id),
        )
        .set(sport.value),
    ),
  )
  // .handleAction(actions.saveSportAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(
    actions.setSportIsLikedAsync.success,
    (state, { payload: { id, value } }) =>
      pipe(
        state,
        selectors.sports
          .composeOptional(
            Index.fromAt(
              WithId._withIdAt<SportId.SportId, Sport.Sport>(SportId.sportId),
            ).index(id),
          )
          .modify((sport) => ({
            ...sport,
            isLiked: value,
          })),
      ),
  )
  // .handleAction(actions.setSportIsLikedAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(
    actions.setActivityListingAsync.success,
    (state, { payload: { activity, listing } }) =>
      pipe(
        state,
        selectors.activitiesWithSettings
          .composeOptional(
            Index.fromAt(
              WithId._withIdAt<
                ActivityId.ActivityId,
                ActivityWithSettings.ActivityWithSettings
              >(ActivityId.activityId),
            ).index(activity),
          )
          .composeOptional(ActivityWithSettings._ActivitySettingsExercise)
          .composeLens(ActivitySettingsExercise._listing)
          .set(listing),
      ),
  )
  // .handleAction(actions.setActivityListingAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(
    actions.fetchGeneralSportsSettingsAsync.success,
    (state, { payload: generalSportsSettings }) =>
      pipe(state, selectors.generalSportsSettings.set(generalSportsSettings)),
  )
  // .handleAction(actions.fetchGeneralSportsSettingsAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(
    actions.setGeneralSportsSettingsAsync.success,
    (state, { payload: generalSportsSettings }) =>
      pipe(state, selectors.generalSportsSettings.set(generalSportsSettings)),
  )
  // .handleAction(actions.setGeneralSportsSettingsAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(
    actions.setExerciseListSearch,
    (state, { payload: { value } }) =>
      pipe(
        state,
        selectors.exerciseList.composeLens(ExerciseList._search).set(value),
      ),
  )
  .handleAction(
    actions.setExerciseListSorting,
    (state, { payload: { sorting } }) =>
      pipe(
        state,
        selectors.exerciseList.composeLens(ExerciseList._sorting).set(sorting),
      ),
  )
  .handleAction(
    actions.setExerciseListFilters,
    (state, { payload: { filters } }) =>
      pipe(
        state,
        selectors.exerciseList
          .composeLens(ExerciseList._filters)
          .modify((old) => ({
            ...old,
            ...filters,
          })),
        selectors.exerciseList.composeLens(ExerciseList._search).set(Opt.none),
      ),
  )

import { FC, ReactNode } from "react"
import { FaCheck } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import { OmitFromKnownKeys } from "@fitnesspilot/data-common"

import { CancelButton } from "../../molecules/CancelButton/CancelButton"
import { Modal, ModalProps } from "../../organisms/Modal/Modal"

export type CommonModalProps = {
  title: ReactNode
  onSave: Opt.Option<() => void | Promise<void>>
  onToggle: (open: boolean) => void
  customSaveText?: ReactNode
} & OmitFromKnownKeys<ModalProps, "toggle" | "title">

export const CommonModal: FC<CommonModalProps> = ({
  children,
  onSave,
  onToggle,
  size = "lg",
  title,
  customSaveText,
  ...props
}) => (
  <Modal {...props} {...{ size }} toggle={() => onToggle(false)}>
    <ModalHeader tag="h1" toggle={() => onToggle(false)}>
      {title}
    </ModalHeader>

    <ModalBody>{children}</ModalBody>

    <ModalFooter>
      {pipe(
        onSave,
        Opt.map((onSave) => (
          <Button key="save" color="success" onClick={onSave}>
            <FaCheck />{" "}
            {customSaveText ?? <FormattedMessage defaultMessage="Save" />}
          </Button>
        )),
        Opt.toNullable,
      )}

      <CancelButton type="button" onClick={() => onToggle(false)} />
    </ModalFooter>
  </Modal>
)

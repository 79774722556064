import { FC, memo, useCallback, useRef, useState } from "react"
import { FaDumbbell, FaPlus } from "react-icons/fa"
import { FormattedMessage, useIntl } from "react-intl"
import { css, useTheme } from "@emotion/react"
import styled from "@emotion/styled"

import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import { unwrap } from "@fitnesspilot/data-common"

import { Card } from "@fitnesspilot/components-common/dist/atoms/Card/Card"
import { StringInput as StringInput_ } from "@fitnesspilot/components-common/dist/atoms/input/StringInput"
import { Tooltip } from "@fitnesspilot/components-common/dist/atoms/Tooltip/Tooltip"
import { VirtualList } from "@fitnesspilot/components-common/dist/atoms/VirtualList/VirtualList"
import { VirtualListItem } from "@fitnesspilot/components-common/dist/atoms/VirtualList/VirtualListItem"
import {
  ButtonWithIcon,
  IconSide,
} from "@fitnesspilot/components-common/dist/molecules/ButtonWithIcon/ButtonWithIcon"
import { ActivityInstance } from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"
import { linkForVideo } from "@fitnesspilot/data-event/dist/video/Video"
import { WorkoutVideo } from "@fitnesspilot/data-event/dist/video/WorkoutVideo"

import { useVirtualizer } from "@tanstack/react-virtual"

export type VideoSearchProps = {
  search: Opt.Option<string>
  videos: ReadonlyArray<WorkoutVideo>
  onChangeSearch: (v: Opt.Option<string>) => IO.IO<void>
  onSelectVideo: (v: Opt.Option<WorkoutVideo>) => IO.IO<void>
}

const Centered = styled.h2`
  text-align: center;
`

const Footer = styled.div`
  margin-top: 10px;
`

const VideoList = memo(styled(VirtualList)<{ withSearch?: boolean }>`
  ${({ withSearch }) =>
    withSearch
      ? css`
          height: calc(100% - 70px);
        `
      : css`
          height: 100%;
        `}
`)
const VideoListItem = VirtualListItem

const StringInput = styled(StringInput_)`
  margin-bottom: 20px;
`

const VideoTitle = styled.h2`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 10px;
`

export const VideoSearch: FC<VideoSearchProps> = ({
  search,
  videos,
  onChangeSearch,
  onSelectVideo,
}) => {
  const [preselectedActivities, setPreselectedActivities] = useState<
    Rec.ReadonlyRecord<string, ActivityInstance>
  >({})

  const theme = useTheme()
  const intl = useIntl()

  const listRef = useRef<HTMLDivElement>(null)

  const videosVirtualizer = useVirtualizer({
    count: videos.length,
    getScrollElement: () => listRef.current,
    estimateSize: () => 488,
    overscan: 3,
  })
  const videosVirtualItems = videosVirtualizer.getVirtualItems()

  return (
    <>
      <StringInput value={search} onChange={onChangeSearch} />

      <VideoList
        withSearch
        ref={listRef}
        totalSize={videosVirtualizer.getTotalSize()}
        start={videosVirtualItems.at(0)?.start ?? 0}
      >
        {pipe(
          videos,
          NonEmpty.fromReadonlyArray,
          Opt.fold(
            () => [
              <Centered key="emptyVideoResults">
                <FormattedMessage defaultMessage="Try searching for a video or paste a link to one." />
              </Centered>,
            ],
            () =>
              pipe(
                videosVirtualItems,
                Arr.map(({ index }) =>
                  pipe(videos[index], (workoutVideo) => {
                    const { id, title, video } = workoutVideo
                    return (
                      <VideoListItem
                        key={unwrap(id)}
                        ref={videosVirtualizer.measureElement}
                        data-help-mode="videoResult"
                      >
                        <Card>
                          <VideoTitle>
                            <span>
                              {Opt.getOrElse(() => video.title)(title)}
                            </span>
                            {Arr.isNonEmpty(workoutVideo.activities) && (
                              <Tooltip
                                title={
                                  <FormattedMessage defaultMessage="Activities for this video available." />
                                }
                                minWidth={240}
                              >
                                <FaDumbbell
                                  color={theme.colours.primary.toString()}
                                />
                              </Tooltip>
                            )}
                          </VideoTitle>

                          <div>
                            <a
                              target="_blank"
                              href={linkForVideo(video)}
                              rel="noreferrer"
                            >
                              <img
                                src={Opt.toUndefined(video.thumbnailUrl)}
                                style={{ maxWidth: "500px" }}
                              />
                            </a>
                          </div>

                          <Footer>
                            <ButtonWithIcon
                              color="primary"
                              icon={<FaPlus />}
                              iconSide={IconSide.left}
                              onClick={onSelectVideo(Opt.some(workoutVideo))}
                            >
                              <FormattedMessage defaultMessage="Select" />
                            </ButtonWithIcon>
                          </Footer>
                        </Card>
                      </VideoListItem>
                    )
                  }),
                ),
              ),
          ),
        )}
      </VideoList>
    </>
  )
}

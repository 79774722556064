import { ReactNode } from "react"
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane as TabPane_,
} from "reactstrap"
import { PropsOf } from "@emotion/react"
import styled from "@emotion/styled"

import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"

import { Content } from "../../molecules/Content/Content"

import classNames from "classnames"

const NavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const TabPane = styled(TabPane_)`
  background: ${({ theme }) => theme.colours.white.toString()};
`

export type TabsProps<a extends string> = {
  className?: string
  tabTexts: Record<a, ReactNode>
  right?: ReactNode
  children: Record<a, ReactNode>
  tabs?: ReadonlyArray<a>
  tabsProps?: Omit<PropsOf<typeof Nav>, "tabs">
  tabProps?: Partial<Record<a, PropsOf<typeof NavItem>>>
  currentTab: a
  onChangeTab: (v: a) => IO.IO<void>
}

export const tabs = <a extends string>() =>
  styled(
    ({
      className,
      tabTexts,
      right,
      children,
      tabs = pipe(tabTexts, Rec.keys),
      tabsProps = {},
      tabProps = {},
      currentTab,
      onChangeTab,
    }: TabsProps<a>) => (
      <div {...{ className }}>
        <NavWrapper>
          <Nav tabs {...tabsProps}>
            {pipe(
              tabs,
              Arr.filterMap((v) =>
                pipe(
                  tabTexts,
                  Rec.lookup(v),
                  Opt.map((t: ReactNode) => [v, t] as const),
                ),
              ),
              Arr.map(([v, t]) => (
                <NavItem key={v} {...(tabProps[v] ?? {})}>
                  <NavLink
                    className={classNames({ active: currentTab === v })}
                    onClick={onChangeTab(v)}
                    tag="button"
                  >
                    {t}
                  </NavLink>
                </NavItem>
              )),
            )}
          </Nav>
          {right}
        </NavWrapper>

        <TabContent activeTab={currentTab}>
          {pipe(
            tabs,
            Arr.filterMap((v) =>
              pipe(
                children,
                Rec.lookup(v),
                Opt.map((c: ReactNode) => [v, c] as const),
              ),
            ),
            Arr.map(([v, c]) => (
              <TabPane key={v} tabId={v}>
                <Content>{c}</Content>
              </TabPane>
            )),
          )}
        </TabContent>
      </div>
    ),
  )``

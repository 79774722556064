/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the PhotoDownload interface.
 */
export function instanceOfPhotoDownload(value) {
    let isInstance = true;
    isInstance = isInstance && "dateCreated" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "contentUrl" in value;
    return isInstance;
}
export function PhotoDownloadFromJSON(json) {
    return PhotoDownloadFromJSONTyped(json, false);
}
export function PhotoDownloadFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        dateCreated: new Date(json.dateCreated),
        id: json.id,
        contentUrl: json.contentUrl,
    };
}
export function PhotoDownloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        dateCreated: value.dateCreated.toISOString(),
        id: value.id,
        contentUrl: value.contentUrl,
    };
}

import { flow, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { At, Lens, Prism } from "monocle-ts"

import { _id, _value } from "@fitnesspilot/data-activity/dist/activity/WithId"

import { Event, EventId, eventId, EventWithId } from "./Event"
import { _EventWithId, EventOrRecommendation } from "./EventOrRecommendation"

export type EventsOrRecommendations = ReadonlyArray<EventOrRecommendation>

export const _eventsWithIdAt = new At<
  EventsOrRecommendations,
  EventId,
  Opt.Option<EventWithId>
>(
  (id) =>
    new Lens<EventsOrRecommendations, Opt.Option<EventWithId>>(
      flow(
        Arr.filterMap((evt) => _EventWithId.getOption(evt)),
        Arr.findFirst((evt) =>
          eventId.equals(id, _id<EventId, Event>().get(evt)),
        ),
      ),
      (evt) =>
        flow(
          Arr.filter((evt2) =>
            pipe(
              evt2,
              _EventWithId.getOption,
              Opt.map(
                (evt2) => !eventId.equals(id, _id<EventId, Event>().get(evt2)),
              ),
              Opt.getOrElse(() => true as boolean),
            ),
          ),
          (evts) =>
            pipe(
              evt,
              Opt.fold(
                () => evts,
                (evt) => Arr.append(_EventWithId.reverseGet(evt))(evts),
              ),
            ),
        ),
    ),
)

export const _eventsAt = new At<
  EventsOrRecommendations,
  EventId,
  Opt.Option<Event>
>(
  (id) =>
    new Lens(
      _eventsWithIdAt
        .at(id)
        .composePrism(Prism.some())
        .composeLens(_value<EventId, Event>()).getOption,
      (a) => (s) =>
        pipe(
          s,
          pipe(
            a,
            Opt.map((value) => ({ id, value })),
            _eventsWithIdAt.at(id).set,
          ),
        ),
    ),
)

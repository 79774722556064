import { FC, Fragment, ReactElement, useRef, useState } from "react"
import { FaShare } from "react-icons/fa"
import { FormattedMessage, IntlShape, useIntl } from "react-intl"
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap"
import styled from "@emotion/styled"

import { constNull, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import { ShareData } from "@fitnesspilot/data-common"

import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import { Label } from "@fitnesspilot/components-common/dist/atoms/Label/Label"
import {
  Popover,
  PopoverBody,
} from "@fitnesspilot/components-common/dist/atoms/Popover/Popover"
import { ReferralCode } from "@fitnesspilot/data-user/dist/ReferralCode"

const ColCentered = styled(Col)`
  align-self: center;
`

export type ReferFriendsFieldsetProps = {
  id: string
  referralCode: Opt.Option<ReferralCode>
  onShare: (share: ShareData) => IO.IO<void>
}

const handleShare = (
  intl: IntlShape,
  code: string,
  callback: (share: ShareData) => IO.IO<void>,
) => {
  const title = intl.formatMessage({
    defaultMessage: "Fitnesspilot Invite",
  })
  const text = intl.formatMessage({
    defaultMessage: "Sign up exclusively for the Fitnesspilot Closed Beta",
  })
  const url = `https://fitnesspilot.com/?referral=${encodeURIComponent(code)}`

  return callback({ title, text, url })()
}

export const ReferFriendsFieldset: FC<ReferFriendsFieldsetProps> = ({
  id,
  referralCode,
  onShare,
}) => {
  const intl = useIntl()
  const targetRef = useRef<HTMLAnchorElement>(null)
  const [descriptionIsOpen, setDescriptionIsOpen] = useState(false)

  return (
    <>
      <h2>
        <FormattedMessage defaultMessage="Refer friends" />
      </h2>

      <span>
        <FormattedMessage
          defaultMessage="Tell your friends about the Fitnesspilot and help us grow and get better.<br></br><a>Currently we honour your help with special rewards.</a>"
          values={{
            a: (children) => (
              <a
                href=""
                ref={targetRef}
                onMouseEnter={() => setDescriptionIsOpen(true)}
                onMouseLeave={() => setDescriptionIsOpen(false)}
              >
                {children}
              </a>
            ),
          }}
        />
      </span>

      {targetRef.current && (
        <Popover
          isOpen={descriptionIsOpen}
          target={targetRef.current}
          placement="auto"
        >
          <PopoverBody>
            <FormattedMessage defaultMessage="Successfully invite 10 unregistered friends and receive a free account for lifetime" />
          </PopoverBody>

          <PopoverBody></PopoverBody>
        </Popover>
      )}

      {pipe(
        referralCode,
        Opt.fold(constNull, (referralCode) => (
          <Fragment>
            <FormGroup row>
              <Label for={`${id}-importActivities`} xs={5} md={3}>
                <FormattedMessage defaultMessage="Your referral code" />
              </Label>

              <Col xs={7} md={7}>
                <InputGroup>
                  <Input
                    id={`${id}-referralCode`}
                    type="text"
                    value={referralCode.code}
                    readOnly
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      onClick={() =>
                        handleShare(intl, referralCode.code, onShare)
                      }
                    >
                      <FaShare />
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </Col>

              <ColCentered
                xs={{ offset: 5, size: 7 }}
                md={{ offset: 0, size: 2 }}
              >
                <FormattedMessage
                  defaultMessage="Used by {count} people"
                  values={{ count: referralCode.usedBy.length }}
                />
              </ColCentered>
            </FormGroup>
          </Fragment>
        )),
      )}
    </>
  )
}

import { FC, ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import { Col, Container, Row } from "reactstrap"
import styled from "@emotion/styled"

import { constNull, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"

import { Range } from "@fitnesspilot/components-common/dist/atoms/Range/Range"
import { FormGroup } from "@fitnesspilot/components-common/dist/molecules/FormGroup/FormGroup"
import { ImageWithTextBesideIt } from "@fitnesspilot/components-common/dist/molecules/ImageWithTextBesideIt/ImageWithTextBesideIt"
import { Switch } from "@fitnesspilot/components-common/dist/molecules/Switch/Switch"
import { MuscleGroupImage } from "@fitnesspilot/components-human-body/dist/atoms/MuscleGroupImage/MuscleGroupImage"
import { MuscleGroupName } from "@fitnesspilot/components-human-body/dist/atoms/MuscleGroupName/MuscleGroupName"
import { MuscleLevelColour } from "@fitnesspilot/components-human-body/dist/atoms/MuscleLevelColour/MuscleLevelColour"
import { MuscleGroupId } from "@fitnesspilot/data-human-body/dist/muscleGroups"
import {
  numberAsPriority,
  Priority,
} from "@fitnesspilot/data-human-body/dist/Priority"

const Level = styled(MuscleLevelColour)`
  font-size: 0.75em;
  margin-left: 10px;
`

const Title = styled.div`
  display: flex;
  align-items: baseline;
`

export type MuscleGroupPreferenceProps = {
  id: string
  muscleGroupId: MuscleGroupId
  enabled: boolean
  priority: Priority
  level?: number | false
  right?: ReactNode
  onSetEnabled: (v: boolean) => IO.IO<void>
  onSetPriority: (v: Priority) => IO.IO<void>
}

export const MuscleGroupPreference: FC<MuscleGroupPreferenceProps> = ({
  id,
  muscleGroupId,
  enabled,
  priority,
  level,
  right,
  onSetEnabled,
  onSetPriority,
}) => (
  <Container fluid={true}>
    <ImageWithTextBesideIt
      image={<MuscleGroupImage value={muscleGroupId} />}
      title={
        <Title>
          <MuscleGroupName id={muscleGroupId} />
          {pipe(
            level,
            Opt.fromNullable,
            Opt.fold(constNull, (level) => (
              <Level {...{ level }}>
                {typeof level === "number" ? (
                  <FormattedMessage
                    defaultMessage="{value, number, percent} ready"
                    values={{
                      value: level,
                    }}
                  />
                ) : level === false ? (
                  <FormattedMessage defaultMessage="Disabled" />
                ) : null}
              </Level>
            )),
          )}
        </Title>
      }
      topRight={Opt.some(
        <Row>
          <Col xs={right ? 8 : 12}>
            <Switch
              id={`${id}-enabled`}
              name="enabled"
              checked={enabled}
              onChange={(v) => onSetEnabled(v)()}
            />
          </Col>

          {right && <Col xs={4}>{right}</Col>}
        </Row>,
      )}
    >
      <FormGroup
        inputId={`${id}-priority`}
        label={<FormattedMessage defaultMessage="Priority" />}
      >
        <Range
          min={0}
          max={10}
          step={1}
          value={pipe(priority, numberAsPriority.reverseGet)}
          disabled={!enabled}
          onChange={(v) =>
            pipe(
              v,
              numberAsPriority.getOption,
              Opt.map(onSetPriority),
              Opt.getOrElse<IO.IO<void>>(() => IO.of(undefined)),
            )()
          }
        />
      </FormGroup>
    </ImageWithTextBesideIt>
  </Container>
)

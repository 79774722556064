import { Lens, Optional } from "monocle-ts"

import * as WithId from "@fitnesspilot/data-activity/dist/activity/WithId"

import { ParentState, State, stateKey } from "./state"

export const state = Lens.fromProp<ParentState>()(stateKey)

export const eventsOrRecommendations = Lens.fromProp<State>()(
  "eventsOrRecommendations",
)

export const timeslots = Lens.fromProp<State>()("timeslots")

export const loadedRanges = Lens.fromProp<State>()("loadedRanges")

export const editingEvent = Lens.fromProp<State>()("editingEvent")
export const editingEventOpt = Optional.fromOptionProp<State>()("editingEvent")

export const editingEventValOpt = editingEventOpt.composeLens(WithId._value())

export const calendarSelection = Lens.fromProp<State>()("calendarSelection")

import { ReactNode } from "react"
import { Button, ButtonProps } from "reactstrap"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

const Icon = styled.div``

const Children = styled.div``

export enum IconSide {
  left = "left",
  right = "right",
}

export type ButtonWithIconProps = {
  icon: ReactNode
  iconSide: IconSide
} & ButtonProps

export const ButtonWithIcon = styled(
  ({ className, icon, iconSide, children, ...props }: ButtonWithIconProps) => (
    <Button className={`c-buttonWithIcon ${className ?? ""}`} {...props}>
      <Icon>{icon}</Icon>
      <Children>{children}</Children>
    </Button>
  ),
)`
  display: inline-flex;
  column-gap: 0.5em;
  ${({ iconSide }) =>
    iconSide === IconSide.right
      ? css`
          flex-direction: row-reverse;
        `
      : null}
`

import { FC, HTMLAttributes } from "react"
import styled from "@emotion/styled"

import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"

import {
  SportId,
  sportIdAsActivityId,
} from "@fitnesspilot/data-activity/dist/sport/SportId"

import { ActivityImage } from "../ActivityImage/ActivityImage"
import def from "./imgs/default.jpg"

const Img = styled.img`
  border: 1px solid hsla(0, 0%, 46.7%, 0.25);
  border-radius: 3px;
`

export type SportImageProps = {
  value: SportId
} & Omit<HTMLAttributes<HTMLImageElement>, "src">

// @TODO Add sports images
export const SportImage = ({ value, className }: SportImageProps) =>
  pipe(
    value,
    sportIdAsActivityId.getOption,
    Opt.fold(
      () => <Img src={def} {...{ className }} />,
      (a) => <ActivityImage value={a} {...{ className }} />,
    ),
  )

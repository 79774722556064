import { HTMLAttributes } from "react"
import styled from "@emotion/styled"

export type MainFooterProps = HTMLAttributes<HTMLDivElement>

export const MainFooter = styled.footer`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`

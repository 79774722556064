// @generated by protoc-gen-es v1.3.0 with parameter "target=ts"
// @generated from file video/v1/video.proto (package video.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, StringValue, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum video.v1.VideoSource
 */
export enum VideoSource {
  /**
   * @generated from enum value: VIDEO_SOURCE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: VIDEO_SOURCE_YOUTUBE = 1;
   */
  YOUTUBE = 1,

  /**
   * @generated from enum value: VIDEO_SOURCE_VIMEO = 2;
   */
  VIMEO = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(VideoSource)
proto3.util.setEnumType(VideoSource, "video.v1.VideoSource", [
  { no: 0, name: "VIDEO_SOURCE_UNSPECIFIED" },
  { no: 1, name: "VIDEO_SOURCE_YOUTUBE" },
  { no: 2, name: "VIDEO_SOURCE_VIMEO" },
]);

/**
 * @generated from message video.v1.Video
 */
export class Video extends Message<Video> {
  /**
   * @generated from field: video.v1.VideoSource source = 1;
   */
  source = VideoSource.UNSPECIFIED;

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  /**
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * @generated from field: string channel_id = 5;
   */
  channelId = "";

  /**
   * @generated from field: string channel_title = 6;
   */
  channelTitle = "";

  /**
   * @generated from field: google.protobuf.Timestamp published_at = 7;
   */
  publishedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.StringValue thumbnail_url = 8;
   */
  thumbnailUrl?: string;

  constructor(data?: PartialMessage<Video>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "video.v1.Video";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "source", kind: "enum", T: proto3.getEnumType(VideoSource) },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "channel_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "channel_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "published_at", kind: "message", T: Timestamp },
    { no: 8, name: "thumbnail_url", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Video {
    return new Video().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Video {
    return new Video().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Video {
    return new Video().fromJsonString(jsonString, options);
  }

  static equals(a: Video | PlainMessage<Video> | undefined, b: Video | PlainMessage<Video> | undefined): boolean {
    return proto3.util.equals(Video, a, b);
  }
}


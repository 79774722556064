import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Num from "fp-ts/es6/number"
import * as Ord from "fp-ts/es6/Ord"
import * as Str from "fp-ts/es6/string"
import { Iso, Lens } from "monocle-ts"
import { currentTimeZone, Time } from "time-ts/es6"

import { liftGetter2 } from "./type"

export enum DayOfWeekIso {
  monday = 1,
  tuesday = 2,
  wednesday = 3,
  thursday = 4,
  friday = 5,
  saturday = 6,
  sunday = 7,
}
export const dayOfWeekIso: Ord.Ord<DayOfWeekIso> = Num.Ord

export enum DayOfWeek {
  monday = "monday",
  tuesday = "tuesday",
  wednesday = "wednesday",
  thursday = "thursday",
  friday = "friday",
  saturday = "saturday",
  sunday = "sunday",
}
export const dayOfWeekAsDayOfWeekIso = new Iso<DayOfWeek, DayOfWeekIso>(
  (dow) => DayOfWeekIso[dow],
  (dowi) => DayOfWeekIso[dowi] as any,
)
export const dayOfWeek: Ord.Ord<DayOfWeek> = {
  equals: Str.Ord.equals,
  compare: liftGetter2(dayOfWeekAsDayOfWeekIso.asGetter())(
    dayOfWeekIso.compare,
  ),
}

export const dayOfWeekOfTime = new Lens<Time, DayOfWeekIso>(
  // @TODO Float out the tz (since it is apparently required to convert an Instant to a PlainDate)
  (s) =>
    pipe(
      currentTimeZone,
      IO.map((tz) => s.toZonedDateTimeISO(tz).dayOfWeek),
    )(),
  (a) => (s) =>
    pipe(
      IO.Do,
      IO.bind("tz", () => currentTimeZone),
      IO.bind("t", ({ tz }) => IO.of(s.toZonedDateTimeISO(tz))),
      IO.bind("dow", ({ t }) => IO.of(t.dayOfWeek)),
      IO.map(({ t, dow }) => t.add({ days: a - dow }).toInstant()),
    )(),
).composeIso(dayOfWeekAsDayOfWeekIso.reverse())

import { BaseColours, Colour, ColourScheme } from "./Theme"

import color from "color"

const baseColours: BaseColours = {
  black: color("#000"),
  blue: {
    100: color("#eaf5fb"),
    300: color("#71cfff"),
    500: color("#309edb"),
    700: color("#0070a9"),
  },
  green: {
    300: color("#87f3c6"),
    500: color("#53c095"),
    600: color("#4ac874"),
    700: color("#138f67"),
  },
  grey: {
    100: color("#eeeef8"),
    200: color("#e3e3ed"),
    300: color("#c0c0d0"),
    500: color("#86a3bf"),
    600: color("#777"),
    700: color("#3b546a"),
    900: color("#23425c"),
  },
  red: {
    300: color("#ff8c84"),
    500: color("#dd5b57"),
    550: color("#db535b"),
    700: color("#a6292e"),
  },
  white: color("#fff"),
  yellow: {
    300: color("#fff855"),
    450: color("#ffc900"),
    500: color("#f8c514"),
    700: color("#c09500"),
  },
} as const

const colours = {
  ...baseColours,
  textMuted: baseColours.grey[500],

  /* TODO some variants of these colors were used; we should unify colors at some point */
  bodyColour: baseColours.grey[900],
  danger: baseColours.red[500] /* old: #df5a5a; variants: #db535b #db5430 */,
  primary: baseColours.blue[500] /* variants: #359DD7, #1298d9 */,
  success: baseColours.green[500] /* old: #1dca87; variants: #4ac874 #c0c0d0 */,
  warning: baseColours.yellow[500] /* old: #feb12c; variants: #ffc900 */,
} as const
export default colours

const toDark = (c: Colour) => c.lightness(100 - c.lightness())

export const schemeColours = (scheme: ColourScheme) =>
  // eslint-disable-next-line no-constant-condition
  scheme === ColourScheme.light || true
    ? colours
    : {
        black: toDark(colours.black),
        blue: {
          100: toDark(colours.blue[100]),
          300: toDark(colours.blue[300]),
          500: toDark(colours.blue[500]),
          700: toDark(colours.blue[700]),
        },
        green: {
          300: toDark(colours.green[300]),
          500: toDark(colours.green[500]),
          600: toDark(colours.green[600]),
          700: toDark(colours.green[700]),
        },
        grey: {
          100: toDark(colours.grey[100]),
          200: toDark(colours.grey[200]),
          300: toDark(colours.grey[300]),
          500: toDark(colours.grey[500]),
          600: toDark(colours.grey[600]),
          700: toDark(colours.grey[700]),
          900: toDark(colours.grey[900]),
        },
        red: {
          300: toDark(colours.red[300]),
          500: toDark(colours.red[500]),
          550: toDark(colours.red[550]),
          700: toDark(colours.red[700]),
        },
        white: toDark(colours.white),
        yellow: {
          300: toDark(colours.yellow[300]),
          450: toDark(colours.yellow[450]),
          500: toDark(colours.yellow[500]),
          700: toDark(colours.yellow[700]),
        },
        textMuted: toDark(colours.textMuted),
        bodyColour: toDark(colours.bodyColour),
        danger: toDark(colours.danger),
        primary: toDark(colours.primary),
        success: toDark(colours.success),
        warning: toDark(colours.warning),
      }

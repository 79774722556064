import { useCallback, useMemo, useRef } from "react"
import { FaUser } from "react-icons/fa"
import { FormattedMessage, useIntl } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import styled from "@emotion/styled"

import {
  constFalse,
  constVoid,
  flow,
  identity,
  not,
  pipe,
} from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as St from "fp-ts/es6/ReadonlySet"
import { fromTraversable } from "monocle-ts"

import { activityName } from "@fitnesspilot/components-activity/dist/atoms/ActivityName/ActivityName"
import { Activity as ActivityComponent } from "@fitnesspilot/components-activity/dist/organisms/Activity/Activity"
import {
  VirtualList,
  VirtualListInner,
  VirtualListOuter,
} from "@fitnesspilot/components-common/dist/atoms/VirtualList/VirtualList"
import { VirtualListItem } from "@fitnesspilot/components-common/dist/atoms/VirtualList/VirtualListItem"
import { Content } from "@fitnesspilot/components-common/dist/molecules/Content/Content"
import { Header } from "@fitnesspilot/components-common/dist/molecules/Header/Header"
import { Markdown } from "@fitnesspilot/components-common/dist/molecules/Markdown/Markdown"
import { ActivitySearch } from "@fitnesspilot/components-event/dist/molecules/ActivitySearch/ActivitySearch"
import {
  _ActivityExercise,
  Activity,
  ActivityWithId,
  foldActivity,
} from "@fitnesspilot/data-activity/dist/activity/Activity"
import * as ActivityExercise from "@fitnesspilot/data-activity/dist/activity/ActivityExercise"
import * as ActivityId from "@fitnesspilot/data-activity/dist/activity/ActivityId"
import { stringAsActivityId } from "@fitnesspilot/data-activity/dist/activity/ActivityId"
import * as ActivityTag from "@fitnesspilot/data-activity/dist/activity/ActivityTag"
import * as WithId from "@fitnesspilot/data-activity/dist/activity/WithId"
import { filterActivities } from "@fitnesspilot/data-activity/dist/activityFilters/ActivityFilters"
import { sortActivities } from "@fitnesspilot/data-activity/dist/activityFilters/ActivitySorting"
import * as ActivitySettingsExercise from "@fitnesspilot/data-activity/dist/activitySettings/ActivitySettingsExercise"
import { ActivitySettingsExerciseListing } from "@fitnesspilot/data-activity/dist/activitySettings/ActivitySettingsExercise"
import * as ActivityWithSettings from "@fitnesspilot/data-activity/dist/activitySettings/ActivityWithSettings"
import {
  Action,
  ParentState,
  selectors,
  setActivityListing,
  setExerciseListFilters,
  setExerciseListSearch,
  setExerciseListSorting,
} from "@fitnesspilot/data-activity/dist/store"
import * as ExerciseList from "@fitnesspilot/data-activity/dist/store/ExerciseList"
import {
  Intensity,
  validNumberAsIntensity,
} from "@fitnesspilot/data-human-body/dist/Intensity"
import { MuscleId_ } from "@fitnesspilot/data-human-body/dist/Muscle"

import { MainTemplate } from "../../templates/Main/Main"

import { useVirtualizer } from "@tanstack/react-virtual"
import mem from "memoize-one"
import { Dispatch } from "redux"

type OwnProps = {
  id: string
}

const ActivityList = styled(VirtualList)`
  max-height: 600px;
`
const ActivityListItem = VirtualListItem

const exerciseList = selectors.state.compose(selectors.exerciseList)

const mapState = (state: ParentState) => ({
  search: pipe(state, exerciseList.composeLens(ExerciseList._search).get),
  sorting: pipe(state, exerciseList.composeLens(ExerciseList._sorting).get),
  filters: pipe(state, exerciseList.composeLens(ExerciseList._filters).get),
  activities: pipe(
    state,
    selectors.state.compose(selectors.activitiesWithSettings).get,
    mem(
      flow(
        fromTraversable(
          Arr.Traversable,
        )<ActivityWithSettings.ActivityWithSettingsWithId>()
          .filter(
            flow(
              ActivityWithSettings._ActivityExerciseWithSettingsWithId
                .composeLens(
                  ActivityWithSettings.activityExerciseWithSettingsWithIdOptics
                    ._value,
                )
                .composeLens(
                  ActivityWithSettings._activityExerciseWithSettingsActivity,
                )
                .composeLens(ActivityExercise._tags).getOption,
              Opt.map(
                not(
                  St.some((tag) =>
                    Arr.elem(ActivityTag.activityTag)(tag)([
                      ActivityTag.ActivityTag.other,
                      ActivityTag.ActivityTag.sport,
                    ]),
                  ),
                ),
              ),
              Opt.getOrElse(constFalse),
            ),
          )
          .asFold().getAll,
        Arr.map(({ id, value }) =>
          pipe(
            value,
            ActivityWithSettings._Activity.get,
            (value): ActivityWithId => ({ id, value }),
          ),
        ),
      ),
    ),
  ),
  activityListings: pipe(
    state,
    selectors.state.compose(selectors.activitiesWithSettings).get,
    mem(
      flow(
        Arr.map((a) =>
          pipe(
            a,
            WithId._value<
              ActivityId.ActivityId,
              ActivityWithSettings.ActivityWithSettings
            >()
              .composeOptional(ActivityWithSettings._ActivitySettingsExercise)
              .composeLens(ActivitySettingsExercise._listing).getOption,
            Opt.map(
              (
                s,
              ): WithId.WithId<
                ActivityId.ActivityId,
                ActivitySettingsExercise.ActivitySettingsExerciseListing
              > => ({
                id: a.id,
                value: s,
              }),
            ),
          ),
        ),
        Arr.filterMap(identity),
      ),
    ),
  ),
})

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ =
    (act: Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, constVoid)

  return {
    onChangeSearch: flow(setExerciseListSearch, dispatch_),
    onChangeSorting: flow(setExerciseListSorting, dispatch_),
    onChangeFilters: flow(setExerciseListFilters, dispatch_),
    onSetActivityListing: flow(setActivityListing, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

export type ExercisesPageProps = PropsFromRedux & OwnProps

export const ExercisesPage = ({
  id,
  search,
  sorting,
  filters,
  activities,
  activityListings,
  onChangeSearch,
  onChangeSorting,
  onChangeFilters,
  onSetActivityListing,
}: ExercisesPageProps) => {
  const handleSetActivityListing =
    (activity: ActivityWithId) => (listing: ActivitySettingsExerciseListing) =>
      pipe(
        activity,
        WithId._withId<ActivityId.ActivityId, Activity>(ActivityId.activityId)
          ._value.get,
        foldActivity(
          () =>
            onSetActivityListing({
              activity: activity.id,
              listing,
            }),
          () => IO.of(undefined),
          () => IO.of(undefined),
          () => IO.of(undefined),
        ),
      )

  const intl = useIntl()

  const listRef = useRef<HTMLDivElement>(null)

  const prefilteredActivities = useMemo(
    () =>
      pipe(
        activities,
        filterActivities(activityName(intl))(search, filters, activityListings),
      ),
    [activities, search, filters, activityListings],
  )
  const filteredActivities = useMemo(
    () =>
      pipe(
        prefilteredActivities,
        sortActivities(activityName(intl))(sorting, activityListings),
      ),
    [prefilteredActivities, sorting, activityListings],
  )

  const activitiesVirtualizer = useVirtualizer({
    count: filteredActivities.length,
    getScrollElement: () => listRef.current,
    estimateSize: () => 370,
    overscan: 3,
  })
  const activitiesVirtualItems = activitiesVirtualizer.getVirtualItems()

  return (
    <MainTemplate
      header={
        <Header
          icon={<FaUser />}
          title={<FormattedMessage defaultMessage="Exercises" />}
        />
      }
    >
      <Content>
        <ActivitySearch
          id={`${id}-search`}
          {...{
            search,
            sorting,
            filters,
            onChangeSearch,
            onChangeSorting,
            onChangeFilters,
          }}
        />
        <ActivityList
          ref={listRef}
          totalSize={activitiesVirtualizer.getTotalSize()}
          start={activitiesVirtualItems.at(0)?.start ?? 0}
        >
          {pipe(
            activitiesVirtualItems,
            Arr.map(({ index }) => (
              <ActivityListItem
                key={index}
                ref={activitiesVirtualizer.measureElement}
              >
                {pipe(
                  filteredActivities,
                  Arr.lookup(index),
                  Opt.fold(
                    () => <div>`Index ${index}`</div>,
                    (activity) => (
                      <ActivityComponent
                        {...{ activity }}
                        data-help-mode="activityInstance"
                        alignment={Opt.none}
                        muscleLevels={pipe(
                          activity,
                          WithId._withId<ActivityId.ActivityId, Activity>(
                            ActivityId.activityId,
                          )
                            ._value.composePrism(_ActivityExercise)
                            .composeLens(ActivityExercise._muscleShares)
                            .getOption,
                          Opt.map(
                            flow(
                              Arr.reduce(
                                {} as Record<MuscleId_, Intensity>,
                                (r, { muscleId, intensity }) => ({
                                  ...r,
                                  [muscleId as any]: intensity,
                                }),
                              ),
                              Rec.map(validNumberAsIntensity.reverseGet),
                            ),
                          ),
                        )}
                        activityListing={pipe(
                          activityListings,
                          Arr.findFirst((s) => s.id === activity.id),
                          Opt.map((s) => s.value),
                        )}
                        overrideTitle={Opt.none}
                        onSetActivityListing={handleSetActivityListing(
                          activity,
                        )}
                      >
                        {pipe(
                          activity.value.value.instructions,
                          Opt.map((instructions) => (
                            <Markdown key="instructions">
                              {instructions}
                            </Markdown>
                          )),
                          Opt.toNullable,
                        )}
                      </ActivityComponent>
                    ),
                  ),
                )}
              </ActivityListItem>
            )),
          )}
        </ActivityList>
      </Content>
    </MainTemplate>
  )
}

export const ExercisesPageContainer = connector(ExercisesPage)

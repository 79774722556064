/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReferralCodeFromJSON, } from "../models";
import * as runtime from "../runtime";
/**
 *
 */
export class ReferralsApi extends runtime.BaseAPI {
    /**
     * Gets the user\'s referral code.
     */
    async userManagementApiUsersUserIdReferralsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling userManagementApiUsersUserIdReferralsGet.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/users/{userId}/referrals`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralCodeFromJSON(jsonValue));
    }
    /**
     * Gets the user\'s referral code.
     */
    async userManagementApiUsersUserIdReferralsGet(requestParameters, initOverrides) {
        const response = await this.userManagementApiUsersUserIdReferralsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
}

import * as Eq from "fp-ts/es6/Eq"
import * as Num from "fp-ts/es6/number"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { Lens } from "monocle-ts"
import { PositiveInteger } from "newtype-ts/es6/PositiveInteger"

import { Equipment, equipment } from "./Equipment"

export type ExerciseSet = {
  repetitions: PositiveInteger
  equipments: ReadonlyArray<Equipment>
}
export const exerciseSet: Eq.Eq<ExerciseSet> = Eq.struct({
  repetitions: Num.Eq as any,
  equipments: Arr.getEq(equipment),
})
export const _repetitions = Lens.fromProp<ExerciseSet>()("repetitions")
export const _equipments = Lens.fromProp<ExerciseSet>()("equipments")

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from "../runtime";
import { ModelSetFromJSON, ModelSetToJSON, } from "./ModelSet";
/**
 * Check if a given object implements the ActivityMax interface.
 */
export function instanceOfActivityMax(value) {
    let isInstance = true;
    isInstance = isInstance && "max" in value;
    isInstance = isInstance && "intensity" in value;
    return isInstance;
}
export function ActivityMaxFromJSON(json) {
    return ActivityMaxFromJSONTyped(json, false);
}
export function ActivityMaxFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        max: json.max,
        intensity: json.intensity,
        sets: !exists(json, "sets")
            ? undefined
            : json.sets === null
                ? null
                : json.sets.map(ModelSetFromJSON),
    };
}
export function ActivityMaxToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        max: value.max,
        intensity: value.intensity,
        sets: value.sets === undefined
            ? undefined
            : value.sets === null
                ? null
                : value.sets.map(ModelSetToJSON),
    };
}

import { ReactNode } from "react"
import { FaHeart, FaRegHeart } from "react-icons/fa"
import styled from "@emotion/styled"

import * as IO from "fp-ts/es6/IO"

import { ClickableWithIcon } from "../ClickableWithIcon/ClickableWithIcon"

export type LikeButtonProps = {
  className: string
  children: ReactNode
  isActive: boolean
  onSetActive: (isActive: boolean) => IO.IO<void>
}

export const LikeButton = styled(
  ({ className, children, isActive, onSetActive }: LikeButtonProps) => (
    <ClickableWithIcon
      icon={isActive ? <FaHeart /> : <FaRegHeart />}
      onClick={() => onSetActive(!isActive)()}
      {...{ className, children }}
    />
  ),
)`
  .c-icon {
    color: ${({ theme }) => theme.colours.primary.toString()};
  }
`

import * as Eq from "fp-ts/es6/Eq"
import * as Str from "fp-ts/es6/string"
import { Lens } from "monocle-ts"

export enum ActivitySettingsExerciseListing {
  default = "default",
  liked = "liked",
  denylisted = "denylisted",
}

export type ActivitySettingsExercise = {
  listing: ActivitySettingsExerciseListing
}
export const activitySettingsExercise: Eq.Eq<ActivitySettingsExercise> =
  Eq.struct({
    listing: Str.Eq,
  })
export const _listing = Lens.fromProp<ActivitySettingsExercise>()("listing")
export const empty = (isSport: boolean): ActivitySettingsExercise => ({
  listing: ActivitySettingsExerciseListing.default,
})

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MuscleGroupFromJSON, MuscleGroupToJSON, } from "../models";
import * as runtime from "../runtime";
/**
 *
 */
export class MuscleSettingsApi extends runtime.BaseAPI {
    /**
     * Handles GET requests for !:PreferencesController.
     */
    async activitiesApiUsersUserIdMuscleSettingsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling activitiesApiUsersUserIdMuscleSettingsGet.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activities/api/users/{userId}/muscleSettings`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MuscleGroupFromJSON));
    }
    /**
     * Handles GET requests for !:PreferencesController.
     */
    async activitiesApiUsersUserIdMuscleSettingsGet(requestParameters, initOverrides) {
        const response = await this.activitiesApiUsersUserIdMuscleSettingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Handles POST requests for !:PreferencesController.
     */
    async activitiesApiUsersUserIdMuscleSettingsPostRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling activitiesApiUsersUserIdMuscleSettingsPost.");
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters["Content-Type"] = "application/json-patch+json";
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activities/api/users/{userId}/muscleSettings`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.muscleGroup.map(MuscleGroupToJSON),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MuscleGroupFromJSON));
    }
    /**
     * Handles POST requests for !:PreferencesController.
     */
    async activitiesApiUsersUserIdMuscleSettingsPost(requestParameters, initOverrides) {
        const response = await this.activitiesApiUsersUserIdMuscleSettingsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}

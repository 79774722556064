import { HTMLAttributes, lazy, Suspense, useMemo } from "react"
import styled from "@emotion/styled"

import { pipe } from "fp-ts/es6/function"
import * as T from "fp-ts/es6/Task"
import * as TE from "fp-ts/es6/TaskEither"
import { OmitFromKnownKeys } from "@fitnesspilot/data-common"

import {
  ActivityId,
  stringAsActivityId,
} from "@fitnesspilot/data-activity/dist/activity/ActivityId"

import def from "./imgs/default.jpg"

const Img = styled.img`
  border: 1px solid hsla(0, 0%, 46.7%, 0.25);
  border-radius: 3px;
`

export type ActivityImageProps = {
  value: ActivityId
} & Omit<HTMLAttributes<HTMLImageElement>, "src">

export const ActivityImage = ({ value, className }: ActivityImageProps) => {
  const name = stringAsActivityId.reverseGet(value)

  const Img_ = useMemo(
    () =>
      pipe(
        TE.tryCatch(
          () => import(`./imgs/${name}.svg`),
          () => null,
        ) as TE.TaskEither<null, { default: string }>,
        TE.fold(
          () => T.of(def),
          (v) => pipe(v.default, T.of),
        ),
        T.map((src) => ({
          default: (props: OmitFromKnownKeys<ActivityImageProps, "value">) => (
            <Img {...props} {...{ src }} />
          ),
        })),
        lazy,
      ),
    [value],
  )

  return (
    <Suspense fallback={<Img />}>
      <Img_ {...{ className }} />
    </Suspense>
  )
}

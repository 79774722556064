import { FormGroup } from "reactstrap"

import * as Bool from "fp-ts/es6/boolean"
import { constNull, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Arr from "fp-ts/es6/ReadonlyArray"

import {
  ActivityCategory,
  activityCategory,
} from "@fitnesspilot/data-activity/dist/activity/ActivityCategory"
import {
  _sets,
  ActivityInstanceExercise,
} from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceExercise"

import { ExerciseSets } from "../../molecules/ExerciseSets/ExerciseSets"

export type ActivitySetsProps = {
  activity: ActivityInstanceExercise
  onChange: (activity: ActivityInstanceExercise) => IO.IO<void>
}
export const ActivitySets = ({ activity, onChange }: ActivitySetsProps) => (
  <>
    <FormGroup>
      {pipe(activity, (a) =>
        Bool.fold(constNull, () => (
          <ExerciseSets
            isWeightOptional={activityCategory.equals(
              a.activity.value.category,
              ActivityCategory.repsBodyWeight,
            )}
            sets={a.sets}
            onChange={(newSets) => pipe(a, _sets.set(newSets), onChange)}
          />
        ))(
          pipe(
            [ActivityCategory.repsBodyWeight, ActivityCategory.repsExtraWeight],
            Arr.elem(activityCategory)(a.activity.value.category),
          ),
        ),
      )}
    </FormGroup>
  </>
)

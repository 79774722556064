import { FC, useMemo } from "react"
import { Col } from "reactstrap"
import { Row } from "reactstrap/lib"
import styled from "@emotion/styled"

import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as St from "fp-ts/es6/ReadonlySet"

import { activityTag } from "@fitnesspilot/data-activity/dist/activity/ActivityTag"
import { equipmentType } from "@fitnesspilot/data-activity/dist/activity/Equipment"
import { ActivityFilters } from "@fitnesspilot/data-activity/dist/activityFilters/ActivityFilters"
import {
  ActivityInstanceFilters,
  foldBaseFilter,
} from "@fitnesspilot/data-activity/dist/activityFilters/ActivityInstanceFilters"
import {
  ActivityInstanceSorting,
  ActivitySorting,
  AnyActivitySorting,
} from "@fitnesspilot/data-activity/dist/activityFilters/ActivitySorting"
import { numberAsAlignmentUnsafe } from "@fitnesspilot/data-human-body/dist/Alignment"
import { muscleGroupId } from "@fitnesspilot/data-human-body/dist/muscleGroups"

import {
  ActivityFilterList,
  ActivityFilterListProps,
} from "../ActivityFilterList/ActivityFilterList"
import {
  ActivityInstanceSearchInput,
  ActivitySearchInput,
  ActivitySearchInputProps,
} from "../ActivitySearchInput/ActivitySearchInput"
import {
  ActivityInstanceSortingInput,
  ActivitySortingInput,
  ActivitySortingInputProps,
} from "../ActivitySortingInput/ActivitySortingInput"

export const addFilter = <
  a extends ActivityFilters,
  b extends keyof ActivityFilters,
>(
  filters: a,
  filter: b,
  value: a[b],
): a => ({
  ...filters,
  tags:
    filter === "tags"
      ? St.union(activityTag)(filters.tags, value as ActivityFilters["tags"])
      : filters.equipments,
  equipments:
    filter === "equipments"
      ? St.union(equipmentType)(
          filters.equipments,
          value as ActivityFilters["equipments"],
        )
      : filters.equipments,
  muscleGroups:
    filter === "muscleGroups"
      ? St.union(muscleGroupId)(
          filters.muscleGroups,
          value as ActivityFilters["muscleGroups"],
        )
      : filters.muscleGroups,
  onlyLiked: filter === "onlyLiked" ? true : filters.onlyLiked,
})

export const addInstanceFilter = <a extends keyof ActivityInstanceFilters>(
  filters: ActivityInstanceFilters,
  filter: a,
  value: ActivityInstanceFilters[a],
): ActivityInstanceFilters => ({
  ...foldBaseFilter<a, keyof ActivityFilters, ActivityInstanceFilters>(
    () => filters,
    (filter, value) => addFilter(filters, filter, value),
  )(filter, value),
  minAlignment:
    filter === "minAlignment"
      ? (value as ActivityInstanceFilters["minAlignment"])
      : filters.minAlignment,
})

export const removeFilter = <
  a extends ActivityFilters,
  b extends keyof ActivityFilters,
>(
  filters: a,
  filter: b,
  value: a[b],
): a => ({
  ...filters,
  tags:
    filter === "tags"
      ? pipe(
          filters.tags,
          St.filter(
            (tag) =>
              !St.elem(activityTag)(
                tag,
                value as ActivityInstanceFilters["tags"],
              ),
          ),
        )
      : filters.equipments,
  equipments:
    filter === "equipments"
      ? pipe(
          filters.equipments,
          St.filter(
            (eq) =>
              !St.elem(equipmentType)(
                eq,
                value as ActivityInstanceFilters["equipments"],
              ),
          ),
        )
      : filters.equipments,
  muscleGroups:
    filter === "muscleGroups"
      ? pipe(
          filters.muscleGroups,
          St.filter(
            (mg) =>
              !St.elem(muscleGroupId)(
                mg,
                value as ActivityInstanceFilters["muscleGroups"],
              ),
          ),
        )
      : filters.muscleGroups,
  onlyLiked: filter === "onlyLiked" ? false : filters.onlyLiked,
})

export const removeInstanceFilter = <a extends keyof ActivityInstanceFilters>(
  filters: ActivityInstanceFilters,
  filter: a,
  value: ActivityInstanceFilters[a],
): ActivityInstanceFilters => ({
  ...foldBaseFilter<a, keyof ActivityFilters, ActivityInstanceFilters>(
    () => filters,
    (filter, value) => removeFilter(filters, filter, value),
  )(filter, value),
  minAlignment:
    filter === "minAlignment"
      ? numberAsAlignmentUnsafe(0)
      : filters.minAlignment,
})

type ActivitySearchBaseProps<
  filters extends ActivityFilters,
  sorting extends AnyActivitySorting,
> = {
  id: string
  search: Opt.Option<string>
  sorting: sorting
  filters: filters
  onChangeSearch: ActivitySearchInputProps<filters>["onChange"]
  onChangeSorting: (sorting: sorting) => IO.IO<void>
}

export type ActivitySearchBase2Props<
  filters extends ActivityFilters,
  sorting extends AnyActivitySorting,
> = ActivitySearchBaseProps<filters, sorting> & {
  searchInput: FC<ActivitySearchInputProps<filters>>
  sortingInput: FC<ActivitySortingInputProps<sorting>>
  onSelectFilter: ActivitySearchInputProps<filters>["onSelectFilter"]
  onDeleteFilter: ActivityFilterListProps<filters>["onDelete"]
}

export type ActivitySearchProps<
  filters extends ActivityFilters,
  sorting extends AnyActivitySorting,
> = ActivitySearchBaseProps<filters, sorting> & {
  onChangeFilters: (filters: filters) => IO.IO<void>
}

const activitySearch =
  <filters extends ActivityFilters, sorting extends AnyActivitySorting>(): FC<
    ActivitySearchBase2Props<filters, sorting>
  > =>
  ({
    id,
    searchInput: SearchInput,
    sortingInput: SortingInput,
    search,
    sorting,
    filters,
    onChangeSearch,
    onChangeSorting,
    onSelectFilter,
    onDeleteFilter,
  }) => (
    <div>
      <Row>
        <Col>
          <SearchInput
            id={`${id}-input`}
            data-help-mode="filter"
            value={search}
            onChange={onChangeSearch}
            {...{ filters, onSelectFilter }}
          />
        </Col>
        <Col data-help-mode="sort" xs={5} md={4} lg={3} xl={2}>
          <SortingInput value={sorting} onChange={onChangeSorting} />
        </Col>
      </Row>
      <ActivityFilterList {...{ filters }} onDelete={onDeleteFilter} />
    </div>
  )

const ActivitySearch_ = activitySearch<ActivityFilters, ActivitySorting>()
export const ActivitySearch: FC<
  ActivitySearchProps<ActivityFilters, ActivitySorting>
> = ({ filters, onChangeFilters, ...props }) => (
  <ActivitySearch_
    searchInput={ActivitySearchInput}
    sortingInput={ActivitySortingInput}
    {...{ filters }}
    {...props}
    onSelectFilter={(filter, value) =>
      onChangeFilters(addFilter(filters, filter, value))
    }
    onDeleteFilter={(filter, value) =>
      onChangeFilters(removeFilter(filters, filter, value))
    }
  />
)

const ActivityInstanceSearch_ = activitySearch<
  ActivityInstanceFilters,
  ActivityInstanceSorting
>()
export const ActivityInstanceSearch: FC<
  ActivitySearchProps<ActivityInstanceFilters, ActivityInstanceSorting>
> = ({ filters, onChangeFilters, ...props }) => (
  <ActivityInstanceSearch_
    searchInput={ActivityInstanceSearchInput}
    sortingInput={ActivityInstanceSortingInput}
    {...{ filters }}
    {...props}
    onSelectFilter={(filter, value) =>
      onChangeFilters(addInstanceFilter(filters, filter, value))
    }
    onDeleteFilter={(filter, value) =>
      onChangeFilters(removeInstanceFilter(filters, filter, value))
    }
  />
)

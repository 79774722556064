import { FC, Fragment } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { flow } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import { Prism } from "monocle-ts"
/* import styled from "@emotion/styled" */
import { Day, isoStringAsDay } from "time-ts/es6"

import { FormAnswersProvided } from "@fitnesspilot/components-common/dist/atoms/AnswersProvided/AnswersProvided"
import { FieldsetHeader } from "@fitnesspilot/components-common/dist/atoms/FieldsetHeader/FieldsetHeader"
import {
  ControllerPrism,
  formGroupRender,
  optionPrism,
  selectRender,
  textRender,
  wrapPrismOpt,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"
import { Sex } from "@fitnesspilot/data-human-body/dist/sex"

export type FormData = {
  sex: Opt.Option<Sex>
  birthDate: Opt.Option<Day>
}
const FormDataKeys: Rec.ReadonlyRecord<keyof FormData, null> = {
  sex: null,
  birthDate: null,
}

export type SexAndBirthDateFieldsetProps = {
  id: string
  showAnswersProvided?: boolean
}

export const SexAndBirthDateFieldset: FC<SexAndBirthDateFieldsetProps> = ({
  id,
  showAnswersProvided,
}) => {
  const intl = useIntl()

  return (
    <Fragment>
      <FieldsetHeader
        title={<FormattedMessage defaultMessage="General" />}
        titleRight={
          showAnswersProvided && (
            <FormAnswersProvided<FormData> keys={FormDataKeys} />
          )
        }
        description={
          <FormattedMessage defaultMessage="Many processes in your body are related to age and sex; it is highly suggested to supply these data as they are necessary for many calculations." />
        }
      />

      <ControllerPrism<FormData, "sex", Sex | "">
        render={formGroupRender(selectRender<Sex | "", "radio">())({
          id: `${id}-sex`,
          type: "radio",
          label: <FormattedMessage defaultMessage="Sex" />,
          values: {
            [Sex.female]: intl.formatMessage({
              defaultMessage: "Female",
            }),
            [Sex.male]: intl.formatMessage({
              defaultMessage: "Male",
            }),
          },
        })}
        name="sex"
        prism={optionPrism<Sex | "">()}
        prismError={intl.formatMessage({
          defaultMessage: "is invalid",
        })}
      />

      <ControllerPrism<FormData, "birthDate">
        render={formGroupRender(textRender)({
          id: `${id}-birthDate`,
          label: <FormattedMessage defaultMessage="Birthday" />,
          type: "date",
          required: false,
        })}
        name="birthDate"
        prism={
          new Prism(
            flow(isoStringAsDay.getOption, Opt.map(Opt.some)),
            flow(
              Opt.map(isoStringAsDay.reverseGet),
              Opt.getOrElse(() => ""),
              (s) => s.replace(/^\+00/, ""),
            ),
          )
        }
        prismError={intl.formatMessage({
          defaultMessage: "is invalid",
        })}
      />
    </Fragment>
  )
}

import { FC, HTMLAttributes, ReactNode } from "react"
import styled from "@emotion/styled"

import bg from "./imgs/bg.png"

const Background = styled.div`
  background: url(${bg}) no-repeat 50% / cover fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 0;

  @media (min-width: 768px) {
    padding-top: 10px;
  }
`

const Contents = styled.div`
  height: 100%;
  max-width: 1420px;
  width: 100%;

  @media ${({ theme }) => theme.media.lg} {
    border-radius: 5px;
  }
`

export type AppWrapperProps = {
  children: ReactNode
} & HTMLAttributes<HTMLDivElement>

export const AppWrapper: FC<AppWrapperProps> = ({ children, ...props }) => (
  <Background {...props}>
    <Contents>{children}</Contents>
  </Background>
)

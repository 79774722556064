import { Lens } from "monocle-ts"

import { CookieConsentState, ParentState, State, stateKey } from "./state"

export const state = Lens.fromProp<ParentState>()(stateKey)

export const cookieConsent = Lens.fromProp<State>()("cookieConsent")

export const showCookieConsent = cookieConsent.compose(
  Lens.fromProp<CookieConsentState>()("show"),
)
// export const showCookieConsent = Lens.fromPath<State>()([
//   "cookieConsent",
//   "show",
// ])
export const cookies = Lens.fromPath<State>()(["cookieConsent", "cookies"])
export const acceptedCookies = Lens.fromPath<State>()([
  "cookieConsent",
  "acceptedCookies",
])
export const checkedCookies = Lens.fromPath<State>()([
  "cookieConsent",
  "checkedCookies",
])
export const expandedCookieCategories = Lens.fromPath<State>()([
  "cookieConsent",
  "expanedCategories",
])

import { FC, Fragment } from "react"
import { FaPlus, FaShare } from "react-icons/fa"
import { FormattedMessage, IntlShape, useIntl } from "react-intl"
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
} from "reactstrap"
import styled from "@emotion/styled"

import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { ShareData } from "@fitnesspilot/data-common"

import { Label } from "@fitnesspilot/components-common/dist/atoms/Label/Label"
import {
  ButtonWithIcon,
  IconSide,
} from "@fitnesspilot/components-common/dist/molecules/ButtonWithIcon/ButtonWithIcon"
import { InviteCode } from "@fitnesspilot/data-user/dist/InviteCode"

const ColCentered = styled(Col)`
  align-self: center;
`

export type InviteFriendsFieldsetProps = {
  id: string
  inviteCodes: ReadonlyArray<InviteCode>
  onShare: (share: ShareData) => IO.IO<void>
  onAddCode: IO.IO<void>
}

const handleShare = (
  intl: IntlShape,
  code: string,
  callback: (share: ShareData) => IO.IO<void>,
) => {
  const title = intl.formatMessage({
    defaultMessage: "Fitnesspilot Invite",
  })
  const text = intl.formatMessage({
    defaultMessage: "Sign up exclusively for the Fitnesspilot Closed Beta",
  })
  const url = `https://${location.hostname}/?inviteCode=${encodeURIComponent(
    code,
  )}`

  return callback({ title, text, url })()
}

export const InviteFriendsFieldset: FC<InviteFriendsFieldsetProps> = ({
  id,
  inviteCodes,
  onShare,
  onAddCode,
}) => {
  const intl = useIntl()

  return (
    <>
      <h2>
        <FormattedMessage defaultMessage="Invite friends (for admins)" />
      </h2>

      <Fragment>
        {pipe(
          inviteCodes,
          Arr.mapWithIndex((i, inviteCode) => (
            <FormGroup row key={inviteCode.code}>
              <Label for={`${id}-importActivities`} xs={5} md={3}>
                {i === 0 && (
                  <FormattedMessage defaultMessage="Your invite codes" />
                )}
              </Label>

              <Col xs={7} md={7}>
                <InputGroup>
                  <Input
                    id={`${id}-referralCode`}
                    type="text"
                    value={inviteCode.code}
                    readOnly
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      onClick={() =>
                        handleShare(intl, inviteCode.code, onShare)
                      }
                    >
                      <FaShare />
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </Col>

              <ColCentered
                xs={{ offset: 5, size: 7 }}
                md={{ offset: 0, size: 2 }}
              >
                {pipe(
                  inviteCode.usedBy,
                  Opt.fold(
                    () => null,
                    () => <FormattedMessage defaultMessage="Used" />,
                  ),
                )}
              </ColCentered>
            </FormGroup>
          )),
        )}

        <FormGroup>
          <ButtonWithIcon
            icon={<FaPlus />}
            iconSide={IconSide.left}
            color="primary"
            onClick={onAddCode}
          >
            <FormattedMessage defaultMessage="Generate new code" />
          </ButtonWithIcon>
        </FormGroup>
      </Fragment>
    </>
  )
}

import * as Eq from "fp-ts/es6/Eq"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import { Lens } from "monocle-ts"
import { LocalTimeOfDay, localTimeOfDay } from "time-ts/es6"

import * as DayOfWeek from "@fitnesspilot/data-common/dist/DayOfWeek"

export type Sleep = {
  time: Rec.ReadonlyRecord<
    DayOfWeek.DayOfWeek,
    readonly [LocalTimeOfDay, LocalTimeOfDay]
  >
}
export const sleep: Eq.Eq<Sleep> = Eq.struct({
  time: Rec.getEq(Eq.tuple(localTimeOfDay, localTimeOfDay)),
})
export const _time = Lens.fromProp<Sleep>()("time")

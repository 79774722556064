import { FC, ReactNode } from "react"
import { ThemeProvider } from "@emotion/react"
import styled from "@emotion/styled"

import * as Bool from "fp-ts/es6/boolean"
import { constant, pipe } from "fp-ts/es6/function"
import * as Monoid from "fp-ts/es6/Monoid"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as Str from "fp-ts/es6/string"

import { Button } from "../../atoms/Button/Button"
import { Card, CardProps, Position } from "../../atoms/Card/Card"
import { CheckboxStatus, toggleStatus } from "../../atoms/Checkbox/Checkbox"
import { Clickable } from "../../atoms/Clickable/Clickable"
import {
  Theme,
  theme as defaultTheme,
} from "../../atoms/ThemeProvider/ThemeProvider"
import { CookieCategoryInfo } from "../../molecules/CookieCategoryInfo/CookieCategoryInfo"
import {
  Cookie,
  CookieCategory,
  CookieInfo,
  Enum,
} from "../../molecules/CookieInfo/CookieInfo"

const monoidAnyAll = Monoid.tuple(Bool.MonoidAny, Bool.MonoidAll)

const foldCheckboxStatus =
  <A,>(onUnchecked: () => A, onIndeterminate: () => A, onChecked: () => A) =>
  ([any, all]: readonly [boolean, boolean]) =>
    !any && !all ? onUnchecked() : any && !all ? onIndeterminate() : onChecked()

const checkedStatus =
  <T extends Enum>(checked: ReadonlyArray<T>) =>
  (cookies: NonEmpty.ReadonlyNonEmptyArray<Cookie<T>>) =>
    pipe(
      cookies,
      NonEmpty.map((c) => c.category === "necessary" || checked.includes(c.id)),
      NonEmpty.foldMap(monoidAnyAll)((c) => [c, c]),
      foldCheckboxStatus<CheckboxStatus>(
        constant("unchecked"),
        constant("indeterminate"),
        constant("checked"),
      ),
    )

const List = styled.ul`
  margin: 10px 0;
`

const ActionButton = styled(Button)`
  background-color: ${({ theme }) => theme.button.colour.toString()};
  color: ${({ theme }) => theme.button.backgroundColour.toString()};
  &:hover {
    color: ${({ theme }) => theme.button.colour.toString()};
  }
`

const StyledCard: FC<CardProps> = styled(Card)`
  font-size: ${({ theme }) => theme.fontSize};
  width: 300px;
  transition: width 0.3s;

  .c-cookieCategoryInfo {
    color: ${({ theme }) => theme.textColour.toString()};
  }
`

const Intro = styled.p`
  margin-top: 0;
  font-size: ${({ theme }) => theme.fontSize};
  color: ${({ theme }) => theme.textColour.toString()};
`

const DismissButton = styled(Clickable)`
  font-size: 18px;
  float: right;
  outline: none;
  border: none;
  background: none;
  color: ${({ theme }) => theme.button.colour.toString()};

  &:hover {
    border-radius: 16px;
    background-color: ${({ theme }) =>
      theme.card.backgroundColour.lighten(0.1).toString()};
  }
`

const ActionSection = styled.section`
  border-top: 1px solid
    ${({ theme }) => theme.card.backgroundColour.darken(0.1).toString()};
  margin-top: 5px;
  padding-top: 10px;

  .c-cookieButton + .c-button {
    margin-left: 6px;
  }
`

export type CookieConsentProps<T extends string | number> = {
  theme?: Theme
  position?: Position
  text?: Partial<Record<"prompt" | "accept" | "acceptAll", ReactNode>>
  cookies: NonEmpty.ReadonlyNonEmptyArray<Cookie<T>>
  checkedCookies: ReadonlyArray<T>
  expandedCategories: ReadonlyArray<CookieCategory>
  onToggle: (category: CookieCategory) => void
  onChange: (cookieIds: ReadonlyArray<T>, checked: boolean) => void
  onAccept: (cookieIds: ReadonlyArray<T>) => void
  onDismiss: () => void
}

export const CookieConsent = <T extends Enum>({
  position,
  text,
  cookies,
  checkedCookies,
  expandedCategories,
  onToggle,
  onChange,
  onAccept,
  onDismiss,
  theme,
}: CookieConsentProps<T>) => (
  <ThemeProvider theme={theme ?? defaultTheme}>
    <StyledCard position={position}>
      <DismissButton onClick={onDismiss}>×</DismissButton>
      <Intro>
        {text?.prompt ??
          "We are using cookies to improve your browsing experience and our products."}
      </Intro>
      {pipe(
        cookies,
        NonEmpty.groupBy((c) => c.category),
        Rec.collect(Str.Ord)((category: CookieCategory, cookies) => (
          <CookieCategoryInfo
            key={category}
            className="category-info"
            category={category}
            cookies={cookies}
            checked={checkedStatus(checkedCookies)(cookies)}
            expanded={expandedCategories.includes(category)}
            onToggle={() => onToggle(category)}
            onChange={() =>
              onChange(
                NonEmpty.Functor.map(cookies, (c) => c.id),
                pipe(
                  cookies,
                  checkedStatus(checkedCookies),
                  toggleStatus,
                  (s) => s === "checked",
                ),
              )
            }
          >
            <List>
              {pipe(
                cookies,
                NonEmpty.map((c) => (
                  <li key={c.id}>
                    <CookieInfo className={"cookie-info"} cookie={c} />
                  </li>
                )),
              )}
            </List>
          </CookieCategoryInfo>
        )),
      )}
      <ActionSection>
        <ActionButton
          onClick={() => onAccept(NonEmpty.Functor.map(cookies, (c) => c.id))}
        >
          {text?.acceptAll ?? "Allow all"}
        </ActionButton>
        <Button
          onClick={() =>
            pipe(
              cookies,
              Arr.filter((c) => checkedCookies.includes(c.id)),
              Arr.map((c) => c.id),
              onAccept,
            )
          }
        >
          {text?.accept ?? "Allow selection"}
        </Button>
      </ActionSection>
    </StyledCard>
  </ThemeProvider>
)

import { FC, Fragment, ReactNode } from "react"
import { FaCheck } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"
import styled from "@emotion/styled"

import * as E from "fp-ts/es6/Either"
import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Arr from "fp-ts/es6/ReadonlyArray"

import { CancelButton } from "@fitnesspilot/components-common/dist/molecules/CancelButton/CancelButton"
import { CoachTaskImportance } from "@fitnesspilot/data-coach-task/dist/CoachTask"

export type CoachTaskAction = {
  label: ReactNode
} & (
  | {
      onClick: IO.IO<void>
    }
  | {
      link: string
    }
)

export type CoachTaskProps = {
  importance: CoachTaskImportance
  label: ReactNode
  actions: ReadonlyArray<CoachTaskAction>
  onDismiss: IO.IO<void>
  className?: string
}

const CheckIcon = styled(FaCheck)`
  color: ${({ theme }) => theme.colours.success.toString()};
`

export const CoachTask_ = ({
  label,
  actions,
  onDismiss,
  className,
}: CoachTaskProps) => (
  <div className={className}>
    <Row>
      <Col xs="auto">
        <img src="https://placekitten.com/60/60" />
      </Col>

      <Col>
        <Row>
          <Col>{label}</Col>
        </Row>

        <Row>
          <Col>
            {pipe(
              actions,
              Arr.map((action) => (
                <Fragment key={action.label?.toString()}>
                  <Button
                    outline
                    color="success"
                    {...("link" in action
                      ? {
                          tag: Link,
                          to: action.link,
                        }
                      : {
                          onClick: action.onClick,
                        })}
                  >
                    <CheckIcon /> {action.label}
                  </Button>{" "}
                </Fragment>
              )),
            )}
            <CancelButton type="button" onClick={onDismiss}>
              <FormattedMessage defaultMessage="Dismiss" />
            </CancelButton>
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
)

export const CoachTask = styled(CoachTask_)`
  border-left: 5px solid
    ${({ importance, theme }: any) =>
      importance === CoachTaskImportance.important
        ? theme.colours.danger.toString()
        : theme.colours.warning.toString()};
  padding-left: 3px;
`

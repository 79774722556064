/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import { ActivityMuscleInfoFromJSON, ActivityMuscleInfoToJSON, } from "./ActivityMuscleInfo";
import { ModelSetFromJSON, ModelSetToJSON, } from "./ModelSet";
/**
 * Check if a given object implements the ActivityExecution interface.
 */
export function instanceOfActivityExecution(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "eventTime" in value;
    isInstance = isInstance && "alignment" in value;
    isInstance = isInstance && "activityId" in value;
    isInstance = isInstance && "intensity" in value;
    return isInstance;
}
export function ActivityExecutionFromJSON(json) {
    return ActivityExecutionFromJSONTyped(json, false);
}
export function ActivityExecutionFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: json.id,
        eventTime: new Date(json.eventTime),
        alignment: json.alignment,
        muscles: !exists(json, "muscles")
            ? undefined
            : json.muscles === null
                ? null
                : mapValues(json.muscles, ActivityMuscleInfoFromJSON),
        activityId: json.activityId,
        duration: !exists(json, "duration") ? undefined : json.duration,
        distance: !exists(json, "distance") ? undefined : json.distance,
        sets: !exists(json, "sets")
            ? undefined
            : json.sets === null
                ? null
                : json.sets.map(ModelSetFromJSON),
        muscleGroupIds: !exists(json, "muscleGroupIds")
            ? undefined
            : json.muscleGroupIds,
        intensity: json.intensity,
    };
}
export function ActivityExecutionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        eventTime: value.eventTime.toISOString(),
        alignment: value.alignment,
        muscles: value.muscles === undefined
            ? undefined
            : value.muscles === null
                ? null
                : mapValues(value.muscles, ActivityMuscleInfoToJSON),
        activityId: value.activityId,
        duration: value.duration,
        distance: value.distance,
        sets: value.sets === undefined
            ? undefined
            : value.sets === null
                ? null
                : value.sets.map(ModelSetToJSON),
        muscleGroupIds: value.muscleGroupIds,
        intensity: value.intensity,
    };
}

import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import { isoStringAsDuration, mkLocalTimeOfDay } from "time-ts/es6"
import {
  Dimension,
  ParentState as CommonParentState,
  UnitLengthDistance,
  UnitLengthHeight,
  UnitMass,
  UnitSelection,
  unsafeFromSome,
} from "@fitnesspilot/data-common"

import { DayOfWeek } from "@fitnesspilot/data-common/dist/DayOfWeek"
import { SumBodyComposition } from "@fitnesspilot/data-human-body/dist/bodyComposition"
import { HumanBody } from "@fitnesspilot/data-human-body/dist/humanBody"

import { AboutMeAnswers } from "../AboutMeAnswers"
import { GoogleFit } from "../GoogleFit"
import {
  ActivityHabits,
  ConsumptionLevel,
  ConsumptionType,
  DrinkingHabits,
  EatingHabits,
  Habits,
} from "../Habits"
import { InviteCode } from "../InviteCode"
import { defaultJob, Job } from "../Job"
import { Photo } from "../photo"
import { ReferralCode } from "../ReferralCode"
import { Sleep } from "../Sleep"
import { User } from "../User"
import { UserClaims } from "../UserClaims"
import { defaultWork, Work } from "../Work"

export const stateKey = "user"

export type State = {
  isLoggedIn: Opt.Option<boolean>
  userClaims: Opt.Option<UserClaims>
  user: Opt.Option<User>
  work: Work
  sleep: Sleep
  habits: Habits
  body: HumanBody
  unitSelection: UnitSelection
  editingPhoto: Opt.Option<Photo>
  openPhoto: Opt.Option<Photo>
  photos: ReadonlyArray<Photo>
  profilePhoto: Opt.Option<Photo>
  userNavIsOpen: boolean
  fitnessLevel: {
    selected: Opt.Option<SumBodyComposition>
  }
  referralCode: Opt.Option<ReferralCode>
  inviteCodes: ReadonlyArray<InviteCode>
  isDeleteAccountConfirmationShown: boolean
  aboutMeAnswers: Opt.Option<AboutMeAnswers>
  jobs: NonEmpty.ReadonlyNonEmptyArray<Job>
  fcmToken: Opt.Option<string>
  googleFit: Opt.Option<GoogleFit>
}
export const initialState: State = {
  isLoggedIn: Opt.none,
  userClaims: Opt.none,
  user: Opt.none,
  work: defaultWork,
  sleep: {
    time: pipe(
      DayOfWeek,
      Rec.map(() => [
        pipe(mkLocalTimeOfDay(23, 0, 0), unsafeFromSome),
        pipe(mkLocalTimeOfDay(7, 0, 0), unsafeFromSome),
      ]),
    ),
  },
  habits: {
    activity: ActivityHabits.moderate,
    eating: EatingHabits.untilNotHungry,
    drinking: DrinkingHabits.insufficient,
    consumption: {
      [ConsumptionType.alcohol]: ConsumptionLevel.occasionally,
      [ConsumptionType.smoking]: ConsumptionLevel.never,
    },
  },
  body: {
    birthDate: Opt.none,
    bodyComposition: Opt.none,
    height: Opt.none,
    mass: Opt.none,
    sex: Opt.none,
  },
  unitSelection: {
    [Dimension.mass]: UnitMass.kilogram,
    [Dimension.lengthHeight]: UnitLengthHeight.centimetre,
    [Dimension.lengthDistance]: UnitLengthDistance.kilometre,
  },
  editingPhoto: Opt.none,
  openPhoto: Opt.none,
  photos: [],
  profilePhoto: Opt.none,
  userNavIsOpen: false,
  fitnessLevel: {
    selected: Opt.none,
  },
  referralCode: Opt.none,
  inviteCodes: [],
  isDeleteAccountConfirmationShown: false,
  aboutMeAnswers: Opt.none,
  jobs: [defaultJob],
  fcmToken: Opt.none,
  googleFit: Opt.none,
}

export type ParentState = CommonParentState & { [k in typeof stateKey]: State }

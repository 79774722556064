import { flow, pipe } from "fp-ts/es6/function"
import * as Func from "fp-ts/es6/function"
import * as Mon from "fp-ts/es6/Monoid"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Str from "fp-ts/es6/string"
import { optionLensToOptional } from "@fitnesspilot/data-common"

import * as GoogleApi from "../GoogleApi"
import { Photo, photo } from "../photo"
import { _googleApi, _googleApiIsEnabled, _googleApiScopes } from "../User"
import * as actions from "./actions"
import { Action } from "./actions"
import * as selectors from "./selectors"
import { initialState, State } from "./state"

import { createReducer } from "typesafe-actions"

const replaceEle =
  <a>(p: (a: a) => (b: a) => boolean) =>
  (a: a) =>
  (as: ReadonlyArray<a>) =>
    pipe(
      as,
      Arr.findIndex(p(a)),
      Opt.fold<number, ReadonlyArray<a>>(
        () => as,
        (i) =>
          Mon.fold(Arr.getMonoid<a>())(
            Arr.map((f: (v: ReadonlyArray<a>) => ReadonlyArray<a>) => f(as))([
              Arr.takeLeft(i),
              Func.constant([a]),
              Arr.dropLeft(i + 1),
            ]),
          ),
      ),
    )

// const handleShare = Opt.fold(
//   () => identity,
//   sharePhotoAsync => reducer =>
//     reducer.handleAction(sharePhotoAsync, (state, { payload }) => state),
// )(actions.sharePhotoAsync)

export default createReducer<State, Action>(initialState)
  .handleAction(actions.resetState, () =>
    pipe(initialState, selectors.isLoggedIn.set(Opt.some(false))),
  )
  .handleAction(actions.setUserClaims, (state, { payload }) =>
    pipe(state, selectors.userClaims.set(payload)),
  )
  // .handleAction(actions.signupAsync.success, (state) =>
  //   selectors.isLoggedIn.set(Opt.some(true))(state),
  // )
  // .handleAction(actions.signupAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  // .handleAction(actions.submitSetupAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(actions.loginAsync.success, (state) =>
    selectors.isLoggedIn.set(Opt.some(true))(state),
  )
  // .handleAction(actions.loginAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(actions.logoutAsync.success, (state) =>
    pipe(state, selectors.user.set(Opt.none)),
  )
  // .handleAction(actions.logoutAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(actions.fetchUserAsync.success, (state, { payload: user }) =>
    selectors.user.set(Opt.some(user))(state),
  )
  // .handleAction(actions.fetchUserAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(
    actions.fetchUserDataAsync.success,
    (state, { payload: { body, work, sleep, habits } }) =>
      pipe(
        state,
        selectors.body.set(body),
        pipe(
          work,
          Opt.getOrElse(() => selectors.work.get(initialState)),
          selectors.work.set,
        ),
        pipe(
          sleep,
          Opt.getOrElse(() => selectors.sleep.get(initialState)),
          selectors.sleep.set,
        ),
        selectors.habits.set(habits),
      ),
  )
  // .handleAction(actions.fetchUserAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(
    actions.fetchAboutMeAnswersAsync.success,
    (state, { payload: aboutMeAnswers }) =>
      selectors.aboutMeAnswers.set(Opt.some(aboutMeAnswers))(state),
  )
  // .handleAction(actions.fetchAboutMeAnswersAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(
    actions.fetchUnitSelectionAsync.success,
    (state, { payload: unitSelection }) =>
      selectors.unitSelection.set(unitSelection)(state),
  )
  // .handleAction(actions.fetchUnitSelectionAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(
    actions.setUnitSelectionLocal,
    (state, { payload: unitSelection }) =>
      selectors.unitSelection.set(unitSelection)(state),
  )
  .handleAction(
    actions.setUnitSelectionAsync.success,
    (state, { payload: unitSelection }) =>
      selectors.unitSelection.set(unitSelection)(state),
  )
  // .handleAction(actions.setUnitSelectionAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(actions.openPhoto, (state, { payload }) =>
    selectors.openPhoto.set(Opt.some(payload))(state),
  )
  .handleAction(actions.closePhoto, (state) =>
    flow(
      selectors.openPhoto.set(Opt.none),
      selectors.editingPhoto.set(Opt.none),
    )(state),
  )
  .handleAction(actions.editPhoto, (state, { payload }) =>
    flow(
      selectors.editingPhoto.set(Opt.some(payload)),
      selectors.openPhoto.set(Opt.some(payload)),
    )(state),
  )
  .handleAction(actions.changePhoto, (state, { payload }) =>
    selectors.editingPhoto.set(Opt.some(payload))(state),
  )
  .handleAction(
    actions.fetchPhotosAsync.success,
    (state, { payload: { photos, profilePhoto } }) =>
      pipe(
        state,
        selectors.photos.set(Arr.sort(photo)(photos)),
        selectors.profilePhoto.set(profilePhoto),
      ),
  )
  // .handleAction(actions.fetchPhotosAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(actions.uploadPhotosAsync.success, (state, { payload }) =>
    selectors.photos.modify((ps) =>
      Arr.sort(photo)(Arr.getMonoid<Photo>().concat(payload, ps)),
    )(state),
  )
  // .handleAction(actions.uploadPhotosAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(actions.setProfilePhotoAsync.success, (state, { payload }) =>
    selectors.profilePhoto.set(payload)(state),
  )
  // .handleAction(actions.setProfilePhotoAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  /** The changes were already made in changePhoto, so we only need to stop editing */
  .handleAction(actions.savePhotoAsync.success, (state, { payload }) =>
    flow(
      selectors.photos.modify(
        flow(
          replaceEle<Photo>((a) => (b) => a.id === b.id)(payload),
          Arr.sort(photo),
        ),
      ),
      selectors.editingPhoto.set(Opt.none),
      selectors.openPhoto.set(Opt.none),
    )(state),
  )
  // .handleAction(actions.savePhotoAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(actions.selectFitnessLevel, (state, { payload }) =>
    flow(
      selectors.fitnessLevel.modify((v) => ({
        ...v,
        selected: Opt.some(payload),
      })),
    )(state),
  )
  .handleAction(actions.showDeleteAccountConfirmation, (state) =>
    flow(selectors.isDeleteAccountConfirmationShown.set(true))(state),
  )
  .handleAction(actions.deleteAccountAsync.success, () =>
    pipe(initialState, selectors.isLoggedIn.set(Opt.some(false))),
  )
  // .handleAction(actions.deleteAccountAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(
    actions.fetchJobsAsync.success,
    (state, { payload: { jobs } }) => pipe(state, selectors.jobs.set(jobs)),
  )
  // .handleAction(actions.fetchJobsAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(actions.sendFcmTokenAsync.success, (state, { payload }) =>
    flow(selectors.fcmToken.set(Opt.some(payload)))(state),
  )
  // .handleAction(actions.sendFcmTokenAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  // .handleAction(actions.shareReferralCode.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  // .handleAction(actions.connectGoogleApiAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(actions.sendGoogleAuthCodeAsync.success, (state) =>
    flow(
      optionLensToOptional(selectors.user)
        .composeLens(_googleApi)
        .set({
          isEnabled: true,
          scopes: pipe(
            Arr.getSemigroup<string>().concat(
              pipe(
                state,
                optionLensToOptional(selectors.user).composeLens(
                  _googleApiScopes,
                ).getOption,
                Opt.getOrElse<ReadonlyArray<string>>(() => []),
              ),
              GoogleApi.requiredScopes.googleFit,
            ),
            Arr.uniq(Str.Eq),
          ),
        }),
    )(state),
  )
  // .handleAction(actions.setGoogleApiAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(actions.fetchGoogleFitAsync.success, (state, { payload }) =>
    flow(selectors.googleFit.set(payload))(state),
  )
  // .handleAction(actions.fetchGoogleFitAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(actions.setGoogleFitAsync.success, (state, { payload }) =>
    flow(selectors.googleFit.set(payload))(state),
  )
  // .handleAction(actions.setGoogleFitAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(actions.fetchReferralCodeAsync.success, (state, { payload }) =>
    flow(selectors.referralCode.set(Opt.some(payload)))(state),
  )
  // .handleAction(actions.fetchReferralCodeAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(actions.fetchInviteCodesAsync.success, (state, { payload }) =>
    flow(selectors.inviteCodes.set(payload))(state),
  )
  // .handleAction(actions.fetchInviteCodesAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(actions.addInviteCodeAsync.success, (state, { payload }) =>
    flow(selectors.inviteCodes.modify((cs) => [...cs, payload]))(state),
  )
// .handleAction(actions.addInviteCodeAsync.failure, (state, { payload }) =>
//   selectors.error.set(payload)(state),
// )
/** Not quite sure how to handle share (as it's an Option) */

import { HTMLAttributes } from "react"
import styled from "@emotion/styled"

export enum Dir {
  down = "down",
  left = "left",
  right = "right",
  up = "up",
}

export const isVertical = (dir: Dir) => [Dir.down, Dir.up].includes(dir)

export type ArrowProps = {
  /**
   * Direction into which the arrow is facing
   */
  dir: Dir
} & HTMLAttributes<HTMLDivElement>

export const Arrow = styled(({ dir, ...props }: ArrowProps) => (
  <div {...props} />
))`
  display: flex;
  flex-direction: ${({ dir }: ArrowProps) =>
    ({
      [Dir.down]: "column",
      [Dir.left]: "row-reverse",
      [Dir.right]: "row",
      [Dir.up]: "column-reverse",
    })[dir]};
  align-items: center;
  color: ${({ theme }) => theme.colours.primary.toString()};

  &::before {
    content: "";
    ${({ dir }: ArrowProps) => (isVertical(dir) ? "width" : "height")}: 0.1em;
    background: currentColor;
    flex-grow: 1;
  }

  &::after {
    content: "";
    width: 0;
    height: 0;
    ${({ dir }: ArrowProps) =>
      isVertical(dir)
        ? `
          border-left: 0.25em solid transparent;
          border-right: 0.25em solid transparent;
        `
        : `
          border-top: 0.25em solid transparent;
          border-bottom: 0.25em solid transparent;
        `}

    border-${({ dir }: ArrowProps) =>
      ({
        [Dir.down]: "top",
        [Dir.left]: "right",
        [Dir.right]: "left",
        [Dir.up]: "bottom",
      })[dir]}: calc((0.25em + 0.25em) * 0.8660254) solid currentColor;
  }
`

import { Lens } from "monocle-ts"

export enum MySportsTab {
  sports = "sports",
  statistics = "statistics",
  preferences = "preferences",
}

export type MySports = {
  currentTab: MySportsTab
}

export const _currentTab = Lens.fromProp<MySports>()("currentTab")

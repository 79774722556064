import { FC } from "react"
import { connect, ConnectedProps } from "react-redux"

import { pipe } from "fp-ts/es6/function"
import * as Func from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import { OmitFromKnownKeys } from "@fitnesspilot/data-common"

import {
  Action,
  close,
  ParentState,
  selectors,
  showSteps,
  StepMode,
} from "@fitnesspilot/data-help"

import {
  HelpButton as HelpButton_,
  HelpButtonProps as HelpButtonProps_,
} from "../../molecules/HelpButton/HelpButton"

import { Dispatch } from "redux"

const mapState = (state: ParentState) => ({
  active: pipe(
    state,
    selectors.state.composeLens(selectors.step).get,
    Opt.isSome,
  ),
})

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ =
    (act: Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, Func.constVoid)

  return {
    showSteps: pipe(showSteps(), dispatch_),
    close: pipe(close(), dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

export type HelpButtonContainerProps = OmitFromKnownKeys<
  HelpButtonProps_,
  keyof PropsFromRedux
>

export type HelpButtonProps = PropsFromRedux & HelpButtonContainerProps

export const HelpButton: FC<HelpButtonProps> = ({
  showSteps,
  close,
  ...props
}) => <HelpButton_ {...props} onStart={showSteps} onStop={close} />

export const HelpButtonContainer = connector(HelpButton)

import * as Eq from "fp-ts/es6/Eq"
import { constTrue, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Str from "fp-ts/es6/string"
import { Getter } from "monocle-ts"
import { iso, Newtype, prism } from "newtype-ts"

import {
  ActivityInstance,
  activityInstance,
} from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"
import { unwrap } from "@fitnesspilot/data-common/dist/type"

import { Video, video } from "./Video"

export type WorkoutVideoId = Newtype<
  { readonly EventId: unique symbol },
  string
>
const isoWorkoutVideoId = iso<WorkoutVideoId>()
export const stringAsWorkoutVideoId = prism<WorkoutVideoId>(constTrue)
export const workoutVideoIdFromVideo = new Getter<Video, WorkoutVideoId>((a) =>
  pipe(`${a.source}:${unwrap(a.id)}`, isoWorkoutVideoId.wrap),
)
export const workoutVideoId: Ord.Ord<WorkoutVideoId> = Str.Ord as any

export type WorkoutVideo = {
  id: WorkoutVideoId
  title: Opt.Option<string>
  video: Video
  activities: ReadonlyArray<ActivityInstance>
}
export const workoutVideo = Eq.struct({
  id: workoutVideoId,
  title: Opt.getEq(Str.Eq),
  video,
  activities: Arr.getEq(activityInstance),
})

import { FC } from "react"
import { IntlShape, useIntl } from "react-intl"

import { formatDynamicMessage } from "@fitnesspilot/components-common/dist/atoms/IntlProvider/IntlProvider"
import { MuscleGroupId } from "@fitnesspilot/data-human-body/dist/muscleGroups"

const names = {
  [MuscleGroupId.abdomen]: "Abdomen",
  [MuscleGroupId.bicepsBrachii]: "Biceps",
  [MuscleGroupId.cardiovascular]: "Cardiovascular system",
  [MuscleGroupId.dorsalThorax]: "Back",
  [MuscleGroupId.forearmAndHand]: "Forearm",
  [MuscleGroupId.hipAndEverythingInferiorToIt]: "Legs",
  [MuscleGroupId.shoulder]: "Shoulder",
  [MuscleGroupId.tricepsBrachii]: "Triceps",
  [MuscleGroupId.ventralThorax]: "Chest",
}

export type MuscleGroupNameProps = {
  id: MuscleGroupId
}

export const muscleGroupName =
  (intl: IntlShape) =>
  ({ id }: MuscleGroupNameProps) =>
    formatDynamicMessage(intl)({
      id: `MuscleGroupName.${id}`,
      defaultMessage: names[id],
    })

export const MuscleGroupName: FC<MuscleGroupNameProps> = (props) => (
  <>{muscleGroupName(useIntl())(props)}</>
)

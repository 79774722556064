import { ReactElement } from "react"
import { renderToStaticMarkup } from "react-dom/server"
import { css } from "@emotion/react"

/**
 * Sets the css content property to a react-icon.
 *
 * @param icon provide a react-icon
 **/
export const cssContentIcon = (icon: ReactElement) => {
  const dataIcon = renderToStaticMarkup(icon).replace(/"/g, "'")
  return css`
    content: url("data:image/svg+xml; utf8,${dataIcon}");
  `
}

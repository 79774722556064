import { Option } from "fp-ts/es6/Option"

import { Token } from "../auth"

import { ActionType, createAction } from "typesafe-actions"

export const setToken = createAction(
  "api/setToken",
  (token: Option<Token>) => token,
)()

export const setAccountReady = createAction(
  "api/setAccountReady",
  (hasCompletedSetup: boolean) => hasCompletedSetup,
)()

export type Action = ActionType<typeof setToken | typeof setAccountReady>

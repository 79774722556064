import { FC, memo, useCallback, useMemo, useRef, useState } from "react"

import { match } from "ts-pattern"
import { unwrap } from "@fitnesspilot/data-common"

import { Video } from "@fitnesspilot/data-event/dist/video/Video"
import { VideoSource } from "@fitnesspilot/data-event/dist/video/VideoSource"

export type VideoPlayerProps = {
  video: Video
  width: number
  height: number
}

export const VideoPlayer: FC<VideoPlayerProps> = ({ video, width, height }) =>
  match(video)
    .with({ source: VideoSource.youtube }, ({ id }) => (
      <iframe
        {...{ width, height }}
        src={`https://www.youtube.com/embed/${unwrap(id)}`}
        title="YouTube video player"
        frameBorder={0}
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    ))
    .with({ source: VideoSource.vimeo }, ({ id }) => (
      <iframe
        {...{ width, height }}
        src={`https://player.vimeo.com/video/${unwrap(id)}?title=0&byline=0`}
        frameBorder={0}
        allow="fullscreen; picture-in-picture"
        allowFullScreen
      />
    ))
    .exhaustive()

import { FC } from "react"

import * as Func from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import { Prism } from "monocle-ts"

import { unboundedInput, UnboundedInputProps } from "./input"

export type StringInputProps = UnboundedInputProps<string>

export const StringInput: FC<StringInputProps> = unboundedInput<string>(
  "text",
  new Prism<string, string>(Opt.some, Func.identity),
)

import { forwardRef, useState } from "react"
import { FaHeart } from "react-icons/fa"
import { FormattedMessage, useIntl } from "react-intl"
import { keyframes, withTheme } from "@emotion/react"
import styled from "@emotion/styled"

import {
  Popover,
  PopoverBody,
  PopoverHeader,
} from "@fitnesspilot/components-common/dist/atoms/Popover/Popover"
import { Theme } from "@fitnesspilot/components-common/dist/atoms/ThemeProvider/ThemeProvider"
import { Dorsoventral } from "@fitnesspilot/data-human-body/dist/anatomicalAxes"
import {
  muscleGroupIdAsMuscleGroups,
  MuscleGroupsByDorsoventralSide,
  VentralMuscleGroups,
} from "@fitnesspilot/data-human-body/dist/muscleGroups"

import {
  MuscleGroupName,
  muscleGroupName,
} from "../MuscleGroupName/MuscleGroupName"

const pulsate = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  75% {
    transform: scale(2);
  }
  100% {
    opacity: 0;
  }
`

const AnimatedG = styled.g`
  animation: ${pulsate} 2s infinite;
`

type Side<a extends Dorsoventral> = {
  dorsoventralSide: a
  muscleGroup: MuscleGroupsByDorsoventralSide[a]
}

export type MuscleGroupIconProps = {
  animate?: boolean
  level?: number | false
  popoverIsOpen?: boolean
  togglePopover: () => void
} & (Side<Dorsoventral.dorsal> | Side<Dorsoventral.ventral>)

export const MuscleGroupIcon = withTheme(
  forwardRef<SVGGElement, MuscleGroupIconProps & { theme: Theme }>(
    (
      {
        animate = true,
        dorsoventralSide,
        muscleGroup,
        level,
        popoverIsOpen = false,
        togglePopover,
        theme,
      },
      ref,
    ) => {
      // local state to prevent ref leaking
      const [target, setTarget] = useState<SVGGElement | null>(null)
      const fill = (
        level === false
          ? theme.colours.grey[300]
          : level == null
          ? theme.colours.primary
          : level < 0.5
          ? theme.colours.red[550].mix(theme.colours.yellow[450], level / 0.5)
          : theme.colours.yellow[450].mix(
              theme.colours.green[600],
              (level - 0.5) / 0.5,
            )
      ).toString()

      const intl = useIntl()

      return (
        <g
          ref={(ele: SVGGElement | null) => {
            setTarget(ele)
            if (typeof ref === "function") {
              ref(ele)
            } else if (ref != null) {
              ref.current = ele
            }
          }}
          aria-label={muscleGroupName(intl)({
            id: muscleGroupIdAsMuscleGroups.reverseGet(muscleGroup),
          })}
          stroke="none"
          {...{ fill }}
          className={`MuscleGroupIcon-${dorsoventralSide}-${muscleGroup}`}
        >
          <circle cx={0} cy={0} r={9.664} fill="transparent" />
          {dorsoventralSide === Dorsoventral.ventral &&
          muscleGroup === VentralMuscleGroups.cardiovascular
            ? [
                animate && !popoverIsOpen && (
                  <AnimatedG key={0}>
                    {/* <svg> doesn't inherit fill */}
                    <FaHeart
                      size={9.664}
                      x={-4.832}
                      y={-4.832 + 0.5}
                      {...{ fill }}
                    />
                  </AnimatedG>
                ),
                /* Apparently svg[transform] isn't supported */
                <g key={1} transform={popoverIsOpen ? "scale(2)" : undefined}>
                  {/* <svg> doesn't inherit fill */}
                  <FaHeart
                    size={9.664}
                    x={-4.832}
                    y={-4.832 * 0.9}
                    {...{ fill }}
                  />
                </g>,
              ]
            : [
                animate && !popoverIsOpen && (
                  <AnimatedG key={0}>
                    <circle cx={0} cy={0} r={4.832} />
                  </AnimatedG>
                ),
                <circle
                  key={1}
                  cx={0}
                  cy={0}
                  r={4.832}
                  transform={popoverIsOpen ? "scale(2)" : undefined}
                />,
              ]}
          {target && (
            <Popover
              placement="bottom"
              isOpen={popoverIsOpen}
              toggle={togglePopover}
              {...{ target: target as any as HTMLElement }}
            >
              <PopoverHeader>
                <MuscleGroupName
                  id={muscleGroupIdAsMuscleGroups.reverseGet(muscleGroup)}
                />
              </PopoverHeader>

              {level != null && (
                <PopoverBody>
                  {level === false ? (
                    <FormattedMessage defaultMessage="This muscle group is disabled." />
                  ) : (
                    <FormattedMessage
                      defaultMessage="{level, number, percent} ready"
                      values={{ level }}
                    />
                  )}
                </PopoverBody>
              )}
            </Popover>
          )}
        </g>
      )
    },
  ),
)

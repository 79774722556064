import * as Ord from "fp-ts/es6/Ord"
import * as Str from "fp-ts/es6/string"
import { Newtype, prism } from "newtype-ts"

export type EmailAddress = Newtype<
  { readonly EmailAddress: unique symbol },
  string
>
export const emailAddress: Ord.Ord<EmailAddress> = Str.Ord as any
// @TODO
const isEmailAddress = (n: any): n is EmailAddress => typeof n === "string"
export const stringAsEmailAddress = prism<EmailAddress>(isEmailAddress)

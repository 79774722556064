import { FC } from "react"
import { FormattedMessage } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import { withTheme } from "@emotion/react"

import { constVoid, flow, pipe } from "fp-ts/es6/function"
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReadonlyNonEmptyArray } from "fp-ts/es6/ReadonlyNonEmptyArray"

import { Theme } from "@fitnesspilot/components-common/dist/atoms/ThemeProvider/ThemeProvider"
import {
  CookieConsent,
  Theme as CookieConsentTheme,
} from "@fitnesspilot/components-cookie-consent"
import {
  acceptCookieConsent,
  Action,
  hideCookieConsent,
  ParentState,
  selectors,
  setCookiesChecked,
  toggleCookieCategory,
} from "@fitnesspilot/data-legal"

/* eslint-enable @typescript-eslint/no-unused-vars */
import { Dispatch } from "redux"

const mapState = (state: ParentState) => ({
  show: pipe(state, selectors.state.compose(selectors.showCookieConsent).get),
  cookies: pipe(state, selectors.state.compose(selectors.cookies).get),
  checkedCookies: pipe(
    state,
    selectors.state.compose(selectors.checkedCookies).get,
  ),
  expandedCategories: pipe(
    state,
    selectors.state.compose(selectors.expandedCookieCategories).get,
  ),
})

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ = flow(dispatch, constVoid)

  return {
    onChange: flow(setCookiesChecked, dispatch_),
    onToggle: flow(toggleCookieCategory, dispatch_),
    onDismiss: flow(hideCookieConsent, dispatch_),
    onAccept: flow(acceptCookieConsent, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

const getTheme = ({ colours }: Theme): CookieConsentTheme => ({
  fontSize: "16px",
  textColour: colours.white,
  card: {
    backgroundColour: colours.primary,
    customCss: `
      z-index: 999;
    `,
  },
  button: {
    backgroundColour: colours.primary,
    colour: colours.white,
  },
})

type PropsFromRedux = ConnectedProps<typeof connector>

type OwnProps = {
  show: boolean
}

export type CookieConsentContainerProps = PropsFromRedux & OwnProps

const CookieConsentContainer_: FC<CookieConsentContainerProps> = withTheme<
  FC<CookieConsentContainerProps & { theme: Theme }>
>(({ theme, show, ...props }) =>
  show ? (
    <FormattedMessage defaultMessage="We are using cookies to improve your browsing experience and our products.">
      {(prompt) => (
        <FormattedMessage defaultMessage="Allow selection">
          {(accept) => (
            <FormattedMessage defaultMessage="Allow all">
              {(acceptAll) => (
                <CookieConsent
                  theme={getTheme(theme)}
                  position={["bottom", "left"]}
                  text={{ prompt, accept, acceptAll }}
                  {...props}
                />
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>
      )}
    </FormattedMessage>
  ) : null,
)

export const CookieConsentContainer = connector(CookieConsentContainer_)

import { FC, Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { Col, FormGroup } from "reactstrap"

import { pipe } from "fp-ts/es6/function"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"

import { Label } from "@fitnesspilot/components-common/dist/atoms/Label/Label"
import { enumRange } from "@fitnesspilot/components-common/dist/molecules/EnumRange/EnumRange"
import {
  CombinedFitnessLevel,
  CombinedFitnessLevelParts,
  FitnessLevel as FitnessLevel_,
} from "@fitnesspilot/data-human-body/dist/fitnessLevel"

import { CombinedFitnessLevelPartLabel } from "../../atoms/CombinedFitnessLevelPartLabel/CombinedFitnessLevelPartLabel"

const EnumRangeFitnessLevel = enumRange(
  [FitnessLevel_.low, FitnessLevel_.medium, FitnessLevel_.high],
  [
    <FormattedMessage key={0} defaultMessage="Low" />,
    <FormattedMessage key={1} defaultMessage="Medium" />,
    <FormattedMessage key={2} defaultMessage="High" />,
  ],
)

export type FitnessLevelRangeProps = {
  id: string
  value?: CombinedFitnessLevel
  onChange: (v: CombinedFitnessLevel) => void
}

export const FitnessLevelRange: FC<FitnessLevelRangeProps> = ({
  id,
  value = { bodyFat: FitnessLevel_.medium, musculature: FitnessLevel_.medium },
  onChange,
}) => (
  <Fragment>
    {pipe(
      Object.values(CombinedFitnessLevelParts),
      Arr.map((key) => (
        <FormGroup key={key} row>
          <Label for={`${id}-musculature`} xs={4}>
            <CombinedFitnessLevelPartLabel combinedFitnessLevelPart={key} />
          </Label>

          <Col auto>
            <EnumRangeFitnessLevel
              value={value[key]}
              onChange={(v) =>
                pipe(
                  value,
                  Rec.upsertAt(key, v),
                  (a) => a as CombinedFitnessLevel,
                  onChange,
                )
              }
            />
          </Col>
        </FormGroup>
      )),
    )}
  </Fragment>
)

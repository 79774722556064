import { Lens } from "monocle-ts"

import { ParentState, State, stateKey } from "./state"

export const state = Lens.fromProp<ParentState>()(stateKey)

export const step = Lens.fromProp<State>()("step")

export const showIndex = Lens.fromProp<State>()("showIndex")

export const status = Lens.fromProp<State>()("status")

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from "../runtime";
import { BodyTypeFromJSON, BodyTypeToJSON, } from "./BodyType";
/**
 * Check if a given object implements the BodyData interface.
 */
export function instanceOfBodyData(value) {
    const isInstance = true;
    return isInstance;
}
export function BodyDataFromJSON(json) {
    return BodyDataFromJSONTyped(json, false);
}
export function BodyDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        height: !exists(json, "height") ? undefined : json.height,
        mass: !exists(json, "mass") ? undefined : json.mass,
        fatShare: !exists(json, "fatShare") ? undefined : json.fatShare,
        waterShare: !exists(json, "waterShare") ? undefined : json.waterShare,
        fatFreeShare: !exists(json, "fatFreeShare") ? undefined : json.fatFreeShare,
        bodyType: !exists(json, "bodyType")
            ? undefined
            : BodyTypeFromJSON(json.bodyType),
    };
}
export function BodyDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        height: value.height,
        mass: value.mass,
        fatShare: value.fatShare,
        waterShare: value.waterShare,
        fatFreeShare: value.fatFreeShare,
        bodyType: BodyTypeToJSON(value.bodyType),
    };
}

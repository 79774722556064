import * as Eq from "fp-ts/es6/Eq"
import * as Num from "fp-ts/es6/number"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/Record"
import { Lens } from "monocle-ts"
import { Duration, duration } from "time-ts/es6"
import {
  Dimension,
  validNumber,
  ValueWithCanonicalUnit,
  valueWithCanonicalUnit,
} from "@fitnesspilot/data-common"

import {
  Alignment,
  alignment,
  numberAsAlignmentUnsafe,
} from "@fitnesspilot/data-human-body/dist/Alignment"
import {
  Intensity,
  intensity,
} from "@fitnesspilot/data-human-body/dist/Intensity"
import { MuscleId_ } from "@fitnesspilot/data-human-body/dist/Muscle"

import {
  ActivityExercise,
  activityExercise,
} from "../activity/ActivityExercise"
import { ActivityId, activityId } from "../activity/ActivityId"
import {
  ActivityMuscleInfo,
  activityMuscleInfo,
} from "../activity/ActivityMuscleInfo"
import { ExerciseSet, exerciseSet } from "../activity/ExerciseSet"
import { WithId, withIdEq } from "../activity/WithId"

export type ActivityInstanceExercise = {
  activity: WithId<ActivityId, ActivityExercise>
  alignment: Alignment
  distance: Opt.Option<ValueWithCanonicalUnit<Dimension.lengthDistance>>
  duration: Opt.Option<Duration>
  importanceFactor: Opt.Option<number>
  intensity: Opt.Option<Intensity>
  sets: ReadonlyArray<ExerciseSet>
  muscles: Record<MuscleId_, ActivityMuscleInfo>
}
export const activityInstanceExercise: Eq.Eq<ActivityInstanceExercise> =
  Eq.struct({
    activity: withIdEq(activityId, activityExercise),
    alignment: alignment,
    distance: Opt.getEq(
      valueWithCanonicalUnit(Dimension.lengthDistance, validNumber),
    ),
    duration: Opt.getEq(duration),
    importanceFactor: Opt.getEq(Num.Eq),
    intensity: Opt.getEq(intensity),
    sets: Arr.getEq(exerciseSet),
    muscles: Rec.getEq(activityMuscleInfo),
  })
export const fromActivityExercise = (
  activity: WithId<ActivityId, ActivityExercise>,
): ActivityInstanceExercise => ({
  activity,
  alignment: numberAsAlignmentUnsafe(100),
  distance: Opt.none,
  duration: Opt.none,
  importanceFactor: Opt.none,
  intensity: Opt.none,
  sets: Arr.empty,
  muscles: {} as Record<string, never>,
})
export const _activity = Lens.fromProp<ActivityInstanceExercise>()("activity")
export const _alignment = Lens.fromProp<ActivityInstanceExercise>()("alignment")
export const _distance = Lens.fromProp<ActivityInstanceExercise>()("distance")
export const _duration = Lens.fromProp<ActivityInstanceExercise>()("duration")
export const _importanceFactor =
  Lens.fromProp<ActivityInstanceExercise>()("importanceFactor")
export const _intensity = Lens.fromProp<ActivityInstanceExercise>()("intensity")
export const _sets = Lens.fromProp<ActivityInstanceExercise>()("sets")
export const _muscles = Lens.fromProp<ActivityInstanceExercise>()("muscles")

import { FC, Fragment, useEffect } from "react"
import { useFormContext, useWatch } from "react-hook-form"
/* import styled from "@emotion/styled" */
import { FormattedMessage } from "react-intl"

import * as Opt from "fp-ts/es6/Option"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import { useStateIO } from "@fitnesspilot/data-common"

import { FormAnswersProvided } from "@fitnesspilot/components-common/dist/atoms/AnswersProvided/AnswersProvided"
import { FieldsetHeader } from "@fitnesspilot/components-common/dist/atoms/FieldsetHeader/FieldsetHeader"
import {
  ControllerPlus,
  noTransform,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"
import { SumBodyComposition } from "@fitnesspilot/data-human-body/dist/bodyComposition"

import { renderBodyComposition } from "../BodyCompositionInput/BodyCompositionInput"

export type FormData = {
  bodyComposition: Opt.Option<SumBodyComposition>
}
const FormDataKeys: Rec.ReadonlyRecord<keyof FormData, null> = {
  bodyComposition: null,
}

export type BodyCompositionFieldsetProps = {
  id: string
  showAnswersProvided?: boolean
}

export const BodyCompositionFieldset: FC<BodyCompositionFieldsetProps> = ({
  id,
  showAnswersProvided,
}) => {
  const [modalIsOpen, setModalIsOpen] = useStateIO(() => false)()

  const form = useFormContext<FormData>()
  const bodyComposition = useWatch({
    control: form.control,
    name: "bodyComposition",
  })
  useEffect(() => setModalIsOpen(false), [bodyComposition])

  return (
    <Fragment>
      <FieldsetHeader
        title={<FormattedMessage defaultMessage="Detailed weight breakdown" />}
        titleRight={
          showAnswersProvided && (
            <FormAnswersProvided<FormData> keys={FormDataKeys} />
          )
        }
        description={
          <FormattedMessage defaultMessage="Would you like to get more out of this app? Our intelligent algorithms get more accurate with more individual data. And of course we'll keep it private and only for your goal progress." />
        }
      />

      <ControllerPlus<
        FormData,
        "bodyComposition",
        Opt.Option<SumBodyComposition>
      >
        render={renderBodyComposition({
          id: `${id}-bodyComposition`,
          modalIsOpen,
          onModalToggle: setModalIsOpen,
        })}
        name="bodyComposition"
        transform={noTransform<FormData, "bodyComposition">()}
      />
    </Fragment>
  )
}

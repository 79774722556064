import { pipe } from "fp-ts/es6/function"
import * as Arr from "fp-ts/es6/ReadonlyArray"

import { ordCookieId } from "../cookieConsent"
import * as actions from "./actions"
import { Action } from "./actions"
import * as selectors from "./selectors"
import { initialState, State } from "./state"

import { createReducer } from "typesafe-actions"

export default createReducer<State, Action>(initialState)
  .handleAction(actions.showCookieConsent, (state) =>
    selectors.showCookieConsent.set(true)(state),
  )
  .handleAction(actions.hideCookieConsent, (state) =>
    selectors.showCookieConsent.set(false)(state),
  )
  .handleAction(actions.acceptCookieConsent, (state, { payload }) =>
    selectors.acceptedCookies.set(payload)(state),
  )
  .handleAction(actions.toggleCookieCategory, (state, { payload }) =>
    selectors.expandedCookieCategories.modify((cs) =>
      cs.includes(payload)
        ? cs.filter((c) => c !== payload)
        : Arr.cons(payload, cs),
    )(state),
  )
  .handleAction(
    actions.setCookiesChecked,
    (state, { payload: { cookieIds, checked } }) =>
      selectors.checkedCookies.modify((cs) =>
        checked
          ? pipe([...cs, ...cookieIds], Arr.uniq(ordCookieId))
          : pipe(
              cookieIds,
              Arr.filter((c) => !cookieIds.includes(c)),
            ),
      )(state),
  )

import { ReactNode, useEffect, useState } from "react"
import { DeepPartial, UnpackNestedValue, useFormContext } from "react-hook-form"
import styled from "@emotion/styled"

import { constVoid, flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as Str from "fp-ts/es6/string"
import { unsafeFromSome } from "@fitnesspilot/data-common"

import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import { MuscleId_, muscleId_ } from "@fitnesspilot/data-human-body/dist/Muscle"
import {
  MuscleGroupId,
  muscleIdsOfGroup,
} from "@fitnesspilot/data-human-body/dist/muscleGroups"
import {
  numberAsPriority,
  Priority,
} from "@fitnesspilot/data-human-body/dist/Priority"

import { MuscleGroupPreference } from "../MuscleGroupPreference/MuscleGroupPreference"
import {
  FormData,
  formData,
  MuscleFormData,
  muscleFormData,
  MusclePreferenceFieldset,
} from "../MusclePreferenceFieldset/MusclePreferenceFieldset"

const FieldsetGroup = styled(Fieldset)`
  border-bottom: 1px solid ${({ theme }) => theme.colours.grey[100].toString()};
`

export { FormData, formData }

export const getGroupSettings =
  (muscleIds: ReadonlyArray<MuscleId_>) =>
  (values: UnpackNestedValue<DeepPartial<FormData>>): MuscleFormData =>
    pipe(
      values,
      Rec.filterMap(Opt.fromNullable),
      Rec.filterWithIndex((id: MuscleId_) =>
        pipe(muscleIds, Arr.elem(muscleId_)(id)),
      ),
      Rec.reduce(Str.Ord)(
        { enabled: false, priorities: [] as ReadonlyArray<Priority> },
        (result, v) => ({
          enabled: result.enabled || (v.enabled ?? false),
          priorities: [
            ...result.priorities,
            (v.priority as Priority | undefined) ??
              pipe(5, numberAsPriority.getOption, unsafeFromSome),
          ],
        }),
      ),
      ({ enabled, priorities }) => ({
        enabled,
        priority: pipe(
          priorities,
          Arr.map(numberAsPriority.reverseGet),
          Arr.reduce(0, (a, b) => a + b),
          (sum) => Math.round(sum / priorities.length),
          numberAsPriority.getOption,
          Opt.getOrElse(() =>
            pipe(0, numberAsPriority.getOption, unsafeFromSome),
          ),
        ),
      }),
    )

export type MusclePreferencesFieldsetsProps = {
  id: string
  className?: string
  muscleGroupId: MuscleGroupId
  showGroupOnly?: boolean
  groupLevel?: number | false
  groupRight?: ReactNode
}

export const MusclePreferencesFieldsets = ({
  id,
  muscleGroupId,
  className,
  showGroupOnly,
  groupLevel,
  groupRight,
}: MusclePreferencesFieldsetsProps) => {
  const [groupEnabled, setGroupEnabled] = useState(false)
  const [groupPriority, setGroupPriority] = useState(
    pipe(1, numberAsPriority.getOption, unsafeFromSome),
  )

  const muscleIds = muscleIdsOfGroup(muscleGroupId)

  const form = useFormContext<FormData>()

  const updateGroup = flow(
    getGroupSettings(muscleIds),
    ({ enabled, priority }) => {
      setGroupEnabled(enabled)
      setGroupPriority(priority)
    },
  )

  useEffect(() => {
    updateGroup(form.getValues())
  }, [muscleGroupId])

  form.watch(updateGroup)

  const handleGroupEnabled: (v: boolean) => IO.IO<void> = flow((enabled) =>
    pipe(
      form.getValues(),
      Rec.filterWithIndex((id) => pipe(muscleIds, Arr.elem(muscleId_)(id))),
      Rec.traverseWithIndex(IO.Applicative)(
        (i: MuscleId_, muscle) => () =>
          form.setValue(i, {
            ...muscle,
            enabled,
          }),
      ),
      IO.map(constVoid),
    ),
  )

  const handleGroupPriority: (v: Priority) => IO.IO<void> = flow((priority) =>
    pipe(
      form.getValues(),
      Rec.filterWithIndex((id) => pipe(muscleIds, Arr.elem(muscleId_)(id))),
      Rec.traverseWithIndex(IO.Applicative)(
        (i: MuscleId_, muscle) => () =>
          form.setValue(i, {
            ...muscle,
            priority: pipe(
              (muscle.priority as any as number) +
                ((priority as any as number) -
                  (groupPriority as any as number)),
              (v) => (v <= 10 ? (v >= 0 ? v : 0) : 10),
              numberAsPriority.getOption,
              Opt.getOrElse(() =>
                pipe(5, numberAsPriority.getOption, unsafeFromSome),
              ),
            ),
          }),
      ),
      IO.map(constVoid),
    ),
  )

  return (
    <div {...{ className }}>
      <FieldsetGroup>
        <MuscleGroupPreference
          id={`${id}-group`}
          {...{ muscleGroupId }}
          enabled={groupEnabled}
          level={groupLevel}
          priority={groupPriority}
          onSetEnabled={handleGroupEnabled}
          onSetPriority={handleGroupPriority}
          right={groupRight}
        />
      </FieldsetGroup>

      {!showGroupOnly &&
        pipe(
          muscleGroupId,
          muscleIdsOfGroup,
          Arr.map((muscleId) => (
            <Fieldset key={muscleId} border={false}>
              <MusclePreferenceFieldset
                id={`${id}-${muscleId}`}
                muscleId={muscleId}
              />
            </Fieldset>
          )),
        )}
    </div>
  )
}

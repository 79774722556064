import * as Eq from "fp-ts/es6/Eq"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Str from "fp-ts/es6/string"
import { Lens, Optional } from "monocle-ts"

import * as ActivityInstanceFilters from "@fitnesspilot/data-activity/dist/activityFilters/ActivityInstanceFilters"
import {
  ActivityInstanceSorting,
  activityInstanceSorting,
} from "@fitnesspilot/data-activity/dist/activityFilters/ActivitySorting"

import { WorkoutVideo, workoutVideo } from "../video/WorkoutVideo"

export enum AddActivityStep {
  searchVideo = "searchVideo",
  activityType = "activityType",
  muscleGroups = "muscleGroups",
  activities = "activities",
  activitySelection = "activitySelection",
}
export const addActivityStep: Eq.Eq<AddActivityStep> = Str.Eq

export type AddActivity = {
  step: AddActivityStep
  search: Opt.Option<string>
  sorting: ActivityInstanceSorting
  filters: ActivityInstanceFilters.ActivityInstanceFilters
  videos: ReadonlyArray<WorkoutVideo>
}
export const addActivity: Eq.Eq<AddActivity> = Eq.struct({
  step: addActivityStep,
  search: Opt.getEq(Str.Eq),
  sorting: activityInstanceSorting,
  filters: ActivityInstanceFilters.activityInstanceFilters,
  videos: Arr.getEq(workoutVideo),
})
export const _step = Lens.fromProp<AddActivity>()("step")
export const _search = Lens.fromProp<AddActivity>()("search")
export const _searchOpt = Optional.fromOptionProp<AddActivity>()("search")
export const _sorting = Lens.fromProp<AddActivity>()("sorting")
export const _filters = Lens.fromProp<AddActivity>()("filters")
export const _videos = Lens.fromProp<AddActivity>()("videos")

export const empty: AddActivity = {
  step: AddActivityStep.activities,
  filters: ActivityInstanceFilters.empty,
  search: Opt.none,
  sorting: ActivityInstanceSorting.default,
  videos: Arr.empty,
}

import * as Arr_ from "fp-ts/es6/Array"
import * as Bool from "fp-ts/es6/boolean"
import { flow, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as T from "fp-ts/es6/Task"
import * as TE from "fp-ts/es6/TaskEither"
import * as Tree from "fp-ts/es6/Tree"
import * as O from "fp-ts-reactive/es6/Observable"
import { fromTraversable } from "monocle-ts"
import { arrayToObservable, withLatestFrom } from "@fitnesspilot/data-common"

import * as API from "@fitnesspilot/api-combined"
import api, {
  Action as APIAction,
  AnyAPIError,
  ParentState as APIParentState,
  setAccountReady,
  setToken,
} from "@fitnesspilot/data-api"
import {
  muscleId_,
  muscleId_FromString,
} from "@fitnesspilot/data-human-body/dist/Muscle"
import * as MuscleGroup from "@fitnesspilot/data-human-body/dist/muscleGroups"

import * as ActivityId from "../activity/ActivityId"
import * as WithId from "../activity/WithId"
import * as ActivitySettings from "../activitySettings/ActivitySettings"
import * as ActivitySettingsExercise from "../activitySettings/ActivitySettingsExercise"
import * as MuscleSettings from "../sport/MuscleSettings"
import * as Sport from "../sport/Sport"
import { stringAsSportId } from "../sport/SportId"
import * as actions from "./actions"
import { Action } from "./actions"
import * as apiToState from "./api"
import * as selectors from "./selectors"
import { ParentState } from "./state"

import { AnyAction } from "redux"
import { combineEpics, Epic } from "redux-observable"
import { isActionOf } from "typesafe-actions"

type ParentAPIState = ParentState & APIParentState

type EpicWithAPI = Epic<
  Action | APIAction | AnyAction,
  Action | APIAction,
  ParentAPIState
>
type EpicWithoutAPI = Epic<
  Action | APIAction | AnyAction,
  Action,
  ParentAPIState
>

export const setTokenFlow: EpicWithAPI = (
  action$,
  // state$,
) =>
  pipe(
    action$,
    O.filter(isActionOf(setToken)),
    O.map((a) => a.payload),
    O.map(
      Opt.fold<any, ReadonlyArray<Action>>(
        flow(actions.resetState, Arr.of),
        () => [],
      ),
    ),
    O.chain(arrayToObservable),
  )

export const setAccountReadyFlow: EpicWithAPI = (
  action$,
  // state$,
) =>
  pipe(
    action$,
    O.filter(isActionOf(setAccountReady)),
    O.map((a) => a.payload),
    O.map(
      Bool.fold(
        () => [],
        () => [
          actions.fetchMuscleSettings(),
          actions.fetchActivitiesWithSettings(),
          actions.fetchSports(),
          actions.fetchGeneralSportsSettings(),
        ],
      ),
    ),
    O.chain(arrayToObservable),
  )

const optToEmptyArr = <a>(a: ReadonlyArray<a> | null | undefined) =>
  pipe(
    a,
    Opt.fromNullable,
    Opt.getOrElse<ReadonlyArray<a>>(() => []),
  )

export const fetchMuscleSettingsFlow: EpicWithoutAPI = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.fetchMuscleSettingsAsync.request)),
    withLatestFrom(O.map(api)(state$)),
    O.chain(([, api]) =>
      pipe(
        api.activities.getMuscleSettings("me"),
        TE.fold<AnyAPIError, ReadonlyArray<API.MuscleGroup>, Action>(
          flow(actions.fetchMuscleSettingsAsync.failure, T.of),
          flow(
            apiToState.muscleSettingsTree.get,
            actions.fetchMuscleSettingsAsync.success,
            T.of,
          ),
        ),
        O.fromTask,
      ),
    ),
  )

export const fetchActivitiesWithSettingsFlow: EpicWithoutAPI = (
  action$,
  state$,
) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.fetchActivitiesWithSettingsAsync.request)),
    withLatestFrom(O.map(api)(state$)),
    O.chain(([, api]) =>
      pipe(
        api.activities.getActivities("me"),
        TE.fold<AnyAPIError, API.SportsActivitiesResponse, Action>(
          flow(actions.fetchActivitiesWithSettingsAsync.failure, T.of),
          flow(
            ({ activities }) =>
              pipe(
                activities,
                optToEmptyArr,
                fromTraversable(Arr.Traversable)<API.Activity>()
                  .composeIso(apiToState.activityWithSettingsWithId)
                  .asFold().getAll,
              ),
            actions.fetchActivitiesWithSettingsAsync.success,
            T.of,
          ),
        ),
        O.fromTask,
      ),
    ),
  )

export const fetchSportsFlow: EpicWithoutAPI = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.fetchSportsAsync.request)),
    withLatestFrom(O.map(api)(state$)),
    O.chain(([, api]) =>
      pipe(
        api.activities.getSports("me"),
        TE.fold<AnyAPIError, ReadonlyArray<API.Sport>, Action>(
          flow(actions.fetchSportsAsync.failure, T.of),
          (sports) =>
            pipe(
              sports,
              optToEmptyArr,
              fromTraversable(Arr.Traversable)<API.Sport>()
                .composeIso(apiToState.sportWithId)
                .asFold().getAll,
              actions.fetchSportsAsync.success,
              T.of,
            ),
        ),
        O.fromTask,
      ),
    ),
  )

export const saveMuscleSettingsFlow: EpicWithoutAPI = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.saveMuscleSettingsAsync.request)),
    withLatestFrom(O.map(api)(state$)),
    O.chain(([{ payload }, api]) =>
      pipe(
        apiToState.muscleSettingsTree.reverseGet(payload),
        (ms) =>
          api.activities.setMuscleSettings({
            muscleGroup: Arr.toArray(ms),
          })("me"),
        TE.map(() => payload),
        TE.fold<
          AnyAPIError,
          Tree.Forest<MuscleSettings.MuscleSettings>,
          Action
        >(
          flow(actions.saveMuscleSettingsAsync.failure, T.of),
          flow(actions.saveMuscleSettingsAsync.success, T.of),
        ),
        O.fromTask,
      ),
    ),
  )

export const saveActivitySettingsFlow: EpicWithoutAPI = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.saveActivitySettingsAsync.request)),
    withLatestFrom(O.map(api)(state$)),
    O.chain(([{ payload }, api]) =>
      pipe(
        payload,
        WithId._value<
          ActivityId.ActivityId,
          ActivitySettings.ActivitySettings
        >().composePrism(ActivitySettings._ActivitySettingsExercise).getOption,
        Opt.fold(
          () => TE.of(payload),
          (s) =>
            pipe(
              s,
              apiToState.activitySettingsExercise.reverseGet,
              (a) =>
                api.activities.setActivitySettings({
                  id: pipe(
                    payload.id,
                    ActivityId.stringAsActivityId.reverseGet,
                  ),
                  activitySettings: {
                    isLiked: pipe(
                      a.isLiked,
                      Opt.fromNullable,
                      Opt.getOrElse<boolean>(() => false),
                    ),
                    isDenylisted: pipe(
                      a.isDenylisted,
                      Opt.fromNullable,
                      Opt.getOrElse<boolean>(() => false),
                    ),
                  },
                })("me"),
              TE.map(() => payload),
            ),
        ),
        TE.fold<AnyAPIError, ActivitySettings.ActivitySettingsWithId, Action>(
          flow(actions.saveActivitySettingsAsync.failure, T.of),
          flow(actions.saveActivitySettingsAsync.success, T.of),
        ),
        O.fromTask,
      ),
    ),
  )

export const saveSportFlow: EpicWithoutAPI = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.saveSportAsync.request)),
    withLatestFrom(O.map(api)(state$)),
    O.chain(([{ payload }, api]) =>
      pipe(
        apiToState.sportWithId.reverseGet(payload),
        (sport) => api.activities.setSport({ sport })("me"),
        TE.map(() => payload),
        TE.fold<AnyAPIError, Sport.SportWithId, Action>(
          flow(actions.saveSportAsync.failure, T.of),
          flow(actions.saveSportAsync.success, T.of),
        ),
        O.fromTask,
      ),
    ),
  )

export const setSportIsLikedFlow: EpicWithoutAPI = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.setSportIsLikedAsync.request)),
    withLatestFrom(O.map(api)(state$)),
    O.chain(
      ([
        {
          payload: { id, value },
        },
        api,
      ]) =>
        pipe(
          value,
          Bool.fold(
            () =>
              api.activities.removeSportIsLiked({
                id: pipe(id, stringAsSportId.reverseGet),
              })("me"),
            () =>
              api.activities.setSportIsLiked({
                id: pipe(id, stringAsSportId.reverseGet),
              })("me"),
          ),
          TE.fold<AnyAPIError, void, Action>(
            flow(actions.setSportIsLikedAsync.failure, T.of),
            () =>
              pipe({ id, value }, actions.setSportIsLikedAsync.success, T.of),
          ),
          O.fromTask,
        ),
    ),
  )

export const setActivityListingFlow: EpicWithoutAPI = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.setActivityListingAsync.request)),
    withLatestFrom(O.map(api)(state$)),
    O.chain(([{ payload }, api]) =>
      pipe(
        api.activities.setActivitySettings({
          id: pipe(payload.activity, ActivityId.stringAsActivityId.reverseGet),
          activitySettings: {
            isLiked:
              payload.listing ===
              ActivitySettingsExercise.ActivitySettingsExerciseListing.liked,
            isDenylisted:
              payload.listing ===
              ActivitySettingsExercise.ActivitySettingsExerciseListing
                .denylisted,
          },
        })("me"),
        TE.map(() => payload),
        TE.fold<
          AnyAPIError,
          {
            activity: ActivityId.ActivityId
            listing: ActivitySettingsExercise.ActivitySettingsExerciseListing
          },
          Action
        >(
          flow(actions.setActivityListingAsync.failure, T.of),
          flow(actions.setActivityListingAsync.success, T.of),
        ),
        O.fromTask,
      ),
    ),
  )

export const fetchGeneralSportsSettingsFlow: EpicWithoutAPI = (
  action$,
  state$,
) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.fetchGeneralSportsSettingsAsync.request)),
    withLatestFrom(O.map(api)(state$)),
    O.chain(([, api]) =>
      pipe(
        api.activities.getPreferences("me"),
        TE.fold<AnyAPIError, API.GeneralSportsPreferences, Action>(
          flow(actions.fetchGeneralSportsSettingsAsync.failure, T.of),
          flow(
            apiToState.generalSportsSettings.get,
            actions.fetchGeneralSportsSettingsAsync.success,
            T.of,
          ),
        ),
        O.fromTask,
      ),
    ),
  )

export const setGeneralSportsSettingsFlow: EpicWithoutAPI = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.setGeneralSportsSettingsAsync.request)),
    withLatestFrom(O.map(api)(state$)),
    O.chain(([{ payload }, api]) =>
      pipe(
        api.activities.setPreferences({
          generalSportsPreferences: pipe(
            payload,
            apiToState.generalSportsSettings.reverseGet,
          ),
        })("me"),
        TE.fold<AnyAPIError, API.GeneralSportsPreferences, Action>(
          flow(actions.setGeneralSportsSettingsAsync.failure, T.of),
          flow(
            apiToState.generalSportsSettings.get,
            actions.setGeneralSportsSettingsAsync.success,
            T.of,
          ),
        ),
        O.fromTask,
      ),
    ),
  )

export const setMuscleSettingsFlow: EpicWithoutAPI = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.setMuscleSettings)),
    withLatestFrom(state$),
    O.map(
      ([
        {
          payload: { muscles },
        },
        state,
      ]) =>
        pipe(
          state,
          selectors.state
            .composeLens(selectors.muscleSettings)
            .composeTraversal(fromTraversable(Arr_.Traversable)())
            .composeTraversal(fromTraversable(Tree.Traversable)())
            .modify((ms) =>
              pipe(
                ms.id,
                MuscleGroup.stringAsMuscleGroupId.getOption,
                Opt.fold(
                  () =>
                    pipe(
                      ms.id,
                      muscleId_FromString.getOption,
                      Opt.chain((id) => Opt.fromNullable(muscles[id])),
                    ),
                  (muscleGroupId) =>
                    Opt.some(
                      MuscleSettings.getGroupSettings(muscleGroupId)(muscles),
                    ),
                ),
                Opt.map(({ enabled, priority }) => ({
                  ...ms,
                  enabled,
                  priority,
                })),
                Opt.getOrElse(() => ms),
              ),
            ),
          selectors.state.composeLens(selectors.muscleSettings).get,
          actions.saveMuscleSettings,
        ),
    ),
  )

export const toggleMuscleGroupFlow: EpicWithoutAPI = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.toggleMuscleGroup)),
    withLatestFrom(state$),
    O.map(
      ([
        {
          payload: { muscleGroup },
        },
        state,
      ]) =>
        pipe(
          state,
          selectors.state
            .composeLens(selectors.muscleSettings)
            .composeTraversal(fromTraversable(Arr_.Traversable)())
            .composeTraversal(fromTraversable(Tree.Traversable)())
            .modify((ms) =>
              pipe(
                muscleGroup,
                MuscleGroup.muscleGroupIdAsMuscleGroups.reverseGet,
                MuscleGroup.muscleIdsOfGroup,
                (muscleIds) =>
                  pipe(
                    ms.id,
                    MuscleGroup.stringAsMuscleGroupId.getOption,
                    Opt.fold(
                      () =>
                        pipe(
                          ms.id,
                          muscleId_FromString.getOption,
                          Opt.map((id) => Arr.elem(muscleId_)(id, muscleIds)),
                          Opt.getOrElse<boolean>(() => false),
                        ),
                      (id) =>
                        pipe(
                          muscleGroup,
                          MuscleGroup.muscleGroupIdAsMuscleGroups.reverseGet,
                          (groupId) =>
                            MuscleGroup.muscleGroupId.equals(id, groupId),
                        ),
                    ),
                    Bool.fold(
                      () => ms,
                      () => ({ ...ms, enabled: !ms.enabled }),
                    ),
                  ),
              ),
            ),
          selectors.state.composeLens(selectors.muscleSettings).get,
          actions.saveMuscleSettings,
        ),
    ),
  )

const epic = combineEpics<
  Action | APIAction,
  Action | APIAction,
  ParentAPIState
>(
  setTokenFlow,
  setAccountReadyFlow,
  fetchMuscleSettingsFlow,
  fetchActivitiesWithSettingsFlow,
  fetchSportsFlow,
  saveActivitySettingsFlow,
  saveMuscleSettingsFlow,
  saveSportFlow,
  setSportIsLikedFlow,
  setActivityListingFlow,
  fetchGeneralSportsSettingsFlow,
  setGeneralSportsSettingsFlow,
  setMuscleSettingsFlow,
  toggleMuscleGroupFlow,
)
export default epic

import { useEffect } from "react"
import { ReactReduxFirebaseProvider } from "react-18-redux-firebase"
import { createRoot } from "react-dom/client"
import { FormattedMessage, ReactIntlErrorCode } from "react-intl"
import { connect, Provider as StateProvider } from "react-redux"
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"

import * as Bool from "fp-ts/es6/boolean"
import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Str from "fp-ts/es6/string"
import * as T from "fp-ts/es6/Task"
import * as TE from "fp-ts/es6/TaskEither"
import {
  Dimension,
  UnitLengthDistance,
  UnitLengthHeight,
  UnitMass,
} from "@fitnesspilot/data-common"
import * as CommonData from "@fitnesspilot/data-common"

import { BootstrapTheme } from "@fitnesspilot/components-common/dist/atoms/BootstrapTheme/BootstrapTheme"
import { CSSSettings } from "@fitnesspilot/components-common/dist/atoms/CSSSettings/CSSSettings"
import { IntlProvider } from "@fitnesspilot/components-common/dist/atoms/IntlProvider/IntlProvider"
import { ThemeProvider } from "@fitnesspilot/components-common/dist/atoms/ThemeProvider/ThemeProvider"
import { HelpContainer } from "@fitnesspilot/components-help/dist/organisms/Help/Help"
import { setToken, stringAsToken, token } from "@fitnesspilot/data-api"

import App from "./App"
import * as config from "./config"
import * as fcm from "./fcm"
import { store } from "./reduxStore"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import * as clientsWeb from "./store"

import "bootstrap/dist/css/bootstrap.css"
import "./index.css"
import * as SentryIntegrations from "@sentry/integrations"
import * as Sentry from "@sentry/react"
import * as SentryTracing from "@sentry/tracing"
import firebase from "firebase/compat/app"
import { AnyAction, Dispatch } from "redux"
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/es/integration/react"

Sentry.init({
  enabled: config.sentry.enable,
  debug: config.sentry.debug,
  dsn: config.sentry.dsn,
  release: `fitnesspilot-web-app@${config.version}`,
  environment: config.stage,
  integrations: [
    new SentryTracing.Integrations.BrowserTracing({
      tracingOrigins: [new URL(config.apiBaseUrl).host],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new SentryIntegrations.CaptureConsole({
      levels: ["error"],
    }),
  ],
  tracesSampleRate: 0.1,
  beforeSend: (event, hint) => {
    if (event.exception) {
      if (hint?.originalException instanceof Error && event.event_id) {
        store.dispatch(
          CommonData.addErrorSentryEventId(
            hint.originalException,
            event.event_id,
          ),
        )
      } else {
        // show the report dialog if sentry event id isn't
        // added to state after a timeout
        setTimeout(() => {
          pipe(
            store.getState().common.errors,
            Arr.map((e) => e.sentryEventId),
            Arr.elem(Opt.getEq(Str.Eq))(Opt.fromNullable(event.event_id)),
            Bool.fold<IO.IO<void>>(
              () => () => Sentry.showReportDialog({ eventId: event.event_id }),
              () => IO.of(undefined),
            ),
          )()
        }, 100)
      }
    }
    return event
  },
  beforeBreadcrumb: (breadcrumb) =>
    breadcrumb.category === "console" &&
    breadcrumb.level != null &&
    (["warning", "error"] as Array<Sentry.SeverityLevel>).includes(
      breadcrumb.level,
    )
      ? breadcrumb
      : null,
})

const updateToken = (forceRefresh = false) =>
  void pipe(
    TE.tryCatch(
      () =>
        firebase.auth().currentUser?.getIdToken?.(forceRefresh) ??
        Promise.resolve(undefined),
      (e: unknown): firebase.FirebaseError => e as any,
    ),
    TE.fold(
      () => pipe(undefined, T.of),
      (newToken) =>
        pipe(
          newToken,
          Opt.fromNullable,
          Opt.chain(stringAsToken.getOption),
          (newToken) =>
            pipe(
              Opt.getEq(token).equals(newToken, store.getState().api.token),
              Bool.fold(
                () => newToken,
                () => Opt.none,
              ),
            ),
          Opt.fold(
            () => IO.of(undefined),
            (token): IO.IO<void> =>
              () =>
                pipe(token, Opt.some, setToken, store.dispatch),
          ),
          T.fromIO,
        ),
    ),
  )()
setInterval(() => updateToken(), 60 * 1000)
setInterval(() => updateToken(true), 30 * 60 * 1000)

const persistor = persistStore(store)

const ConnectedIntlProvider = connect((state: clientsWeb.ParentState) => ({
  locale: pipe(
    state,
    clientsWeb.selectors.state.composeLens(clientsWeb.selectors.locale).get,
  ),
}))(IntlProvider)

createRoot(document.getElementById("root")!).render(
  <StateProvider store={store}>
    <Sentry.ErrorBoundary>
      <ReactReduxFirebaseProvider
        firebase={firebase}
        config={{}}
        dispatch={store.dispatch as Dispatch<AnyAction>}
      >
        <ConnectedIntlProvider
          units={{
            [Dimension.mass]: UnitMass.kilogram,
            [Dimension.lengthHeight]: UnitLengthHeight.centimetre,
            [Dimension.lengthDistance]: UnitLengthDistance.kilometre,
          }}
          onError={(err) => {
            if (err.code === ReactIntlErrorCode.MISSING_TRANSLATION) {
              // Should be warn or error, but it spams the console during dev
              console.debug("Missing translation", err.message)
              return
            }
            throw err
          }}
        >
          <PersistGate
            {...{ persistor }}
            loading={<FormattedMessage defaultMessage="Loading..." />}
          >
            <ThemeProvider mode={Opt.none}>
              <CSSSettings>
                <BootstrapTheme>
                  <HelpContainer />
                  <App />
                </BootstrapTheme>
              </CSSSettings>
            </ThemeProvider>
          </PersistGate>
        </ConnectedIntlProvider>
      </ReactReduxFirebaseProvider>
    </Sentry.ErrorBoundary>
  </StateProvider>,
)

const handleWaitingWorker = (registration: ServiceWorkerRegistration) => {
  const waitingWorker = registration.waiting
  if (waitingWorker) {
    waitingWorker.addEventListener("statechange", () => {
      if (waitingWorker.state === "activated") {
        window.location.reload()
      }
    })

    store.dispatch(CommonData.setAvailableUpdate(waitingWorker))
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register({
  onRegister: (registration) => {
    fcm.registerSW(registration)

    // also show pending updates after reloading page
    handleWaitingWorker(registration)
  },
  onUpdate: (registration) => {
    handleWaitingWorker(registration)
  },
})

import { FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import * as Rec from "fp-ts/es6/ReadonlyRecord"

import { FormAnswersProvided } from "@fitnesspilot/components-common/dist/atoms/AnswersProvided/AnswersProvided"
import { FieldsetHeader } from "@fitnesspilot/components-common/dist/atoms/FieldsetHeader/FieldsetHeader"
import {
  ControllerPlus,
  formGroupRender,
  noTransform,
  selectRender,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"
import { ActivityHabits } from "@fitnesspilot/data-user/dist/Habits"

export type FormData = {
  activityHabits: ActivityHabits
}
const FormDataKeys: Rec.ReadonlyRecord<keyof FormData, null> = {
  activityHabits: null,
}

export type ActivityHabitsFieldsetProps = {
  id: string
  showAnswersProvided?: boolean
}

export const ActivityHabitsFieldset: FC<ActivityHabitsFieldsetProps> = ({
  id,
  showAnswersProvided,
}) => {
  const intl = useIntl()

  return (
    <>
      <FieldsetHeader
        title={
          <FormattedMessage defaultMessage="General physical activity level" />
        }
        titleRight={
          showAnswersProvided && (
            <FormAnswersProvided<FormData> keys={FormDataKeys} />
          )
        }
        description={
          <FormattedMessage defaultMessage="Do you always prefer the stairs? Choose a little walk over hanging on the couch? Skip the car when you can walk or take a bike? Or do you even prefer to control everything with your smart phone just not to move? We also like progress, but be conscious in your lifestyle choices. We can easily make ourselves aware of progress we loose for ourselves, for our body, for our health. Over a week this makes a relevant difference in movement we sacrifice - often for a very little comfort. It's on you to decide if it's worth it." />
        }
      />

      <ControllerPlus<FormData, "activityHabits", ActivityHabits>
        render={formGroupRender(selectRender<ActivityHabits, "radio">())({
          id: `${id}-activityHabits`,
          type: "radio",
          label: <FormattedMessage defaultMessage="Activity level" />,
          values: {
            // TODO add description for each option
            [ActivityHabits.lazy]: intl.formatMessage({
              defaultMessage: "Lazy",
            }),
            [ActivityHabits.moderate]: intl.formatMessage({
              defaultMessage: "Moderate",
            }),
            [ActivityHabits.active]: intl.formatMessage({
              defaultMessage: "Active",
            }),
          },
        })}
        name="activityHabits"
        transform={noTransform<FormData, "activityHabits">()}
        rules={{
          required: true,
        }}
      />
    </>
  )
}

import { FC, Fragment } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import { Alert, AlertProps, Button } from "reactstrap"
import styled from "@emotion/styled"

/* import styled from "@emotion/styled" */
import { constNull, constVoid, flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import { catchOption } from "@fitnesspilot/data-common"

import { UserError } from "@fitnesspilot/data-common/dist/error"
import {
  Action,
  clearShownError,
  ParentState,
  selectors,
  showErrorFeedbackDialog,
  toggleErrorDetailsAreOpen,
} from "@fitnesspilot/data-common/dist/store"

import { Markdown } from "../../molecules/Markdown/Markdown"
import { CommonModal } from "../CommonModal/CommonModal"

import { Dispatch } from "redux"

const mapState = (state: ParentState) => ({
  error: selectors.state.compose(selectors.shownError).get(state),
  isOpen: selectors.state.compose(selectors.errorDetailsAreOpen).get(state),
})

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ = flow(dispatch, constVoid)

  return {
    onDismiss: flow(clearShownError, dispatch_),
    onToggleOpen: flow(toggleErrorDetailsAreOpen, dispatch_),
    onFeedback: flow(showErrorFeedbackDialog, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type OwnProps = AlertProps

const StyledAlert = styled(Alert)`
  margin: 0;
`

type InnerErrorProps = Omit<PropsFromRedux, "error"> & {
  error: UserError
} & OwnProps

const InnerError: FC<InnerErrorProps> = ({
  error: {
    error: { userMessage, detailedUserMessage },
    sentryEventId,
  },
  isOpen,
  onDismiss,
  onToggleOpen,
  onFeedback,
  ...props
}) => {
  const intl = useIntl()
  const message = pipe(
    userMessage,
    Opt.fromNullable,
    catchOption(() =>
      intl.formatMessage({ defaultMessage: "An error occurred" }),
    ),
  )
  const detailedMessage = pipe(
    detailedUserMessage,
    Opt.fromNullable,
    catchOption(() =>
      intl.formatMessage({
        defaultMessage: `We will investigate the error ASAP; meanwhile the following approaches may help:

* Retry the failed action.
* Disable your ad blocker.
* Verify the data which you have input.`,
      }),
    ),
  )

  return (
    <Fragment>
      <StyledAlert color="danger" {...props}>
        {message}

        <Button type="button" color="link" onClick={() => onToggleOpen(true)}>
          <FormattedMessage defaultMessage="Details" />
        </Button>

        <Button close onClick={() => onDismiss()} />
      </StyledAlert>

      <CommonModal
        title={message}
        onToggle={onToggleOpen}
        onSave={Opt.none}
        {...{ isOpen }}
      >
        <Markdown>{detailedMessage}</Markdown>

        {pipe(
          sentryEventId,
          Opt.fold(constNull, (sentryEventId) => (
            <div>
              <Button
                type="button"
                onClick={pipe(
                  IO.Do,
                  IO.map(() => onDismiss()),
                  IO.map(() => onFeedback(sentryEventId)),
                )}
              >
                <FormattedMessage defaultMessage="Tell us what happened" />
              </Button>
            </div>
          )),
        )}
      </CommonModal>
    </Fragment>
  )
}

export type ErrorProps = PropsFromRedux & OwnProps

export const Error: FC<ErrorProps> = ({ error, ...props }) =>
  pipe(
    error,
    Opt.fold(
      () => null,
      (error) => <InnerError {...{ error }} {...props} />,
    ),
  )

export const ErrorContainer = connector(Error)

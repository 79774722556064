import { FC, HTMLAttributes } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import styled from "@emotion/styled"

import { pipe } from "fp-ts/es6/function"
import { Prism } from "monocle-ts"
import { dateAsTime, isoStringAsLocalTimeOfDay } from "time-ts/es6"
import { timeAsLocalTimeOfDayInCurrentEnv } from "time-ts/es6/Local/TimeOfDay"
import { prismToGetter, unsafeFromSome } from "@fitnesspilot/data-common"

import { FormGroup } from "@fitnesspilot/components-common/dist/molecules/FormGroup/FormGroup"
import { SaveButton } from "@fitnesspilot/components-common/dist/molecules/SaveButton/SaveButton"
import {
  ControllerPlus,
  ControllerPrism,
  formGroupRender,
  noTransform,
  selectRender,
  textRender,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"

export type FormData = {
  daysToEdit: DaysToEdit
  start: Date
  end: Date
}

export type TimeBatchModifyFieldsetProps = {
  id: string
  className?: string
  defaultValues: Pick<FormData, "daysToEdit">
  onSubmit: SubmitHandler<FormData>
}

export enum DaysToEdit {
  allDays = "allDays",
  weekdays = "weekdays",
  weekend = "weekend",
}

const IntervalSeparator = styled(
  ({ className, ...props }: HTMLAttributes<HTMLSpanElement>) => (
    <span className={`c-intervalSeparator ${className ?? ""}`} {...props} />
  ),
)``

const Interval = styled.div`
  display: flex;
  align-items: center;

  .c-intervalSeparator {
    margin: 0 5px;
  }
`

const stringAsLocalTimeOfDayDate = new Prism<string, Date>(
  (a) =>
    isoStringAsLocalTimeOfDay
      .composeGetter(prismToGetter(timeAsLocalTimeOfDayInCurrentEnv()))
      .composeGetter(prismToGetter(dateAsTime))
      .headOption(a),
  (d) =>
    pipe(
      d,
      (a) =>
        dateAsTime
          .composePrism(timeAsLocalTimeOfDayInCurrentEnv())
          .composeGetter(prismToGetter(isoStringAsLocalTimeOfDay))
          .headOption(a),
      unsafeFromSome,
    ),
)

export const TimeBatchModifyFieldset: FC<TimeBatchModifyFieldsetProps> = ({
  id,
  onSubmit,
  defaultValues,
  ...props
}: TimeBatchModifyFieldsetProps) => {
  const intl = useIntl()
  const { handleSubmit, ...form } = useForm<FormData>({
    defaultValues,
  })

  return (
    <FormProvider {...{ handleSubmit }} {...form}>
      <form
        onSubmit={async (evt) => {
          evt.stopPropagation()
          await handleSubmit(onSubmit)(evt)
        }}
        {...props}
      >
        <ControllerPlus<FormData, "daysToEdit", DaysToEdit>
          render={formGroupRender(selectRender<DaysToEdit>())({
            id: `${id}-daysToEdit`,
            label: <FormattedMessage defaultMessage="Day(s) to edit" />,
            type: "select",
            values: {
              [DaysToEdit.allDays]: intl.formatMessage({
                defaultMessage: "All days",
              }),
              [DaysToEdit.weekdays]: intl.formatMessage({
                defaultMessage: "Weekdays",
              }),
              [DaysToEdit.weekend]: intl.formatMessage({
                defaultMessage: "Weekend",
              }),
            },
          })}
          name="daysToEdit"
          transform={noTransform<FormData, "daysToEdit">()}
        />

        <FormGroup
          inputId={`${id}-start`}
          label={<FormattedMessage defaultMessage="Time" />}
        >
          <Interval>
            <ControllerPrism<FormData, "start">
              render={textRender({
                id: `${id}-start`,
                type: "time",
                required: true,
                step: 60,
              })}
              name="start"
              prism={stringAsLocalTimeOfDayDate}
              prismError={intl.formatMessage({
                defaultMessage: "is invalid",
              })}
            />
            <IntervalSeparator>–</IntervalSeparator>
            <ControllerPrism<FormData, "end">
              render={textRender({
                id: `${id}-end`,
                type: "time",
                required: true,
                step: 60,
              })}
              name="end"
              prism={stringAsLocalTimeOfDayDate}
              prismError={intl.formatMessage({
                defaultMessage: "is invalid",
              })}
            />
          </Interval>
        </FormGroup>

        <SaveButton type="submit">
          <FormattedMessage defaultMessage="Update all" />
        </SaveButton>
      </form>
    </FormProvider>
  )
}

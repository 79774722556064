import * as Eq from "fp-ts/es6/Eq"
import { identity } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import { Lens, Optional, Prism } from "monocle-ts"

import { Activity } from "../activity/Activity"
import {
  ActivityExercise,
  activityExercise,
} from "../activity/ActivityExercise"
import { ActivityId, activityId } from "../activity/ActivityId"
import { ActivityJob, activityJob } from "../activity/ActivityJob"
import { ActivityMuscles, activityMuscles } from "../activity/ActivityMuscles"
import { ActivitySleep, activitySleep } from "../activity/ActivitySleep"
import { ActivityType } from "../activity/ActivityType"
import { _withId, WithId } from "../activity/WithId"
import { ActivitySettings } from "./ActivitySettings"
import {
  ActivitySettingsExercise,
  activitySettingsExercise,
  empty as emptyExercise,
} from "./ActivitySettingsExercise"
import {
  ActivitySettingsJob,
  activitySettingsJob,
  empty as emptyJob,
} from "./ActivitySettingsJob"
import {
  ActivitySettingsMuscles,
  activitySettingsMuscles,
  empty as emptyMuscles,
} from "./ActivitySettingsMuscles"
import {
  ActivitySettingsSleep,
  activitySettingsSleep,
  empty as emptySleep,
} from "./ActivitySettingsSleep"

export type ActivityWithSettings =
  | {
      $type: ActivityType.exercise
      activity: ActivityExercise
      settings: ActivitySettingsExercise
    }
  | {
      $type: ActivityType.job
      activity: ActivityJob
      settings: ActivitySettingsJob
    }
  | {
      $type: ActivityType.muscles
      activity: ActivityMuscles
      settings: ActivitySettingsMuscles
    }
  | {
      $type: ActivityType.sleep
      activity: ActivitySleep
      settings: ActivitySettingsSleep
    }
export const activity = Eq.fromEquals<ActivityWithSettings>((a, b) =>
  a.$type === ActivityType.exercise
    ? b.$type === ActivityType.exercise
      ? activityExercise.equals(a.activity, b.activity) &&
        activitySettingsExercise.equals(a.settings, b.settings)
      : false
    : a.$type === ActivityType.job
    ? b.$type === ActivityType.job
      ? activityJob.equals(a.activity, b.activity) &&
        activitySettingsJob.equals(a.settings, b.settings)
      : false
    : a.$type === ActivityType.muscles
    ? b.$type === ActivityType.muscles
      ? activityMuscles.equals(a.activity, b.activity) &&
        activitySettingsMuscles.equals(a.settings, b.settings)
      : false
    : activitySleep.equals(a.activity, b.activity) &&
      activitySettingsSleep.equals(a.settings, b.settings),
)

export const exerciseWithSettings = (
  activity: ActivityExercise,
  settings: ActivitySettingsExercise,
): ActivityWithSettings => ({
  $type: ActivityType.exercise,
  activity,
  settings,
})
export const jobWithSettings = (
  activity: ActivityJob,
  settings: ActivitySettingsJob,
): ActivityWithSettings => ({
  $type: ActivityType.job,
  activity,
  settings,
})
export const musclesWithSettings = (
  activity: ActivityMuscles,
  settings: ActivitySettingsMuscles,
): ActivityWithSettings => ({
  $type: ActivityType.muscles,
  activity,
  settings,
})
export const sleepWithSettings = (
  activity: ActivitySleep,
  settings: ActivitySettingsSleep,
): ActivityWithSettings => ({
  $type: ActivityType.sleep,
  activity,
  settings,
})

export const _Activity = new Lens<ActivityWithSettings, Activity>(
  ({ $type, activity }) => ({ $type, value: activity }) as Activity,
  (a) => (s) =>
    a.$type === s.$type
      ? ({
          ...s,
          activity: a.value,
        } as ActivityWithSettings)
      : ({
          $type: a.$type,
          activity: a.value,
          // @TODO This is pretty much illegal in a lens; dunno how to handle it.
          settings: null as never,
        } as any as ActivityWithSettings),
)
export const _ActivityExercise = new Prism<
  ActivityWithSettings,
  ActivityExercise
>(
  (s) => (s.$type === ActivityType.exercise ? Opt.some(s.activity) : Opt.none),
  (a) => ({
    $type: ActivityType.exercise,
    activity: a,
    // @TODO This is wrong
    settings: emptyExercise(false),
  }),
)
export const _ActivityJob = new Prism<ActivityWithSettings, ActivityJob>(
  (s) => (s.$type === ActivityType.job ? Opt.some(s.activity) : Opt.none),
  (a) => ({ $type: ActivityType.job, activity: a, settings: emptyJob }),
)
export const _ActivityMuscles = new Prism<
  ActivityWithSettings,
  ActivityMuscles
>(
  (s) => (s.$type === ActivityType.muscles ? Opt.some(s.activity) : Opt.none),
  (a) => ({ $type: ActivityType.muscles, activity: a, settings: emptyMuscles }),
)
export const _ActivitySleep = new Prism<ActivityWithSettings, ActivitySleep>(
  (s) => (s.$type === ActivityType.sleep ? Opt.some(s.activity) : Opt.none),
  (a) => ({ $type: ActivityType.sleep, activity: a, settings: emptySleep }),
)

export const _ActivitySettingsExercise = new Optional<
  ActivityWithSettings,
  ActivitySettingsExercise
>(
  (s) => (s.$type === ActivityType.exercise ? Opt.some(s.settings) : Opt.none),
  (settings) => (s) =>
    s.$type === ActivityType.exercise ? { ...s, settings } : s,
)
export const _ActivitySettingsJob = new Optional<
  ActivityWithSettings,
  ActivitySettingsJob
>(
  (s) => (s.$type === ActivityType.job ? Opt.some(s.settings) : Opt.none),
  (settings) => (s) => (s.$type === ActivityType.job ? { ...s, settings } : s),
)
export const _ActivitySettingsMuscles = new Optional<
  ActivityWithSettings,
  ActivitySettingsMuscles
>(
  (s) => (s.$type === ActivityType.muscles ? Opt.some(s.settings) : Opt.none),
  (settings) => (s) =>
    s.$type === ActivityType.muscles ? { ...s, settings } : s,
)
export const _ActivitySettingsSleep = new Optional<
  ActivityWithSettings,
  ActivitySettingsSleep
>(
  (s) => (s.$type === ActivityType.sleep ? Opt.some(s.settings) : Opt.none),
  (settings) => (s) =>
    s.$type === ActivityType.sleep ? { ...s, settings } : s,
)

export const foldActivity =
  <a>(
    exercise: (a: ActivityExercise, s: ActivitySettingsExercise) => a,
    job: (a: ActivityJob, s: ActivitySettingsJob) => a,
    muscles: (a: ActivityMuscles, s: ActivitySettingsMuscles) => a,
    sleep: (a: ActivitySleep, s: ActivitySettingsSleep) => a,
  ) =>
  (v: ActivityWithSettings) =>
    v.$type === ActivityType.exercise
      ? exercise(v.activity, v.settings)
      : v.$type === ActivityType.job
      ? job(v.activity, v.settings)
      : v.$type === ActivityType.muscles
      ? muscles(v.activity, v.settings)
      : sleep(v.activity, v.settings)

export type ActivityWithSettingsWithId = WithId<
  ActivityId,
  ActivityWithSettings
>
export const activityWithSettingsWithIdOptics = _withId<
  ActivityId,
  ActivityWithSettings
>(activityId)

// @TODO This isn't completely valid as setting an incompatible setting type (eg setting settings for job on exercise) does nothing.
export const _settings = new Lens<ActivityWithSettings, ActivitySettings>(
  ({ $type, settings }) => ({ $type, settings }) as ActivitySettings,
  (a) => (s) =>
    a.$type === s.$type
      ? ({
          ...s,
          settings: a.settings,
        } as ActivityWithSettings)
      : s,
)

export type ActivityExerciseWithSettings = ActivityWithSettings & {
  $type: ActivityType.exercise
}

export type ActivityExerciseWithSettingsWithId = WithId<
  ActivityId,
  ActivityExerciseWithSettings
>

export const _ActivityExerciseWithSettingsWithId = new Prism<
  ActivityWithSettingsWithId,
  ActivityExerciseWithSettingsWithId
>(
  (a) =>
    a.value.$type === ActivityType.exercise
      ? Opt.some({ id: a.id, value: a.value })
      : Opt.none,
  identity,
)

export const _activityExerciseWithSettingsActivity =
  Lens.fromProp<ActivityExerciseWithSettings>()("activity")
export const _activityExerciseWithSettingsSettings =
  Lens.fromProp<ActivityExerciseWithSettings>()("settings")

export const activityExerciseWithSettingsWithIdOptics = _withId<
  ActivityId,
  ActivityExerciseWithSettings
>(activityId)

export const _ActivityExerciseWithSettings = new Prism<
  ActivityWithSettings,
  ActivityExerciseWithSettings
>((a) => (a.$type === ActivityType.exercise ? Opt.some(a) : Opt.none), identity)

import * as Opt from "fp-ts/es6/Option";
import { Prism } from "monocle-ts";
import { ioTsPrism } from "../internal";
import { Temporal, toTemporalInstant } from "@js-temporal/polyfill";
import * as t from "io-ts";
export const time = {
    equals: (a, b) => a.equals(b),
    compare: (a, b) => Temporal.Instant.compare(a, b),
};
export const equals = (d2) => (d) => time.equals(d, d2);
export const compare = (d2) => (d) => time.compare(d, d2);
export const now = () => Temporal.Now.instant();
export const isoStringAsTime = new Prism((s) => Opt.tryCatch(() => Temporal.Instant.from(s)), (s) => s.toString());
export const timeT = t.string.pipe(ioTsPrism("Time", (a) => a instanceof Temporal.Instant, isoStringAsTime));
export const dateAsTime = new Prism((s) => Opt.tryCatch(() => toTemporalInstant.call(s)), (s) => new Date(s.toString()));
export const timeFromDateT = ioTsPrism("TimeFromDate", (a) => a instanceof Temporal.Instant, dateAsTime);

import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { now } from "time-ts/es6"
import { arrTrav } from "@fitnesspilot/data-common"

import * as CoachTask from "../CoachTask"
import * as actions from "./actions"
import { Action } from "./actions"
import * as selectors from "./selectors"
import { initialState, State } from "./state"

import { createReducer } from "typesafe-actions"

export default createReducer<State, Action>(initialState)
  .handleAction(actions.resetState, () => initialState)
  .handleAction(actions.fetchCoachTasksAsync.success, (state, action) =>
    pipe(state, selectors.coachTasks.set(action.payload)),
  )
  // .handleAction(actions.fetchCoachTasksAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(actions.confirmCoachTaskAsync.success, (state, action) =>
    pipe(
      state,
      selectors.coachTasks
        .composeTraversal(arrTrav<CoachTask.CoachTask>())
        .modify((task) =>
          CoachTask.coachTaskId.equals(task.id, action.payload.id)
            ? (pipe(
                task,
                CoachTask._completedOn.set(Opt.some(now())),
              ) as CoachTask.CoachTask)
            : task,
        ),
    ),
  )
  // .handleAction(actions.confirmCoachTaskAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(actions.dismissCoachTaskAsync.success, (state, action) =>
    pipe(
      state,
      selectors.coachTasks
        .composeTraversal(arrTrav<CoachTask.CoachTask>())
        .modify((task) =>
          CoachTask.coachTaskId.equals(task.id, action.payload.id)
            ? (pipe(
                task,
                CoachTask._dismissedOn.set(Opt.some(now())),
              ) as CoachTask.CoachTask)
            : task,
        ),
    ),
  )
  // .handleAction(actions.dismissCoachTaskAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(
    actions.setEventConfirmationIsOpen,
    (state, { payload: { isOpen } }) =>
      pipe(state, pipe(isOpen, selectors.eventConfirmationIsOpen.set)),
  )

import { ReactNode } from "react"
import { FaCheck } from "react-icons/fa"
import { FormattedMessage } from "react-intl"

import * as IO from "fp-ts/es6/IO"

import { ButtonWithIcon, IconSide } from "../ButtonWithIcon/ButtonWithIcon"

export type SaveButtonProps = {
  className?: string
  iconSide?: IconSide
  type: "button" | "submit"
  children?: ReactNode
  onClick?: IO.IO<void>
}

export const SaveButton = ({
  className,
  type,
  iconSide = IconSide.left,
  children = <FormattedMessage defaultMessage="Save" />,
  onClick,
}: SaveButtonProps) => (
  <ButtonWithIcon
    icon={<FaCheck />}
    {...{ className, type, iconSide, onClick }}
    color="success"
  >
    {children}
  </ButtonWithIcon>
)

import React from "react"
import { NavLink, NavLinkProps } from "reactstrap"
import styled from "@emotion/styled"

export type SupraNavLinkProps = NavLinkProps

export const SupraNavLink = styled(NavLink)`
  font-weight: ${({ theme }) => theme.font.weights.bold};
  font-size: 14px;
  color: #849aae;
  border-radius: 3px;

  &:hover,
  &.active {
    background: #f8c514;
    color: ${({ theme }) => theme.colours.white.toString()};
  }
`

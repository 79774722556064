import { FC, ReactNode } from "react"
import styled from "@emotion/styled"

import { Image as Image_, ImageProps } from "../../atoms/Image/Image"
import {
  BackgroundWithDetails,
  BackgroundWithDetailsProps,
} from "../BackgroundWithDetails/BackgroundWithDetails"

const Image = styled(Image_)`
  max-width: 100%;
`

export type ImageWithDetailsProps = {
  image: ImageProps["image"]
} & Omit<BackgroundWithDetailsProps, "background">

export const ImageWithDetails = styled(
  ({ image, ...props }: ImageWithDetailsProps) => (
    <BackgroundWithDetails background={<Image {...{ image }} />} {...props} />
  ),
)``

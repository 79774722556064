/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from "../runtime";
import { ActivityInstanceFromJSONTyped, ActivityInstanceToJSON, } from "./ActivityInstance";
import { ActivityMusclesFromJSON, ActivityMusclesToJSON, } from "./ActivityMuscles";
import { ActivityTypeFromJSON, ActivityTypeToJSON, } from "./ActivityType";
/**
 * Check if a given object implements the ActivityInstanceMuscles interface.
 */
export function instanceOfActivityInstanceMuscles(value) {
    let isInstance = true;
    isInstance = isInstance && "activity" in value;
    isInstance = isInstance && "muscleGroupIds" in value;
    isInstance = isInstance && "$type" in value;
    return isInstance;
}
export function ActivityInstanceMusclesFromJSON(json) {
    return ActivityInstanceMusclesFromJSONTyped(json, false);
}
export function ActivityInstanceMusclesFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        ...ActivityInstanceFromJSONTyped(json, ignoreDiscriminator),
        activity: ActivityMusclesFromJSON(json.activity),
        muscleGroupIds: json.muscleGroupIds,
        $type: ActivityTypeFromJSON(json.$type),
        duration: !exists(json, "duration") ? undefined : json.duration,
        intensity: !exists(json, "intensity") ? undefined : json.intensity,
        alignment: !exists(json, "alignment") ? undefined : json.alignment,
    };
}
export function ActivityInstanceMusclesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...ActivityInstanceToJSON(value, true),
        activity: ActivityMusclesToJSON(value.activity),
        muscleGroupIds: value.muscleGroupIds,
        $type: ActivityTypeToJSON(value.$type),
        duration: value.duration,
        intensity: value.intensity,
        alignment: value.alignment,
    };
}

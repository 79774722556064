import { ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import { FormGroup, FormGroupProps, Input, Label } from "reactstrap"
import styled from "@emotion/styled"

import * as Bool from "fp-ts/es6/boolean"
import * as E from "fp-ts/es6/Either"
import * as Eq from "fp-ts/es6/Eq"
import {
  constFalse,
  constNull,
  constTrue,
  flow,
  pipe,
} from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Num from "fp-ts/es6/number"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import { fromTraversable, Getter, Lens, Prism } from "monocle-ts"
import {
  PositiveInteger,
  prismPositiveInteger,
} from "newtype-ts/es6/PositiveInteger"
import {
  _localDurationDays,
  _localDurationMonths,
  _localDurationWeeks,
  dateAsTime,
  dayOfTime,
  Duration,
  Local,
  mkLocalDuration,
  now,
  Time,
  timeAsDayAndTimeOfDayPair,
} from "time-ts/es6"
import {
  arrTrav,
  prismToGetter,
  unsafeFromSome,
} from "@fitnesspilot/data-common"

import { DayInput as DayInput_ } from "@fitnesspilot/components-common/dist/atoms/input/DayInput"
import {
  _end,
  _MonthlyOccurenceDayOfMonth,
  _monthlyOccurenceDayOfMonthDayOfMonth,
  _MonthlyOccurenceWeekdayOfMonth,
  _monthlyOccurenceWeekdayOfMonthDayOfWeek,
  _monthlyOccurenceWeekdayOfMonthWeekOfMonth,
  _occursOnRecurrencePatternMonthly,
  _occursOnRecurrencePatternWeekly,
  _RecurrencePattern,
  _RecurrencePatternDaily,
  _RecurrencePatternMonthly,
  _RecurrencePatternWeekly,
  _RecurrenceSingle,
  _repeatEveryRecurrencePatternDaily,
  _repeatEveryRecurrencePatternMonthly,
  _repeatEveryRecurrencePatternWeekly,
  _start,
  emptyRepeatEveryDaily,
  emptyRepeatEveryMonthly,
  emptyRepeatEveryWeekly,
  MonthlyOccurence,
  monthlyOccurence,
  MonthlyOccurenceDayOfMonth,
  monthlyOccurenceDayOfMonth,
  MonthlyOccurenceType,
  MonthlyOccurenceWeekdayOfMonth,
  Recurrence,
  RecurrencePattern,
  RecurrencePatternDaily,
  RecurrencePatternMonthly,
  RecurrencePatternType,
  RecurrencePatternWeekly,
  RecurrenceType,
} from "@fitnesspilot/data-event/dist/calendar/Recurrence"
import {
  DayOfWeek,
  dayOfWeek,
  dayOfWeekDaysShort,
  dayOfWeekOfTime,
} from "@fitnesspilot/data-event/dist/schedule/day"

import { Temporal } from "@js-temporal/polyfill"

enum CombinedPattern {
  single = "single",
  daily = "daily",
  weekly = "weekly",
  monthly = "monthly",
}

const combinedPatternFromRecurrence = flow(
  _RecurrencePattern.getOption,
  Opt.fold(
    () => CombinedPattern.single,
    (recurrence) =>
      pipe(
        recurrence,
        _RecurrencePatternDaily.getOption,
        Opt.fold(
          () =>
            pipe(
              _RecurrencePatternWeekly.getOption(recurrence),
              Opt.fold(
                () => CombinedPattern.monthly,
                () => CombinedPattern.weekly,
              ),
            ),
          () => CombinedPattern.daily,
        ),
      ),
  ),
)

const combinedPatternToRecurrence =
  (start: Time) =>
  (combined: CombinedPattern): Recurrence =>
    ({
      [CombinedPattern.single]: _RecurrenceSingle.reverseGet(),
      [CombinedPattern.daily]: _RecurrencePattern
        .composePrism(_RecurrencePatternDaily)
        .reverseGet({
          between: [start, Opt.none],
          // @TODO Remove `as any`
          repeatEvery: mkLocalDuration({ days: 1 }) as any,
        }),
      [CombinedPattern.weekly]: _RecurrencePattern
        .composePrism(_RecurrencePatternWeekly)
        .reverseGet({
          between: [start, Opt.none],
          occursOn: pipe(start, dayOfWeekOfTime.get, NonEmpty.of),
          // @TODO Remove `as any`
          repeatEvery: mkLocalDuration({ weeks: 1 }) as any,
        }),
      [CombinedPattern.monthly]: _RecurrencePattern
        .composePrism(_RecurrencePatternMonthly)
        .reverseGet({
          between: [start, Opt.none],
          occursOn: [
            _MonthlyOccurenceDayOfMonth.reverseGet({
              dayOfMonth: pipe(
                start,
                (v) =>
                  prismToGetter(dateAsTime)
                    .composeGetter(new Getter((t) => t.getDate()))
                    .composePrism(prismPositiveInteger)
                    .headOption(v),
                unsafeFromSome,
              ),
            }),
          ],
          // @TODO Remove `as any`
          repeatEvery: mkLocalDuration({ months: 1 }) as any,
        }),
    })[combined]

const Capitalise = styled.div`
  text-transform: capitalize;
`

const FormGroupInline = styled((props: FormGroupProps) => (
  <FormGroup inline {...props} />
))`
  > * {
    margin-right: 6px;
  }
`

const CustomLabel = styled.div`
  margin: 20px 0 5px;
`

const DayInput = styled(DayInput_)``

const InputLabel = styled(Label)`
  display: flex;
  align-items: center;

  /* TODO revert to DayInput component selector later */
  > .form-control {
    margin-left: 10px;
  }
`

const RepeatEveryInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > *:first-of-type {
    margin-right: 10px;
  }
`

export type RecurrenceInputProps = {
  className?: string
  recurrence: Recurrence
  onChange: (recurrence: Recurrence) => IO.IO<void>
  start: Time
}

export const RecurrenceInput = styled(
  ({ className, recurrence, onChange, start }: RecurrenceInputProps) => {
    const monthlyOccurence = flow(
      _RecurrencePattern
        .compose(_RecurrencePatternMonthly)
        .composeLens(_occursOnRecurrencePatternMonthly)
        .composeTraversal(fromTraversable(NonEmpty.Traversable)())
        .asFold().getAll,
      Arr.lookup(0),
      Opt.fold<
        MonthlyOccurence,
        E.Either<MonthlyOccurenceDayOfMonth, MonthlyOccurenceWeekdayOfMonth>
      >(
        () =>
          E.left({
            dayOfMonth: pipe(1, prismPositiveInteger.getOption, unsafeFromSome),
          }),
        (occurence) =>
          pipe(
            occurence,
            _MonthlyOccurenceDayOfMonth.getOption,
            Opt.fold(
              () =>
                pipe(
                  occurence,
                  _MonthlyOccurenceWeekdayOfMonth.getOption,
                  Opt.fold(
                    () =>
                      E.left({
                        dayOfMonth: pipe(
                          1,
                          prismPositiveInteger.getOption,
                          unsafeFromSome,
                        ),
                      }),
                    E.right,
                  ),
                ),
              E.left,
            ),
          ),
      ),
    )

    return (
      <div {...{ className }}>
        <Input
          type="select"
          value={combinedPatternFromRecurrence(recurrence)}
          onChange={(e) =>
            pipe(
              e.target.value as CombinedPattern,
              combinedPatternToRecurrence(start),
              Opt.some,
              Opt.filter((r) =>
                r.type === RecurrenceType.single
                  ? recurrence.type !== RecurrenceType.single
                  : recurrence.type !== RecurrenceType.recurring ||
                    recurrence.value.type !== r.value.type,
              ),
              Opt.fold(() => IO.of(undefined), onChange),
            )()
          }
        >
          <FormattedMessage defaultMessage="Single">
            {(v) => <option value={CombinedPattern.single}>{String(v)}</option>}
          </FormattedMessage>
          <FormattedMessage defaultMessage="Daily">
            {(v) => <option value={CombinedPattern.daily}>{String(v)}</option>}
          </FormattedMessage>
          <FormattedMessage defaultMessage="Weekly">
            {(v) => <option value={CombinedPattern.weekly}>{String(v)}</option>}
          </FormattedMessage>
          <FormattedMessage defaultMessage="Monthly">
            {(v) => (
              <option value={CombinedPattern.monthly}>{String(v)}</option>
            )}
          </FormattedMessage>
        </Input>

        {recurrence.type === RecurrenceType.recurring && (
          <>
            <CustomLabel>
              <FormattedMessage defaultMessage="Repeat every" />
            </CustomLabel>
            {recurrence.value.type === RecurrencePatternType.daily ? (
              <>
                <RepeatEveryInput>
                  <Input
                    type="number"
                    value={pipe(
                      recurrence,
                      (a) =>
                        _RecurrencePattern
                          .compose(_RecurrencePatternDaily)
                          .composeLens(_repeatEveryRecurrencePatternDaily)
                          .composeGetter(_localDurationDays.asGetter())
                          .headOption(a),
                      unsafeFromSome,
                    )}
                    onChange={(e) =>
                      pipe(
                        recurrence,
                        _RecurrencePattern
                          .compose(_RecurrencePatternDaily)
                          .composeLens(_repeatEveryRecurrencePatternDaily)
                          .composeLens<number>(
                            _localDurationDays as Lens<
                              Local<
                                Temporal.Duration & {
                                  hours: 0
                                  minutes: 0
                                  seconds: 0
                                }
                              >,
                              number
                            >,
                          )
                          .set(Number(e.target.value)),
                        onChange,
                      )()
                    }
                  />
                  <FormattedMessage defaultMessage="days" />
                </RepeatEveryInput>
              </>
            ) : recurrence.value.type === RecurrencePatternType.weekly ? (
              <>
                <RepeatEveryInput>
                  <Input
                    type="number"
                    value={pipe(
                      recurrence,
                      (a) =>
                        _RecurrencePattern
                          .compose(_RecurrencePatternWeekly)
                          .composeLens(_repeatEveryRecurrencePatternWeekly)
                          .composeGetter(_localDurationWeeks.asGetter())
                          .headOption(a),
                      unsafeFromSome,
                    )}
                    onChange={(e) =>
                      pipe(
                        recurrence,
                        _RecurrencePattern
                          .compose(_RecurrencePatternWeekly)
                          .composeLens(_repeatEveryRecurrencePatternWeekly)
                          .composeLens<number>(
                            _localDurationWeeks as Lens<
                              Local<
                                Temporal.Duration & {
                                  days: 0
                                  hours: 0
                                  minutes: 0
                                  seconds: 0
                                }
                              >,
                              number
                            >,
                          )
                          .set(Number(e.target.value)),
                        onChange,
                      )()
                    }
                  />
                  <FormattedMessage defaultMessage="weeks" />
                </RepeatEveryInput>

                <div>
                  <CustomLabel>
                    <FormattedMessage defaultMessage="Repeat on" />
                  </CustomLabel>
                  <FormGroupInline inline check>
                    {pipe(
                      Object.values(DayOfWeek),
                      Arr.map((day) => (
                        <InputLabel key={day}>
                          <Input
                            key={day}
                            type="checkbox"
                            checked={pipe(
                              recurrence,
                              _RecurrencePattern
                                .compose(_RecurrencePatternWeekly)
                                .composeLens(_occursOnRecurrencePatternWeekly)
                                .getOption,
                              unsafeFromSome,
                              flow(Arr.elem(dayOfWeek)(day)),
                            )}
                            onChange={pipe(
                              recurrence,
                              _RecurrencePattern
                                .compose(_RecurrencePatternWeekly)
                                .composeLens(_occursOnRecurrencePatternWeekly)
                                .modify((old) =>
                                  pipe(
                                    old,
                                    Arr.elem(dayOfWeek)(day),
                                    Bool.fold(
                                      () =>
                                        pipe(old, (as) => Arr.cons(day, as)),
                                      () =>
                                        pipe(
                                          old,
                                          NonEmpty.filter((v) => v !== day),
                                          Opt.getOrElse(() =>
                                            Arr.cons<DayOfWeek>(
                                              DayOfWeek.monday,
                                              [],
                                            ),
                                          ),
                                        ),
                                    ),
                                  ),
                                ),
                              onChange,
                            )}
                          />
                          <Capitalise>
                            {/* TODO translations */}
                            {dayOfWeekDaysShort.get(day)}
                          </Capitalise>
                        </InputLabel>
                      )),
                    )}
                  </FormGroupInline>
                </div>
              </>
            ) : recurrence.value.type === RecurrencePatternType.monthly ? (
              <>
                <RepeatEveryInput>
                  <Input
                    type="number"
                    value={pipe(
                      recurrence,
                      (a) =>
                        _RecurrencePattern
                          .compose(_RecurrencePatternMonthly)
                          .composeLens(_repeatEveryRecurrencePatternMonthly)
                          .composeGetter(_localDurationMonths.asGetter())
                          .headOption(a),
                      unsafeFromSome,
                    )}
                    onChange={(e) =>
                      pipe(
                        recurrence,
                        _RecurrencePattern
                          .compose(_RecurrencePatternMonthly)
                          .composeLens(_repeatEveryRecurrencePatternMonthly)
                          .composeLens<number>(
                            _localDurationMonths as Lens<
                              Local<
                                Temporal.Duration & {
                                  weeks: 0
                                  days: 0
                                  hours: 0
                                  minutes: 0
                                  seconds: 0
                                }
                              >,
                              number
                            >,
                          )
                          .set(Number(e.target.value)),
                        onChange,
                      )()
                    }
                  />
                  <FormattedMessage defaultMessage="months" />
                </RepeatEveryInput>

                <div>
                  <CustomLabel>
                    <FormattedMessage defaultMessage="Repeat on" />
                  </CustomLabel>
                  <FormGroup>
                    <FormGroupInline inline check>
                      <InputLabel check>
                        <Input
                          type="radio"
                          checked={pipe(
                            recurrence,
                            monthlyOccurence,
                            E.fold(constTrue, constFalse),
                          )}
                          onChange={pipe(
                            recurrence,
                            _RecurrencePattern
                              .compose(_RecurrencePatternMonthly)
                              .composeLens(_occursOnRecurrencePatternMonthly)
                              .set(
                                pipe(
                                  {
                                    dayOfMonth: pipe(
                                      1,
                                      prismPositiveInteger.getOption,
                                      unsafeFromSome,
                                    ),
                                  },
                                  _MonthlyOccurenceDayOfMonth.reverseGet,
                                  NonEmpty.of,
                                ),
                              ),
                            onChange,
                          )}
                        />
                        <FormattedMessage defaultMessage="Day of month" />
                      </InputLabel>
                      <InputLabel check>
                        <Input
                          type="radio"
                          checked={pipe(
                            recurrence,
                            monthlyOccurence,
                            E.fold(constFalse, constTrue),
                          )}
                          onChange={pipe(
                            recurrence,
                            _RecurrencePattern
                              .compose(_RecurrencePatternMonthly)
                              .composeLens(_occursOnRecurrencePatternMonthly)
                              .set(
                                pipe(
                                  {
                                    weekOfMonth: pipe(
                                      1,
                                      prismPositiveInteger.getOption,
                                      unsafeFromSome,
                                    ),
                                    dayOfWeek: DayOfWeek.monday,
                                  },
                                  _MonthlyOccurenceWeekdayOfMonth.reverseGet,
                                  NonEmpty.of,
                                ),
                              ),
                            onChange,
                          )}
                        />
                        <FormattedMessage defaultMessage="Week day of month" />
                      </InputLabel>
                    </FormGroupInline>

                    {pipe(
                      recurrence,
                      monthlyOccurence,
                      E.fold(
                        (occurence) => (
                          <FormGroup>
                            <RepeatEveryInput>
                              <Input
                                type="number"
                                min={1}
                                max={31}
                                value={pipe(
                                  occurence,
                                  _monthlyOccurenceDayOfMonthDayOfMonth.get,
                                  prismPositiveInteger.reverseGet,
                                )}
                                onChange={(e) =>
                                  pipe(
                                    recurrence,
                                    _RecurrencePattern
                                      .compose(_RecurrencePatternMonthly)
                                      .composeLens(
                                        _occursOnRecurrencePatternMonthly,
                                      )
                                      .set(
                                        pipe(
                                          {
                                            dayOfMonth: pipe(
                                              Number(e.target.value),
                                              prismPositiveInteger.getOption,
                                              unsafeFromSome,
                                            ),
                                          },
                                          _MonthlyOccurenceDayOfMonth.reverseGet,
                                          NonEmpty.of,
                                        ),
                                      ),
                                    onChange,
                                  )()
                                }
                              />
                              <FormattedMessage defaultMessage="day of the month" />
                            </RepeatEveryInput>
                          </FormGroup>
                        ),
                        (occurence) => (
                          <>
                            <FormGroup>
                              <RepeatEveryInput>
                                <Input
                                  type="number"
                                  min={1}
                                  max={5}
                                  value={pipe(
                                    occurence,
                                    _monthlyOccurenceWeekdayOfMonthWeekOfMonth.get,
                                    prismPositiveInteger.reverseGet,
                                  )}
                                  onChange={(e) =>
                                    pipe(
                                      recurrence,
                                      _RecurrencePattern
                                        .compose(_RecurrencePatternMonthly)
                                        .composeLens(
                                          _occursOnRecurrencePatternMonthly,
                                        )
                                        .set(
                                          pipe(
                                            {
                                              ...occurence,
                                              weekOfMonth: pipe(
                                                Number(e.target.value),
                                                prismPositiveInteger.getOption,
                                                unsafeFromSome,
                                              ),
                                            },
                                            _MonthlyOccurenceWeekdayOfMonth.reverseGet,
                                            NonEmpty.of,
                                          ),
                                        ),
                                      onChange,
                                    )()
                                  }
                                />
                                <FormattedMessage defaultMessage="week of the month" />
                              </RepeatEveryInput>
                            </FormGroup>
                            <FormGroupInline inline check>
                              {pipe(
                                Object.values(DayOfWeek),
                                Arr.map((day) => (
                                  <InputLabel key={day}>
                                    <Input
                                      key={day}
                                      type="radio"
                                      checked={pipe(
                                        occurence,
                                        _monthlyOccurenceWeekdayOfMonthDayOfWeek.get,
                                        (a) => dayOfWeek.equals(a, day),
                                      )}
                                      onChange={pipe(
                                        recurrence,
                                        _RecurrencePattern
                                          .compose(_RecurrencePatternMonthly)
                                          .composeLens(
                                            _occursOnRecurrencePatternMonthly,
                                          )
                                          .set(
                                            pipe(
                                              {
                                                ...occurence,
                                                dayOfWeek: day,
                                              },
                                              _MonthlyOccurenceWeekdayOfMonth.reverseGet,
                                              NonEmpty.of,
                                            ),
                                          ),
                                        onChange,
                                      )}
                                    />
                                    <Capitalise>
                                      {/* TODO translations */}
                                      {dayOfWeekDaysShort.get(day)}
                                    </Capitalise>
                                  </InputLabel>
                                )),
                              )}
                            </FormGroupInline>
                          </>
                        ),
                      ),
                    )}
                  </FormGroup>
                </div>
              </>
            ) : (
              constNull
            )}

            <CustomLabel>
              <FormattedMessage defaultMessage="Ends" />
            </CustomLabel>
            <FormGroup>
              <FormGroup check>
                <InputLabel check>
                  <Input
                    type="radio"
                    checked={pipe(
                      recurrence.value.value.between,
                      ([_, end]) => end,
                      Opt.fold(
                        () => true,
                        () => false,
                      ),
                    )}
                    onChange={pipe(
                      recurrence,
                      _RecurrencePattern.modify(
                        (old) =>
                          ({
                            ...old,
                            value: {
                              ...old.value,
                              between: [old.value.between[0], Opt.none],
                            },
                          }) as RecurrencePattern,
                      ),
                      onChange,
                    )}
                  />
                  <FormattedMessage defaultMessage="Never" />
                </InputLabel>
              </FormGroup>
              <FormGroup check>
                <InputLabel>
                  <Input
                    type="radio"
                    disabled
                    checked={pipe(
                      recurrence.value.value.between,
                      ([_, end]) => end,
                      Opt.fold(
                        () => false,
                        () => true,
                      ),
                    )}
                    onChange={pipe(
                      recurrence,
                      _RecurrencePattern.modify(
                        (old) =>
                          ({
                            ...old,
                            value: {
                              ...old.value,
                              between: [old.value.between[0], Opt.some(now())],
                            },
                          }) as RecurrencePattern,
                      ),
                      onChange,
                    )}
                  />
                  <FormattedMessage defaultMessage="On" />
                  <DayInput
                    hideClear
                    disabled={pipe(
                      recurrence.value.value.between,
                      ([_, end]) => end,
                      Opt.fold(
                        () => true,
                        () => false,
                      ),
                    )}
                    min={Opt.none}
                    max={Opt.none}
                    step={Opt.none}
                    value={pipe(
                      recurrence,
                      _RecurrencePattern
                        .composeLens(_end)
                        .composePrism(Prism.some())
                        .composeLens(dayOfTime).getOption,
                    )}
                    onChange={(day) =>
                      pipe(
                        recurrence,
                        _RecurrencePattern
                          .composeLens(_end)
                          .set(
                            pipe(
                              day,
                              Opt.map(dayOfTime.set),
                              Opt.ap(
                                pipe(
                                  recurrence,
                                  _RecurrencePattern.composeLens(_start)
                                    .getOption,
                                ),
                              ),
                            ),
                          ),
                        onChange,
                      )
                    }
                  />
                </InputLabel>
              </FormGroup>
            </FormGroup>
          </>
        )}
      </div>
    )
  },
)`
  max-width: 500px;
`

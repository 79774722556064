import * as Bool from "fp-ts/es6/boolean"
import * as Eq from "fp-ts/es6/Eq"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Str from "fp-ts/es6/string"
import { Lens } from "monocle-ts"

export type GoogleApi = {
  isEnabled: boolean
  scopes: ReadonlyArray<string>
}
export const googleApi = Eq.struct<GoogleApi>({
  isEnabled: Bool.Eq,
  scopes: Arr.getEq(Str.Eq),
})
export const _isEnabled = Lens.fromProp<GoogleApi>()("isEnabled")
export const _scopes = Lens.fromProp<GoogleApi>()("scopes")

export enum GoogleApiScopeSet {
  googleFit = "googleFit",
}

export const requiredScopes: Record<
  GoogleApiScopeSet,
  ReadonlyArray<string>
> = {
  googleFit: [
    "https://www.googleapis.com/auth/fitness.activity.read",
    "https://www.googleapis.com/auth/fitness.body.read",
    "https://www.googleapis.com/auth/fitness.location.read",
  ],
}

import { useState } from "react"

import * as IO from "fp-ts/es6/IO"

export enum NavSection {
  dashboard = "dashboard",
  calendar = "calendar",
  sports = "sports",
  aboutMe = "aboutMe",
}

export const useStateIO =
  <a>(initial: IO.IO<a>): IO.IO<readonly [a, (a: a) => IO.IO<void>]> =>
  () => {
    const [value, setValue] = useState(initial)

    return [value, (a) => () => setValue(a)]
  }

import * as F from "fp-ts/es6/function"
import { flow, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import { TaskEither } from "fp-ts/es6/TaskEither"

import { wrapTaskEither } from "./type"

// The native ShareData type is too generic
export type ShareData =
  | {
      text: string
      title?: string
      url?: string
    }
  | {
      text?: string
      title: string
      url?: string
    }
  | {
      text?: string
      title?: string
      url: string
    }

const writeClipboardText = wrapTaskEither(
  navigator.clipboard.writeText,
  F.identity as (v: any) => Error,
)

const formatShareClipboardText = ({
  text = "",
  title = "",
  url = "",
}: ShareData) => `${title && `${title}\n\n`}${text && `${text}\n`}${url}`

export type NavigatorShare = (options: ShareData) => TaskEither<Error, void>
export const share: NavigatorShare = pipe(
  navigator?.share,
  Opt.fromNullable,
  Opt.map((share) => wrapTaskEither(share, F.identity as (v: any) => Error)),
  Opt.getOrElse(() => flow(formatShareClipboardText, writeClipboardText)),
)

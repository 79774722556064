import { identity, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Str from "fp-ts/es6/string"

export type Capitalise<s extends string> = Capitalize<s>
export const capitalise = <k extends string>(k: k): Capitalize<k> => {
  const head = k[0]
  return (
    head !== undefined ? `${head.toUpperCase()}${k.slice(1)}` : ""
  ) as Capitalize<k>
}

const limitList =
  (limit?: number) =>
  <A>(as: ReadonlyArray<A>) =>
    pipe(
      limit,
      Opt.fromNullable,
      Opt.fold(() => identity, Arr.takeLeft),
    )(as)

export const humanList =
  (limit?: number) =>
  (as: ReadonlyArray<string>): string =>
    pipe(as, Arr.reverse, limitList(limit), ([head, ...as]) =>
      pipe(
        [pipe(as, Arr.reverse, Arr.intercalate(Str.Monoid)(", ")), head],
        Arr.intercalate(Str.Monoid)(" & "),
      ),
    )

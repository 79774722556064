import { FC } from "react"
import { IntlShape, useIntl } from "react-intl"

import { capitalise } from "@fitnesspilot/data-common"

import { formatDynamicMessage } from "@fitnesspilot/components-common/dist/atoms/IntlProvider/IntlProvider"
import { EquipmentType } from "@fitnesspilot/data-activity/dist/activity/Equipment"

const names = {
  [EquipmentType.barbell]: "barbell",
  [EquipmentType.dumbbell]: "dumbbell",
  [EquipmentType.cableMachine]: "cable machine",
  [EquipmentType.butterflyMachine]: "butterfly machine",
  [EquipmentType.inclineBench]: "incline bench",
  [EquipmentType.treadmill]: "treadmill",
  [EquipmentType.rowingMachine]: "rowing machine",
  [EquipmentType.stationaryBicycle]: "stationary bicycle",
  [EquipmentType.pullUpBar]: "pull-up bar",
  [EquipmentType.rope]: "rope",
  [EquipmentType.skippingRope]: "skipping rope",
  [EquipmentType.box]: "box",
  [EquipmentType.sledgehammer]: "sledgehammer",
  [EquipmentType.theraband]: "theraband",
  [EquipmentType.rollerskates]: "rollerskates",
  [EquipmentType.medicineBalls]: "medicine balls",
  [EquipmentType.exerciseBall]: "exercise ball",
  [EquipmentType.kettlebell]: "kettlebell",
  [EquipmentType.foamRoll]: "foam roll",
  [EquipmentType.trx]: "TRX",
  [EquipmentType.sandbag]: "sandbag",
  [EquipmentType.bodybar]: "bodybar",
  [EquipmentType.tire]: "tire",
  [EquipmentType.punchingBag]: "punching-bag",
  [EquipmentType.smithMachine]: "smith machine",
  [EquipmentType.bodybarBroom]: "bodybar/broom",
  [EquipmentType.prowler]: "prowler",
  [EquipmentType.bicycle]: "bicycle",
  [EquipmentType.machine]: "machine",
  [EquipmentType.bands]: "bands",
  [EquipmentType.other]: "other",
  [EquipmentType.cable]: "cable",
  [EquipmentType.medicineBall]: "medicine ball",
  [EquipmentType.trainingPlate]: "training plate",
  [EquipmentType.kettlebells]: "kettlebells",
  [EquipmentType.eZCurlBar]: "e-z curl bar",
  [EquipmentType.beltRopeOrBand]: "belt, rope or band",
  [EquipmentType.sledgehammerTire]: "sledgehammer, tire",
  [EquipmentType["2BoxesOrBenches"]]: "2 boxes or benches",
  [EquipmentType.bar]: "bar",
  [EquipmentType.chains]: "chains",
  [EquipmentType.anyWeight]: "Any weight",
}

export type EquipmentNameProps = {
  id: EquipmentType
}

export const equipmentName =
  (intl: IntlShape) =>
  ({ id }: EquipmentNameProps) =>
    formatDynamicMessage(intl)({
      id: `EquipmentName.${id}`,
      defaultMessage: capitalise(names[id]),
    })

export const EquipmentName: FC<EquipmentNameProps> = (props) => (
  <>{equipmentName(useIntl())(props)}</>
)

import { ReactNode } from "react"
import { FormattedMessage } from "react-intl"

import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"

import { CoachTask } from "@fitnesspilot/components-coach-task/dist/molecules/CoachTask/CoachTask"
import {
  CoachTaskImportance,
  coachTaskImportance,
} from "@fitnesspilot/data-coach-task/dist/CoachTask"
import { KnownStepSet, StepSet } from "@fitnesspilot/data-help"

const importanceNames: Readonly<Record<CoachTaskImportance, ReactNode>> = {
  [CoachTaskImportance.important]: (
    <FormattedMessage defaultMessage="Important" />
  ),
  [CoachTaskImportance.recommended]: (
    <FormattedMessage defaultMessage="Recommended" />
  ),
  [CoachTaskImportance.optional]: (
    <FormattedMessage defaultMessage="Optional" />
  ),
  [CoachTaskImportance.info]: <FormattedMessage defaultMessage="Info" />,
}

export const dashboard: StepSet = {
  id: KnownStepSet.dashboard,
  title: <FormattedMessage defaultMessage="Your Personal Dashboard" />,
  steps: [
    {
      target: '[data-help-mode="mainNav"] [data-help-mode="dashboardLink"]',
      continueOnClick: true,
      title: <FormattedMessage defaultMessage="Dashboard" />,
      content: (
        <>
          <p>
            <FormattedMessage defaultMessage="The dashboard gives an overview over your recovery, today's events, and open tasks, advice, and recommendation given by your coach." />
          </p>

          <p>
            <FormattedMessage defaultMessage="Now please click here to continue to the dashboard." />
          </p>
        </>
      ),
      showNext: false,
    },
    {
      target: '[data-help-mode="dashboard"] [data-help-mode="coachTasks"]',
      availableOn: ["/"],
      title: <FormattedMessage defaultMessage="Coach Tasks" />,
      content: (
        <>
          <FormattedMessage defaultMessage="The Coach will give you individual advice according to your needs." />

          <div>
            <header>
              <FormattedMessage defaultMessage="The tasks are colour-coded according to their importance:" />

              {pipe(
                Object.values(CoachTaskImportance),
                Arr.sort(coachTaskImportance),
                Arr.map((i) => (
                  <CoachTask
                    key={i}
                    importance={i}
                    label={importanceNames[i]}
                    actions={[]}
                    onDismiss={IO.of(undefined)}
                  />
                )),
              )}
            </header>
          </div>
        </>
      ),
    },
    {
      target: '[data-help-mode="dashboard"] [data-help-mode="upcomingEvents"]',
      availableOn: ["/"],
      title: <FormattedMessage defaultMessage="Ahead of you Today" />,
      content: (
        <FormattedMessage defaultMessage="These are your upcoming planned activities for today." />
      ),
    },
    {
      target: '[data-help-mode="dashboard"] [data-help-mode="recoveryStatus"]',
      availableOn: ["/"],
      title: <FormattedMessage defaultMessage="Recovery Status" />,
      content: (
        <FormattedMessage defaultMessage="The colour of the dots and the heart show the recovery status of muscle groups, ranging from green for fully recovered through red for not at all recovered; you can use this information to plan your next activities accordingly." />
      ),
    },
  ],
  startOn: Opt.none,
  href: "/",
}

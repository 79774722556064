import { Dispatch, FC, ReactNode, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { FormattedMessage } from "react-intl"
import Media from "react-media"
import { connect, ConnectedProps } from "react-redux"
import { Button } from "reactstrap"
import { withTheme } from "@emotion/react"
import styled from "@emotion/styled"

import * as Foldable from "fp-ts/es6/Foldable"
import { constVoid, flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as Semigroup from "fp-ts/es6/Semigroup"
import * as Tree from "fp-ts/es6/Tree"
import { now, time } from "time-ts/es6"
import {
  mapKeys,
  optionLensToOptional,
  useStateIO,
} from "@fitnesspilot/data-common"

import { MuscleGroupPreferencesFieldsets } from "@fitnesspilot/components-activity/dist/molecules/MuscleGroupPreferencesFieldsets/MuscleGroupPreferencesFieldsets"
import {
  FormData,
  formData,
  MuscleFormData,
} from "@fitnesspilot/components-activity/dist/molecules/MusclePreferenceFieldset/MusclePreferenceFieldset"
import { ClearButtonGroup } from "@fitnesspilot/components-common/dist/atoms/ClearButtonGroup/ClearButtonGroup"
import {
  DjIcon,
  IconType,
} from "@fitnesspilot/components-common/dist/atoms/DjIcon/DjIcon"
import { Theme } from "@fitnesspilot/components-common/dist/atoms/ThemeProvider/Theme"
import { CancelButton } from "@fitnesspilot/components-common/dist/molecules/CancelButton/CancelButton"
import { Content } from "@fitnesspilot/components-common/dist/molecules/Content/Content"
import { Header } from "@fitnesspilot/components-common/dist/molecules/Header/Header"
import { SaveButton } from "@fitnesspilot/components-common/dist/molecules/SaveButton/SaveButton"
import { useFormChangeEffect } from "@fitnesspilot/components-common/dist/react"
import { BodyMapWithLabels } from "@fitnesspilot/components-human-body/dist/organisms/BodyMapWithLabels/BodyMapWithLabels"
import * as ActivityData from "@fitnesspilot/data-activity"
import * as EventData from "@fitnesspilot/data-event"
import * as Timeslot from "@fitnesspilot/data-event/dist/calendar/Timeslot"
import * as HumanBody from "@fitnesspilot/data-human-body/dist/humanBody"
import { MuscleId_ } from "@fitnesspilot/data-human-body/dist/Muscle"
import * as MuscleGroup from "@fitnesspilot/data-human-body/dist/muscleGroups"
import * as MuscleGroupStatus from "@fitnesspilot/data-human-body/dist/MuscleGroupStatus"
import * as Sex from "@fitnesspilot/data-human-body/dist/sex"
import * as UserData from "@fitnesspilot/data-user"

import { MainTemplate } from "../../templates/Main/Main"

import mem from "memoize-one"
import { push as pushHistory, RouterActions } from "redux-first-history"

const leqTime = Ord.leq(time)

enum View {
  body = "body",
  list = "list",
}

const viewTexts = {
  [View.body]: <FormattedMessage defaultMessage="Body" />,
  [View.list]: <FormattedMessage defaultMessage="List" />,
}

const ClearButtonGroupContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  & .btn {
    font-size: 1em;
  }
`

const _muscleSettings = ActivityData.selectors.state.compose(
  ActivityData.selectors.muscleSettings,
)

const mapState = (
  state: UserData.ParentState &
    EventData.ParentState &
    ActivityData.ParentState,
) => ({
  sex: pipe(
    state,
    UserData.selectors.state
      .composeLens(UserData.selectors.body)
      .composeOptional(optionLensToOptional(HumanBody.sex)).getOption,
    Opt.getOrElse(() => Sex.Sex.male),
  ),
  muscleGroupLevels: pipe(
    state,
    EventData.selectors.state.compose(EventData.selectors.timeslots).get,
    mem(
      flow(
        Arr.findLast((timeslot) => leqTime(timeslot.time, now())),
        Opt.fold(
          () => MuscleGroupStatus.defaultMuscleGroupStatus,
          ({ recoveryStatus }) =>
            pipe(
              state,
              _muscleSettings.get,
              Timeslot._RecoveryStatusAsMuscleGroupStatus,
            ).get(recoveryStatus),
        ),
      ),
    ),
  ),
  defaultValues: pipe(
    state,
    _muscleSettings.get,
    Arr.chain((t) => Foldable.toReadonlyArray(Tree.Foldable)(t)),
    Arr.filter((ms) => Object.values(MuscleId_).includes(ms.id as MuscleId_)),
    NonEmpty.fromReadonlyArray,
    Opt.map(
      NonEmpty.reduce({} as FormData, (as, { id, enabled, priority }) => ({
        ...as,
        [id as MuscleId_]: {
          enabled,
          priority,
        } as MuscleFormData,
      })),
    ),
    Opt.getOrElse<Partial<FormData>>(() => ({})),
  ),
})

const mapDispatch = (
  dispatch: Dispatch<RouterActions | ActivityData.Action>,
) => {
  const dispatch_ =
    (act: RouterActions | ActivityData.Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, constVoid)

  return {
    onMuscleGroupClick: (muscleGroup: MuscleGroup.MuscleGroups) =>
      pipe(pushHistory(`/sports/muscle-preferences/${muscleGroup}`), dispatch_),
    onToggle: (muscleGroup: MuscleGroup.MuscleGroups) =>
      pipe(ActivityData.toggleMuscleGroup(muscleGroup), dispatch_),
    onSubmit: flow(ActivityData.setMuscleSettings, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type OwnProps = Record<string, never>

export type MuscleStatusProps = PropsFromRedux & OwnProps

export const MuscleStatusPage: FC<MuscleStatusProps> = withTheme(
  ({
    sex,
    muscleGroupLevels,
    defaultValues,
    onMuscleGroupClick,
    onToggle,
    onSubmit,
    theme,
  }: MuscleStatusProps & { theme: Theme }) => {
    const [currentView, setCurrentView] = useStateIO<View>(() => View.body)()

    const [openMuscleGroupPopover, setOpenMuscleGroupPopover] = useStateIO<
      Opt.Option<MuscleGroup.MuscleGroups>
    >(IO.of(Opt.none))()

    const { handleSubmit, ...form } = useForm<FormData>({
      defaultValues,
    })

    useEffect(() => {
      if (!form.formState.isDirty) {
        form.reset(defaultValues)
      }
    }, [defaultValues])

    const submit = handleSubmit((a) => onSubmit(a)())
    useFormChangeEffect(form, formData, submit)

    return (
      <MainTemplate
        header={
          <Header
            icon={<DjIcon icon={IconType.weight} />}
            title={
              <FormattedMessage defaultMessage="Muscle Status & Priorities" />
            }
          />
        }
      >
        <Media queries={theme.media}>
          {({ xl }) => (
            <Content data-help-mode="muscle">
              {xl && (
                <ClearButtonGroupContainer>
                  <ClearButtonGroup>
                    {pipe(
                      Object.values(View),
                      Arr.filterMap((v) =>
                        pipe(
                          viewTexts,
                          Rec.lookup(v),
                          Opt.map((t: ReactNode) => [v, t] as const),
                        ),
                      ),
                      Arr.map(([v, t]) => (
                        <Button
                          key={v}
                          color="active"
                          active={currentView === v}
                          onClick={setCurrentView(v)}
                        >
                          {t}
                        </Button>
                      )),
                    )}
                  </ClearButtonGroup>
                </ClearButtonGroupContainer>
              )}
              {xl && currentView === View.body && (
                <BodyMapWithLabels
                  {...{
                    sex,
                    muscleGroupLevels,
                    openMuscleGroupPopover,
                    onMuscleGroupClick,
                    onToggle,
                  }}
                  toggleMuscleGroupPopover={(
                    muscleGroup: MuscleGroup.MuscleGroups,
                    force: boolean,
                  ) =>
                    force === false
                      ? Opt.getEq(MuscleGroup.muscleGroups).equals(
                          openMuscleGroupPopover,
                          Opt.some(muscleGroup),
                        )
                        ? setOpenMuscleGroupPopover(Opt.none)
                        : IO.of(undefined)
                      : setOpenMuscleGroupPopover(Opt.some(muscleGroup))
                  }
                />
              )}
              {((xl && currentView === View.list) || !xl) && (
                <FormProvider {...{ handleSubmit }} {...form}>
                  <form onSubmit={submit}>
                    <MuscleGroupPreferencesFieldsets
                      id="muscleGroupPreferences"
                      groupLevels={pipe(
                        muscleGroupLevels,
                        mapKeys(
                          Semigroup.first<number | false>(),
                          MuscleGroup.muscleGroupIdAsMuscleGroups.reverseGet,
                        ),
                      )}
                    />
                  </form>
                </FormProvider>
              )}
            </Content>
          )}
        </Media>
      </MainTemplate>
    )
  },
)

export const MuscleStatusPageContainer = connector(MuscleStatusPage)

import { FormattedMessage } from "react-intl"
import styled from "@emotion/styled"

import { flow, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Pair from "fp-ts/es6/ReadonlyTuple"

import * as WithId from "@fitnesspilot/data-activity/dist/activity/WithId"
import * as ActivityWithSettings from "@fitnesspilot/data-activity/dist/activitySettings/ActivityWithSettings"
import * as Sport from "@fitnesspilot/data-activity/dist/sport/Sport"
import * as SportId from "@fitnesspilot/data-activity/dist/sport/SportId"

import { ActivityGrid as ActivityGrid_ } from "../../molecules/ActivityGrid/ActivityGrid"
import { SportTileSport } from "../../molecules/SportTile/SportTile"

const SectionWithHeadline = styled.section`
  padding-top: 1em;
`
const SectionHeadline = styled.h2``

const ActivityGrid = styled(ActivityGrid_)`
  grid-template-columns: repeat(1, 1fr);

  @media (${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: repeat(4, 1fr);
  }
`

export type MySportsGridProps = {
  sports: ReadonlyArray<Sport.SportWithId>
  activitiesWithSettings: ReadonlyArray<ActivityWithSettings.ActivityWithSettingsWithId>
}

export const MySportsGrid = ({
  sports,
  activitiesWithSettings,
}: MySportsGridProps) => {
  return (
    <>
      {pipe(
        sports,
        Arr.map(
          (sport) =>
            [
              sport,
              pipe(
                sport.id,
                SportId.sportIdAsActivityId.getOption,
                Opt.chain((id) =>
                  pipe(
                    activitiesWithSettings,
                    Arr.findFirst((a) => a.id === id),
                  ),
                ),
              ),
            ] as const,
        ),
        Arr.partition(([s]) =>
          pipe(
            s,
            WithId._value<SportId.SportId, Sport.Sport>().compose(
              Sport._isLiked,
            ).get,
          ),
        ),
        ({ left, right }) => [left, right] as const,
        Pair.bimap(
          flow(
            Arr.mapWithIndex((i, [sport, activity]) => (
              <SportTileSport
                key={i}
                {...{ sport, activity }}
                liked={true}
                autoHeight={true}
              />
            )),
            (acts) => (
              <SectionWithHeadline key={0}>
                <SectionHeadline>
                  <FormattedMessage defaultMessage="My sports" />
                </SectionHeadline>

                <ActivityGrid>{acts}</ActivityGrid>
              </SectionWithHeadline>
            ),
          ),
          flow(
            Arr.mapWithIndex((i, [sport, activity]) => (
              <SportTileSport
                key={i}
                {...{ sport, activity }}
                liked={false}
                autoHeight={true}
              />
            )),
            (acts) => (
              <SectionWithHeadline key={1}>
                <SectionHeadline>
                  <FormattedMessage defaultMessage="Other sports" />
                </SectionHeadline>

                <ActivityGrid>{acts}</ActivityGrid>
              </SectionWithHeadline>
            ),
          ),
        ),
        Arr.reverse,
      )}
    </>
  )
}

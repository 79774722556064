import { HTMLAttributes } from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

export const Fieldset = styled(
  ({
    className,
    border,
    ...props
  }: HTMLAttributes<HTMLDivElement> & { border?: boolean }) => (
    <div className={`c-fieldset ${className ?? ""}`} {...props} />
  ),
)`
  padding: 20px 0;

  ${({ theme, border }) =>
    (border ?? true) &&
    css`
      & + & {
        border-top: 1px solid ${theme.colours.grey[100].toString()};
      }
    `}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
  }
`

import { FC, memo, useCallback, useMemo, useRef, useState } from "react"
import { FaCheck, FaPlus, FaTrash } from "react-icons/fa"
import { FormattedMessage, useIntl } from "react-intl"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import { constant, constNull, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as Str from "fp-ts/es6/string"

import { activityName } from "@fitnesspilot/components-activity/dist/atoms/ActivityName/ActivityName"
import { Label } from "@fitnesspilot/components-common/dist/atoms/Label/Label"
import { VirtualList } from "@fitnesspilot/components-common/dist/atoms/VirtualList/VirtualList"
import { VirtualListItem } from "@fitnesspilot/components-common/dist/atoms/VirtualList/VirtualListItem"
import {
  ButtonWithIcon,
  IconSide,
} from "@fitnesspilot/components-common/dist/molecules/ButtonWithIcon/ButtonWithIcon"
import {
  ActivityId,
  stringAsActivityId,
} from "@fitnesspilot/data-activity/dist/activity/ActivityId"
import { ActivityType } from "@fitnesspilot/data-activity/dist/activity/ActivityType"
import * as WithId from "@fitnesspilot/data-activity/dist/activity/WithId"
import {
  ActivityInstanceFilters,
  filterActivityInstances,
} from "@fitnesspilot/data-activity/dist/activityFilters/ActivityInstanceFilters"
import {
  ActivityInstanceSorting,
  sortActivityInstances,
} from "@fitnesspilot/data-activity/dist/activityFilters/ActivitySorting"
import {
  ActivityInstanceNonGroup,
  foldActivityInstanceExercise,
  foldActivityInstanceNonGroup,
} from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"
import { ActivitySettingsExerciseListing } from "@fitnesspilot/data-activity/dist/activitySettings/ActivitySettingsExercise"
import { stringAsMuscleGroupId } from "@fitnesspilot/data-human-body/dist/muscleGroups"

import { ActivityInputs } from "../../molecules/ActivityInputs/ActivityInputs"
import { ActivityInstanceSearch } from "../../molecules/ActivitySearch/ActivitySearch"
import {
  EventActivity,
  EventActivityProps,
  EventActivityWrapper,
  EventActivityWrapperProps,
} from "../../organisms/EventActivity/EventActivity"

import { useVirtualizer } from "@tanstack/react-virtual"

const catalogActivityId: (v: ActivityInstanceNonGroup) => string =
  foldActivityInstanceNonGroup(
    (a) => pipe(a.activity.id, stringAsActivityId.reverseGet),
    (a) => pipe(a.activity.id, stringAsActivityId.reverseGet),
    (a) =>
      pipe(
        [
          "muscles",
          pipe(a.activity.id, stringAsActivityId.reverseGet),
          ...pipe(a.muscleGroupIds, Arr.map(stringAsMuscleGroupId.reverseGet)),
        ],
        Arr.intercalate(Str.Monoid)("-"),
      ),
    (a) => pipe(a.activity.id, stringAsActivityId.reverseGet),
  )

export enum ActivitySelectionView {
  add = "add",
  view = "view",
}

export type ActivitySelectionProps = {
  id: string
  view: ActivitySelectionView
  search: Opt.Option<string>
  sorting: ActivityInstanceSorting
  filters: ActivityInstanceFilters
  catalog: ReadonlyArray<ActivityInstanceNonGroup>
  activityListings: ReadonlyArray<
    WithId.WithId<ActivityId, ActivitySettingsExerciseListing>
  >
  selectedActivities: ReadonlyArray<ActivityInstanceNonGroup>
  onSelectActivity: (activity: ActivityInstanceNonGroup) => IO.IO<void>
  onUnselectActivity: (activityIndex: number) => IO.IO<void>
  onChangeSelectedActivity: (
    activityIndex: number,
    activity: ActivityInstanceNonGroup,
  ) => IO.IO<void>
  onChangeSorting: (sorting: ActivityInstanceSorting) => IO.IO<void>
  onChangeSearch: (v: Opt.Option<string>) => IO.IO<void>
  onChangeFilters: (filters: ActivityInstanceFilters) => IO.IO<void>
  onSetActivityListing: (payload: {
    activity: ActivityId
    listing: ActivitySettingsExerciseListing
  }) => IO.IO<void>
}

const Centered = styled.h2`
  text-align: center;
`

const ActivityList = memo(styled(VirtualList)<{ withSearch?: boolean }>`
  ${({ withSearch }) =>
    withSearch
      ? css`
          height: calc(100% - 70px);
        `
      : css`
          height: 100%;
        `}
`)
const ActivityListItem = VirtualListItem

const InCartLabel = styled(Label)`
  margin-left: 10px;
`

type InCartProps = {
  activity: ActivityInstanceNonGroup
  selectedActivities: ReadonlyArray<ActivityInstanceNonGroup>
}

const InCartCount = ({ activity, selectedActivities }: InCartProps) => {
  const count = useMemo(
    () =>
      pipe(
        selectedActivities,
        Arr.filter((a) => catalogActivityId(a) === catalogActivityId(activity)),
        NonEmpty.fromReadonlyArray,
        Opt.map((as) => as.length),
      ),
    [activity, selectedActivities],
  )

  return pipe(
    count,
    Opt.fold(constNull, (count) => (
      <InCartLabel noColon>
        <FormattedMessage
          defaultMessage="{count}x in selection"
          values={{ count }}
        />
      </InCartLabel>
    )),
  )
}

type PreselectedActivityProps = Omit<
  EventActivityProps,
  "children" | "bottomLeft"
> & {
  onChange: (activity: ActivityInstanceNonGroup) => IO.IO<void>
  onSave: IO.IO<void>
  onCancel: IO.IO<void>
}
const PreselectedActivity = ({
  onChange,
  onSave,
  onCancel,
  ...props
}: PreselectedActivityProps) => {
  const { activity } = props

  const bottomLeft = useMemo(
    () => (
      <>
        <ButtonWithIcon
          icon={<FaCheck />}
          iconSide={IconSide.left}
          type="button"
          color="success"
          onClick={onSave}
          data-help-mode="addToCart"
        >
          <FormattedMessage defaultMessage="Add to Selection" />
        </ButtonWithIcon>
        <ButtonWithIcon
          outline
          icon={<FaTrash color="red" />}
          iconSide={IconSide.left}
          type="button"
          color="inline"
          onClick={onCancel}
        >
          <FormattedMessage defaultMessage="Remove" />
        </ButtonWithIcon>
      </>
    ),
    [onSave, onCancel],
  )

  return (
    <EventActivity {...props} {...{ bottomLeft }}>
      <ActivityInputs {...{ activity, onChange }} />
    </EventActivity>
  )
}

type CatalogActivityProps = Omit<
  EventActivityWrapperProps,
  "children" | "bottomLeft"
> & {
  id: string
  selectedActivities: ReadonlyArray<ActivityInstanceNonGroup>
  onPreselect: (id: string, activity: ActivityInstanceNonGroup) => void
}
const CatalogActivity = ({
  id,
  selectedActivities,
  onPreselect,
  ...props
}: CatalogActivityProps) => {
  const { activity } = props

  const onClick = useCallback(
    () => onPreselect(id, activity),
    [id, activity, onPreselect],
  )

  const bottomLeft = useMemo(
    () => (
      <>
        <ButtonWithIcon
          icon={<FaPlus />}
          iconSide={IconSide.left}
          type="button"
          color="primary"
          {...{ onClick }}
          data-help-mode="select"
        >
          <FormattedMessage defaultMessage="Select" />
        </ButtonWithIcon>
        <InCartCount {...{ activity, selectedActivities }} />
      </>
    ),
    [activity, selectedActivities, onClick],
  )

  return <EventActivityWrapper {...props} {...{ bottomLeft }} />
}

type ActivitySelectedProps = Omit<EventActivityProps, "children"> & {
  onChange: (activity: ActivityInstanceNonGroup) => IO.IO<void>
  onRemove: IO.IO<void>
}
const ActivitySelected = ({
  onChange,
  onRemove,
  ...props
}: ActivitySelectedProps) => {
  const { activity } = props
  return (
    <EventActivity
      {...props}
      bottomLeft={
        <ButtonWithIcon
          outline
          icon={<FaTrash color="red" />}
          iconSide={IconSide.left}
          type="button"
          color="inline"
          onClick={onRemove}
        >
          <FormattedMessage defaultMessage="Remove" />
        </ButtonWithIcon>
      }
    >
      <ActivityInputs {...{ activity, onChange }} />
    </EventActivity>
  )
}

export const ActivitySelection: FC<ActivitySelectionProps> = ({
  id,
  view,
  sorting,
  search,
  filters,
  catalog,
  selectedActivities,
  activityListings,
  onSelectActivity,
  onUnselectActivity,
  onChangeSelectedActivity,
  onChangeSearch,
  onChangeSorting,
  onChangeFilters,
  onSetActivityListing,
}) => {
  const [preselectedActivities, setPreselectedActivities] = useState<
    Rec.ReadonlyRecord<string, ActivityInstanceNonGroup>
  >({})

  const intl = useIntl()

  const handleSetActivityListing = useCallback(
    (activity: ActivityInstanceNonGroup) =>
      (listing: ActivitySettingsExerciseListing) =>
        pipe(
          activity,
          foldActivityInstanceExercise(
            () => IO.of(undefined),
            (a) =>
              onSetActivityListing({
                activity: a.activity.id,
                listing,
              }),
          ),
        ),
    [onSetActivityListing],
  )

  const handlePreselectActivity = useCallback(
    (id: string, activity: ActivityInstanceNonGroup) =>
      pipe(
        preselectedActivities,
        Rec.upsertAt(id, activity),
        setPreselectedActivities,
      ),
    [preselectedActivities, setPreselectedActivities],
  )

  const listRef = useRef<HTMLDivElement>(null)

  const filteredCatalog_ = useMemo(
    () =>
      pipe(
        catalog,
        Arr.filter(
          (a) => ![ActivityType.job, ActivityType.sleep].includes(a.$type),
        ),
        filterActivityInstances(activityName(intl))(
          search,
          filters,
          activityListings,
        ),
      ),
    [catalog, search, filters, activityListings],
  )
  const filteredCatalog = useMemo(
    () =>
      pipe(
        filteredCatalog_,
        sortActivityInstances(activityName(intl))(sorting, activityListings),
      ),
    [filteredCatalog_, sorting, activityListings],
  )

  const catalogVirtualizer = useVirtualizer({
    count: filteredCatalog.length,
    getScrollElement: () => listRef.current,
    estimateSize: () => 370,
    overscan: 3,
  })
  const catalogVirtualItems = catalogVirtualizer.getVirtualItems()

  const selectedVirtualizer = useVirtualizer({
    count: selectedActivities.length,
    getScrollElement: () => listRef.current,
    estimateSize: () => 370,
    overscan: 3,
  })
  const selectedVirtualItems = selectedVirtualizer.getVirtualItems()

  return (
    <>
      {view === ActivitySelectionView.add ? (
        <>
          <ActivityInstanceSearch
            id={`${id}-search`}
            {...{
              search,
              sorting,
              filters,
              onChangeSearch,
              onChangeSorting,
              onChangeFilters,
            }}
          />
          <ActivityList
            withSearch
            ref={listRef}
            totalSize={catalogVirtualizer.getTotalSize()}
            start={catalogVirtualItems.at(0)?.start ?? 0}
          >
            {pipe(
              catalogVirtualItems,
              Arr.map(({ index }) =>
                pipe(filteredCatalog[index], (catalogActivity) => (
                  <ActivityListItem
                    key={catalogActivityId(catalogActivity)}
                    ref={catalogVirtualizer.measureElement}
                    data-help-mode="activityInstance"
                  >
                    {pipe(
                      preselectedActivities,
                      Rec.lookup(catalogActivityId(catalogActivity)),
                      Opt.map((a) => [catalogActivityId(a), a] as const),
                      Opt.fold(
                        () =>
                          pipe(catalogActivityId(catalogActivity), (id) => (
                            <CatalogActivity
                              {...{ id }}
                              activity={catalogActivity}
                              {...{
                                activityListings,
                                handleSetActivityListing,
                              }}
                              {...{ selectedActivities }}
                              onPreselect={handlePreselectActivity}
                            />
                          )),
                        ([id, preselectedActivity]) => (
                          <PreselectedActivity
                            activity={preselectedActivity}
                            activityListing={pipe(
                              activityListings,
                              Arr.findFirst(
                                (s) =>
                                  s.id ===
                                  preselectedActivity.value.activity.id,
                              ),
                              Opt.map((s) => s.value),
                            )}
                            onChange={(a) => () =>
                              pipe(
                                preselectedActivities,
                                Rec.upsertAt(id, a),
                                setPreselectedActivities,
                              )
                            }
                            onSave={pipe(
                              preselectedActivity,
                              onSelectActivity,
                              IO.chain(
                                constant(() =>
                                  pipe(
                                    preselectedActivities,
                                    Rec.deleteAt(id),
                                    setPreselectedActivities,
                                  ),
                                ),
                              ),
                            )}
                            onCancel={() =>
                              pipe(
                                preselectedActivities,
                                Rec.deleteAt(id),
                                setPreselectedActivities,
                              )
                            }
                            onSetActivityListing={handleSetActivityListing(
                              preselectedActivity,
                            )}
                          />
                        ),
                      ),
                    )}
                  </ActivityListItem>
                )),
              ),
            )}
          </ActivityList>
        </>
      ) : (
        <ActivityList
          withSearch
          ref={listRef}
          totalSize={selectedVirtualizer.getTotalSize()}
          start={selectedVirtualItems.at(0)?.start ?? 0}
        >
          {pipe(
            selectedActivities,
            NonEmpty.fromReadonlyArray,
            Opt.fold(
              () => [
                <Centered key="emptyExerciseSelection">
                  <FormattedMessage defaultMessage="The Exercise Selection is empty.<br></br>Please add activities in the previous step." />
                </Centered>,
              ],
              () =>
                pipe(
                  selectedVirtualItems,
                  Arr.map(({ index }) =>
                    pipe(selectedActivities[index], (activity) => (
                      <ActivityListItem
                        key={index}
                        ref={selectedVirtualizer.measureElement}
                      >
                        <ActivitySelected
                          activity={activity}
                          activityListing={pipe(
                            activityListings,
                            Arr.findFirst(
                              (s) => s.id === activity.value.activity.id,
                            ),
                            Opt.map((s) => s.value),
                          )}
                          topRight={null}
                          onChange={(a) => onChangeSelectedActivity(index, a)}
                          onRemove={onUnselectActivity(index)}
                          onSetActivityListing={handleSetActivityListing(
                            activity,
                          )}
                        />
                      </ActivityListItem>
                    )),
                  ),
                ),
            ),
          )}
        </ActivityList>
      )}
    </>
  )
}

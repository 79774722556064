/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityFromJSONTyped, ActivityToJSON, } from "./Activity";
import { ActivityTypeFromJSON, ActivityTypeToJSON, } from "./ActivityType";
/**
 * Check if a given object implements the ActivitySleep interface.
 */
export function instanceOfActivitySleep(value) {
    let isInstance = true;
    isInstance = isInstance && "$type" in value;
    return isInstance;
}
export function ActivitySleepFromJSON(json) {
    return ActivitySleepFromJSONTyped(json, false);
}
export function ActivitySleepFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        ...ActivityFromJSONTyped(json, ignoreDiscriminator),
        $type: ActivityTypeFromJSON(json.$type),
    };
}
export function ActivitySleepToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...ActivityToJSON(value, true),
        $type: ActivityTypeToJSON(value.$type),
    };
}

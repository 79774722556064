/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from "../runtime";
import { UnitPreferencesFromJSON, UnitPreferencesToJSON, } from "./UnitPreferences";
import { UserFromJSON, UserToJSON } from "./User";
/**
 * Check if a given object implements the WizardData interface.
 */
export function instanceOfWizardData(value) {
    let isInstance = true;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "activityRecommendations" in value;
    return isInstance;
}
export function WizardDataFromJSON(json) {
    return WizardDataFromJSONTyped(json, false);
}
export function WizardDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        units: !exists(json, "units")
            ? undefined
            : UnitPreferencesFromJSON(json.units),
        user: UserFromJSON(json.user),
        activityRecommendations: json.activityRecommendations,
    };
}
export function WizardDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        units: UnitPreferencesToJSON(value.units),
        user: UserToJSON(value.user),
        activityRecommendations: value.activityRecommendations,
    };
}

import { FormattedMessage } from "react-intl"

import * as Opt from "fp-ts/es6/Option"

import { KnownStepSet, StepSet } from "@fitnesspilot/data-help"

export const sports: StepSet = {
  id: KnownStepSet.sports,
  title: <FormattedMessage defaultMessage="Your Sports" />,
  steps: [
    {
      target: '[data-help-mode="mainNav"] [data-help-mode="sportsLink"]',
      continueOnClick: true,
      title: <FormattedMessage defaultMessage="Sports" />,
      content: (
        <>
          <p>
            <FormattedMessage defaultMessage="The sports section gives you an overview over available sports and allows you to customise and configure them." />
          </p>

          <p>
            <FormattedMessage defaultMessage="Now please click here to continue to the sports section." />
          </p>
        </>
      ),
      showNext: false,
    },
    {
      target: '[data-help-mode="mainNav"] [data-help-mode="mySports"]',
      availableOn: ["/sports"],
      title: <FormattedMessage defaultMessage="My Sports" />,
      content: (
        <FormattedMessage defaultMessage="In this sidebar you will always find your favorite sports." />
      ),
    },
    {
      target:
        '[data-help-mode="sportsTabs"] [data-help-mode="statisticsAndEvents"]',
      availableOn: false,
      title: <FormattedMessage defaultMessage="Statistics & Events" />,
      content: (
        <FormattedMessage defaultMessage="If you are in one of your favourite sports, you can look at the related statistics .At the moment we would like to ask you here for your feedback: Which statistics would you like to see here?" />
      ),
    },
    {
      target: '[data-help-mode="sportsTabs"] [data-help-mode="exercises"]',
      title: <FormattedMessage defaultMessage="Exercises" />,
      content: (
        <FormattedMessage defaultMessage="Here you find exercises that relate to your sport preference. You can filter them by muscle groups." />
      ),
    },
    {
      target:
        '[data-help-mode="sportsTabs"] [data-help-mode="workoutSettings"]',
      title: <FormattedMessage defaultMessage="Workout Settings" />,
      content: (
        <FormattedMessage defaultMessage="Here you make basic settings for your sport preference. You can set the intensity and the favorite muscle groups. On this basis we make recommendations." />
      ),
    },
    {
      target: '[data-help-mode="muscle"]',
      availableOn: ["/sports"],
      alignment: "top",
      title: (
        <FormattedMessage defaultMessage="Your general muscle preferences" />
      ),
      content: (
        <FormattedMessage defaultMessage="Here you can enter your general muscle preferences and how important they are to you." />
      ),
    },
  ],
  startOn: Opt.none,
  href: "/sports",
}

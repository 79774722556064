import { FC } from "react"

import { flow } from "fp-ts/es6/function"
import {
  isoStringAsLocalTimeOfDay,
  LocalTimeOfDay,
  LocalTimeOfDayDuration,
  secondsAsLocalTimeOfDayDuration,
} from "time-ts/es6"

import { input, InputProps } from "./input"

export type LocalTimeOfDayInputProps = InputProps<
  LocalTimeOfDay,
  LocalTimeOfDayDuration
>

// Note that min & max only work on input[type=time] when they're both set
export const LocalTimeOfDayInput: FC<LocalTimeOfDayInputProps> = input(
  "time",
  isoStringAsLocalTimeOfDay,
  flow(secondsAsLocalTimeOfDayDuration.reverseGet, String),
)

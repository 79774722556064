import { FC } from "react"
import { FormattedMessage } from "react-intl"
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"

import { EventChangeType } from "@fitnesspilot/api-combined"
import { CancelButton } from "@fitnesspilot/components-common/dist/molecules/CancelButton/CancelButton"
import { Modal } from "@fitnesspilot/components-common/dist/organisms/Modal/Modal"

export enum EventChangeAction {
  change = "change",
  delete = "delete",
}

export type EventChangeModalProps = {
  id: string
  isOpen: boolean
  action: EventChangeAction
  onChange: (v: { changeType: EventChangeType }) => IO.IO<void>
  onDelete: (v: { changeType: EventChangeType }) => IO.IO<void>
  onCancel: IO.IO<void>
}

export const EventChangeModal: FC<EventChangeModalProps> = ({
  id,
  action,
  onChange,
  onDelete,
  ...props
}) => {
  const { isOpen, onCancel } = props

  const onSubmit = (changeType: EventChangeType) =>
    pipe(
      action,
      (a) =>
        ({
          [EventChangeAction.change]: onChange({ changeType }),
          [EventChangeAction.delete]: onDelete({ changeType }),
        })[a],
    )

  return (
    <Modal toggle={onCancel} {...{ isOpen }}>
      <ModalHeader tag="h1" toggle={onCancel}>
        {
          {
            change: <FormattedMessage defaultMessage="Change event" />,
            delete: <FormattedMessage defaultMessage="Delete event" />,
          }[action]
        }
      </ModalHeader>

      <ModalBody>
        {
          {
            change: (
              <FormattedMessage defaultMessage="Please choose if you want to change this single event, the event you selected and all events following ones or all events including ones in the past." />
            ),
            delete: (
              <FormattedMessage defaultMessage="Please choose if you want to delete this single event, the event you selected and all events following ones or all events including ones in the past." />
            ),
          }[action]
        }
      </ModalBody>

      <ModalFooter>
        <Button color="primary" onClick={onSubmit(EventChangeType.Single)}>
          <FormattedMessage defaultMessage="Single" />
        </Button>
        <Button color="primary" onClick={onSubmit(EventChangeType.Following)}>
          <FormattedMessage defaultMessage="Following" />
        </Button>
        <Button color="primary" onClick={onSubmit(EventChangeType.All)}>
          <FormattedMessage defaultMessage="All" />
        </Button>
        <CancelButton type="button" onClick={onCancel} />
      </ModalFooter>
    </Modal>
  )
}

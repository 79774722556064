import { FC, useMemo } from "react"
import { FaUser } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import styled from "@emotion/styled"

import { constVoid, flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as St from "fp-ts/es6/ReadonlySet"
import { fromTraversable } from "monocle-ts"
import { arrTrav, useStateIO } from "@fitnesspilot/data-common"

import { GeneralPreferencesForm } from "@fitnesspilot/components-activity/dist/organisms/GeneralPreferencesForm/GeneralPreferencesForm"
import { MySportsGrid } from "@fitnesspilot/components-activity/dist/organisms/MySportsGrid/MySportsGrid"
import { Header } from "@fitnesspilot/components-common/dist/molecules/Header/Header"
import { tabs } from "@fitnesspilot/components-common/dist/templates/Tabs/Tabs"
import { SportStatistics } from "@fitnesspilot/components-event/dist/organisms/SportStatistics/SportStatistics"
import {
  ActivityTagSportType,
  activityTagSportType,
  activityTagSportTypeFromActivityTag,
} from "@fitnesspilot/data-activity/dist/activity/ActivityTag"
import {
  _ActivityExerciseWithSettingsWithId,
  ActivityWithSettingsWithId,
} from "@fitnesspilot/data-activity/dist/activitySettings/ActivityWithSettings"
import * as ActivityData from "@fitnesspilot/data-activity/dist/store"
import { MySportsTab } from "@fitnesspilot/data-activity/dist/store/MySports"
import * as Event from "@fitnesspilot/data-event/dist/calendar/Event"
import * as EventOrRecommendation from "@fitnesspilot/data-event/dist/calendar/EventOrRecommendation"
import * as EventData from "@fitnesspilot/data-event/dist/store"

import { MainTemplate } from "../../templates/Main/Main"

import mem from "memoize-one"
import { Dispatch } from "redux"

const Tab = MySportsTab

const Tabs = styled(tabs<MySportsTab>())`
  padding-top: 10px;
  background: #eeeef8;
`

type ParentState = ActivityData.ParentState & EventData.ParentState
type Action = ActivityData.Action | EventData.Action

const mapState = (state: ParentState) => ({
  sports: pipe(
    state,
    ActivityData.selectors.state.compose(ActivityData.selectors.sports).get,
  ),
  activitiesWithSettings: pipe(
    state,
    ActivityData.selectors.state.compose(
      ActivityData.selectors.activitiesWithSettings,
    ).get,
    mem(
      fromTraversable(Arr.Traversable)<ActivityWithSettingsWithId>()
        .composePrism(_ActivityExerciseWithSettingsWithId)
        .filter((a) =>
          pipe(
            a.value.activity.tags,
            St.map(activityTagSportType)(activityTagSportTypeFromActivityTag),
            St.elem(activityTagSportType)(ActivityTagSportType.individual),
          ),
        )
        .asFold().getAll,
    ),
  ),
  generalSettings: ActivityData.selectors.state
    .compose(ActivityData.selectors.generalSportsSettings)
    .get(state),
  eventsOrRecommendations: EventData.selectors.state
    .compose(EventData.selectors.eventsOrRecommendations)
    .get(state),
})

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ =
    (act: Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, constVoid)

  return {
    onGeneralSettingsSubmit: flow(
      ActivityData.setGeneralSportsSettings,
      dispatch_,
    ),
    onFetchEvents: flow(EventData.fetchEventsForSportsStatistics, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

export type MySportsPageProps = PropsFromRedux

export const MySportsPage: FC<MySportsPageProps> = ({
  sports,
  activitiesWithSettings,
  generalSettings,
  eventsOrRecommendations,
  onGeneralSettingsSubmit,
  onFetchEvents,
  ...props
}) => {
  const [currentTab, setCurrentTab] = useStateIO<MySportsTab>(
    () => MySportsTab.sports,
  )()

  const events = useMemo(
    () =>
      pipe(
        eventsOrRecommendations,
        arrTrav<EventOrRecommendation.EventOrRecommendation>()
          .composePrism(EventOrRecommendation._EventWithId)
          .filter((e) => e.value.type === Event.EventType.activity)
          .asFold().getAll,
      ),
    [eventsOrRecommendations],
  )

  return (
    <MainTemplate
      header={
        <Header
          icon={<FaUser />}
          title={<FormattedMessage defaultMessage="My Sports" />}
        />
      }
    >
      <Tabs
        tabTexts={{
          [Tab.sports]: <FormattedMessage defaultMessage="Sports" />,
          [Tab.statistics]: <FormattedMessage defaultMessage="Statistics" />,
          [Tab.preferences]: (
            <FormattedMessage defaultMessage="General Preferences" />
          ),
        }}
        tabs={[Tab.sports, Tab.statistics, Tab.preferences]}
        {...props}
        {...{ currentTab }}
        onChangeTab={setCurrentTab}
      >
        {{
          [Tab.sports]: (
            <MySportsGrid {...{ sports, activitiesWithSettings }} />
          ),
          [Tab.statistics]: (
            <SportStatistics {...{ events }} onView={onFetchEvents()} />
          ),
          [Tab.preferences]: (
            <GeneralPreferencesForm
              id="generalPreferences"
              defaultValues={{
                recommendations: generalSettings.enableExerciseRecommendations,
                maxExerciseDays: generalSettings.maxExerciseDaysPerP1W,
                maxCardioUnits: generalSettings.maxCardioDaysPerP1W,
              }}
              onSubmit={({
                recommendations,
                maxExerciseDays,
                maxCardioUnits,
              }) =>
                onGeneralSettingsSubmit({
                  enableExerciseRecommendations: recommendations,
                  maxExerciseDaysPerP1W: maxExerciseDays,
                  maxCardioDaysPerP1W: maxCardioUnits,
                })()
              }
            />
          ),
        }}
      </Tabs>
    </MainTemplate>
  )
}

export const MySportsPageContainer = connector(MySportsPage)

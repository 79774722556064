import * as Eq from "fp-ts/es6/Eq"
import * as Opt from "fp-ts/es6/Option"
import * as Str from "fp-ts/es6/string"
import { Lens } from "monocle-ts"

export type ActivitySleep = {
  title: string
  instructions: Opt.Option<string>
}
export const activitySleep = Eq.struct<ActivitySleep>({
  title: Str.Eq,
  instructions: Opt.getEq(Str.Eq),
})
export const _title = Lens.fromProp<ActivitySleep>()("title")
export const _instructions = Lens.fromProp<ActivitySleep>()("instructions")

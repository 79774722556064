import { iso } from "newtype-ts";
import { on } from "../internal";
import { Temporal } from "@js-temporal/polyfill";
export const isoLocal = () => iso();
const liftToLocal = () => on(isoLocal().get);
export const local = (ord) => ({
    equals: liftToLocal()(ord.equals),
    compare: liftToLocal()(ord.compare),
});
export const equals = (ord) => {
    const local_ = local(ord);
    return (d2) => (d) => local_.equals(d, d2);
};
export const compare = (ord) => {
    const local_ = local(ord);
    return (d2) => (d) => local_.compare(d, d2);
};
export const currentTimeZone = () => new Temporal.TimeZone(Temporal.Now.timeZoneId());

import { flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import { Prism } from "monocle-ts"

import { arrTrav } from "./type"

// only add locales that have translations
// to avoid missing translations debug messages
export enum Locale {
  en = "en",
  // enGB = "en-GB",
  // enUS = "en-US",
  de = "de",
}
export const defaultLocale = Locale.en

const isLocale = (v: string): v is Locale =>
  ([Locale.en, Locale.de] as ReadonlyArray<string>).includes(v)

const stringAsLocale = Prism.fromPredicate(isLocale)

export const currentLocale: IO.IO<Locale> = pipe(
  () => navigator.languages,
  IO.map(
    flow(
      (a) =>
        arrTrav<string>().composePrism(stringAsLocale).asFold().headOption(a),
      Opt.getOrElse((): Locale => Locale.en),
    ),
  ),
)

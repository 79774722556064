import { ReadonlyNonEmptyArray } from "fp-ts/es6/ReadonlyNonEmptyArray"

import { CookieCategory } from "@fitnesspilot/components-cookie-consent"

import { CookieId, FpCookie } from "../cookieConsent"
import { cookies } from "./internal"

export const stateKey = "legal"

export type CookieConsentState = {
  show: boolean
  cookies: ReadonlyNonEmptyArray<FpCookie>
  expanedCategories: ReadonlyArray<CookieCategory>
  checkedCookies: ReadonlyArray<CookieId>
  acceptedCookies: ReadonlyArray<CookieId>
}

export type State = {
  cookieConsent: CookieConsentState
}
export const initialState: State = {
  cookieConsent: {
    show: true,
    expanedCategories: [],
    cookies: cookies,
    checkedCookies: [],
    acceptedCookies: [],
  },
}

export type ParentState = { [k in typeof stateKey]: State }

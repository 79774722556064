import { FC, ReactNode } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { FaEdit } from "react-icons/fa"
import { FormattedMessage, useIntl } from "react-intl"
import FormattedDuration from "react-intl-formatted-duration"

import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import {
  currentTimeZone,
  dateAsTime,
  localTimeAsZonedTime,
  localTimeFromDayAndLocalTimeOfDay,
  mkZonedLocalTime,
  today,
  zonedTimeToTime,
} from "time-ts/es6"
import { useStateIO } from "@fitnesspilot/data-common"

import { FormAnswersProvided } from "@fitnesspilot/components-common/dist/atoms/AnswersProvided/AnswersProvided"
import { dayOfWeekName } from "@fitnesspilot/components-common/dist/atoms/DayOfWeekName/DayOfWeekName"
import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import { FieldsetHeader } from "@fitnesspilot/components-common/dist/atoms/FieldsetHeader/FieldsetHeader"
import {
  ButtonWithIcon,
  IconSide,
} from "@fitnesspilot/components-common/dist/molecules/ButtonWithIcon/ButtonWithIcon"
import { FormGroup } from "@fitnesspilot/components-common/dist/molecules/FormGroup/FormGroup"
import * as DayOfWeek from "@fitnesspilot/data-common/dist/DayOfWeek"
import { initialState } from "@fitnesspilot/data-user"
import * as Sleep from "@fitnesspilot/data-user/dist/Sleep"

import { SleepTimeModal } from "../SleepTimeModal/SleepTimeModal"

export type FormData = {
  sleep: {
    time: Rec.ReadonlyRecord<DayOfWeek.DayOfWeek, [Date, Date]>
  }
}
const FormDataKeys: Rec.ReadonlyRecord<keyof FormData, null> = {
  sleep: null,
}

export type SleepFieldsetProps = {
  id: string
  showAnswersProvided?: boolean
}

export const SleepFieldset: FC<SleepFieldsetProps> = ({
  id,
  showAnswersProvided,
}) => {
  const intl = useIntl()
  const [isTimeModalOpen, setIsTimeModalOpen] = useStateIO(() => false)()
  const form = useFormContext<FormData>()

  const { time } = useWatch({ control: form.control, name: "sleep" })

  return (
    <>
      <FieldsetHeader
        title={<FormattedMessage defaultMessage="Sleep" />}
        titleRight={
          showAnswersProvided && (
            <FormAnswersProvided<FormData> keys={FormDataKeys} />
          )
        }
        description={
          <FormattedMessage defaultMessage="Rest is important. Improve your physical and mental progress and boost your abilities by giving yourself the recovery you need. Setting it here will make us also consider your sleep in recovery calculations and recommendations." />
        }
      />

      <Fieldset>
        <FormGroup
          inputId={`${id}-time`}
          label={<FormattedMessage defaultMessage="Time" />}
        >
          <table>
            <tbody>
              {pipe(
                time,
                Rec.mapWithIndex((dow: DayOfWeek.DayOfWeek, time) => (
                  <tr key={dow}>
                    <FormattedMessage
                      defaultMessage="<th>{dayOfWeek}:</th><td>{start, date, ::Hm}–{end, date, ::Hm}</td>"
                      values={{
                        th: (children: ReactNode) => (
                          <th scope="row">{children}</th>
                        ),
                        td: (children: ReactNode) => <td>{children}</td>,
                        dayOfWeek: dayOfWeekName(intl)({ id: dow }),
                        start: time[0],
                        end: time[1],
                      }}
                    />
                  </tr>
                )),
                Rec.toReadonlyArray,
                Arr.sort(
                  pipe(
                    DayOfWeek.dayOfWeek,
                    Ord.contramap(
                      ([a]: readonly [DayOfWeek.DayOfWeek, JSX.Element]) => a,
                    ),
                  ),
                ),
                Arr.map(([, a]) => a),
              )}
            </tbody>
          </table>
        </FormGroup>

        <ButtonWithIcon
          color="primary"
          icon={<FaEdit />}
          iconSide={IconSide.left}
          onClick={setIsTimeModalOpen(true)}
        >
          <FormattedMessage defaultMessage="Edit" />
        </ButtonWithIcon>
      </Fieldset>

      <SleepTimeModal
        id={`${id}-sleepTimeModal`}
        isOpen={isTimeModalOpen}
        onSetIsOpen={setIsTimeModalOpen}
        defaultValues={{
          time:
            form.getValues("sleep.time") ??
            pipe(
              initialState.sleep.time,
              Rec.map(
                ([start, end]) =>
                  [
                    pipe(
                      start,
                      (t) => localTimeFromDayAndLocalTimeOfDay(today(), t),
                      (t) => localTimeAsZonedTime.get([t, currentTimeZone()]),
                      zonedTimeToTime.get,
                      dateAsTime.reverseGet,
                    ),
                    pipe(
                      end,
                      (t) => localTimeFromDayAndLocalTimeOfDay(today(), t),
                      (t) => localTimeAsZonedTime.get([t, currentTimeZone()]),
                      zonedTimeToTime.get,
                      dateAsTime.reverseGet,
                    ),
                  ] as const,
              ),
            ),
        }}
        onSubmit={({ time }) =>
          pipe(
            IO.Do,
            IO.chainFirst(() => () => form.setValue("sleep.time", time)),
            IO.chainFirst(() => setIsTimeModalOpen(false)),
          )()
        }
      />
    </>
  )
}

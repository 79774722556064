import { ReactNode } from "react"
import { FieldPathValue, FieldValues, Path } from "react-hook-form"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import {
  ControllerPlus,
  FieldRender,
  formGroupRender,
  noTransform,
  switchRender,
  Transform,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"

import activity from "./imgs/activity.jpg"
import nutrition from "./imgs/nutrition.jpg"

export enum RecommendationType {
  activity = "activity",
  nutrition = "nutrition",
}

const Container = styled.div<{ comingSoon: boolean }>`
  overflow: hidden;
  ${({ comingSoon }) =>
    comingSoon &&
    css`
      filter: grayscale(100%);
      pointer-events: none;
    `}
`

const ImgContainer = styled.div`
  position: relative;
`

const Img = styled.img`
  border: 1px solid hsla(0, 0%, 46.7%, 0.25);
  width: 100%;
`

const Title = styled.span`
  position: absolute;
  font-size: 1.25em;
  color: ${({ theme }) => theme.colours.white.toString()};
  display: block;
  padding: 10px 0;
  background-color: rgba(46, 61, 78, 0.2);
  bottom: 0;
  text-align: center;
  width: 100%;
  font-weight: 500;
`

const Content = styled.div`
  border: 1px solid ${({ theme }) => theme.colours.grey["100"].toString()};
  border-top: none;
  padding: 20px;
`

export type RecommendationSettingsFieldProps<
  formData extends FieldValues,
  name extends Path<formData>,
> = {
  className?: string
  id: string
  name: name
  comingSoon?: true
  type: RecommendationType
  title: ReactNode
  content: ReactNode
  checkboxLabel: ReactNode
}

export const RecommendationSettingsField = <
  formData extends FieldValues,
  name extends Path<formData>,
  value extends FieldPathValue<formData, name> & boolean = FieldPathValue<
    formData,
    name
  > &
    boolean,
>({
  className,
  id,
  name,
  comingSoon,
  type,
  title,
  content,
  checkboxLabel,
}: RecommendationSettingsFieldProps<formData, name>) => (
  <Container {...{ className }} comingSoon={comingSoon === true}>
    <ImgContainer>
      <Img
        src={type === RecommendationType.activity ? activity : nutrition}
        {...{ className }}
      />
      <Title>{title}</Title>
    </ImgContainer>
    <Content>
      {content}
      <ControllerPlus<formData, name, value>
        render={
          formGroupRender(switchRender)({
            id,
            name,
            label: checkboxLabel,
            fullWidth: true,
          }) as any as FieldRender<formData, name, value>
        }
        {...{ name }}
        transform={
          noTransform<formData, name>() as Transform<formData, name, value>
        }
      />
    </Content>
  </Container>
)

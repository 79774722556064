import { FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import * as Rec from "fp-ts/es6/ReadonlyRecord"

import { FormAnswersProvided } from "@fitnesspilot/components-common/dist/atoms/AnswersProvided/AnswersProvided"
import { FieldsetHeader } from "@fitnesspilot/components-common/dist/atoms/FieldsetHeader/FieldsetHeader"
import {
  ControllerPlus,
  formGroupRender,
  noTransform,
  selectRender,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"
import {
  DrinkingHabits,
  EatingHabits,
} from "@fitnesspilot/data-user/dist/Habits"

export type FormData = {
  eatingHabits: EatingHabits
  drinkingHabits: DrinkingHabits
}
const FormDataKeys: Rec.ReadonlyRecord<keyof FormData, null> = {
  eatingHabits: null,
  drinkingHabits: null,
}

export type FoodHabitsFieldsetProps = {
  id: string
  showAnswersProvided?: boolean
}

export const FoodHabitsFieldset: FC<FoodHabitsFieldsetProps> = ({
  id,
  showAnswersProvided,
}) => {
  const intl = useIntl()

  return (
    <>
      <FieldsetHeader
        title={<FormattedMessage defaultMessage="Food" />}
        titleRight={
          showAnswersProvided && (
            <FormAnswersProvided<FormData> keys={FormDataKeys} />
          )
        }
        description={
          <FormattedMessage defaultMessage="Do you always prefer the stairs? Choose a little walk over hanging on the couch? Skip the car when you can walk or take a bike? Or do you even prefer to control everything with your smart phone just not to move? We also like progress, but be conscious in your lifestyle choices. We can easily make ourselves aware of progress we loose for ourselves, for our body, for our health. Over a week this makes a relevant difference in movement we sacrifice - often for a very little comfort. It's on you to decide if it's worth it." />
        }
      />

      <ControllerPlus<FormData, "eatingHabits", EatingHabits>
        render={formGroupRender(selectRender<EatingHabits, "radio">())({
          id: `${id}-eatingHabits`,
          type: "radio",
          label: <FormattedMessage defaultMessage="Eating habits" />,
          values: {
            [EatingHabits.untilFull]: intl.formatMessage({
              defaultMessage: "I usually eat until I'm full",
            }),
            [EatingHabits.untilSomewhatHungry]: intl.formatMessage({
              defaultMessage:
                "I eat until my hunger is gone but not completely full",
            }),
            [EatingHabits.untilNotHungry]: intl.formatMessage({
              defaultMessage:
                "I usually end eating while still a little hungry",
            }),
          },
        })}
        name="eatingHabits"
        transform={noTransform<FormData, "eatingHabits">()}
        rules={{
          required: true,
        }}
      />

      <ControllerPlus<FormData, "drinkingHabits", DrinkingHabits>
        render={formGroupRender(selectRender<DrinkingHabits, "radio">())({
          id: `${id}-drinkingHabits`,
          type: "radio",
          label: <FormattedMessage defaultMessage="Drinking habits" />,
          values: {
            [DrinkingHabits.sufficient]: intl.formatMessage({
              defaultMessage: "I drink enough water",
            }),
            [DrinkingHabits.insufficient]: intl.formatMessage({
              defaultMessage: "I drink moderate amounts of water",
            }),
            [DrinkingHabits.grosslyInsufficient]: intl.formatMessage({
              defaultMessage: "I drink far below the recommended level",
            }),
          },
        })}
        name="drinkingHabits"
        transform={noTransform<FormData, "drinkingHabits">()}
        rules={{
          required: true,
        }}
      />
    </>
  )
}

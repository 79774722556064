import { FC, ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import styled from "@emotion/styled"

import {
  Arrow,
  Dir,
} from "@fitnesspilot/components-common/dist/atoms/Arrow/Arrow"

const Container = styled.div`
  display: grid;
  grid-template-columns: 1em 1em repeat(3, 1fr);
  grid-template-rows: repeat(3, auto) 1em 1em;
  grid-gap: 20px;
  max-width: 100%;

  img {
    max-width: 100%;
  }
`

const ArrowUp = styled(Arrow)`
  grid-column: 2;
  grid-row: 1 / 5;
  transform: translateX(-50%);
`

const ArrowUpText = styled.div`
  grid-column: 1;
  grid-row: 1 / 5;
  display: flex;
  align-items: center;
`

const ArrowUpTextContent = styled.div`
  transform: rotate(-90deg) translate(-50%, 50%);
  transform-origin: 0 50%;
  position: absolute;
  white-space: nowrap;
`

const ArrowRight = styled(Arrow)`
  grid-column: 2 / 6;
  grid-row: 4;
  transform: translateY(50%);
`

const ArrowRightText = styled.div`
  grid-column: 2 / 6;
  grid-row: 5;
  text-align: center;
`

type Triple<a> = [a, a, a]
export type FitnessLevelGridProps = {
  children: Triple<Triple<ReactNode>>
}

export const FitnessLevelGrid: FC<FitnessLevelGridProps> = ({ children }) => (
  <Container>
    <ArrowUp dir={Dir.up} />
    <ArrowUpText>
      <ArrowUpTextContent>
        <FormattedMessage defaultMessage="Your musculature" />
      </ArrowUpTextContent>
    </ArrowUpText>
    <ArrowRight dir={Dir.right} />
    <ArrowRightText>
      <FormattedMessage defaultMessage="Your body fat" />
    </ArrowRightText>
    {children}
  </Container>
)

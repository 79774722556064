import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import { Prism } from "monocle-ts"
import { prismToGetter } from "@fitnesspilot/data-common"

import {
  ActivityId,
  activityId,
  stringAsActivityId,
} from "../activity/ActivityId"
import {
  ActivityTag,
  activityTag,
  stringAsActivityTag,
} from "../activity/ActivityTag"

export enum SportIdType {
  activityTag = "activityTag",
  activity = "activity",
}

const activitySportPrefix = "activity-"
const tagBasedSportPrefix = "activityTag-"

export type SportId =
  | { $type: SportIdType.activityTag; value: ActivityTag }
  | { $type: SportIdType.activity; value: ActivityId }
export const sportIdAsActivityId = new Prism<SportId, ActivityId>(
  (s) => (s.$type === SportIdType.activity ? Opt.some(s.value) : Opt.none),
  (value) => ({ $type: SportIdType.activity, value }),
)
export const stringAsSportId = new Prism<string, SportId>(
  (s) =>
    s.startsWith(tagBasedSportPrefix)
      ? pipe(
          s.replace(tagBasedSportPrefix, ""),
          stringAsActivityTag.getOption,
          Opt.map((value) => ({ $type: SportIdType.activityTag, value })),
        )
      : s.startsWith(activitySportPrefix)
      ? pipe(s, (v) =>
          stringAsActivityId
            .composeGetter(prismToGetter(sportIdAsActivityId))
            .headOption(v),
        )
      : Opt.none,
  (s) =>
    s.$type === SportIdType.activityTag
      ? pipe(
          s.value,
          stringAsActivityTag.reverseGet,
          (tag) => `${tagBasedSportPrefix}${tag}`,
        )
      : s.$type === SportIdType.activity
      ? pipe(s.value, stringAsActivityId.reverseGet)
      : (null as never),
)

export const sportId: Ord.Ord<SportId> = Ord.fromCompare((a, b) =>
  a.$type === SportIdType.activityTag
    ? b.$type === SportIdType.activityTag
      ? activityTag.compare(a.value, b.value)
      : -1
    : b.$type === SportIdType.activityTag
    ? 1
    : activityId.compare(a.value, b.value),
)

export const foldSportId =
  <a>(onActivityTag: (v: ActivityTag) => a, onActivity: (v: ActivityId) => a) =>
  (v: SportId) =>
    v.$type === SportIdType.activityTag
      ? onActivityTag(v.value)
      : v.$type === SportIdType.activity
      ? onActivity(v.value)
      : (null as never)

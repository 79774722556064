import * as Bool from "fp-ts/es6/boolean"
import * as Eq from "fp-ts/es6/Eq"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Str from "fp-ts/es6/string"
import { Getter, Lens, Optional } from "monocle-ts"
import { Time, time } from "time-ts/es6"
import { atPair, PartialOptional } from "@fitnesspilot/data-common"

import {
  WithId,
  withIdEq,
} from "@fitnesspilot/data-activity/dist/activity/WithId"
import * as ActivityInstance from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"
import { alignment } from "@fitnesspilot/data-human-body/dist/Alignment"

import { Event, EventId, eventId, RecommendationEvent } from "../calendar/Event"
import { eventSource } from "../calendar/EventSource"
import { recurrence } from "../calendar/Recurrence"
import { workoutVideo } from "../video/WorkoutVideo"
import * as AddActivity from "./AddActivity"

export type EditingEvent = PartialOptional<Event, "title"> & {
  activities: ReadonlyArray<ActivityInstance.ActivityInstance>
  selectedActivities: ReadonlyArray<ActivityInstance.ActivityInstanceNonGroup>
  // @TODO Make this a ReadonlySet
  catalog: ReadonlyArray<ActivityInstance.ActivityInstanceNonGroup>
  recommendations: ReadonlyArray<RecommendationEvent>
  addActivity: AddActivity.AddActivity
}
export const editingEvent: Eq.Eq<EditingEvent> = Eq.struct({
  activities: Arr.getEq(ActivityInstance.activityInstance),
  between: Eq.tuple(time, time),
  alignment,
  recurrence,
  title: Opt.getEq(Str.Eq),
  source: Opt.getEq(eventSource),
  video: Opt.getEq(workoutVideo),
  isRecommendation: Bool.Eq,
  selectedActivities: Arr.getEq(ActivityInstance.activityInstance),
  catalog: Arr.getEq(ActivityInstance.activityInstance),
})
export const _activities = Lens.fromProp<EditingEvent>()("activities")
export const _between = Lens.fromProp<EditingEvent>()("between")
export const _start = _between.composeLens(atPair<Time>().at(0))
export const _end = _between.composeLens(atPair<Time>().at(1))
export const _alignment = Lens.fromProp<EditingEvent>()("alignment")
export const _recurrence = Lens.fromProp<EditingEvent>()("recurrence")
export const _title = Lens.fromProp<EditingEvent>()("title")
export const _titleOpt = Optional.fromOptionProp<EditingEvent>()("title")
export const _source = Lens.fromProp<EditingEvent>()("source")
export const _video = Lens.fromProp<EditingEvent>()("video")
export const _videoOpt = Optional.fromOptionProp<EditingEvent>()("video")
export const _isRecommendation =
  Lens.fromProp<EditingEvent>()("isRecommendation")
export const _selectedActivities =
  Lens.fromProp<EditingEvent>()("selectedActivities")
export const _catalog = Lens.fromProp<EditingEvent>()("catalog")
export const _addActivity = Lens.fromProp<EditingEvent>()("addActivity")

export type EditingEventWithId = WithId<Opt.Option<EventId>, EditingEvent>
export const editingEventWithId = withIdEq(Opt.getEq(eventId), editingEvent)

export const eventAsEditingEvent = new Getter<Event, EditingEvent>(
  ({ title, ...value }) => ({
    title: Opt.some(title),
    selectedActivities: [],
    catalog: [],
    recommendations: [],
    addActivity: AddActivity.empty,
    ...value,
  }),
)

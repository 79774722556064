import { ComponentType, FC } from "react"
import { injectIntl, IntlShape, WrappedComponentProps } from "react-intl"
import { Col, Row } from "reactstrap"
import styled from "@emotion/styled"

import { flow } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import { dateAsTime } from "time-ts/es6"

import { Clickable } from "@fitnesspilot/components-common/dist/atoms/Clickable/Clickable"
import { Modal } from "@fitnesspilot/components-common/dist/organisms/Modal/Modal"
import { Photo as TPhoto, photo } from "@fitnesspilot/data-user/dist/photo"

import { Photo } from "../../molecules/Photo/Photo"

export type PhotoGalleryProps = {
  editingPhoto: Opt.Option<TPhoto>
  photos: ReadonlyArray<TPhoto>
  openPhoto: Opt.Option<TPhoto>
  profilePhoto: Opt.Option<TPhoto>
  onClose: () => void
  onChange: (v: TPhoto) => void
  onStartEdit: (v: TPhoto) => void
  onOpen: (v: TPhoto) => void
  onSave: (v: TPhoto) => void
  onSetProfilePhoto: (v: Opt.Option<TPhoto>) => void
  onShare: Opt.Option<(v: TPhoto) => void>
}

const MonthGallery = styled.div`
  margin-top: 30px;
`
const MonthGalleryItem = styled.div`
  margin-bottom: 30px;
`

const FullWidthClickable = styled(Clickable)`
  width: 100%;
`

const StyledPhoto = styled(Photo)`
  width: 100%;
  height: 300px;
`

const PhotoGallery_: FC<
  PhotoGalleryProps & {
    intl: IntlShape
  }
> = ({
  editingPhoto,
  photos = [],
  onChange,
  onClose,
  onStartEdit,
  onOpen,
  onSave,
  onSetProfilePhoto,
  onShare,
  openPhoto,
  profilePhoto,
  intl,
}) =>
  flow(
    (photos: ReadonlyArray<TPhoto>) =>
      Rec.fromFoldableMap(Arr.getMonoid<TPhoto>(), Arr.Foldable)(
        photos,
        (photo) =>
          [
            intl.formatDate(dateAsTime.reverseGet(photo.dateCreated), {
              month: "long",
              year: "numeric",
            }),
            [photo],
          ] as const,
      ),
    Rec.toReadonlyArray,
    Arr.map(([month, monthPhotos]) => (
      <Row key={month}>
        <Col lg="12">
          <header>
            <h1>{month}</h1>
          </header>

          <MonthGallery>
            <Row>
              {Arr.Functor.map(Arr.sort(photo)(monthPhotos), (photo) => (
                <Col sm="6" lg="3" tag={MonthGalleryItem} key={photo.id}>
                  <FullWidthClickable onClick={() => onOpen(photo)}>
                    <StyledPhoto
                      isEditing={true}
                      photo={
                        Opt.isSome(editingPhoto) &&
                        photo.id === editingPhoto.value.id
                          ? editingPhoto.value
                          : photo
                      }
                      isProfilePhoto={
                        Opt.isSome(profilePhoto) &&
                        photo.id === profilePhoto.value.id
                      }
                      {...{
                        onChange: flow(onChange, IO.of),
                        onSave,
                        onSetProfilePhoto,
                        onShare,
                        onStartEdit,
                      }}
                    />
                  </FullWidthClickable>
                </Col>
              ))}
            </Row>
          </MonthGallery>
        </Col>
      </Row>
    )),
    Arr.append(
      <Modal toggle={() => onClose()} isOpen={Opt.isSome(openPhoto)} key={0}>
        {Opt.isSome(openPhoto) && (
          <Photo
            photo={
              (Opt.isSome(editingPhoto) &&
              openPhoto.value.id === editingPhoto.value.id
                ? editingPhoto
                : openPhoto
              ).value
            }
            isProfilePhoto={
              Opt.isSome(profilePhoto) &&
              openPhoto.value.id === profilePhoto.value.id
            }
            isEditing={
              Opt.isSome(editingPhoto) &&
              openPhoto.value.id === editingPhoto.value.id
            }
            {...{
              onChange: flow(onChange, IO.of),
              onSave,
              onSetProfilePhoto,
              onShare,
              onStartEdit,
            }}
          />
        )}
      </Modal>,
    ),
  )(photos) as any

export const PhotoGallery: ComponentType<PhotoGalleryProps> = injectIntl<
  "intl",
  PhotoGalleryProps & WrappedComponentProps
>(PhotoGallery_)

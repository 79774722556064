/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from "../runtime";
/**
 * Check if a given object implements the TimeslotApi interface.
 */
export function instanceOfTimeslotApi(value) {
    let isInstance = true;
    isInstance = isInstance && "time" in value;
    isInstance = isInstance && "outdated" in value;
    isInstance = isInstance && "recoveryStatuses" in value;
    return isInstance;
}
export function TimeslotApiFromJSON(json) {
    return TimeslotApiFromJSONTyped(json, false);
}
export function TimeslotApiFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        time: new Date(json.time),
        outdated: json.outdated,
        calories: !exists(json, "calories") ? undefined : json.calories,
        carbonhydrate: !exists(json, "carbonhydrate")
            ? undefined
            : json.carbonhydrate,
        protein: !exists(json, "protein") ? undefined : json.protein,
        fatSaturated: !exists(json, "fatSaturated") ? undefined : json.fatSaturated,
        fatUnsaturated: !exists(json, "fatUnsaturated")
            ? undefined
            : json.fatUnsaturated,
        glx: !exists(json, "glx") ? undefined : json.glx,
        recoveryStatuses: json.recoveryStatuses,
    };
}
export function TimeslotApiToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        time: value.time.toISOString(),
        outdated: value.outdated,
        calories: value.calories,
        carbonhydrate: value.carbonhydrate,
        protein: value.protein,
        fatSaturated: value.fatSaturated,
        fatUnsaturated: value.fatUnsaturated,
        glx: value.glx,
        recoveryStatuses: value.recoveryStatuses,
    };
}

import { FormattedMessage } from "react-intl"
import styled from "@emotion/styled"

import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { ReadonlyNonEmptyArray } from "fp-ts/es6/ReadonlyNonEmptyArray"
import * as Pair from "fp-ts/es6/ReadonlyTuple"

import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import { ListItem as ListItem_ } from "@fitnesspilot/components-common/dist/atoms/List/ListItem"
import { ListUnordered } from "@fitnesspilot/components-common/dist/atoms/List/ListUnordered"
import {
  CalendarCoachTaskType,
  CoachTaskImportance,
  UserCoachTaskType,
} from "@fitnesspilot/data-coach-task"
import * as CoachTaskData from "@fitnesspilot/data-coach-task"
import { stringAsCoachTaskId } from "@fitnesspilot/data-coach-task/dist/CoachTask"

import { CoachTask } from "../../molecules/CoachTask/CoachTask"

const ListItem = styled(ListItem_)`
  margin: 10px 0;
`

const labels = {
  [UserCoachTaskType.sex]: <FormattedMessage defaultMessage="Enter your sex" />,
  [UserCoachTaskType.age]: <FormattedMessage defaultMessage="Enter your age" />,
  [UserCoachTaskType.mass]: (
    <FormattedMessage defaultMessage="Enter your weight" />
  ),
  [UserCoachTaskType.height]: (
    <FormattedMessage defaultMessage="Enter your height" />
  ),
  [UserCoachTaskType.bodyType]: (
    <FormattedMessage defaultMessage="Enter your bodyType" />
  ),
}

export type CoachTasksProps = {
  coachTasks: ReadonlyArray<CoachTaskData.CoachTask>
  onSetEventConfirmationIsOpen: (v: boolean) => IO.IO<void>
  onDismiss: (
    coachTasks: ReadonlyNonEmptyArray<CoachTaskData.CoachTask>,
  ) => IO.IO<void>
}

export const CoachTasks = ({
  coachTasks,
  onSetEventConfirmationIsOpen,
  onDismiss,
}: CoachTasksProps) => (
  <Fieldset data-help-mode="coachTasks">
    <h2>
      <FormattedMessage defaultMessage="What's the coach saying?" />
    </h2>

    {coachTasks.length === 0 ? (
      <strong>
        <FormattedMessage defaultMessage="You have no coach tasks remaining 👍" />
      </strong>
    ) : (
      <ListUnordered>
        {pipe(
          coachTasks,
          Arr.partition(
            (task) => task.type === CalendarCoachTaskType.eventConfirmation,
          ),
          ({ left, right }) => [left, right] as const,
          Pair.bimap(
            (v) =>
              Arr.isNonEmpty(v) ? (
                <ListItem key="events">
                  <CoachTask
                    label={
                      <FormattedMessage
                        defaultMessage="{count, number} {count, plural,
                                          one {event}
                                          other {events}
                                        } to confirm"
                        values={{ count: v.length }}
                      />
                    }
                    importance={CoachTaskImportance.important}
                    actions={[
                      {
                        label: <FormattedMessage defaultMessage="Confirm" />,
                        onClick: onSetEventConfirmationIsOpen(true),
                      },
                    ]}
                    onDismiss={onDismiss(v)}
                  />
                </ListItem>
              ) : null,
            Arr.map((task) => (
              <ListItem key={stringAsCoachTaskId.reverseGet(task.id)}>
                <CoachTask
                  label={labels[task.type as keyof typeof labels]}
                  actions={[
                    {
                      label: <FormattedMessage defaultMessage="Enter" />,
                      link: "/aboutMe/body",
                    },
                  ]}
                  importance={task.importance}
                  onDismiss={onDismiss([task])}
                />
              </ListItem>
            )),
          ),
          ([other, events]) => (
            <>
              {events}
              {other}
            </>
          ),
        )}
      </ListUnordered>
    )}
  </Fieldset>
)

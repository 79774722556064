/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const ActivityTag = {
    Calisthenics: "calisthenics",
    Bodybuilding: "bodybuilding",
    Strength: "strength",
    Endurance: "endurance",
    StrengthEndurance: "strengthEndurance",
    Isometric: "isometric",
    Isotonic: "isotonic",
    Cardio: "cardio",
    Sport: "sport",
    Other: "other",
    Yoga: "yoga",
    Bodyshaping: "bodyshaping",
    Plyometric: "plyometric",
    Stretching: "stretching",
    Balance: "balance",
    Stability: "stability",
    Mobility: "mobility",
    Fascia: "fascia",
    Powerlifting: "powerlifting",
    Strongman: "strongman",
    Weightlifting: "weightlifting",
    PushUpVariations: "pushUpVariations",
    Core: "core",
    FullBody: "fullBody",
    Beginner: "beginner",
    Intermediate: "intermediate",
    Advanced: "advanced",
};
export function ActivityTagFromJSON(json) {
    return ActivityTagFromJSONTyped(json, false);
}
export function ActivityTagFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ActivityTagToJSON(value) {
    return value;
}

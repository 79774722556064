/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from "../runtime";
/**
 * Check if a given object implements the MuscleGroup interface.
 */
export function instanceOfMuscleGroup(value) {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
export function MuscleGroupFromJSON(json) {
    return MuscleGroupFromJSONTyped(json, false);
}
export function MuscleGroupFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        name: json.name,
        majorgroup: !exists(json, "majorgroup") ? undefined : json.majorgroup,
        enabled: !exists(json, "enabled") ? undefined : json.enabled,
        priority: !exists(json, "priority") ? undefined : json.priority,
        id: json.id,
        status: !exists(json, "status") ? undefined : json.status,
    };
}
export function MuscleGroupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        name: value.name,
        majorgroup: value.majorgroup,
        enabled: value.enabled,
        priority: value.priority,
        id: value.id,
        status: value.status,
    };
}

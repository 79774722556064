import { Lens } from "monocle-ts"

import { ParentState, State, stateKey } from "./state"

export const state = Lens.fromProp<ParentState>()(stateKey)

export const activitiesWithSettings = Lens.fromProp<State>()(
  "activitiesWithSettings",
)

export const sports = Lens.fromProp<State>()("sports")

export const muscleSettings = Lens.fromProp<State>()("muscleSettings")

export const mySports = Lens.fromProp<State>()("mySports")

export const generalSportsSettings = Lens.fromProp<State>()(
  "generalSportsSettings",
)
export const exerciseList = Lens.fromProp<State>()("exerciseList")

import * as Func from "fp-ts/es6/function"
import { flow } from "fp-ts/es6/function"
import * as Num from "fp-ts/es6/number"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import { Iso, Prism } from "monocle-ts"
import { Positive, prismPositive } from "newtype-ts/es6/Positive"
import {
  PositiveInteger,
  prismPositiveInteger,
} from "newtype-ts/es6/PositiveInteger"

import { unsafeFromSome } from "./type"

export type ValidNumber = number & { readonly __tag: unique symbol }
export const validNumber: Ord.Ord<ValidNumber> = Num.Ord as any
const isValidNumber = (n: number): n is ValidNumber => !isNaN(n)

export const numberAsValidNumber = new Prism<number, ValidNumber>(
  Opt.fromPredicate(isValidNumber),
  Func.identity as (a: ValidNumber) => number,
)

export const stringAsNumber = new Iso<string, number>(
  Number,
  String,
).composePrism(numberAsValidNumber)

export const validNumberAsPositive = new Prism<ValidNumber, Positive>(
  prismPositive.getOption,
  flow(prismPositive.reverseGet, numberAsValidNumber.getOption, unsafeFromSome),
)

export const validNumberAsPositiveInteger = new Prism<
  ValidNumber,
  PositiveInteger
>(
  prismPositiveInteger.getOption,
  flow(
    prismPositiveInteger.reverseGet,
    numberAsValidNumber.getOption,
    unsafeFromSome,
  ),
)

import * as Num from "fp-ts/es6/number"
import * as Ord from "fp-ts/es6/Ord"
import { Concat, Newtype, prism } from "newtype-ts"
import {
  isNonNegativeInteger,
  NonNegativeInteger,
} from "newtype-ts/es6/NonNegativeInteger"

export type Priority = Concat<
  NonNegativeInteger,
  Newtype<{ readonly Priority: unique symbol }, number>
>
export const numberAsPriority = prism<Priority>(
  (v) => isNonNegativeInteger(v) && v <= 10,
)
export const priority: Ord.Ord<Priority> = Num.Ord as any

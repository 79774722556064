import { FC, useState } from "react"
import { useFormContext } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { Col, Container, Row } from "reactstrap"
import styled from "@emotion/styled"

import * as Bool from "fp-ts/es6/boolean"
import * as Eq from "fp-ts/es6/Eq"
import { constFalse, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import { not } from "fp-ts/es6/Predicate"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as Semigroup from "fp-ts/es6/Semigroup"
import * as Str from "fp-ts/es6/string"

import {
  ControllerPlus,
  ControllerPrism,
  formGroupRender,
  noTransform,
  rangeRender,
  switchRender,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"
import { MuscleImage } from "@fitnesspilot/components-human-body/dist/atoms/MuscleImage/MuscleImage"
import { MuscleName } from "@fitnesspilot/components-human-body/dist/atoms/MuscleName/MuscleName"
import { MuscleId_ } from "@fitnesspilot/data-human-body/dist/Muscle"
import {
  numberAsPriority,
  Priority,
  priority,
} from "@fitnesspilot/data-human-body/dist/Priority"

export type MuscleFormData = {
  enabled: boolean
  priority: Priority
}
export const muscleFormData = Eq.struct<MuscleFormData>({
  enabled: Bool.Eq,
  priority: priority,
})

export type FormData = Record<MuscleId_, MuscleFormData>

export const formData = Eq.struct<FormData>(
  pipe(
    Object.values(MuscleId_),
    Arr.map((k) => [k, muscleFormData] as const),
    Rec.fromFoldable(Semigroup.last<Eq.Eq<MuscleFormData>>(), Arr.Foldable),
    (a) => a as Rec.ReadonlyRecord<MuscleId_, Eq.Eq<MuscleFormData>>,
  ),
)

const OuterRow = styled(Row)`
  flex-wrap: nowrap;
`

const ImageCol = styled(Col)`
  > * {
    width: 100px;
  }
`

const Header = styled.div`
  display: flex;
`

const VerticalRows = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const Title = styled(Row)`
  font-weight: ${({ theme }) => theme.font.weights.bold};
  font-size: 1.5em;
`

export type MusclePreferenceFieldsetProps = {
  id: string
  muscleId: MuscleId_
}

export const MusclePreferenceFieldset: FC<MusclePreferenceFieldsetProps> = ({
  id,
  muscleId,
}) => {
  const intl = useIntl()

  const [disabled, setDisabled] = useState(false)

  const form = useFormContext<FormData>()

  form.watch((values) =>
    pipe(
      values,
      Rec.lookup(muscleId),
      Opt.map(
        not(({ enabled }) =>
          pipe(
            enabled,
            Opt.fromNullable,
            Opt.getOrElse((): boolean => false),
          ),
        ),
      ),
      Opt.getOrElse(constFalse),
      setDisabled,
    ),
  )

  return (
    <Container fluid={true}>
      <OuterRow>
        <Header>
          <ImageCol>
            <MuscleImage value={muscleId} />
          </ImageCol>
        </Header>

        <Col>
          <VerticalRows>
            <Container fluid={true}>
              <Row>
                <Col md={8}>
                  <Title>
                    <MuscleName id={muscleId} />
                  </Title>

                  <ControllerPrism<
                    FormData,
                    `${typeof muscleId}.priority`,
                    number
                  >
                    render={formGroupRender(rangeRender)({
                      id: `${id}-priority`,
                      min: 0,
                      max: 10,
                      step: 1,
                      disabled,
                      label: <FormattedMessage defaultMessage="Priority" />,
                      fullWidth: true,
                    })}
                    name={`${muscleId}.priority`}
                    prism={numberAsPriority as any}
                    prismError={intl.formatMessage({
                      defaultMessage: "is invalid",
                    })}
                    rules={{
                      required: true,
                    }}
                  />
                </Col>
                <Col md={2}>
                  <ControllerPlus<
                    FormData,
                    `${typeof muscleId}.enabled`,
                    boolean
                  >
                    render={switchRender({
                      id: `${id}-enabled`,
                      name: "enabled",
                    })}
                    name={`${muscleId}.enabled`}
                    transform={
                      noTransform<
                        FormData,
                        `${typeof muscleId}.enabled`
                      >() as any
                    }
                  />
                </Col>
              </Row>
            </Container>
          </VerticalRows>
        </Col>
      </OuterRow>
    </Container>
  )
}

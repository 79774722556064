import * as Ord from "fp-ts/es6/Ord"
import * as Str from "fp-ts/es6/string"

export enum ActivityType {
  exercise = "exercise",
  job = "job",
  muscles = "muscles",
  sleep = "sleep",
  group = "group",
}
export const activityType: Ord.Ord<ActivityType> = Str.Ord

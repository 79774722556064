import { FC, useEffect, useMemo, useState } from "react"
import { FaCog } from "react-icons/fa"
import { FormattedMessage, useIntl } from "react-intl"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  Col,
  Row,
} from "reactstrap"
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"

import { constUndefined, flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as Semigroup from "fp-ts/es6/Semigroup"
import { Prism } from "monocle-ts"
import {
  Dimension,
  filterMapKeys,
  numberAsValidNumber,
  reunit,
  runIO,
  UnitNoDim,
  valueInUnit,
} from "@fitnesspilot/data-common"

import { Switch } from "@fitnesspilot/components-common/dist/molecules/Switch/Switch"
import { Dorsoventral } from "@fitnesspilot/data-human-body/dist/anatomicalAxes"
import * as MuscleGroup from "@fitnesspilot/data-human-body/dist/muscleGroups"
import { Sex } from "@fitnesspilot/data-human-body/dist/sex"

import { MuscleGroupIcon } from "../../atoms/MuscleGroupIcon/MuscleGroupIcon"
import { MuscleGroupName } from "../../atoms/MuscleGroupName/MuscleGroupName"
import { muscleLevelColour } from "../../atoms/MuscleLevelColour/MuscleLevelColour"
import { BodyProps } from "../../molecules/Body/Body"
import { BodyMap } from "../BodyMap/BodyMap"

const StyledSwitch = styled(Switch)`
  margin: 0;

  .custom-switch {
    font-size: 12px;
  }
`

const StyledCardHeader = styled(CardHeader)`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  font-size: 0.6em;

  @media (${({ theme }) => theme.breakpoints.xl}) {
    font-size: 0.85em;
  }

  @media (min-width: 1400px) {
    font-size: 1em;
  }

  .custom-switch {
    top: -0.3em;
    font-size: 10px !important;

    @media (min-width: 1400px) {
      font-size: 12px !important;
    }
  }
`

type Side<a extends Dorsoventral> = {
  dorsoventralSide: a
  muscleGroup: MuscleGroup.MuscleGroupsByDorsoventralSide[a]
}

enum MuscleGroupMiniSettingsSide {
  left = "left",
  right = "right",
}

type MuscleGroupMiniSettings = {
  muscleGroup: MuscleGroup.MuscleGroups
  level: number | false | undefined
  target: SVGGElement | null
  side: MuscleGroupMiniSettingsSide
  onToggle: (value: boolean) => IO.IO<void>
} & (Side<Dorsoventral.dorsal> | Side<Dorsoventral.ventral>)

const elePos =
  (scope: HTMLElement | SVGElement) => (ele: HTMLElement | SVGElement) => () =>
    pipe(
      ele,
      (ele) => ele.getBoundingClientRect(),
      ({ top, left, bottom, right }) => ({
        x: (left + right) / 2,
        y: (top + bottom) / 2,
      }),
      pipe(
        scope,
        (ele) => ele.getBoundingClientRect(),
        ({ top, left, bottom, right }) =>
          ({ x, y }: { x: number; y: number }) => ({
            x: x - left,
            y: y - top,
          }),
      ),
    )

const MuscleGroupMiniSettings = ({
  target,
  side,
  onToggle,
  ...props
}: MuscleGroupMiniSettings) => {
  const intl = useIntl()
  const [winSize, setWinSize] = useState<
    { width: number; height: number } | undefined
  >()
  useEffect(() => {
    window.addEventListener("resize", () =>
      setWinSize({
        height: window.innerHeight,
        width: window.innerWidth,
      }),
    )
  })
  const { muscleGroup, level } = props
  const [source, setSource] = useState<SVGGElement | null>(null)
  const [strokeContainer, setStrokeContainer] = useState<SVGSVGElement | null>(
    null,
  )
  const sourcePos = pipe(
    strokeContainer,
    Opt.fromNullable,
    Opt.map(elePos),
    Opt.ap(pipe(source, Opt.fromNullable)),
    Opt.map(runIO),
  )
  const targetPos = pipe(
    strokeContainer,
    Opt.fromNullable,
    Opt.map(elePos),
    Opt.ap(pipe(target, Opt.fromNullable)),
    Opt.map(runIO),
  )
  const theme = useTheme()
  const colour = muscleLevelColour({ theme, level })

  return (
    <>
      <Card>
        <StyledCardHeader>
          <MuscleGroupName
            id={pipe(
              muscleGroup,
              MuscleGroup.muscleGroupIdAsMuscleGroups.reverseGet,
            )}
          />

          <StyledSwitch
            id={`bodyMapWithLabels-muscleGroup-${muscleGroup}`}
            checked={typeof level === "number"}
            disabled={level == null}
            onChange={(v) => onToggle(v)()}
            name={`${muscleGroup}`}
          />
        </StyledCardHeader>

        <CardBody>
          <CardSubtitle
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              color: colour,
              ...(side === MuscleGroupMiniSettingsSide.left
                ? { justifyContent: "end", flexDirection: "row-reverse" }
                : { justifyContent: "start", flexDirection: "row" }),
            }}
          >
            <svg viewBox="-10 -10 20 20" height="1.5em" width="1em">
              <MuscleGroupIcon
                togglePopover={constUndefined}
                ref={setSource}
                {...props}
              />
            </svg>

            {typeof level === "number" ? (
              <FormattedMessage
                defaultMessage="{value, number, percent} ready"
                values={{
                  value: level,
                }}
              />
            ) : level === false ? (
              <FormattedMessage defaultMessage="Disabled" />
            ) : null}
          </CardSubtitle>

          <Button
            color="secondary"
            tag={Link}
            to={`/sports/muscle-preferences/${muscleGroup}`}
            title={intl.formatMessage({
              defaultMessage: "Settings",
            })}
          >
            <FaCog />
          </Button>
        </CardBody>
      </Card>
      <svg
        ref={setStrokeContainer}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          pointerEvents: "none",
        }}
      >
        {pipe(
          Opt.Do,
          Opt.bind("source", () => sourcePos),
          Opt.bind("target", () => targetPos),
          Opt.map(({ source, target }) => (
            <path
              key={0}
              d={`M${source.x} ${source.y} L${(source.x + target.x) / 2} ${
                source.y
              } L${target.x} ${target.y}`}
              stroke={colour}
              strokeWidth="2"
              fill="transparent"
            />
          )),
          Opt.toNullable,
        )}
      </svg>
    </>
  )
}

export type BodyMapWithLabelsProps = {
  sex: Sex
  muscleGroupLevels: Record<
    MuscleGroup.MuscleGroups,
    number | false | undefined
  >
  onMuscleGroupClick: (v: MuscleGroup.MuscleGroups) => IO.IO<void>
  toggleMuscleGroupPopover: (
    v: MuscleGroup.MuscleGroups,
    force: boolean,
  ) => IO.IO<void>
  openMuscleGroupPopover: Opt.Option<MuscleGroup.MuscleGroups>
  onToggle: (
    muscleGroup: MuscleGroup.MuscleGroups,
    value: boolean,
  ) => IO.IO<void>
} & Omit<BodyProps, "sex" | "dorsoventralSide">

const StyledCol = styled(Col)`
  position: static;
`

const LabelCol = styled(StyledCol)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
`

export const BodyMapWithLabels: FC<BodyMapWithLabelsProps> = ({
  sex,
  muscleGroupLevels,
  openMuscleGroupPopover,
  onToggle,
  toggleMuscleGroupPopover,
  ...props
}) => {
  const [ventral, setVentral] = useState<SVGSVGElement | null>(null)
  const [dorsal, setDorsal] = useState<SVGSVGElement | null>(null)
  const ventralMuscleGroups = useMemo(
    () => [
      MuscleGroup.VentralMuscleGroups.shoulder,
      MuscleGroup.VentralMuscleGroups.bicepsBrachii,
      MuscleGroup.VentralMuscleGroups.ventralThorax,
      MuscleGroup.VentralMuscleGroups.abdomen,
    ],
    [],
  )
  const dorsalMuscleGroups = useMemo(
    () => [
      MuscleGroup.DorsalMuscleGroups.dorsalThorax,
      MuscleGroup.DorsalMuscleGroups.tricepsBrachii,
      MuscleGroup.DorsalMuscleGroups.forearmAndHand,
      MuscleGroup.DorsalMuscleGroups.hipAndEverythingInferiorToIt,
    ],
    [],
  )
  const ventralMuscleGroupTargets = pipe(
    ventralMuscleGroups,
    Arr.map(
      (mg) =>
        [
          mg,
          ventral?.querySelector<SVGGElement>(
            `.MuscleGroupIcon-ventral-${mg}`,
          ) ?? null,
        ] as const,
    ),
    Rec.fromFoldable(Semigroup.last<SVGGElement | null>(), Arr.Foldable),
  ) as Rec.ReadonlyRecord<
    (typeof ventralMuscleGroups)[number],
    SVGGElement | null
  >
  const dorsalMuscleGroupTargets = pipe(
    dorsalMuscleGroups,
    Arr.map(
      (mg) =>
        [
          mg,
          dorsal?.querySelector<SVGGElement>(`.MuscleGroupIcon-dorsal-${mg}`) ??
            null,
        ] as const,
    ),
    Rec.fromFoldable(Semigroup.last<SVGGElement | null>(), Arr.Foldable),
  ) as Rec.ReadonlyRecord<
    (typeof dorsalMuscleGroups)[number],
    SVGGElement | null
  >

  return (
    <Row>
      <LabelCol xs={3}>
        {pipe(
          ventralMuscleGroups,
          Arr.map((muscleGroup) => (
            <MuscleGroupMiniSettings
              key={muscleGroup}
              {...{ muscleGroup }}
              level={muscleGroupLevels[muscleGroup]}
              dorsoventralSide={Dorsoventral.ventral}
              target={ventralMuscleGroupTargets[muscleGroup]}
              side={MuscleGroupMiniSettingsSide.left}
              onToggle={(v) => onToggle(muscleGroup, v)}
            />
          )),
        )}
      </LabelCol>

      <LabelCol xs={6}>
        <Row>
          <StyledCol>
            <BodyMap
              {...{ sex }}
              dorsoventralSide={Dorsoventral.ventral}
              muscleGroupLevels={pipe(
                muscleGroupLevels,
                filterMapKeys(
                  Semigroup.first<number | false | undefined>(),
                  MuscleGroup._VentralMuscleGroups.getOption,
                ),
              )}
              openMuscleGroupPopover={pipe(
                openMuscleGroupPopover,
                Prism.some<MuscleGroup.MuscleGroups>().composePrism(
                  MuscleGroup._VentralMuscleGroups,
                ).getOption,
                Opt.toUndefined,
              )}
              ref={setVentral}
              toggleMuscleGroupPopover={(...args) =>
                toggleMuscleGroupPopover(...args)()
              }
              {...props}
            />
          </StyledCol>

          <StyledCol>
            <BodyMap
              {...{ sex }}
              dorsoventralSide={Dorsoventral.dorsal}
              muscleGroupLevels={pipe(
                muscleGroupLevels,
                filterMapKeys(
                  Semigroup.first<number | false | undefined>(),
                  MuscleGroup._DorsalMuscleGroups.getOption,
                ),
              )}
              openMuscleGroupPopover={pipe(
                openMuscleGroupPopover,
                Prism.some<MuscleGroup.MuscleGroups>().composePrism(
                  MuscleGroup._DorsalMuscleGroups,
                ).getOption,
                Opt.toUndefined,
              )}
              ref={setDorsal}
              toggleMuscleGroupPopover={(...args) =>
                toggleMuscleGroupPopover(...args)()
              }
              {...props}
            />
          </StyledCol>
        </Row>

        <Row>
          <StyledCol xs={{ size: 8, offset: 2 }}>
            <MuscleGroupMiniSettings
              muscleGroup={MuscleGroup.VentralMuscleGroups.cardiovascular}
              level={
                muscleGroupLevels[
                  MuscleGroup.VentralMuscleGroups.cardiovascular
                ]
              }
              dorsoventralSide={Dorsoventral.ventral}
              target={null}
              side={MuscleGroupMiniSettingsSide.right}
              onToggle={(v) =>
                onToggle(MuscleGroup.VentralMuscleGroups.cardiovascular, v)
              }
            />
          </StyledCol>
        </Row>
      </LabelCol>

      <LabelCol xs={3}>
        {pipe(
          dorsalMuscleGroups,
          Arr.map((muscleGroup) => (
            <MuscleGroupMiniSettings
              key={muscleGroup}
              {...{ muscleGroup }}
              level={muscleGroupLevels[muscleGroup]}
              dorsoventralSide={Dorsoventral.dorsal}
              target={dorsalMuscleGroupTargets[muscleGroup]}
              side={MuscleGroupMiniSettingsSide.right}
              onToggle={(v) => onToggle(muscleGroup, v)}
            />
          )),
        )}
      </LabelCol>
    </Row>
  )
}

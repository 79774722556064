import * as Opt from "fp-ts/es6/Option"
import { Time } from "time-ts/es6"

import { EventChangeType } from "@fitnesspilot/api-combined"
import { ActivityInstanceFilters } from "@fitnesspilot/data-activity/dist/activityFilters/ActivityInstanceFilters"
import { ActivityInstanceSorting } from "@fitnesspilot/data-activity/dist/activityFilters/ActivitySorting"
import * as ActivityInstance from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"
import { AnyAPIError } from "@fitnesspilot/data-api"

import {
  Event,
  EventId,
  EventWithId,
  RecommendationEvent,
} from "../calendar/Event"
import { EventsOrRecommendations } from "../calendar/EventsOrRecommendations"
import { Recommendation } from "../calendar/Recommendation"
import { Recurrence } from "../calendar/Recurrence"
import { Timeslot } from "../calendar/Timeslot"
import { WorkoutVideo } from "../video/WorkoutVideo"
import * as AddActivity from "./AddActivity"
import * as CalendarSelection from "./CalendarSelection"
import * as EditingEvent from "./EditingEvent"

import { ActionType, createAction, createAsyncAction } from "typesafe-actions"

export const resetState = createAction("event/resetState", () => ({}))()

export const fetchEventsOrRecommendationsBetweenAsync = createAsyncAction(
  "event/fetchEventsOrRecommendationsBetween/request",
  "event/fetchEventsOrRecommendationsBetween/success",
  "event/fetchEventsOrRecommendationsBetween/failure",
)<
  { between: readonly [Time, Time] },
  {
    between: readonly [Time, Time]
    eventsOrRecommendations: EventsOrRecommendations
    timeslots: ReadonlyArray<Timeslot>
  },
  AnyAPIError
>()
export const fetchEventsOrRecommendationsBetween =
  fetchEventsOrRecommendationsBetweenAsync.request

export const fetchEventsOrRecommendationsBetweenIfNeeded = createAction(
  "event/fetchEventsOrRecommendationsBetweenIfNeeded",
)<{ between: readonly [Time, Time] }>()

export const refetchEventsOrRecommendations = createAction(
  "event/refetchEventsOrRecommendations",
)<void>()

export const fetchEventsForSportsStatistics = createAction(
  "event/fetchEventsForSportsStatistics",
)<void>()

export const fetchEventByIdAsync = createAsyncAction(
  "event/fetchEventById/request",
  "event/fetchEventById/success",
  "event/fetchEventById/failure",
)<
  { id: EventId },
  {
    event: EventWithId
  },
  AnyAPIError
>()
export const fetchEventById = fetchEventByIdAsync.request

export const initNewEvent = createAction("event/initNewEvent")<{
  between: readonly [Opt.Option<Time>, Opt.Option<Time>]
}>()
export const startEditingEvent = createAction(
  "event/startEditingEvent",
  (event: EditingEvent.EditingEventWithId) => ({ event }),
)()
export const startEditingEventByIdAsync = createAsyncAction(
  "event/startEditingEventById/request",
  "event/startEditingEventById/success",
  "event/startEditingEventById/failure",
)<{ eventId: EventId }, { event: EventWithId }, AnyAPIError>()
export const startEditingEventById = startEditingEventByIdAsync.request
export const cancelEditingEvent = createAction(
  "event/cancelEditingEvent",
  (navigate: boolean) => ({ navigate }),
)()
export const setEventTitle = createAction(
  "event/setEventTitle",
  (title: Opt.Option<string>) => ({ title }),
)()
export const setEventBetween = createAction(
  "event/setEventBetween",
  (between: readonly [Time, Time]) => ({ between }),
)()
export const setEventRecurrence = createAction(
  "event/setEventRecurrence",
  (recurrence: Recurrence) => ({ recurrence }),
)()
export const setEventVideo = createAction(
  "event/setEventVideo",
  (workoutVideo: Opt.Option<WorkoutVideo>) => ({ workoutVideo }),
)()
export const setAddActivityStep = createAction(
  "event/setAddActivityStep",
  (step: AddActivity.AddActivityStep) => ({ step }),
)()
export const addActivities = createAction(
  "event/addActivities",
  (activities: ReadonlyArray<ActivityInstance.ActivityInstance>) => ({
    activities,
  }),
)()
export const removeActivity = createAction(
  "event/removeActivity",
  (activityIndex: number) => ({ activityIndex }),
)()
// TODO add support for `ActivityInstanceGroup`
export const updateActivity = createAction(
  "event/updateActivity",
  (
    activityIndex: number,
    activity: ActivityInstance.ActivityInstanceNonGroup,
  ) => ({
    activityIndex,
    activity,
  }),
)()
export const moveActivity = createAction(
  "event/moveActivity",
  (oldActivityIndex: number, newActivityIndex: number) => ({
    oldActivityIndex,
    newActivityIndex,
  }),
)()
export const selectActivity = createAction(
  "event/selectActivity",
  (activity: ActivityInstance.ActivityInstanceNonGroup) => ({ activity }),
)()
export const unselectActivity = createAction(
  "event/unselectActivity",
  (activityIndex: number) => ({ activityIndex }),
)()
export const updateSelectedActivity = createAction(
  "event/updateSelectedActivity",
  (
    activityIndex: number,
    activity: ActivityInstance.ActivityInstanceNonGroup,
  ) => ({
    activityIndex,
    activity,
  }),
)()
export const saveSelectedActivities = createAction(
  "event/saveSelectedActivities",
  () => ({}),
)()
export const cancelSelectedActivities = createAction(
  "event/cancelSelectedActivities",
  () => ({}),
)()
export const setCatalogSearch = createAction(
  "event/setCatalogSearch",
  (value: Opt.Option<string>) => ({ value }),
)()
export const setCatalogSorting = createAction(
  "event/setCatalogSorting",
  (sorting: ActivityInstanceSorting) => ({ sorting }),
)()
export const setCatalogFilters = createAction(
  "event/setCatalogFilters",
  (filters: ActivityInstanceFilters) => ({ filters }),
)()
export const saveEventAsync = createAsyncAction(
  "event/saveEvent/request",
  "event/saveEvent/success",
  "event/saveEvent/failure",
)<
  { event: EventWithId; changeType: EventChangeType },
  { event: EventWithId; changeType: EventChangeType },
  AnyAPIError
>()
export const saveEvent = saveEventAsync.request
export const confirmEventAsync = createAsyncAction(
  "event/confirmEvent/request",
  "event/confirmEvent/success",
  "event/confirmEvent/failure",
)<EventId, EventId, AnyAPIError>()
export const confirmEvent = confirmEventAsync.request
export const saveEditingEventAsync = createAsyncAction(
  "event/saveEditingEvent/request",
  "event/saveEditingEvent/success",
  "event/saveEditingEvent/failure",
)<
  { changeType: EventChangeType },
  { event: EventWithId; changeType: EventChangeType },
  AnyAPIError
>()
export const saveEditingEvent = saveEditingEventAsync.request
export const deleteEventAsync = createAsyncAction(
  "event/deleteEvent/request",
  "event/deleteEvent/success",
  "event/deleteEvent/failure",
)<
  { event: EventWithId; changeType: EventChangeType },
  { event: EventWithId; changeType: EventChangeType },
  AnyAPIError
>()
export const deleteEvent = deleteEventAsync.request
export const deleteEditingEventAsync = createAsyncAction(
  "event/deleteEditingEvent/request",
  "event/deleteEditingEvent/success",
  "event/deleteEditingEvent/failure",
)<
  { changeType: EventChangeType },
  { event: EventWithId; changeType: EventChangeType },
  AnyAPIError
>()
export const deleteEditingEvent = deleteEditingEventAsync.request

export const fetchCatalogForEditingEventAsync = createAsyncAction(
  "event/fetchCatalogForEditingEvent/request",
  "event/fetchCatalogForEditingEvent/success",
  "event/fetchCatalogForEditingEvent/failure",
)<
  void,
  { catalog: ReadonlyArray<ActivityInstance.ActivityInstanceNonGroup> },
  AnyAPIError
>()
export const fetchCatalogForEditingEvent =
  fetchCatalogForEditingEventAsync.request

export const fetchRecommendationsForCalendarAsync = createAsyncAction(
  "event/fetchRecommendationsForCalendar/request",
  "event/fetchRecommendationsForCalendar/success",
  "event/fetchRecommendationsForCalendar/failure",
)<Recommendation, { recommendations: ReadonlyArray<Event> }, AnyAPIError>()
export const fetchRecommendationsForCalendar =
  fetchRecommendationsForCalendarAsync.request

export const fetchRecommendationsForEditingEventAsync = createAsyncAction(
  "event/fetchRecommendationsForEditingEvent/request",
  "event/fetchRecommendationsForEditingEvent/success",
  "event/fetchRecommendationsForEditingEvent/failure",
)<void, { recommendations: ReadonlyArray<RecommendationEvent> }, AnyAPIError>()
export const fetchRecommendationsForEditingEvent =
  fetchRecommendationsForEditingEventAsync.request

export const fetchVideosForEditingEventAsync = createAsyncAction(
  "event/fetchVideosForEditingEvent/request",
  "event/fetchVideosForEditingEvent/success",
  "event/fetchVideosForEditingEvent/failure",
)<
  { search: Opt.Option<string> },
  { videos: ReadonlyArray<WorkoutVideo> },
  AnyAPIError
>()
export const fetchVideosForEditingEvent =
  fetchVideosForEditingEventAsync.request

export const calendarSelect = createAction(
  "event/calendarSelect",
)<CalendarSelection.CalendarSelection>()
export const calendarUnselect = createAction("event/calendarUnselect")<void>()

export type Action = ActionType<
  | typeof resetState
  | (typeof fetchEventsOrRecommendationsBetweenAsync)[
      | "request"
      | "success"
      | "failure"]
  | typeof fetchEventsOrRecommendationsBetweenIfNeeded
  | typeof refetchEventsOrRecommendations
  | typeof fetchEventsForSportsStatistics
  | (typeof fetchEventByIdAsync)["request" | "success" | "failure"]
  | typeof initNewEvent
  | typeof startEditingEvent
  | (typeof startEditingEventByIdAsync)["request" | "success" | "failure"]
  | typeof setEventTitle
  | typeof setEventBetween
  | typeof setEventRecurrence
  | typeof setEventVideo
  | typeof cancelEditingEvent
  | typeof setAddActivityStep
  | typeof addActivities
  | typeof removeActivity
  | typeof updateActivity
  | typeof moveActivity
  | typeof selectActivity
  | typeof unselectActivity
  | typeof updateSelectedActivity
  | typeof saveSelectedActivities
  | typeof cancelSelectedActivities
  | typeof setCatalogSearch
  | typeof setCatalogSorting
  | typeof setCatalogFilters
  | (typeof saveEventAsync)["request" | "success" | "failure"]
  | (typeof confirmEventAsync)["request" | "success" | "failure"]
  | (typeof saveEditingEventAsync)["request" | "success" | "failure"]
  | (typeof deleteEventAsync)["request" | "success" | "failure"]
  | (typeof deleteEditingEventAsync)["request" | "success" | "failure"]
  | (typeof fetchCatalogForEditingEventAsync)["request" | "success" | "failure"]
  | (typeof fetchRecommendationsForCalendarAsync)[
      | "request"
      | "success"
      | "failure"]
  | (typeof fetchRecommendationsForEditingEventAsync)[
      | "request"
      | "success"
      | "failure"]
  | (typeof fetchVideosForEditingEventAsync)["request" | "success" | "failure"]
  | typeof calendarSelect
  | typeof calendarUnselect
>

import styled from "@emotion/styled"

import * as Eq from "fp-ts/es6/Eq"
import * as Str from "fp-ts/es6/string"

export type Enum = string | number

export const eqEnum: Eq.Eq<Enum> = Str.Eq as any

export type CookieCategory = "necessary" | "statistics" | "marketing"

export type Cookie<T extends Enum> = {
  id: T
  name: string
  company: string
  description: string
  category: CookieCategory
  required?: boolean
}

export type CookieInfoProps<T extends Enum> = {
  className: string
  cookie: Cookie<T>
}

const Title = styled.div`
  font-weight: bold;
`

export const CookieInfo_ = <T extends Enum>() =>
  styled(
    ({
      className,
      cookie: { name, description, company },
    }: CookieInfoProps<T>) => (
      <section className={`c-cookieInfo ${className ?? ""}`}>
        <Title>
          {name} ({company}):
        </Title>
        <span>{description}</span>
      </section>
    ),
  )``

export const CookieInfo = <T extends Enum>(props: CookieInfoProps<T>) => {
  const Component = CookieInfo_<T>()
  return <Component {...props} />
}

import { MessageDescriptor } from "react-intl"

import { Locale } from "@fitnesspilot/data-common/dist/Locale"

import defaultTranslations from "../translations/en.json"

export const stateKey = "clientsWeb"

export type State = {
  locale: Locale
  translations: Record<string, MessageDescriptor>
}
export const initialState: State = {
  locale: Locale.en,
  translations: defaultTranslations,
}

export type ParentState = { [k in typeof stateKey]: State }

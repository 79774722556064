import { FC, Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { Alert, Button, FormGroup } from "reactstrap"

/* import styled from "@emotion/styled" */
import * as IO from "fp-ts/es6/IO"
import { Lens } from "monocle-ts"

import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"

type AccountDeletion = {
  showConfirmation: boolean
}
const showConfirmation = Lens.fromProp<AccountDeletion>()("showConfirmation")

export type AccountDeletionFieldsetProps = {
  value: AccountDeletion
  onDelete: IO.IO<void>
}

export const AccountDeletionFieldset: FC<AccountDeletionFieldsetProps> = ({
  value,
  onDelete,
}) => (
  <>
    <h2>
      <FormattedMessage defaultMessage="Account deletion" />
    </h2>

    {showConfirmation.get(value) ? (
      <Fragment>
        <Alert color="danger">
          <FormattedMessage defaultMessage="Please confirm that you want to delete your account. You will lose access to your account immediately and all your personal data will be deleted within a 30 day period. This action is irrevocable." />
        </Alert>
      </Fragment>
    ) : (
      <Fragment>
        <p>
          <FormattedMessage defaultMessage="You will lose access to your account immediately and all your personal data will be deleted within a 30 day period. This action is irrevocable." />
        </p>
      </Fragment>
    )}

    <FormGroup>
      <Button type="button" onClick={() => onDelete()} color="danger">
        <FormattedMessage defaultMessage="Delete my account" />
      </Button>
    </FormGroup>
  </>
)

import { FC, HTMLAttributes, useEffect } from "react"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { Table } from "reactstrap"
import styled from "@emotion/styled"

import * as Apply from "fp-ts/es6/Apply"
import * as Bool from "fp-ts/es6/boolean"
import * as E from "fp-ts/es6/Either"
import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as Semigroup from "fp-ts/es6/Semigroup"
import * as Str from "fp-ts/es6/string"
import { Prism } from "monocle-ts"
import { indexReadonlyRecord } from "monocle-ts/lib/Index/ReadonlyRecord"
import { dateAsTime, isoStringAsLocalTimeOfDay } from "time-ts/es6"
import { timeAsLocalTimeOfDayInCurrentEnv } from "time-ts/es6/Local/TimeOfDay"
import {
  OmitFromKnownKeys,
  prismToGetter,
  unsafeFromSome,
} from "@fitnesspilot/data-common"

import { DayOfWeekName } from "@fitnesspilot/components-common/dist/atoms/DayOfWeekName/DayOfWeekName"
import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import { FormGroup } from "@fitnesspilot/components-common/dist/molecules/FormGroup/FormGroup"
import { CommonModal } from "@fitnesspilot/components-common/dist/organisms/CommonModal/CommonModal"
import {
  ControllerPlus,
  ControllerPrism,
  formGroupRender,
  localTimeOfDayDurationStringRender,
  noTransform,
  textRender,
  wrapPrismOpt,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"
import * as DayOfWeek from "@fitnesspilot/data-common/dist/DayOfWeek"
import * as Work from "@fitnesspilot/data-user/dist/Work"

import {
  DaysToEdit,
  TimeBatchModifyFieldset,
  TimeBatchModifyFieldsetProps,
} from "../TimeBatchModifyFieldset/TimeBatchModifyFieldset"

import { ioTsResolver } from "@hookform/resolvers/io-ts"
import * as t from "io-ts"

export const daysToEditToDaysOfWeek = (dte: DaysToEdit) =>
  ({
    [DaysToEdit.allDays]: Object.values(DayOfWeek.DayOfWeek),
    [DaysToEdit.weekdays]: [
      DayOfWeek.DayOfWeek.monday,
      DayOfWeek.DayOfWeek.tuesday,
      DayOfWeek.DayOfWeek.wednesday,
      DayOfWeek.DayOfWeek.thursday,
      DayOfWeek.DayOfWeek.friday,
    ],
    [DaysToEdit.weekend]: [
      DayOfWeek.DayOfWeek.saturday,
      DayOfWeek.DayOfWeek.sunday,
    ],
  })[dte]

const IntervalSeparator = styled(
  ({ className, ...props }: HTMLAttributes<HTMLSpanElement>) => (
    <span className={`c-intervalSeparator ${className ?? ""}`} {...props} />
  ),
)``

const Interval = styled.div`
  display: flex;
  align-items: center;

  .c-intervalSeparator {
    margin: 0 5px;
  }
`

const WorkTimeBatchModifyFieldset = (
  props: OmitFromKnownKeys<TimeBatchModifyFieldsetProps, "onSubmit">,
) => {
  const { setValue } = useFormContext<FormData>()

  return (
    <TimeBatchModifyFieldset
      onSubmit={({ daysToEdit, start, end }) =>
        pipe(
          daysToEdit,
          daysToEditToDaysOfWeek,
          Arr.traverse(IO.io)((dow) =>
            pipe(
              Apply.sequenceT(IO.io)(
                () =>
                  setValue(
                    // This somehow doesn't work:
                    // `time.${dow}.0` as any as `time.${DayOfWeek.DayOfWeek.monday}.0`,
                    `time.${dow}.0` as any,
                    // which is why I added the cast here:
                    Opt.some(start),
                  ),
                () => setValue(`time.${dow}.1` as any, Opt.some(end)),
              ),
            ),
          ),
        )()
      }
      {...props}
    />
  )
}

const dateT = new t.Type<Date, Date, unknown>(
  "Date",
  (a): a is Date =>
    typeof a === "object" &&
    a != null &&
    a instanceof Date &&
    !isNaN(a.valueOf()),
  (value, context) =>
    pipe(
      value,
      Opt.fromPredicate(
        (v): v is Date =>
          typeof v === "object" && v != null && v instanceof Date,
      ),
      E.fromOption(() => [{ value, context }]),
      E.chain(
        E.fromOptionK(() => [{ value, context }])(
          Opt.fromPredicate((v) => !isNaN(v.valueOf())),
        ),
      ),
    ),
  (v) => v,
)
const someDateT = t.type({
  _tag: t.literal("Some"),
  value: dateT,
})
const noneT = t.type({ _tag: t.literal("None") })
const timeEntry = t.union([
  t.tuple([someDateT, someDateT]),
  t.tuple([noneT, noneT]),
])
const FormData = t.type({
  mayEatAtWork: t.boolean,
  lunchtime: t.type({
    duration: t.string,
    within: t.tuple([dateT, dateT]),
  }),
  time: t.type({
    [DayOfWeek.DayOfWeek.monday]: timeEntry,
    [DayOfWeek.DayOfWeek.tuesday]: timeEntry,
    [DayOfWeek.DayOfWeek.wednesday]: timeEntry,
    [DayOfWeek.DayOfWeek.thursday]: timeEntry,
    [DayOfWeek.DayOfWeek.friday]: timeEntry,
    [DayOfWeek.DayOfWeek.saturday]: timeEntry,
    [DayOfWeek.DayOfWeek.sunday]: timeEntry,
  }),
})

export type FormData = Omit<Work.Work, "job" | "time" | "lunchtime"> & {
  lunchtime: {
    duration: string
    within: [Date, Date]
  }
  time: Rec.ReadonlyRecord<
    DayOfWeek.DayOfWeek,
    [Opt.Option<Date>, Opt.Option<Date>]
  >
}

export type WorkTimeModalProps = {
  id: string
  className?: string
  isOpen: boolean
  onSetIsOpen: (isOpen: boolean) => IO.IO<void>
  defaultValues: FormData
  onSubmit: (v: FormData) => void
}

const stringAsLocalTimeOfDayDate = new Prism<string, Date>(
  (a) =>
    isoStringAsLocalTimeOfDay
      .composeGetter(prismToGetter(timeAsLocalTimeOfDayInCurrentEnv()))
      .composeGetter(prismToGetter(dateAsTime))
      .headOption(a),
  (d) =>
    pipe(
      d,
      (a) =>
        dateAsTime
          .composePrism(timeAsLocalTimeOfDayInCurrentEnv())
          .composeGetter(prismToGetter(isoStringAsLocalTimeOfDay))
          .headOption(a),
      unsafeFromSome,
    ),
)

export const WorkTimeModal: FC<WorkTimeModalProps> = ({
  id,
  isOpen,
  onSetIsOpen,
  defaultValues,
  onSubmit,
  ...props
}) => {
  const intl = useIntl()
  const { handleSubmit, ...form } = useForm<FormData>({
    defaultValues,
    resolver: ioTsResolver(FormData),
  })

  useEffect(() => {
    if (!form.formState.isDirty) {
      form.reset(defaultValues)
    }
  }, [defaultValues])

  return (
    <FormProvider {...{ handleSubmit }} {...form}>
      <CommonModal
        onToggle={(open) => onSetIsOpen(open)()}
        onSave={Opt.some(handleSubmit((v) => onSubmit(v)))}
        title={<FormattedMessage defaultMessage="Work hours" />}
        size="xl"
        {...{ isOpen }}
        {...props}
      >
        <p>
          <FormattedMessage defaultMessage="Knowing when you're busy working and when you're free to eat allows us to make more realistic recommendations." />
        </p>

        <Fieldset>
          <WorkTimeBatchModifyFieldset
            id={`${id}-workTimeBatchModify`}
            defaultValues={{ daysToEdit: DaysToEdit.weekdays }}
          />
        </Fieldset>

        <Fieldset>
          <Table>
            <tbody>
              {pipe(
                DayOfWeek.DayOfWeek,
                Rec.toReadonlyArray,
                Arr.map(([, v]) => v),
                Arr.sort(DayOfWeek.dayOfWeek),
                Arr.map((dow) => (
                  <tr key={dow}>
                    <th scope="row">
                      <label htmlFor={`${id}-${dow}Start`}>
                        <DayOfWeekName id={dow} />
                      </label>
                    </th>

                    <td>
                      <Interval>
                        <ControllerPrism<
                          FormData,
                          `time.${DayOfWeek.DayOfWeek.monday}.0`
                        >
                          render={textRender({
                            id: `${id}-${dow}Start`,
                            type: "time",
                            step: 60,
                          })}
                          name={
                            `time.${dow}.0` as `time.${DayOfWeek.DayOfWeek.monday}.0`
                          }
                          prism={
                            wrapPrismOpt(stringAsLocalTimeOfDayDate) as any
                          }
                          prismError={intl.formatMessage({
                            defaultMessage: "is invalid",
                          })}
                        />
                        <IntervalSeparator>–</IntervalSeparator>
                        <ControllerPrism<
                          FormData,
                          `time.${DayOfWeek.DayOfWeek.monday}.1`
                        >
                          render={textRender({
                            id: `${id}-${dow}End`,
                            type: "time",
                            step: 60,
                          })}
                          name={
                            // The template literal doesn't work since it expands to multiple strings
                            `time.${dow}.1` as any as `time.${DayOfWeek.DayOfWeek.monday}.1`
                          }
                          prism={
                            // The lookup somehow doesn't seem to work for 'monday'; maybe due to the enum.
                            wrapPrismOpt(stringAsLocalTimeOfDayDate) as any
                          }
                          prismError={intl.formatMessage({
                            defaultMessage: "is invalid",
                          })}
                        />
                      </Interval>
                    </td>
                  </tr>
                )),
              )}
            </tbody>
          </Table>
        </Fieldset>

        <Fieldset>
          <ControllerPlus<FormData, "lunchtime.duration", string>
            render={formGroupRender(localTimeOfDayDurationStringRender)({
              id: `${id}-lunchtimeDuration`,
              label: <FormattedMessage defaultMessage="Lunchtime duration" />,
              step: Opt.none,
              min: Opt.none,
              max: Opt.none,
            })}
            name="lunchtime.duration"
            transform={noTransform<FormData, "lunchtime.duration">()}
          />

          <FormGroup
            inputId={`${id}-lunchtime-within-0`}
            label={
              <FormattedMessage defaultMessage="Lunch break starts between" />
            }
          >
            <Interval>
              <ControllerPrism<FormData, "lunchtime.within.0">
                render={textRender({
                  id: `${id}-lunchtime-within-0`,
                  type: "time",
                  step: 60,
                })}
                name="lunchtime.within.0"
                prism={stringAsLocalTimeOfDayDate}
                prismError={intl.formatMessage({
                  defaultMessage: "is invalid",
                })}
              />
              <IntervalSeparator>–</IntervalSeparator>
              <ControllerPrism<FormData, "lunchtime.within.1">
                render={textRender({
                  id: `${id}-lunchtimeWithin.1`,
                  type: "time",
                  step: 60,
                })}
                name="lunchtime.within.1"
                prism={stringAsLocalTimeOfDayDate}
                prismError={intl.formatMessage({
                  defaultMessage: "is invalid",
                })}
              />
            </Interval>
          </FormGroup>
        </Fieldset>
      </CommonModal>
    </FormProvider>
  )
}

import * as Eq from "fp-ts/es6/Eq"
import * as F from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Str from "fp-ts/es6/string"
import { Prism } from "monocle-ts"

export type Token = string & { readonly __tag: unique symbol }
export const token: Eq.Eq<Token> = Str.Eq
export const stringAsToken = new Prism(
  // @TODO
  Opt.some as (v: string) => Opt.Option<Token>,
  F.identity as (v: Token) => string,
)

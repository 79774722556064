import { HTMLAttributes } from "react"
import { withTheme } from "@emotion/react"
import styled from "@emotion/styled"

import { Theme } from "../ThemeProvider/ThemeProvider"

import { Cell, Label, Pie, PieChart } from "recharts"

type StyledLabelProps = {
  label: string | JSX.Element
  labelSuffix?: string | JSX.Element
}

const StyledSpan = styled.span`
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  div:first-of-type {
    font-size: 26px;
  }
`

const HighlightLabel = ({ label, labelSuffix }: StyledLabelProps) => (
  <StyledSpan>
    <div className="test1">{label}</div>
    <div>{labelSuffix}</div>
  </StyledSpan>
)

export type ProgressCircleProps = {
  label: "percentage" | "fraction"
  labelSuffix?: string | JSX.Element // TODO does this work with Intl Message?
  at: number
  of: number
} & HTMLAttributes<HTMLDivElement>

export const ProgressCircle = withTheme(
  ({
    theme,
    label,
    labelSuffix,
    at,
    of,
  }: ProgressCircleProps & { theme: Theme }) => {
    const background = theme.colours.grey["100"].toString()
    const colour = theme.colours.primary.toString()

    return (
      <PieChart width={200} height={200} barSize={5}>
        <Pie
          data={[{ value: 1 }]}
          dataKey="value"
          innerRadius="80%"
          outerRadius="90%"
          isAnimationActive={false}
          fill={background}
          stroke={background}
        />
        <Pie
          data={[{ value: at }, { value: of - at }]}
          dataKey="value"
          innerRadius="80%"
          outerRadius="90%"
          startAngle={90}
          endAngle={-270}
          fill={colour}
          stroke={colour}
        >
          <Cell />
          <Cell opacity={0} />
          <Label
            width={100}
            position="center"
            content={({ viewBox: { cx, cy } }: any) => (
              <foreignObject x={cx / 2} y={cy / 2} width={cx} height={cy}>
                <HighlightLabel
                  label={
                    label === "percentage"
                      ? `${Math.round((at / of) * 100)} %`
                      : `${at}/${of}`
                  }
                  labelSuffix={labelSuffix}
                />
              </foreignObject>
            )}
          />
        </Pie>
      </PieChart>
    )
  },
)

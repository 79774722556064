import { FC, memo, useMemo, useState } from "react"
import { FaCog, FaHeart, FaListUl, FaPlus } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { NavItem as NavItem_ } from "reactstrap"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import * as Bool from "fp-ts/es6/boolean"
import * as Foldable from "fp-ts/es6/Foldable"
import { constNull, flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import { not, Predicate } from "fp-ts/es6/Predicate"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import { fromTraversable } from "monocle-ts"
import {
  currentTimeZone,
  day,
  dayOfLocalTime,
  timeAsLocalTime,
  today,
} from "time-ts/es6"
import { arrTrav, mapPair, NavSection } from "@fitnesspilot/data-common"

import { SportName } from "@fitnesspilot/components-activity/dist/atoms/SportName/SportName"
import {
  DjIcon,
  IconType,
} from "@fitnesspilot/components-common/dist/atoms/DjIcon/DjIcon"
import { ProgressCircle } from "@fitnesspilot/components-common/dist/atoms/ProgressCircle/ProgressCircle"
import { NavItem as MainNavItem } from "@fitnesspilot/components-common/dist/molecules/NavItem/NavItem"
import { SupraNav } from "@fitnesspilot/components-common/dist/molecules/SupraNav/SupraNav"
import { SupraNavLink } from "@fitnesspilot/components-common/dist/molecules/SupraNav/SupraNavLink"
import { SupraNavList } from "@fitnesspilot/components-common/dist/molecules/SupraNav/SupraNavList"
import { SupraNavSection } from "@fitnesspilot/components-common/dist/molecules/SupraNav/SupraNavSection"
import { EventsToday } from "@fitnesspilot/components-event/dist/molecules/EventsToday/EventsToday"
import { BodyMapToggleSideContainer } from "@fitnesspilot/components-human-body/dist/organisms/BodyMapToggleSide/BodyMapToggleSide"
import * as Sport from "@fitnesspilot/data-activity/dist/sport/Sport"
import * as SportId from "@fitnesspilot/data-activity/dist/sport/SportId"
import * as ActivityData from "@fitnesspilot/data-activity/dist/store"
import { EventType } from "@fitnesspilot/data-event/dist/calendar/Event"
import * as Event from "@fitnesspilot/data-event/dist/calendar/Event"
import * as EventOrRecommendation from "@fitnesspilot/data-event/dist/calendar/EventOrRecommendation"
import * as EventData from "@fitnesspilot/data-event/dist/store"
import * as UserData from "@fitnesspilot/data-user"
import * as AboutMeAnswers from "@fitnesspilot/data-user/dist/AboutMeAnswers"

import {
  CoachTaskImportanceStepContainer,
  ShownCoachTaskImportance,
  shownCoachTaskImportance,
} from "./CoachTaskImportanceStep"
import { RootState } from "./reduxStore"

import mem from "memoize-one"

const NavItem = styled(NavItem_)`
  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`

const StyledBodyMapToggleSideContainer = styled(BodyMapToggleSideContainer)`
  max-width: 200px;
  max-height: 300px;
  margin: auto;
`

const MainNavWrapper = styled.nav`
  display: flex;
  flex-direction: row;
`

const SuperNav = styled.div`
  display: flex;
  flex-direction: column;
  background: #eeeef8;
`

const StyledMainNavItem = styled(MainNavItem)`
  width: 100px;
  height: 100px;

  ${({ active }) =>
    active &&
    css`
      box-sizing: border-box;
      width: 105px;
      margin-left: -5px;
      padding-left: 5px;

      border-radius: 3px;
    `}
`

const mapState = (state: RootState) => {
  const eventsStartingToday = pipe(
    IO.Do,
    IO.bind("tz", () => currentTimeZone),
    IO.bind("today", () => today),
    IO.bind(
      "tomorrow",
      ({ today }) =>
        () =>
          day.add(today, 1),
    ),
    IO.map(
      ({ tz, today, tomorrow }): Predicate<Event.EventWithId> =>
        flow(
          Event._eventWithId._value.composeLens(Event._between).get,
          mapPair(timeAsLocalTime(tz).composeLens(dayOfLocalTime).get),
          ([low]) => Ord.geq(day)(low, today) && Ord.lt(day)(low, tomorrow),
        ),
    ),
  )

  return {
    mySports: pipe(
      state,
      ActivityData.selectors.state.compose(ActivityData.selectors.sports).get,
      mem(
        fromTraversable(Arr.Traversable)<Sport.SportWithId>()
          .filter(
            Sport.sportWithIdOptics._value.composeLens(Sport._isLiked).get,
          )
          .asFold().getAll,
      ),
    ),
    events: pipe(
      state,
      EventData.selectors.state.compose(
        EventData.selectors.eventsOrRecommendations,
      ).get,
      mem(
        arrTrav<EventOrRecommendation.EventOrRecommendation>()
          .composePrism(EventOrRecommendation._EventWithId)
          .filter(eventsStartingToday())
          .asFold().getAll,
      ),
    ),
    aboutMeAnswers: pipe(
      state,
      UserData.selectors.state.compose(UserData.selectors.aboutMeAnswers).get,
    ),
  }
}

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

export type MainNavContainerProps = {
  className?: string
}

export type MainNavProps = PropsFromRedux & MainNavContainerProps

const MainNav_: FC<MainNavProps> = ({
  mySports,
  events,
  aboutMeAnswers,
  className,
}) => {
  const location = useLocation()
  const pathname = location.pathname
  const active = pathname.startsWith("/calendar")
    ? NavSection.calendar
    : pathname.startsWith("/sports")
    ? NavSection.sports
    : pathname.startsWith("/aboutMe")
    ? NavSection.aboutMe
    : NavSection.dashboard

  const [open, setOpen] = useState(active)

  const onMouseEnter = useMemo(
    () => ({
      [NavSection.dashboard]: () => setOpen(NavSection.dashboard),
      [NavSection.calendar]: () => setOpen(NavSection.calendar),
      [NavSection.sports]: () => setOpen(NavSection.sports),
      [NavSection.aboutMe]: () => setOpen(NavSection.aboutMe),
    }),
    [setOpen],
  )

  return (
    <MainNavWrapper
      {...{ className }}
      onMouseLeave={() => setOpen(active)}
      data-help-mode="mainNav"
    >
      <SuperNav>
        <StyledMainNavItem
          active={open === NavSection.dashboard}
          to="/dashboard"
          icon={<DjIcon icon={IconType.dashboard} />}
          onMouseEnter={onMouseEnter.dashboard}
          data-help-mode="dashboardLink"
        >
          <FormattedMessage defaultMessage="Dashboard" />
        </StyledMainNavItem>

        <StyledMainNavItem
          active={open === NavSection.calendar}
          to="/calendar"
          icon={<DjIcon icon={IconType.calendar} />}
          onMouseEnter={onMouseEnter.calendar}
          data-help-mode="calendarLink"
        >
          <FormattedMessage defaultMessage="Calendar" />
        </StyledMainNavItem>

        <StyledMainNavItem
          active={open === NavSection.sports}
          to="/sports"
          icon={<DjIcon icon={IconType.weight} />}
          onMouseEnter={onMouseEnter.sports}
          data-help-mode="sportsLink"
        >
          <FormattedMessage defaultMessage="Sports" />
        </StyledMainNavItem>

        <StyledMainNavItem
          active={open === NavSection.aboutMe}
          to="/aboutMe"
          icon={<DjIcon icon={IconType.user} />}
          onMouseEnter={onMouseEnter.aboutMe}
          data-help-mode="aboutMeLink"
        >
          <FormattedMessage defaultMessage="About Me" />
        </StyledMainNavItem>
      </SuperNav>

      <SupraNav>
        {
          {
            [NavSection.aboutMe]: (
              <>
                <SupraNavSection vertical>
                  <NavItem>
                    <h2>
                      <FormattedMessage defaultMessage="About Me" />
                    </h2>

                    <p>
                      <FormattedMessage defaultMessage="Manage information about you so we can provide you optimal food and nutrition suggestions." />
                    </p>
                  </NavItem>

                  <NavItem>
                    <SupraNavLink
                      tag={Link}
                      to="/aboutMe"
                      active={pathname === "/aboutMe"}
                    >
                      <FormattedMessage defaultMessage="My account" />
                    </SupraNavLink>
                  </NavItem>
                </SupraNavSection>

                <SupraNavSection vertical>
                  <NavItem>
                    <SupraNavLink
                      tag={Link}
                      to="/aboutMe/body"
                      active={pathname === "/aboutMe/body"}
                    >
                      <FormattedMessage defaultMessage="Me & my body" />
                    </SupraNavLink>
                  </NavItem>

                  <NavItem>
                    <SupraNavLink
                      tag={Link}
                      to="/aboutMe/MyPhotos"
                      active={pathname === "/aboutMe/MyPhotos"}
                    >
                      <FormattedMessage defaultMessage="Photo timeline" />
                    </SupraNavLink>
                  </NavItem>

                  <NavItem>
                    <SupraNavLink
                      tag={Link}
                      to="/aboutMe/workAndLifestyle"
                      active={pathname === "/aboutMe/workAndLifestyle"}
                    >
                      <FormattedMessage defaultMessage="Work & lifestyle" />
                    </SupraNavLink>
                  </NavItem>
                </SupraNavSection>

                <SupraNavSection vertical>
                  <h2>
                    <FormattedMessage defaultMessage="My Data" />
                    <ProgressCircle
                      label="percentage"
                      labelSuffix={
                        <FormattedMessage defaultMessage="Answers Provided" />
                      }
                      at={pipe(
                        aboutMeAnswers,
                        Opt.map(AboutMeAnswers._at.get),
                        Opt.getOrElse(() => 0),
                      )}
                      of={pipe(
                        aboutMeAnswers,
                        Opt.map(AboutMeAnswers._of.get),
                        Opt.getOrElse(() => 1),
                      )}
                    />
                  </h2>
                </SupraNavSection>
              </>
            ),
            [NavSection.calendar]: (
              <>
                <SupraNavSection vertical>
                  <NavItem>
                    <h2>
                      <FormattedMessage defaultMessage="Calendar" />
                    </h2>

                    <p>
                      <FormattedMessage defaultMessage="Manage your daily schedule, review food and exercising suggestions, log unplanned activities." />
                    </p>
                  </NavItem>
                </SupraNavSection>

                <SupraNavSection vertical>
                  <NavItem>
                    <SupraNavLink
                      tag={Link}
                      to="/calendar"
                      active={pathname === "/calendar"}
                    >
                      <FormattedMessage defaultMessage="Calendar" />
                    </SupraNavLink>
                  </NavItem>
                </SupraNavSection>

                <SupraNavSection vertical data-help-mode="addEvent">
                  <NavItem>
                    <SupraNavLink
                      tag={Link}
                      to="/calendar/add-event"
                      active={pathname === "/calendar/add-event"}
                      data-help-mode="addEvent"
                    >
                      <FaPlus /> <FormattedMessage defaultMessage="Add event" />
                    </SupraNavLink>
                  </NavItem>
                </SupraNavSection>

                <SupraNavSection vertical>
                  <NavItem>
                    <h2>
                      <FormattedMessage defaultMessage="Today" />
                    </h2>

                    <EventsToday {...{ events }} />
                  </NavItem>
                </SupraNavSection>
              </>
            ),
            [NavSection.dashboard]: (
              <>
                <SupraNavSection vertical>
                  <NavItem>
                    <h2>
                      <FormattedMessage defaultMessage="Dashboard" />
                    </h2>

                    <p>
                      <FormattedMessage defaultMessage="Here you keep an overview and everything under control." />
                    </p>
                  </NavItem>
                </SupraNavSection>

                <SupraNavSection vertical>
                  <NavItem>
                    <SupraNavLink
                      tag={Link}
                      to="/dashboard"
                      active={pathname === "/dashboard"}
                    >
                      <FormattedMessage defaultMessage="Dashboard" />
                    </SupraNavLink>
                  </NavItem>
                </SupraNavSection>

                <SupraNavSection vertical>
                  <NavItem>
                    <h2>
                      <FormattedMessage defaultMessage="Coach Tasks" />
                    </h2>
                  </NavItem>

                  {pipe(
                    ShownCoachTaskImportance,
                    Foldable.toArray(Rec.readonlyRecord),
                    Arr.sort(shownCoachTaskImportance),
                    Arr.map((v) => (
                      <CoachTaskImportanceStepContainer
                        key={v}
                        importance={v}
                      />
                    )),
                  )}
                </SupraNavSection>
              </>
            ),
            [NavSection.sports]: (
              <>
                <SupraNavSection vertical>
                  <NavItem>
                    <h2>
                      <FormattedMessage defaultMessage="Sports" />
                    </h2>

                    <p>
                      <FormattedMessage defaultMessage="Browse database of exercises, see the best matches for you and your goals." />
                    </p>
                  </NavItem>
                </SupraNavSection>

                <SupraNavSection vertical>
                  <NavItem>
                    <SupraNavLink
                      tag={Link}
                      to="/sports"
                      active={pathname === "/sports"}
                    >
                      <FaHeart />{" "}
                      <FormattedMessage defaultMessage="Body status" />
                    </SupraNavLink>
                  </NavItem>

                  <NavItem>
                    <StyledBodyMapToggleSideContainer />
                  </NavItem>
                </SupraNavSection>

                <SupraNavSection vertical>
                  <NavItem>
                    <SupraNavLink
                      tag={Link}
                      to="/sports/exercises"
                      active={pathname === "/sports/exercises"}
                    >
                      <FaListUl />{" "}
                      <FormattedMessage defaultMessage="Exercises" />
                    </SupraNavLink>
                  </NavItem>
                </SupraNavSection>

                <SupraNavSection vertical>
                  <NavItem data-help-mode="mySports">
                    <SupraNavLink
                      tag={Link}
                      to="/sports/my-sports"
                      active={pathname === "/sports/my-sports"}
                    >
                      <FaCog /> <FormattedMessage defaultMessage="My sports" />
                    </SupraNavLink>
                    <SupraNavList>
                      {pipe(mySports, Arr.splitAt(3), ([highlighted, rest]) => (
                        <>
                          {pipe(
                            highlighted,
                            Arr.map(({ id, value }) => {
                              const id_ = SportId.stringAsSportId.reverseGet(id)
                              return (
                                <NavItem key={id_}>
                                  <SupraNavLink
                                    tag={Link}
                                    to={`/sports/sport-preferences/${id_}`}
                                    active={
                                      pathname ===
                                      `/sports/sport-preferences/${id_}`
                                    }
                                  >
                                    <SportName
                                      {...{ id }}
                                      def={pipe(value.name, Opt.toUndefined)}
                                    />
                                  </SupraNavLink>
                                </NavItem>
                              )
                            }),
                          )}
                          {pipe(
                            rest,
                            not(Arr.isEmpty),
                            Bool.fold(constNull, () => (
                              <NavItem key="all">
                                <SupraNavLink tag={Link} to="/sports/my-sports">
                                  <FormattedMessage defaultMessage="..." />
                                </SupraNavLink>
                              </NavItem>
                            )),
                          )}
                        </>
                      ))}
                    </SupraNavList>
                  </NavItem>
                </SupraNavSection>

                <SupraNavSection vertical>
                  <NavItem>
                    <h2>
                      <FormattedMessage defaultMessage="Today" />
                    </h2>

                    <EventsToday
                      events={pipe(
                        events,
                        Arr.filter((e) => e.value.type === EventType.activity),
                      )}
                    />
                  </NavItem>
                </SupraNavSection>
              </>
            ),
          }[open]
        }
      </SupraNav>
    </MainNavWrapper>
  )
}

export const MainNav = memo(MainNav_)
export const MainNavContainer = connector(MainNav)

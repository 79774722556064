import * as Arr_ from "fp-ts/es6/Array"
import * as Bool from "fp-ts/es6/boolean"
import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as Semigroup from "fp-ts/es6/Semigroup"
import * as Tree from "fp-ts/es6/Tree"
import { At, fromTraversable, Getter, Lens } from "monocle-ts"
import { Time, time } from "time-ts/es6"
import {
  Dimension,
  numberAsValidNumber,
  UnitNoDim,
  validNumber,
  valueInUnit,
  ValueWithUnit,
  valueWithUnit,
} from "@fitnesspilot/data-common"

import * as MuscleSettings from "@fitnesspilot/data-activity/dist/sport/MuscleSettings"
import * as MuscleGroup from "@fitnesspilot/data-human-body/dist/muscleGroups"
import * as MuscleGroupStatus from "@fitnesspilot/data-human-body/dist/MuscleGroupStatus"

const valueInPercent = numberAsValidNumber.composeIso(
  valueInUnit(Dimension.noDim, UnitNoDim.percent),
)

export type Timeslot = {
  time: Time
  outdated: boolean
  recoveryStatus: Record<
    MuscleGroup.MuscleGroups,
    ValueWithUnit<Dimension.noDim, UnitNoDim.percent>
  >
}
export const _time = Lens.fromProp<Timeslot>()("time")
export const _outdated = Lens.fromProp<Timeslot>()("outdated")
export const _recoveryStatus = Lens.fromProp<Timeslot>()("recoveryStatus")

// @TODO Once MuscleGroupStatus's type has been fixed it should be used directly in the `Timeslot` type
export const _RecoveryStatusAsMuscleGroupStatus = (
  muscleSettings: Tree.Forest<MuscleSettings.MuscleSettings>,
): Getter<Timeslot["recoveryStatus"], MuscleGroupStatus.MuscleGroupStatus> =>
  new Getter((s) =>
    pipe(
      s,
      Rec.mapWithIndex((i, v) =>
        pipe(
          muscleSettings,
          (v) =>
            fromTraversable(Arr_.Traversable)<
              Tree.Tree<MuscleSettings.MuscleSettings>
            >()
              .composeTraversal(fromTraversable(Tree.Traversable)())
              .filter(
                (ms) =>
                  ms.id ===
                  MuscleGroup.muscleGroupIdAsMuscleGroups.reverseGet(i),
              )
              .composeLens(MuscleSettings._enabled())
              .asFold()
              .headOption(v),
          Opt.getOrElse(() => false),
          Bool.fold(
            (): number | false => false,
            () => pipe(v, valueInPercent.reverseGet),
          ),
        ),
      ),
    ),
  )

export const _timeslotsAt = new At<
  ReadonlyArray<Timeslot>,
  Time,
  Opt.Option<Timeslot>
>(
  (i) =>
    new Lens(
      (s) =>
        pipe(
          s,
          Arr.findLast((t) => time.compare(i, pipe(t, _time.get)) < 1),
        ),
      (a) => (s) =>
        pipe(
          s,
          Arr.findLastIndex((t) => time.compare(i, pipe(t, _time.get)) < 1),
          Opt.fold(
            () =>
              pipe(
                a,
                Opt.fold(
                  () => s,
                  (a) => Arr.cons(a, s),
                ),
              ),
            pipe(
              a,
              Opt.fold(
                // Unsafe is fine since we already know that the index exists
                () => (i) => Arr.unsafeDeleteAt(i, s),
                (a) => (i) => Arr.unsafeUpdateAt(i, a, s),
              ),
            ),
          ),
        ),
    ),
)

const min = <a>(ord: Ord.Ord<a>): Semigroup.Semigroup<a> => ({
  concat: (a, b) => Ord.min(ord)(a, b),
})

export const timeslot: Semigroup.Semigroup<Timeslot> = Semigroup.struct({
  time: min(time),
  outdated: Bool.SemigroupAny,
  recoveryStatus: Rec.getMonoid(
    min(valueWithUnit(Dimension.noDim, UnitNoDim.percent, validNumber)),
  ),
})

import { useEffect, useMemo } from "react"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { FormattedMessage } from "react-intl"

import * as Bool from "fp-ts/es6/boolean"
import { flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import {
  bisequencePair,
  canonicalDistanceFromSpeedAndDuration,
  canonicalDurationFromSpeedAndDistance,
  canonicalSpeedFromDistanceAndDuration,
  Dimension,
  unsafeFromSome,
  ValidNumber,
  validNumber,
  ValueWithCanonicalUnit,
  valueWithCanonicalUnit,
} from "@fitnesspilot/data-common"

import {
  formGroupRender,
  FormGroupRenderProps,
  wrapPrismOptFallback,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"
import { ControllerDimensionalNumberContainer } from "@fitnesspilot/components-user/dist/organisms/ControllerDimensionalNumber/ControllerDimensionalNumber"
import { ActivityType } from "@fitnesspilot/data-activity/dist/activity/ActivityType"
import { _ActivityInstanceExercise } from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"
import {
  _distance,
  _duration,
  ActivityInstanceExercise,
} from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceExercise"

import { ActivityDistance } from "../ActivityDistance/ActivityDistance"
import { ActivityDuration } from "../ActivityDuration/ActivityDuration"

type FormData = {
  speed: Opt.Option<ValueWithCanonicalUnit<Dimension.speed, ValidNumber>>
}

const isEqualSpeed = (
  a: Opt.Option<ValueWithCanonicalUnit<Dimension.speed, ValidNumber>>,
  b: Opt.Option<ValueWithCanonicalUnit<Dimension.speed, ValidNumber>>,
) =>
  pipe(valueWithCanonicalUnit(Dimension.speed, validNumber), Opt.getEq, (eq) =>
    eq.equals(a, b),
  )

export type ActivityDurationDistanceProps = {
  id: string
  activity: ActivityInstanceExercise
  onChange: (activity: ActivityInstanceExercise) => IO.IO<void>
}
export const ActivityDurationDistance = ({
  id,
  activity,
  onChange,
}: ActivityDurationDistanceProps) => {
  const { handleSubmit, ...form } = useForm<FormData>({})

  const distance = pipe(activity, _distance.get)
  const duration = pipe(activity, _duration.get)
  const speed = useMemo(
    () =>
      pipe(
        [distance, duration] as const,
        bisequencePair(Opt.Applicative),
        Opt.chain(([distance, duration]) =>
          canonicalSpeedFromDistanceAndDuration(distance, duration),
        ),
      ),
    [distance, duration],
  )

  const onSubmit = handleSubmit(({ speed }) =>
    pipe(
      speed,
      Opt.chain((speed) =>
        pipe(
          [distance, duration] as const,
          bisequencePair(Opt.Applicative),
          Opt.fold(
            () =>
              pipe(
                distance,
                Opt.fold(
                  () =>
                    pipe(
                      duration,
                      Opt.map(
                        flow(
                          (duration) =>
                            canonicalDistanceFromSpeedAndDuration(
                              speed,
                              duration,
                            ),
                          _distance.set,
                        ),
                      ),
                    ),
                  (distance) =>
                    pipe(
                      canonicalDurationFromSpeedAndDistance(speed, distance),
                      _duration.set,
                      Opt.some,
                    ),
                ),
              ),
            ([_, duration]) =>
              pipe(
                canonicalDistanceFromSpeedAndDuration(speed, duration),
                _distance.set,
                Opt.some,
              ),
          ),
          Opt.map((f) => pipe(activity, f, onChange)),
        ),
      ),
      Opt.getOrElse<IO.IO<void>>(() => IO.of(undefined)),
    )(),
  )

  const formSpeed:
    | Opt.Option<ValueWithCanonicalUnit<Dimension.speed, ValidNumber>>
    | undefined = useWatch({ control: form.control, name: "speed" })

  // update form for duration & distance changes
  useEffect(
    pipe(
      formSpeed,
      Opt.fromNullable,
      Opt.flatten,
      (formSpeed) => isEqualSpeed(formSpeed, speed),
      Bool.fold<IO.IO<undefined>>(
        () => () => void form.reset({ speed }),
        () => IO.of(undefined),
      ),
    ),
    [activity.duration, activity.distance],
  )

  // update duration & distance for speed changes
  useEffect(
    pipe(
      formSpeed,
      Opt.fromNullable,
      Opt.fold(
        () => IO.of(undefined),
        (formSpeed) =>
          pipe(
            isEqualSpeed(formSpeed, speed),
            Bool.fold<IO.IO<undefined>>(
              () => () => void onSubmit(),
              () => IO.of(undefined),
            ),
          ),
      ),
    ),
    [formSpeed],
  )

  return (
    <>
      <ActivityDuration
        activity={{ $type: ActivityType.exercise, value: activity }}
        id={`${id}-duration`}
        onChange={flow(
          _ActivityInstanceExercise.getOption,
          unsafeFromSome,
          onChange,
        )}
      />
      <ActivityDistance
        id={`${id}-distance`}
        optional
        {...{ activity, onChange }}
      />
      <FormProvider {...{ handleSubmit }} {...form}>
        <form {...{ onSubmit }}>
          <ControllerDimensionalNumberContainer<
            FormData,
            "speed",
            Dimension.speed,
            FormGroupRenderProps
          >
            name="speed"
            dimension={Dimension.speed}
            transform={wrapPrismOptFallback}
            renderTransform={formGroupRender}
            renderTransformProps={{
              id: `${id}-speed`,
              label: <FormattedMessage defaultMessage="Speed" />,
            }}
          />
        </form>
      </FormProvider>
    </>
  )
}

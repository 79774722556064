import { FC } from "react"
import { IntlShape, useIntl } from "react-intl"

import { formatDynamicMessage } from "@fitnesspilot/components-common/dist/atoms/IntlProvider/IntlProvider"
import { MuscleId_ } from "@fitnesspilot/data-human-body/dist/Muscle"

const names = {
  [MuscleId_.upperTrapezium]: "Upper trapezium",
  [MuscleId_.middleTrapezium]: "Middle trapezium",
  [MuscleId_.lowerTrapezium]: "Lower trapezium",
  [MuscleId_.latissimus]: "Latissimus",
  [MuscleId_.backExtensor]: "Back extensor",
  [MuscleId_.upperChest]: "Upper chest",
  [MuscleId_.middleChest]: "Middle chest",
  [MuscleId_.lowerChest]: "Lower chest",
  [MuscleId_.frontShoulder]: "Front shoulder",
  [MuscleId_.middleShoulder]: "Middle shoulder",
  [MuscleId_.backShoulder]: "Back shoulder",
  [MuscleId_.diagonalAbdominal]: "Diagonal abdominal",
  [MuscleId_.straightAbdominal]: "Straight abdominal",
  [MuscleId_.biceps]: "Biceps",
  [MuscleId_.triceps]: "Triceps",
  [MuscleId_.forearm]: "Forearm",
  [MuscleId_.quadriceps]: "Quadriceps",
  [MuscleId_.adductors]: "Adductors",
  [MuscleId_.abductors]: "Abductors",
  [MuscleId_.hamstring]: "Hamstring",
  [MuscleId_.calves]: "Calves",
  [MuscleId_.glutes]: "Glutes",
  [MuscleId_.cardiovascularSystem]: "Cardiovascular system",
}

export type MuscleNameProps = {
  id: MuscleId_
}

export const muscleName =
  (intl: IntlShape) =>
  ({ id }: MuscleNameProps) =>
    formatDynamicMessage(intl)({
      id: `MuscleName.${id}`,
      defaultMessage: names[id],
    })

export const MuscleName: FC<MuscleNameProps> = (props) => (
  <>{muscleName(useIntl())(props)}</>
)

import { FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { flow } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import { Prism } from "monocle-ts"

import {
  ControllerPrism,
  formGroupRender,
  textRender,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"

export type FormData = {
  alternateName: Opt.Option<string>
  givenName: Opt.Option<string>
  familyName: Opt.Option<string>
}

export type PersonalInformationFieldsetProps = {
  id: string
}

export const PersonalInformationFieldset: FC<
  PersonalInformationFieldsetProps
> = ({ id }) => {
  const intl = useIntl()
  return (
    <>
      <h2>
        <FormattedMessage defaultMessage="Personal information" />
      </h2>

      <ControllerPrism<FormData, "alternateName">
        render={formGroupRender(textRender)({
          id: `${id}-alternateName`,
          autoComplete: "nickname",
          type: "text",
          required: false,
          label: <FormattedMessage defaultMessage="Nickname" />,
        })}
        name="alternateName"
        prism={
          new Prism(flow(Opt.some, Opt.some), flow(Opt.getOrElse(() => "")))
        }
        prismError={intl.formatMessage({
          defaultMessage: "is invalid",
        })}
      />

      <ControllerPrism<FormData, "givenName">
        render={formGroupRender(textRender)({
          id: `${id}-givenName`,
          autoComplete: "given-name",
          type: "text",
          required: false,
          label: <FormattedMessage defaultMessage="Given name" />,
        })}
        name="givenName"
        prism={
          new Prism(flow(Opt.some, Opt.some), flow(Opt.getOrElse(() => "")))
        }
        prismError={intl.formatMessage({
          defaultMessage: "is invalid",
        })}
      />

      <ControllerPrism<FormData, "familyName">
        render={formGroupRender(textRender)({
          id: `${id}-familyName`,
          autoComplete: "family-name",
          type: "text",
          required: false,
          label: <FormattedMessage defaultMessage="Family name" />,
        })}
        name="familyName"
        prism={
          new Prism(flow(Opt.some, Opt.some), flow(Opt.getOrElse(() => "")))
        }
        prismError={intl.formatMessage({
          defaultMessage: "is invalid",
        })}
      />
    </>
  )
}

import { FC } from "react"
import styled from "@emotion/styled"

import * as Opt from "fp-ts/es6/Option"

import {
  ImageWithDetails,
  ImageWithDetailsProps,
} from "@fitnesspilot/components-common/dist/molecules/ImageWithDetails/ImageWithDetails"
import {
  CombinedFitnessLevel,
  FitnessLevel as FitnessLevel_,
} from "@fitnesspilot/data-human-body/dist/fitnessLevel"

import { FitnessLevel } from "../../atoms/FitnessLevel/FitnessLevel"

const WithMargin = styled.div`
  margin-left: 1em;
`

type FitnessLevelWithImageProps = {
  fitnessLevel: CombinedFitnessLevel
  autoHeight?: ImageWithDetailsProps["autoHeight"]
}

export const FitnessLevelWithImage: FC<FitnessLevelWithImageProps> = ({
  fitnessLevel,
  autoHeight = false,
}) => (
  <ImageWithDetails
    image={{
      dateCreated: Opt.none,
      description: Opt.some("Lorem ipsum"),
      contentUrl: new URL(
        {
          [FitnessLevel_.high]: {
            [FitnessLevel_.high]: "https://placekitten.com/g/600/340",
            [FitnessLevel_.medium]: "https://placekitten.com/g/600/340",
            [FitnessLevel_.low]: "https://placekitten.com/g/600/340",
          },
          [FitnessLevel_.medium]: {
            [FitnessLevel_.high]: "https://placekitten.com/g/600/340",
            [FitnessLevel_.medium]: "https://placekitten.com/g/600/340",
            [FitnessLevel_.low]: "https://placekitten.com/g/600/340",
          },
          [FitnessLevel_.low]: {
            [FitnessLevel_.high]: "https://placekitten.com/g/600/340",
            [FitnessLevel_.medium]: "https://placekitten.com/g/600/340",
            [FitnessLevel_.low]: "https://placekitten.com/g/600/340",
          },
        }[fitnessLevel.bodyFat][fitnessLevel.musculature],
      ),
    }}
    {...{ autoHeight }}
  >
    <WithMargin>
      <FitnessLevel {...{ fitnessLevel }} />
    </WithMargin>
  </ImageWithDetails>
)

import * as Bool from "fp-ts/es6/boolean"
import * as Eq from "fp-ts/es6/Eq"
import { constFalse, constTrue, flow, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as St from "fp-ts/es6/ReadonlySet"

import { muscleId_FromMuscleId } from "@fitnesspilot/data-human-body/dist/Muscle"
import {
  MuscleGroupId,
  muscleGroupId,
  muscleIdMap,
} from "@fitnesspilot/data-human-body/dist/muscleGroups"

import {
  Activity,
  ActivityWithId,
  foldActivity,
  ifActivityExercise,
} from "../activity/Activity"
import { ActivityId, activityId } from "../activity/ActivityId"
import { ActivityTag, activityTag } from "../activity/ActivityTag"
import { EquipmentType, equipmentType } from "../activity/Equipment"
import * as WithId from "../activity/WithId"
import { ActivitySettingsExerciseListing } from "../activitySettings/ActivitySettingsExercise"

export type ActivityFilters = {
  tags: ReadonlySet<ActivityTag>
  muscleGroups: ReadonlySet<MuscleGroupId>
  equipments: ReadonlySet<EquipmentType>
  onlyLiked: boolean
}
export const ActivityFilterKeys: Rec.ReadonlyRecord<
  keyof ActivityFilters,
  null
> = {
  tags: null,
  muscleGroups: null,
  equipments: null,
  onlyLiked: null,
}
export const activityFilters: Eq.Eq<ActivityFilters> = Eq.struct({
  tags: St.getEq(activityTag),
  muscleGroups: St.getEq(muscleGroupId),
  equipments: St.getEq(equipmentType),
  onlyLiked: Bool.Eq,
})

export const empty: ActivityFilters = {
  tags: new Set([]),
  muscleGroups: new Set([]),
  equipments: new Set([]),
  onlyLiked: false,
}

export const matches = (a: string, b: string) =>
  a.toLowerCase().includes(b.toLowerCase())

export const foldActivityFilter =
  <a>(
    tags: () => a,
    muscleGroups: () => a,
    equipments: () => a,
    onlyLiked: () => a,
  ) =>
  (k: keyof ActivityFilters) => {
    switch (k) {
      case "tags":
        return tags()
      case "muscleGroups":
        return muscleGroups()
      case "equipments":
        return equipments()
      case "onlyLiked":
        return onlyLiked()
    }
  }

export const foldActivityFilterValue =
  <a, k extends keyof ActivityFilters>(
    tags: (v: ReadonlySet<ActivityTag>) => a,
    muscleGroups: (v: ReadonlySet<MuscleGroupId>) => a,
    equipments: (v: ReadonlySet<EquipmentType>) => a,
    onlyLiked: (v: boolean) => a,
  ) =>
  (k: k, v: ActivityFilters[k]) =>
    foldActivityFilter(
      () => tags(v as ActivityFilters["tags"]),
      () => muscleGroups(v as ActivityFilters["muscleGroups"]),
      () => equipments(v as ActivityFilters["equipments"]),
      () => onlyLiked(v as ActivityFilters["onlyLiked"]),
    )(k)

export const filterActivities =
  <a extends ActivityFilters>(
    activityName: (v: { id: ActivityId; def: string }) => string,
  ) =>
  (
    search: Opt.Option<string>,
    filters: a,
    activityListings: ReadonlyArray<
      WithId.WithId<ActivityId, ActivitySettingsExerciseListing>
    >,
  ) =>
  (activities: ReadonlyArray<ActivityWithId>) =>
    pipe(
      activities,
      Arr.filter((a) =>
        pipe(
          search,
          Opt.fold(constTrue, (v) =>
            matches(
              activityName({
                id: a.id,
                def: a.value.value.title,
              }),
              v,
            ),
          ),
        ),
      ),
      Arr.filter(
        filters.tags.size > 0
          ? flow(
              WithId._withId<ActivityId, Activity>(activityId)._value.get,
              foldActivity(
                (a) =>
                  pipe(
                    St.intersection(activityTag)(filters.tags)(a.tags),
                    (ts) => ts.size > 0,
                  ),
                constFalse,
                constFalse,
                constFalse,
              ),
            )
          : constTrue,
      ),
      Arr.filter(
        filters.muscleGroups.size > 0
          ? flow(
              WithId._withId<ActivityId, Activity>(activityId)._value.get,
              foldActivity(
                (a) =>
                  pipe(
                    filters.muscleGroups,
                    St.some((mg) =>
                      pipe(
                        a.muscleShares,
                        Arr.map(
                          (ms) =>
                            muscleIdMap[muscleId_FromMuscleId.get(ms.muscleId)],
                        ),
                        Arr.uniq(muscleGroupId),
                        Arr.elem(muscleGroupId)(mg),
                      ),
                    ),
                  ),
                constFalse,
                constFalse,
                constFalse,
              ),
            )
          : constTrue,
      ),
      Arr.filter(
        filters.equipments.size > 0
          ? flow(
              WithId._withId<ActivityId, Activity>(activityId)._value.get,
              ifActivityExercise((a) =>
                pipe(
                  filters.equipments,
                  St.some((eq) => St.elem(equipmentType)(eq, a.equipments)),
                ),
              ),
            )
          : constTrue,
      ),
      Arr.filter((a) =>
        pipe(
          filters.onlyLiked,
          Bool.fold(
            () => constTrue,
            () =>
              flow(
                WithId._withId<ActivityId, Activity>(activityId)._value.get,
                ifActivityExercise(() =>
                  pipe(
                    activityListings,
                    Arr.findFirst((s) => s.id === a.id),
                    Opt.map(
                      (s) => s.value === ActivitySettingsExerciseListing.liked,
                    ),
                    Opt.getOrElse(constFalse),
                  ),
                ),
              ),
          ),
        )(a),
      ),
    )

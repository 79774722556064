import * as Eq from "fp-ts/es6/Eq"
import * as Opt from "fp-ts/es6/Option"
import * as Str from "fp-ts/es6/string"
import { Time, time } from "time-ts/es6"
import { match } from "ts-pattern"
import { unwrap } from "@fitnesspilot/data-common"

import {
  videoId,
  VimeoVideoId,
  vimeoVideoId,
  YoutubeVideoId,
  youtubeVideoId,
} from "./VideoId"
import { VideoSource, videoSource } from "./VideoSource"

export type YoutubeVideo = {
  source: VideoSource.youtube
  id: YoutubeVideoId
  title: string
  description: string
  channelId: string
  channelTitle: string
  publishedAt: Time
  thumbnailUrl: Opt.Option<string>
}
export const youtubeVideo = Eq.struct({
  source: videoSource,
  id: youtubeVideoId,
  title: Str.Eq,
  description: Str.Eq,
  channelId: Str.Eq,
  channelTitle: Str.Eq,
  publishedAt: time,
  thumbnailUrl: Opt.getEq(Str.Eq),
})

export type VimeoVideo = {
  source: VideoSource.vimeo
  id: VimeoVideoId
  title: string
  description: string
  channelId: string
  channelTitle: string
  publishedAt: Time
  thumbnailUrl: Opt.Option<string>
}
export const vimeoVideo = Eq.struct({
  source: videoSource,
  id: vimeoVideoId,
  title: Str.Eq,
  description: Str.Eq,
  channelId: Str.Eq,
  channelTitle: Str.Eq,
  publishedAt: time,
  thumbnailUrl: Opt.getEq(Str.Eq),
})

export type Video = YoutubeVideo | VimeoVideo
export const video = Eq.struct({
  source: videoSource,
  id: videoId,
  title: Str.Eq,
  description: Str.Eq,
  channelId: Str.Eq,
  channelTitle: Str.Eq,
  publishedAt: time,
  thumbnailUrl: Opt.getEq(Str.Eq),
})

export const linkForVideo = (video: Video) =>
  match(video)
    .with(
      { source: VideoSource.youtube },
      (v) => `https://youtu.be/${unwrap(v.id)}`,
    )
    .with(
      { source: VideoSource.vimeo },
      (v) => `https://vimeo.com/${unwrap(v.id)}`,
    )
    .exhaustive()

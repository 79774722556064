import * as Eq from "fp-ts/es6/Eq"
import { constFalse, flow, pipe } from "fp-ts/es6/function"
import * as Num from "fp-ts/es6/number"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import { Getter, Prism } from "monocle-ts"
import { Duration, duration } from "time-ts/es6"
import { match, P } from "ts-pattern"

import * as Alignment from "@fitnesspilot/data-human-body/dist/Alignment"

import { ActivityWithId } from "../activity/Activity"
import { ActivityType } from "../activity/ActivityType"
import {
  ActivityInstanceExercise,
  activityInstanceExercise,
} from "./ActivityInstanceExercise"
import { ActivityInstanceJob, activityInstanceJob } from "./ActivityInstanceJob"
import {
  ActivityInstanceMuscles,
  activityInstanceMuscles,
} from "./ActivityInstanceMuscles"
import {
  ActivityInstanceSleep,
  activityInstanceSleep,
} from "./ActivityInstanceSleep"

export type ActivityInstanceNonGroup =
  | {
      $type: ActivityType.exercise
      value: ActivityInstanceExercise
    }
  | {
      $type: ActivityType.job
      value: ActivityInstanceJob
    }
  | {
      $type: ActivityType.muscles
      value: ActivityInstanceMuscles
    }
  | {
      $type: ActivityType.sleep
      value: ActivityInstanceSleep
    }
export type ActivityInstance =
  | ActivityInstanceNonGroup
  | {
      $type: ActivityType.group
      value: ActivityInstanceGroup
    }
export const activityInstance = Eq.fromEquals<ActivityInstance>((a, b) =>
  a.$type === ActivityType.exercise && b.$type === ActivityType.exercise
    ? activityInstanceExercise.equals(a.value, b.value)
    : a.$type === ActivityType.job && b.$type === ActivityType.job
    ? activityInstanceJob.equals(a.value, b.value)
    : a.$type === ActivityType.muscles && b.$type === ActivityType.muscles
    ? activityInstanceMuscles.equals(a.value, b.value)
    : a.$type === ActivityType.sleep && b.$type === ActivityType.sleep
    ? activityInstanceSleep.equals(a.value, b.value)
    : a.$type === ActivityType.group && b.$type === ActivityType.group
    ? activityInstanceGroup.equals(a.value, b.value)
    : false,
)

// defined here to avoid circular dependency
export type ActivityInstanceGroup = {
  activities: ReadonlyArray<ActivityInstance>
  repetitions: number
  breakBetweenRepetitions: Duration
}
export const activityInstanceGroup: Eq.Eq<ActivityInstanceGroup> = Eq.struct({
  activities: Arr.getEq(activityInstance),
  repetitions: Num.Eq,
  breakBetweenRepetitions: duration,
})

export const _ActivityInstanceExercise = new Prism<
  ActivityInstanceNonGroup,
  ActivityInstanceExercise
>(
  (s) => (s.$type === ActivityType.exercise ? Opt.some(s.value) : Opt.none),
  (a) => ({ $type: ActivityType.exercise, value: a }),
)
export const _ActivityInstanceJob = new Prism<
  ActivityInstanceNonGroup,
  ActivityInstanceJob
>(
  (s) => (s.$type === ActivityType.job ? Opt.some(s.value) : Opt.none),
  (a) => ({ $type: ActivityType.job, value: a }),
)
export const _ActivityInstanceMuscles = new Prism<
  ActivityInstanceNonGroup,
  ActivityInstanceMuscles
>(
  (s) => (s.$type === ActivityType.muscles ? Opt.some(s.value) : Opt.none),
  (a) => ({ $type: ActivityType.muscles, value: a }),
)
export const _ActivityInstanceSleep = new Prism<
  ActivityInstanceNonGroup,
  ActivityInstanceSleep
>(
  (s) => (s.$type === ActivityType.sleep ? Opt.some(s.value) : Opt.none),
  (a) => ({ $type: ActivityType.sleep, value: a }),
)
export const _ActivityInstanceGroup = new Prism<
  ActivityInstance,
  ActivityInstanceGroup
>(
  (s) => (s.$type === ActivityType.group ? Opt.some(s.value) : Opt.none),
  (a) => ({ $type: ActivityType.group, value: a }),
)
export const _ActivityInstanceNonGroup = new Prism<
  ActivityInstance,
  ActivityInstanceNonGroup
>(
  (s) => (s.$type === ActivityType.group ? Opt.none : Opt.some(s)),
  (a) => a,
)
export const foldActivityInstance =
  <a>(
    exercise: (v: ActivityInstanceExercise) => a,
    job: (v: ActivityInstanceJob) => a,
    muscles: (v: ActivityInstanceMuscles) => a,
    sleep: (v: ActivityInstanceSleep) => a,
    group: (v: ActivityInstanceGroup) => a,
  ) =>
  (v: ActivityInstance) =>
    match(v)
      .with({ $type: ActivityType.exercise }, ({ value }) => exercise(value))
      .with({ $type: ActivityType.job }, ({ value }) => job(value))
      .with({ $type: ActivityType.muscles }, ({ value }) => muscles(value))
      .with({ $type: ActivityType.sleep }, ({ value }) => sleep(value))
      .with({ $type: ActivityType.group }, ({ value }) => group(value))
      .exhaustive()
export const foldActivityInstanceNonGroup =
  <a>(
    exercise: (v: ActivityInstanceExercise) => a,
    job: (v: ActivityInstanceJob) => a,
    muscles: (v: ActivityInstanceMuscles) => a,
    sleep: (v: ActivityInstanceSleep) => a,
  ) =>
  (v: ActivityInstanceNonGroup) =>
    match(v)
      .with({ $type: ActivityType.exercise }, ({ value }) => exercise(value))
      .with({ $type: ActivityType.job }, ({ value }) => job(value))
      .with({ $type: ActivityType.muscles }, ({ value }) => muscles(value))
      .with({ $type: ActivityType.sleep }, ({ value }) => sleep(value))
      .exhaustive()

export const foldActivityInstanceExercise =
  <a>(onElse: () => a, onExercise: (activity: ActivityInstanceExercise) => a) =>
  (activity: ActivityInstance) =>
    pipe(
      activity,
      _ActivityInstanceNonGroup.compose(_ActivityInstanceExercise).getOption,
      Opt.fold(onElse, onExercise),
    )

export const ifActivityInstanceExercise =
  (condition: (activity: ActivityInstanceExercise) => boolean) =>
  (activity: ActivityInstance) =>
    pipe(
      activity,
      _ActivityInstanceNonGroup.compose(_ActivityInstanceExercise).getOption,
      Opt.fold(constFalse, condition),
    )

// export type ActivityWithId = WithId<ActivityId, ActivityInstance>

export const activitiesFromActivityInstance: Getter<
  ActivityInstance,
  ReadonlyArray<ActivityWithId>
> = new Getter<ActivityInstance, ReadonlyArray<ActivityWithId>>(
  foldActivityInstance<ReadonlyArray<ActivityWithId>>(
    (a) => [
      {
        id: a.activity.id,
        value: { $type: ActivityType.exercise, value: a.activity.value },
      },
    ],
    (a) => [
      {
        id: a.activity.id,
        value: { $type: ActivityType.job, value: a.activity.value },
      },
    ],
    (a) => [
      {
        id: a.activity.id,
        value: { $type: ActivityType.muscles, value: a.activity.value },
      },
    ],
    (a) => [
      {
        id: a.activity.id,
        value: { $type: ActivityType.sleep, value: a.activity.value },
      },
    ],
    (a) =>
      pipe(
        a.activities,
        Arr.map(activitiesFromActivityInstance.get),
        Arr.flatten,
      ),
  ),
)

/**
 * removes all `ActivityInstanceGroup` entries and just returns them in a list of `ActivityInstanceNonGroup` entries.
 **/
export const flattenActivityInstance: (
  a: ActivityInstance,
) => ReadonlyArray<ActivityInstanceNonGroup> = (a: ActivityInstance) =>
  match(a)
    .with({ $type: ActivityType.group }, (a) =>
      pipe(a.value.activities, flattenActivityInstances),
    )
    .with({ $type: P.not(ActivityType.group) }, Arr.of)
    .exhaustive()

/**
 * removes all `ActivityInstanceGroup` entries and just returns them in a list of `ActivityInstanceNonGroup` entries.
 **/
export const flattenActivityInstances: (
  as: ReadonlyArray<ActivityInstance>,
) => ReadonlyArray<ActivityInstanceNonGroup> = flow(
  Arr.map(flattenActivityInstance),
  Arr.flatten,
)

/** returns all alignment values within this activity. */
export const getAlignments: (
  activity: ActivityInstance,
) => ReadonlyArray<Opt.Option<Alignment.Alignment>> = (activity) =>
  match(activity)
    .with({ $type: ActivityType.exercise }, (a) => [
      Opt.some(a.value.alignment),
    ])
    .with({ $type: ActivityType.muscles }, (a) => [a.value.alignment])
    .with({ $type: ActivityType.job }, () => [])
    .with({ $type: ActivityType.sleep }, () => [])
    .with({ $type: ActivityType.group }, (a) =>
      pipe(a.value.activities, Arr.map(getAlignments), Arr.flatten),
    )
    .exhaustive()

/** returns alignment or average alignment in case of ActivityInstanceGroup. */
export const getAlignment: (
  activity: ActivityInstance,
) => Opt.Option<Alignment.Alignment> = (activity) =>
  match(activity)
    .with({ $type: ActivityType.exercise }, (a) => Opt.some(a.value.alignment))
    .with({ $type: ActivityType.muscles }, (a) => a.value.alignment)
    .with({ $type: ActivityType.job }, () => Opt.none)
    .with({ $type: ActivityType.sleep }, () => Opt.none)
    .with({ $type: ActivityType.group }, (a) =>
      // filter out empty alignments so we calculate an accurate average
      pipe(
        a.value.activities,
        Arr.map(getAlignments),
        Arr.flatten,
        Arr.compact,
        NonEmpty.fromReadonlyArray,
        Opt.map((alignments) =>
          pipe(
            alignments,
            Arr.foldMap(Num.MonoidSum)(Alignment.numberAsAlignment.reverseGet),
            (alignmentSum) =>
              Alignment.numberAsAlignmentUnsafe(
                alignmentSum / alignments.length,
              ),
          ),
        ),
      ),
    )
    .exhaustive()

import * as Bool from "fp-ts/es6/boolean"
import * as Eq from "fp-ts/es6/Eq"
import { Lens } from "monocle-ts"

export type GoogleFit = {
  importActivities: boolean
  importBodyData: boolean
}
export const googleFit = Eq.struct<GoogleFit>({
  importActivities: Bool.Eq,
  importBodyData: Bool.Eq,
})
export const _importActivities = Lens.fromProp<GoogleFit>()("importActivities")
export const _importBodyData = Lens.fromProp<GoogleFit>()("importBodyData")

export const initialState: GoogleFit = {
  importActivities: false,
  importBodyData: false,
}

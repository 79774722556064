import { pipe } from "fp-ts/es6/function"

import * as actions from "./actions"
import { Action } from "./actions"
import * as selectors from "./selectors"
import { initialState, State } from "./state"

import { createReducer } from "typesafe-actions"

export default createReducer<State, Action>(initialState)
  .handleAction(actions.setLocale, (state, { payload: locale }) =>
    pipe(state, selectors.locale.set(locale)),
  )
  .handleAction(
    actions.loadTranslationsAsync.success,
    (state, { payload: translations }) =>
      pipe(state, selectors.translations.set(translations)),
  )
// .handleAction(actions.loadTranslationsAsync.failure, (state, { payload }) =>
//   selectors.error.set(payload)(state),
// )

import { FC, ReactNode } from "react"
import { Col, Container, Row } from "reactstrap"
import styled from "@emotion/styled"

import { LoggedOutTemplate } from "../LoggedOut/LoggedOut"

const Section = styled(Row)`
  @media (min-height: 400px) {
    padding: 130px 0 0;
  }
`

export type LoginTemplateProps = {
  children?: ReactNode
  className?: string
}

export const LoginTemplate: FC<LoginTemplateProps> = ({
  children,
  className,
}) => (
  <LoggedOutTemplate {...{ className }}>
    <Container>
      <Section {...{ className }}>
        <Col lg={{ offset: 3, size: 6 }}>{children}</Col>
      </Section>
    </Container>
  </LoggedOutTemplate>
)

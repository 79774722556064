/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const ActivityCategory = {
    RepsBodyWeight: "repsBodyWeight",
    RepsExtraWeight: "repsExtraWeight",
    Isometric: "isometric",
    Generic: "generic",
    Distance: "distance",
    Stretching: "stretching",
};
export function ActivityCategoryFromJSON(json) {
    return ActivityCategoryFromJSONTyped(json, false);
}
export function ActivityCategoryFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ActivityCategoryToJSON(value) {
    return value;
}

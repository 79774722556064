import { Lens, Optional } from "monocle-ts"

import { ParentState, State, stateKey } from "./state"

export const state = Lens.fromProp<ParentState>()(stateKey)

export const token = Lens.fromProp<State>()("token")
export const tokenOpt = Optional.fromOptionProp<State>()("token")

export const baseURL = Lens.fromProp<State>()("baseURL")

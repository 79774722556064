import { FC, HTMLAttributes, ReactNode } from "react"
import { Link, useMatch } from "react-router-dom"
import {
  NavItem as NavItem_,
  NavLink as NavLink_,
  NavLinkProps,
} from "reactstrap"
import styled from "@emotion/styled"

const NavLink = styled(({ className, ...props }: NavLinkProps) => (
  <NavLink_ className={`c-navLink ${className ?? ""}`} {...props} />
))``

const NavIcon = styled(
  ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div className={`c-navIcon ${className ?? ""}`} {...props} />
  ),
)`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-shrink: 1;
  flex-grow: 1;
  align-self: stretch;

  & > * {
    width: auto;
    height: auto;
    flex-grow: 1;
  }
`

const NavLabel = styled(
  ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div className={`c-navLabel ${className ?? ""}`} {...props} />
  ),
)`
  flex-shrink: 0;
  flex-grow: 0;
`

const NavGrouping = styled(
  ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div className={`c-navGrouping ${className ?? ""}`} {...props} />
  ),
)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`

const StyledNavLink = styled(({ className, ...props }: NavLinkProps) => (
  <NavLink className={`c-styledNavLink ${className ?? ""}`} {...props} />
))`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colours.textMuted.toString()};
  box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.08);
  padding: 0;
  text-align: center;

  &.active {
    box-shadow: none;
  }

  & > .c-navGrouping {
    height: 50%;
  }

  .c-navIcon {
    color: ${({ theme }) => theme.colours.grey[700].toString()};
  }
`

const StyledNavItem = styled(NavItem_)`
  list-style: none;
  border-left: 1px solid #eeeef8;
  border-bottom: 2px solid #e6e6f2;
  border-top: 1px solid #f8f8ff;

  .c-styledNavLink {
    width: 100%;
    height: 100%;
  }

  .c-navLabel {
    margin-top: 10px;
    font-size: 13px;
    font-weight: ${({ theme }) => theme.font.weights.bold};
  }

  .c-navIcon {
    font-size: 25px;
  }

  &:hover,
  &.active {
    background: ${({ theme }) => theme.colours.white.toString()};

    &,
    .c-styledNavLink {
      color: #1298d9;
    }

    .c-navIcon {
      color: #0d6a98;
    }
  }
`

export type NavItemProps = {
  icon: ReactNode
  children: ReactNode
  to: string
  active: boolean
  className?: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  "data-help-mode"?: string
}

export const NavItem: FC<NavItemProps> = ({
  icon,
  children,
  to,
  active,
  "data-help-mode": helpMode,
  ...props
}) => (
  <StyledNavItem {...props} {...{ active }}>
    <StyledNavLink tag={Link} {...{ to, active }} data-help-mode={helpMode}>
      <NavGrouping>
        <NavIcon>{icon}</NavIcon>
        <NavLabel>{children}</NavLabel>
      </NavGrouping>
    </StyledNavLink>
  </StyledNavItem>
)

export const NavItemContainer: FC<Omit<NavItemProps, "active">> = (props) => {
  const match = useMatch(props.to)

  return <NavItem active={!!match} {...props} />
}

import * as Bool from "fp-ts/es6/boolean"
import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"

import color from "color"

export type Colour = color<string>

export enum ColourScheme {
  dark = "dark",
  light = "light",
}

export type NormalColourScale = Record<300 | 500 | 700, Colour>
export type BaseColours = {
  black: Colour
  blue: NormalColourScale & Record<100, Colour>
  green: NormalColourScale & Record<600, Colour>
  grey: Record<100 | 200 | 300 | 500 | 600 | 700 | 900, Colour>
  red: NormalColourScale & Record<550, Colour>
  white: Colour
  yellow: NormalColourScale & Record<450, Colour>
}
export type BaseFont = {
  families: {
    monospace: string
    sansSerif: string
  }
  heights: {
    base: string
  }
  sizes: {
    base: string
    lg: string
    sm: string
  }
  weights: {
    bold: string
    bolder: string
    light: string
    lighter: string
    normal: string
  }
}
export type Theme = {
  colours: BaseColours & {
    textMuted: Colour

    bodyColour: Colour
    danger: Colour
    primary: Colour
    success: Colour
    warning: Colour
  }
  font: BaseFont & {
    families: BaseFont["families"] & {
      base: string
    }
    weights: BaseFont["weights"] & {
      base: string
    }
  }
  mode: Opt.Option<ColourScheme>
  breakpoints: Record<"sm" | "md" | "lg" | "xl", string>
  media: Record<"sm" | "md" | "lg" | "xl", string>
}

export const prefersColourScheme: IO.IO<Opt.Option<ColourScheme>> = () =>
  pipe(
    window.matchMedia("(prefers-color-scheme: dark)").matches,
    Bool.fold(
      () =>
        pipe(
          window.matchMedia("(prefers-color-scheme: light)").matches,
          Bool.fold(
            () => Opt.none,
            () => Opt.some(ColourScheme.light as ColourScheme),
          ),
        ),
      () => Opt.some(ColourScheme.dark),
    ),
  )

import { FC, useRef } from "react"
import {
  Button,
  CSSModule,
  Input as BsInput,
  InputProps as BsInputProps,
} from "reactstrap"
import { InputType as BsInputType } from "reactstrap/lib/Input"
import styled from "@emotion/styled"

import * as Func from "fp-ts/es6/function"
import { flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import { Option } from "fp-ts/es6/Option"
import * as Opt from "fp-ts/es6/Option"
import { Prism } from "monocle-ts"

import { OmitFromKnownKeys } from "@fitnesspilot/data-common/dist/type"

const toStringOpt = <v,>(f: (v: v) => string) =>
  flow(Opt.map(f), Opt.toUndefined)

type StyledBsInputProps = BsInputProps & { hideClear?: boolean }

const StyledBsInput = styled(
  ({
    required = false,
    hideClear = required,
    ...props
  }: StyledBsInputProps) => <BsInput {...props} {...{ required }} />,
)`
  display: inline-block;

  ${({ hideClear }) =>
    hideClear &&
    `
      &::-webkit-clear-button {
        display: none;
      }
    `}
`

type BaseProps<v, s> = {
  className?: string
  value: Option<v>
  min: Option<v>
  max: Option<v>
  step: Option<s>
  onChange: (v: Option<v>) => IO.IO<void>
}

export type InputProps<v, s> = BaseProps<v, s> &
  OmitFromKnownKeys<StyledBsInputProps, keyof BaseProps<v, s> | "type"> &
  Record<string, any>

export const input =
  <v, s>(
    type: BsInputType,
    prismV: Prism<string, v>,
    sToString: s extends null ? null : (s: s) => string,
  ): FC<InputProps<v, s>> =>
  ({ value, min, max, step, onChange, ...props }) => {
    const vToStr = toStringOpt(prismV.reverseGet)
    const sToStr =
      sToString != null
        ? toStringOpt(sToString as (s: s) => string)
        : Func.constUndefined

    return (
      <StyledBsInput
        value={pipe(
          vToStr(value),
          Opt.fromNullable,
          Opt.getOrElse(Func.constant("")),
        )}
        min={vToStr(min)}
        max={vToStr(max)}
        step={sToStr(step)}
        onChange={({ target }: { target: HTMLInputElement }) =>
          pipe(
            target,
            (ele) => ele.value,
            Opt.fromNullable,
            Opt.chain(Opt.fromPredicate((v) => v !== "")),
            Opt.chain(prismV.getOption),
            onChange,
          )()
        }
        {...{ type }}
        {...props}
      />
    )
  }

export type InputStepperProps<v, s> = Omit<BaseProps<v, s>, "step"> & {
  step: s
} & Omit<StyledBsInputProps, keyof BaseProps<v, s> | "type">

const InputStepperContainer = styled.div`
  display: flex;
`

const StepperInput = styled.input`
  text-align: center;
  color: ${({ theme }) => theme.colours.grey[700].toString()};
  background-color: ${({ theme }) => theme.colours.white.toString()};
  border: none;
  flex-grow: 1;
  min-width: 0;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    display: none;
  }

  -moz-appearance: textfield;
`

export const StepperButton = styled(Button)`
  padding: 0 8px;
  margin: 3px 0;
  height: 20px;
  width: 24px;
  line-height: 1.4;
  font-weight: 700;
  color: ${({ theme }) => theme.colours.primary.toString()};
  background-color: ${({ theme }) => theme.colours.grey[100].toString()};
  border: none;

  transition-duration: 50ms;

  &.btn-secondary:not(:disabled):not(.disabled):active,
  &.btn-secondary:not(:disabled):not(.disabled).active,
  &.btn-secondary:focus,
  &.btn-secondary.focus,
  &.btn-secondary:hover,
  &.btn-secondary.hover {
    color: ${({ theme }) => theme.colours.primary.toString()};
    background-color: ${({ theme }) => theme.colours.grey[100].toString()};
  }

  &.btn-secondary:not(:disabled):not(.disabled):active,
  &.btn-secondary:not(:disabled):not(.disabled).active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
`

export const inputStepper =
  <v, s>(
    type: BsInputType,
    prismV: Prism<string, v>,
    sToString: s extends null ? null : (s: s) => string,
  ): FC<InputStepperProps<v, s>> =>
  ({ className, value, min, max, step, onChange, ...props }) => {
    const vToStr = toStringOpt(prismV.reverseGet)
    const sToStr =
      sToString != null ? (sToString as (s: s) => string) : Func.constUndefined

    const inputRef = useRef<HTMLInputElement>(null)
    const input: IO.IO<HTMLInputElement> = () => {
      if (inputRef.current) return inputRef.current
      throw new Error("Invalid input ref")
    }
    const _onChange: IO.IO<void> = pipe(
      input,
      IO.chain(
        flow(
          (ele) => ele.value,
          Opt.fromNullable,
          Opt.chain(Opt.fromPredicate((v) => v !== "")),
          Opt.chain(prismV.getOption),
          onChange,
        ),
      ),
    )
    const stepUp: IO.IO<void> = pipe(
      input,
      IO.chain((ele) => () => ele.stepUp()),
      IO.apFirst(_onChange),
    )
    const stepDown: IO.IO<void> = pipe(
      input,
      IO.chain((ele) => () => ele.stepDown()),
      IO.apFirst(_onChange),
    )

    return (
      <InputStepperContainer {...{ className }}>
        <StepperButton onClick={stepDown}>−</StepperButton>
        <StepperInput
          ref={inputRef}
          value={pipe(
            vToStr(value),
            Opt.fromNullable,
            Opt.getOrElse(Func.constant("")),
          )}
          min={vToStr(min)}
          max={vToStr(max)}
          step={sToStr(step)}
          onChange={_onChange}
          {...{ type }}
          {...props}
        />
        <StepperButton onClick={stepUp}>+</StepperButton>
      </InputStepperContainer>
    )
  }

export type UnboundedInputProps<v> = Omit<
  BaseProps<v, never>,
  "min" | "max" | "step"
> &
  Omit<StyledBsInputProps, keyof BaseProps<v, never> | "type">

export const unboundedInput = <v,>(
  type: BsInputType,
  prismV: Prism<string, v>,
): FC<UnboundedInputProps<v>> => {
  const Input = input<v, null>(type, prismV, null) as any

  const UnboundedInput = (
    props: Omit<InputProps<v, null>, "min" | "max" | "step">,
  ) => <Input min={Opt.none} max={Opt.none} step={Opt.none} {...props} />

  return UnboundedInput
}

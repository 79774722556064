/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from "../runtime";
/**
 * Check if a given object implements the MuscleStatus interface.
 */
export function instanceOfMuscleStatus(value) {
    let isInstance = true;
    isInstance = isInstance && "readiness" in value;
    isInstance = isInstance && "condition" in value;
    return isInstance;
}
export function MuscleStatusFromJSON(json) {
    return MuscleStatusFromJSONTyped(json, false);
}
export function MuscleStatusFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        readiness: json.readiness,
        condition: json.condition,
        recoveryTime: !exists(json, "recoveryTime") ? undefined : json.recoveryTime,
        regenerationSlope: !exists(json, "regenerationSlope")
            ? undefined
            : json.regenerationSlope,
    };
}
export function MuscleStatusToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        readiness: value.readiness,
        condition: value.condition,
        recoveryTime: value.recoveryTime,
        regenerationSlope: value.regenerationSlope,
    };
}

import { Dispatch, FC } from "react"
import { FaPlus } from "react-icons/fa"
import { FormattedMessage, useIntl } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import { Link } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"
import styled from "@emotion/styled"

import { constVoid, flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import { Predicate } from "fp-ts/es6/Predicate"
import {
  currentTimeZone,
  day,
  dayOfLocalTime,
  localNow,
  localStartOfDay,
  time,
  timeAsLocalTime,
} from "time-ts/es6"
import { arrTrav } from "@fitnesspilot/data-common"

import { CoachTasks } from "@fitnesspilot/components-coach-task/dist/organisms/CoachTasks/CoachTasks"
import { EventConfirmationModalContainer } from "@fitnesspilot/components-coach-task/dist/organisms/EventConfirmationModal/EventConfirmationModal"
import {
  DjIcon,
  IconType,
} from "@fitnesspilot/components-common/dist/atoms/DjIcon/DjIcon"
import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import { Content } from "@fitnesspilot/components-common/dist/molecules/Content/Content"
import { Header } from "@fitnesspilot/components-common/dist/molecules/Header/Header"
import { EventTimeline } from "@fitnesspilot/components-event/dist/organisms/EventTimeline/EventTimeline"
import { BodyMapToggleSideContainer } from "@fitnesspilot/components-human-body/dist/organisms/BodyMapToggleSide/BodyMapToggleSide"
import * as CoachTaskData from "@fitnesspilot/data-coach-task"
import * as CoachTask from "@fitnesspilot/data-coach-task/dist/CoachTask"
import * as Event from "@fitnesspilot/data-event/dist/calendar/Event"
import * as EventOrRecommendation from "@fitnesspilot/data-event/dist/calendar/EventOrRecommendation"
import * as EventData from "@fitnesspilot/data-event/dist/store"

import { MainTemplate } from "../../templates/Main/Main"

const StyledBodyMapToggleSideContainer = styled(BodyMapToggleSideContainer)`
  max-width: 200px;
  margin: auto;
`

const mapState = (state: CoachTaskData.ParentState & EventData.ParentState) => {
  const eventsUpcomingToday = pipe(
    IO.Do,
    IO.bind("tz", () => currentTimeZone),
    IO.bind("localNow", ({ tz }) => localNow(tz)),
    IO.map(
      flow(
        ({ tz, localNow }) => ({
          tz,
          now: timeAsLocalTime(tz).reverseGet(localNow),
          today: dayOfLocalTime.get(localNow),
        }),
        ({ tz, now, today }) => ({
          now,
          startOfTomorrow: pipe(
            day.add(today, 1),
            localStartOfDay,
            timeAsLocalTime(tz).reverseGet,
          ),
        }),
        ({ now, startOfTomorrow }): Predicate<Event.EventWithId> =>
          flow(
            Event._eventWithId._value.composeLens(Event._between).get,
            ([low, high]) =>
              Ord.between(time)(now, startOfTomorrow)(low) ||
              Ord.between(time)(now, startOfTomorrow)(high),
          ),
      ),
    ),
  )

  return {
    coachTasks: pipe(
      state,
      CoachTaskData.selectors.state
        .compose(CoachTaskData.selectors.coachTasks)
        .composeTraversal(arrTrav<CoachTask.CoachTask>())
        .filter(flow(CoachTask._dismissedOn.get, Opt.isNone))
        .filter(flow(CoachTask._completedOn.get, Opt.isNone))
        .asFold().getAll,
    ),
    events: EventData.selectors.state
      .compose(EventData.selectors.eventsOrRecommendations)
      .composeTraversal(arrTrav())
      .composePrism(EventOrRecommendation._EventWithId)
      .filter(eventsUpcomingToday())
      .filter((e) =>
        [Event.EventType.activity, Event.EventType.menu].includes(e.value.type),
      )
      .asFold()
      .getAll(state),
  }
}

const mapDispatch = (
  dispatch: Dispatch<CoachTaskData.Action | EventData.Action>,
) => {
  const dispatch_ =
    (act: CoachTaskData.Action | EventData.Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, constVoid)

  return {
    onSetEventConfirmationIsOpen: flow(
      CoachTaskData.setEventConfirmationIsOpen,
      dispatch_,
    ),
    onDismissCoachTasks: flow(CoachTaskData.dismissCoachTasks, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type OwnProps = Record<string, never>

export type DashboardProps = PropsFromRedux & OwnProps

export const DashboardPage: FC<DashboardProps> = ({
  coachTasks,
  events,
  onSetEventConfirmationIsOpen,
  onDismissCoachTasks,
}) => {
  const intl = useIntl()

  return (
    <MainTemplate
      header={
        <Header
          icon={<DjIcon icon={IconType.dashboard} />}
          title={<FormattedMessage defaultMessage="Dashboard" />}
        />
      }
    >
      <Content data-help-mode="dashboard">
        <Row>
          <Col xs={12} md={8}>
            <CoachTasks
              {...{ coachTasks }}
              onSetEventConfirmationIsOpen={onSetEventConfirmationIsOpen}
              onDismiss={onDismissCoachTasks}
            />

            <Fieldset data-help-mode="upcomingEvents">
              <h2>
                <FormattedMessage defaultMessage="Ahead of you today" />
              </h2>

              {events.length === 0 ? (
                <>
                  <strong>
                    <FormattedMessage defaultMessage="No activities left for today 💪" />
                  </strong>
                  <p>
                    <FormattedMessage defaultMessage="But in case you want to create new events here you go:" />
                  </p>
                  <Button tag={Link} to="/calendar" outline>
                    <FormattedMessage defaultMessage="Go to calendar" />
                  </Button>{" "}
                  <Button
                    tag={Link}
                    to="/calendar/add-event"
                    color="primary"
                    outline
                  >
                    <FaPlus />{" "}
                    <FormattedMessage defaultMessage="Create event" />
                  </Button>
                </>
              ) : (
                <EventTimeline {...{ events }} />
              )}
            </Fieldset>
          </Col>

          <Col xs={12} md={4}>
            <Fieldset data-help-mode="recoveryStatus">
              <h2>
                <FormattedMessage defaultMessage="Recovery status" />
              </h2>

              {/* @TODO what should this do? */}
              <Button color="link">
                <FormattedMessage defaultMessage="Forgot to add training?" />
              </Button>

              <StyledBodyMapToggleSideContainer />
            </Fieldset>
          </Col>
        </Row>
      </Content>

      <EventConfirmationModalContainer id="modals-eventConfirmation" />
    </MainTemplate>
  )
}

export const DashboardPageContainer = connector(DashboardPage)

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApiUserDataFromJSON, ApiUserDataToJSON, } from "../models";
import * as runtime from "../runtime";
/**
 *
 */
export class DataApi extends runtime.BaseAPI {
    /**
     * Handles GET requests for UserManagement.Controllers.DataController.
     */
    async userManagementApiUsersUserIdAboutmeGetRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling userManagementApiUsersUserIdAboutmeGet.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/users/{userId}/aboutme`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ApiUserDataFromJSON(jsonValue));
    }
    /**
     * Handles GET requests for UserManagement.Controllers.DataController.
     */
    async userManagementApiUsersUserIdAboutmeGet(requestParameters, initOverrides) {
        const response = await this.userManagementApiUsersUserIdAboutmeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Handles POST requests for UserManagement.Controllers.DataController.
     */
    async userManagementApiUsersUserIdAboutmePutRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling userManagementApiUsersUserIdAboutmePut.");
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters["Content-Type"] = "application/json";
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/users/{userId}/aboutme`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
            body: ApiUserDataToJSON(requestParameters.apiUserData),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ApiUserDataFromJSON(jsonValue));
    }
    /**
     * Handles POST requests for UserManagement.Controllers.DataController.
     */
    async userManagementApiUsersUserIdAboutmePut(requestParameters, initOverrides) {
        const response = await this.userManagementApiUsersUserIdAboutmePutRaw(requestParameters, initOverrides);
        return await response.value();
    }
}

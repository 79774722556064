import * as Eq from "fp-ts/es6/Eq"
import { constFalse, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import { Lens, Prism } from "monocle-ts"

import { ActivityExercise, activityExercise } from "./ActivityExercise"
import { ActivityId } from "./ActivityId"
import { ActivityJob, activityJob } from "./ActivityJob"
import { ActivityMuscles, activityMuscles } from "./ActivityMuscles"
import { ActivitySleep, activitySleep } from "./ActivitySleep"
import { ActivityType } from "./ActivityType"
import { WithId } from "./WithId"

export type Activity =
  | {
      $type: ActivityType.exercise
      value: ActivityExercise
    }
  | {
      $type: ActivityType.job
      value: ActivityJob
    }
  | {
      $type: ActivityType.muscles
      value: ActivityMuscles
    }
  | {
      $type: ActivityType.sleep
      value: ActivitySleep
    }
export const activity = Eq.fromEquals<Activity>((a, b) =>
  a.$type === ActivityType.exercise
    ? b.$type === ActivityType.exercise
      ? activityExercise.equals(a.value, b.value)
      : false
    : a.$type === ActivityType.job
    ? b.$type === ActivityType.job
      ? activityJob.equals(a.value, b.value)
      : false
    : a.$type === ActivityType.muscles
    ? b.$type === ActivityType.muscles
      ? activityMuscles.equals(a.value, b.value)
      : false
    : activitySleep.equals(a.value, b.value),
)
export const _ActivityExercise = new Prism<Activity, ActivityExercise>(
  (s) => (s.$type === ActivityType.exercise ? Opt.some(s.value) : Opt.none),
  (a) => ({ $type: ActivityType.exercise, value: a }),
)
export const _ActivityJob = new Prism<Activity, ActivityJob>(
  (s) => (s.$type === ActivityType.job ? Opt.some(s.value) : Opt.none),
  (a) => ({ $type: ActivityType.job, value: a }),
)
export const _ActivityMuscles = new Prism<Activity, ActivityMuscles>(
  (s) => (s.$type === ActivityType.muscles ? Opt.some(s.value) : Opt.none),
  (a) => ({ $type: ActivityType.muscles, value: a }),
)
export const _ActivitySleep = new Prism<Activity, ActivitySleep>(
  (s) => (s.$type === ActivityType.sleep ? Opt.some(s.value) : Opt.none),
  (a) => ({ $type: ActivityType.sleep, value: a }),
)
export const foldActivity =
  <a>(
    exercise: (v: ActivityExercise) => a,
    job: (v: ActivityJob) => a,
    muscles: (v: ActivityMuscles) => a,
    sleep: (v: ActivitySleep) => a,
  ) =>
  (v: Activity) =>
    v.$type === ActivityType.exercise
      ? exercise(v.value)
      : v.$type === ActivityType.job
      ? job(v.value)
      : v.$type === ActivityType.muscles
      ? muscles(v.value)
      : sleep(v.value)
export const _title = new Lens<Activity, string>(
  (s) => s.value.title,
  (title) => (s) => ({ ...s, value: { ...s.value, title } }) as Activity,
)
export const _instructions = new Lens<Activity, Opt.Option<string>>(
  (s) => s.value.instructions,
  (instructions) => (s) =>
    ({ ...s, value: { ...s.value, instructions } }) as Activity,
)

export const foldActivityExercise =
  <a>(onElse: () => a, onExercise: (activity: ActivityExercise) => a) =>
  (activity: Activity) =>
    pipe(activity, _ActivityExercise.getOption, Opt.fold(onElse, onExercise))

export const ifActivityExercise =
  (condition: (activity: ActivityExercise) => boolean) =>
  (activity: Activity) =>
    pipe(activity, _ActivityExercise.getOption, Opt.fold(constFalse, condition))

export type ActivityWithId = WithId<ActivityId, Activity>

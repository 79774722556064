/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from "../runtime";
/**
 *
 */
export class SubscriptionsApi extends runtime.BaseAPI {
    /**
     */
    async notificationsApiSubscriptionsUserIdTokensDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling notificationsApiSubscriptionsUserIdTokensDelete.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications/api/subscriptions/{userId}/tokens`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async notificationsApiSubscriptionsUserIdTokensDelete(requestParameters, initOverrides) {
        await this.notificationsApiSubscriptionsUserIdTokensDeleteRaw(requestParameters, initOverrides);
    }
    /**
     */
    async notificationsApiSubscriptionsUserIdTokensTokenDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling notificationsApiSubscriptionsUserIdTokensTokenDelete.");
        }
        if (requestParameters.token === null ||
            requestParameters.token === undefined) {
            throw new runtime.RequiredError("token", "Required parameter requestParameters.token was null or undefined when calling notificationsApiSubscriptionsUserIdTokensTokenDelete.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications/api/subscriptions/{userId}/tokens/{token}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId)))
                .replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async notificationsApiSubscriptionsUserIdTokensTokenDelete(requestParameters, initOverrides) {
        await this.notificationsApiSubscriptionsUserIdTokensTokenDeleteRaw(requestParameters, initOverrides);
    }
    /**
     */
    async notificationsApiSubscriptionsUserIdTokensTokenPutRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling notificationsApiSubscriptionsUserIdTokensTokenPut.");
        }
        if (requestParameters.token === null ||
            requestParameters.token === undefined) {
            throw new runtime.RequiredError("token", "Required parameter requestParameters.token was null or undefined when calling notificationsApiSubscriptionsUserIdTokensTokenPut.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications/api/subscriptions/{userId}/tokens/{token}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId)))
                .replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async notificationsApiSubscriptionsUserIdTokensTokenPut(requestParameters, initOverrides) {
        await this.notificationsApiSubscriptionsUserIdTokensTokenPutRaw(requestParameters, initOverrides);
    }
}

import { FC } from "react"
import { FaBalanceScale } from "react-icons/fa"
import { FormattedMessage } from "react-intl"

import { Content } from "@fitnesspilot/components-common/dist/molecules/Content/Content"
import { Header } from "@fitnesspilot/components-common/dist/molecules/Header/Header"
import { LegalNotice } from "@fitnesspilot/components-legal/dist/molecules/LegalNotice/LegalNotice"

import { MainTemplate } from "../../templates/Main/Main"

export type LegalNoticePageProps = {
  noLogin?: boolean
}

export const LegalNoticePage: FC<LegalNoticePageProps> = ({ noLogin }) => (
  <MainTemplate
    {...{ noLogin }}
    header={
      <Header
        icon={<FaBalanceScale />}
        title={<FormattedMessage defaultMessage="Legal notice" />}
      />
    }
  >
    <Content>
      <LegalNotice />
    </Content>
  </MainTemplate>
)

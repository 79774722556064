import { Lens } from "monocle-ts"
import { optionLensToOptional } from "@fitnesspilot/data-common"

import { _id } from "../User"
import { ParentState, State, stateKey } from "./state"

export const state = Lens.fromProp<ParentState>()(stateKey)

export const userClaims = Lens.fromProp<State>()("userClaims")

export const isLoggedIn = Lens.fromProp<State>()("isLoggedIn")

export const user = Lens.fromProp<State>()("user")
export const stateUserId = state
  .composeOptional(optionLensToOptional(user))
  .composeLens(_id)

export const work = Lens.fromProp<State>()("work")

export const sleep = Lens.fromProp<State>()("sleep")

export const habits = Lens.fromProp<State>()("habits")

export const body = Lens.fromProp<State>()("body")

export const unitSelection = Lens.fromProp<State>()("unitSelection")

export const editingPhoto = Lens.fromProp<State>()("editingPhoto")

export const openPhoto = Lens.fromProp<State>()("openPhoto")

export const photos = Lens.fromProp<State>()("photos")

export const profilePhoto = Lens.fromProp<State>()("profilePhoto")

export const userNavIsOpen = Lens.fromProp<State>()("userNavIsOpen")

export const fitnessLevel = Lens.fromProp<State>()("fitnessLevel")

export const referralCode = Lens.fromProp<State>()("referralCode")

export const inviteCodes = Lens.fromProp<State>()("inviteCodes")

export const isDeleteAccountConfirmationShown = Lens.fromProp<State>()(
  "isDeleteAccountConfirmationShown",
)

export const aboutMeAnswers = Lens.fromProp<State>()("aboutMeAnswers")

export const jobs = Lens.fromProp<State>()("jobs")

export const fcmToken = Lens.fromProp<State>()("fcmToken")

export const googleFit = Lens.fromProp<State>()("googleFit")

import { FC } from "react"
import { Label as Label_, LabelProps as LabelProps_ } from "reactstrap"
import styled from "@emotion/styled"

const StyledLabel = styled(Label_)`
  font-size: ${({ theme }) => theme.font.sizes.sm};
  color: ${({ theme }) => theme.colours.textMuted.toString()};
  align-self: start;
`

export type LabelProps = LabelProps_ & {
  noColon?: boolean
}

export const Label: FC<LabelProps> = ({ noColon, children, ...props }) => (
  <StyledLabel {...props}>
    <>
      {children}
      {noColon ?? ":"}
    </>
  </StyledLabel>
)

import { FC, useEffect, useMemo } from "react"
import { FaArrowLeft, FaCheck, FaShoppingBasket } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import { Badge, Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import * as Bool from "fp-ts/es6/boolean"
import { constNull, flow, pipe } from "fp-ts/es6/function"
import * as Func from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import { Prism } from "monocle-ts"
import { match } from "ts-pattern"

import { CancelButton } from "@fitnesspilot/components-common/dist/molecules/CancelButton/CancelButton"
import { Modal } from "@fitnesspilot/components-common/dist/organisms/Modal/Modal"
import * as ActivityId from "@fitnesspilot/data-activity/dist/activity/ActivityId"
import * as WithId from "@fitnesspilot/data-activity/dist/activity/WithId"
import { ActivityInstanceNonGroup } from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"
import * as ActivitySettingsExercise from "@fitnesspilot/data-activity/dist/activitySettings/ActivitySettingsExercise"
import * as ActivityWithSettings from "@fitnesspilot/data-activity/dist/activitySettings/ActivityWithSettings"
import * as ActivityData from "@fitnesspilot/data-activity/dist/store"
import * as EventData from "@fitnesspilot/data-event/dist/store"
import { fetchCatalogForEditingEvent } from "@fitnesspilot/data-event/dist/store"
import { AddActivityStep } from "@fitnesspilot/data-event/dist/store/AddActivity"
import * as AddActivity from "@fitnesspilot/data-event/dist/store/AddActivity"
import * as EditingEvent from "@fitnesspilot/data-event/dist/store/EditingEvent"

import {
  ActivitySelection,
  ActivitySelectionView,
} from "../ActivitySelection/ActivitySelection"
import { VideoSearch } from "../VideoSearch/VideoSearch"

import { Dispatch } from "redux"

const _editingEvent = Prism.some<EditingEvent.EditingEventWithId>().composeLens(
  WithId._value(),
)
const _addActivity = _editingEvent.composeLens(EditingEvent._addActivity)

const mapState = (state: EventData.ParentState & ActivityData.ParentState) => ({
  editingEvent: pipe(
    state,
    EventData.selectors.state.composeLens(EventData.selectors.editingEvent).get,
  ),
  activitiesWithSettings: pipe(
    state,
    ActivityData.selectors.state.compose(
      ActivityData.selectors.activitiesWithSettings,
    ).get,
  ),
})

const mapDispatch = (
  dispatch: Dispatch<EventData.Action | ActivityData.Action>,
) => {
  const dispatch_ =
    (act: EventData.Action | ActivityData.Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, Func.constVoid)

  return {
    onSelectActivity: flow(EventData.selectActivity, dispatch_),
    onUnselectActivity: flow(EventData.unselectActivity, dispatch_),
    onChangeSelectedActivity: flow(EventData.updateSelectedActivity, dispatch_),
    onChangeSearch: flow(EventData.setCatalogSearch, dispatch_),
    onChangeSorting: flow(EventData.setCatalogSorting, dispatch_),
    onChangeFilters: flow(EventData.setCatalogFilters, dispatch_),
    onSave: flow(EventData.saveSelectedActivities, dispatch_),
    onCancel: flow(EventData.cancelSelectedActivities, dispatch_),
    onSetStep: flow(EventData.setAddActivityStep, dispatch_),
    onSetEventVideo: flow(EventData.setEventVideo, dispatch_),
    onSetActivityListing: flow(ActivityData.setActivityListing, dispatch_),
    fetchCatalog: pipe(fetchCatalogForEditingEvent(), dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

const Title = (step: AddActivityStep) =>
  match(step)
    .with(AddActivityStep.searchVideo, () => (
      <FormattedMessage defaultMessage="Start Video Workout" />
    ))
    .with(AddActivityStep.activityType, () => (
      <FormattedMessage defaultMessage="Add Activity" />
    ))
    .with(AddActivityStep.muscleGroups, () => (
      <FormattedMessage defaultMessage="Select Muscle Groups" />
    ))
    .with(AddActivityStep.activities, () => (
      <FormattedMessage defaultMessage="Select Exercises" />
    ))
    .with(AddActivityStep.activitySelection, () => (
      <FormattedMessage defaultMessage="Exercise Selection" />
    ))
    .exhaustive()

type PropsFromRedux = ConnectedProps<typeof connector>

export type AddActivityModalProps = PropsFromRedux & {
  id: string
  isOpen: boolean
  onSetIsOpen: (v: boolean) => IO.IO<void>
}

export const AddActivityModal: FC<AddActivityModalProps> = ({
  editingEvent,
  activitiesWithSettings,
  id,
  fetchCatalog,
  ...props
}) => {
  const { isOpen, onSetIsOpen, onSetStep, onSave, onCancel } = props

  const {
    step,
    search,
    sorting,
    filters,
    videos,
    selectedActivities,
    catalog,
  } = useMemo(
    () => ({
      step: pipe(
        editingEvent,
        _addActivity.composeLens(AddActivity._step).getOption,
        Opt.getOrElse(() => AddActivity.empty.step),
      ),
      search: pipe(
        editingEvent,
        _addActivity.composeLens(AddActivity._search).getOption,
        Opt.getOrElse(() => AddActivity.empty.search),
      ),
      sorting: pipe(
        editingEvent,
        _addActivity.composeLens(AddActivity._sorting).getOption,
        Opt.getOrElse(() => AddActivity.empty.sorting),
      ),
      filters: pipe(
        editingEvent,
        _addActivity.composeLens(AddActivity._filters).getOption,
        Opt.getOrElse(() => AddActivity.empty.filters),
      ),
      videos: pipe(
        editingEvent,
        _addActivity.composeLens(AddActivity._videos).getOption,
        Opt.getOrElse(() => AddActivity.empty.videos),
      ),
      selectedActivities: pipe(
        editingEvent,
        _editingEvent.composeLens(EditingEvent._selectedActivities).getOption,
        Opt.getOrElse<ReadonlyArray<ActivityInstanceNonGroup>>(() => []),
      ),
      catalog: pipe(
        editingEvent,
        _editingEvent.composeLens(EditingEvent._catalog).getOption,
        Opt.getOrElse<ReadonlyArray<ActivityInstanceNonGroup>>(() => []),
      ),
    }),
    [editingEvent],
  )
  const activityListings = useMemo(
    () =>
      pipe(
        activitiesWithSettings,
        Arr.map((a) =>
          pipe(
            a,
            WithId._value<
              ActivityId.ActivityId,
              ActivityWithSettings.ActivityWithSettings
            >()
              .composeOptional(ActivityWithSettings._ActivitySettingsExercise)
              .composeLens(ActivitySettingsExercise._listing).getOption,
            Opt.map(
              (
                s,
              ): WithId.WithId<
                ActivityId.ActivityId,
                ActivitySettingsExercise.ActivitySettingsExerciseListing
              > => ({
                id: a.id,
                value: s,
              }),
            ),
          ),
        ),
        Arr.filterMap(Func.identity),
      ),
    [activitiesWithSettings],
  )

  useEffect(() => {
    if (isOpen) {
      fetchCatalog()
    }
  }, [isOpen, fetchCatalog])

  return (
    <Modal
      toggle={onSetIsOpen(false)}
      {...{ isOpen }}
      size="lg"
      data-help-mode="selectActivities"
    >
      <ModalHeader tag="h1" toggle={onSetIsOpen(false)}>
        {Title(step)}
        {pipe(
          step === AddActivityStep.activitySelection,
          Bool.fold(constNull, () =>
            pipe(
              selectedActivities,
              NonEmpty.fromReadonlyArray,
              Opt.fold(constNull, (as) => (
                <>
                  {" "}
                  <Badge color="primary">{as.length}</Badge>
                </>
              )),
            ),
          ),
        )}
      </ModalHeader>

      <ModalBody>
        {match(step)
          .with(AddActivityStep.searchVideo, () => (
            <VideoSearch
              {...{ search, videos }}
              onChangeSearch={props.onChangeSearch}
              onSelectVideo={flow(
                props.onSetEventVideo,
                IO.chain(() => onSetIsOpen(false)),
              )}
            />
          ))
          .with(AddActivityStep.activityType, () => (
            <h4>
              {/* TODO show selection, component probably already exists but not pushed yet */}
              <FormattedMessage defaultMessage="Select type of activity" />
              <p>TODO</p>
            </h4>
          ))
          // @TODO add muscle group selection with "always skip option"
          .with(AddActivityStep.muscleGroups, () => <p>TODO</p>)
          .with(AddActivityStep.activities, () => (
            <ActivitySelection
              {...props}
              view={ActivitySelectionView.add}
              id={`${id}-add`}
              {...{
                search,
                sorting,
                filters,
                selectedActivities,
                catalog,
                activityListings,
              }}
            />
          ))
          .with(AddActivityStep.activitySelection, () => (
            <ActivitySelection
              {...props}
              view={ActivitySelectionView.view}
              id={`${id}-activitySelection`}
              {...{
                search,
                sorting,
                filters,
                selectedActivities,
                catalog,
                activityListings,
              }}
            />
          ))
          .exhaustive()}
      </ModalBody>

      <ModalFooter>
        {step === AddActivityStep.activities ? (
          <>
            <Button
              outline
              color="primary"
              onClick={onSetStep(AddActivityStep.activitySelection)}
              data-help-mode="goToExerciseSelection"
            >
              <FaShoppingBasket />{" "}
              <FormattedMessage defaultMessage="Go to Exercise Selection" />
              {pipe(
                selectedActivities,
                NonEmpty.fromReadonlyArray,
                Opt.fold(constNull, (as) => (
                  <>
                    {" "}
                    <Badge color="primary">{as.length}</Badge>
                  </>
                )),
              )}
            </Button>
          </>
        ) : (
          <div style={{ display: "contents" }} data-help-mode="saveOrGoBack">
            {step !== AddActivityStep.searchVideo && (
              <Button
                color="link"
                onClick={onSetStep(AddActivityStep.activities)}
              >
                <FaArrowLeft />{" "}
                <FormattedMessage defaultMessage="Go back to selection" />
              </Button>
            )}
            <Button
              color="success"
              onClick={pipe(
                onSave(),
                IO.chain(() => onSetIsOpen(false)),
              )}
            >
              <FaCheck /> <FormattedMessage defaultMessage="Save" />
            </Button>
          </div>
        )}
        <CancelButton
          type="button"
          onClick={pipe(
            onCancel(),
            IO.chain(() => onSetIsOpen(false)),
          )}
        />
      </ModalFooter>
    </Modal>
  )
}

export const AddActivityModalContainer = connector(AddActivityModal)

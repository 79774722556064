import { FormattedMessage } from "react-intl"

import { flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { fromTraversable, Getter, Optional } from "monocle-ts"
import {
  DurationAsTimeOfDayDuration,
  isoLocalDuration,
  isoLocalTimeOfDayDuration,
  isoStringAsDuration,
  isoStringAsLocalDuration,
  isoStringAsLocalTimeOfDayDuration,
  LocalDurationAsLocalTimeOfDayDuration,
} from "time-ts/es6"
import * as Duration from "time-ts/es6/UTC/Duration"
import { optionLensToOptional, unsafeFromSome } from "@fitnesspilot/data-common"

import { LocalTimeOfDayDurationInput } from "@fitnesspilot/components-common/dist/atoms/input/LocalTimeOfDayDurationInput"
import { FormGroup } from "@fitnesspilot/components-common/dist/molecules/FormGroup/FormGroup"
import {
  _ActivityInstanceExercise,
  _ActivityInstanceGroup,
  _ActivityInstanceJob,
  _ActivityInstanceMuscles,
  _ActivityInstanceSleep,
  ActivityInstance,
  ActivityInstanceNonGroup,
  foldActivityInstance,
  foldActivityInstanceNonGroup,
} from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"
import * as ActivityInstanceExercise from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceExercise"
import * as ActivityInstanceGroup from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceGroup"
import * as ActivityInstanceJob from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceJob"
import * as ActivityInstanceMuscles from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceMuscles"
import * as ActivityInstanceSleep from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceSleep"

const PT15M = pipe(
  "PT15M",
  isoStringAsLocalTimeOfDayDuration.getOption,
  unsafeFromSome,
)

const durationOpt: (
  v: ActivityInstanceNonGroup,
) => Optional<ActivityInstanceNonGroup, Duration.Duration> =
  foldActivityInstanceNonGroup(
    () =>
      _ActivityInstanceExercise.composeOptional(
        optionLensToOptional(ActivityInstanceExercise._duration),
      ),
    () =>
      _ActivityInstanceJob.composeOptional(
        ActivityInstanceJob._duration.asOptional(),
      ),
    () =>
      _ActivityInstanceMuscles.composeOptional(
        optionLensToOptional(ActivityInstanceMuscles._duration),
      ),
    () =>
      _ActivityInstanceSleep.composeOptional(
        ActivityInstanceSleep._duration.asOptional(),
      ),
  )

export type ActivityDurationProps = {
  id: string
  activity: ActivityInstanceNonGroup
  onChange: (activity: ActivityInstanceNonGroup) => IO.IO<void>
}
export const ActivityDuration = ({
  id,
  activity,
  onChange,
}: ActivityDurationProps) => {
  const _durationOpt = durationOpt(activity)
    .composePrism(DurationAsTimeOfDayDuration)
    .composeIso(isoLocalTimeOfDayDuration.reverse())

  return pipe(activity, (a) => (
    <FormGroup
      inputId={id}
      label={<FormattedMessage defaultMessage="Duration" />}
    >
      <LocalTimeOfDayDurationInput
        value={pipe(a, _durationOpt.getOption)}
        min={Opt.none}
        max={Opt.none}
        step={Opt.some(PT15M)}
        onChange={(newDuration) =>
          pipe(
            a,
            _durationOpt.set(
              pipe(
                newDuration,
                Opt.getOrElse(() => PT15M),
              ),
            ),
            onChange,
          )
        }
      />
    </FormGroup>
  ))
}

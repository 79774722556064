import * as Ord from "fp-ts/es6/Ord"
import * as Ordering from "fp-ts/es6/Ordering"
import { Lens } from "monocle-ts"
import { Time, time } from "time-ts/es6"

export type ExerciseSlot = {
  start: Time
  end: Time
}
export const exerciseSlot = Ord.fromCompare<ExerciseSlot>((a, b) =>
  Ordering.Monoid.concat(
    time.compare(a.start, b.start),
    time.compare(a.end, b.end),
  ),
)
export const _start = Lens.fromProp<ExerciseSlot>()("start")
export const _end = Lens.fromProp<ExerciseSlot>()("end")

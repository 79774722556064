import { Helmet } from "react-helmet"
import { connect, ConnectedProps } from "react-redux"
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom"

import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import { optionLensToOptional } from "@fitnesspilot/data-common"

import { UpdateNoticeContainer } from "@fitnesspilot/components-common/dist/organisms/UpdateNotice/UpdateNotice"
import { CookieConsentContainer } from "@fitnesspilot/components-legal/dist/organisms/CookieConsent/CookieConsent"
import * as UserData from "@fitnesspilot/data-user"

import { AddEventPageContainer } from "./pages/AddEvent/AddEvent"
import { CalendarPageContainer } from "./pages/Calendar/Calendar"
import { DashboardPageContainer } from "./pages/Dashboard/Dashboard"
import { EventDetailsPageContainer } from "./pages/EventDetails/EventDetails"
import { ExercisesPageContainer } from "./pages/Exercises/Exercises"
import { LegalNoticePage } from "./pages/LegalNotice/LegalNotice"
import { LoginPageContainer } from "./pages/Login/Login"
import { MeAndMyBodyPageContainer } from "./pages/MeAndMyBody/MeAndMyBody"
import { MusclePreferencesPageContainer } from "./pages/MusclePreferences/MusclePreferences"
import { MuscleStatusPageContainer } from "./pages/MuscleStatus/MuscleStatus"
import { MyAccountPageContainer } from "./pages/MyAccount/MyAccount"
import { MySportsPageContainer } from "./pages/MySports/MySports"
import { PasswordResetPageContainer } from "./pages/PasswordReset/PasswordReset"
import { PhotoGalleryPageContainer } from "./pages/PhotoGallery/PhotoGallery"
import { PrivacyPolicyPageContainer } from "./pages/PrivacyPolicy/PrivacyPolicy"
import { SetupPageContainer } from "./pages/Setup/Setup"
import { SignupPageContainer } from "./pages/Signup/Signup"
import { SportPreferencesPageContainer } from "./pages/SportPreferences/SportPreferences"
import { WorkAndLifestylePageContainer } from "./pages/WorkAndLifestyle/WorkAndLifestyle"
import { history, RootState } from "./reduxStore"
import { RouteEffects } from "./RouteEffects"

import "./App.css"
import * as Sentry from "@sentry/react"
import { HistoryRouter } from "redux-first-history/rr6"

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

type AppRoutesProps = {
  isLoggedIn: boolean
  hasCompletedSetup: boolean
}

const AppRoutes = ({ isLoggedIn, hasCompletedSetup }: AppRoutesProps) => {
  const { pathname } = useLocation()
  const [searchParams, _] = useSearchParams()
  const inviteCode = searchParams.get("inviteCode")
  const redo = searchParams.has("redo")
  const loggedOutRedirectTo = inviteCode === null ? "/login" : "/signup"

  return (
    <SentryRoutes>
      {isLoggedIn && (
        <>
          {!hasCompletedSetup && (
            <Route
              path="*"
              element={<Navigate replace to={{ pathname: "/setup/generic" }} />}
            />
          )}

          {hasCompletedSetup && !redo ? (
            <Route
              path="/setup"
              element={<Navigate replace to="/dashboard" />}
            />
          ) : (
            <Route
              path="/setup"
              element={<SetupPageContainer id="pages-setup" />}
            >
              <Route
                path=":step"
                element={<SetupPageContainer id="pages-setup" />}
              />
            </Route>
          )}

          <Route path="/" element={<Navigate replace to="/dashboard" />} />
          <Route path="/dashboard" element={<DashboardPageContainer />} />
          <Route path="/calendar" element={<CalendarPageContainer />} />
          <Route
            path="/calendar/add-event"
            element={<AddEventPageContainer />}
          />
          <Route
            path="/calendar/events/:eventId?"
            element={<EventDetailsPageContainer />}
          />
          <Route path="/sports" element={<MuscleStatusPageContainer />} />
          <Route path="/sports/my-sports" element={<MySportsPageContainer />} />
          <Route
            path="/sports/exercises"
            element={<ExercisesPageContainer id="pages-exercises" />}
          />
          <Route
            path="/sports/sport-preferences/:sportId"
            element={<SportPreferencesPageContainer />}
          />
          <Route
            path="/sports/muscle-preferences/:muscleGroup"
            element={<MusclePreferencesPageContainer />}
          />
          <Route
            path="/aboutMe"
            element={<MyAccountPageContainer id="pages-myAccount" />}
          />
          <Route
            path="/aboutMe/body"
            element={<MeAndMyBodyPageContainer id="pages-meAndMyBody" />}
          />
          <Route
            path="/aboutMe/MyPhotos"
            element={<PhotoGalleryPageContainer />}
          />
          <Route
            path="/aboutMe/workAndLifestyle"
            element={
              <WorkAndLifestylePageContainer id="pages-workAndLifestyle" />
            }
          />
          <Route
            path="*"
            element={<Navigate replace to={{ pathname: "/dashboard" }} />}
          />
        </>
      )}

      {!isLoggedIn && (
        <>
          <Route
            path="/"
            element={<Navigate replace to={loggedOutRedirectTo} />}
          />
          {inviteCode !== null && (
            <Route
              path="/signup"
              element={<SignupPageContainer id="pages-signup" />}
            />
          )}
          <Route
            path="/login"
            element={<LoginPageContainer id="pages-login" />}
          />
          <Route
            path="/login/passwordReset"
            element={<PasswordResetPageContainer id="pages-passwordReset" />}
          />
          <Route
            path="*"
            element={
              <Navigate
                replace
                to={loggedOutRedirectTo}
                state={{ from: pathname }}
              />
            }
          />
        </>
      )}

      <Route
        path="/legal/notice"
        element={<LegalNoticePage noLogin={!isLoggedIn} />}
      />
      <Route
        path="/legal/privacy"
        element={<PrivacyPolicyPageContainer noLogin={!isLoggedIn} />}
      />
    </SentryRoutes>
  )
}

const mapState = (state: RootState) => ({
  isLoggedIn: pipe(
    UserData.selectors.state.compose(UserData.selectors.isLoggedIn).get(state),
    Opt.getOrElse(() => false),
  ),
  hasCompletedSetup: pipe(
    optionLensToOptional(
      UserData.selectors.state.compose(UserData.selectors.user),
    )
      .composeLens(UserData._hasCompletedSetup)
      .getOption(state),
    Opt.getOrElse(() => true), // TODO or default to false?
  ),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type AppProps = PropsFromRedux

const App = (props: AppProps) => (
  <>
    <Helmet>
      {/* @TODO Title and description */}
      <title>
        Fitnesspilot
        {location.hostname.endsWith(".localhost")
          ? " — Local"
          : location.hostname.startsWith("staging.")
          ? " — Staging"
          : ""}
      </title>
    </Helmet>

    <HistoryRouter history={history}>
      <RouteEffects />

      <AppRoutes {...props} />
    </HistoryRouter>

    <UpdateNoticeContainer />
    <CookieConsentContainer />

    <div id="modal-container" />
    <div id="popover-container" />
  </>
)

const AppContainer = connector(App)

export default AppContainer

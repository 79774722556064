import { identity } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Str from "fp-ts/es6/string"
import { Prism } from "monocle-ts"

export enum ActivityTag {
  bodybuilding = "bodybuilding",
  calisthenics = "calisthenics",
  strength = "strength",
  endurance = "endurance",
  strengthEndurance = "strengthEndurance",
  cardio = "cardio",
  isometric = "isometric",
  isotonic = "isotonic",
  sport = "sport",
  other = "other",
  yoga = "yoga",
  bodyshaping = "bodyshaping",
  plyometric = "plyometric",
  stretching = "stretching",
  balance = "balance",
  stability = "stability",
  mobility = "mobility",
  fascia = "fascia",
  powerlifting = "powerlifting",
  strongman = "strongman",
  weightlifting = "weightlifting",
  pushUpVariations = "pushUpVariations",
  core = "core",
  fullBody = "fullBody",
  beginner = "beginner",
  intermediate = "intermediate",
  advanced = "advanced",
}

export enum ActivityTagSportType {
  none = "none",
  group = "group",
  individual = "individual",
}

export const activityTag: Ord.Ord<ActivityTag> = Str.Ord
export const stringAsActivityTag = new Prism(
  // @TODO
  Opt.some as (v: string) => Opt.Option<ActivityTag>,
  identity as (v: ActivityTag) => string,
)

const activityTagSportTypeActivityTagMap: Record<
  ActivityTag,
  ActivityTagSportType
> = {
  [ActivityTag.calisthenics]: ActivityTagSportType.group,
  [ActivityTag.bodybuilding]: ActivityTagSportType.group,
  [ActivityTag.strength]: ActivityTagSportType.none,
  [ActivityTag.endurance]: ActivityTagSportType.none,
  [ActivityTag.strengthEndurance]: ActivityTagSportType.none,
  [ActivityTag.isometric]: ActivityTagSportType.none,
  [ActivityTag.isotonic]: ActivityTagSportType.none,
  [ActivityTag.cardio]: ActivityTagSportType.none,
  [ActivityTag.sport]: ActivityTagSportType.individual,
  [ActivityTag.other]: ActivityTagSportType.none,
  [ActivityTag.yoga]: ActivityTagSportType.group,
  [ActivityTag.bodyshaping]: ActivityTagSportType.group,
  [ActivityTag.plyometric]: ActivityTagSportType.none,
  [ActivityTag.stretching]: ActivityTagSportType.group,
  [ActivityTag.balance]: ActivityTagSportType.none,
  [ActivityTag.stability]: ActivityTagSportType.none,
  [ActivityTag.mobility]: ActivityTagSportType.none,
  [ActivityTag.fascia]: ActivityTagSportType.none,
  [ActivityTag.powerlifting]: ActivityTagSportType.group,
  [ActivityTag.strongman]: ActivityTagSportType.group,
  [ActivityTag.weightlifting]: ActivityTagSportType.group,
  [ActivityTag.pushUpVariations]: ActivityTagSportType.none,
  [ActivityTag.core]: ActivityTagSportType.none,
  [ActivityTag.fullBody]: ActivityTagSportType.none,
  [ActivityTag.beginner]: ActivityTagSportType.none,
  [ActivityTag.intermediate]: ActivityTagSportType.none,
  [ActivityTag.advanced]: ActivityTagSportType.none,
}

export const activityTagSportType: Ord.Ord<ActivityTagSportType> = Str.Ord

export const activityTagSportTypeFromActivityTag = (tag: ActivityTag) =>
  activityTagSportTypeActivityTagMap[tag]

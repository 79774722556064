import { FC } from "react"
import { FormattedMessage } from "react-intl"
import { Col, Row } from "reactstrap"

import { Markdown } from "@fitnesspilot/components-common/dist/molecules/Markdown/Markdown"

import {
  RecommendationSettingsField,
  RecommendationType,
} from "../../atoms/RecommendationSettingsField/RecommendationSettingsField"

export type FormData = {
  enableActivityRecommendations: boolean
  enableNutritionRecommendations: boolean
}

export type RecommendationsFieldsetProps = {
  id: string
}

export const RecommendationsFieldset: FC<RecommendationsFieldsetProps> = ({
  id,
}) => (
  <>
    <Row>
      <Col md={6}>
        <RecommendationSettingsField<FormData, "enableActivityRecommendations">
          id={`${id}-activity`}
          name="enableActivityRecommendations"
          type={RecommendationType.activity}
          title={<FormattedMessage defaultMessage="Activity recommendations" />}
          content={
            <FormattedMessage
              defaultMessage={`Enable this option if you want

* individual training session recommendations
* reminders for sports related tasks
* sport related information which is relevant for you
`}
            >
              {(text) => <Markdown>{String(text)}</Markdown>}
            </FormattedMessage>
          }
          checkboxLabel={
            <FormattedMessage defaultMessage="Include activity recommendations" />
          }
        />
      </Col>
      <Col md={6}>
        <RecommendationSettingsField<FormData, "enableNutritionRecommendations">
          id={`${id}-nutrition`}
          name="enableNutritionRecommendations"
          type={RecommendationType.activity}
          title={
            <FormattedMessage defaultMessage="Nutritional recommendations" />
          }
          content={
            <FormattedMessage
              defaultMessage={`Enable this option if you want

* individual meal recommendations
* reminders for nutrition related tasks
* nutrition-related information which is relevant for you
`}
            >
              {(text) => <Markdown>{String(text)}</Markdown>}
            </FormattedMessage>
          }
          checkboxLabel={
            <FormattedMessage defaultMessage="Include nutritional recommendations" />
          }
          comingSoon
        />
      </Col>
    </Row>
  </>
)

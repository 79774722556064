import { FormattedMessage } from "react-intl"
import { Link as Link_ } from "react-router-dom"
import styled from "@emotion/styled"

import { pipe } from "fp-ts/es6/function"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { dateAsTime, now, time } from "time-ts/es6"
import { prismToGetter } from "@fitnesspilot/data-common"

import { _ActivityInstanceExercise } from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"
import { _alignment } from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceExercise"
import * as Event_ from "@fitnesspilot/data-event/dist/calendar/Event"
import {
  EventWithId,
  stringAsEventId,
} from "@fitnesspilot/data-event/dist/calendar/Event"

const Link = styled(Link_)`
  color: ${({ theme }) => theme.colours.black.toString()};
`

const Timeline = styled.div`
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 6px;
    width: 2px;
    background: ${({ theme }) => theme.colours.grey[300].toString()};
  }
`

type CheckboxProps = {
  className?: string
  checked: boolean
}
const Checkbox = styled((props: CheckboxProps) => (
  <input type="checkbox" readOnly {...props} />
))`
  margin-right: 0.5em;
  pointer-events: none;
`

const Event = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 4px 0;
  font-variant-numeric: tabular-nums;
`

export type EventsTodayProps = {
  className?: string
  events: ReadonlyArray<EventWithId>
}
export const EventsToday = ({ className, events }: EventsTodayProps) => (
  <Timeline {...{ className }}>
    {pipe(
      events,
      Arr.map((event) => (
        <Link
          key={stringAsEventId.reverseGet(event.id)}
          to={`/calendar/events/${stringAsEventId.reverseGet(event.id)}`}
        >
          <Event>
            <Checkbox
              checked={pipe(event.value.between, ([_, end]) =>
                Ord.leq(time)(end, now()),
              )}
            />
            <span>
              {pipe(
                event,
                Event_._eventWithId._value
                  .composeLens(Event_._start)
                  .composeGetter(prismToGetter(dateAsTime)).get,
                (start) => (
                  <FormattedMessage
                    defaultMessage="{start, time, short}"
                    values={{ start }}
                  />
                ),
              )}{" "}
              {event.value.title}
            </span>
          </Event>
        </Link>
      )),
    )}
  </Timeline>
)

import { forwardRef } from "react"
import { TooltipRenderProps } from "react-joyride"
import { Card } from "reactstrap"

import { OmitFromKnownKeys } from "@fitnesspilot/data-common"

export type TooltipBodyProps = TooltipRenderProps["tooltipProps"]

export const TooltipBody = forwardRef<
  HTMLElement,
  OmitFromKnownKeys<TooltipBodyProps, "ref">
>((props, ref) => <Card {...props} innerRef={ref} />)

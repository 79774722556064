import { Component, FC, useCallback, useMemo, useRef, useState } from "react"
import { createRoot } from "react-dom/client"
import {
  FaCalendarDay,
  FaChevronLeft,
  FaChevronRight,
  FaEllipsisV,
} from "react-icons/fa"
import { useIntl } from "react-intl"
import Media from "react-media"
import { css, useTheme } from "@emotion/react"
import styled from "@emotion/styled"

import * as Bool from "fp-ts/es6/boolean"
import * as Func from "fp-ts/es6/function"
import { flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as OptT from "fp-ts/es6/OptionT"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import * as Pair from "fp-ts/es6/ReadonlyTuple"
import {
  addLocalTimeOfDayDuration,
  currentLocalTimeOfDay,
  currentTimeZone,
  LocalTimeOfDay,
  localTimeOfDay,
  mkLocalTimeOfDayDuration,
  mkLocalTimeOfDayWrapped,
} from "time-ts/es6"
import { runIO } from "@fitnesspilot/data-common"

import {
  dayOfWeekAsDayOfWeekIso,
  DayOfWeekIso,
} from "@fitnesspilot/data-common/dist/DayOfWeek"
import { serializedStyles } from "@fitnesspilot/data-common/dist/emotion"

import { cssContentIcon } from "../../atoms/CssContentIcon/CssContentIcon"
import { DayOfWeekName } from "../../atoms/DayOfWeekName/DayOfWeekName"

import "@fullcalendar/react?effect"
import "@fullcalendar/common/main.css"
import "@fullcalendar/daygrid/main.css"
import "@fullcalendar/timegrid/main.css"
import bootstrapPlugin from "@fullcalendar/bootstrap"
import { CalendarOptions as FullCalendarProps } from "@fullcalendar/common"
import enGbLocale from "@fullcalendar/core/locales/en-gb"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import FullCalendar from "@fullcalendar/react"
import timeGridPlugin from "@fullcalendar/timegrid"
import color from "color"

const querySelector =
  (q: string) =>
  (e: Element): IO.IO<Opt.Option<Element>> =>
  () =>
    pipe(e.querySelector(q), Opt.fromNullable)
const querySelectorAll =
  (q: string) =>
  (e: Element): IO.IO<ReadonlyArray<Element>> =>
  () =>
    pipe(Array.from(e.querySelectorAll(q)), Arr.fromArray)
const addEleClasses =
  (cs: NonEmpty.ReadonlyNonEmptyArray<string>) =>
  (e: Element): IO.IO<void> =>
  () =>
    e.classList.add(...cs)
const removeEleClasses =
  (cs: NonEmpty.ReadonlyNonEmptyArray<string>) =>
  (e: Element): IO.IO<void> =>
  () =>
    e.classList.remove(...cs)
const hasEleClass =
  (c: string) =>
  (e: Element): IO.IO<boolean> =>
  () =>
    e.classList.contains(c)
const chainIOOpt = OptT.chain(IO.Monad)
const render =
  (element: React.ReactElement) =>
  (container: Element | DocumentFragment): IO.IO<void> =>
  () =>
    createRoot(container).render(element)

const MainDate = styled.div`
  font-size: 16px;
`

const CalendarDayIcon = styled(FaCalendarDay)`
  align-self: center;
`

const FcContainer = styled.div`
  .fc-toolbar {
    margin: 0;
    padding: 10px;
    border: 1px solid #dee2e6;
    box-shadow: 0px 3px 10px -4px ${({ theme }) => theme.colours.black.alpha(0.25).toString()};
    z-index: 4;
    align-items: stretch;
    margin-bottom: 0 !important;
    position: sticky;
    top: 0;
    background: white;

    &:before {
      content: "";
      position: absolute;
      height: 22px;
      top: -22px;
      left: -2px;
      right: -2px;
      background: #eeeef8;
    }

    @media screen and (min-width: 576px) {
      padding: 20px;
    }
  }

  .fc-scrollgrid-section-sticky {
    position: relative;
    top: -1px;

    & > td {
      top: 49px !important;

      @media screen and (min-width: 576px) {
        top: 73.5px !important;
      }
    }
  }

  .btn {
    display: flex;
    align-self: stretch;
  }

  .fc-toolbar h2 {
    line-height: 1;
  }

  .fc-toolbar-chunk {
    display: flex;
    align-items: center;
  }

  .fc-direction-ltr .fc-toolbar > .fc-toolbar-chunk > * {
    margin-left: 0;
  }

  .fc-toolbar .fc-today-button {
    padding: 0.375rem 0.5rem;
  }

  .fc-toolbar .fc-toolbar-title {
    font-size: 1em;
  }

  & .fc-toolbar-chunk:last-of-type {
    order: 1;

    & .btn-group button {
      background: none;
      color: ${({ theme }) => theme.colours.black.toString()};
      border: 0;
      box-shadow: none;

      &.active,
      &:active {
        background: ${({ theme }) =>
          theme.colours.grey["100"].toString()} !important;
        color: ${({ theme }) => theme.colours.black.toString()};
        font-weight: bold;
      }
    }

    @media (max-width: 575px) {
      display: flex;
      align-items: center;
      align-self: end;

      &::before {
        ${cssContentIcon(<FaEllipsisV />)}
        font-family: "Font Awesome 5 Free";
        font-weight: 600;
        font-size: 16px;
      }

      & .btn-group {
        visibility: hidden;
        position: absolute;
        z-index: 1;
        right: 6px;
        top: 14px;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 2px 0;
        background-color: #fff;

        & > button {
          margin: 0;
        }
      }

      &[data-show]::after {
        position: absolute;
        opacity: 0.5;
        content: "";
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
      }

      &[data-show] .btn-group {
        visibility: visible;
      }
    }
  }

  .fc-timeGridDay-view {
    & .fc-timegrid-col.fc-day-today {
      background-color: inherit;
    }
  }

  .fc-timeGridWeek-view,
  .fc-dayGridMonth-view {
    & .fc-timegrid-col.fc-day-today,
    & .fc-daygrid-day.fc-day-today {
      background-color: ${({ theme }) =>
        theme.colours.primary.alpha(0.15).toString()};
    }
  }

  .fc-timegrid-now-indicator-line {
    border-color: ${({ theme }) => theme.colours.primary.toString()};
  }
  .fc-timegrid-now-indicator-arrow {
    border-left-color: ${({ theme }) => theme.colours.primary.toString()};
    border-right-color: ${({ theme }) => theme.colours.primary.toString()};
  }

  .fc-timegrid-event {
    &.fp-fc-event,
    &.fp-fc-event-muted {
      border-radius: 3px;
    }

    &.fp-fc-event {
      background: ${({ theme }) => theme.colours.white.toString()};
      border: 0;
      border-top: 4px solid ${({ theme }) => theme.colours.primary.toString()};
      box-shadow:
        0 0 1px 1px
          ${({ theme }) => theme.colours.grey[600].alpha(0.25).toString()},
        0 1px 1px 1px
          ${({ theme }) => theme.colours.grey[600].alpha(0.25).toString()};

      .fc-event-main {
        color: ${({ theme }) => theme.colours.bodyColour.toString()};
      }
    }

    &.fp-fc-event-muted {
      background: transparent;
      border: 0;
      border-left: 4px solid
        ${({ theme }) => theme.colours.grey[300].alpha(0.6).toString()};

      .fc-event-main {
        color: ${({ theme }) => theme.colours.textMuted.toString()};
      }

      ::before {
        content: "\u202f";
        position: absolute;
        border-left: 4px solid
          ${({ theme }) => theme.colours.grey[300].toString()};
        margin-left: -4px;
        left: 0;
      }
    }
  }

  @media (max-width: 575px) {
    .fc-dayGridMonth-view {
      & .fc-daygrid-event-dot,
      & .fc-event-time {
        display: none;
      }
    }
  }

  .fc-col-header .fc-timegrid-axis,
  .fc-col-header-cell {
    background: #f5f5fb;
    border: 0;

    @media (max-width: 575px) {
      font-size: 12px;
      line-height: 1.25;
    }

    @media screen and (min-width: 576px) {
      padding: 10px 0;
    }
  }

  .fc-timeGridWeek-view .fc-col-header-cell.fc-day-today {
    background: ${({ theme }) =>
      theme.colours.primary.alpha(0.15).mix(color("#f5f5fb"), 0.1).toString()};
    color: ${({ theme }) => theme.colours.primary.darken(0.1).toString()};
  }

  .fc-timegrid-slot {
    font-variant-numeric: tabular-nums;

    @media (max-width: 575px) {
      font-size: 10px;
    }
  }

  .fc-prev-button,
  .fc-next-button {
    display: flex;
    align-items: center;
  }

  /* I sure hope this doesn't break anything */
  .fc-timegrid-now-indicator-container {
    overflow: visible;
  }
  ${pipe(
    NonEmpty.range(1, 7),
    NonEmpty.map((n) => [String(n + 1), [n - 1, 7 - n] as const] as const),
    NonEmpty.map(
      Pair.mapSnd(Arr.map((v) => `calc(${v * -100}% - ${v * 1.5}px)`)),
    ),
    NonEmpty.foldMap(serializedStyles)(
      ([n, [l, r]]) => css`
        .fc-timegrid-col:nth-of-type(${n}) .fc-timegrid-now-indicator-line {
          left: ${l};
          right: ${r};
        }
      `,
    ),
  )}
`

export type CalendarProps = {
  className?: string
  "data-help-mode"?: string
} & Omit<
  FullCalendarProps,
  "initialView" | "header" | "themeSystem" | "plugins" | "dateClick"
>

const scrollToTime =
  (tod: LocalTimeOfDay) =>
  (calendar: FullCalendar): IO.IO<void> =>
  () =>
    calendar.getApi().scrollToTime(tod.toString())

const scrollToNow = (calendar: FullCalendar) =>
  pipe(
    currentTimeZone,
    IO.chain(currentLocalTimeOfDay),
    IO.map(
      flow(
        Ord.clamp(localTimeOfDay)(
          mkLocalTimeOfDayWrapped(2, 0, 0),
          mkLocalTimeOfDayWrapped(23, 59, 59),
        ),
        addLocalTimeOfDayDuration(mkLocalTimeOfDayDuration(-2, 0, 0)),
        (tod) =>
          mkLocalTimeOfDayWrapped(
            Math.round(
              mkLocalTimeOfDayWrapped(0, 0, 0)
                .until(tod)
                .total({ unit: "hours" }),
            ),
            0,
            0,
          ),
        scrollToTime,
      ),
    ),
    IO.ap(IO.of(calendar)),
    IO.chain((a) => a),
  )

export const Calendar: FC<CalendarProps> = ({
  className,
  "data-help-mode": dataHelpMode,
  ...props
}) => {
  const ref = useCallback(
    (container: HTMLElement | null) =>
      pipe(
        container,
        Opt.fromNullable,
        IO.of,
        chainIOOpt(
          flow(
            querySelectorAll(".fc-prev-button, .fc-next-button"),
            IO.chain(
              Arr.traverse(IO.Monad)((ele) =>
                pipe(
                  [
                    addEleClasses(["btn-link"]),
                    removeEleClasses(["btn-primary"]),
                    (ele: Element) =>
                      pipe(
                        ele,
                        hasEleClass("fc-prev-button"),
                        IO.map(
                          Bool.fold(
                            () => <FaChevronRight />,
                            () => <FaChevronLeft />,
                          ),
                        ),
                        IO.chain((rele) => render(rele)(ele)),
                      ),
                  ],
                  Arr.map((f) => f(ele)),
                  Arr.sequence(IO.Monad),
                ),
              ),
            ),
            IO.map(Opt.some),
          ),
        ),
        IO.map(Func.constVoid),
        IO.chain(() =>
          pipe(
            container,
            Opt.fromNullable,
            IO.of,
            chainIOOpt(
              flow(
                querySelector(".fc-today-button"),
                IO.chain(
                  Opt.fold<Element, IO.IO<void>>(
                    () => IO.of(null),
                    (ele) => render(<CalendarDayIcon />)(ele),
                  ),
                ),
                IO.map(Opt.some),
              ),
            ),
          ),
        ),
        IO.map(Func.constVoid),
        IO.chain(() =>
          pipe(
            container,
            Opt.fromNullable,
            IO.of,
            chainIOOpt(
              flow(
                querySelector(".fc-toolbar-chunk:last-of-type"),
                IO.chain(
                  Opt.fold<Element, IO.IO<void>>(
                    () => IO.of(null),
                    (ele) => () => {
                      // @TODO does this work?
                      ele.addEventListener("click", () => {
                        ele.toggleAttribute("data-show")
                      })
                    },
                  ),
                ),
                IO.map(Opt.some),
              ),
            ),
          ),
        ),
        IO.map(Func.constVoid),
      )(),
    [],
  )
  const intl = useIntl()
  const theme = useTheme()

  const [calendarRef, setCalendarRef] = useState<FullCalendar | null>(null)
  const calendarCallbackRef = useCallback(
    (calendar: FullCalendar) =>
      pipe(
        // That's actually the correct type, but using it in the argument apparently destroys the type of `ref` further down
        calendar as FullCalendar | undefined,
        Opt.fromNullable,
        Opt.fold(
          () => IO.of(undefined),
          (cal) =>
            pipe(
              [scrollToNow, (a: FullCalendar) => () => setCalendarRef(a)],
              Arr.map((f) => f(cal)),
              Arr.sequence(IO.Monad),
              IO.map(Func.constVoid),
            ),
        ),
        runIO,
        () => calendar,
      ),
    [],
  )

  const isMobile = useMemo(() => window.innerWidth < 576, [])

  console.log(props)

  return (
    <FcContainer {...{ className }} data-help-mode={dataHelpMode} ref={ref}>
      <FullCalendar
        ref={calendarCallbackRef}
        locales={useMemo(() => [enGbLocale], [])}
        locale={intl.locale.toLowerCase()}
        initialView={isMobile ? "timeGridDay" : "timeGridWeek"}
        headerToolbar={{
          left: "today",
          center: "prev title next",
          right: "timeGridDay,timeGridWeek,dayGridMonth",
        }}
        themeSystem="bootstrap"
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          bootstrapPlugin,
          interactionPlugin,
        ]}
        longPressDelay={300}
        nowIndicator={true}
        allDaySlot={false}
        slotEventOverlap={false}
        stickyHeaderDates={true}
        eventMinHeight={21}
        dateClick={({ date, jsEvent }) => {
          if (jsEvent.type === "touchend") {
            jsEvent.preventDefault()

            // prevent calendarUnselect by executing this after a timeout
            setTimeout(() => {
              const end = new Date(date)
              end.setMinutes(end.getMinutes() + 30)
              calendarRef?.getApi().select(date, end)
            }, 50)
          }
        }}
        dayHeaderContent={({ dow, date, view }) =>
          pipe(
            (dow || 7) as DayOfWeekIso,
            dayOfWeekAsDayOfWeekIso.reverseGet,
            (id) =>
              view.type === "timeGridWeek" ? (
                <Media queries={theme.media}>
                  {({ sm }) =>
                    sm ? (
                      <>
                        <div>
                          <DayOfWeekName length="short" {...{ id }} />,{" "}
                          {date.getDate()}
                        </div>
                      </>
                    ) : (
                      <>
                        <MainDate>{date.getDate()}</MainDate>
                        <div>
                          <DayOfWeekName length="short" {...{ id }} />
                        </div>
                      </>
                    )
                  }
                </Media>
              ) : (
                <DayOfWeekName length="short" {...{ id }} />
              ),
          )
        }
        height="auto"
        {...props}
      />
    </FcContainer>
  )
}

import * as Bool from "fp-ts/es6/boolean"
import * as Eq from "fp-ts/es6/Eq"
import * as Func from "fp-ts/es6/function"
import { flow } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Str from "fp-ts/es6/string"
import { Iso, Prism } from "monocle-ts"
import { Newtype, prism } from "newtype-ts"
import { unsafeFromSome } from "@fitnesspilot/data-common"

import { Priority, priority } from "./Priority"

export type MuscleId = Newtype<{ readonly MuscleId: unique symbol }, string>
export const stringAsMuscleId = prism<MuscleId>(Func.constTrue)
export const muscleId: Ord.Ord<MuscleId> = Str.Ord as any

export enum MuscleId_ {
  upperTrapezium = "muscle-upperTrapezium",
  middleTrapezium = "muscle-middleTrapezium",
  lowerTrapezium = "muscle-lowerTrapezium",
  latissimus = "muscle-latissimus",
  backExtensor = "muscle-backExtensor",
  upperChest = "muscle-upperChest",
  middleChest = "muscle-middleChest",
  lowerChest = "muscle-lowerChest",
  frontShoulder = "muscle-frontShoulder",
  middleShoulder = "muscle-middleShoulder",
  backShoulder = "muscle-backShoulder",
  diagonalAbdominal = "muscle-diagonalAbdominal",
  straightAbdominal = "muscle-straightAbdominal",
  biceps = "muscle-biceps",
  triceps = "muscle-triceps",
  forearm = "muscle-forearm",
  quadriceps = "muscle-quadriceps",
  adductors = "muscle-adductors",
  abductors = "muscle-abductors",
  hamstring = "muscle-hamstring",
  calves = "muscle-calves",
  glutes = "muscle-glutes",
  cardiovascularSystem = "muscle-cardiovascularSystem",
}

export const muscleId_: Ord.Ord<MuscleId_> = Str.Ord

export const muscleId_FromString = new Prism<string, MuscleId_>(
  (m) => Opt.some(m as MuscleId_),
  Func.identity,
)

export const muscleId_FromMuscleId = new Iso<MuscleId, MuscleId_>(
  flow(
    stringAsMuscleId.reverseGet,
    muscleId_FromString.getOption,
    unsafeFromSome,
  ),
  flow(
    muscleId_FromString.reverseGet,
    stringAsMuscleId.getOption,
    unsafeFromSome,
  ),
)

export type MuscleWithMetadata = {
  id: MuscleId
  enabled: boolean
  priority: Priority
}
export const muscleWithMetadata: Ord.Ord<MuscleWithMetadata> = {
  equals: Eq.struct({
    id: muscleId,
    enabled: Bool.Eq,
    priority,
  }).equals,
  compare: (a, b) => muscleId.compare(a.id, b.id),
}

import * as Eq from "fp-ts/es6/Eq"
import * as Ord from "fp-ts/es6/Ord"
import * as Str from "fp-ts/es6/string"
import { Lens } from "monocle-ts"
import { Time, time } from "time-ts/es6"

import { liftGetter2, liftGetterEq, prismToGetter } from "./type"
import { stringAsUrl } from "./url"

export type ImageObject = {
  contentUrl: URL
  dateCreated: Time
  description: string
}
export const contentUrl = Lens.fromProp<ImageObject>()("contentUrl")
export const dateCreated = Lens.fromProp<ImageObject>()("dateCreated")
export const description = Lens.fromProp<ImageObject>()("description")

export const imageObject: Ord.Ord<ImageObject> = {
  ...Eq.struct<ImageObject>({
    contentUrl: liftGetterEq(prismToGetter(stringAsUrl))(Str.Eq),
    dateCreated: time,
    description: Str.Eq,
  }),
  compare: liftGetter2(dateCreated.asGetter())(Ord.reverse(time).compare),
}

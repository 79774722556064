import { ReactNode, useContext } from "react"
import { FormattedMessage } from "react-intl"
import styled from "@emotion/styled"

import { flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import { PositiveInteger } from "newtype-ts/es6/PositiveInteger"
import {
  Dimension,
  numberAsValidNumber,
  unitSymbols,
  unsafeFromSome,
  validNumberAsPositiveInteger,
  valueInUnit,
} from "@fitnesspilot/data-common"

import { NumberInputStepper as NumberInputStepper_ } from "@fitnesspilot/components-common/dist/atoms/input/NumberInput"
import { UnitContext } from "@fitnesspilot/components-common/dist/atoms/IntlProvider/IntlProvider"
import {
  ButtonWithIcon,
  IconSide,
} from "@fitnesspilot/components-common/dist/molecules/ButtonWithIcon/ButtonWithIcon"
import {
  _AnyWeightMass,
  AnyWeight,
} from "@fitnesspilot/data-activity/dist/activity/Equipment"

const Title = styled.span`
  /* TODO */
  /* font-family: monospace; */
`

const NumberInputStepper = styled(NumberInputStepper_)`
  width: 75px;
`

export type OptionalWeight =
  | { isOptional: true; value: Opt.Option<AnyWeight> }
  | { isOptional: false; value: AnyWeight }

export type ExerciseSetProps = {
  className?: string
  index: number
  repetitions: PositiveInteger
  weight: OptionalWeight
  children: ReactNode
  onChangeRepetitions: (repetitions: Opt.Option<PositiveInteger>) => IO.IO<void>
  onChangeWeight: (weight: Opt.Option<AnyWeight>) => IO.IO<void>
}

export const ExerciseSet = styled(
  ({
    className,
    index,
    weight,
    repetitions,
    children,
    onChangeRepetitions,
    onChangeWeight,
  }: ExerciseSetProps) => {
    const units = useContext(UnitContext)

    return (
      <div {...{ className }}>
        <Title>
          <FormattedMessage
            defaultMessage="Set {no}:"
            values={{ no: index + 1 }}
          />
        </Title>
        <NumberInputStepper
          min={numberAsValidNumber.getOption(1)}
          max={Opt.none}
          step={pipe(1, numberAsValidNumber.getOption, unsafeFromSome)}
          value={pipe(
            repetitions,
            validNumberAsPositiveInteger.reverseGet,
            Opt.some,
          )}
          onChange={(a) =>
            pipe(
              a,
              Opt.fold(() => Opt.none, validNumberAsPositiveInteger.getOption),
              onChangeRepetitions,
            )
          }
        />
        reps
        {pipe(
          weight.isOptional ? weight.value : Opt.some(weight.value),
          Opt.fold(
            () =>
              weight.isOptional && (
                <ButtonWithIcon
                  icon={null}
                  iconSide={IconSide.left}
                  size="sm"
                  color="none"
                  onClick={pipe(
                    1,
                    numberAsValidNumber.getOption,
                    unsafeFromSome,
                    valueInUnit(Dimension.mass, units[Dimension.mass]).get,
                    _AnyWeightMass.reverseGet,
                    Opt.some,
                    onChangeWeight,
                  )}
                >
                  <FormattedMessage defaultMessage="Add additional weight" />
                </ButtonWithIcon>
              ),
            (weight) => (
              <>
                <NumberInputStepper
                  min={numberAsValidNumber.getOption(1)}
                  max={Opt.none}
                  step={pipe(
                    0.5,
                    numberAsValidNumber.getOption,
                    unsafeFromSome,
                  )}
                  value={Opt.some(weight.mass.value)}
                  onChange={(a) =>
                    pipe(
                      a,
                      Opt.fold(
                        () => Opt.none,
                        flow(
                          valueInUnit(Dimension.mass, units[Dimension.mass])
                            .get,
                          _AnyWeightMass.reverseGet,
                          Opt.some,
                        ),
                      ),
                      onChangeWeight,
                    )
                  }
                />{" "}
                {unitSymbols[Dimension.mass][weight.mass.unit]}
              </>
            ),
          ),
        )}
        {children}
      </div>
    )
  },
)`
  display: flex;
  font-variant-numeric: tabular-nums;
  & > * {
    margin: 0 4px;
  }
`

import { pipe } from "fp-ts/es6/function"

import * as actions from "./actions"
import { Action } from "./actions"
import * as selectors from "./selectors"
import { initialState, State } from "./state"

import { createReducer } from "typesafe-actions"

export default createReducer<State, Action>(initialState).handleAction(
  actions.setToken,
  (state, action) => pipe(state, selectors.token.set(action.payload)),
)

import { FC, forwardRef, SVGAttributes } from "react"

import { OmitFromKnownKeys } from "@fitnesspilot/data-common"

import * as BodyAtom from "../../atoms/Body/Body"

export type BodyProps = BodyAtom.BodyProps &
  OmitFromKnownKeys<SVGAttributes<SVGSVGElement>, "viewBox">

export const Body = forwardRef<SVGSVGElement, BodyProps>(
  ({ alt, sex, dorsoventralSide, children, ...props }, ref) => (
    <svg
      viewBox="0 0 187 400"
      stroke="currentColor"
      fill="currentColor"
      {...{ ref }}
      {...props}
    >
      <BodyAtom.Body {...{ alt, sex, dorsoventralSide }}>
        {children}
      </BodyAtom.Body>
    </svg>
  ),
)

import { FC, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { FaArrowRight } from "react-icons/fa"
import { FormattedMessage, useIntl } from "react-intl"
import { FormGroup } from "reactstrap"
import styled from "@emotion/styled"

import { pipe } from "fp-ts/es6/function"
import * as Func from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { Prism } from "monocle-ts"
import { stringAsEmailAddress } from "@fitnesspilot/data-common"

import { isAPIError } from "@fitnesspilot/api"
import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import {
  ButtonWithIcon,
  IconSide,
} from "@fitnesspilot/components-common/dist/molecules/ButtonWithIcon/ButtonWithIcon"
import {
  ControllerPrism,
  formGroupRender,
  textRender,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"
import { UserRegistration } from "@fitnesspilot/data-user/dist/UserRegistration"

const ButtonRow = styled(FormGroup)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;

  .c-buttonWithIcon {
    font-size: 1em;
  }
`

export type FormData = UserRegistration & {
  confirmPassword: string
}

const Error = styled.div`
  color: ${({ theme }) => theme.colours.danger.toString()};
`

type SignupErrorProps = {
  code: string | number | undefined
}

const SignupError = ({ code }: SignupErrorProps) => {
  switch (code) {
    case "USRInviteCodeInvalid":
      return (
        <FormattedMessage defaultMessage="The given invite code is invalid." />
      )
    case "USRReferralCodeInvalid":
      return (
        <FormattedMessage defaultMessage="The given referral code is invalid." />
      )
    case "USRAlreadySignedUp":
      return (
        <FormattedMessage defaultMessage="You already signed up for a full account." />
      )
    case "USRAccessDenied":
      return (
        <FormattedMessage defaultMessage="Access to Fitnesspilot not yet granted." />
      )
    case "USRDuplicateUserId":
      return (
        <FormattedMessage defaultMessage="An account with the given username already exists." />
      )
    case "USRDuplicateEmail":
      return (
        <FormattedMessage defaultMessage="An account with the given email address already exists." />
      )
    default:
      return <FormattedMessage defaultMessage="Signup failed" />
  }
}

export type SignupFormProps = {
  id: string
  className?: string
  errors: ReadonlyArray<Error>
  onSubmit: (v: FormData) => void
  defaultValues: Partial<FormData>
}

export const SignupForm: FC<SignupFormProps> = ({
  id,
  className,
  defaultValues,
  errors,
  onSubmit,
}) => {
  const intl = useIntl()
  const { handleSubmit, ...form } = useForm<FormData>({
    defaultValues,
  })

  const signupError = useMemo(
    () =>
      pipe(
        errors,
        Arr.filter(isAPIError),
        Arr.last,
        Opt.map(({ errorCode: code }) => (
          <Error key={code}>
            <SignupError {...{ code }} />
          </Error>
        )),
        Opt.toNullable,
      ),
    [errors],
  )

  return (
    <FormProvider {...{ handleSubmit }} {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Fieldset {...{ className }}>
          <FormGroup tag="header">
            <h1 className="h4">
              <FormattedMessage defaultMessage="Sign up" />
            </h1>
          </FormGroup>

          <ControllerPrism<FormData, "email">
            render={formGroupRender(textRender)({
              id: `${id}-email`,
              autoComplete: "email",
              type: "email",
              required: true,
              label: <FormattedMessage defaultMessage="Email address" />,
              fullWidth: true,
            })}
            name="email"
            prism={stringAsEmailAddress}
            prismError={intl.formatMessage({
              defaultMessage: "is invalid",
            })}
            rules={{
              required: true,
            }}
          />

          <ControllerPrism<FormData, "password">
            render={formGroupRender(textRender)({
              id: `${id}-password`,
              autoComplete: "new-password",
              type: "password",
              required: true,
              label: <FormattedMessage defaultMessage="Password" />,
              fullWidth: true,
            })}
            name="password"
            prism={new Prism(Opt.some, Func.identity)}
            prismError={intl.formatMessage({
              defaultMessage: "is invalid",
            })}
            rules={{
              required: true,
              min: 8,
            }}
          />

          <ControllerPrism<FormData, "confirmPassword">
            render={formGroupRender(textRender)({
              id: `${id}-confirmPassword`,
              autoComplete: "new-password",
              type: "password",
              required: true,
              label: <FormattedMessage defaultMessage="Confirm password" />,
              fullWidth: true,
            })}
            name="confirmPassword"
            prism={new Prism(Opt.some, Func.identity)}
            prismError={intl.formatMessage({
              defaultMessage: "is invalid",
            })}
            rules={{
              required: true,
              validate: (v) =>
                v === form.getValues().password ||
                intl.formatMessage({
                  defaultMessage: "Passwords do not match",
                }),
            }}
          />

          <ButtonRow>
            <ButtonWithIcon
              icon={<FaArrowRight />}
              iconSide={IconSide.right}
              type="submit"
              color="success"
            >
              <FormattedMessage defaultMessage="Sign up" />
            </ButtonWithIcon>
          </ButtonRow>

          {signupError}
        </Fieldset>
      </form>
    </FormProvider>
  )
}

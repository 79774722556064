import * as Eq from "fp-ts/es6/Eq"
import * as Str from "fp-ts/es6/string"
import { Lens } from "monocle-ts"

export type UserPassword = {
  password: string
}
export const userLogin = Eq.struct<UserPassword>({
  password: Str.Eq,
})
export const _password = Lens.fromProp<UserPassword>()("password")

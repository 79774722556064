import { forwardRef, HTMLAttributes } from "react"
import styled from "@emotion/styled"

const WithBottomPadding = styled.div`
  padding-bottom: 20px;
`

export const VirtualListItem = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ children, ...props }, ref) => (
  <div {...props} {...{ ref }}>
    <WithBottomPadding>{children}</WithBottomPadding>
  </div>
))

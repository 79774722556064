import { FC, memo, ReactNode, useCallback, useState } from "react"
import { FaBalanceScale, FaComment, FaShieldAlt } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import {
  AppWrapper,
  AppWrapperProps,
} from "@fitnesspilot/components-common/dist/atoms/Wrapper/Wrapper"
import { MainFooter } from "@fitnesspilot/components-common/dist/molecules/MainFooter/MainFooter"
import { Page } from "@fitnesspilot/components-common/dist/molecules/Page/Page"
import { ErrorContainer } from "@fitnesspilot/components-common/dist/organisms/Error/Error"
import { LoadingBarContainer } from "@fitnesspilot/components-common/dist/organisms/LoadingBar/LoadingBar"
import { LoadingTemplateHeaderContainer } from "@fitnesspilot/components-common/dist/organisms/LoadingTemplateHeader/LoadingTemplateHeader"
import { HelpButtonContainer } from "@fitnesspilot/components-help/dist/organisms/HelpButton/HelpButton"
import { UserNavContainer } from "@fitnesspilot/components-user/dist/organisms/UserNav/UserNav"

import { MainNavContainer } from "../../MainNav"

const StyledLoadingTemplateHeaderContainer = styled(
  LoadingTemplateHeaderContainer,
)`
  position: relative;
  z-index: 6;
`

const StyledLoadingBarContainer = styled(LoadingBarContainer)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
`

const StyledPage = styled(Page)`
  display: flex;
  flex-direction: column;
  max-height: 100%;
`

const StyledMainNav = styled(MainNavContainer)<{ showMobileNav: boolean }>`
  position: absolute;
  z-index: 5;
  width: 360px;
  max-width: 100vw;
  height: calc(100vh - var(--template-header--height) - 10px);
  transform: translateX(-100%);
  transition: transform 0.3s;

  ${({ showMobileNav }) =>
    showMobileNav &&
    css`
      transform: translateX(0);
    `}

  @media (${({ theme }) => theme.breakpoints.lg}) {
    position: relative;
    transform: translateX(0);
    transition: 0;
    height: calc(100vh - var(--template-header--height) - 10px - 100px);
  }
`

const RowMain = styled(Row)`
  flex-wrap: nowrap;
`

const GrowingCol = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--template-header--height) - 10px);
  flex-grow: 1;
  background: #eeeef8;
  overflow-y: auto;

  @media screen and (min-width: 576px) {
    && {
      padding: 15px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lg}) {
    height: calc(100vh - var(--template-header--height) - 10px - 100px);
  }
`

const StyledInnerPage = styled(Page)`
  flex-grow: 1;
`

const StyledErrorContainer = styled(ErrorContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media not (${({ theme }) => theme.breakpoints.sm}) {
    position: fixed;
    top: 0;
    left: 58px;
    right: 10px;
    z-index: 1;
    min-height: var(--template-header--height);
    padding-top: calc((var(--template-header--height) - 1px * 2 - 35.5px) / 2);
    padding-bottom: calc(
      (var(--template-header--height) - 1px * 2 - 35.5px) / 2
    );
  }
`

export type MainTemplateProps = {
  noLogin?: boolean
  header: ReactNode
  children?: ReactNode
} & AppWrapperProps

const MainContent = styled.div`
  /* TODO revert to StickyFooter component selector later */
  > footer {
    bottom: -15px;
  }
`

const SideBySide = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const MainTemplate_: FC<MainTemplateProps> = ({
  noLogin,
  header,
  children,
  ...props
}) => {
  const [showMobileNav, setShowMobileNav] = useState(false)

  const onToggleNav = useCallback(
    () => setShowMobileNav(!showMobileNav),
    [showMobileNav],
  )

  return (
    <AppWrapper {...props}>
      <StyledLoadingBarContainer />

      <StyledPage>
        <StyledLoadingTemplateHeaderContainer
          showNavToggle={true}
          {...{ onToggleNav }}
        >
          <SideBySide>
            <StyledErrorContainer />
            {!noLogin && <UserNavContainer />}
            <HelpButtonContainer data-help-mode="launchHelp" />
          </SideBySide>
        </StyledLoadingTemplateHeaderContainer>

        <RowMain noGutters>
          {!noLogin && (
            <Col md={{ size: 0 }}>
              <StyledMainNav {...{ showMobileNav }} />
            </Col>
          )}

          <GrowingCol>
            <StyledInnerPage>
              {header}

              <MainContent>{children}</MainContent>
            </StyledInnerPage>

            <MainFooter>
              <div>
                <FormattedMessage
                  defaultMessage="© {year} <strong>Fitnesspilot</strong>"
                  values={{ year: new Date().getFullYear() }}
                />
              </div>

              <div>
                <Button tag={Link} to={"/legal/notice"} color="inline">
                  <FaBalanceScale />{" "}
                  <FormattedMessage defaultMessage="Legal Notice" />
                </Button>
                <Button tag={Link} to={"/legal/privacy"} color="inline">
                  <FaShieldAlt />{" "}
                  <FormattedMessage defaultMessage="Privacy Policy" />
                </Button>
                <Button
                  tag="a"
                  href={`https://docs.google.com/forms/d/e/1FAIpQLSfLh9kR-snAjHvTHsXBxvBpT7ul8Ijk2G7dxcr3EXkf_itu2Q/viewform?usp=pp_url&entry.658551058=${location.href}`}
                  target="_blank"
                  color="inline"
                >
                  <FaComment /> <FormattedMessage defaultMessage="Feedback" />
                </Button>
              </div>
            </MainFooter>
          </GrowingCol>
        </RowMain>
      </StyledPage>
    </AppWrapper>
  )
}

export const MainTemplate = memo(MainTemplate_)

import { CoachTask } from "../CoachTask"

export const stateKey = "coachTask"

export type State = {
  coachTasks: ReadonlyArray<CoachTask>
  eventConfirmationIsOpen: boolean
}
export const initialState: State = {
  coachTasks: [],
  eventConfirmationIsOpen: false,
}

export type ParentState = { [k in typeof stateKey]: State }

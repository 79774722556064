import * as Eq from "fp-ts/es6/Eq"
import * as Str from "fp-ts/es6/string"

export type EventSource = {
  name: string
  id: string
}
export const eventSource = Eq.struct({
  name: Str.Eq,
  id: Str.Eq,
})

import { FC } from "react"
import { IntlShape, useIntl } from "react-intl"

import { capitalise } from "@fitnesspilot/data-common"

import { formatDynamicMessage } from "@fitnesspilot/components-common/dist/atoms/IntlProvider/IntlProvider"
import {
  ActivityId,
  stringAsActivityId,
} from "@fitnesspilot/data-activity/dist/activity/ActivityId"

export type ActivityNameProps = {
  id: ActivityId
  def: string
}

export const activityName =
  (intl: IntlShape) =>
  ({ id, def }: ActivityNameProps) =>
    formatDynamicMessage(intl)({
      id: `ActivityName.${stringAsActivityId.reverseGet(id)}`,
      defaultMessage: capitalise(def),
    })

export const ActivityName: FC<ActivityNameProps> = (props) => (
  <>{activityName(useIntl())(props)}</>
)

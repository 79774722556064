import { ReactNode, useEffect } from "react"
import {
  FormProvider,
  SubmitHandler,
  UnpackNestedValue,
  useForm,
} from "react-hook-form"
import { FormattedMessage } from "react-intl"

import * as Bool from "fp-ts/boolean"
import * as Eq from "fp-ts/Eq"
import * as Num from "fp-ts/number"
import { DeepPartial } from "@fitnesspilot/data-common"

import { CancelButton } from "@fitnesspilot/components-common/dist/molecules/CancelButton/CancelButton"
import { SaveButton } from "@fitnesspilot/components-common/dist/molecules/SaveButton/SaveButton"
import { useFormChangeEffect } from "@fitnesspilot/components-common/dist/react"

import { MuscleGroupPreferencesFieldsets } from "../../molecules/MuscleGroupPreferencesFieldsets/MuscleGroupPreferencesFieldsets"
import {
  FormData as MusclePreferencesFormData,
  formData as musclePreferencesFormData,
} from "../../molecules/MusclePreferencesFieldsets/MusclePreferencesFieldsets"
import {
  FormData,
  formData,
  SportPreferencesFieldsets,
} from "../../molecules/SportPreferencesFieldsets/SportPreferencesFieldsets"

export { FormData } from "../../molecules/SportPreferencesFieldsets/SportPreferencesFieldsets"

export type FormDataWithMuscles = FormData & MusclePreferencesFormData
export const formDataWithMuscles = Eq.fromEquals<FormDataWithMuscles>(
  (a, b) => formData.equals(a, b) && musclePreferencesFormData.equals(a, b),
)

export type BaseSportPreferencesFormProps<a extends FormData> = {
  id: string
  className?: string
  children?: ReactNode
  defaultValues: DeepPartial<a>
  formData: Eq.Eq<a>
  onSubmit: SubmitHandler<a>
}

const BaseSportPreferencesForm = <a extends FormData>({
  id,
  className,
  children,
  defaultValues,
  formData,
  onSubmit,
}: BaseSportPreferencesFormProps<a>) => {
  const { handleSubmit, ...form } = useForm<a, a>({
    defaultValues,
  })

  useEffect(() => {
    if (!form.formState.isDirty) {
      form.reset(defaultValues)
    }
  }, [defaultValues])

  const submit = handleSubmit(onSubmit)
  useFormChangeEffect(form, formData, submit)

  return (
    <FormProvider {...{ handleSubmit }} {...form}>
      <form onSubmit={submit} {...{ className }}>
        <SportPreferencesFieldsets id={`${id}-fieldsets`} />

        {children}
      </form>
    </FormProvider>
  )
}

export type SportPreferencesFormProps = Omit<
  BaseSportPreferencesFormProps<FormData>,
  "formData"
>

export const SportPreferencesForm = (props: SportPreferencesFormProps) => (
  <BaseSportPreferencesForm<FormData> {...{ formData }} {...props} />
)

export type SportPreferencesWithMusclesFormProps = Omit<
  BaseSportPreferencesFormProps<FormDataWithMuscles>,
  "formData"
>

export const SportPreferencesWithMusclesForm = ({
  id,
  ...props
}: SportPreferencesWithMusclesFormProps) => (
  <BaseSportPreferencesForm<FormDataWithMuscles>
    {...{ id }}
    formData={formDataWithMuscles}
    {...props}
  >
    <MuscleGroupPreferencesFieldsets id={`${id}-muscleGroupPreferences`} />
  </BaseSportPreferencesForm>
)

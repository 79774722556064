import { FaEllipsisH } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import { Button } from "reactstrap"

import { pipe } from "fp-ts/es6/function"

import { _ActivityInstanceExercise } from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"
import { _alignment } from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceExercise"
import {
  _eventWithId,
  EventWithId,
  stringAsEventId,
} from "@fitnesspilot/data-event/dist/calendar/Event"

import { EventSummary } from "../../molecules/EventSummary/EventSummary"
import { EventTimeline as EventTimeline_ } from "../../molecules/EventTimeline/EventTimeline"

export type EventTimelineProps = {
  className?: string
  events: Array<EventWithId>
}

export const EventTimeline = ({ className, events }: EventTimelineProps) => (
  <EventTimeline_
    {...{ className, events }}
    eventComponent={({ event }) => (
      <EventSummary event={pipe(event, _eventWithId._value.get)}>
        <Button
          tag={Link}
          to={`/calendar/events/${stringAsEventId.reverseGet(event.id)}`}
          color="primary"
          outline
        >
          <FaEllipsisH /> <FormattedMessage defaultMessage="See details" />
        </Button>
      </EventSummary>
    )}
  />
)

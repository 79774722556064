import { FC } from "react"
import { FormattedMessage } from "react-intl"
import {
  CustomInput,
  CustomInputProps,
  FormGroup,
  Label,
  LabelProps,
} from "reactstrap"
import styled from "@emotion/styled"

import { OmitFromKnownKeys } from "@fitnesspilot/data-common"

const StyledLabel = styled(({ className, ...props }: LabelProps) => (
  <Label className={`c-styledLabel ${className ?? ""}`} {...props} />
))`
  pointer-events: none;
`

const StyledInput = styled(CustomInput)`
  font-size: 14px;
  width: 7.5em;
  height: 2.3em;
  user-select: none;

  .custom-control-label::before {
    border-radius: 4px;
    width: 7.5em;
    height: 2.3em;
    background-color: ${({ theme }) => theme.colours.white.toString()};
    border-color: ${({ theme }) => theme.colours.grey[200].toString()};
  }

  .custom-control-label::after {
    top: calc(0.25rem + 3px);
    margin-left: 1px;
    border-radius: 2px;
    height: 1.85em;
    width: 3.3em;
    content: "|||";
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    color: ${({ theme }) => theme.colours.grey[500].toString()};
    background-color: ${({ theme }) => theme.colours.grey[200].toString()};
    border-color: ${({ theme }) => theme.colours.grey[200].toString()};
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: ${({ theme }) => theme.colours.white.toString()};
    border-color: ${({ theme }) => theme.colours.grey[200].toString()};
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(3.75em);
    color: ${({ theme }) => theme.colours.primary.darken(0.5).toString()};
    background-color: ${({ theme }) => theme.colours.primary.toString()};
    border-color: ${({ theme }) => theme.colours.primary.toString()};
  }

  .c-styledLabel {
    position: absolute;
    top: 0.7em;

    &:nth-last-of-type(2) {
      left: 2em;
      transform: translateX(-50%);
    }

    &:nth-last-of-type(1) {
      right: 2em;
      transform: translateX(50%);
    }
  }
`

type BaseProps = {
  checked: boolean
  className?: string
  id: string
  name: string
  onChange: (checked: boolean) => void
}

export type SwitchProps = BaseProps &
  OmitFromKnownKeys<CustomInputProps, keyof BaseProps | "type">

export const Switch: FC<SwitchProps> = ({
  id,
  className,
  onChange,
  ...props
}) => (
  <FormGroup {...{ className }} check inline>
    <StyledInput
      type="switch"
      {...props}
      {...{ id }}
      onChange={({ target }: { target: HTMLInputElement }) =>
        onChange(target.checked)
      }
    >
      <StyledLabel>
        <FormattedMessage defaultMessage="ON" />
      </StyledLabel>
      <StyledLabel>
        <FormattedMessage defaultMessage="OFF" />
      </StyledLabel>
    </StyledInput>
  </FormGroup>
)

// @generated by protoc-gen-es v1.3.0 with parameter "target=ts"
// @generated from file uom/v1/mass.proto (package uom.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * @generated from enum uom.v1.UnitMass
 */
export enum UnitMass {
  /**
   * @generated from enum value: UNIT_MASS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * SI
   *
   * @generated from enum value: UNIT_MASS_MICROGRAM = 1;
   */
  MICROGRAM = 1,

  /**
   * @generated from enum value: UNIT_MASS_MILLIGRAM = 2;
   */
  MILLIGRAM = 2,

  /**
   * @generated from enum value: UNIT_MASS_CENTIGRAM = 3;
   */
  CENTIGRAM = 3,

  /**
   * @generated from enum value: UNIT_MASS_GRAM = 4;
   */
  GRAM = 4,

  /**
   * SI base unit
   *
   * @generated from enum value: UNIT_MASS_KILOGRAM = 5;
   */
  KILOGRAM = 5,

  /**
   * @generated from enum value: UNIT_MASS_TON = 6;
   */
  TON = 6,

  /**
   * @generated from enum value: UNIT_MASS_POUND = 7;
   */
  POUND = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(UnitMass)
proto3.util.setEnumType(UnitMass, "uom.v1.UnitMass", [
  { no: 0, name: "UNIT_MASS_UNSPECIFIED" },
  { no: 1, name: "UNIT_MASS_MICROGRAM" },
  { no: 2, name: "UNIT_MASS_MILLIGRAM" },
  { no: 3, name: "UNIT_MASS_CENTIGRAM" },
  { no: 4, name: "UNIT_MASS_GRAM" },
  { no: 5, name: "UNIT_MASS_KILOGRAM" },
  { no: 6, name: "UNIT_MASS_TON" },
  { no: 7, name: "UNIT_MASS_POUND" },
]);

/**
 * @generated from message uom.v1.MassInt32
 */
export class MassInt32 extends Message<MassInt32> {
  /**
   * @generated from field: uom.v1.UnitMass unit = 1;
   */
  unit = UnitMass.UNSPECIFIED;

  /**
   * @generated from field: int32 value = 2;
   */
  value = 0;

  constructor(data?: PartialMessage<MassInt32>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.MassInt32";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unit", kind: "enum", T: proto3.getEnumType(UnitMass) },
    { no: 2, name: "value", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MassInt32 {
    return new MassInt32().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MassInt32 {
    return new MassInt32().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MassInt32 {
    return new MassInt32().fromJsonString(jsonString, options);
  }

  static equals(a: MassInt32 | PlainMessage<MassInt32> | undefined, b: MassInt32 | PlainMessage<MassInt32> | undefined): boolean {
    return proto3.util.equals(MassInt32, a, b);
  }
}

/**
 * @generated from message uom.v1.MassInt64
 */
export class MassInt64 extends Message<MassInt64> {
  /**
   * @generated from field: uom.v1.UnitMass unit = 1;
   */
  unit = UnitMass.UNSPECIFIED;

  /**
   * @generated from field: int64 value = 2;
   */
  value = protoInt64.zero;

  constructor(data?: PartialMessage<MassInt64>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.MassInt64";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unit", kind: "enum", T: proto3.getEnumType(UnitMass) },
    { no: 2, name: "value", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MassInt64 {
    return new MassInt64().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MassInt64 {
    return new MassInt64().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MassInt64 {
    return new MassInt64().fromJsonString(jsonString, options);
  }

  static equals(a: MassInt64 | PlainMessage<MassInt64> | undefined, b: MassInt64 | PlainMessage<MassInt64> | undefined): boolean {
    return proto3.util.equals(MassInt64, a, b);
  }
}

/**
 * @generated from message uom.v1.MassUint32
 */
export class MassUint32 extends Message<MassUint32> {
  /**
   * @generated from field: uom.v1.UnitMass unit = 1;
   */
  unit = UnitMass.UNSPECIFIED;

  /**
   * @generated from field: uint32 value = 2;
   */
  value = 0;

  constructor(data?: PartialMessage<MassUint32>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.MassUint32";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unit", kind: "enum", T: proto3.getEnumType(UnitMass) },
    { no: 2, name: "value", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MassUint32 {
    return new MassUint32().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MassUint32 {
    return new MassUint32().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MassUint32 {
    return new MassUint32().fromJsonString(jsonString, options);
  }

  static equals(a: MassUint32 | PlainMessage<MassUint32> | undefined, b: MassUint32 | PlainMessage<MassUint32> | undefined): boolean {
    return proto3.util.equals(MassUint32, a, b);
  }
}

/**
 * @generated from message uom.v1.MassUint64
 */
export class MassUint64 extends Message<MassUint64> {
  /**
   * @generated from field: uom.v1.UnitMass unit = 1;
   */
  unit = UnitMass.UNSPECIFIED;

  /**
   * @generated from field: uint64 value = 2;
   */
  value = protoInt64.zero;

  constructor(data?: PartialMessage<MassUint64>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.MassUint64";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unit", kind: "enum", T: proto3.getEnumType(UnitMass) },
    { no: 2, name: "value", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MassUint64 {
    return new MassUint64().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MassUint64 {
    return new MassUint64().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MassUint64 {
    return new MassUint64().fromJsonString(jsonString, options);
  }

  static equals(a: MassUint64 | PlainMessage<MassUint64> | undefined, b: MassUint64 | PlainMessage<MassUint64> | undefined): boolean {
    return proto3.util.equals(MassUint64, a, b);
  }
}

/**
 * @generated from message uom.v1.MassFloat
 */
export class MassFloat extends Message<MassFloat> {
  /**
   * @generated from field: uom.v1.UnitMass unit = 1;
   */
  unit = UnitMass.UNSPECIFIED;

  /**
   * @generated from field: float value = 2;
   */
  value = 0;

  constructor(data?: PartialMessage<MassFloat>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.MassFloat";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unit", kind: "enum", T: proto3.getEnumType(UnitMass) },
    { no: 2, name: "value", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MassFloat {
    return new MassFloat().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MassFloat {
    return new MassFloat().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MassFloat {
    return new MassFloat().fromJsonString(jsonString, options);
  }

  static equals(a: MassFloat | PlainMessage<MassFloat> | undefined, b: MassFloat | PlainMessage<MassFloat> | undefined): boolean {
    return proto3.util.equals(MassFloat, a, b);
  }
}

/**
 * @generated from message uom.v1.MassDouble
 */
export class MassDouble extends Message<MassDouble> {
  /**
   * @generated from field: uom.v1.UnitMass unit = 1;
   */
  unit = UnitMass.UNSPECIFIED;

  /**
   * @generated from field: double value = 2;
   */
  value = 0;

  constructor(data?: PartialMessage<MassDouble>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.MassDouble";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unit", kind: "enum", T: proto3.getEnumType(UnitMass) },
    { no: 2, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MassDouble {
    return new MassDouble().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MassDouble {
    return new MassDouble().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MassDouble {
    return new MassDouble().fromJsonString(jsonString, options);
  }

  static equals(a: MassDouble | PlainMessage<MassDouble> | undefined, b: MassDouble | PlainMessage<MassDouble> | undefined): boolean {
    return proto3.util.equals(MassDouble, a, b);
  }
}

/**
 * specifies values in SI base unit (kilogram)
 *
 * @generated from message uom.v1.CanonicalMassInt32
 */
export class CanonicalMassInt32 extends Message<CanonicalMassInt32> {
  /**
   * @generated from field: int32 value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<CanonicalMassInt32>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.CanonicalMassInt32";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanonicalMassInt32 {
    return new CanonicalMassInt32().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanonicalMassInt32 {
    return new CanonicalMassInt32().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanonicalMassInt32 {
    return new CanonicalMassInt32().fromJsonString(jsonString, options);
  }

  static equals(a: CanonicalMassInt32 | PlainMessage<CanonicalMassInt32> | undefined, b: CanonicalMassInt32 | PlainMessage<CanonicalMassInt32> | undefined): boolean {
    return proto3.util.equals(CanonicalMassInt32, a, b);
  }
}

/**
 * @generated from message uom.v1.CanonicalMassInt64
 */
export class CanonicalMassInt64 extends Message<CanonicalMassInt64> {
  /**
   * @generated from field: int64 value = 1;
   */
  value = protoInt64.zero;

  constructor(data?: PartialMessage<CanonicalMassInt64>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.CanonicalMassInt64";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanonicalMassInt64 {
    return new CanonicalMassInt64().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanonicalMassInt64 {
    return new CanonicalMassInt64().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanonicalMassInt64 {
    return new CanonicalMassInt64().fromJsonString(jsonString, options);
  }

  static equals(a: CanonicalMassInt64 | PlainMessage<CanonicalMassInt64> | undefined, b: CanonicalMassInt64 | PlainMessage<CanonicalMassInt64> | undefined): boolean {
    return proto3.util.equals(CanonicalMassInt64, a, b);
  }
}

/**
 * @generated from message uom.v1.CanonicalMassUint32
 */
export class CanonicalMassUint32 extends Message<CanonicalMassUint32> {
  /**
   * @generated from field: uint32 value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<CanonicalMassUint32>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.CanonicalMassUint32";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanonicalMassUint32 {
    return new CanonicalMassUint32().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanonicalMassUint32 {
    return new CanonicalMassUint32().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanonicalMassUint32 {
    return new CanonicalMassUint32().fromJsonString(jsonString, options);
  }

  static equals(a: CanonicalMassUint32 | PlainMessage<CanonicalMassUint32> | undefined, b: CanonicalMassUint32 | PlainMessage<CanonicalMassUint32> | undefined): boolean {
    return proto3.util.equals(CanonicalMassUint32, a, b);
  }
}

/**
 * @generated from message uom.v1.CanonicalMassUint64
 */
export class CanonicalMassUint64 extends Message<CanonicalMassUint64> {
  /**
   * @generated from field: uint64 value = 1;
   */
  value = protoInt64.zero;

  constructor(data?: PartialMessage<CanonicalMassUint64>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.CanonicalMassUint64";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanonicalMassUint64 {
    return new CanonicalMassUint64().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanonicalMassUint64 {
    return new CanonicalMassUint64().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanonicalMassUint64 {
    return new CanonicalMassUint64().fromJsonString(jsonString, options);
  }

  static equals(a: CanonicalMassUint64 | PlainMessage<CanonicalMassUint64> | undefined, b: CanonicalMassUint64 | PlainMessage<CanonicalMassUint64> | undefined): boolean {
    return proto3.util.equals(CanonicalMassUint64, a, b);
  }
}

/**
 * @generated from message uom.v1.CanonicalMassFloat
 */
export class CanonicalMassFloat extends Message<CanonicalMassFloat> {
  /**
   * @generated from field: float value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<CanonicalMassFloat>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.CanonicalMassFloat";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanonicalMassFloat {
    return new CanonicalMassFloat().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanonicalMassFloat {
    return new CanonicalMassFloat().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanonicalMassFloat {
    return new CanonicalMassFloat().fromJsonString(jsonString, options);
  }

  static equals(a: CanonicalMassFloat | PlainMessage<CanonicalMassFloat> | undefined, b: CanonicalMassFloat | PlainMessage<CanonicalMassFloat> | undefined): boolean {
    return proto3.util.equals(CanonicalMassFloat, a, b);
  }
}

/**
 * @generated from message uom.v1.CanonicalMassDouble
 */
export class CanonicalMassDouble extends Message<CanonicalMassDouble> {
  /**
   * @generated from field: double value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<CanonicalMassDouble>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.CanonicalMassDouble";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanonicalMassDouble {
    return new CanonicalMassDouble().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanonicalMassDouble {
    return new CanonicalMassDouble().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanonicalMassDouble {
    return new CanonicalMassDouble().fromJsonString(jsonString, options);
  }

  static equals(a: CanonicalMassDouble | PlainMessage<CanonicalMassDouble> | undefined, b: CanonicalMassDouble | PlainMessage<CanonicalMassDouble> | undefined): boolean {
    return proto3.util.equals(CanonicalMassDouble, a, b);
  }
}


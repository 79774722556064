import { FC, ReactNode } from "react"
import styled from "@emotion/styled"

import { constNull, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"

import { Card } from "@fitnesspilot/components-common/dist/atoms/Card/Card"
import { ImageWithTextBesideIt } from "@fitnesspilot/components-common/dist/molecules/ImageWithTextBesideIt/ImageWithTextBesideIt"
import { Alignment } from "@fitnesspilot/components-human-body/dist/molecules/Alignment/Alignment"
import * as alignment from "@fitnesspilot/data-human-body/dist/Alignment"

const ActivityCard = styled(Card)`
  @media (hover: hover) {
    &:not(:hover) .c-topRight {
      display: none;
    }
  }
`

const StyledAlignment = styled(Alignment)`
  margin-left: 0.5em;
`

export type ActivityProps = {
  image: ReactNode
  title: ReactNode
  alignment: Opt.Option<alignment.Alignment>
  alignmentDescriptionIsOpen: boolean
  onAlignmentDescriptionIsOpenChange: (v: boolean) => IO.IO<void>
  topRight?: ReactNode
  children?: ReactNode
}

export const Activity: FC<ActivityProps> = ({
  image,
  title,
  alignment,
  alignmentDescriptionIsOpen,
  onAlignmentDescriptionIsOpenChange,
  topRight,
  children,
}) => (
  <ActivityCard fluid={true}>
    <ImageWithTextBesideIt
      title={
        <>
          {title}

          {pipe(
            alignment,
            Opt.fold(constNull, (alignment) => (
              <StyledAlignment
                data-help-mode="alignment"
                value={alignment}
                descriptionIsOpen={alignmentDescriptionIsOpen}
                onDescriptionIsOpenChange={onAlignmentDescriptionIsOpenChange}
              />
            )),
          )}
        </>
      }
      topRight={Opt.some(topRight)}
      {...{ image, children }}
    />
  </ActivityCard>
)

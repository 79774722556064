import * as Eq from "fp-ts/es6/Eq"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Str from "fp-ts/es6/string"
import { Lens } from "monocle-ts"

import { MuscleShare, muscleShare } from "./MuscleShare"

export type ActivityJob = {
  title: string
  instructions: Opt.Option<string>
  muscleShares: ReadonlyArray<MuscleShare>
}
export const activityJob = Eq.struct<ActivityJob>({
  title: Str.Eq,
  instructions: Opt.getEq(Str.Eq),
  muscleShares: Arr.getEq(muscleShare),
})
export const _title = Lens.fromProp<ActivityJob>()("title")
export const _instructions = Lens.fromProp<ActivityJob>()("instructions")
export const _muscleShares = Lens.fromProp<ActivityJob>()("muscleShares")

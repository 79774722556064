/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import { ActivityExerciseFromJSON, ActivityExerciseToJSON, } from "./ActivityExercise";
import { ActivityInstanceFromJSONTyped, ActivityInstanceToJSON, } from "./ActivityInstance";
import { ActivityMuscleInfoFromJSON, ActivityMuscleInfoToJSON, } from "./ActivityMuscleInfo";
import { ActivityTypeFromJSON, ActivityTypeToJSON, } from "./ActivityType";
import { ModelSetFromJSON, ModelSetToJSON, } from "./ModelSet";
/**
 * Check if a given object implements the ActivityInstanceExercise interface.
 */
export function instanceOfActivityInstanceExercise(value) {
    let isInstance = true;
    isInstance = isInstance && "activity" in value;
    isInstance = isInstance && "$type" in value;
    isInstance = isInstance && "alignment" in value;
    isInstance = isInstance && "muscles" in value;
    return isInstance;
}
export function ActivityInstanceExerciseFromJSON(json) {
    return ActivityInstanceExerciseFromJSONTyped(json, false);
}
export function ActivityInstanceExerciseFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        ...ActivityInstanceFromJSONTyped(json, ignoreDiscriminator),
        activity: ActivityExerciseFromJSON(json.activity),
        $type: ActivityTypeFromJSON(json.$type),
        duration: !exists(json, "duration") ? undefined : json.duration,
        distance: !exists(json, "distance") ? undefined : json.distance,
        intensity: !exists(json, "intensity") ? undefined : json.intensity,
        sets: !exists(json, "sets")
            ? undefined
            : json.sets === null
                ? null
                : json.sets.map(ModelSetFromJSON),
        alignment: json.alignment,
        importanceFactor: !exists(json, "importanceFactor")
            ? undefined
            : json.importanceFactor,
        muscles: mapValues(json.muscles, ActivityMuscleInfoFromJSON),
    };
}
export function ActivityInstanceExerciseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...ActivityInstanceToJSON(value, true),
        activity: ActivityExerciseToJSON(value.activity),
        $type: ActivityTypeToJSON(value.$type),
        duration: value.duration,
        distance: value.distance,
        intensity: value.intensity,
        sets: value.sets === undefined
            ? undefined
            : value.sets === null
                ? null
                : value.sets.map(ModelSetToJSON),
        alignment: value.alignment,
        importanceFactor: value.importanceFactor,
        muscles: mapValues(value.muscles, ActivityMuscleInfoToJSON),
    };
}

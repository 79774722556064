import { ReactElement } from "react"
import { FaInfoCircle, FaPlus } from "react-icons/fa"
import { FormattedMessage } from "react-intl"

import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { prismPositiveInteger } from "newtype-ts/es6/PositiveInteger"
import { unsafeFromSome } from "@fitnesspilot/data-common"

import { Info } from "@fitnesspilot/components-common/dist/atoms/Info/Info"
import {
  ButtonWithIcon,
  IconSide,
} from "@fitnesspilot/components-common/dist/molecules/ButtonWithIcon/ButtonWithIcon"
import { DeleteButton } from "@fitnesspilot/components-common/dist/molecules/DeleteButton/DeleteButton"
import * as Data from "@fitnesspilot/data-activity/dist/activity/ExerciseSet"

import { ExerciseSet } from "../../atoms/ExerciseSet/ExerciseSet"

const infoTooltipText = (
  <FormattedMessage
    defaultMessage={`<p>With time the Fitnesspilot learns what you are capable of from previous workouts.</p>
<p>For activities you do the first time we cannot suggest perfect values. Thus you should enter them manually.</p>
<p>Ideally you should enter your limit here. But also do not overexert yourself!</p>`}
    values={{
      p: (chunks) => <p>{chunks}</p>,
    }}
  />
)

export type ExerciseSetsProps = {
  isWeightOptional: boolean
  sets: ReadonlyArray<Data.ExerciseSet>
  onChange: (sets: ReadonlyArray<Data.ExerciseSet>) => IO.IO<void>
}

export const ExerciseSets = ({
  isWeightOptional,
  sets,
  onChange,
}: ExerciseSetsProps) => (
  <div>
    {pipe(
      sets,
      Arr.mapWithIndex((i, { repetitions, equipments }) => (
        <ExerciseSet
          key={i}
          index={i}
          {...{ repetitions }}
          weight={{ isOptional: true, value: Arr.head(equipments) }} // TODO
          onChangeRepetitions={(newReps) =>
            pipe(
              sets,
              Arr.modifyAt(i, (old) => ({
                repetitions: Opt.getOrElse(() =>
                  pipe(1, prismPositiveInteger.getOption, unsafeFromSome),
                )(newReps),
                equipments: old.equipments,
              })),
              Opt.getOrElse(() => sets),
              onChange,
            )
          }
          onChangeWeight={(newWeight) =>
            pipe(
              sets,
              Arr.modifyAt(i, (old) => ({
                repetitions: old.repetitions,
                equipments: pipe(
                  newWeight,
                  Opt.fold(
                    () => [],
                    (w) => [w],
                  ),
                ),
              })),
              Opt.getOrElse(() => sets),
              onChange,
            )
          }
        >
          {sets.length > 1 && (
            <DeleteButton
              onClick={pipe(
                sets,
                (sets) => Arr.unsafeDeleteAt(i, sets),
                onChange,
              )}
            />
          )}
        </ExerciseSet>
      )),
    )}
    {isWeightOptional ? (
      <Info tooltipContent={infoTooltipText}>
        <FormattedMessage defaultMessage="Need help to find the right repetitions?" />
      </Info>
    ) : (
      <Info tooltipContent={infoTooltipText}>
        <FormattedMessage defaultMessage="Need help to find the right weight?" />
      </Info>
    )}
    <ButtonWithIcon
      icon={<FaPlus />}
      iconSide={IconSide.left}
      color="primary"
      outline
      onClick={pipe(
        sets,
        Arr.append(
          pipe(
            sets,
            Arr.last,
            Opt.getOrElse<Data.ExerciseSet>(() => ({
              repetitions: pipe(
                3,
                prismPositiveInteger.getOption,
                unsafeFromSome,
              ),
              equipments: [],
            })),
          ),
        ),
        onChange,
      )}
    >
      <FormattedMessage defaultMessage="Add set" />
    </ButtonWithIcon>
  </div>
)

import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { Getter, Iso } from "monocle-ts"
import { match } from "ts-pattern"
import { unsafeFromSome, unwrap } from "@fitnesspilot/data-common"

import {
  activityInstance,
  activityInstanceReverse,
} from "@fitnesspilot/data-activity/dist/store/proto"
import { timestampAsTime } from "@fitnesspilot/data-common/dist/store/proto"
import * as PROTO from "@fitnesspilot/proto/dist/index"

import { Video, VimeoVideo, YoutubeVideo } from "../video/Video"
import { stringAsVimeoVideoId, stringAsYoutubeVideoId } from "../video/VideoId"
import { VideoSource } from "../video/VideoSource"
import { stringAsWorkoutVideoId, WorkoutVideo } from "../video/WorkoutVideo"

export const videoSource = new Iso<PROTO.VideoSource, VideoSource>(
  (a) =>
    match(a)
      .with(PROTO.VideoSource.YOUTUBE, () => VideoSource.youtube)
      .with(PROTO.VideoSource.VIMEO, () => VideoSource.vimeo)
      // assume Youtube if unspecified
      .with(PROTO.VideoSource.UNSPECIFIED, () => VideoSource.youtube)
      .otherwise(() => VideoSource.youtube),
  (a) =>
    match(a)
      .with(VideoSource.youtube, () => PROTO.VideoSource.YOUTUBE)
      .with(VideoSource.vimeo, () => PROTO.VideoSource.VIMEO)
      .exhaustive(),
)

export const video = new Iso<PROTO.Video, Video>(
  (a) => {
    const source = pipe(a.source, videoSource.get)
    return match(source)
      .with(
        VideoSource.youtube,
        (source): YoutubeVideo => ({
          source,
          id: pipe(a.id, stringAsYoutubeVideoId.getOption, unsafeFromSome),
          title: a.title,
          description: a.description,
          channelId: a.channelId,
          channelTitle: a.channelTitle,
          publishedAt: pipe(
            a.publishedAt,
            Opt.fromNullable,
            Opt.chain(timestampAsTime.getOption),
            unsafeFromSome,
          ),
          thumbnailUrl: pipe(a.thumbnailUrl, Opt.fromNullable),
        }),
      )
      .with(
        VideoSource.vimeo,
        (source): VimeoVideo => ({
          source,
          id: pipe(a.id, stringAsVimeoVideoId.getOption, unsafeFromSome),
          title: a.title,
          description: a.description,
          channelId: a.channelId,
          channelTitle: a.channelTitle,
          publishedAt: pipe(
            a.publishedAt,
            Opt.fromNullable,
            Opt.chain(timestampAsTime.getOption),
            unsafeFromSome,
          ),
          thumbnailUrl: pipe(a.thumbnailUrl, Opt.fromNullable),
        }),
      )
      .exhaustive()
  },
  (a) =>
    new PROTO.Video({
      source: pipe(a.source, videoSource.reverseGet),
      id: unwrap(a.id),
      title: a.title,
      description: a.description,
      channelId: a.channelId,
      channelTitle: a.channelTitle,
      publishedAt: pipe(a.publishedAt, timestampAsTime.reverseGet),
      thumbnailUrl: pipe(a.thumbnailUrl, Opt.toUndefined),
    }),
)

export const workoutVideo = new Getter<
  [PROTO.WorkoutVideo, Array<PROTO.Activity>],
  WorkoutVideo
>(([a, activities]) => ({
  id: pipe(a.id, stringAsWorkoutVideoId.getOption, unsafeFromSome),
  title: pipe(a.title, Opt.fromNullable),
  video: pipe(a.video, Opt.fromNullable, Opt.map(video.get), unsafeFromSome),
  activities: pipe(
    a.activities,
    Arr.filterMap((a) => activityInstance.get([a, activities])),
  ),
}))
export const workoutVideoReverse = new Getter<WorkoutVideo, PROTO.WorkoutVideo>(
  (a) =>
    new PROTO.WorkoutVideo({
      id: pipe(a.id, unwrap),
      title: pipe(a.title, Opt.toUndefined),
      video: pipe(a.video, video.reverseGet),
      activities: pipe(
        a.activities,
        Arr.map(activityInstanceReverse.get),
        Arr.toArray,
      ),
    }),
)

export const workoutVideoResponse = new Getter<
  PROTO.ListVideosResponse,
  ReadonlyArray<WorkoutVideo>
>(({ videos, activities }) =>
  pipe(
    videos,
    Arr.map((a) => workoutVideo.get([a, activities])),
  ),
)

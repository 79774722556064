// @generated by protoc-gen-es v1.3.0 with parameter "target=ts"
// @generated from file video/v1/workout_video.proto (package video.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, StringValue } from "@bufbuild/protobuf";
import { Video } from "./video_pb.js";
import { ActivityInstance } from "../../activity/v1/activity_instance_pb.js";

/**
 * @generated from message video.v1.WorkoutVideo
 */
export class WorkoutVideo extends Message<WorkoutVideo> {
  /**
   * `${video.source}:${video.id}`
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * a title for the workout
   *
   * @generated from field: google.protobuf.StringValue title = 2;
   */
  title?: string;

  /**
   * @generated from field: video.v1.Video video = 3;
   */
  video?: Video;

  /**
   * @generated from field: repeated activity.v1.ActivityInstance activities = 4;
   */
  activities: ActivityInstance[] = [];

  constructor(data?: PartialMessage<WorkoutVideo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "video.v1.WorkoutVideo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "message", T: StringValue },
    { no: 3, name: "video", kind: "message", T: Video },
    { no: 4, name: "activities", kind: "message", T: ActivityInstance, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WorkoutVideo {
    return new WorkoutVideo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WorkoutVideo {
    return new WorkoutVideo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WorkoutVideo {
    return new WorkoutVideo().fromJsonString(jsonString, options);
  }

  static equals(a: WorkoutVideo | PlainMessage<WorkoutVideo> | undefined, b: WorkoutVideo | PlainMessage<WorkoutVideo> | undefined): boolean {
    return proto3.util.equals(WorkoutVideo, a, b);
  }
}


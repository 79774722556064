import { FC, forwardRef, useCallback } from "react"

import { pipe } from "fp-ts/es6/function"
import * as Arr from "fp-ts/es6/ReadonlyArray"

import { Dorsoventral } from "@fitnesspilot/data-human-body/dist/anatomicalAxes"
import {
  MuscleGroups,
  MuscleGroupsByDorsoventralSide,
} from "@fitnesspilot/data-human-body/dist/muscleGroups"
import { Sex } from "@fitnesspilot/data-human-body/dist/sex"

import { MuscleGroupIcon } from "../../atoms/MuscleGroupIcon/MuscleGroupIcon"
import { MuscleGroupPosition } from "../../atoms/MuscleGroupPosition/MuscleGroupPosition"
import { Body, BodyProps } from "../../molecules/Body/Body"

type Side<a extends Dorsoventral> = {
  dorsoventralSide: a
  muscleGroupLevels: Record<
    MuscleGroupsByDorsoventralSide[a],
    number | false | undefined
  >
  onMuscleGroupClick: (v: MuscleGroupsByDorsoventralSide[a]) => void
  toggleMuscleGroupPopover: (
    v: MuscleGroupsByDorsoventralSide[a],
    force: boolean,
  ) => void
  openMuscleGroupPopover?: MuscleGroupsByDorsoventralSide[a]
}

type MuscleGroupProps = {
  dorsoventralSide: Dorsoventral
  muscleGroup: MuscleGroups
  level: number | false | undefined
  popoverIsOpen: boolean
  onToggleMuscleGroupPopover: (
    muscleGroup: MuscleGroups,
    force: boolean,
  ) => void
}

const MuscleGroup = ({
  dorsoventralSide,
  muscleGroup,
  level,
  popoverIsOpen,
  onToggleMuscleGroupPopover,
}: MuscleGroupProps) => {
  const togglePopover = useCallback(
    () => onToggleMuscleGroupPopover(muscleGroup, false),
    [muscleGroup, onToggleMuscleGroupPopover],
  )

  return (
    <MuscleGroupPosition
      {...{ dorsoventralSide }}
      {...({ muscleGroup } as any)}
    >
      <MuscleGroupIcon
        dorsoventralSide={dorsoventralSide as any}
        {...{
          muscleGroup,
          level,
          popoverIsOpen,
          togglePopover,
        }}
      />
    </MuscleGroupPosition>
  )
}

export type BodyMapProps = {
  sex: Sex
} & Omit<BodyProps, "sex" | "dorsoventralSide"> &
  (Side<Dorsoventral.dorsal> | Side<Dorsoventral.ventral>)

export const BodyMap = forwardRef<SVGSVGElement, BodyMapProps>(
  (
    {
      sex,
      dorsoventralSide,
      muscleGroupLevels,
      onMuscleGroupClick,
      toggleMuscleGroupPopover,
      openMuscleGroupPopover,
      ...props
    },
    ref,
  ) => (
    <Body {...{ sex, dorsoventralSide, ref }} {...props}>
      {pipe(
        Object.values(MuscleGroupsByDorsoventralSide[dorsoventralSide]),
        Arr.map((muscleGroup) => (
          <a
            key={muscleGroup}
            onMouseEnter={() =>
              (toggleMuscleGroupPopover as any)(muscleGroup, true)
            }
            onMouseLeave={() =>
              (toggleMuscleGroupPopover as any)(muscleGroup, false)
            }
            onClick={() => (onMuscleGroupClick as any)(muscleGroup)}
          >
            <MuscleGroup
              {...{ dorsoventralSide, muscleGroup }}
              level={
                (
                  muscleGroupLevels as Record<
                    typeof muscleGroup,
                    number | false | undefined
                  >
                )[muscleGroup]
              }
              popoverIsOpen={openMuscleGroupPopover === muscleGroup}
              onToggleMuscleGroupPopover={toggleMuscleGroupPopover as any}
            />
          </a>
        )),
      )}
    </Body>
  ),
)

import * as Eq from "fp-ts/es6/Eq"
import * as Num from "fp-ts/es6/number"
import { Lens } from "monocle-ts"

export type AboutMeAnswers = {
  at: number
  of: number
}
export const aboutMeAnswers = Eq.struct<AboutMeAnswers>({
  at: Num.Eq,
  of: Num.Eq,
})
export const _at = Lens.fromProp<AboutMeAnswers>()("at")
export const _of = Lens.fromProp<AboutMeAnswers>()("of")

import { FC, useMemo } from "react"
import { FaUser } from "react-icons/fa"
import { FormattedMessage, useIntl } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import { Navigate, useParams } from "react-router-dom"
import styled from "@emotion/styled"

import * as Arr_ from "fp-ts/es6/Array"
import * as Foldable from "fp-ts/es6/Foldable"
import { constNull, constVoid, flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import * as St from "fp-ts/es6/ReadonlySet"
import * as Tree from "fp-ts/es6/Tree"
import { fromTraversable, Index, Prism } from "monocle-ts"
import { arrTrav, useStateIO } from "@fitnesspilot/data-common"

import { sportName } from "@fitnesspilot/components-activity/dist/atoms/SportName/SportName"
import { MuscleFormData } from "@fitnesspilot/components-activity/dist/molecules/MusclePreferenceFieldset/MusclePreferenceFieldset"
import {
  FormData as SportFormData,
  SportPreferencesForm,
  SportPreferencesWithMusclesForm,
} from "@fitnesspilot/components-activity/dist/organisms/SportPreferencesForm/SportPreferencesForm"
import { Header } from "@fitnesspilot/components-common/dist/molecules/Header/Header"
import { LikeButton } from "@fitnesspilot/components-common/dist/molecules/LikeButton/LikeButton"
import { Markdown } from "@fitnesspilot/components-common/dist/molecules/Markdown/Markdown"
import { tabs } from "@fitnesspilot/components-common/dist/templates/Tabs/Tabs"
import { SportStatistics } from "@fitnesspilot/components-event/dist/organisms/SportStatistics/SportStatistics"
import { activityId } from "@fitnesspilot/data-activity/dist/activity/ActivityId"
import { activityTag } from "@fitnesspilot/data-activity/dist/activity/ActivityTag"
import { filtersToSearch } from "@fitnesspilot/data-activity/dist/activityFilters/searchString"
import {
  flattenActivityInstances,
  foldActivityInstanceExercise,
} from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"
import * as MuscleSettings from "@fitnesspilot/data-activity/dist/sport/MuscleSettings"
import * as Sport from "@fitnesspilot/data-activity/dist/sport/Sport"
import * as SportId from "@fitnesspilot/data-activity/dist/sport/SportId"
import * as ActivityData from "@fitnesspilot/data-activity/dist/store"
import { SportPreferencesTab } from "@fitnesspilot/data-activity/dist/store/SportPreferences"
import * as Event from "@fitnesspilot/data-event/dist/calendar/Event"
import * as EventOrRecommendation from "@fitnesspilot/data-event/dist/calendar/EventOrRecommendation"
import * as EventData from "@fitnesspilot/data-event/dist/store"
import {
  MuscleId_,
  muscleId_FromString,
} from "@fitnesspilot/data-human-body/dist/Muscle"
import { stringAsMuscleGroupId } from "@fitnesspilot/data-human-body/dist/muscleGroups"

import { MainTemplate } from "../../templates/Main/Main"

import mem from "memoize-one"
import { Dispatch } from "redux"

const Tabs = styled(tabs<SportPreferencesTab>())`
  padding-top: 10px;
  background: #eeeef8;
`

type OwnProps = {
  sportId: SportId.SportId
}

type ParentState = ActivityData.ParentState & EventData.ParentState
type Action = ActivityData.Action | EventData.Action

const _muscleSettings = ActivityData.selectors.state.composeLens(
  ActivityData.selectors.muscleSettings,
)

const mapState = (state: ParentState, { sportId }: OwnProps) => ({
  sport: pipe(
    state,
    ActivityData.selectors.state.compose(ActivityData.selectors.sports).get,
    mem(
      flow(
        Index.fromAt(Sport.sportWithIdOptics._at).index(sportId).getOption,
        Opt.map<Sport.Sport, Sport.SportWithId>((value) => ({
          id: sportId,
          value,
        })),
        Opt.getOrElse(() => ({ id: sportId, value: Sport.empty })),
      ),
    ),
  ),
  muscleSettings: pipe(state, _muscleSettings.get),
  muscleDefaultValues: pipe(
    state,
    _muscleSettings.get,
    mem(
      flow(
        Arr.chain((t) => Foldable.toReadonlyArray(Tree.Foldable)(t)),
        Arr.filter((ms) =>
          Object.values(MuscleId_).includes(ms.id as MuscleId_),
        ),
        NonEmpty.fromReadonlyArray,
        Opt.map(
          NonEmpty.reduce(
            {} as MuscleFormData,
            (as, { id, enabled, priority }) => ({
              ...as,
              [id as MuscleId_]: {
                enabled,
                priority,
              } as MuscleFormData,
            }),
          ),
        ),
      ),
    ),
  ),
  eventsOrRecommendations: EventData.selectors.state
    .compose(EventData.selectors.eventsOrRecommendations)
    .get(state),
})

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ =
    (act: Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, constVoid)

  return {
    saveSport: flow(ActivityData.saveSport, dispatch_),
    saveMuscleSettings: flow(ActivityData.saveMuscleSettings, dispatch_),
    setSportIsLiked: flow(ActivityData.setSportIsLiked, dispatch_),
    fetchEvents: flow(EventData.fetchEventsForSportsStatistics, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

export type SportPreferencesPageProps = PropsFromRedux & OwnProps

export const SportPreferencesPage: FC<SportPreferencesPageProps> = ({
  sport,
  muscleSettings,
  muscleDefaultValues,
  eventsOrRecommendations,
  saveSport,
  saveMuscleSettings,
  setSportIsLiked,
  fetchEvents,
  ...props
}) => {
  const [currentTab, setCurrentTab] = useStateIO<SportPreferencesTab>(
    () => SportPreferencesTab.settings,
  )()

  const events = useMemo(
    () =>
      pipe(
        eventsOrRecommendations,
        arrTrav<EventOrRecommendation.EventOrRecommendation>()
          .composePrism(EventOrRecommendation._EventWithId)
          .filter((e) => e.value.type === Event.EventType.activity)
          .filter((e) =>
            pipe(
              sport.id,
              SportId.foldSportId(
                (tag) =>
                  pipe(
                    e.value.activities,
                    Arr.some(
                      foldActivityInstanceExercise(
                        () => false,
                        (a) =>
                          pipe(
                            a.activity.value.tags,
                            St.elem(activityTag)(tag),
                          ),
                      ),
                    ),
                  ),
                (id) =>
                  pipe(
                    e.value.activities,
                    flattenActivityInstances,
                    Arr.some((a) => activityId.equals(a.value.activity.id, id)),
                  ),
              ),
            ),
          )
          .asFold().getAll,
      ),
    [sport, eventsOrRecommendations],
  )

  const intl = useIntl()

  const defaultValues = pipe(
    sport,
    ({ value: { defaultIntensity, fixedSchedule } }) => ({
      intensity: defaultIntensity,
      fixedSchedule,
    }),
  )

  const handleSaveSport = ({ intensity, fixedSchedule }: SportFormData) =>
    pipe(
      sport,
      Sport.sportWithIdOptics._value.modify((s) => ({
        ...s,
        defaultIntensity: intensity,
        fixedSchedule,
      })),
      saveSport,
    )

  return (
    <MainTemplate
      header={
        <>
          <Header
            icon={<FaUser />}
            title={
              <FormattedMessage
                defaultMessage="{name} settings"
                values={{
                  name: pipe(sport, ({ id, value }) =>
                    sportName(intl)({
                      id,
                      def: pipe(value, Sport._name.get, Opt.toUndefined),
                    }),
                  ),
                }}
              />
            }
          >
            <LikeButton
              className="like"
              isActive={sport.value.isLiked}
              onSetActive={(value) => setSportIsLiked({ id: sport.id, value })}
            >
              <FormattedMessage defaultMessage="Like" />
            </LikeButton>
          </Header>
        </>
      }
    >
      <Tabs
        tabTexts={{
          [SportPreferencesTab.statistics]: (
            <FormattedMessage defaultMessage="Statistics" />
          ),
          [SportPreferencesTab.settings]: (
            <FormattedMessage defaultMessage="Settings" />
          ),
          [SportPreferencesTab.instructions]: (
            <FormattedMessage defaultMessage="Instructions" />
          ),
          [SportPreferencesTab.exercises]: (
            <FormattedMessage defaultMessage="Exercises" />
          ),
        }}
        {...props}
        {...{ currentTab }}
        onChangeTab={setCurrentTab}
        tabs={pipe(
          [
            Opt.some(SportPreferencesTab.statistics),
            Opt.some(SportPreferencesTab.settings),
            pipe(
              sport.id,
              SportId.foldSportId(
                () => SportPreferencesTab.exercises,
                () => SportPreferencesTab.instructions,
              ),
              Opt.some,
            ),
          ],
          Arr.compact,
        )}
        tabsProps={{
          "data-help-mode": "sportsTabs",
        }}
        tabProps={{
          [SportPreferencesTab.statistics]: {
            "data-help-mode": "statisticsAndEvents",
          },
          [SportPreferencesTab.settings]: {
            "data-help-mode": "workoutSettings",
          },
          [SportPreferencesTab.exercises]: {
            "data-help-mode": "exercises",
          },
        }}
      >
        {{
          [SportPreferencesTab.statistics]: (
            <SportStatistics {...{ events }} onView={fetchEvents()} />
          ),
          [SportPreferencesTab.settings]: pipe(
            sport.id,
            SportId.foldSportId(
              () => (
                <SportPreferencesWithMusclesForm
                  key="sportPreferencesTag"
                  id="sportPreferencesTag"
                  defaultValues={{
                    ...defaultValues,
                    ...Opt.toUndefined(muscleDefaultValues),
                  }}
                  onSubmit={({ intensity, fixedSchedule, ...muscles }) =>
                    pipe(
                      handleSaveSport({
                        intensity,
                        fixedSchedule,
                      } as SportFormData),
                      IO.chain(() =>
                        pipe(
                          muscleSettings,
                          fromTraversable(Arr_.Traversable)<
                            Tree.Tree<MuscleSettings.MuscleSettings>
                          >()
                            .composeTraversal(
                              fromTraversable(Tree.Traversable)(),
                            )
                            .modify((ms) =>
                              pipe(
                                ms.id,
                                stringAsMuscleGroupId.getOption,
                                Opt.fold(
                                  () =>
                                    pipe(
                                      ms.id,
                                      muscleId_FromString.getOption,
                                      Opt.chain((id) =>
                                        Opt.fromNullable(muscles[id]),
                                      ),
                                    ),
                                  (muscleGroupId) =>
                                    Opt.some(
                                      MuscleSettings.getGroupSettings(
                                        muscleGroupId,
                                      )(muscles),
                                    ),
                                ),
                                Opt.map(({ enabled, priority }) => ({
                                  ...ms,
                                  enabled,
                                  priority,
                                })),
                                Opt.getOrElse(() => ms),
                              ),
                            ),
                          saveMuscleSettings,
                        ),
                      ),
                    )()
                  }
                />
              ),
              () => (
                <SportPreferencesForm
                  key="sportPreferencesActivity"
                  id="sportPreferencesActivity"
                  {...{ defaultValues }}
                  onSubmit={(values) => handleSaveSport(values)()}
                />
              ),
            ),
          ),
          [SportPreferencesTab.instructions]: pipe(
            sport,
            Sport.sportWithIdOptics._value.compose(Sport._instructions).get,
            Opt.map((value) => (
              <Markdown key={SportPreferencesTab.instructions}>
                {value}
              </Markdown>
            )),
            Opt.toNullable,
          ),
          [SportPreferencesTab.exercises]: currentTab ===
            SportPreferencesTab.exercises && (
            <Navigate
              to={{
                pathname: "/sports/exercises",
                search: filtersToSearch({
                  tags: SportId.foldSportId(
                    (t) => new Set([t]),
                    () => undefined,
                  )(sport.id),
                }),
              }}
            />
          ),
        }}
      </Tabs>
    </MainTemplate>
  )
}

const SportPreferencesPageContainer_ = connector(SportPreferencesPage)
export type SportPreferencesPageContainerProps = Omit<
  SportPreferencesPageProps,
  "sportId"
>
export const SportPreferencesPageContainer: FC = () => {
  const params = useParams<{ sportId: string }>()
  const sportId = pipe(
    params.sportId,
    Opt.fromNullable,
    Prism.some<string>().composePrism(SportId.stringAsSportId).getOption,
  )

  return pipe(
    sportId,
    Opt.fold(constNull, (sportId) => (
      <SportPreferencesPageContainer_ {...{ sportId }} />
    )),
  )
}

import * as Opt from "fp-ts/es6/Option"
import { Time } from "time-ts/es6"

import * as ActivityInstanceFilters from "@fitnesspilot/data-activity/dist/activityFilters/ActivityInstanceFilters"
import { ActivityInstanceSorting } from "@fitnesspilot/data-activity/dist/activityFilters/ActivitySorting"
import { numberAsAlignmentUnsafe } from "@fitnesspilot/data-human-body/dist/Alignment"

import { EventType } from "../calendar/Event"
import { EventsOrRecommendations } from "../calendar/EventsOrRecommendations"
import { _RecurrenceSingle } from "../calendar/Recurrence"
import { Timeslot } from "../calendar/Timeslot"
import * as AddActivity from "./AddActivity"
import { CalendarSelection } from "./CalendarSelection"
import { EditingEvent, EditingEventWithId } from "./EditingEvent"

export const stateKey = "event"

export type State = {
  // @TODO Make this a ReadonlySet
  eventsOrRecommendations: EventsOrRecommendations
  timeslots: ReadonlyArray<Timeslot>
  loadedRanges: ReadonlySet<readonly [Time, Time]>
  editingEvent: Opt.Option<EditingEventWithId>
  calendarSelection: Opt.Option<CalendarSelection>
}
export const initialState: State = {
  eventsOrRecommendations: [],
  timeslots: [],
  loadedRanges: new Set(),
  editingEvent: Opt.none,
  calendarSelection: Opt.none,
}
export const initialEditingEventBase = (): Omit<EditingEvent, "between"> => ({
  title: Opt.none,
  type: EventType.activity,
  source: Opt.none,
  video: Opt.none,
  isRecommendation: false,
  recommendations: [],
  alignment: numberAsAlignmentUnsafe(0),
  recurrence: _RecurrenceSingle.reverseGet(undefined),
  activities: [],
  addActivity: AddActivity.empty,
  catalog: [],
  selectedActivities: [],
  confirmed: Opt.none,
})

export const initialEditingEvent = (start: Time, end: Time): EditingEvent => ({
  ...initialEditingEventBase(),
  between: [start, end],
})

export type ParentState = { [k in typeof stateKey]: State }

import * as Eq from "fp-ts/es6/Eq"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { Lens } from "monocle-ts"
import { Duration, duration } from "time-ts/es6"

import {
  Alignment,
  alignment,
} from "@fitnesspilot/data-human-body/dist/Alignment"
import {
  Intensity,
  intensity,
} from "@fitnesspilot/data-human-body/dist/Intensity"
import {
  MuscleGroupId,
  muscleGroupId,
} from "@fitnesspilot/data-human-body/dist/muscleGroups"

import { ActivityId, activityId } from "../activity/ActivityId"
import { ActivityMuscles, activityMuscles } from "../activity/ActivityMuscles"
import { WithId, withIdEq } from "../activity/WithId"

export type ActivityInstanceMuscles = {
  activity: WithId<ActivityId, ActivityMuscles>
  alignment: Opt.Option<Alignment>
  duration: Opt.Option<Duration>
  intensity: Opt.Option<Intensity>
  muscleGroupIds: ReadonlyArray<MuscleGroupId>
}
export const activityInstanceMuscles: Eq.Eq<ActivityInstanceMuscles> =
  Eq.struct({
    activity: withIdEq(activityId, activityMuscles),
    alignment: Opt.getEq(alignment),
    duration: Opt.getEq(duration),
    intensity: Opt.getEq(intensity),
    muscleGroupIds: Arr.getEq(muscleGroupId),
  })
export const fromActivityMuscles = (
  activity: WithId<ActivityId, ActivityMuscles>,
): ActivityInstanceMuscles => ({
  activity,
  alignment: Opt.none,
  duration: Opt.none,
  intensity: Opt.none,
  muscleGroupIds: [],
})
export const _activity = Lens.fromProp<ActivityInstanceMuscles>()("activity")
export const _alignment = Lens.fromProp<ActivityInstanceMuscles>()("alignment")
export const _duration = Lens.fromProp<ActivityInstanceMuscles>()("duration")
export const _intensity = Lens.fromProp<ActivityInstanceMuscles>()("intensity")
export const _muscleGroupIds =
  Lens.fromProp<ActivityInstanceMuscles>()("muscleGroupIds")

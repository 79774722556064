import * as Eq from "fp-ts/es6/Eq"
import * as Num from "fp-ts/es6/number"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { Lens } from "monocle-ts"
import { Positive } from "newtype-ts/es6/Positive"
import { Time, time } from "time-ts/es6"
import { atPair, explicitOrder } from "@fitnesspilot/data-common"

// import {
//   Alignment,
//   alignment,
// } from "@fitnesspilot/data-human-body/dist/Alignment"
import { MuscleId, muscleId } from "@fitnesspilot/data-human-body/dist/Muscle"

import { Recurrence, recurrence } from "./Recurrence"

export type Recommendation = {
  between: readonly [Time, Time]
  // alignment: Alignment
  recurrence: Recurrence
  scheduledMuscles: ReadonlyArray<readonly [MuscleId, Positive]>
}
export const recommendation: Eq.Eq<Recommendation> = Eq.struct({
  between: Eq.tuple(time, time),
  // alignment,
  recurrence,
  scheduledMuscles: Arr.getEq(
    Eq.tuple(muscleId, Num.Eq as any as Eq.Eq<Positive>),
  ),
})
export const _between = Lens.fromProp<Recommendation>()("between")
export const _start = _between.composeLens(atPair<Time>().at(0))
export const _end = _between.composeLens(atPair<Time>().at(1))
// export const _alignment = Lens.fromProp<Recommendation>()("alignment")
export const _recurrence = Lens.fromProp<Recommendation>()("recurrence")
export const _scheduledMuscles =
  Lens.fromProp<Recommendation>()("scheduledMuscles")

export enum EventOrRecommendationType {
  event = "event",
  recommendation = "recommendation",
}
export const eventOrRecommendationType: Ord.Ord<EventOrRecommendationType> =
  explicitOrder([
    EventOrRecommendationType.event,
    EventOrRecommendationType.recommendation,
  ])

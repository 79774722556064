import { FC } from "react"
import styled from "@emotion/styled"

const Fitness = styled.span`
  color: ${({ theme }) => theme.colours.primary.toString()};
  font-weight: ${({ theme }) => theme.font.weights.bold};
`

const Pilot = styled.span`
  color: ${({ theme }) => theme.colours.bodyColour.toString()};
`

const LogoText_ = styled.span`
  text-transform: uppercase;
  vertical-align: sub;
`

export type LogoTextProps = {
  className?: string
}

export const LogoText: FC<LogoTextProps> = (props) => (
  <LogoText_ {...props}>
    <Fitness>Fitness</Fitness>
    <Pilot>pilot</Pilot>
  </LogoText_>
)

import * as Eq from "fp-ts/es6/Eq"
import * as Opt from "fp-ts/es6/Option"
import { Lens } from "monocle-ts"
import { Day, day } from "time-ts/es6"
import {
  Dimension,
  validNumber,
  ValueWithCanonicalUnit,
  valueWithCanonicalUnit,
} from "@fitnesspilot/data-common"

import * as BodyComposition from "./bodyComposition"
import { Sex, sex as _sex } from "./sex"

export type HumanBody = {
  birthDate: Opt.Option<Day>
  bodyComposition: Opt.Option<BodyComposition.BodyComposition>
  height: Opt.Option<ValueWithCanonicalUnit<Dimension.lengthHeight>>
  mass: Opt.Option<ValueWithCanonicalUnit<Dimension.mass>>
  sex: Opt.Option<Sex>
}
export const humanBody: Eq.Eq<HumanBody> = Eq.struct({
  birthDate: Opt.getEq(day),
  bodyComposition: Opt.getEq(BodyComposition.bodyComposition),
  height: Opt.getEq(
    valueWithCanonicalUnit(Dimension.lengthHeight, validNumber),
  ),
  mass: Opt.getEq(valueWithCanonicalUnit(Dimension.mass, validNumber)),
  sex: Opt.getEq(_sex),
})
export const birthDate = Lens.fromProp<HumanBody>()("birthDate")
export const bodyComposition = Lens.fromProp<HumanBody>()("bodyComposition")
export const height = Lens.fromProp<HumanBody>()("height")
export const mass = Lens.fromProp<HumanBody>()("mass")
export const sex = Lens.fromProp<HumanBody>()("sex")

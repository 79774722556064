import { Lens } from "monocle-ts"

import { ParentState, State, stateKey } from "./state"

export const state = Lens.fromProp<ParentState>()(stateKey)

export const coachTasks = Lens.fromProp<State>()("coachTasks")
export const eventConfirmationIsOpen = Lens.fromProp<State>()(
  "eventConfirmationIsOpen",
)

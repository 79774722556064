import { FC } from "react"
import { FormattedMessage } from "react-intl"

import { CombinedFitnessLevelParts } from "@fitnesspilot/data-human-body/dist/fitnessLevel"

export type CombinedFitnessLevelPartLabelProps = {
  combinedFitnessLevelPart: CombinedFitnessLevelParts
}

export const CombinedFitnessLevelPartLabel: FC<
  CombinedFitnessLevelPartLabelProps
> = ({ combinedFitnessLevelPart }) =>
  ({
    [CombinedFitnessLevelParts.bodyFat]: (
      <FormattedMessage defaultMessage="Body Fat" />
    ),
    [CombinedFitnessLevelParts.musculature]: (
      <FormattedMessage defaultMessage="Musculature" />
    ),
  })[combinedFitnessLevelPart]

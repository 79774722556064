import { FC } from "react"
import { InputProps } from "reactstrap"

import { flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import { Iso } from "monocle-ts"
import {
  _localTimeOfDayDurationHours,
  _localTimeOfDayDurationMinutes,
  _localTimeOfDayDurationSeconds,
  LocalTimeOfDay,
  LocalTimeOfDayDuration,
  mkLocalTimeOfDayDuration,
  mkLocalTimeOfDayWrapped,
} from "time-ts/es6"

import { LocalTimeOfDayInput } from "./LocalTimeOfDayInput"

// @TODO Find a better solution
const localTimeOfDayAsLocalTimeOfDayDuration = new Iso<
  LocalTimeOfDay,
  LocalTimeOfDayDuration
>(
  (s) => mkLocalTimeOfDayDuration(s.hour, s.minute, s.second),
  (s) =>
    mkLocalTimeOfDayWrapped(
      _localTimeOfDayDurationHours.get(s),
      _localTimeOfDayDurationMinutes.get(s),
      _localTimeOfDayDurationSeconds.get(s),
    ),
)

export type LocalTimeOfDayDurationInputProps = {
  value: Opt.Option<LocalTimeOfDayDuration>
  min: Opt.Option<LocalTimeOfDayDuration>
  max: Opt.Option<LocalTimeOfDayDuration>
  step: Opt.Option<LocalTimeOfDayDuration>
  onChange: (v: Opt.Option<LocalTimeOfDayDuration>) => IO.IO<void>
  hideClear?: boolean
} & Partial<
  Pick<
    InputProps,
    "valid" | "invalid" | "required" | "disabled" | "readonly" | "bsSize" | "id"
  >
>

export const LocalTimeOfDayDurationInput: FC<
  LocalTimeOfDayDurationInputProps
> = ({ value, min, max, onChange, ...props }) => (
  <LocalTimeOfDayInput
    value={pipe(
      value,
      Opt.map(localTimeOfDayAsLocalTimeOfDayDuration.reverseGet),
    )}
    min={pipe(min, Opt.map(localTimeOfDayAsLocalTimeOfDayDuration.reverseGet))}
    max={pipe(max, Opt.map(localTimeOfDayAsLocalTimeOfDayDuration.reverseGet))}
    onChange={flow(
      Opt.map(localTimeOfDayAsLocalTimeOfDayDuration.get),
      onChange,
    )}
    {...props}
  />
)

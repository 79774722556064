import { FirebaseReducer } from "react-18-redux-firebase"

import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"

import { ParentState as APIParentState } from "@fitnesspilot/data-api"

import { UserError } from "../error"

import { RouterState } from "redux-first-history"

export const stateKey = "common"

export type State = {
  availableUpdate: Opt.Option<ServiceWorker>
  errors: ReadonlyArray<UserError>
  shownError: Opt.Option<UserError>
  errorDetailsAreOpen: boolean
}
export const initialState: State = {
  availableUpdate: Opt.none,
  errors: Arr.zero<UserError>(),
  shownError: Opt.zero<UserError>(),
  errorDetailsAreOpen: false,
}

export type ParentState = APIParentState & {
  firebase: FirebaseReducer.Reducer
  router: RouterState
} & {
  [k in typeof stateKey]: State
}

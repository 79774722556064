import color from "color"

export type Colour = color<string>

export type Theme = {
  textColour: Colour
  fontSize: string
  card: {
    backgroundColour: Colour
    customCss?: string
  }
  button: {
    colour: Colour
    backgroundColour: Colour
    customCss?: string
  }
}

export const theme: Theme = {
  textColour: color("#000"),
  fontSize: "16px",
  card: {
    backgroundColour: color("lightgrey"),
  },
  button: {
    colour: color("#000"),
    backgroundColour: color("lightgrey"),
  },
}

import { useMemo } from "react"
import styled from "@emotion/styled"

import * as Bool from "fp-ts/es6/boolean"
import { flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"

import {
  ActivityCategory,
  activityCategory,
  foldActivityCategory,
} from "@fitnesspilot/data-activity/dist/activity/ActivityCategory"
import {
  _ActivityInstanceExercise,
  ActivityInstanceNonGroup,
  foldActivityInstanceNonGroup,
} from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"

import { ActivityDuration } from "../ActivityDuration/ActivityDuration"
import { ActivityDurationDistance } from "../ActivityDurationDistance/ActivityDurationDistance"
import { ActivityIntensity } from "../ActivityIntensity/ActivityIntensity"
import { ActivitySets } from "../ActivitySets/ActivitySets"

import { v4 as uuid } from "uuid"

export type ActivityInputsProps = {
  className?: string
  activity: ActivityInstanceNonGroup
  onChange: (activity: ActivityInstanceNonGroup) => IO.IO<void>
}
export const ActivityInputs = styled(
  ({ className, activity, onChange }: ActivityInputsProps) => {
    const id = useMemo(uuid, [])

    return (
      <div {...{ className }}>
        {pipe(
          activity,
          foldActivityInstanceNonGroup(
            (a) => (
              <>
                {foldActivityCategory(
                  () => (
                    <ActivitySets
                      activity={a}
                      onChange={flow(
                        _ActivityInstanceExercise.reverseGet,
                        onChange,
                      )}
                    />
                  ),
                  () => (
                    <ActivitySets
                      activity={a}
                      onChange={flow(
                        _ActivityInstanceExercise.reverseGet,
                        onChange,
                      )}
                    />
                  ),
                  () => (
                    <ActivityDuration
                      id={`${id}-duration`}
                      {...{ activity, onChange }}
                    />
                  ),
                  () => (
                    <ActivityDurationDistance
                      id={`${id}-durationDistance`}
                      activity={a}
                      onChange={flow(
                        _ActivityInstanceExercise.reverseGet,
                        onChange,
                      )}
                    />
                  ),
                  () => (
                    <ActivityDurationDistance
                      id={`${id}-durationDistance`}
                      activity={a}
                      onChange={flow(
                        _ActivityInstanceExercise.reverseGet,
                        onChange,
                      )}
                    />
                  ),
                  () => null,
                )(a.activity.value.category)}
                {pipe(
                  activityCategory.equals(
                    a.activity.value.category,
                    ActivityCategory.stretching,
                  ),
                  Bool.fold(
                    () => (
                      <ActivityIntensity
                        id={`${id}-intensity`}
                        {...{ activity, onChange }}
                      />
                    ),
                    () => null,
                  ),
                )}
              </>
            ),
            () => (
              <ActivityDuration
                id={`${id}-duration`}
                {...{ activity, onChange }}
              />
            ),
            (a) => (
              <>
                <ActivityDuration
                  id={`${id}-duration`}
                  {...{ activity, onChange }}
                />
                <ActivityIntensity
                  id={`${id}-intensity`}
                  {...{ activity, onChange }}
                />
              </>
            ),
            () => (
              <ActivityDuration
                id={`${id}-duration`}
                {...{ activity, onChange }}
              />
            ),
          ),
        )}
      </div>
    )
  },
)`
  padding-top: 10px;

  & > .form-group:last-of-type {
    margin-bottom: 0;
  }
`

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityInstanceExerciseFromJSONTyped, ActivityInstanceGroupFromJSONTyped, ActivityInstanceJobFromJSONTyped, ActivityInstanceMusclesFromJSONTyped, ActivityInstanceSleepFromJSONTyped, } from "./";
import { ActivityInstanceExerciseToJSON, } from "./ActivityInstanceExercise";
import { ActivityInstanceGroupToJSON, } from "./ActivityInstanceGroup";
import { ActivityInstanceJobToJSON, } from "./ActivityInstanceJob";
import { ActivityInstanceMusclesToJSON, } from "./ActivityInstanceMuscles";
import { ActivityInstanceSleepToJSON, } from "./ActivityInstanceSleep";
import { ActivityType, ActivityTypeFromJSON, ActivityTypeToJSON, } from "./ActivityType";
/**
 * Check if a given object implements the ActivityInstance interface.
 */
export function instanceOfActivityInstance(value) {
    let isInstance = true;
    isInstance = isInstance && "$type" in value;
    return isInstance;
}
export function ActivityInstanceFromJSON(json) {
    return ActivityInstanceFromJSONTyped(json, false);
}
export function ActivityInstanceFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json.$type === "exercise") {
            return ActivityInstanceExerciseFromJSONTyped(json, true);
        }
        if (json.$type === "group") {
            return ActivityInstanceGroupFromJSONTyped(json, true);
        }
        if (json.$type === "job") {
            return ActivityInstanceJobFromJSONTyped(json, true);
        }
        if (json.$type === "muscles") {
            return ActivityInstanceMusclesFromJSONTyped(json, true);
        }
        if (json.$type === "sleep") {
            return ActivityInstanceSleepFromJSONTyped(json, true);
        }
    }
    return {
        $type: ActivityTypeFromJSON(json.$type),
    };
}
export function ActivityInstanceToJSON(value, ignoreDiscriminator) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    if (!ignoreDiscriminator) {
        if (value.$type === ActivityType.Exercise) {
            return ActivityInstanceExerciseToJSON(value);
        }
        if (value.$type === ActivityType.Group) {
            return ActivityInstanceGroupToJSON(value);
        }
        if (value.$type === ActivityType.Job) {
            return ActivityInstanceJobToJSON(value);
        }
        if (value.$type === ActivityType.Muscles) {
            return ActivityInstanceMusclesToJSON(value);
        }
        if (value.$type === ActivityType.Sleep) {
            return ActivityInstanceSleepToJSON(value);
        }
    }
    return {
        $type: ActivityTypeToJSON(value.$type),
    };
}

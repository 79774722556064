import { FC, SVGAttributes, useState } from "react"
import { keyframes } from "@emotion/react"
import styled from "@emotion/styled"

const rotating = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const RotatingG = styled(
  ({
    isAnimating,
    ...props
  }: { isAnimating?: boolean } & SVGAttributes<SVGGElement>) => (
    <g {...props} />
  ),
)`
  animation: 1s cubic-bezier(0.65, 0.05, 0.36, 1) infinite normal forwards
    paused ${rotating};
  transform-box: fill-box;
  transform-origin: center;
  ${({ isAnimating }) => isAnimating && `animation-play-state: running;`}
`

export type LogoProps = {
  isLoading?: boolean
} & Omit<SVGAttributes<SVGElement>, "xmlns" | "viewBox">

export const Logo: FC<LogoProps> = ({ isLoading = false, ...props }) => {
  // Let's not use global state for this animation
  const [isAnimating, setIsAnimating] = useState<boolean>(isLoading)
  if (isLoading && !isAnimating) {
    setIsAnimating(true)
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33.87 33.87"
      {...props}
    >
      <g fill="#fff">
        <path d="M16.93 30.9a10.85 10.85 0 110-21.7 10.85 10.85 0 010 21.7m3.06-24.3a.77.77 0 01-.6-.74v-.83c0-.16.14-.3.3-.3h1.33a.69.69 0 00.69-.68V.69A.69.69 0 0021 0h-8.18a.69.69 0 00-.7.69v3.35c0 .38.32.69.7.69h1.33a.3.3 0 01.29.26v.89a.78.78 0 01-.6.7 13.82 13.82 0 106.14 0" />
        <path d="M31.95 5a.69.69 0 01.05.98l-2.95 3.34a.69.69 0 01-.97.05l-2-1.81a.69.69 0 01-.06-.98l2.96-3.33a.69.69 0 01.98-.05z" />
      </g>
      <RotatingG
        isAnimating={isAnimating}
        onAnimationIteration={() => setIsAnimating(isLoading)}
      >
        <path fill="#3ac6ef" d="M17.18 20.21h8.33a8.58 8.58 0 01-8.33 8.33z" />
        <path fill="#f7cb15" d="M16.69 20.21v8.33a8.58 8.58 0 01-8.34-8.33z" />
        <path fill="#3ac6ef" d="M16.69 19.72H8.35a8.58 8.58 0 018.34-8.33z" />
      </RotatingG>
    </svg>
  )
}

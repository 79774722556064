import { FC, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { FaUser } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import { Col, Row } from "reactstrap"

import * as Bool from "fp-ts/es6/boolean"
import { eqDate } from "fp-ts/es6/Date"
import * as Eq from "fp-ts/es6/Eq"
import { pipe } from "fp-ts/es6/function"
import * as Func from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as Str from "fp-ts/es6/string"
import {
  dateAsTime,
  isoStringAsLocalTimeOfDayDuration,
  LocalTimeOfDay,
} from "time-ts/es6"
import { timeAsLocalTimeOfDayInCurrentEnv } from "time-ts/es6/Local/TimeOfDay"
import { unsafeFromSome } from "@fitnesspilot/data-common"

import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import { Content } from "@fitnesspilot/components-common/dist/molecules/Content/Content"
import { Header } from "@fitnesspilot/components-common/dist/molecules/Header/Header"
import { SaveButton } from "@fitnesspilot/components-common/dist/molecules/SaveButton/SaveButton"
import { useFormChangeEffect } from "@fitnesspilot/components-common/dist/react"
import {
  FormData as SleepFormData,
  SleepFieldset,
} from "@fitnesspilot/components-event/dist/organisms/SleepFieldset/SleepFieldset"
import {
  FormData as WorkFormData,
  WorkFieldsetContainer,
} from "@fitnesspilot/components-event/dist/organisms/WorkFieldset/WorkFieldset"
import {
  ActivityHabitsFieldset,
  FormData as ActivityHabitsFormData,
} from "@fitnesspilot/components-user/dist/molecules/ActivityHabitsFieldset/ActivityHabitsFieldset"
import {
  AddictionHabitsFieldset,
  FormData as AddictionHabitsFormData,
} from "@fitnesspilot/components-user/dist/molecules/AddictionHabitsFieldset/AddictionHabitsFieldset"
import {
  FoodHabitsFieldset,
  FormData as FoodHabitsFormData,
} from "@fitnesspilot/components-user/dist/molecules/FoodHabitsFieldset/FoodHabitsFieldset"
import {
  Action,
  job,
  ParentState,
  selectors,
  setWorkAndLifestyle,
} from "@fitnesspilot/data-user"
import * as Habits from "@fitnesspilot/data-user/dist/Habits"

import { MainTemplate } from "../../templates/Main/Main"

import { Dispatch } from "redux"

const work = selectors.state.composeLens(selectors.work)
const sleep = selectors.state.composeLens(selectors.sleep)
const habits = selectors.state.compose(selectors.habits)

type FormData = WorkFormData &
  SleepFormData &
  ActivityHabitsFormData &
  FoodHabitsFormData &
  AddictionHabitsFormData
const formData = Eq.struct<FormData>({
  work: Eq.struct<FormData["work"]>({
    job: job,
    time: Rec.getEq(Opt.getEq(Eq.getStructEq<[Date, Date]>([eqDate, eqDate]))),
    lunchtime: Eq.struct<FormData["work"]["lunchtime"]>({
      duration: Str.Eq,
      within: Eq.getStructEq<[Date, Date]>([eqDate, eqDate]),
    }),
    mayEatAtWork: Bool.Eq,
  }),
  sleep: Eq.struct<FormData["sleep"]>({
    time: Rec.getEq(Eq.getStructEq<[Date, Date]>([eqDate, eqDate])),
  }),
  eatingHabits: Habits.eatingHabits,
  activityHabits: Habits.activityHabits,
  drinkingHabits: Habits.drinkingHabits,
  alcoholHabits: Habits.consumptionLevel,
  smokingHabits: Habits.consumptionLevel,
})

const mapState = (state: ParentState) => {
  const defaultValues: FormData = {
    work: pipe(
      state,
      work.get,
      ({ time, lunchtime: { duration, within }, ...a }) => ({
        time: pipe(
          time,
          Rec.map((a) =>
            pipe(
              a,
              Opt.map(
                ([start, end]) =>
                  [
                    pipe(
                      start,
                      dateAsTime.composePrism(
                        timeAsLocalTimeOfDayInCurrentEnv(),
                      ).reverseGet,
                    ),
                    pipe(
                      end,
                      dateAsTime.composePrism(
                        timeAsLocalTimeOfDayInCurrentEnv(),
                      ).reverseGet,
                    ),
                  ] as [Date, Date],
              ),
            ),
          ),
        ),
        lunchtime: {
          duration: pipe(
            duration,
            isoStringAsLocalTimeOfDayDuration.reverseGet,
          ),
          within: [
            pipe(
              within[0],
              dateAsTime.composePrism(timeAsLocalTimeOfDayInCurrentEnv())
                .reverseGet,
            ),
            pipe(
              within[1],
              dateAsTime.composePrism(timeAsLocalTimeOfDayInCurrentEnv())
                .reverseGet,
            ),
          ] as [Date, Date],
        },
        ...a,
      }),
    ),
    sleep: pipe(state, sleep.get, ({ time, ...a }) => ({
      time: pipe(
        time,
        Rec.map(
          ([start, end]) =>
            [
              pipe(
                start,
                dateAsTime.composePrism(timeAsLocalTimeOfDayInCurrentEnv())
                  .reverseGet,
              ),
              pipe(
                end,
                dateAsTime.composePrism(timeAsLocalTimeOfDayInCurrentEnv())
                  .reverseGet,
              ),
            ] as [Date, Date],
        ),
      ),
      ...a,
    })),
    activityHabits: pipe(state, habits.compose(Habits._activity).get),
    eatingHabits: pipe(state, habits.compose(Habits._eating).get),
    drinkingHabits: pipe(state, habits.compose(Habits._drinking).get),
    alcoholHabits: pipe(state, habits.compose(Habits._consumptionAlcohol).get),
    smokingHabits: pipe(state, habits.compose(Habits._consumptionSmoking).get),
  }
  return { defaultValues }
}

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ =
    (act: Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, Func.constVoid)

  return {
    onSubmit: ({
      work,
      sleep,
      activityHabits,
      eatingHabits,
      drinkingHabits,
      alcoholHabits,
      smokingHabits,
    }: FormData) =>
      pipe(
        Arr.Traversable.traverse(IO.Applicative)(
          [
            setWorkAndLifestyle({
              work: pipe(
                work,
                ({ time, lunchtime: { duration, within }, ...a }) => ({
                  time: pipe(
                    time,
                    Rec.map(
                      Opt.map(
                        ([start, end]) =>
                          [
                            pipe(
                              start,
                              dateAsTime.composePrism(
                                timeAsLocalTimeOfDayInCurrentEnv(),
                              ).getOption,
                              unsafeFromSome,
                            ),
                            pipe(
                              end,
                              dateAsTime.composePrism(
                                timeAsLocalTimeOfDayInCurrentEnv(),
                              ).getOption,
                              unsafeFromSome,
                            ),
                          ] as [LocalTimeOfDay, LocalTimeOfDay],
                      ),
                    ),
                  ),
                  lunchtime: {
                    duration: pipe(
                      duration,
                      isoStringAsLocalTimeOfDayDuration.getOption,
                      unsafeFromSome,
                    ),
                    within: [
                      pipe(
                        within[0],
                        dateAsTime.composePrism(
                          timeAsLocalTimeOfDayInCurrentEnv(),
                        ).getOption,
                        unsafeFromSome,
                      ),
                      pipe(
                        within[1],
                        dateAsTime.composePrism(
                          timeAsLocalTimeOfDayInCurrentEnv(),
                        ).getOption,
                        unsafeFromSome,
                      ),
                    ] as [LocalTimeOfDay, LocalTimeOfDay],
                  },
                  ...a,
                }),
                Opt.some,
              ),
              sleep: pipe(
                sleep,
                ({ time, ...a }) => ({
                  time: pipe(
                    time,
                    Rec.map(
                      ([start, end]) =>
                        [
                          pipe(
                            start,
                            dateAsTime.composePrism(
                              timeAsLocalTimeOfDayInCurrentEnv(),
                            ).getOption,
                            unsafeFromSome,
                          ),
                          pipe(
                            end,
                            dateAsTime.composePrism(
                              timeAsLocalTimeOfDayInCurrentEnv(),
                            ).getOption,
                            unsafeFromSome,
                          ),
                        ] as [LocalTimeOfDay, LocalTimeOfDay],
                    ),
                  ),
                  ...a,
                }),
                Opt.some,
              ),
              habits: {
                activity: activityHabits,
                eating: eatingHabits,
                drinking: drinkingHabits,
                consumption: {
                  alcohol: alcoholHabits,
                  smoking: smokingHabits,
                },
              },
            }),
          ],
          dispatch_,
        ),
        IO.map(Func.constVoid),
      )(),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type OwnProps = {
  id: string
}

export type WorkAndLifestylePageProps = PropsFromRedux & OwnProps

export const WorkAndLifestylePage: FC<WorkAndLifestylePageProps> = ({
  id,
  defaultValues,
  onSubmit,
}) => {
  const { handleSubmit, ...form } = useForm<FormData>({
    defaultValues,
  })

  useEffect(() => {
    if (!form.formState.isDirty) {
      form.reset(defaultValues)
    }
  }, [defaultValues])

  const submit = handleSubmit(onSubmit)
  useFormChangeEffect(form, formData, submit)

  return (
    <MainTemplate
      header={
        <Header
          icon={<FaUser />}
          title={<FormattedMessage defaultMessage="Work & lifestyle" />}
        />
      }
    >
      <Content>
        <FormProvider {...{ handleSubmit }} {...form}>
          <form onSubmit={submit}>
            <Fieldset>
              <Row>
                <Col md={12} lg={8}>
                  <FormattedMessage defaultMessage="Knowing how your typical day look like and how your job is physicaly and mentally demanding is important for Fitnesspilot to prepare optimal recommendations for you." />
                </Col>
              </Row>

              <Fieldset>
                <WorkFieldsetContainer id={`${id}-work`} showAnswersProvided />
              </Fieldset>

              <Fieldset>
                <SleepFieldset id={`${id}-sleep`} showAnswersProvided />
              </Fieldset>

              <Fieldset>
                <ActivityHabitsFieldset
                  id={`${id}-activityHabits`}
                  showAnswersProvided
                />
              </Fieldset>

              <Fieldset>
                <FoodHabitsFieldset
                  id={`${id}-foodHabits`}
                  showAnswersProvided
                />
              </Fieldset>

              <Fieldset>
                <AddictionHabitsFieldset
                  id={`${id}-addictionHabits`}
                  showAnswersProvided
                />
              </Fieldset>
            </Fieldset>
          </form>
        </FormProvider>
      </Content>
    </MainTemplate>
  )
}

export const WorkAndLifestylePageContainer = connector(WorkAndLifestylePage)

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from "../runtime";
import { VideoFromJSON, VideoToJSON } from "./Video";
/**
 * Check if a given object implements the WorkoutVideo interface.
 */
export function instanceOfWorkoutVideo(value) {
    let isInstance = true;
    isInstance = isInstance && "video" in value;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
export function WorkoutVideoFromJSON(json) {
    return WorkoutVideoFromJSONTyped(json, false);
}
export function WorkoutVideoFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        video: VideoFromJSON(json.video),
        title: !exists(json, "title") ? undefined : json.title,
        id: json.id,
    };
}
export function WorkoutVideoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        video: VideoToJSON(value.video),
        title: value.title,
        id: value.id,
    };
}

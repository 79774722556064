import * as Eq from "fp-ts/es6/Eq"
import * as Opt from "fp-ts/es6/Option"
import * as Str from "fp-ts/es6/string"
import { Lens } from "monocle-ts"
import { emailAddress } from "@fitnesspilot/data-common"

import { UserLogin } from "./UserLogin"

export type UserRegistration = UserLogin & {
  inviteCode: Opt.Option<string>
}
export const userRegistration = Eq.struct<UserRegistration>({
  email: emailAddress,
  password: Str.Eq,
  inviteCode: Opt.getEq(Str.Eq),
})
export { _email, _password } from "./UserLogin"
export const _inviteCode = Lens.fromProp<UserRegistration>()("inviteCode")

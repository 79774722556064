/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const Importance = {
    Info: "info",
    Optional: "optional",
    Recommended: "recommended",
    Important: "important",
};
export function ImportanceFromJSON(json) {
    return ImportanceFromJSONTyped(json, false);
}
export function ImportanceFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ImportanceToJSON(value) {
    return value;
}

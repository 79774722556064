import * as Bool from "fp-ts/es6/boolean"
import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import {
  currentTimeZone,
  day,
  isoStringAsTime,
  localTimeAsDayAndLocalTimeOfDayPair,
  timeAsLocalTime,
  today,
} from "time-ts/es6"

import type _firebase from "firebase/compat"

export const titleWithVariables = (
  notification: _firebase.messaging.NotificationPayload,
  data: _firebase.messaging.MessagePayload["data"],
): string =>
  pipe(
    data?.time,
    Opt.fromNullable,
    Opt.chain(isoStringAsTime.getOption),
    Opt.map((time) =>
      pipe(
        time,
        timeAsLocalTime(currentTimeZone()).compose(
          localTimeAsDayAndLocalTimeOfDayPair,
        ).get,
        ([d, tod]) =>
          pipe(
            day.equals(d, today()),
            Bool.fold(
              () => time.toLocaleString(),
              () => tod.toLocaleString(),
            ),
          ),
      ),
    ),
    Opt.fold(
      () => notification.title,
      (time) => notification.title?.replace("{time}", time),
    ),
    (t) => t ?? "",
  )

export const handleMessage =
  (showNotification: ServiceWorkerRegistration["showNotification"]) =>
  (payload: _firebase.messaging.MessagePayload) => {
    console.log("Message received. ", payload)
    const notification = payload.notification
    if (notification) {
      void showNotification(titleWithVariables(notification, payload.data), {
        ...notification,
        icon: "/favicon.ico",
        badge: "/favicon.ico",
      })
    }
  }

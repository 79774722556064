import { FaCog } from "react-icons/fa"
import { Link } from "react-router-dom"
import { Button } from "reactstrap"

import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"

import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import {
  MuscleGroupId,
  muscleGroupIdAsMuscleGroups,
} from "@fitnesspilot/data-human-body/dist/muscleGroups"

import { MusclePreferencesFieldsets } from "../MusclePreferencesFieldsets/MusclePreferencesFieldsets"

export { FormData } from "../MusclePreferenceFieldset/MusclePreferenceFieldset"

export type MuscleGroupPreferencesFieldsetsProps = {
  id: string
  groupLevels?: Rec.ReadonlyRecord<MuscleGroupId, number | false>
  className?: string
}

export const MuscleGroupPreferencesFieldsets = ({
  id,
  groupLevels,
  className,
}: MuscleGroupPreferencesFieldsetsProps) => {
  return (
    <Fieldset {...{ className }}>
      {pipe(
        Object.values(MuscleGroupId),
        Arr.map((muscleGroupId) => (
          <MusclePreferencesFieldsets
            key={muscleGroupId}
            id={`${id}-${muscleGroupId}-musclePreferences`}
            {...{ muscleGroupId }}
            groupLevel={pipe(
              groupLevels,
              Opt.fromNullable,
              Opt.chain(Rec.lookup(muscleGroupId)),
              Opt.toUndefined,
            )}
            showGroupOnly
            groupRight={
              <Button
                tag={Link}
                color="secondary"
                to={`/sports/muscle-preferences/${muscleGroupIdAsMuscleGroups.get(
                  muscleGroupId,
                )}`}
              >
                <FaCog />
              </Button>
            }
          />
        )),
      )}
    </Fieldset>
  )
}

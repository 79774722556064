import { ComponentProps, memo } from "react"
import { Popover as Popover_, PopoverProps as PopoverProps_ } from "reactstrap"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

export {
  PopoverBody,
  PopoverBodyProps,
  PopoverHeader,
  PopoverHeaderProps,
} from "reactstrap"

export type PopoverProps = ComponentProps<typeof Popover>

export const Popover = memo(styled((props: PopoverProps_) => (
  <Popover_ container="#popover-container" {...props} />
))<{ mobileFullWidth?: boolean }>`
  ${({ mobileFullWidth }) =>
    mobileFullWidth &&
    css`
      & > .popover {
        @media not screen and (min-width: 576px) {
          position: fixed !important;
          transform: initial !important;
          right: 0;
          margin: 1.75rem auto;

          & .arrow {
            display: none;
          }

          & .popover-body {
            max-height: calc(100vh - 36px - 3.5rem);
            overflow-y: auto;
            overflow-x: hidden;
          }
        }
      }
    `}
`)

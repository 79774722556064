import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import { dayOfTime, now, startOfDay, Time } from "time-ts/es6"

import { addDays } from "@fitnesspilot/data-common/dist/time"

export const preCreationHistoryWeeks = 4
export const newEventHistoryWeeks = 4
export const timeslotHistoryWeeks = 4
export const timeslotFutureWeeks = 4

const getBoundIO =
  (weeks: number, temporal: "history" | "future") =>
  (relativeTo: IO.IO<Time>): IO.IO<Time> =>
  () =>
    pipe(
      relativeTo(),
      dayOfTime.get,
      addDays((temporal === "history" ? -7 : 7) * weeks),
      startOfDay,
    )

export const maxPreCreationHistory = (relativeTo: Time) =>
  getBoundIO(preCreationHistoryWeeks, "history")(() => relativeTo)
export const maxNewEventHistory = getBoundIO(
  newEventHistoryWeeks,
  "history",
)(now)
export const maxTimeslotHistory = getBoundIO(
  timeslotHistoryWeeks,
  "history",
)(now)
export const maxTimeslotFuture = getBoundIO(timeslotFutureWeeks, "future")(now)

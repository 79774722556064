import { FC, ReactNode } from "react"
import {
  Col,
  FormGroup as BsFormGroup,
  FormGroupProps as BsFormGroupProps,
} from "reactstrap"

/* import styled from "@emotion/styled" */
import { Label } from "../../atoms/Label/Label"

export type FormGroupProps = {
  inputId: string
  label: ReactNode
  children: ReactNode
  fullWidth?: boolean
} & Omit<BsFormGroupProps, "id" | "children" | "row">

export const FormGroup: FC<FormGroupProps> = ({
  inputId,
  label,
  children,
  fullWidth = false,
  ...props
}) => (
  <BsFormGroup {...props} row>
    <Label for={inputId} xs={5} md={fullWidth ? 5 : 3}>
      {label}
    </Label>

    <Col xs={7}>{children}</Col>
  </BsFormGroup>
)

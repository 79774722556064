import { FC } from "react"

import {
  stringAsNumber,
  ValidNumber,
} from "@fitnesspilot/data-common/dist/number"

import { input, InputProps, inputStepper, InputStepperProps } from "./input"

export type NumberInputProps = InputProps<ValidNumber, ValidNumber>

export const NumberInput: FC<NumberInputProps> = input(
  "number",
  stringAsNumber,
  stringAsNumber.reverseGet,
)

export type NumberInputStepperProps = InputStepperProps<
  ValidNumber,
  ValidNumber
>

export const NumberInputStepper: FC<NumberInputStepperProps> = inputStepper(
  "number",
  stringAsNumber,
  stringAsNumber.reverseGet,
)

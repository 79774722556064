import * as Eq from "fp-ts/es6/Eq"
import * as Opt from "fp-ts/es6/Option"
import * as Str from "fp-ts/es6/string"

export type ErrorForUser = Error & {
  userMessage?: string
  detailedUserMessage?: string
}

export type UserError = {
  error: ErrorForUser
  sentryEventId: Opt.Option<string>
}

const eqUndefString = Eq.fromEquals<string | undefined>((a, b) => a === b)

export const errorForUser = Eq.struct<ErrorForUser>({
  name: Str.Eq,
  message: Str.Eq,
  stack: eqUndefString,
  userMessage: eqUndefString,
  detailedUserMessage: eqUndefString,
})

export const userError = Eq.struct<UserError>({
  error: errorForUser,
  sentryEventId: Opt.getEq(Str.Eq),
})

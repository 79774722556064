import { FC, Fragment, memo, ReactElement, RefObject } from "react"
import { FormattedMessage } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import { FormGroup } from "reactstrap"
import styled from "@emotion/styled"

import { constant, constVoid, flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { fromTraversable, Traversal } from "monocle-ts"
import {
  addLocalDuration,
  compareLocalTime,
  currentTimeZone,
  dayOfLocalTime,
  dayOfTime,
  localTimeAsDayAndLocalTimeOfDayPair,
  localTimeOfDayOfLocalTime,
  mkLocalDuration,
  mkLocalTimeOfDayDuration,
  Time,
  timeAsLocalTime,
} from "time-ts/es6"

import { DayInput } from "@fitnesspilot/components-common/dist/atoms/input/DayInput"
import { LocalTimeOfDayInput } from "@fitnesspilot/components-common/dist/atoms/input/LocalTimeOfDayInput"
import {
  Popover as Popover_,
  PopoverBody as PopoverBody_,
  PopoverHeader,
} from "@fitnesspilot/components-common/dist/atoms/Popover/Popover"
import { normaliseInterval } from "@fitnesspilot/data-common/dist/time"
import {
  Action,
  ParentState,
  selectors,
  setEventBetween,
  setEventRecurrence,
} from "@fitnesspilot/data-event"
import * as EditingEvent from "@fitnesspilot/data-event/dist/store/EditingEvent"

import { RecurrenceInput } from "../../molecules/RecurrenceInput/RecurrenceInput"

import { Dispatch } from "redux"

const traversalPair = fromTraversable(Arr.Traversable) as any as <
  a,
>() => Traversal<readonly [a, a], a>

const mapState = (state: ParentState) => ({
  event: pipe(
    state,
    selectors.state.composeOptional(selectors.editingEventValOpt).getOption,
  ),
})

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ =
    (act: Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, constVoid)

  return {
    onSetBetween: flow(setEventBetween, dispatch_),
    onSetRecurrence: flow(setEventRecurrence, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

export type EventTimeModalProps = PropsFromRedux & {
  id: string
  className?: string
  isOpen: boolean
  target: HTMLElement | RefObject<HTMLElement>
  onSetIsOpen: (isOpen: boolean) => IO.IO<void>
}

const flippedSet =
  <s, a>(l: { set: (a: a) => (s: s) => s }) =>
  (s: s) =>
  (a: a) =>
    l.set(a)(s)

const Popover = styled(Popover_)`
  & > .popover {
    max-width: calc(100vw - 10px);
  }
`

const PopoverBody = styled(PopoverBody_)`
  width: 360px;
  max-width: calc(100vw - 10px);
`

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const EventTimeModal_: FC<EventTimeModalProps> = ({
  id,
  isOpen,
  target,
  event,
  onSetIsOpen,
  onSetBetween,
  onSetRecurrence,
  className,
}) =>
  pipe(
    event,
    Opt.map((event) => (
      <Popover
        key={1}
        toggle={() => onSetIsOpen(!isOpen)()}
        {...{ isOpen, target, className }}
      >
        <PopoverHeader>
          <FormattedMessage defaultMessage="Event schedule" />
        </PopoverHeader>

        <PopoverBody>
          <FormGroup>
            <FormattedMessage defaultMessage="Date:" />

            <DayInput
              hideClear
              min={Opt.none}
              max={Opt.none}
              step={Opt.none}
              value={pipe(
                event,
                EditingEvent._start
                  .composeIso(timeAsLocalTime(currentTimeZone()))
                  .composeLens(dayOfLocalTime).get,
                Opt.some,
              )}
              onChange={flow(
                Opt.map((day) =>
                  pipe(
                    event,
                    EditingEvent._between.get,
                    traversalPair<Time>()
                      .composeIso(timeAsLocalTime(currentTimeZone()))
                      .composeLens(dayOfLocalTime)
                      .set(day),
                    normaliseInterval(currentTimeZone()),
                    onSetBetween,
                  ),
                ),
                Opt.getOrElse(() => IO.of<void>(undefined)),
              )}
            />
          </FormGroup>

          <FormGroup>
            <TimeContainer>
              {([EditingEvent._start, EditingEvent._end] as const).map(
                (baseLens, i) => {
                  const lens = baseLens.composeIso(
                    timeAsLocalTime(currentTimeZone()),
                  )
                  const startTime = event.between[0]
                  const localStartTime = timeAsLocalTime(currentTimeZone()).get(
                    startTime,
                  )
                  const startDay = dayOfTime.get(startTime)
                  const isEndTime = i === 1

                  return (
                    <Fragment key={i}>
                      <LocalTimeOfDayInput
                        hideClear={true}
                        min={Opt.none}
                        max={Opt.none}
                        step={Opt.some(mkLocalTimeOfDayDuration(0, 1, 0))}
                        value={pipe(
                          event,
                          lens.composeLens(localTimeOfDayOfLocalTime).get,
                          Opt.some,
                        )}
                        onChange={(tod) =>
                          pipe(
                            event,
                            baseLens.set(
                              pipe(
                                tod,
                                Opt.fold(constant(startTime), (tod) =>
                                  pipe(
                                    localTimeAsDayAndLocalTimeOfDayPair.reverseGet(
                                      [startDay, tod],
                                    ),
                                    (localTime) =>
                                      isEndTime &&
                                      compareLocalTime(localTime)(
                                        localStartTime,
                                      ) == 1
                                        ? addLocalDuration(
                                            mkLocalDuration({ days: 1 }),
                                          )(localTime)
                                        : localTime,
                                    timeAsLocalTime(currentTimeZone())
                                      .reverseGet,
                                  ),
                                ),
                              ),
                            ),
                            EditingEvent._between.get,
                            normaliseInterval(currentTimeZone()),
                            onSetBetween,
                          )
                        }
                      />
                      {i === 0 && <span>—</span>}
                    </Fragment>
                  )
                },
              )}
            </TimeContainer>
          </FormGroup>

          <FormGroup>
            <FormattedMessage defaultMessage="Recurrence:" />

            <RecurrenceInput
              recurrence={event.recurrence}
              onChange={onSetRecurrence}
              start={event.between[0]}
            />
          </FormGroup>
        </PopoverBody>
      </Popover>
    )),
    Opt.getOrElse<ReactElement | null>(() => null),
  )

export const EventTimeModal = memo(EventTimeModal_)
export const EventTimeModalContainer = connector(EventTimeModal)

import { ReadonlyNonEmptyArray } from "fp-ts/es6/ReadonlyNonEmptyArray"

import { AnyAPIError } from "@fitnesspilot/data-api"

import { CoachTask } from "../CoachTask"

import { ActionType, createAction, createAsyncAction } from "typesafe-actions"

export const resetState = createAction("coachTask/resetState", () => ({}))()

export const fetchCoachTasksAsync = createAsyncAction(
  "coachTask/fetchCoachTasksAsync/request",
  "coachTask/fetchCoachTasksAsync/success",
  "coachTask/fetchCoachTasksAsync/failure",
)<void, ReadonlyArray<CoachTask>, AnyAPIError>()
export const fetchCoachTasks = fetchCoachTasksAsync.request

export const dismissCoachTaskAsync = createAsyncAction(
  "coachTask/dismissCoachTaskAsync/request",
  "coachTask/dismissCoachTaskAsync/success",
  "coachTask/dismissCoachTaskAsync/failure",
)<CoachTask, CoachTask, AnyAPIError>()
export const dismissCoachTask = dismissCoachTaskAsync.request

export const dismissCoachTasksAsync = createAsyncAction(
  "coachTask/dismissCoachTasksAsync/request",
  "coachTask/dismissCoachTasksAsync/success",
  "coachTask/dismissCoachTasksAsync/failure",
)<
  ReadonlyNonEmptyArray<CoachTask>,
  ReadonlyNonEmptyArray<CoachTask>,
  AnyAPIError
>()
export const dismissCoachTasks = dismissCoachTasksAsync.request

export const confirmCoachTaskAsync = createAsyncAction(
  "coachTask/confirmCoachTaskAsync/request",
  "coachTask/confirmCoachTaskAsync/success",
  "coachTask/confirmCoachTaskAsync/failure",
)<CoachTask, CoachTask, AnyAPIError>()
export const confirmCoachTask = confirmCoachTaskAsync.request

export const cancelEventConfirmation = createAction(
  "coachTask/cancelEventConfirmation",
  () => ({}),
)()

export const setEventConfirmationIsOpen = createAction(
  "coachTask/setEventConfirmationIsOpen",
  (isOpen: boolean) => ({ isOpen }),
)()

export type Action = ActionType<
  | typeof resetState
  | (typeof fetchCoachTasksAsync)["request" | "success" | "failure"]
  | (typeof dismissCoachTaskAsync)["request" | "success" | "failure"]
  | (typeof dismissCoachTasksAsync)["request" | "success" | "failure"]
  | (typeof confirmCoachTaskAsync)["request" | "success" | "failure"]
  | typeof cancelEventConfirmation
  | typeof setEventConfirmationIsOpen
>

import { FC, useEffect, useRef, useState } from "react"
import { FaCheck, FaPen } from "react-icons/fa"
import { FormattedMessage, useIntl } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import { Link } from "react-router-dom"
import { Button, CardFooter, Col, Row } from "reactstrap"
import styled from "@emotion/styled"

import * as Bool from "fp-ts/es6/boolean"
import { constFalse, constNull, flow, pipe } from "fp-ts/es6/function"
import * as Func from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as Semigroup from "fp-ts/es6/Semigroup"
import * as Str from "fp-ts/es6/string"
import { Getter } from "monocle-ts"
import {
  dateAsTime,
  day,
  dayOfTime,
  isoStringAsTime,
  now,
  Time,
  time,
} from "time-ts/es6"
import {
  Dimension,
  filterMapKeys,
  liftGetterEq,
  numberAsValidNumber,
  prismToGetter,
  UnitNoDim,
  valueInUnit,
  ValueWithUnit,
} from "@fitnesspilot/data-common"

import { Info } from "@fitnesspilot/components-common/dist/atoms/Info/Info"
import {
  Popover,
  PopoverBody,
  PopoverHeader,
} from "@fitnesspilot/components-common/dist/atoms/Popover/Popover"
import {
  ButtonWithIcon,
  IconSide,
} from "@fitnesspilot/components-common/dist/molecules/ButtonWithIcon/ButtonWithIcon"
import { DeleteButton } from "@fitnesspilot/components-common/dist/molecules/DeleteButton/DeleteButton"
import { BodyMap } from "@fitnesspilot/components-human-body/dist/organisms/BodyMap/BodyMap"
import * as Event from "@fitnesspilot/data-event/dist/calendar/Event"
import * as EventOrRecommendation from "@fitnesspilot/data-event/dist/calendar/EventOrRecommendation"
import * as Recommendation from "@fitnesspilot/data-event/dist/calendar/Recommendation"
import * as Timeslot from "@fitnesspilot/data-event/dist/calendar/Timeslot"
import { maxNewEventHistory } from "@fitnesspilot/data-event/dist/calendarBounds"
import * as EventData from "@fitnesspilot/data-event/dist/store"
import {
  Action,
  confirmEvent,
  startEditingEvent,
  startEditingEventById,
} from "@fitnesspilot/data-event/dist/store"
import * as CalendarSelection from "@fitnesspilot/data-event/dist/store/CalendarSelection"
import * as EditingEvent from "@fitnesspilot/data-event/dist/store/EditingEvent"
import { Dorsoventral } from "@fitnesspilot/data-human-body/dist/anatomicalAxes"
import * as HumanBody from "@fitnesspilot/data-human-body/dist/humanBody"
import * as MuscleGroup from "@fitnesspilot/data-human-body/dist/muscleGroups"
import * as Sex from "@fitnesspilot/data-human-body/dist/sex"
import * as UserData from "@fitnesspilot/data-user/dist/store"

import { EventSummary } from "../../molecules/EventSummary/EventSummary"

import { Dispatch } from "redux"
import { push as pushHistory, RouterActions } from "redux-first-history"

const timeRangeAsString = new Getter<readonly [Time, Time], string>(
  ([start, end]) =>
    `${isoStringAsTime.reverseGet(start)}/${isoStringAsTime.reverseGet(end)}`,
)

const firstTimeslotBetween = (
  timeslots: ReadonlyArray<Timeslot.Timeslot>,
  between: readonly [Time, Time],
) =>
  pipe(
    timeslots,
    Arr.filter((t) =>
      pipe(t, Timeslot._time.get, Ord.between(time)(between[0], between[1])),
    ),
    NonEmpty.fromReadonlyArray,
    Opt.map(NonEmpty.head),
  )

const mapState = (state: EventData.ParentState & UserData.ParentState) => ({
  events: EventData.selectors.state
    .compose(EventData.selectors.eventsOrRecommendations)
    .get(state),
  timeslots: EventData.selectors.state
    .composeLens(EventData.selectors.timeslots)
    .get(state),
  sex: UserData.selectors.state
    .composeLens(UserData.selectors.body)
    .composeLens(HumanBody.sex)
    .get(state),
  selection: EventData.selectors.state
    .composeLens(EventData.selectors.calendarSelection)
    .get(state),
})

const mapDispatch = (dispatch: Dispatch<Action | RouterActions>) => {
  const dispatch_ =
    (act: Action | RouterActions): IO.IO<void> =>
    () =>
      pipe(act, dispatch, Func.constVoid)

  return {
    onStartEditingEventOrRecommendation:
      EventOrRecommendation.foldEventOrRecommendation(
        flow(
          Event._eventWithId._id.get,
          (eventId) => ({ eventId }),
          startEditingEventById,
          dispatch_,
        ),
        () => IO.of(undefined),
      ),
    onCreateEventFromRec: flow(startEditingEvent, dispatch_),
    onConfirmEvent: flow(confirmEvent, dispatch_),
    onMuscleGroupClick: (muscleGroup: MuscleGroup.MuscleGroups) =>
      pipe(pushHistory(`/sports/muscle-preferences/${muscleGroup}`), dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type OwnProps = {
  target: HTMLElement
  onDeleteEvent: (event: Event.EventWithId) => IO.IO<void>
  onClose: IO.IO<void>
}

export type CalendarEventSelectionProps = PropsFromRedux & OwnProps

const BodyMapRow = styled(Row)`
  flex-wrap: nowrap;
`

export const CalendarEventSelection: FC<CalendarEventSelectionProps> = ({
  timeslots,
  sex,
  selection,
  onStartEditingEventOrRecommendation,
  onClose,
  onCreateEventFromRec,
  onConfirmEvent,
  onMuscleGroupClick,
  target,
  onDeleteEvent,
}) => {
  const [openMuscleGroupPopover, setOpenMuscleGroupPopover] =
    useState<MuscleGroup.MuscleGroups>()

  const popoverRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const listener = (ev: MouseEvent) =>
      popoverRef.current?.contains(ev.target as HTMLElement) == false &&
      onClose()
    document.addEventListener("mouseup", listener)
    return () => document.removeEventListener("mouseup", listener)
  }, [popoverRef])

  const intl = useIntl()

  return pipe(
    selection,
    Opt.fold(
      () => null,
      CalendarSelection.fold(
        (evt) => {
          const isInPast = pipe(
            IO.Do,
            IO.bind("end", () =>
              pipe(
                evt,
                Event._eventWithId._value.composeLens(Event._end).get,
                IO.of,
              ),
            ),
            IO.bind("now", () => now),
            IO.map(({ now, end }) => Ord.gt(time)(now, end)),
          )()
          const isConfirmable =
            isInPast &&
            pipe(
              evt,
              Event._eventWithId._value.composeLens(Event._confirmed).get,
              Opt.isNone,
            )

          const timeslot = firstTimeslotBetween(timeslots, evt.value.between)

          return (
            <Popover
              mobileFullWidth
              placement="right"
              isOpen={true}
              target={target}
              toggle={onClose}
              key={pipe(
                evt,
                Event._eventWithId._id.composeGetter(
                  prismToGetter(Event.stringAsEventId),
                ).get,
              )}
            >
              <div ref={popoverRef}>
                <PopoverHeader>
                  {pipe(
                    evt,
                    Event._eventWithId._value.composeLens(Event._title).get,
                  )}
                </PopoverHeader>
                <PopoverBody>
                  {pipe(
                    timeslot,
                    Opt.fold(constFalse, Timeslot._outdated.get),
                    Bool.fold(constNull, () => (
                      <Info
                        tooltipContent={
                          <FormattedMessage defaultMessage="The alignments for this event were outdated by changes you made to an event in the past. We only update the simulation for recent & future events." />
                        }
                      >
                        <FormattedMessage defaultMessage="Outdated data." />
                      </Info>
                    )),
                  )}

                  <EventSummary
                    event={pipe(evt, Event._eventWithId._value.get)}
                    activityLimit={3}
                  />
                </PopoverBody>

                <CardFooter style={{ display: "flex", alignItems: "center" }}>
                  {isConfirmable && (
                    <ButtonWithIcon
                      color="success"
                      icon={<FaCheck />}
                      iconSide={IconSide.right}
                      onClick={onConfirmEvent(evt.id)}
                    >
                      <FormattedMessage defaultMessage="Confirm" />
                    </ButtonWithIcon>
                  )}

                  <ButtonWithIcon
                    tag={Link}
                    icon={<FaPen />}
                    iconSide={IconSide.right}
                    to={`/calendar/events/${pipe(
                      evt,
                      Event._eventWithId._id.composeGetter(
                        prismToGetter(Event.stringAsEventId),
                      ).get,
                    )}`}
                    color={isConfirmable ? "link" : "primary"}
                    onClick={onStartEditingEventOrRecommendation(
                      EventOrRecommendation._EventWithId.reverseGet(evt),
                    )}
                  >
                    <FormattedMessage defaultMessage="Edit" />
                  </ButtonWithIcon>

                  <DeleteButton
                    onClick={onDeleteEvent(evt)}
                    title={intl.formatMessage({
                      defaultMessage: "Delete event",
                    })}
                  />
                </CardFooter>
              </div>
            </Popover>
          )
        },
        (rec) => (
          <Popover
            mobileFullWidth
            placement="right"
            isOpen={true}
            target={target}
            toggle={onClose}
            key={pipe(
              rec,
              Recommendation._between.composeGetter(timeRangeAsString).get,
            )}
          >
            <div ref={popoverRef}>
              <PopoverHeader>Activity</PopoverHeader>
              <PopoverBody>
                {/* @TODO indicate loading */}
                <Button
                  type="button"
                  onClick={onStartEditingEventOrRecommendation(
                    EventOrRecommendation._Recommendation.reverseGet(rec),
                  )}
                  color="primary"
                >
                  <FormattedMessage defaultMessage="Create event" />
                </Button>
              </PopoverBody>
            </div>
          </Popover>
        ),
        (evt) => (
          <Popover
            mobileFullWidth
            placement="right"
            isOpen={true}
            target={target}
            toggle={onClose}
            key={pipe(evt, Event._between.composeGetter(timeRangeAsString).get)}
          >
            <div ref={popoverRef}>
              <PopoverHeader>{pipe(evt, Event._title.get)}</PopoverHeader>
              <PopoverBody>
                <EventSummary event={evt}>
                  <Button
                    tag={Link}
                    to="/calendar/events/new"
                    color="primary"
                    onClick={pipe(
                      evt,
                      EditingEvent.eventAsEditingEvent.get,
                      EditingEvent._title.set(Opt.none),
                      (value) => ({ id: Opt.none, value }),
                      onCreateEventFromRec,
                    )}
                  >
                    <FormattedMessage defaultMessage="Create event" />
                  </Button>
                </EventSummary>
              </PopoverBody>
            </div>
          </Popover>
        ),
        (between) => {
          const timeslot = firstTimeslotBetween(timeslots, between)

          const isWithinNewEventHistory = Ord.geq(time)(
            between[0],
            maxNewEventHistory(),
          )

          return (
            <Popover
              mobileFullWidth
              key={pipe(between, timeRangeAsString.get)}
              placement="right"
              isOpen={true}
              target={target}
              toggle={onClose}
            >
              <div ref={popoverRef}>
                <PopoverHeader>
                  {pipe(
                    liftGetterEq(dayOfTime.asGetter())(day).equals(
                      between[0],
                      between[1],
                    ),
                    Bool.fold(
                      () => (
                        <FormattedMessage
                          defaultMessage="{start, date, ::yMdHm}–{end, date, ::yMdHm}"
                          values={{
                            start: pipe(between[0], dateAsTime.reverseGet),
                            end: pipe(between[1], dateAsTime.reverseGet),
                          }}
                        />
                      ),
                      () => (
                        <FormattedMessage
                          defaultMessage="{start, date, ::yMdHm}–{end, date, ::Hm}"
                          values={{
                            start: pipe(between[0], dateAsTime.reverseGet),
                            end: pipe(between[1], dateAsTime.reverseGet),
                          }}
                        />
                      ),
                    ),
                  )}
                </PopoverHeader>

                <PopoverBody>
                  {pipe(
                    timeslot,
                    Opt.fold(constFalse, Timeslot._outdated.get),
                    Bool.fold(constNull, () => (
                      <Info
                        tooltipContent={
                          <FormattedMessage defaultMessage="This data was outdated by changes you made to an event in the past. We only update the simulation for recent & future events." />
                        }
                      >
                        <FormattedMessage defaultMessage="Outdated data." />
                      </Info>
                    )),
                  )}

                  <BodyMapRow>
                    {pipe(
                      timeslot,
                      Opt.fold(
                        () => null,
                        (t) =>
                          pipe(
                            {
                              [Dorsoventral.dorsal]: t,
                              [Dorsoventral.ventral]: t,
                            },
                            Rec.map(Timeslot._recoveryStatus.get),
                            Rec.mapWithIndex((i, v) =>
                              pipe(
                                v,
                                filterMapKeys(
                                  Semigroup.last<
                                    ValueWithUnit<
                                      Dimension.noDim,
                                      UnitNoDim.percent
                                    >
                                  >(),
                                  (k) =>
                                    ({
                                      // TS somehow doesn't know that DorsalMuscleGroups
                                      // and VentralMuscleGroups are a subtype of
                                      // MuscleGroups (which is literally the sum of them)
                                      [Dorsoventral.dorsal]:
                                        MuscleGroup._DorsalMuscleGroups.composeGetter(
                                          prismToGetter(
                                            MuscleGroup._DorsalMuscleGroups,
                                          ),
                                        ),
                                      [Dorsoventral.ventral]:
                                        MuscleGroup._VentralMuscleGroups.composeGetter(
                                          prismToGetter(
                                            MuscleGroup._VentralMuscleGroups,
                                          ),
                                        ),
                                    })[i].headOption(k),
                                ),
                              ),
                            ),
                            Rec.collect(Str.Ord)((i, vs) => (
                              <Col key={i} md={6}>
                                <BodyMap
                                  key={i}
                                  dorsoventralSide={i}
                                  muscleGroupLevels={pipe(
                                    vs,
                                    Rec.map(
                                      numberAsValidNumber.composeIso(
                                        valueInUnit(
                                          Dimension.noDim,
                                          UnitNoDim.percent,
                                        ),
                                      ).reverseGet,
                                    ),
                                  )}
                                  openMuscleGroupPopover={
                                    openMuscleGroupPopover as any
                                  }
                                  onMuscleGroupClick={(
                                    muscleGroup: MuscleGroup.MuscleGroups,
                                  ) => onMuscleGroupClick(muscleGroup)()}
                                  toggleMuscleGroupPopover={
                                    ((
                                      muscleGroup: MuscleGroup.MuscleGroups,
                                      force: boolean,
                                    ) =>
                                      force === false
                                        ? openMuscleGroupPopover === muscleGroup
                                          ? setOpenMuscleGroupPopover(undefined)
                                          : undefined
                                        : setOpenMuscleGroupPopover(
                                            muscleGroup,
                                          )) as any
                                  }
                                  sex={pipe(
                                    sex,
                                    Opt.getOrElse((): Sex.Sex => Sex.Sex.male),
                                  )}
                                />
                              </Col>
                            )),
                          ),
                      ),
                    )}
                  </BodyMapRow>
                </PopoverBody>

                <CardFooter>
                  {pipe(
                    isWithinNewEventHistory,
                    Bool.fold(
                      () => (
                        <FormattedMessage defaultMessage="You cannot create new events this far in the past." />
                      ),
                      () => (
                        <Button
                          tag={Link}
                          to={`/calendar/events/new?start=${pipe(
                            between[0],
                            isoStringAsTime.reverseGet,
                            encodeURIComponent,
                          )}&end=${pipe(
                            between[1],
                            isoStringAsTime.reverseGet,
                            encodeURIComponent,
                          )}`}
                          color="primary"
                        >
                          <FormattedMessage defaultMessage="Create event" />
                        </Button>
                      ),
                    ),
                  )}
                </CardFooter>
              </div>
            </Popover>
          )
        },
      ),
    ),
  )
}

export const CalendarEventSelectionContainer = connector(CalendarEventSelection)

import { FC, Fragment } from "react"
import { css, Global } from "@emotion/react"
import styled from "@emotion/styled"

import djIcons from "./dj.woff2"

const DjIcons = () => (
  <Global
    styles={css`
      @font-face {
        font-family: "dj-icons";
        src: url(${djIcons}) format("woff2");
        font-weight: normal;
        font-style: normal;
      }
    `}
  />
)

const BaseIcon = styled.i`
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "dj-icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
`

export enum IconType {
  dashboard = "dashboard",
  calendar = "calendar",
  weight = "weight",
  user = "user",
  userClock = "userClock",
  userClockCircle = "userClockCircle",
}

const iconTexts: Record<IconType, string> = {
  [IconType.dashboard]: "\u{e60c}",
  [IconType.calendar]: "\u{e609}",
  [IconType.weight]: "\u{e634}",
  [IconType.user]: "\u{e630}",
  [IconType.userClock]: "\u{e632}",
  [IconType.userClockCircle]: "\u{e631}",
}

export type DjIconProps = {
  icon: IconType
  className?: string
}

export const DjIcon: FC<DjIconProps> = ({ className, icon }) => (
  <Fragment>
    <BaseIcon {...{ className }}>{iconTexts[icon]}</BaseIcon>
    <DjIcons />
  </Fragment>
)

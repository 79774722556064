import { FC, HTMLAttributes, ReactNode } from "react"
import styled from "@emotion/styled"

const BgContainer = styled.div`
  max-width: 100%;
  ${({ autoHeight }: { autoHeight: boolean }) =>
    !autoHeight &&
    `
    max-height: 100%;
  `}
`

const Details = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background: #2e3d4e66;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #fff;
  height: 61px;
`

export type BackgroundWithDetailsProps = {
  background: ReactNode
  extra?: ReactNode
  autoHeight: boolean
} & HTMLAttributes<HTMLDivElement>

export const BackgroundWithDetails = styled(
  ({
    background,
    extra,
    autoHeight,
    children,
    ...props
  }: BackgroundWithDetailsProps) => (
    <div {...props}>
      <BgContainer {...{ autoHeight }}>{background}</BgContainer>
      <Details>{children}</Details>
      {extra}
    </div>
  ),
)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #2e3d4e12;
`

import { HTMLAttributes } from "react"
import styled from "@emotion/styled"

export type SupraNavListProps = HTMLAttributes<HTMLUListElement>

export const SupraNavList = styled.ul`
  position: relative;
  margin-top: 0.5em;

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: calc(100% - 1.2em);
    width: 1px;
    background-color: ${({ theme }) => theme.colours.grey["200"].toString()};
  }
  margin-left: 1.4em;

  list-style: none;

  & li.nav-item {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 1.1em;
      left: -2.5em;
      height: 1px;
      width: 2em;
      background-color: ${({ theme }) => theme.colours.grey["200"].toString()};
    }
  }
`

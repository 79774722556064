import * as Eq from "fp-ts/es6/Eq";
import { pipe } from "fp-ts/es6/function";
import * as Num from "fp-ts/es6/number";
import * as Opt from "fp-ts/es6/Option";
import { Iso, Lens, Prism } from "monocle-ts";
import { compareNumKeys, ioTsPrism } from "../internal";
import { isoStringAsDuration, mkDuration } from "./Duration";
import { Temporal } from "@js-temporal/polyfill";
import * as t from "io-ts";
const mkTimeOfDayDurationRaw = (hours, minutes, seconds) => ({
    tag: "TimeOfDayDuration",
    hours,
    minutes,
    seconds,
});
export const _hours = Lens.fromProp()("hours");
export const _minutes = Lens.fromProp()("minutes");
export const _seconds = Lens.fromProp()("seconds");
export const mkTimeOfDayDuration = (hours, minutes, seconds) => pipe(mkTimeOfDayDurationRaw(hours, minutes, seconds), ({ hours, minutes, seconds }) => {
    const s = hours * 3600 + minutes * 60 + seconds;
    return mkTimeOfDayDurationRaw((s / 3600) | 0, (s / 60) % 60 | 0, s % 60);
});
export const TemporalDurationAsTimeOfDayDuration = new Prism(({ years, months, weeks, days, hours, minutes, seconds }) => years === 0 && months === 0 && weeks === 0 && days === 0
    ? Opt.some(mkTimeOfDayDuration(hours, minutes, seconds))
    : Opt.none, ({ hours, minutes, seconds }) => mkDuration(hours, minutes, seconds));
export const DurationAsTimeOfDayDuration = new Prism(({ hours, minutes, seconds }) => Opt.some(mkTimeOfDayDuration(hours, minutes, seconds)), ({ hours, minutes, seconds }) => mkDuration(hours, minutes, seconds));
export const timeOfDayDuration = Object.assign(Object.assign({}, Eq.struct({
    hours: Num.Eq,
    minutes: Num.Eq,
    seconds: Num.Eq,
})), { compare: compareNumKeys(["hours", "minutes", "seconds"]), empty: mkTimeOfDayDurationRaw(0, 0, 0), concat: (a, b) => mkTimeOfDayDuration(a.hours + b.hours, a.minutes + b.minutes, a.seconds + b.seconds), inverse: ({ tag, hours, minutes, seconds }) => ({
        tag,
        hours: -hours,
        minutes: -minutes,
        seconds: -seconds,
    }), add: (t, d) => t.add(d), difference: (t, t2) => pipe(t.until(t2), Opt.fromPredicate((d) => d.years === 0 && d.months === 0 && d.weeks === 0 && d.days === 0), Opt.chain(DurationAsTimeOfDayDuration.getOption)).value });
export const equals = (d2) => (d) => timeOfDayDuration.equals(d, d2);
export const compare = (d2) => (d) => timeOfDayDuration.compare(d, d2);
export const concat = (d2) => (d) => timeOfDayDuration.concat(d, d2);
export const empty = timeOfDayDuration.empty;
export const inverse = timeOfDayDuration.inverse;
export const add = (d) => (t) => timeOfDayDuration.add(t, d);
export const difference = (t2) => (t) => timeOfDayDuration.difference(t, t2);
export const isoStringAsTimeOfDayDuration = isoStringAsDuration.composePrism(DurationAsTimeOfDayDuration);
export const timeOfDayDurationT = t.string.pipe(ioTsPrism("TimeOfDayDuration", (a) => typeof a === "object" &&
    a != null &&
    a.tag === "TimeOfDayDuration", isoStringAsTimeOfDayDuration));
export const secondsAsTimeOfDayDuration = new Iso((s) => mkTimeOfDayDuration(0, 0, s), (s) => Temporal.Duration.from(s).total({ unit: "seconds" }));
export const millisecondsAsTimeOfDayDuration = new Iso((s) => mkTimeOfDayDuration(0, 0, s / 1000), (s) => Temporal.Duration.from(s).total({ unit: "milliseconds" }));

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EventChangeType = {
    Single: "single",
    Following: "following",
    All: "all",
};
export function EventChangeTypeFromJSON(json) {
    return EventChangeTypeFromJSONTyped(json, false);
}
export function EventChangeTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EventChangeTypeToJSON(value) {
    return value;
}

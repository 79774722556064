import { FC } from "react"
import { FaUser } from "react-icons/fa"
import styled from "@emotion/styled"

import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"

import { Image } from "@fitnesspilot/components-common/dist/atoms/Image/Image"
import { Photo } from "@fitnesspilot/data-user/dist/photo"

const StyledFaUser = styled(FaUser)`
  width: 60.8695%;
  color: ${({ theme }) => theme.colours.textMuted.toString()};
`

const ProfilePhotoContainer = styled.div`
  display: block;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colours.grey[100].toString()};
  overflow: hidden;

  & > * {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);
  }

  & > img {
    object-fit: contain;
  }

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`

export type ProfilePhotoProps = {
  className?: string
  profilePhoto: Opt.Option<Photo>
}

export const ProfilePhoto: FC<ProfilePhotoProps> = ({
  className,
  profilePhoto,
}) => (
  <ProfilePhotoContainer {...{ className }}>
    {pipe(
      profilePhoto,
      Opt.fold(
        () => <StyledFaUser />,
        ({ dateCreated, ...props }) => (
          <Image
            image={{
              dateCreated: Opt.some(dateCreated),
              ...props,
            }}
          />
        ),
      ),
    )}
  </ProfilePhotoContainer>
)

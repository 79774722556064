import * as Eq from "fp-ts/es6/Eq"
import * as Num from "fp-ts/es6/number"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as St from "fp-ts/es6/ReadonlySet"
import * as Str from "fp-ts/es6/string"
import { Lens } from "monocle-ts"
import { PositiveInteger } from "newtype-ts/es6/PositiveInteger"
import { Time, time } from "time-ts/es6"

import { ActivityCategory, activityCategory } from "./ActivityCategory"
import { ActivityTag, activityTag } from "./ActivityTag"
import { EquipmentType, equipmentType } from "./Equipment"
import { MuscleShare, muscleShare } from "./MuscleShare"

export type ActivityExercise = {
  createdBy: Opt.Option<string>
  title: string
  instructions: Opt.Option<string>
  category: ActivityCategory
  tags: ReadonlySet<ActivityTag>
  muscleShares: ReadonlyArray<MuscleShare>
  equipments: ReadonlySet<EquipmentType>
  lastInstance: Opt.Option<Time>
  frequency: Opt.Option<PositiveInteger>
}
export const activityExercise = Eq.struct<ActivityExercise>({
  createdBy: Opt.getEq(Str.Eq),
  title: Str.Eq,
  instructions: Opt.getEq(Str.Eq),
  category: activityCategory,
  tags: St.getEq(activityTag),
  muscleShares: Arr.getEq(muscleShare),
  equipments: St.getEq(equipmentType),
  lastInstance: Opt.getEq(time),
  frequency: Opt.getEq(Num.Eq as any as Eq.Eq<PositiveInteger>),
})
export const _title = Lens.fromProp<ActivityExercise>()("title")
export const _instructions = Lens.fromProp<ActivityExercise>()("instructions")
export const _category = Lens.fromProp<ActivityExercise>()("category")
export const _tags = Lens.fromProp<ActivityExercise>()("tags")
export const _muscleShares = Lens.fromProp<ActivityExercise>()("muscleShares")
export const _equipments = Lens.fromProp<ActivityExercise>()("equipments")
export const _lastInstance = Lens.fromProp<ActivityExercise>()("lastInstance")
export const _frequency = Lens.fromProp<ActivityExercise>()("frequency")

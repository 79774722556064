import { FC, Fragment } from "react"
import { FormattedMessage } from "react-intl"

import { Markdown } from "@fitnesspilot/components-common/dist/molecules/Markdown/Markdown"

export const LegalNotice: FC = () => (
  <Fragment>
    <FormattedMessage
      defaultMessage={`fitnesspilot.com is a service provided by:\n\nupsquared GmbH  \nAlsterdorfer Str. 19A  \n22299 Hamburg  \nGermany\n\nTelephone number: +49 40 23 49 4999  \nE-mail: contact@fitnesspilot.com\n\nRegistry court: Amtsgericht Hamburg  \nRegister number: HRB 158886  \nManaging Director: Dirk Solbach\n\nVAT ID number in accordance with § 27 UStG: DE 326387233`}
    >
      {(text) => <Markdown>{String(text)}</Markdown>}
    </FormattedMessage>
  </Fragment>
)

import { FC, forwardRef, ReactNode, Ref } from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

export type VirtualListOuterProps = {
  ref?: Ref<HTMLDivElement>
  children: ReactNode
}
export const VirtualListOuter = styled.div<VirtualListOuterProps>`
  height: 100%;
  width: calc(100% + 16px);
  overflow-y: auto;
  padding-right: 16px;
`

export type VirtualListInnerProps = {
  totalSize: number
  children: ReactNode
}
export const VirtualListInner = styled.div<VirtualListInnerProps>`
  height: ${({ totalSize }) => totalSize}px;
  width: 100%;
  position: relative;
`

export type VirtualListMostInnerProps = {
  start: number
  children: ReactNode
}
export const VirtualListMostInner = styled.div<VirtualListMostInnerProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  ${({ start }) => css`
    transform: translateY(${start}px);
  `}
`

export type VirtualListProps = Required<VirtualListOuterProps> &
  VirtualListInnerProps &
  VirtualListMostInnerProps & {
    children: ReactNode
    className?: string
  }

export const VirtualList: FC<VirtualListProps> = forwardRef<
  HTMLDivElement,
  VirtualListProps
>(({ className, totalSize, start, children }, ref) => (
  <VirtualListOuter {...{ className, ref }}>
    <VirtualListInner {...{ totalSize }}>
      <VirtualListMostInner {...{ start }}>{children}</VirtualListMostInner>
    </VirtualListInner>
  </VirtualListOuter>
))

import React, { HTMLAttributes } from "react"
import styled from "@emotion/styled"

export type SupraNavProps = HTMLAttributes<HTMLDivElement>

export const SupraNav = styled.div`
  background: ${({ theme }) => theme.colours.white.toString()};
  flex-grow: 1;
  box-shadow: 7px 0 8px -7px rgba(0, 0, 0, 0.25);
  overflow-y: auto;

  & .c-supraNavSection {
    margin: 0 20px;
    padding: 20px 0;
  }
`

import * as Opt from "fp-ts/es6/Option"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import { ShareData, UnitSelection } from "@fitnesspilot/data-common"

import { AnyAPIError } from "@fitnesspilot/data-api"
import { SumBodyComposition } from "@fitnesspilot/data-human-body/dist/bodyComposition"
import * as HumanBody from "@fitnesspilot/data-human-body/dist/humanBody"

import { AboutMeAnswers } from "../AboutMeAnswers"
import { GoogleApi } from "../GoogleApi"
import { GoogleFit } from "../GoogleFit"
import * as Habits from "../Habits"
import { InviteCode } from "../InviteCode"
import * as Job from "../Job"
import { Photo } from "../photo"
import { ReferralCode } from "../ReferralCode"
import * as Sleep from "../Sleep"
import { User } from "../User"
import { UserAuth } from "../UserAuth"
import { UserClaims } from "../UserClaims"
import { UserLogin } from "../UserLogin"
import { UserPassword } from "../UserPassword"
import { UserRegistration } from "../UserRegistration"
import { UserSetup } from "../UserSetup"
import * as Work from "../Work"

import "firebase/compat/auth"
import firebase from "firebase/compat/app"
import { ActionType, createAction, createAsyncAction } from "typesafe-actions"

export const resetState = createAction("user/resetState", () => ({}))()

export const setUserClaims = createAction(
  "user/setUserClaims",
  (role: Opt.Option<UserClaims>) => role,
)()

export const signupAsync = createAsyncAction(
  "user/signup/request",
  "user/signup/success",
  "user/signup/failure",
)<UserRegistration, UserLogin, AnyAPIError>()
export const signup = signupAsync.request

export const submitSetupAsync = createAsyncAction(
  "user/submitSetup/request",
  "user/submitSetup/success",
  "user/submitSetup/failure",
)<UserSetup, void, AnyAPIError>()
export const submitSetup = submitSetupAsync.request

export const loginAsync = createAsyncAction(
  "user/login/request",
  "user/login/success",
  "user/login/failure",
)<UserLogin, void, AnyAPIError>()
export const login = loginAsync.request

export const logoutAsync = createAsyncAction(
  "user/logout/request",
  "user/logout/success",
  "user/logout/failure",
)<void, void, AnyAPIError>()
export const logout = logoutAsync.request

export const fetchUserAsync = createAsyncAction(
  "user/fetchUser/request",
  "user/fetchUser/success",
  "user/fetchUser/failure",
)<void, User, AnyAPIError>()
export const fetchUser = fetchUserAsync.request

export const setUserAsync = createAsyncAction(
  "user/setUser/request",
  "user/setUser/success",
  "user/setUser/failure",
)<User, User, AnyAPIError>()
export const setUser = setUserAsync.request

export const fetchUserDataAsync = createAsyncAction(
  "user/fetchUserData/request",
  "user/fetchUserData/success",
  "user/fetchUserData/failure",
)<
  void,
  {
    body: HumanBody.HumanBody
    work: Opt.Option<Work.Work>
    sleep: Opt.Option<Sleep.Sleep>
    habits: Habits.Habits
  },
  AnyAPIError
>()
export const fetchUserData = fetchUserDataAsync.request

export const setUserDataAsync = createAsyncAction(
  "user/setUserData/request",
  "user/setUserData/success",
  "user/setUserData/failure",
)<
  {
    body: HumanBody.HumanBody
    work: Opt.Option<Work.Work>
    sleep: Opt.Option<Sleep.Sleep>
    habits: Habits.Habits
  },
  {
    body: HumanBody.HumanBody
    work: Opt.Option<Work.Work>
    sleep: Opt.Option<Sleep.Sleep>
    habits: Habits.Habits
  },
  AnyAPIError
>()
export const setUserData = setUserDataAsync.request

export const setHabits = createAction("user/setHabits")<Habits.Habits>()

export const fetchBody = createAction("user/fetchBody")<void>()
export const setBody = createAction("user/setBody")<HumanBody.HumanBody>()

export const fetchAboutMeAnswersAsync = createAsyncAction(
  "user/fetchAboutMeAnswers/request",
  "user/fetchAboutMeAnswers/success",
  "user/fetchAboutMeAnswers/failure",
)<void, AboutMeAnswers, AnyAPIError>()
export const fetchAboutMeAnswers = fetchAboutMeAnswersAsync.request

export const fetchWorkAndLifestyle = createAction(
  "user/fetchWorkAndLifestyle",
)<void>()
export const setWorkAndLifestyle = createAction("user/setWorkAndLifestyle")<{
  work: Opt.Option<Work.Work>
  sleep: Opt.Option<Sleep.Sleep>
  habits: Habits.Habits
}>()

export const fetchUnitSelectionAsync = createAsyncAction(
  "user/fetchUnitSelection/request",
  "user/fetchUnitSelection/success",
  "user/fetchUnitSelection/failure",
)<void, UnitSelection, AnyAPIError>()
export const fetchUnitSelection = fetchUnitSelectionAsync.request

export const setUnitSelectionLocal = createAction(
  "user/setUnitSelectionLocal",
)<UnitSelection>()

export const setUnitSelectionAsync = createAsyncAction(
  "user/setUnitSelection/request",
  "user/setUnitSelection/success",
  "user/setUnitSelection/failure",
)<UnitSelection, UnitSelection, AnyAPIError>()
export const setUnitSelection = setUnitSelectionAsync.request

export const changePasswordAsync = createAsyncAction(
  "user/changePassword/request",
  "user/changePassword/success",
  "user/changePassword/failure",
)<UserPassword, void, AnyAPIError>()
export const changePassword = changePasswordAsync.request

export const resetPasswordAsync = createAsyncAction(
  "user/resetPassword/request",
  "user/resetPassword/success",
  "user/resetPassword/failure",
)<UserAuth, void, AnyAPIError>()
export const resetPassword = resetPasswordAsync.request

export const openPhoto = createAction(
  "user/openPhoto",
  (photo: Photo) => photo,
)()

export const closePhoto = createAction("user/closePhoto", () => ({}))()

export const editPhoto = createAction(
  "user/editPhoto",
  (photo: Photo) => photo,
)()

export const changePhoto = createAction(
  "user/changePhoto",
  (photo: Photo) => photo,
)()

export const fetchPhotosAsync = createAsyncAction(
  "user/photos/request",
  "user/photos/success",
  "user/photos/failure",
)<
  void,
  { photos: ReadonlyArray<Photo>; profilePhoto: Opt.Option<Photo> },
  AnyAPIError
>()
export const fetchPhotos = fetchPhotosAsync.request

export const uploadPhotosAsync = createAsyncAction(
  "user/uploadPhotos/request",
  "user/uploadPhotos/success",
  "user/uploadPhotos/failure",
)<ReadonlyArray<File>, ReadonlyArray<Photo>, AnyAPIError>()
export const uploadPhotos = uploadPhotosAsync.request

export const setProfilePhotoAsync = createAsyncAction(
  "user/setProfilePhoto/request",
  "user/setProfilePhoto/success",
  "user/setProfilePhoto/failure",
)<Opt.Option<Photo>, Opt.Option<Photo>, AnyAPIError>()
export const setProfilePhoto = setProfilePhotoAsync.request

export const uploadProfilePhoto = createAction(
  "user/uploadProfilePhoto",
  (file: File) => file,
)()

export const savePhotoAsync = createAsyncAction(
  "user/savePhoto/request",
  "user/savePhoto/success",
  "user/savePhoto/failure",
)<Photo, Photo, AnyAPIError>()
export const savePhoto = savePhotoAsync.request

export const sharePhotoAsync = createAsyncAction(
  "user/sharePhoto/request",
  "user/sharePhoto/success",
  "user/sharePhoto/failure",
)<Photo, void, AnyAPIError>()
export const sharePhoto = sharePhotoAsync.request

export const selectFitnessLevel = createAction(
  "user/selectFitnessLevel",
  (fitnessLevel: SumBodyComposition) => fitnessLevel,
)()

export const shareAsync = createAsyncAction(
  "user/share/request",
  "user/share/success",
  "user/share/failure",
)<ShareData, void, AnyAPIError>()
export const share = shareAsync.request

export const showDeleteAccountConfirmation = createAction(
  "user/showDeleteAccountConfirmation",
  () => ({}),
)()

export const deleteAccountAsync = createAsyncAction(
  "user/deleteAccount/request",
  "user/deleteAccount/success",
  "user/deleteAccount/failure",
)<void, void, AnyAPIError>()
export const deleteAccount = deleteAccountAsync.request

export const fetchJobsAsync = createAsyncAction(
  "event/fetchJobs/request",
  "event/fetchJobs/success",
  "event/fetchJobs/failure",
)<
  void,
  {
    jobs: NonEmpty.ReadonlyNonEmptyArray<Job.Job>
  },
  AnyAPIError
>()
export const fetchJobs = fetchJobsAsync.request

export const requestFcmTokenAsync = createAsyncAction(
  "clientsWeb/requestFcmToken/request",
  "clientsWeb/requestFcmToken/success",
  "clientsWeb/requestFcmToken/failure",
)<void, string, Error>()
export const requestFcmToken = requestFcmTokenAsync.request

export const sendFcmTokenAsync = createAsyncAction(
  "user/sendFcmToken/request",
  "user/sendFcmToken/success",
  "user/sendFcmToken/failure",
)<string, string, AnyAPIError>()
export const sendFcmToken = sendFcmTokenAsync.request

export const showGoogleOAuthPopupAsync = createAsyncAction(
  "user/showGoogleOAuthPopup/request",
  "user/showGoogleOAuthPopup/success",
  "user/showGoogleOAuthPopup/failure",
)<GoogleApi["scopes"], void, Error>()
export const showGoogleOAuthPopup = showGoogleOAuthPopupAsync.request

export type GoogleAuthCode = {
  code: string
  scopes: ReadonlyArray<string>
  redirectUri: string
}
export const sendGoogleAuthCodeAsync = createAsyncAction(
  "user/sendGoogleAuthCode/request",
  "user/sendGoogleAuthCode/success",
  "user/sendGoogleAuthCode/failure",
)<GoogleAuthCode, void, AnyAPIError>()
export const sendGoogleAuthCode = sendGoogleAuthCodeAsync.request

export const fetchGoogleFitAsync = createAsyncAction(
  "user/fetchGoogleFit/request",
  "user/fetchGoogleFit/success",
  "user/fetchGoogleFit/failure",
)<void, Opt.Option<GoogleFit>, AnyAPIError>()
export const fetchGoogleFit = fetchGoogleFitAsync.request

export const setGoogleFitAsync = createAsyncAction(
  "user/setGoogleFit/request",
  "user/setGoogleFit/success",
  "user/setGoogleFit/failure",
)<Opt.Option<GoogleFit>, Opt.Option<GoogleFit>, AnyAPIError>()
export const setGoogleFit = setGoogleFitAsync.request

export const fetchReferralCodeAsync = createAsyncAction(
  "user/fetchReferralCode/request",
  "user/fetchReferralCode/success",
  "user/fetchReferralCode/failure",
)<void, ReferralCode, AnyAPIError>()
export const fetchReferralCode = fetchReferralCodeAsync.request

export const fetchInviteCodesAsync = createAsyncAction(
  "user/fetchInviteCodes/request",
  "user/fetchInviteCodes/success",
  "user/fetchInviteCodes/failure",
)<void, ReadonlyArray<InviteCode>, AnyAPIError>()
export const fetchInviteCodes = fetchInviteCodesAsync.request

export const addInviteCodeAsync = createAsyncAction(
  "user/addInviteCode/request",
  "user/addInviteCode/success",
  "user/addInviteCode/failure",
)<void, InviteCode, AnyAPIError>()
export const addInviteCode = addInviteCodeAsync.request

export type Action = ActionType<
  | typeof resetState
  | typeof setUserClaims
  | (typeof signupAsync)["request" | "success" | "failure"]
  | (typeof submitSetupAsync)["request" | "success" | "failure"]
  | (typeof loginAsync)["request" | "success" | "failure"]
  | (typeof fetchUserAsync)["request" | "success" | "failure"]
  | (typeof setUserAsync)["request" | "success" | "failure"]
  | (typeof fetchUserDataAsync)["request" | "success" | "failure"]
  | (typeof setUserDataAsync)["request" | "success" | "failure"]
  | typeof setHabits
  | typeof fetchBody
  | typeof setBody
  | (typeof fetchAboutMeAnswersAsync)["request" | "success" | "failure"]
  | typeof fetchWorkAndLifestyle
  | typeof setWorkAndLifestyle
  | (typeof logoutAsync)["request" | "success" | "failure"]
  | (typeof fetchUnitSelectionAsync)["request" | "success" | "failure"]
  | typeof setUnitSelectionLocal
  | (typeof setUnitSelectionAsync)["request" | "success" | "failure"]
  | (typeof changePasswordAsync)["request" | "success" | "failure"]
  | (typeof resetPasswordAsync)["request" | "success" | "failure"]
  | typeof openPhoto
  | typeof closePhoto
  | typeof editPhoto
  | typeof changePhoto
  | (typeof fetchPhotosAsync)["request" | "success" | "failure"]
  | (typeof uploadPhotosAsync)["request" | "success" | "failure"]
  | (typeof setProfilePhotoAsync)["request" | "success" | "failure"]
  | typeof uploadProfilePhoto
  | (typeof savePhotoAsync)["request" | "success" | "failure"]
  | (typeof sharePhotoAsync)["request" | "success" | "failure"]
  | typeof selectFitnessLevel
  | (typeof shareAsync)["request" | "success" | "failure"]
  | typeof showDeleteAccountConfirmation
  | (typeof deleteAccountAsync)["request" | "success" | "failure"]
  | (typeof fetchJobsAsync)["request" | "success" | "failure"]
  | (typeof requestFcmTokenAsync)["request" | "success" | "failure"]
  | (typeof sendFcmTokenAsync)["request" | "success" | "failure"]
  | (typeof showGoogleOAuthPopupAsync)["request" | "success" | "failure"]
  | (typeof sendGoogleAuthCodeAsync)["request" | "success" | "failure"]
  | (typeof fetchGoogleFitAsync)["request" | "success" | "failure"]
  | (typeof setGoogleFitAsync)["request" | "success" | "failure"]
  | (typeof fetchReferralCodeAsync)["request" | "success" | "failure"]
  | (typeof fetchInviteCodesAsync)["request" | "success" | "failure"]
  | (typeof addInviteCodeAsync)["request" | "success" | "failure"]
>

import { CookieCategory } from "@fitnesspilot/components-cookie-consent"

import { CookieId } from "../cookieConsent"

import { ActionType, createAction } from "typesafe-actions"

export const showCookieConsent = createAction(
  "legal/cookieConsent/show",
  () => ({}),
)()

export const hideCookieConsent = createAction(
  "legal/cookieConsent/hide",
  () => ({}),
)()

export const acceptCookieConsent = createAction(
  "legal/cookieConsent/accept",
  (cookieIds: ReadonlyArray<CookieId>) => cookieIds,
)()

export const toggleCookieCategory = createAction(
  "legal/cookieConsent/toggleCategory",
  (category: CookieCategory) => category,
)()

export const setCookiesChecked = createAction(
  "legal/cookieConsent/setCookiesChecked",
  (cookieIds: ReadonlyArray<CookieId>, checked: boolean) => ({
    cookieIds,
    checked,
  }),
)()

export type Action = ActionType<
  | typeof showCookieConsent
  | typeof hideCookieConsent
  | typeof acceptCookieConsent
  | typeof toggleCookieCategory
  | typeof setCookiesChecked
>

import * as Arr_ from "fp-ts/es6/Array"
import { flow, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as St from "fp-ts/es6/ReadonlySet"
import * as Rec_ from "fp-ts/es6/Record"
import { fromTraversable, Getter, Iso, Lens, Optional, Prism } from "monocle-ts"
import {
  PositiveInteger,
  prismPositiveInteger,
} from "newtype-ts/es6/PositiveInteger"
import { emptyDuration } from "time-ts/es6"
import { match } from "ts-pattern"
import {
  Dimension,
  matchEnum,
  numberAsValidNumber,
  numLit,
  optionLensToOptional,
  prismToGetter,
  unsafeFromSome,
  unwrap,
  validNumberAsPositive,
  valueInAnyUnit,
  valueInCanonicalUnit,
} from "@fitnesspilot/data-common"

import {
  durationAsDuration,
  timestampAsTime,
} from "@fitnesspilot/data-common/dist/store/proto"
import * as Alignment from "@fitnesspilot/data-human-body/dist/Alignment"
import * as Intensity from "@fitnesspilot/data-human-body/dist/Intensity"
import * as Muscle from "@fitnesspilot/data-human-body/dist/Muscle"
import * as MuscleGroup from "@fitnesspilot/data-human-body/dist/muscleGroups"
import * as PROTO from "@fitnesspilot/proto/dist/index"

import * as Activity from "../activity/Activity"
import * as ActivityCategory from "../activity/ActivityCategory"
import * as ActivityExercise from "../activity/ActivityExercise"
import { ActivityId, stringAsActivityId } from "../activity/ActivityId"
import * as ActivityJob from "../activity/ActivityJob"
import * as ActivityMuscleInfo from "../activity/ActivityMuscleInfo"
import * as ActivityMuscles from "../activity/ActivityMuscles"
import * as ActivitySleep from "../activity/ActivitySleep"
import * as ActivityTag from "../activity/ActivityTag"
import * as Equipment from "../activity/Equipment"
import * as ExerciseSet from "../activity/ExerciseSet"
import * as MuscleShare from "../activity/MuscleShare"
import * as WithId from "../activity/WithId"
import * as ActivityInstance from "../activityInstance/ActivityInstance"
import * as ActivityInstanceExercise from "../activityInstance/ActivityInstanceExercise"
import * as ActivityInstanceGroup from "../activityInstance/ActivityInstanceGroup"
import * as ActivityInstanceJob from "../activityInstance/ActivityInstanceJob"
import * as ActivityInstanceMuscles from "../activityInstance/ActivityInstanceMuscles"
import * as ActivityInstanceSleep from "../activityInstance/ActivityInstanceSleep"

export const activityCategory = new Iso<
  PROTO.ActivityCategory,
  ActivityCategory.ActivityCategory
>(
  (a) =>
    matchEnum(PROTO.ActivityCategory)(a)(
      (known) =>
        match(known)
          .with(
            numLit(PROTO.ActivityCategory.REPS_BODY_WEIGHT),
            () => ActivityCategory.ActivityCategory.repsBodyWeight,
          )
          .with(
            numLit(PROTO.ActivityCategory.REPS_EXTRA_WEIGHT),
            () => ActivityCategory.ActivityCategory.repsExtraWeight,
          )
          .with(
            numLit(PROTO.ActivityCategory.ISOMETRIC),
            () => ActivityCategory.ActivityCategory.isometric,
          )
          .with(
            numLit(PROTO.ActivityCategory.GENERIC),
            () => ActivityCategory.ActivityCategory.generic,
          )
          .with(
            numLit(PROTO.ActivityCategory.DISTANCE),
            () => ActivityCategory.ActivityCategory.distance,
          )
          .with(
            numLit(PROTO.ActivityCategory.STRETCHING),
            () => ActivityCategory.ActivityCategory.stretching,
          )
          .with(numLit(PROTO.ActivityCategory.UNSPECIFIED), () => {
            throw new Error("activity category unspecified")
          })
          .exhaustive(),
      (unknown) => {
        throw new Error(`activity category not supported: ${unknown}`)
      },
    ),
  (a) =>
    match(a)
      .with(
        ActivityCategory.ActivityCategory.repsBodyWeight,
        () => PROTO.ActivityCategory.REPS_BODY_WEIGHT,
      )
      .with(
        ActivityCategory.ActivityCategory.repsExtraWeight,
        () => PROTO.ActivityCategory.REPS_EXTRA_WEIGHT,
      )
      .with(
        ActivityCategory.ActivityCategory.isometric,
        () => PROTO.ActivityCategory.ISOMETRIC,
      )
      .with(
        ActivityCategory.ActivityCategory.generic,
        () => PROTO.ActivityCategory.GENERIC,
      )
      .with(
        ActivityCategory.ActivityCategory.distance,
        () => PROTO.ActivityCategory.DISTANCE,
      )
      .with(
        ActivityCategory.ActivityCategory.stretching,
        () => PROTO.ActivityCategory.STRETCHING,
      )
      .exhaustive(),
)

export const activityTag = new Iso<PROTO.ActivityTag, ActivityTag.ActivityTag>(
  (a) =>
    matchEnum(PROTO.ActivityTag)(a)(
      (known) =>
        match(known)
          .with(numLit(PROTO.ActivityTag.UNSPECIFIED), () => {
            throw new Error("activity category unspecified")
          })
          .with(
            numLit(PROTO.ActivityTag.CALISTHENICS),
            () => ActivityTag.ActivityTag.calisthenics,
          )
          .with(
            numLit(PROTO.ActivityTag.BODYBUILDING),
            () => ActivityTag.ActivityTag.bodybuilding,
          )
          .with(
            numLit(PROTO.ActivityTag.STRENGTH),
            () => ActivityTag.ActivityTag.strength,
          )
          .with(
            numLit(PROTO.ActivityTag.ENDURANCE),
            () => ActivityTag.ActivityTag.endurance,
          )
          .with(
            numLit(PROTO.ActivityTag.STRENGTH_ENDURANCE),
            () => ActivityTag.ActivityTag.strengthEndurance,
          )
          .with(
            numLit(PROTO.ActivityTag.ISOMETRIC),
            () => ActivityTag.ActivityTag.isometric,
          )
          .with(
            numLit(PROTO.ActivityTag.ISOTONIC),
            () => ActivityTag.ActivityTag.isotonic,
          )
          .with(
            numLit(PROTO.ActivityTag.CARDIO),
            () => ActivityTag.ActivityTag.cardio,
          )
          .with(
            numLit(PROTO.ActivityTag.SPORT),
            () => ActivityTag.ActivityTag.sport,
          )
          .with(
            numLit(PROTO.ActivityTag.OTHER),
            () => ActivityTag.ActivityTag.other,
          )
          .with(
            numLit(PROTO.ActivityTag.YOGA),
            () => ActivityTag.ActivityTag.yoga,
          )
          .with(
            numLit(PROTO.ActivityTag.BODYSHAPING),
            () => ActivityTag.ActivityTag.bodyshaping,
          )
          .with(
            numLit(PROTO.ActivityTag.PLYOMETRIC),
            () => ActivityTag.ActivityTag.plyometric,
          )
          .with(
            numLit(PROTO.ActivityTag.STRETCHING),
            () => ActivityTag.ActivityTag.stretching,
          )
          .with(
            numLit(PROTO.ActivityTag.BALANCE),
            () => ActivityTag.ActivityTag.balance,
          )
          .with(
            numLit(PROTO.ActivityTag.STABILITY),
            () => ActivityTag.ActivityTag.stability,
          )
          .with(
            numLit(PROTO.ActivityTag.MOBILITY),
            () => ActivityTag.ActivityTag.mobility,
          )
          .with(
            numLit(PROTO.ActivityTag.FASCIA),
            () => ActivityTag.ActivityTag.fascia,
          )
          .with(
            numLit(PROTO.ActivityTag.POWERLIFTING),
            () => ActivityTag.ActivityTag.powerlifting,
          )
          .with(
            numLit(PROTO.ActivityTag.STRONGMAN),
            () => ActivityTag.ActivityTag.strongman,
          )
          .with(
            numLit(PROTO.ActivityTag.WEIGHTLIFTING),
            () => ActivityTag.ActivityTag.weightlifting,
          )
          .with(
            numLit(PROTO.ActivityTag.PUSH_UP_VARIATIONS),
            () => ActivityTag.ActivityTag.pushUpVariations,
          )
          .with(
            numLit(PROTO.ActivityTag.CORE),
            () => ActivityTag.ActivityTag.core,
          )
          .with(
            numLit(PROTO.ActivityTag.FULL_BODY),
            () => ActivityTag.ActivityTag.fullBody,
          )
          .with(
            numLit(PROTO.ActivityTag.BEGINNER),
            () => ActivityTag.ActivityTag.beginner,
          )
          .with(
            numLit(PROTO.ActivityTag.INTERMEDIATE),
            () => ActivityTag.ActivityTag.intermediate,
          )
          .with(
            numLit(PROTO.ActivityTag.ADVANCED),
            () => ActivityTag.ActivityTag.advanced,
          )
          .exhaustive(),
      (unknown) => {
        throw new Error(`activity category not supported: ${unknown}`)
      },
    ),
  (a) =>
    match(a)
      .with(
        ActivityTag.ActivityTag.calisthenics,
        () => PROTO.ActivityTag.CALISTHENICS,
      )
      .with(
        ActivityTag.ActivityTag.bodybuilding,
        () => PROTO.ActivityTag.BODYBUILDING,
      )
      .with(ActivityTag.ActivityTag.strength, () => PROTO.ActivityTag.STRENGTH)
      .with(
        ActivityTag.ActivityTag.endurance,
        () => PROTO.ActivityTag.ENDURANCE,
      )
      .with(
        ActivityTag.ActivityTag.strengthEndurance,
        () => PROTO.ActivityTag.STRENGTH_ENDURANCE,
      )
      .with(
        ActivityTag.ActivityTag.isometric,
        () => PROTO.ActivityTag.ISOMETRIC,
      )
      .with(ActivityTag.ActivityTag.isotonic, () => PROTO.ActivityTag.ISOTONIC)
      .with(ActivityTag.ActivityTag.cardio, () => PROTO.ActivityTag.CARDIO)
      .with(ActivityTag.ActivityTag.sport, () => PROTO.ActivityTag.SPORT)
      .with(ActivityTag.ActivityTag.other, () => PROTO.ActivityTag.OTHER)
      .with(ActivityTag.ActivityTag.yoga, () => PROTO.ActivityTag.YOGA)
      .with(
        ActivityTag.ActivityTag.bodyshaping,
        () => PROTO.ActivityTag.BODYSHAPING,
      )
      .with(
        ActivityTag.ActivityTag.plyometric,
        () => PROTO.ActivityTag.PLYOMETRIC,
      )
      .with(
        ActivityTag.ActivityTag.stretching,
        () => PROTO.ActivityTag.STRETCHING,
      )
      .with(ActivityTag.ActivityTag.balance, () => PROTO.ActivityTag.BALANCE)
      .with(
        ActivityTag.ActivityTag.stability,
        () => PROTO.ActivityTag.STABILITY,
      )
      .with(ActivityTag.ActivityTag.mobility, () => PROTO.ActivityTag.MOBILITY)
      .with(ActivityTag.ActivityTag.fascia, () => PROTO.ActivityTag.FASCIA)
      .with(
        ActivityTag.ActivityTag.powerlifting,
        () => PROTO.ActivityTag.POWERLIFTING,
      )
      .with(
        ActivityTag.ActivityTag.strongman,
        () => PROTO.ActivityTag.STRONGMAN,
      )
      .with(
        ActivityTag.ActivityTag.weightlifting,
        () => PROTO.ActivityTag.WEIGHTLIFTING,
      )
      .with(
        ActivityTag.ActivityTag.pushUpVariations,
        () => PROTO.ActivityTag.PUSH_UP_VARIATIONS,
      )
      .with(ActivityTag.ActivityTag.core, () => PROTO.ActivityTag.CORE)
      .with(ActivityTag.ActivityTag.fullBody, () => PROTO.ActivityTag.FULL_BODY)
      .with(ActivityTag.ActivityTag.beginner, () => PROTO.ActivityTag.BEGINNER)
      .with(
        ActivityTag.ActivityTag.intermediate,
        () => PROTO.ActivityTag.INTERMEDIATE,
      )
      .with(ActivityTag.ActivityTag.advanced, () => PROTO.ActivityTag.ADVANCED)
      .exhaustive(),
)

const equipmentType = new Iso<PROTO.Equipment, Equipment.EquipmentType>(
  (a) => pipe(a.id, Equipment.stringAsEquipmentType.getOption, unsafeFromSome),
  (id) => new PROTO.Equipment({ id }),
)

const muscleShare = new Iso<PROTO.MuscleShare, MuscleShare.MuscleShare>(
  (a) => ({
    muscleId: pipe(
      a.muscleId,
      Muscle.stringAsMuscleId.getOption,
      unsafeFromSome,
    ),
    intensity: pipe(
      a.intensity,
      Intensity.numberAsIntensity.getOption,
      unsafeFromSome,
    ),
  }),
  (a) =>
    new PROTO.MuscleShare({
      muscleId: pipe(a.muscleId, unwrap),
      intensity: pipe(a.intensity, Intensity.numberAsIntensity.reverseGet),
    }),
)

export const exerciseSet = new Iso<PROTO.ExerciseSet, ExerciseSet.ExerciseSet>(
  (s) => ({
    repetitions: pipe(
      s,
      Optional.fromNullableProp<PROTO.ExerciseSet>()(
        "repetitions",
      ).composePrism(prismPositiveInteger).getOption,
      Opt.getOrElse(() => 1 as any as PositiveInteger),
    ),
    equipments: pipe(
      s,
      Optional.fromNullableProp<PROTO.ExerciseSet>()("weight")
        .composeLens(Lens.fromProp<PROTO.CanonicalMassDouble>()("value"))
        .composePrism(
          numberAsValidNumber.composeIso(valueInCanonicalUnit(Dimension.mass)),
        )
        .composeGetter(prismToGetter(Equipment._AnyWeightMass)).getAll,
    ),
  }),
  (a) =>
    new PROTO.ExerciseSet({
      repetitions: pipe(a.repetitions, prismPositiveInteger.reverseGet),
      weight: pipe(
        a,
        (a) =>
          ExerciseSet._equipments
            .composeTraversal(fromTraversable(Arr.Traversable)())
            .composePrism(Equipment._AnyWeightMass)
            .composeGetter(valueInAnyUnit(Dimension.mass))
            .composeGetter(prismToGetter(numberAsValidNumber))
            .composeGetter(
              new Getter((a) => new PROTO.CanonicalMassDouble({ value: a })),
            )
            .headOption(a),
        Opt.toUndefined,
      ),
    }),
)

export const activityExercise = new Iso<
  PROTO.ActivityExercise,
  WithId.WithId<ActivityId, ActivityExercise.ActivityExercise>
>(
  (a) => ({
    id: pipe(
      a.base?.id,
      Opt.fromNullable,
      Opt.chain(stringAsActivityId.getOption),
      unsafeFromSome,
    ),
    value: {
      createdBy: pipe(a.base?.createdBy, Opt.fromNullable),
      title: pipe(a.base?.title, Opt.fromNullable, unsafeFromSome),
      instructions: pipe(a.base?.instructions, Opt.fromNullable),
      category: pipe(a.category, activityCategory.get),
      tags: pipe(
        a.tags,
        Arr.map(activityTag.get),
        St.fromReadonlyArray(ActivityTag.activityTag),
      ),
      equipments: pipe(
        a.equipments,
        Arr.fromArray,
        Arr.map(equipmentType.get),
        St.fromArray(Equipment.equipmentType),
      ),
      muscleShares: pipe(a.muscleShares, Arr.map(muscleShare.get)),
      lastInstance: pipe(
        a.stats?.lastInstance,
        Opt.fromNullable,
        Opt.chain(timestampAsTime.getOption),
      ),
      frequency: pipe(
        a.stats?.frequency3Months,
        Opt.fromNullable,
        Opt.chain(prismPositiveInteger.getOption),
      ),
    },
  }),
  ({ id, value: a }) =>
    new PROTO.ActivityExercise({
      base: {
        id: unwrap(id),
        title: a.title,
        instructions: pipe(a.instructions, Opt.toUndefined),
        createdBy: pipe(a.createdBy, Opt.toUndefined),
      },
      category: pipe(a.category, activityCategory.reverseGet),
      tags: pipe(
        a.tags,
        St.toReadonlyArray(ActivityTag.activityTag),
        Arr.map(activityTag.reverseGet),
        Arr.toArray,
      ),
      equipments: pipe(
        a.equipments,
        St.toReadonlyArray(Equipment.equipmentType),
        Arr.map(equipmentType.reverseGet),
        Arr.toArray,
      ),
      muscleShares: pipe(
        a.muscleShares,
        Arr.map(muscleShare.reverseGet),
        Arr.toArray,
      ),
      stats: {
        lastInstance: pipe(
          a.lastInstance,
          Opt.map(timestampAsTime.reverseGet),
          Opt.toUndefined,
        ),
        frequency3Months: pipe(a.frequency, Opt.map(unwrap), Opt.toUndefined),
      },
    }),
)

export const activityMuscles = new Iso<
  PROTO.ActivityMuscles,
  WithId.WithId<ActivityId, ActivityMuscles.ActivityMuscles>
>(
  (a) => ({
    id: pipe(
      a.base?.id,
      Opt.fromNullable,
      Opt.chain(stringAsActivityId.getOption),
      unsafeFromSome,
    ),
    value: {
      title: pipe(a.base?.title, Opt.fromNullable, unsafeFromSome),
      instructions: pipe(a.base?.instructions, Opt.fromNullable),
    },
  }),
  ({ id, value: a }) =>
    new PROTO.ActivityMuscles({
      base: {
        id: unwrap(id),
        title: a.title,
        instructions: pipe(a.instructions, Opt.toUndefined),
        createdBy: undefined,
      },
    }),
)

export const activityJob = new Iso<
  PROTO.ActivityJob,
  WithId.WithId<ActivityId, ActivityJob.ActivityJob>
>(
  (a) => ({
    id: pipe(
      a.base?.id,
      Opt.fromNullable,
      Opt.chain(stringAsActivityId.getOption),
      unsafeFromSome,
    ),
    value: {
      title: pipe(a.base?.title, Opt.fromNullable, unsafeFromSome),
      instructions: pipe(a.base?.instructions, Opt.fromNullable),
      muscleShares: pipe(a.muscleShares, Arr.map(muscleShare.get)),
    },
  }),
  ({ id, value: a }) =>
    new PROTO.ActivityJob({
      base: {
        id: unwrap(id),
        title: a.title,
        instructions: pipe(a.instructions, Opt.toUndefined),
        createdBy: undefined,
      },
      muscleShares: pipe(
        a.muscleShares,
        Arr.map(muscleShare.reverseGet),
        Arr.toArray,
      ),
    }),
)

export const activitySleep = new Iso<
  PROTO.ActivitySleep,
  WithId.WithId<ActivityId, ActivitySleep.ActivitySleep>
>(
  (a) => ({
    id: pipe(
      a.base?.id,
      Opt.fromNullable,
      Opt.chain(stringAsActivityId.getOption),
      unsafeFromSome,
    ),
    value: {
      title: pipe(a.base?.title, Opt.fromNullable, unsafeFromSome),
      instructions: pipe(a.base?.instructions, Opt.fromNullable),
    },
  }),
  ({ id, value: a }) =>
    new PROTO.ActivitySleep({
      base: {
        id: unwrap(id),
        title: a.title,
        instructions: pipe(a.instructions, Opt.toUndefined),
        createdBy: undefined,
      },
    }),
)

export const activity = new Prism<PROTO.Activity, Activity.ActivityWithId>(
  (s) =>
    match(s.kind)
      .with({ case: "exercise" }, ({ value }) =>
        pipe(
          value,
          activityExercise.get,
          ({ id, value }): Activity.ActivityWithId => ({
            id,
            value: Activity._ActivityExercise.reverseGet(value),
          }),
          Opt.some,
        ),
      )
      .with({ case: "job" }, ({ value }) =>
        pipe(
          value,
          activityJob.get,
          ({ id, value }): Activity.ActivityWithId => ({
            id,
            value: Activity._ActivityJob.reverseGet(value),
          }),
          Opt.some,
        ),
      )
      .with({ case: "muscles" }, ({ value }) =>
        pipe(
          value,
          activityMuscles.get,
          ({ id, value }): Activity.ActivityWithId => ({
            id,
            value: Activity._ActivityMuscles.reverseGet(value),
          }),
          Opt.some,
        ),
      )
      .with({ case: "sleep" }, ({ value }) =>
        pipe(
          value,
          activitySleep.get,
          ({ id, value }): Activity.ActivityWithId => ({
            id,
            value: Activity._ActivitySleep.reverseGet(value),
          }),
          Opt.some,
        ),
      )
      .with({ case: undefined }, () => Opt.none)
      .exhaustive(),
  ({ id, value }) =>
    pipe(
      value,
      Activity.foldActivity<PROTO.Activity["kind"]>(
        (a) => ({
          case: "exercise",
          value: pipe({ id, value: a }, activityExercise.reverseGet),
        }),
        (a) => ({
          case: "job",
          value: pipe({ id, value: a }, activityJob.reverseGet),
        }),
        (a) => ({
          case: "muscles",
          value: pipe({ id, value: a }, activityMuscles.reverseGet),
        }),
        (a) => ({
          case: "sleep",
          value: pipe({ id, value: a }, activitySleep.reverseGet),
        }),
      ),
      (kind) => new PROTO.Activity({ kind }),
    ),
)

export const activityInstanceExercise = new Getter<
  [PROTO.ActivityInstanceExercise, Array<PROTO.Activity>],
  ActivityInstanceExercise.ActivityInstanceExercise
>(([act, activities]) => ({
  // TODO get from activities list
  activity: pipe(
    activities,
    // TODO change this to a Set for quicker lookup?
    Arr.findFirst((a) => a.kind.value?.base?.id === act.activityId),
    Opt.chain(activity.getOption),
    Opt.chain(({ id, value }) =>
      pipe(
        value,
        Activity._ActivityExercise.getOption,
        Opt.map((value) => ({ id, value })),
      ),
    ),
    unsafeFromSome,
  ),
  alignment: pipe(
    act.alignment,
    Opt.fromNullable,
    Opt.chain((a) => pipe(a * 100, Alignment.numberAsAlignment.getOption)),
    unsafeFromSome,
  ),
  distance: pipe(act, (v) =>
    Optional.fromNullableProp<PROTO.ActivityInstanceExercise>()("distance")
      .composeLens(Lens.fromProp<PROTO.CanonicalLengthDouble>()("value"))
      .composeGetter(new Getter((a) => a * 1000)) // m to km
      .composePrism(numberAsValidNumber)
      .composePrism(validNumberAsPositive)
      .composeGetter(new Getter(validNumberAsPositive.reverseGet))
      .composeIso(valueInCanonicalUnit(Dimension.lengthDistance))
      .headOption(v),
  ),
  duration: pipe(
    act.duration,
    Opt.fromNullable,
    Opt.map(durationAsDuration.get),
  ),
  importanceFactor: Opt.none,
  intensity: pipe(
    act.intensity,
    Opt.fromNullable,
    Opt.chain(Intensity.numberAsIntensity.getOption),
  ),
  sets: pipe(act.sets, Arr.map(exerciseSet.get)),
  muscles: Rec_.empty, // HINT: Proto messages don't support muscles atm
}))
export const activityInstanceExerciseReverse = new Getter<
  ActivityInstanceExercise.ActivityInstanceExercise,
  PROTO.ActivityInstanceExercise
>(
  (a) =>
    new PROTO.ActivityInstanceExercise({
      activityId: unwrap(a.activity.id),
      alignment: pipe(
        a.alignment,
        Alignment.numberAsAlignment.reverseGet,
        (a) => a / 100,
      ),
      distance: pipe(
        optionLensToOptional(ActivityInstanceExercise._distance)
          .composeIso(valueInCanonicalUnit(Dimension.lengthDistance).reverse())
          .composeGetter(prismToGetter(numberAsValidNumber))
          .composeGetter(new Getter((a) => a / 1000)) // km to m
          .composeGetter(
            new Getter((a) => new PROTO.CanonicalLengthDouble({ value: a })),
          )
          .headOption(a),
        Opt.toUndefined,
      ),
      duration: pipe(
        a.duration,
        Opt.map(durationAsDuration.reverseGet),
        Opt.toUndefined,
      ),
      intensity: pipe(
        a.intensity,
        Opt.map(Intensity.numberAsIntensity.reverseGet),
        Opt.toUndefined,
      ),
      sets: pipe(a.sets, Arr.map(exerciseSet.reverseGet), Arr.toArray),
    }),
)

export const activityInstanceMuscles = new Getter<
  [PROTO.ActivityInstanceMuscles, Array<PROTO.Activity>],
  ActivityInstanceMuscles.ActivityInstanceMuscles
>(([act, activities]) => ({
  // TODO get from activities list
  activity: pipe(
    activities,
    // TODO change this to a Set for quicker lookup?
    Arr.findFirst((a) => a.kind.value?.base?.id === act.activityId),
    Opt.chain(activity.getOption),
    Opt.chain(({ id, value }) =>
      pipe(
        value,
        Activity._ActivityExercise.getOption,
        Opt.map((value) => ({ id, value })),
      ),
    ),
    unsafeFromSome,
  ),
  alignment: pipe(
    act.alignment,
    Opt.fromNullable,
    Opt.chain((a) => pipe(a * 100, Alignment.numberAsAlignment.getOption)),
  ),
  duration: pipe(
    act.duration,
    Opt.fromNullable,
    Opt.map(durationAsDuration.get),
  ),
  intensity: pipe(
    act.intensity,
    Opt.fromNullable,
    Opt.chain(Intensity.numberAsIntensity.getOption),
  ),
  muscleGroupIds: pipe(
    act.muscleGroupIds,
    Arr.map(flow(MuscleGroup.stringAsMuscleGroupId.getOption, unsafeFromSome)),
  ),
}))
export const activityInstanceMusclesReverse = new Getter<
  ActivityInstanceMuscles.ActivityInstanceMuscles,
  PROTO.ActivityInstanceMuscles
>(
  (a) =>
    new PROTO.ActivityInstanceMuscles({
      activityId: unwrap(a.activity.id),
      alignment: pipe(
        a.alignment,
        Opt.map(Alignment.numberAsAlignment.reverseGet),
        Opt.map((a) => a / 100),
        Opt.toUndefined,
      ),
      duration: pipe(
        a.duration,
        Opt.map(durationAsDuration.reverseGet),
        Opt.toUndefined,
      ),
      intensity: pipe(
        a.intensity,
        Opt.map(Intensity.numberAsIntensity.reverseGet),
        Opt.toUndefined,
      ),
      muscleGroupIds: pipe(
        a.muscleGroupIds,
        Arr.map(MuscleGroup.stringAsMuscleGroupId.reverseGet),
        Arr.toArray,
      ),
    }),
)

export const activityInstanceJob = new Getter<
  [PROTO.ActivityInstanceJob, Array<PROTO.Activity>],
  ActivityInstanceJob.ActivityInstanceJob
>(([act, activities]) => ({
  // TODO get from activities list
  activity: pipe(
    activities,
    // TODO change this to a Set for quicker lookup?
    Arr.findFirst((a) => a.kind.value?.base?.id === act.activityId),
    Opt.chain(activity.getOption),
    Opt.chain(({ id, value }) =>
      pipe(
        value,
        Activity._ActivityExercise.getOption,
        Opt.map((value) => ({ id, value })),
      ),
    ),
    unsafeFromSome,
  ),
  duration: pipe(
    act.duration,
    Opt.fromNullable,
    Opt.map(durationAsDuration.get),
    unsafeFromSome,
  ),
}))
export const activityInstanceJobReverse = new Getter<
  ActivityInstanceJob.ActivityInstanceJob,
  PROTO.ActivityInstanceJob
>(
  (a) =>
    new PROTO.ActivityInstanceJob({
      activityId: unwrap(a.activity.id),
      duration: pipe(a.duration, durationAsDuration.reverseGet),
    }),
)

export const activityInstanceSleep = new Getter<
  [PROTO.ActivityInstanceSleep, Array<PROTO.Activity>],
  ActivityInstanceSleep.ActivityInstanceSleep
>(([act, activities]) => ({
  activity: pipe(
    activities,
    // TODO change this to a Set for quicker lookup?
    Arr.findFirst((a) => a.kind.value?.base?.id === act.activityId),
    Opt.chain(activity.getOption),
    Opt.chain(({ id, value }) =>
      pipe(
        value,
        Activity._ActivityExercise.getOption,
        Opt.map((value) => ({ id, value })),
      ),
    ),
    unsafeFromSome,
  ),
  duration: pipe(
    act.duration,
    Opt.fromNullable,
    Opt.map(durationAsDuration.get),
    unsafeFromSome,
  ),
}))
export const activityInstanceSleepReverse = new Getter<
  ActivityInstanceSleep.ActivityInstanceSleep,
  PROTO.ActivityInstanceSleep
>(
  (a) =>
    new PROTO.ActivityInstanceSleep({
      activityId: unwrap(a.activity.id),
      duration: pipe(a.duration, durationAsDuration.reverseGet),
    }),
)

export const activityInstanceGroup: Getter<
  [PROTO.ActivityInstanceGroup, Array<PROTO.Activity>],
  ActivityInstanceGroup.ActivityInstanceGroup
> = new Getter<
  [PROTO.ActivityInstanceGroup, Array<PROTO.Activity>],
  ActivityInstanceGroup.ActivityInstanceGroup
>(([act, activities]) => ({
  activities: pipe(
    act.activities,
    Arr.map((a) => activityInstance.get([a, activities])),
    Arr.compact,
  ),
  repetitions: act.repetitions,
  breakBetweenRepetitions: pipe(
    act.breakBetweenRepetitions,
    Opt.fromNullable,
    Opt.map(durationAsDuration.get),
    Opt.getOrElse(() => emptyDuration),
  ),
}))
export const activityInstanceGroup2: Getter<
  ActivityInstanceGroup.ActivityInstanceGroup,
  PROTO.ActivityInstanceGroup
> = new Getter<
  ActivityInstanceGroup.ActivityInstanceGroup,
  PROTO.ActivityInstanceGroup
>(
  (a) =>
    new PROTO.ActivityInstanceGroup({
      activities: pipe(
        a.activities,
        Arr.map(activityInstanceReverse.get),
        Arr.toArray,
      ),
      repetitions: a.repetitions,
      breakBetweenRepetitions: pipe(
        a.breakBetweenRepetitions,
        durationAsDuration.reverseGet,
      ),
    }),
)

export const activityInstance = new Getter<
  [PROTO.ActivityInstance, Array<PROTO.Activity>],
  Opt.Option<ActivityInstance.ActivityInstance>
>(([s, activities]) =>
  match(s.kind)
    .with({ case: "exercise" }, ({ value }) =>
      pipe(
        activityInstanceExercise
          .composeGetter(
            prismToGetter(ActivityInstance._ActivityInstanceExercise),
          )
          .get([value, activities]),
        Opt.some,
      ),
    )
    .with({ case: "job" }, ({ value }) =>
      pipe(
        activityInstanceJob
          .composeGetter(prismToGetter(ActivityInstance._ActivityInstanceJob))
          .get([value, activities]),
        Opt.some,
      ),
    )
    .with({ case: "muscles" }, ({ value }) =>
      pipe(
        activityInstanceMuscles
          .composeGetter(
            prismToGetter(ActivityInstance._ActivityInstanceMuscles),
          )
          .get([value, activities]),
        Opt.some,
      ),
    )
    .with({ case: "sleep" }, ({ value }) =>
      pipe(
        activityInstanceSleep
          .composeGetter(prismToGetter(ActivityInstance._ActivityInstanceSleep))
          .get([value, activities]),
        Opt.some,
      ),
    )
    .with({ case: "group" }, ({ value }) =>
      pipe(
        activityInstanceGroup
          .composeGetter(prismToGetter(ActivityInstance._ActivityInstanceGroup))
          .get([value, activities]),
        Opt.some,
      ),
    )
    .with({ case: undefined }, () => Opt.none)
    .exhaustive(),
)
export const activityInstanceReverse = new Getter<
  ActivityInstance.ActivityInstance,
  PROTO.ActivityInstance
>(
  flow(
    ActivityInstance.foldActivityInstance<PROTO.ActivityInstance["kind"]>(
      (a) => ({
        case: "exercise",
        value: activityInstanceExerciseReverse.get(a),
      }),
      (a) => ({
        case: "job",
        value: activityInstanceJobReverse.get(a),
      }),
      (a) => ({
        case: "muscles",
        value: activityInstanceMusclesReverse.get(a),
      }),
      (a) => ({
        case: "sleep",
        value: activityInstanceSleepReverse.get(a),
      }),
      (a) => ({
        case: "group",
        value: activityInstanceGroup2.get(a),
      }),
    ),
    (kind) => new PROTO.ActivityInstance({ kind }),
  ),
)

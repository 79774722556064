import { FC, forwardRef, HTMLAttributes, MouseEvent } from "react"
import styled from "@emotion/styled"

import * as IO from "fp-ts/es6/IO"
import { OmitFromKnownKeys } from "@fitnesspilot/data-common"

const Button = styled.button`
  all: unset;
  display: block;
  cursor: pointer;

  * {
    pointer-events: none;
  }
`

const stopPropagation = (f: () => void) => (e: MouseEvent) => {
  e.stopPropagation()
  f()
}

export type ClickableProps = {
  onClick: IO.IO<void>
} & OmitFromKnownKeys<HTMLAttributes<HTMLButtonElement>, "onClick">

export const Clickable = forwardRef<HTMLButtonElement, ClickableProps>(
  ({ onClick, ...props }, ref) => (
    <Button
      type="button"
      onClick={stopPropagation(onClick)}
      {...props}
      {...{ ref }}
    />
  ),
)

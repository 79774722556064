import { FC, InputHTMLAttributes, useEffect, useRef } from "react"

import {
  constant,
  constFalse,
  constTrue,
  constUndefined,
} from "fp-ts/es6/function"

export type DeterminateCheckboxStatus = "checked" | "unchecked"

export type CheckboxStatus = DeterminateCheckboxStatus | "indeterminate"

const foldCheckboxStatus =
  <A,>(onUnchecked: () => A, onIndeterminate: () => A, onChecked: () => A) =>
  (status: CheckboxStatus) => {
    switch (status) {
      case "unchecked":
        return onUnchecked()
      case "indeterminate":
        return onIndeterminate()
      default:
        return onChecked()
    }
  }

const checked = foldCheckboxStatus<boolean | undefined>(
  constFalse,
  constUndefined,
  constTrue,
)

const indeterminate = foldCheckboxStatus<boolean>(
  constFalse,
  constTrue,
  constFalse,
)

export const toggleStatus = foldCheckboxStatus<DeterminateCheckboxStatus>(
  constant("checked"),
  constant("checked"),
  constant("unchecked"),
)

export type CheckboxProps = {
  status: CheckboxStatus
  onChange: (status: DeterminateCheckboxStatus) => void
} & Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "value" | "checked" | "onChange"
>

export const Checkbox: FC<CheckboxProps> = ({ status, onChange, ...props }) => {
  const input = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (input.current) input.current.indeterminate = indeterminate(status)
  })

  return (
    <input
      type="checkbox"
      ref={input}
      checked={checked(status)}
      onChange={() => onChange(toggleStatus(status))}
      {...props}
    ></input>
  )
}

import { ComponentType } from "react"
import { FormattedMessage } from "react-intl"
import FormattedDuration from "react-intl-formatted-duration"

import { pipe } from "fp-ts/es6/function"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { Getter, Iso } from "monocle-ts"
import { dateAsTime, Duration } from "time-ts/es6"
import { prismToGetter } from "@fitnesspilot/data-common"

import { Timeline } from "@fitnesspilot/components-common/dist/atoms/Timeline/Timeline"
import { TimelineItem } from "@fitnesspilot/components-common/dist/atoms/Timeline/TimelineItem"
import { _ActivityInstanceExercise } from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"
import { _alignment } from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceExercise"
import * as Event from "@fitnesspilot/data-event/dist/calendar/Event"

const durationAsSeconds = (dur: Duration) => dur.total({ unit: "seconds" })

export type EventTimelineProps = {
  className?: string
  events: Array<Event.EventWithId>
  eventComponent: ComponentType<{ event: Event.EventWithId }>
}

export const EventTimeline = ({
  className,
  events,
  eventComponent: EventComponent,
}: EventTimelineProps) => (
  <Timeline {...{ className }}>
    {pipe(
      events,
      Arr.mapWithIndex((i, event) => (
        <TimelineItem
          key={i}
          header={
            <>
              <strong>
                {pipe(
                  event,
                  Event._eventWithId._value
                    .composeLens(Event._start)
                    .composeGetter(prismToGetter(dateAsTime)).get,
                  (start) => (
                    <FormattedMessage
                      defaultMessage="{start, time, short}"
                      values={{ start }}
                    />
                  ),
                )}
              </strong>
              <div>
                <FormattedDuration
                  unitDisplay="short"
                  seconds={pipe(
                    event,
                    Event._eventWithId._value
                      .composeGetter(Event._duration)
                      .composeGetter(new Getter(durationAsSeconds)).get,
                  )}
                />
              </div>
            </>
          }
        >
          <EventComponent
            key={pipe(
              event,
              Event._eventWithId._id.composeGetter(
                prismToGetter(Event.stringAsEventId),
              ).get,
            )}
            {...{ event }}
          />
        </TimelineItem>
      )),
    )}
  </Timeline>
)

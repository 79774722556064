/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from "../runtime";
import { JobFromJSON, JobToJSON } from "./Job";
import { LunchtimeFromJSON, LunchtimeToJSON, } from "./Lunchtime";
import { UserSleepScheduleFromJSON, UserSleepScheduleToJSON, } from "./UserSleepSchedule";
/**
 * Check if a given object implements the UserWork interface.
 */
export function instanceOfUserWork(value) {
    let isInstance = true;
    isInstance = isInstance && "schedule" in value;
    isInstance = isInstance && "job" in value;
    isInstance = isInstance && "mayEatAtWork" in value;
    return isInstance;
}
export function UserWorkFromJSON(json) {
    return UserWorkFromJSONTyped(json, false);
}
export function UserWorkFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        schedule: UserSleepScheduleFromJSON(json.schedule),
        job: JobFromJSON(json.job),
        lunchtime: !exists(json, "lunchtime")
            ? undefined
            : LunchtimeFromJSON(json.lunchtime),
        mayEatAtWork: json.mayEatAtWork,
    };
}
export function UserWorkToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        schedule: UserSleepScheduleToJSON(value.schedule),
        job: JobToJSON(value.job),
        lunchtime: LunchtimeToJSON(value.lunchtime),
        mayEatAtWork: value.mayEatAtWork,
    };
}

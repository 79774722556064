import { ReactNode, useMemo, useState } from "react"
import { FormattedMessage } from "react-intl"
import { Col, Row } from "reactstrap"
import styled from "@emotion/styled"

import * as Bool from "fp-ts/es6/boolean"
import { constNull, flow, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import { dateAsTime } from "time-ts/es6"
import { prismToGetter } from "@fitnesspilot/data-common"

import { ActivityImage as ActivityImage_ } from "@fitnesspilot/components-activity/dist/atoms/ActivityImage/ActivityImage"
import { ActivityName } from "@fitnesspilot/components-activity/dist/atoms/ActivityName/ActivityName"
import { Alignment } from "@fitnesspilot/components-human-body/dist/molecules/Alignment/Alignment"
import {
  _ActivityInstanceExercise,
  ActivityInstance,
  flattenActivityInstance,
} from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"
import { _alignment } from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceExercise"
import { _start, Event } from "@fitnesspilot/data-event/dist/calendar/Event"

import { ActivityMessage } from "../../atoms/ActivityMessage/ActivityMessage"

const EventTitle = styled.h2`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 10px 0;
`

const ActivityImage = styled(ActivityImage_)`
  width: 60px;
  height: 60px;
`

const ContentCol = styled(Col)`
  display: flex;
  justify-content: space-between;
`

type EventSummaryActivityProps = {
  className?: string
  activity: ActivityInstance
}
const EventSummaryActivity = styled(
  ({ className, activity }: EventSummaryActivityProps) => {
    const [descriptionIsOpen, setDescriptionIsOpen] = useState(false)

    return (
      <>
        {pipe(
          activity,
          flattenActivityInstance,
          Arr.mapWithIndex((i, activity) => (
            <Row key={i} {...{ className }}>
              <Col xs="auto">
                <ActivityImage value={activity.value.activity.id} />
              </Col>
              <ContentCol>
                <div>
                  <strong>
                    <ActivityName
                      id={activity.value.activity.id}
                      def={activity.value.activity.value.title}
                    />
                  </strong>
                  <div>
                    <ActivityMessage {...{ activity }} />
                  </div>
                </div>
                {pipe(
                  activity,
                  _ActivityInstanceExercise.getOption,
                  Opt.fold(
                    constNull,
                    flow(_alignment.get, (value) => (
                      <Alignment
                        {...{ value, descriptionIsOpen }}
                        onDescriptionIsOpenChange={(v) => () =>
                          setDescriptionIsOpen(v)
                        }
                      />
                    )),
                  ),
                )}
              </ContentCol>
            </Row>
          )),
        )}
      </>
    )
  },
)`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`

const TimeContainer = styled.strong`
  margin-left: 10px;
  font-size: 0.75em;
`

export type EventSummaryProps = {
  className?: string
  event: Event
  activityLimit?: number
  showTime?: boolean
  children?: ReactNode
}

export const EventSummary = ({
  className,
  event,
  activityLimit,
  showTime = false,
  children,
}: EventSummaryProps) => {
  const [descriptionIsOpen, setDescriptionIsOpen] = useState(false)

  const [activities, moreActivities] = useMemo(
    () =>
      pipe(
        activityLimit,
        Opt.fromNullable,
        Opt.fold<
          number,
          readonly [
            ReadonlyArray<ActivityInstance>,
            ReadonlyArray<ActivityInstance>,
          ]
        >(
          () => [event.activities, []],
          (limit) => pipe(event.activities, Arr.splitAt(limit)),
        ),
      ),
    [event, activityLimit],
  )

  return (
    <div {...{ className }}>
      <EventTitle>
        <div>
          {event.title}
          {pipe(
            showTime,
            Bool.fold(constNull, () =>
              pipe(
                event,
                _start.composeGetter(prismToGetter(dateAsTime)).get,
                (start) => (
                  <TimeContainer>
                    <FormattedMessage
                      defaultMessage="{start, date, ::yMdHm}"
                      values={{ start }}
                    />
                  </TimeContainer>
                ),
              ),
            ),
          )}
        </div>
        {pipe(event.alignment, (value) => (
          <Alignment
            {...{ value, descriptionIsOpen }}
            onDescriptionIsOpenChange={(v) => () => setDescriptionIsOpen(v)}
          />
        ))}
      </EventTitle>
      {pipe(
        activities,
        Arr.mapWithIndex((i, activity) => (
          <EventSummaryActivity key={i} {...{ activity }} />
        )),
      )}
      {pipe(
        moreActivities,
        NonEmpty.fromReadonlyArray,
        Opt.fold(constNull, (as) => (
          <div>
            <FormattedMessage
              defaultMessage="And {count} more {count, plural, one {activity} other {activities}}"
              values={{ count: as.length }}
            />
          </div>
        )),
      )}
      {children}
    </div>
  )
}

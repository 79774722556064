import { FaTrash } from "react-icons/fa"
import styled from "@emotion/styled"

import { OmitFromKnownKeys } from "@fitnesspilot/data-common"

import {
  ClickableWithIcon,
  ClickableWithIconProps,
} from "../ClickableWithIcon/ClickableWithIcon"

export type DeleteButtonProps = OmitFromKnownKeys<
  ClickableWithIconProps,
  "icon"
>

export const DeleteButton = styled((props: DeleteButtonProps) => (
  <ClickableWithIcon icon={<FaTrash />} {...props} />
))`
  .c-icon {
    color: ${({ theme }) => theme.colours.danger.toString()};
  }
`

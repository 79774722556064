import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"

import api, {
  AnyAPIError,
  API,
  APIError,
  ApiErrorCode,
  UserAPIError,
} from "@fitnesspilot/api"

import epic from "./epics"
import reducer from "./reducers"
import * as selectors from "./selectors"
import { ParentState } from "./state"

export * from "./actions"
export * from "./state"
export { epic, reducer, selectors }
export { AnyAPIError, API, APIError, ApiErrorCode, UserAPIError }

const _default: (s: ParentState) => API = (s) => {
  const baseUrl = pipe(
    s,
    selectors.state.compose(selectors.baseURL).get,
    (url) =>
      url.pathname.endsWith("/") ? String(url).replace(/\/$/, "") : String(url),
  )
  const getToken = () =>
    String(Opt.toNullable(selectors.state.compose(selectors.token).get(s)))

  return api(baseUrl, getToken)
}
export default _default

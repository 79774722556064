import * as Bool from "fp-ts/es6/boolean"
import * as Eq from "fp-ts/es6/Eq"
import { pipe } from "fp-ts/es6/function"
import * as Num from "fp-ts/es6/number"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Str from "fp-ts/es6/string"
import { Lens } from "monocle-ts"
import { unsafeFromSome } from "@fitnesspilot/data-common"

import {
  Intensity,
  intensity,
  numberAsIntensity,
} from "@fitnesspilot/data-human-body/dist/Intensity"

import { _withId, WithId } from "../activity/WithId"
import { ExerciseSlot, exerciseSlot } from "./ExerciseSlot"
import { SportId, sportId } from "./SportId"

export type Sport = {
  name: Opt.Option<string>
  instructions: Opt.Option<string>
  isLiked: boolean
  defaultIntensity: Intensity
  frequency: number
  workoutMethod: Opt.Option<string>
  workoutTarget: Opt.Option<string>
  fixedSchedule: boolean
  exerciseSlots: ReadonlyArray<ExerciseSlot>
}
export const sport = Eq.struct<Sport>({
  name: Opt.getEq(Str.Eq),
  instructions: Opt.getEq(Str.Eq),
  isLiked: Bool.Eq,
  defaultIntensity: intensity,
  frequency: Num.Eq,
  workoutMethod: Opt.getEq(Str.Eq),
  workoutTarget: Opt.getEq(Str.Eq),
  fixedSchedule: Bool.Eq,
  exerciseSlots: Arr.getEq(exerciseSlot),
})
export const _defaultIntensity = Lens.fromProp<Sport>()("defaultIntensity")
export const _name = Lens.fromProp<Sport>()("name")
export const _instructions = Lens.fromProp<Sport>()("instructions")
export const _isLiked = Lens.fromProp<Sport>()("isLiked")
export const _frequency = Lens.fromProp<Sport>()("frequency")
export const _workoutMethod = Lens.fromProp<Sport>()("workoutMethod")
export const _workoutTarget = Lens.fromProp<Sport>()("workoutTarget")
export const _fixedSchedule = Lens.fromProp<Sport>()("fixedSchedule")
export const _exerciseSlots = Lens.fromProp<Sport>()("exerciseSlots")

export type SportWithId = WithId<SportId, Sport>

export const sportWithIdOptics = _withId<SportId, Sport>(sportId)

export const empty: Sport = {
  name: Opt.none,
  instructions: Opt.none,
  isLiked: false,
  defaultIntensity: pipe(0.8, numberAsIntensity.getOption, unsafeFromSome),
  frequency: 1,
  workoutMethod: Opt.none,
  workoutTarget: Opt.none,
  fixedSchedule: false,
  exerciseSlots: [],
}

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from "../runtime";
import { ActivityInstanceFromJSON, ActivityInstanceToJSON, } from "./ActivityInstance";
import { EventSourceFromJSON, EventSourceToJSON, } from "./EventSource";
import { EventTypeFromJSON, EventTypeToJSON, } from "./EventType";
import { RecurringPatternFromJSON, RecurringPatternToJSON, } from "./RecurringPattern";
import { ScheduledMuscleFromJSON, ScheduledMuscleToJSON, } from "./ScheduledMuscle";
import { UnitsOverrideFromJSON, UnitsOverrideToJSON, } from "./UnitsOverride";
import { WorkoutVideoFromJSON, WorkoutVideoToJSON, } from "./WorkoutVideo";
/**
 * Check if a given object implements the Event interface.
 */
export function instanceOfEvent(value) {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "alignment" in value;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "end" in value;
    isInstance = isInstance && "isRecommendation" in value;
    isInstance = isInstance && "isBestMatch" in value;
    isInstance = isInstance && "recurringPattern" in value;
    isInstance = isInstance && "recurringInterval" in value;
    return isInstance;
}
export function EventFromJSON(json) {
    return EventFromJSONTyped(json, false);
}
export function EventFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? undefined : json.id,
        source: !exists(json, "source")
            ? undefined
            : EventSourceFromJSON(json.source),
        title: !exists(json, "title") ? undefined : json.title,
        type: EventTypeFromJSON(json.type),
        video: !exists(json, "video")
            ? undefined
            : WorkoutVideoFromJSON(json.video),
        alignment: json.alignment,
        start: new Date(json.start),
        end: new Date(json.end),
        confirmed: !exists(json, "confirmed")
            ? undefined
            : json.confirmed === null
                ? null
                : new Date(json.confirmed),
        isRecommendation: json.isRecommendation,
        isBestMatch: json.isBestMatch,
        recurringPattern: RecurringPatternFromJSON(json.recurringPattern),
        recurringInterval: json.recurringInterval,
        daysOfWeek: !exists(json, "daysOfWeek") ? undefined : json.daysOfWeek,
        dayOfMonth: !exists(json, "dayOfMonth") ? undefined : json.dayOfMonth,
        weekOfMonth: !exists(json, "weekOfMonth") ? undefined : json.weekOfMonth,
        dayofweek: !exists(json, "dayofweek") ? undefined : json.dayofweek,
        recurrenceStart: !exists(json, "recurrenceStart")
            ? undefined
            : json.recurrenceStart === null
                ? null
                : new Date(json.recurrenceStart),
        recurrenceEnd: !exists(json, "recurrenceEnd")
            ? undefined
            : json.recurrenceEnd === null
                ? null
                : new Date(json.recurrenceEnd),
        activities: !exists(json, "activities")
            ? undefined
            : json.activities === null
                ? null
                : json.activities.map(ActivityInstanceFromJSON),
        scheduledMuscles: !exists(json, "scheduledMuscles")
            ? undefined
            : json.scheduledMuscles === null
                ? null
                : json.scheduledMuscles.map(ScheduledMuscleFromJSON),
        units: !exists(json, "units")
            ? undefined
            : UnitsOverrideFromJSON(json.units),
    };
}
export function EventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        source: EventSourceToJSON(value.source),
        title: value.title,
        type: EventTypeToJSON(value.type),
        video: WorkoutVideoToJSON(value.video),
        alignment: value.alignment,
        start: value.start.toISOString(),
        end: value.end.toISOString(),
        confirmed: value.confirmed === undefined
            ? undefined
            : value.confirmed === null
                ? null
                : value.confirmed.toISOString(),
        isRecommendation: value.isRecommendation,
        isBestMatch: value.isBestMatch,
        recurringPattern: RecurringPatternToJSON(value.recurringPattern),
        recurringInterval: value.recurringInterval,
        daysOfWeek: value.daysOfWeek,
        dayOfMonth: value.dayOfMonth,
        weekOfMonth: value.weekOfMonth,
        dayofweek: value.dayofweek,
        recurrenceStart: value.recurrenceStart === undefined
            ? undefined
            : value.recurrenceStart === null
                ? null
                : value.recurrenceStart.toISOString(),
        recurrenceEnd: value.recurrenceEnd === undefined
            ? undefined
            : value.recurrenceEnd === null
                ? null
                : value.recurrenceEnd.toISOString(),
        activities: value.activities === undefined
            ? undefined
            : value.activities === null
                ? null
                : value.activities.map((a) => ActivityInstanceToJSON(a)),
        scheduledMuscles: value.scheduledMuscles === undefined
            ? undefined
            : value.scheduledMuscles === null
                ? null
                : value.scheduledMuscles.map(ScheduledMuscleToJSON),
        units: UnitsOverrideToJSON(value.units),
    };
}

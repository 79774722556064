import { FC, HTMLAttributes } from "react"

import * as Opt from "fp-ts/es6/Option"

import { ImageObject } from "@fitnesspilot/data-common/dist/image"
import { PartialNotOptional } from "@fitnesspilot/data-common/dist/type"

export type ImageProps = {
  image: PartialNotOptional<ImageObject, "contentUrl">
} & Omit<HTMLAttributes<HTMLImageElement>, "src" | "alt" | "children">

export const Image: FC<ImageProps> = ({
  image: { contentUrl, description },
  ...props
}) => (
  <img src={String(contentUrl)} alt={Opt.toUndefined(description)} {...props} />
)

import { IntlShape } from "react-intl"

import * as Bool from "fp-ts/es6/boolean"
import * as E from "fp-ts/es6/Either"
import { flow, identity, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as St from "fp-ts/es6/ReadonlySet"
import * as Str from "fp-ts/es6/string"
import { fromTraversable } from "monocle-ts"
import { arrTrav } from "@fitnesspilot/data-common"

import { activityName } from "@fitnesspilot/components-activity/dist/atoms/ActivityName/ActivityName"
import { activityTagName } from "@fitnesspilot/components-activity/dist/atoms/ActivityTagName/ActivityTagName"
import * as Activity from "@fitnesspilot/data-activity/dist/activity/Activity"
import * as ActivityExercise from "@fitnesspilot/data-activity/dist/activity/ActivityExercise"
import { ActivityId } from "@fitnesspilot/data-activity/dist/activity/ActivityId"
import * as ActivityTag from "@fitnesspilot/data-activity/dist/activity/ActivityTag"
import * as WithId from "@fitnesspilot/data-activity/dist/activity/WithId"
import * as ActivityInstance from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"
import {
  _activities,
  _title,
  EditingEvent,
} from "@fitnesspilot/data-event/dist/store/EditingEvent"

type TitleOrTags = E.Either<
  string,
  NonEmpty.ReadonlyNonEmptyArray<ActivityTag.ActivityTag>
>

const getExerciseTitleOrTags =
  (intl: IntlShape) =>
  (id: ActivityId) =>
  (exercise: ActivityExercise.ActivityExercise): TitleOrTags =>
    pipe(
      exercise,
      ActivityExercise._tags.get,
      St.toReadonlyArray(ActivityTag.activityTag),
      NonEmpty.fromReadonlyArray,
      Opt.fold(
        () =>
          pipe(
            intl.formatMessage({
              defaultMessage: "Activity",
            }),
            E.left,
          ),
        (tags) =>
          pipe(
            tags,
            Arr.elem(ActivityTag.activityTag)(ActivityTag.ActivityTag.sport),
            Bool.fold<TitleOrTags>(
              () =>
                pipe(
                  tags,
                  Arr.elem(ActivityTag.activityTag)(
                    ActivityTag.ActivityTag.other,
                  ),
                  Bool.fold(
                    () => E.right(tags),
                    () =>
                      pipe(
                        activityName(intl)({ id, def: exercise.title }),
                        E.left,
                      ),
                  ),
                ),
              () =>
                pipe(activityName(intl)({ id, def: exercise.title }), E.left),
            ),
          ),
      ),
    )

const getTitleOrTags =
  (intl: IntlShape) =>
  (activity: Activity.ActivityWithId): TitleOrTags =>
    pipe(
      activity,
      WithId._value<ActivityId, Activity.Activity>().get,
      Activity.foldActivity(
        getExerciseTitleOrTags(intl)(activity.id),
        () =>
          pipe(
            intl.formatMessage({
              defaultMessage: "Work",
            }),
            E.left,
          ),
        () =>
          pipe(
            intl.formatMessage({
              defaultMessage: "Workout",
            }),
            E.left,
          ),
        () =>
          pipe(
            intl.formatMessage({
              defaultMessage: "Sleep",
            }),
            E.left,
          ),
      ),
    )

const mainTagFromTitlesAndTags = (titlesAndTags: ReadonlyArray<TitleOrTags>) =>
  pipe(
    titlesAndTags,
    Arr.filterMap(E.fold(() => Opt.none, Opt.some)),
    Arr.flatten,
    Arr.reduce({} as Record<ActivityTag.ActivityTag, number>, (r, tag) => ({
      ...r,
      [tag]: (r[tag] ?? 0) + 1,
    })),
    Rec.reduceWithIndex(Str.Ord)(
      [Opt.none, 0] as readonly [Opt.Option<ActivityTag.ActivityTag>, number],
      (k, [rk, rv], v) =>
        v > rv ? ([Opt.some(k), v] as const) : ([rk, rv] as const),
    ),
    ([mainTag]) => mainTag,
  )

export const defaultEventTitle = (intl: IntlShape) => (event: EditingEvent) =>
  pipe(
    event,
    _title.get,
    flow(
      Opt.fold(
        () =>
          pipe(
            event,
            _activities
              .composeTraversal(arrTrav())
              .composeGetter(ActivityInstance.activitiesFromActivityInstance)
              .composeTraversal(fromTraversable(Arr.Traversable)()).getAll,
            Arr.map(getTitleOrTags(intl)),
            (titlesAndTags) =>
              pipe(titlesAndTags, mainTagFromTitlesAndTags, (mainTag) =>
                pipe(
                  titlesAndTags,
                  Arr.filterMap(E.fold(Opt.some, () => Opt.none)),
                  Arr.uniq(Str.Eq),
                  pipe(
                    mainTag,
                    Opt.fold<
                      ActivityTag.ActivityTag,
                      (as: ReadonlyArray<string>) => ReadonlyArray<string>
                    >(
                      () => identity,
                      (id) => pipe(activityTagName(intl)({ id }), Arr.prepend),
                    ),
                  ),
                  NonEmpty.fromReadonlyArray,
                  Opt.getOrElse(() =>
                    pipe(
                      intl.formatMessage({
                        defaultMessage: "Event",
                      }),
                      NonEmpty.of,
                    ),
                  ),
                  (titles) =>
                    pipe(
                      titles.length > 1,
                      Bool.fold(
                        () => pipe(titles, NonEmpty.head),
                        () =>
                          pipe(
                            titles,
                            NonEmpty.splitAt(titles.length - 1),
                            ([as, a]) => `${as.join(", ")} & ${a[0]}`,
                          ),
                      ),
                    ),
                ),
              ),
          ),
        identity,
      ),
    ),
  )

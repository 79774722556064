import { useEffect } from "react"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { FormattedMessage } from "react-intl"

import * as Bool from "fp-ts/es6/boolean"
import { flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import {
  Dimension,
  numberAsValidNumber,
  ValidNumber,
  valueInCanonicalUnit,
  ValueWithCanonicalUnit,
} from "@fitnesspilot/data-common"

import {
  formGroupRender,
  FormGroupRenderProps,
  wrapPrismOptFallback,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"
import { ControllerDimensionalNumberContainer } from "@fitnesspilot/components-user/dist/organisms/ControllerDimensionalNumber/ControllerDimensionalNumber"
import {
  _distance,
  ActivityInstanceExercise,
} from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceExercise"

type FormData = {
  distance: Opt.Option<
    ValueWithCanonicalUnit<Dimension.lengthDistance, ValidNumber>
  >
}

export type ActivityDistanceProps = {
  id: string
  activity: ActivityInstanceExercise
  optional?: boolean
  onChange: (activity: ActivityInstanceExercise) => IO.IO<void>
}

export const ActivityDistance = ({
  id,
  activity,
  optional,
  onChange,
}: ActivityDistanceProps) => {
  const { handleSubmit, ...form } = useForm<FormData>({})

  useEffect(() => {
    form.reset({
      distance: activity.distance,
    })
  }, [activity.distance])

  const onSubmit = handleSubmit(({ distance }) =>
    pipe(activity, _distance.set(distance), onChange)(),
  )

  const distance:
    | Opt.Option<ValueWithCanonicalUnit<Dimension.lengthDistance, ValidNumber>>
    | undefined = useWatch({ control: form.control, name: "distance" })
  const distance_ = pipe(
    distance,
    Opt.fromNullable,
    Opt.map(
      Opt.fold(
        () => null,
        flow(
          valueInCanonicalUnit(Dimension.lengthDistance).reverseGet,
          numberAsValidNumber.reverseGet,
        ),
      ),
    ),
    Opt.getOrElse<number | null | undefined>(() => undefined),
  )
  useEffect(
    pipe(
      distance_ !== undefined,
      Bool.fold(
        () => IO.of(undefined),
        () => () => void onSubmit(),
      ),
    ),
    [distance_],
  )

  // TODO handle optional
  return (
    <FormProvider {...{ handleSubmit }} {...form}>
      <form {...{ onSubmit }}>
        <ControllerDimensionalNumberContainer<
          FormData,
          "distance",
          Dimension.lengthDistance,
          FormGroupRenderProps
        >
          name="distance"
          dimension={Dimension.lengthDistance}
          transform={wrapPrismOptFallback}
          renderTransform={formGroupRender}
          renderTransformProps={{
            id: `${id}-distance`,
            label: <FormattedMessage defaultMessage="Distance" />,
          }}
        />
      </form>
    </FormProvider>
  )
}

import { FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import * as Rec from "fp-ts/es6/ReadonlyRecord"

import { FormAnswersProvided } from "@fitnesspilot/components-common/dist/atoms/AnswersProvided/AnswersProvided"
import { FieldsetHeader } from "@fitnesspilot/components-common/dist/atoms/FieldsetHeader/FieldsetHeader"
import {
  ControllerPlus,
  formGroupRender,
  noTransform,
  selectRender,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"
import { ConsumptionLevel } from "@fitnesspilot/data-user/dist/Habits"

export type FormData = {
  alcoholHabits: ConsumptionLevel
  smokingHabits: ConsumptionLevel
}
const FormDataKeys: Rec.ReadonlyRecord<keyof FormData, null> = {
  alcoholHabits: null,
  smokingHabits: null,
}

export type AddictionHabitsFieldsetProps = {
  id: string
  showAnswersProvided?: boolean
}

export const AddictionHabitsFieldset: FC<AddictionHabitsFieldsetProps> = ({
  id,
  showAnswersProvided,
}) => {
  const intl = useIntl()

  return (
    <>
      <FieldsetHeader
        title={<FormattedMessage defaultMessage="Addictions" />}
        titleRight={
          showAnswersProvided && (
            <FormAnswersProvided<FormData> keys={FormDataKeys} />
          )
        }
        description={
          <FormattedMessage defaultMessage="Most people don't want to sacrifice the joy of a glas of wine. And that's alright. Probably it's not a surprise, but your smoking and drinking behaviour have influence on your results. By telling us more we can consider this and give you useful informations. We don't want to change your lifestyle, but we want to give you transparency, so you can decide yourself with a health-conscious mind and your personal perspective." />
        }
      />

      <ControllerPlus<FormData, "alcoholHabits", ConsumptionLevel>
        render={formGroupRender(selectRender<ConsumptionLevel, "radio">())({
          id: `${id}-alcoholHabits`,
          type: "radio",
          label: <FormattedMessage defaultMessage="Alcohol" />,
          values: {
            [ConsumptionLevel.never]: intl.formatMessage({
              defaultMessage: "I don't drink at all",
            }),
            [ConsumptionLevel.occasionally]: intl.formatMessage({
              defaultMessage: "I drink occassionally, during social situations",
            }),
            [ConsumptionLevel.frequently]: intl.formatMessage({
              defaultMessage: "I drink a lot, I might be addicted",
            }),
          },
        })}
        name="alcoholHabits"
        transform={noTransform<FormData, "alcoholHabits">()}
        rules={{
          required: true,
        }}
      />

      <ControllerPlus<FormData, "smokingHabits", ConsumptionLevel>
        render={formGroupRender(selectRender<ConsumptionLevel, "radio">())({
          id: `${id}-smokingHabits`,
          type: "radio",
          label: <FormattedMessage defaultMessage="Smoking" />,
          values: {
            [ConsumptionLevel.never]: intl.formatMessage({
              defaultMessage: "I don't smoke at all",
            }),
            [ConsumptionLevel.occasionally]: intl.formatMessage({
              defaultMessage: "I smoke occassionally, not addicted",
            }),
            [ConsumptionLevel.frequently]: intl.formatMessage({
              defaultMessage: "I smoke a lot and I'm addicted",
            }),
          },
        })}
        name="smokingHabits"
        transform={noTransform<FormData, "smokingHabits">()}
        rules={{
          required: true,
        }}
      />
    </>
  )
}

import { FC, useContext } from "react"
import { FormattedMessage } from "react-intl"
import FormattedDuration from "react-intl-formatted-duration"
import styled from "@emotion/styled"

import { constNull, flow, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { concatDuration, Duration, emptyDuration } from "time-ts/es6"
import { Dimension, valueInCanonicalUnit } from "@fitnesspilot/data-common"

import { UnitContext } from "@fitnesspilot/components-common/dist/atoms/IntlProvider/IntlProvider"
import { foldActivityCategory } from "@fitnesspilot/data-activity/dist/activity/ActivityCategory"
import { _AnyWeightMass } from "@fitnesspilot/data-activity/dist/activity/Equipment"
import {
  ActivityInstance,
  foldActivityInstance,
} from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"
import { ActivityInstanceExercise } from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceExercise"
import * as ActivityInstanceGroup from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceGroup"
import * as ActivityInstanceJob from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceJob"
import * as ActivityInstanceMuscles from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceMuscles"
import * as ActivityInstanceSleep from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceSleep"

const durationAsSeconds = (dur: Duration) => dur.total({ unit: "seconds" })

const DurationMessage = ({ duration }: { duration: Duration }) => (
  <FormattedDuration
    unitDisplay="long"
    seconds={pipe(duration, durationAsSeconds)}
  />
)

const ExerciseSetsMessage = ({
  activity,
}: {
  activity: ActivityInstanceExercise
}) => (
  <FormattedMessage
    defaultMessage="{count, plural,
                      one {# set}
                      other {# sets}
                    }"
    values={{
      count: activity.sets.length,
    }}
  />
)

const ExerciseDurationMessage: FC<{ activity: ActivityInstanceExercise }> = ({
  activity,
}) =>
  pipe(
    activity.duration,
    Opt.map((duration) => <DurationMessage key="duration" {...{ duration }} />),
    Opt.toNullable,
  )

const ExerciseDistanceMessage = ({
  activity,
}: {
  activity: ActivityInstanceExercise
}) => {
  const units = useContext(UnitContext)

  return (
    <>
      {pipe(
        activity.duration,
        Opt.map((duration) => (
          <>
            <DurationMessage key="duration" {...{ duration }} />,{" "}
          </>
        )),
        Opt.toNullable,
      )}
      {pipe(
        activity.distance,
        Opt.map((distance) => (
          <FormattedMessage
            key="distance"
            defaultMessage="{distance, number, lengthDistance}"
            values={{
              distance: pipe(
                distance,
                valueInCanonicalUnit(Dimension.lengthDistance).reverseGet,
              ),
              unit: units.lengthDistance,
            }}
          />
        )),
        Opt.toNullable,
      )}
    </>
  )
}

export type ActivityMessageProps = {
  className?: string
  activity: ActivityInstance
}

const activityInstanceDurtaion: (v: ActivityInstance) => Duration =
  foldActivityInstance(
    (a) =>
      pipe(
        a.duration,
        Opt.getOrElse(() => emptyDuration),
      ),
    (a) => a.duration,
    (a) =>
      pipe(
        a.duration,
        Opt.getOrElse(() => emptyDuration),
      ),
    (a) => a.duration,
    (a) =>
      pipe(
        a.activities,
        Arr.reduce(emptyDuration, (r, a) =>
          concatDuration(r)(activityInstanceDurtaion(a)),
        ),
      ),
  )

export const ActivityMessage = styled(
  ({ className, activity }: ActivityMessageProps) =>
    pipe(
      activity,
      foldActivityInstance(
        (a) =>
          foldActivityCategory(
            () => <ExerciseSetsMessage activity={a} />,
            () => <ExerciseSetsMessage activity={a} />,
            () => <ExerciseDurationMessage activity={a} />,
            () => <ExerciseDurationMessage activity={a} />,
            () => <ExerciseDistanceMessage activity={a} />,
            () => null,
          )(a.activity.value.category),
        flow(ActivityInstanceJob._duration.get, (duration) => (
          <DurationMessage {...{ duration }} />
        )),
        flow(
          ActivityInstanceMuscles._duration.get,
          Opt.fold(constNull, (duration) => (
            <DurationMessage {...{ duration }} />
          )),
        ),
        flow(ActivityInstanceSleep._duration.get, (duration) => (
          <DurationMessage {...{ duration }} />
        )),
        flow(
          ActivityInstanceGroup._activities.get,
          Arr.reduce(emptyDuration, (r, a) =>
            concatDuration(r)(activityInstanceDurtaion(a)),
          ),
          (duration) => <DurationMessage {...{ duration }} />,
        ),
      ),
    ),
)`
  display: flex;
  font-variant-numeric: tabular-nums;
  & > * {
    margin: 0 4px;
  }
`

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from "../runtime";
import { ExerciseSlotFromJSON, ExerciseSlotToJSON, } from "./ExerciseSlot";
/**
 * Check if a given object implements the Sport interface.
 */
export function instanceOfSport(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isLiked" in value;
    isInstance = isInstance && "frequency" in value;
    isInstance = isInstance && "fixedSchedule" in value;
    isInstance = isInstance && "defaultIntensity" in value;
    return isInstance;
}
export function SportFromJSON(json) {
    return SportFromJSONTyped(json, false);
}
export function SportFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: json.id,
        name: !exists(json, "name") ? undefined : json.name,
        instructions: !exists(json, "instructions") ? undefined : json.instructions,
        isLiked: json.isLiked,
        frequency: json.frequency,
        fixedSchedule: json.fixedSchedule,
        exerciseSlots: !exists(json, "exerciseSlots")
            ? undefined
            : json.exerciseSlots === null
                ? null
                : json.exerciseSlots.map(ExerciseSlotFromJSON),
        workoutMethod: !exists(json, "workoutMethod")
            ? undefined
            : json.workoutMethod,
        workoutTarget: !exists(json, "workoutTarget")
            ? undefined
            : json.workoutTarget,
        defaultIntensity: json.defaultIntensity,
    };
}
export function SportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        name: value.name,
        instructions: value.instructions,
        isLiked: value.isLiked,
        frequency: value.frequency,
        fixedSchedule: value.fixedSchedule,
        exerciseSlots: value.exerciseSlots === undefined
            ? undefined
            : value.exerciseSlots === null
                ? null
                : value.exerciseSlots.map(ExerciseSlotToJSON),
        workoutMethod: value.workoutMethod,
        workoutTarget: value.workoutTarget,
        defaultIntensity: value.defaultIntensity,
    };
}

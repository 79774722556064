import * as Eq from "fp-ts/es6/Eq"
import { flow, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Ordering from "fp-ts/es6/Ordering"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { At, fromTraversable, Lens } from "monocle-ts"

export type WithId<id, value> = {
  id: id
  value: value
}
export const withIdOrd = <id, value>(id: Ord.Ord<id>, value: Ord.Ord<value>) =>
  Ord.fromCompare<WithId<id, value>>((a, b) =>
    Ordering.Monoid.concat(
      id.compare(a.id, b.id),
      value.compare(a.value, b.value),
    ),
  )
export const withIdEq = <id, value>(id: Eq.Eq<id>, value: Eq.Eq<value>) =>
  Eq.struct<WithId<id, value>>({
    id,
    value,
  })
export const _id = <id, value>() => Lens.fromProp<WithId<id, value>>()("id")
export const _value = <id, value>() =>
  Lens.fromProp<WithId<id, value>>()("value")
export const _withIdAt = <id, value>(idEq: Eq.Eq<id>) =>
  new At<ReadonlyArray<WithId<id, value>>, id, Opt.Option<value>>(
    (id) =>
      new Lens<ReadonlyArray<WithId<id, value>>, Opt.Option<value>>(
        (v) =>
          fromTraversable(Arr.Traversable)<WithId<id, value>>()
            .filter(flow(_id<id, value>().get, (a) => idEq.equals(a, id)))
            .composeLens(_value<id, value>())
            .asFold()
            .headOption(v),
        (a) => (s) =>
          pipe(
            a,
            Opt.fold(
              () =>
                fromTraversable(Arr.Traversable)<WithId<id, value>>()
                  .filter(
                    flow(_id<id, value>().get, (a) => !idEq.equals(a, id)),
                  )
                  .asFold()
                  .getAll(s),
              (a_) =>
                fromTraversable(Arr.Traversable)<WithId<id, value>>()
                  .filter(flow(_id<id, value>().get, (a) => idEq.equals(a, id)))
                  .composeLens(_value<id, value>())
                  .set(a_)(s),
            ),
          ),
      ),
  )
export const _withId = <id, value>(idEq: Eq.Eq<id>) => ({
  _id: _id<id, value>(),
  _value: _value<id, value>(),
  _at: _withIdAt<id, value>(idEq),
})

import { pipe } from "fp-ts/es6/function";
import * as Opt from "fp-ts/es6/Option";
import { Iso, Prism } from "monocle-ts";
import { ioTsPrism, liftIso, liftIso2 } from "../internal";
import * as TimeOfDayDuration from "../UTC/TimeOfDayDuration";
import { isoLocalDuration } from "./Duration";
import { isoLocal, local } from "./Local";
import { Temporal } from "@js-temporal/polyfill";
import * as t from "io-ts";
export const isoLocalTimeOfDayDuration = isoLocal();
export const mkLocalTimeOfDayDuration = (hours, minutes, seconds) => isoLocalTimeOfDayDuration.reverseGet(TimeOfDayDuration.mkTimeOfDayDuration(hours, minutes, seconds));
export const _hours = isoLocalTimeOfDayDuration.composeLens(TimeOfDayDuration._hours);
export const _minutes = isoLocalTimeOfDayDuration.composeLens(TimeOfDayDuration._minutes);
export const _seconds = isoLocalTimeOfDayDuration.composeLens(TimeOfDayDuration._seconds);
export const TemporalDurationAsLocalTimeOfDayDuration = new Prism(({ years, months, weeks, days, hours, minutes, seconds }) => years === 0 && months === 0 && weeks === 0 && days === 0
    ? Opt.some(TimeOfDayDuration.mkTimeOfDayDuration(hours, minutes, seconds))
    : Opt.none, ({ hours, minutes, seconds }) => Temporal.Duration.from({
    hours,
    minutes,
    microseconds: seconds * 1000000,
})).composeIso(isoLocalTimeOfDayDuration.reverse());
export const LocalDurationAsLocalTimeOfDayDuration = isoLocalDuration.composePrism(TemporalDurationAsLocalTimeOfDayDuration);
export const localTimeOfDayDuration = Object.assign(Object.assign({}, local(TimeOfDayDuration.timeOfDayDuration)), { concat: liftIso2(isoLocalTimeOfDayDuration)(TimeOfDayDuration.timeOfDayDuration.concat), empty: isoLocalTimeOfDayDuration.reverseGet(TimeOfDayDuration.timeOfDayDuration.empty), inverse: liftIso(isoLocalTimeOfDayDuration)(TimeOfDayDuration.timeOfDayDuration.inverse), add: (t, d) => t.add(isoLocalTimeOfDayDuration.get(d)), difference: (t, t2) => pipe(t.until(t2), TemporalDurationAsLocalTimeOfDayDuration.getOption, (v) => v.value) });
export const equals = (d2) => (d) => localTimeOfDayDuration.equals(d, d2);
export const compare = (d2) => (d) => localTimeOfDayDuration.compare(d, d2);
export const concat = (d2) => (d) => localTimeOfDayDuration.concat(d, d2);
export const empty = localTimeOfDayDuration.empty;
export const inverse = localTimeOfDayDuration.inverse;
export const add = (d) => (t) => localTimeOfDayDuration.add(t, d);
export const difference = (t2) => (t) => localTimeOfDayDuration.difference(t, t2);
export const isoStringAsLocalTimeOfDayDuration = TimeOfDayDuration.isoStringAsTimeOfDayDuration.composeIso(isoLocalTimeOfDayDuration.reverse());
export const localTimeOfDayDurationT = t.string.pipe(ioTsPrism("LocalTimeOfDayDuration", 
// @TODO
(a) => TimeOfDayDuration.timeOfDayDurationT.is(a), isoStringAsLocalTimeOfDayDuration));
export const millisecondsAsLocalTimeOfDayDuration = TimeOfDayDuration.millisecondsAsTimeOfDayDuration.composeIso(isoLocalTimeOfDayDuration.reverse());
export const secondsAsLocalTimeOfDayDuration = new Iso((s) => s * 1000, (s) => s / 1000).composeIso(millisecondsAsLocalTimeOfDayDuration);

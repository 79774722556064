import { ComponentType, FC } from "react"
import { connect, ConnectedProps } from "react-redux"
import styled from "@emotion/styled"

import {
  TemplateHeader,
  TemplateHeaderProps,
} from "../../molecules/TemplateHeader/TemplateHeader"

const mapState = (state: { loadingBar: { default: number } }) => ({
  progress: state.loadingBar.default,
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type OwnProps = Omit<TemplateHeaderProps, "isLoading">

export type LoadingTemplateHeaderProps = PropsFromRedux & OwnProps

export const LoadingTemplateHeader: FC<LoadingTemplateHeaderProps> = ({
  progress,
  ...props
}) => <TemplateHeader isLoading={!!progress} {...props} />

export const LoadingTemplateHeaderContainer = connector(LoadingTemplateHeader)

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Option1UnitDistanceFromJSON, Option1UnitDistanceToJSON, } from "./Option1UnitDistance";
import { Option1UnitHeightFromJSON, Option1UnitHeightToJSON, } from "./Option1UnitHeight";
import { Option1UnitMassFromJSON, Option1UnitMassToJSON, } from "./Option1UnitMass";
/**
 * Check if a given object implements the UnitOptions interface.
 */
export function instanceOfUnitOptions(value) {
    let isInstance = true;
    isInstance = isInstance && "height" in value;
    isInstance = isInstance && "mass" in value;
    isInstance = isInstance && "distance" in value;
    return isInstance;
}
export function UnitOptionsFromJSON(json) {
    return UnitOptionsFromJSONTyped(json, false);
}
export function UnitOptionsFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        height: json.height.map(Option1UnitHeightFromJSON),
        mass: json.mass.map(Option1UnitMassFromJSON),
        distance: json.distance.map(Option1UnitDistanceFromJSON),
    };
}
export function UnitOptionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        height: value.height.map(Option1UnitHeightToJSON),
        mass: value.mass.map(Option1UnitMassToJSON),
        distance: value.distance.map(Option1UnitDistanceToJSON),
    };
}

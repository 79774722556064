import { FC, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { FaUser } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import { Col, Row } from "reactstrap"

import * as Eq from "fp-ts/es6/Eq"
import { pipe } from "fp-ts/es6/function"
import * as Func from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import { day } from "time-ts/es6"
import {
  Dimension,
  optionLensToOptional,
  validNumber,
  valueWithCanonicalUnit,
} from "@fitnesspilot/data-common"

import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import { CancelButton } from "@fitnesspilot/components-common/dist/molecules/CancelButton/CancelButton"
import { Content } from "@fitnesspilot/components-common/dist/molecules/Content/Content"
import { Header } from "@fitnesspilot/components-common/dist/molecules/Header/Header"
import { SaveButton } from "@fitnesspilot/components-common/dist/molecules/SaveButton/SaveButton"
import { StickyFooter } from "@fitnesspilot/components-common/dist/molecules/StickyFooter/StickyFooter"
import { useFormChangeEffect } from "@fitnesspilot/components-common/dist/react"
import {
  BodyCompositionFieldset,
  FormData as BodyCompositionFormData,
} from "@fitnesspilot/components-human-body/dist/organisms/BodyCompositionFieldset/BodyCompositionFieldset"
import {
  FormData as HeightAndMassFormData,
  HeightAndMassFieldset,
} from "@fitnesspilot/components-human-body/dist/organisms/HeightAndMassFieldset/HeightAndMassFieldset"
import {
  FormData as SexAndBirthDateFormData,
  SexAndBirthDateFieldset,
} from "@fitnesspilot/components-human-body/dist/organisms/SexAndBirthDateFieldset/SexAndBirthDateFieldset"
import {
  sumBodyComposition,
  sumBodyCompositionAsBodyComposition,
  SumBodyCompositionTypes,
} from "@fitnesspilot/data-human-body/dist/bodyComposition"
import * as HumanBody from "@fitnesspilot/data-human-body/dist/humanBody"
import { sex } from "@fitnesspilot/data-human-body/dist/sex"
import {
  Action,
  ParentState,
  selectors,
  setBody,
} from "@fitnesspilot/data-user"

import { MainTemplate } from "../../templates/Main/Main"

import { Dispatch } from "redux"

const body = selectors.state.compose(selectors.body)

type FormData = SexAndBirthDateFormData &
  HeightAndMassFormData &
  BodyCompositionFormData
const formData = Eq.struct<FormData>({
  sex: Opt.getEq(sex),
  birthDate: Opt.getEq(day),
  bodyHeight: Opt.getEq(
    valueWithCanonicalUnit(Dimension.lengthHeight, validNumber),
  ),
  bodyMass: Opt.getEq(valueWithCanonicalUnit(Dimension.mass, validNumber)),
  bodyComposition: Opt.getEq(sumBodyComposition),
})

const mapState = (state: ParentState) => {
  const defaultValues: Partial<FormData> = {
    sex: pipe(
      state,
      body.composeOptional(optionLensToOptional(HumanBody.sex)).getOption,
    ),
    birthDate: pipe(
      state,
      body.composeOptional(optionLensToOptional(HumanBody.birthDate)).getOption,
    ),
    bodyHeight: pipe(
      state,
      body.composeOptional(optionLensToOptional(HumanBody.height)).getOption,
    ),
    bodyMass: pipe(
      state,
      body.composeOptional(optionLensToOptional(HumanBody.mass)).getOption,
    ),
    bodyComposition: pipe(
      state,
      body.composeOptional(optionLensToOptional(HumanBody.bodyComposition))
        .getOption,
      Opt.map((bc) => ({ ...bc, type: SumBodyCompositionTypes.relative })),
    ),
  }
  return { defaultValues }
}

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ =
    (act: Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, Func.constVoid)

  return {
    onSubmit: ({
      sex,
      birthDate,
      bodyHeight,
      bodyMass,
      bodyComposition,
    }: FormData) =>
      pipe(
        Arr.Traversable.traverse(IO.Applicative)(
          [
            setBody({
              sex,
              birthDate,
              height: bodyHeight,
              mass: bodyMass,
              bodyComposition,
            }),
          ],
          dispatch_,
        ),
        IO.map(Func.constVoid),
      )(),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type OwnProps = {
  id: string
}

export type MeAndMyBodyPageProps = PropsFromRedux & OwnProps

export const MeAndMyBodyPage: FC<MeAndMyBodyPageProps> = ({
  id,
  defaultValues,
  onSubmit,
}) => {
  const { handleSubmit, ...form } = useForm<FormData>({
    defaultValues,
  })

  useEffect(() => {
    if (!form.formState.isDirty) {
      form.reset(defaultValues)
    }
  }, [defaultValues])

  const submit = handleSubmit(onSubmit)
  useFormChangeEffect(form, formData, submit)

  return (
    <MainTemplate
      header={
        <Header
          icon={<FaUser />}
          title={<FormattedMessage defaultMessage="Me & my body" />}
        />
      }
    >
      <Content>
        <FormProvider {...{ handleSubmit }} {...form}>
          <form onSubmit={submit}>
            <Fieldset>
              <Row>
                <Col md={12} lg={8}>
                  <FormattedMessage defaultMessage="In order to prepare meaningful and accurate recommendations Fitnesspilot needs to know something about yourself. Once done we will be able to tell you when we expect you to accomplish your goals." />
                </Col>
              </Row>

              <Fieldset>
                <SexAndBirthDateFieldset
                  id={`${id}-sexAndBirthDate`}
                  showAnswersProvided
                />
              </Fieldset>

              <Fieldset>
                <HeightAndMassFieldset
                  id={`${id}-heightAndMass`}
                  showAnswersProvided
                />
              </Fieldset>

              <Fieldset>
                <BodyCompositionFieldset
                  id={`${id}-bodyComposition`}
                  showAnswersProvided
                />
              </Fieldset>
            </Fieldset>
          </form>
        </FormProvider>
      </Content>
    </MainTemplate>
  )
}

export const MeAndMyBodyPageContainer = connector(MeAndMyBodyPage)

import { flow, pipe } from "fp-ts/es6/function"
import * as Ord from "fp-ts/es6/Ord"
import { Newtype, prism } from "newtype-ts"
import {
  Dimension,
  numberAsValidNumber,
  UnitNoDim,
  unsafeFromSome,
  validNumber,
  valueInUnit,
  ValueWithUnit,
  valueWithUnit,
} from "@fitnesspilot/data-common"

const validNumberAsPercent = valueInUnit(Dimension.noDim, UnitNoDim.percent)
const numberAsPercent = numberAsValidNumber.composeIso(validNumberAsPercent)
const percent = valueWithUnit(Dimension.noDim, UnitNoDim.percent, validNumber)

export type Alignment = Newtype<
  { readonly Alignment: unique symbol },
  ValueWithUnit<Dimension.noDim, UnitNoDim.percent>
>
export const alignment: Ord.Ord<Alignment> = percent as any
export const percentAsAlignment = prism<Alignment>(
  Ord.between(percent)(
    pipe(0, numberAsPercent.getOption, unsafeFromSome),
    pipe(100, numberAsPercent.getOption, unsafeFromSome),
  ),
)
export const validNumberAsAlignment =
  validNumberAsPercent.composePrism(percentAsAlignment)
export const numberAsAlignment =
  numberAsPercent.composePrism(percentAsAlignment)
export const numberAsAlignmentUnsafe = flow(
  numberAsAlignment.getOption,
  unsafeFromSome,
)

import { AnyAPIError } from "@fitnesspilot/data-api"

import { KnownStepSet, StepSelection } from "../StepSet"

import { ActionType, createAction, createAsyncAction } from "typesafe-actions"

export const resetState = createAction("help/resetState")()

export const showIndex = createAction("help/showIndex")()
export const hideIndex = createAction("help/hideIndex")()

export const close = createAction("help/close")()
export const gotoStepAsync = createAsyncAction(
  "help/gotoStepAsync/request",
  "help/gotoStepAsync/success",
  "help/gotoStepAsync/failure",
)<StepSelection, StepSelection, AnyAPIError>()
export const gotoStep = gotoStepAsync.request
export const gotoStepSet = createAction(
  "help/gotoStepSet",
  (set: KnownStepSet) => ({ set }),
)()

export const fetchHelpStatusAsync = createAsyncAction(
  "help/fetchHelpStatusAsync/request",
  "help/fetchHelpStatusAsync/success",
  "help/fetchHelpStatusAsync/failure",
)<void, Partial<Record<KnownStepSet, Date>>, AnyAPIError>()
export const fetchHelpStatus = fetchHelpStatusAsync.request

export const saveHelpStatusAsync = createAsyncAction(
  "help/saveHelpStatusAsync/request",
  "help/saveHelpStatusAsync/success",
  "help/saveHelpStatusAsync/failure",
)<
  Partial<Record<KnownStepSet, Date>>,
  Partial<Record<KnownStepSet, Date>>,
  AnyAPIError
>()
export const saveHelpStatus = saveHelpStatusAsync.request

export const showSteps = createAction("help/showSteps")()

export type Action = ActionType<
  | typeof resetState
  | typeof showIndex
  | typeof hideIndex
  | typeof close
  | typeof gotoStepSet
  | (
      | typeof gotoStepAsync
      | typeof fetchHelpStatusAsync
      | typeof saveHelpStatusAsync
    )["request" | "success" | "failure"]
  | typeof showSteps
>

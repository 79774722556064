import * as Arr from "fp-ts/es6/ReadonlyArray"
import { fromTraversable, Lens } from "monocle-ts"
import { Duration } from "time-ts/es6"

// defined in `./ActivityInstance` to avoid circular dependency
import { ActivityInstance, ActivityInstanceGroup } from "./ActivityInstance"

export { ActivityInstance, ActivityInstanceGroup }

export const fromActivityGroup = (
  activities: ReadonlyArray<ActivityInstance>,
  repetitions: number,
  breakBetweenRepetitions: Duration,
): ActivityInstanceGroup => ({
  activities,
  repetitions,
  breakBetweenRepetitions,
})
export const _activities = Lens.fromProp<ActivityInstanceGroup>()("activities")
export const _activitiesTraversal = _activities.composeTraversal(
  fromTraversable(Arr.Traversable)(),
)
export const _repetitions =
  Lens.fromProp<ActivityInstanceGroup>()("repetitions")
export const _breakBetweenRepetitions = Lens.fromProp<ActivityInstanceGroup>()(
  "breakBetweenRepetitions",
)

import styled from "@emotion/styled"

export const CSSSettings = styled.div`
  display: contents;

  --template-header--height: 50px;
  @media (${({ theme }) => theme.breakpoints.sm}) and (min-height: 200px) {
    --template-header--height: 61px;
  }
  @media (${({ theme }) => theme.breakpoints.lg}) and (min-height: 400px) {
    --template-header--height: 100px;
  }
`

import { ComponentProps, FC } from "react"

import { OmitFromKnownKeys } from "@fitnesspilot/data-common"

import Markdown_ from "markdown-to-jsx"

export type MarkdownProps = OmitFromKnownKeys<
  ComponentProps<typeof Markdown_>,
  "options"
>

export const Markdown: FC<MarkdownProps> = (props) => <Markdown_ {...props} />

import { FC, useMemo } from "react"
import { FormattedMessage } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import { Navigate, useLocation, useSearchParams } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import styled from "@emotion/styled"

import { constVoid, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as CommonData from "@fitnesspilot/data-common"

import { CoachMessage } from "@fitnesspilot/components-common/dist/molecules/CoachMessage/CoachMessage"
import { SignupForm } from "@fitnesspilot/components-user/dist/organisms/SignupForm/SignupForm"
import { Action, signup, UserRegistration } from "@fitnesspilot/data-user"

import { LoggedOutTemplate } from "../../templates/LoggedOut/LoggedOut"

import { Dispatch } from "redux"

const Section = styled(Row)`
  @media (min-height: 400px) {
    padding: 130px 0 0;
  }
`

const Title = styled.h1`
  text-align: center;
`

const mapState = (state: CommonData.ParentState) => ({
  errors: pipe(
    CommonData.selectors.state.compose(CommonData.selectors.errors).get(state),
  ),
})

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ =
    (act: Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, constVoid)

  return {
    onSubmit: (v: UserRegistration) => pipe(v, signup, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

export type SignupPageContainerProps = {
  id: string
  className?: string
}

export type SignupPageProps = PropsFromRedux & SignupPageContainerProps

export const SignupPage: FC<SignupPageProps> = ({
  id,
  className,
  errors: errors_,
  onSubmit,
}) => {
  const [searchParams, _] = useSearchParams()
  const inviteCode = searchParams.get("inviteCode")

  const errors = useMemo(
    () =>
      pipe(
        errors_,
        Arr.map((e) => e.error),
      ),
    [errors_],
  )

  return (
    <LoggedOutTemplate {...{ className }}>
      <Container>
        <Section>
          <Col lg={{ size: 6 }}>
            <CoachMessage>
              <Title>
                <FormattedMessage defaultMessage="Congratulation! 🎉" />
              </Title>
              <FormattedMessage defaultMessage="You received an exclusive invitation to the Fitnesspilot Alpha.<br></br>I'm your coach and I will guide you through the app and help you to reach your goals!" />
            </CoachMessage>
          </Col>
          <Col lg={{ size: 6 }}>
            <SignupForm
              onSubmit={(values: UserRegistration) => onSubmit(values)()}
              {...{ id, errors }}
              defaultValues={{
                inviteCode: Opt.fromNullable(inviteCode),
              }}
            />
          </Col>
        </Section>
      </Container>
    </LoggedOutTemplate>
  )
}

export const SignupPageContainer = connector(SignupPage)

import { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import { Button } from "reactstrap"

import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import { CancelButton } from "@fitnesspilot/components-common/dist/molecules/CancelButton/CancelButton"
import { SaveButton } from "@fitnesspilot/components-common/dist/molecules/SaveButton/SaveButton"
import { useFormChangeEffect } from "@fitnesspilot/components-common/dist/react"
import { filtersToSearch } from "@fitnesspilot/data-activity/dist/activityFilters/searchString"
import { MuscleGroupId } from "@fitnesspilot/data-human-body/dist/muscleGroups"

import {
  FormData,
  formData,
  MusclePreferencesFieldsets,
} from "../../molecules/MusclePreferencesFieldsets/MusclePreferencesFieldsets"

export { FormData } from "../../molecules/MusclePreferencesFieldsets/MusclePreferencesFieldsets"

export type MusclePreferencesFormProps = {
  id: string
  className?: string
  muscleGroupId: MuscleGroupId
  defaultValues: Partial<FormData>
  onSubmit: (v: FormData) => void
}

export const MusclePreferencesForm = ({
  id,
  muscleGroupId,
  className,
  defaultValues,
  onSubmit,
}: MusclePreferencesFormProps) => {
  const { handleSubmit, ...form } = useForm<FormData>({
    defaultValues,
  })

  useEffect(() => {
    if (!form.formState.isDirty) {
      form.reset(defaultValues)
    }
  }, [defaultValues])

  const submit = handleSubmit(onSubmit)
  useFormChangeEffect(form, formData, submit)

  return (
    <FormProvider {...{ handleSubmit }} {...form}>
      <form onSubmit={submit} {...{ className }}>
        <MusclePreferencesFieldsets
          id={`${id}-musclePreferences`}
          {...{ muscleGroupId }}
        />

        <Fieldset>
          <Button
            tag={Link}
            to={{
              pathname: "/sports/exercises",
              search: filtersToSearch({
                muscleGroups: new Set([muscleGroupId]),
              }),
            }}
            color="primary"
            outline
          >
            <FormattedMessage defaultMessage="Show matching exercises" />
          </Button>
        </Fieldset>
      </form>
    </FormProvider>
  )
}

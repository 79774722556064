/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from "../runtime";
import { EventFromJSON, EventToJSON } from "./Event";
import { TimeslotApiFromJSON, TimeslotApiToJSON, } from "./TimeslotApi";
/**
 * Check if a given object implements the EventResponse interface.
 */
export function instanceOfEventResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "events" in value;
    return isInstance;
}
export function EventResponseFromJSON(json) {
    return EventResponseFromJSONTyped(json, false);
}
export function EventResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        events: json.events.map(EventFromJSON),
        timeslots: !exists(json, "timeslots")
            ? undefined
            : json.timeslots === null
                ? null
                : json.timeslots.map(TimeslotApiFromJSON),
    };
}
export function EventResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        events: value.events.map(EventToJSON),
        timeslots: value.timeslots === undefined
            ? undefined
            : value.timeslots === null
                ? null
                : value.timeslots.map(TimeslotApiToJSON),
    };
}

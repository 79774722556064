import * as Num from "fp-ts/es6/number"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Ordering from "fp-ts/es6/Ordering"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import { Getter, Lens, Prism } from "monocle-ts"
import { PositiveInteger } from "newtype-ts/es6/PositiveInteger"
import {
  emptyLocalDuration,
  Local,
  LocalDuration,
  localDuration,
  Time,
  time,
} from "time-ts/es6"
import { explicitOrder } from "@fitnesspilot/data-common"

import { DayOfWeek, dayOfWeek } from "@fitnesspilot/data-common/dist/DayOfWeek"

import { Temporal } from "@js-temporal/polyfill"

export enum MonthlyOccurenceType {
  dayOfMonth = "dayOfMonth",
  weekdayOfMonth = "weekdayOfMonth",
}
export const monthlyOccurenceType: Ord.Ord<MonthlyOccurenceType> =
  explicitOrder([
    MonthlyOccurenceType.dayOfMonth,
    MonthlyOccurenceType.weekdayOfMonth,
  ])

export type MonthlyOccurenceDayOfMonth = {
  dayOfMonth: PositiveInteger
}
export const monthlyOccurenceDayOfMonth =
  Ord.fromCompare<MonthlyOccurenceDayOfMonth>((a, b) =>
    (Num.Ord as any as Ord.Ord<PositiveInteger>).compare(
      a.dayOfMonth,
      b.dayOfMonth,
    ),
  )
export const _monthlyOccurenceDayOfMonthDayOfMonth =
  Lens.fromProp<MonthlyOccurenceDayOfMonth>()("dayOfMonth")

export type MonthlyOccurenceWeekdayOfMonth = {
  weekOfMonth: PositiveInteger
  dayOfWeek: DayOfWeek
}
export const monthlyOccurenceWeekdayOfMonth =
  Ord.fromCompare<MonthlyOccurenceWeekdayOfMonth>((a, b) =>
    Ordering.Monoid.concat(
      (Num.Ord as any as Ord.Ord<PositiveInteger>).compare(
        a.weekOfMonth,
        b.weekOfMonth,
      ),
      dayOfWeek.compare(a.dayOfWeek, b.dayOfWeek),
    ),
  )
export const _monthlyOccurenceWeekdayOfMonthWeekOfMonth =
  Lens.fromProp<MonthlyOccurenceWeekdayOfMonth>()("weekOfMonth")
export const _monthlyOccurenceWeekdayOfMonthDayOfWeek =
  Lens.fromProp<MonthlyOccurenceWeekdayOfMonth>()("dayOfWeek")

export type MonthlyOccurence =
  | {
      type: MonthlyOccurenceType.dayOfMonth
      value: MonthlyOccurenceDayOfMonth
    }
  | {
      type: MonthlyOccurenceType.weekdayOfMonth
      value: MonthlyOccurenceWeekdayOfMonth
    }
export const monthlyOccurence: Ord.Ord<MonthlyOccurence> = Ord.fromCompare(
  (a, b) =>
    a.type === MonthlyOccurenceType.dayOfMonth &&
    b.type === MonthlyOccurenceType.dayOfMonth
      ? monthlyOccurenceDayOfMonth.compare(a.value, b.value)
      : a.type === MonthlyOccurenceType.weekdayOfMonth &&
        b.type === MonthlyOccurenceType.weekdayOfMonth
      ? monthlyOccurenceWeekdayOfMonth.compare(a.value, b.value)
      : monthlyOccurenceType.compare(a.type, b.type),
)
export const _MonthlyOccurenceDayOfMonth = new Prism<
  MonthlyOccurence,
  MonthlyOccurenceDayOfMonth
>(
  (s) =>
    s.type === MonthlyOccurenceType.dayOfMonth ? Opt.some(s.value) : Opt.none,
  (a) => ({ type: MonthlyOccurenceType.dayOfMonth, value: a }),
)
export const _MonthlyOccurenceWeekdayOfMonth = new Prism<
  MonthlyOccurence,
  MonthlyOccurenceWeekdayOfMonth
>(
  (s) =>
    s.type === MonthlyOccurenceType.weekdayOfMonth
      ? Opt.some(s.value)
      : Opt.none,
  (a) => ({ type: MonthlyOccurenceType.weekdayOfMonth, value: a }),
)

export enum RecurrencePatternType {
  daily = "daily",
  weekly = "weekly",
  monthly = "monthly",
}
export const recurrencePatternType: Ord.Ord<RecurrencePatternType> =
  explicitOrder([
    RecurrencePatternType.daily,
    RecurrencePatternType.weekly,
    RecurrencePatternType.monthly,
  ])

export type RecurrencePatternDaily = {
  between: readonly [Time, Opt.Option<Time>]
  repeatEvery: Local<
    Temporal.Duration & {
      hours: 0
      minutes: 0
      seconds: 0
    }
  >
}
export const recurrencePatternDaily: Ord.Ord<RecurrencePatternDaily> =
  Ord.fromCompare((a, b) =>
    NonEmpty.fold(Ordering.semigroupOrdering)([
      time.compare(a.between[0], b.between[0]),
      localDuration.compare(a.repeatEvery, b.repeatEvery),
      Opt.getOrd(time).compare(a.between[1], b.between[1]),
    ]),
  )
export const _betweenRecurrencePatternDaily =
  Lens.fromProp<RecurrencePatternDaily>()("between")
export const _startRecurrencePatternDaily =
  _betweenRecurrencePatternDaily.composeLens(
    new Lens(
      (s: readonly [Time, Opt.Option<Time>]) => s[0],
      (a) => (s) => [a, s[1]],
    ),
  )
export const _endRecurrencePatternDaily =
  _betweenRecurrencePatternDaily.composeLens(
    new Lens(
      (s: readonly [Time, Opt.Option<Time>]) => s[1],
      (a) => (s) => [s[0], a],
    ),
  )
export const _repeatEveryRecurrencePatternDaily =
  Lens.fromProp<RecurrencePatternDaily>()("repeatEvery")
export const emptyRepeatEveryDaily =
  emptyLocalDuration as RecurrencePatternDaily["repeatEvery"]

export type RecurrencePatternWeekly = {
  between: readonly [Time, Opt.Option<Time>]
  repeatEvery: Local<
    Temporal.Duration & {
      days: 0
      hours: 0
      minutes: 0
      seconds: 0
    }
  >
  occursOn: NonEmpty.ReadonlyNonEmptyArray<DayOfWeek>
}
export const recurrencePatternWeekly: Ord.Ord<RecurrencePatternWeekly> =
  Ord.fromCompare((a, b) =>
    NonEmpty.fold(Ordering.semigroupOrdering)([
      time.compare(a.between[0], b.between[0]),
      localDuration.compare(a.repeatEvery, b.repeatEvery),
      Arr.getOrd(dayOfWeek).compare(a.occursOn, b.occursOn),
      Opt.getOrd(time).compare(a.between[1], b.between[1]),
    ]),
  )
export const _betweenRecurrencePatternWeekly =
  Lens.fromProp<RecurrencePatternWeekly>()("between")
export const _startRecurrencePatternWeekly =
  _betweenRecurrencePatternWeekly.composeLens(
    new Lens(
      (s: readonly [Time, Opt.Option<Time>]) => s[0],
      (a) => (s) => [a, s[1]],
    ),
  )
export const _endRecurrencePatternWeekly =
  _betweenRecurrencePatternWeekly.composeLens(
    new Lens(
      (s: readonly [Time, Opt.Option<Time>]) => s[1],
      (a) => (s) => [s[0], a],
    ),
  )
export const _repeatEveryRecurrencePatternWeekly =
  Lens.fromProp<RecurrencePatternWeekly>()("repeatEvery")
export const _occursOnRecurrencePatternWeekly =
  Lens.fromProp<RecurrencePatternWeekly>()("occursOn")
export const emptyRepeatEveryWeekly =
  emptyLocalDuration as RecurrencePatternWeekly["repeatEvery"]

export type RecurrencePatternMonthly = {
  between: readonly [Time, Opt.Option<Time>]
  repeatEvery: Local<
    Temporal.Duration & {
      weeks: 0
      days: 0
      hours: 0
      minutes: 0
      seconds: 0
    }
  >
  occursOn: NonEmpty.ReadonlyNonEmptyArray<MonthlyOccurence>
}
export const recurrencePatternMonthly: Ord.Ord<RecurrencePatternMonthly> =
  Ord.fromCompare((a, b) =>
    NonEmpty.fold(Ordering.semigroupOrdering)([
      time.compare(a.between[0], b.between[0]),
      localDuration.compare(a.repeatEvery, b.repeatEvery),
      Arr.getOrd(monthlyOccurence).compare(a.occursOn, b.occursOn),
      Opt.getOrd(time).compare(a.between[1], b.between[1]),
    ]),
  )
export const _betweenRecurrencePatternMonthly =
  Lens.fromProp<RecurrencePatternMonthly>()("between")
export const _startRecurrencePatternMonthly =
  _betweenRecurrencePatternMonthly.composeLens(
    new Lens(
      (s: readonly [Time, Opt.Option<Time>]) => s[0],
      (a) => (s) => [a, s[1]],
    ),
  )
export const _endRecurrencePatternMonthly =
  _betweenRecurrencePatternMonthly.composeLens(
    new Lens(
      (s: readonly [Time, Opt.Option<Time>]) => s[1],
      (a) => (s) => [s[0], a],
    ),
  )
export const _repeatEveryRecurrencePatternMonthly =
  Lens.fromProp<RecurrencePatternMonthly>()("repeatEvery")
export const _occursOnRecurrencePatternMonthly =
  Lens.fromProp<RecurrencePatternMonthly>()("occursOn")
export const emptyRepeatEveryMonthly =
  emptyLocalDuration as RecurrencePatternMonthly["repeatEvery"]

export type RecurrencePattern =
  | {
      type: RecurrencePatternType.daily
      value: RecurrencePatternDaily
    }
  | {
      type: RecurrencePatternType.weekly
      value: RecurrencePatternWeekly
    }
  | {
      type: RecurrencePatternType.monthly
      value: RecurrencePatternMonthly
    }
export const recurrencePattern: Ord.Ord<RecurrencePattern> = Ord.fromCompare(
  (a, b) =>
    a.type === RecurrencePatternType.daily &&
    b.type === RecurrencePatternType.daily
      ? recurrencePatternDaily.compare(a.value, b.value)
      : a.type === RecurrencePatternType.weekly &&
        b.type === RecurrencePatternType.weekly
      ? recurrencePatternWeekly.compare(a.value, b.value)
      : a.type === RecurrencePatternType.monthly &&
        b.type === RecurrencePatternType.monthly
      ? recurrencePatternMonthly.compare(a.value, b.value)
      : recurrencePatternType.compare(a.type, b.type),
)
export const _RecurrencePatternDaily = new Prism<
  RecurrencePattern,
  RecurrencePatternDaily
>(
  (v) =>
    v.type === RecurrencePatternType.daily ? Opt.some(v.value) : Opt.none,
  (v) => ({ type: RecurrencePatternType.daily, value: v }),
)
export const _RecurrencePatternWeekly = new Prism<
  RecurrencePattern,
  RecurrencePatternWeekly
>(
  (v) =>
    v.type === RecurrencePatternType.weekly ? Opt.some(v.value) : Opt.none,
  (v) => ({ type: RecurrencePatternType.weekly, value: v }),
)
export const _RecurrencePatternMonthly = new Prism<
  RecurrencePattern,
  RecurrencePatternMonthly
>(
  (v) =>
    v.type === RecurrencePatternType.monthly ? Opt.some(v.value) : Opt.none,
  (v) => ({ type: RecurrencePatternType.monthly, value: v }),
)
export const _between = Lens.fromPath<RecurrencePattern>()(["value", "between"])
export const _start = Lens.fromPath<RecurrencePattern>()([
  "value",
  "between",
  0,
])
export const _end = Lens.fromPath<RecurrencePattern>()(["value", "between", 1])
export const _repeatEvery = new Getter<RecurrencePattern, LocalDuration>(
  (s) => s.value.repeatEvery,
)

export const foldRecurrencePattern =
  <a>(
    onDaily: (v: RecurrencePatternDaily) => a,
    onWeekly: (v: RecurrencePatternWeekly) => a,
    onMonthly: (v: RecurrencePatternMonthly) => a,
  ) =>
  (v: RecurrencePattern) =>
    v.type === RecurrencePatternType.daily
      ? onDaily(v.value)
      : v.type === RecurrencePatternType.weekly
      ? onWeekly(v.value)
      : v.type === RecurrencePatternType.monthly
      ? onMonthly(v.value)
      : (false as never)

export enum RecurrenceType {
  single = "single",
  recurring = "recurring",
}
export const recurrenceType: Ord.Ord<RecurrenceType> = explicitOrder([
  RecurrenceType.single,
  RecurrenceType.recurring,
])

export type Recurrence =
  | {
      type: RecurrenceType.single
    }
  | {
      type: RecurrenceType.recurring
      value: RecurrencePattern
    }
export const recurrence: Ord.Ord<Recurrence> = Ord.fromCompare((a, b) =>
  a.type === RecurrenceType.single || b.type === RecurrenceType.single
    ? recurrenceType.compare(a.type, b.type)
    : recurrencePattern.compare(a.value, b.value),
)
export const isSingle = (
  v: Recurrence,
): v is Recurrence & { type: RecurrenceType.single } =>
  v.type === RecurrenceType.single
export const isRecurring = (
  v: Recurrence,
): v is Recurrence & { type: RecurrenceType.recurring } =>
  v.type === RecurrenceType.recurring
export const _RecurrenceSingle = new Prism<Recurrence, void>(
  (v) => (isSingle(v) ? Opt.some(undefined) : Opt.none),
  () => ({ type: RecurrenceType.single }),
)
export const _RecurrencePattern = new Prism<Recurrence, RecurrencePattern>(
  (v) => (isRecurring(v) ? Opt.some(v.value) : Opt.none),
  (v) => ({ type: RecurrenceType.recurring, value: v }),
)

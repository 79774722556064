import { createPromiseClient } from "@bufbuild/connect"
import { createGrpcWebTransport } from "@bufbuild/connect-web"

export const createTransport = (
  baseUrl: string,
  getToken: () => string | Promise<string>,
) =>
  createGrpcWebTransport({
    baseUrl,
    interceptors: [
      (next) => async (req) => {
        const token = await getToken()
        if (token) {
          req.header.set("Authorization", `Bearer ${token}`)
        }
        return await next(req)
      },
    ],
  })

export const createClient = createPromiseClient

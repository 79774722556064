import * as Eq from "fp-ts/es6/Eq"
import * as F from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Str from "fp-ts/es6/string"
import { Lens, Optional, Prism } from "monocle-ts"
import { time } from "time-ts/es6"

import { ImageObject } from "@fitnesspilot/data-common/dist/image"
import {
  liftGetter2,
  liftGetterEq,
  PartialNotOptional,
  prismToGetter,
} from "@fitnesspilot/data-common/dist/type"
import { stringAsUrl } from "@fitnesspilot/data-common/dist/url"

// We should prolly make the PhotoId isomorphic to the photo's URI
export type PhotoId = string & { readonly __tag: unique symbol }
export const stringAsPhotoId = new Prism(
  // @TODO
  Opt.some as (v: string) => Opt.Option<PhotoId>,
  F.identity as (v: PhotoId) => string,
)
export const photoId = liftGetterEq(prismToGetter(stringAsPhotoId))(Str.Eq)

export type Photo = {
  readonly id: PhotoId
} & PartialNotOptional<ImageObject, "contentUrl" | "dateCreated">
export const id = Lens.fromProp<Photo>()("id")
export const contentUrl = Lens.fromProp<Photo>()("contentUrl")
export const dateCreated = Lens.fromProp<Photo>()("dateCreated")
export const description = Lens.fromProp<Photo>()("description")
export const descriptionOpt = Optional.fromOptionProp<Photo>()("description")

const optStringEq = Opt.getEq(Str.Eq)
export const photo: Ord.Ord<Photo> = {
  ...Eq.struct<Photo>({
    id: photoId,
    contentUrl: liftGetterEq(prismToGetter(stringAsUrl))(Str.Eq),
    dateCreated: time,
    description: optStringEq,
  }),
  compare: liftGetter2(dateCreated.asGetter())(Ord.reverse(time).compare),
}

import * as Ord from "fp-ts/es6/Ord"
import * as Str from "fp-ts/es6/string"

export enum ActivityCategory {
  repsBodyWeight = "repsBodyWeight",
  repsExtraWeight = "repsExtraWeight",
  isometric = "isometric",
  generic = "generic",
  distance = "distance",
  stretching = "stretching",
}
export const activityCategory: Ord.Ord<ActivityCategory> = Str.Ord

export const foldActivityCategory =
  <a>(
    onRepsBodyWeight: (v: ActivityCategory.repsBodyWeight) => a,
    onRepsExtraweight: (v: ActivityCategory.repsExtraWeight) => a,
    onIsometric: (v: ActivityCategory.isometric) => a,
    onGeneric: (v: ActivityCategory.generic) => a,
    onDistance: (v: ActivityCategory.distance) => a,
    onStretching: (v: ActivityCategory.stretching) => a,
  ) =>
  (v: ActivityCategory) => {
    switch (v) {
      case ActivityCategory.repsBodyWeight:
        return onRepsBodyWeight(v)
      case ActivityCategory.repsExtraWeight:
        return onRepsExtraweight(v)
      case ActivityCategory.isometric:
        return onIsometric(v)
      case ActivityCategory.generic:
        return onGeneric(v)
      case ActivityCategory.distance:
        return onDistance(v)
      case ActivityCategory.stretching:
        return onStretching(v)
    }
  }

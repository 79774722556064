import * as Bool from "fp-ts/es6/boolean"
import * as Eq from "fp-ts/es6/Eq"
import { constFalse, constTrue, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as St from "fp-ts/es6/ReadonlySet"

import {
  Alignment,
  alignment,
  numberAsAlignmentUnsafe,
} from "@fitnesspilot/data-human-body/dist/Alignment"
import { muscleId_FromMuscleId } from "@fitnesspilot/data-human-body/dist/Muscle"
import {
  MuscleGroupId,
  muscleGroupId,
  muscleIdMap,
} from "@fitnesspilot/data-human-body/dist/muscleGroups"

import { ActivityId } from "../activity/ActivityId"
import { ActivityTag, activityTag } from "../activity/ActivityTag"
import { EquipmentType, equipmentType } from "../activity/Equipment"
import * as WithId from "../activity/WithId"
import {
  ActivityFilters,
  foldActivityFilter,
  matches,
} from "../activityFilters/ActivityFilters"
import {
  activitiesFromActivityInstance,
  ActivityInstanceNonGroup,
  foldActivityInstanceNonGroup,
  ifActivityInstanceExercise,
} from "../activityInstance/ActivityInstance"
import { ActivitySettingsExerciseListing } from "../activitySettings/ActivitySettingsExercise"

export type ActivityInstanceFilters = ActivityFilters & {
  minAlignment: Alignment
}
export const activityInstanceFilters: Eq.Eq<ActivityInstanceFilters> =
  Eq.struct({
    tags: St.getEq(activityTag),
    muscleGroups: St.getEq(muscleGroupId),
    equipments: St.getEq(equipmentType),
    onlyLiked: Bool.Eq,
    minAlignment: alignment,
  })

export const empty: ActivityInstanceFilters = {
  tags: new Set([]),
  muscleGroups: new Set([]),
  equipments: new Set([]),
  onlyLiked: false,
  minAlignment: numberAsAlignmentUnsafe(0),
}

export const foldActivityInstanceFilter =
  <a>(
    tags: () => a,
    muscleGroups: () => a,
    equipments: () => a,
    onlyLiked: () => a,
    minAlignment: () => a,
  ) =>
  (k: keyof ActivityInstanceFilters) => {
    switch (k) {
      case "tags":
      case "muscleGroups":
      case "equipments":
      case "onlyLiked":
        return foldActivityFilter(tags, muscleGroups, equipments, onlyLiked)(k)
      case "minAlignment":
        return minAlignment()
    }
  }

export const foldActivityInstanceFilterValue =
  <a, k extends keyof ActivityInstanceFilters>(
    tags: (v: ReadonlySet<ActivityTag>) => a,
    muscleGroups: (v: ReadonlySet<MuscleGroupId>) => a,
    equipments: (v: ReadonlySet<EquipmentType>) => a,
    onlyLiked: (v: boolean) => a,
    minAlignment: (v: Alignment) => a,
  ) =>
  (k: k, v: ActivityInstanceFilters[k]) =>
    foldActivityInstanceFilter(
      () => tags(v as ActivityInstanceFilters["tags"]),
      () => muscleGroups(v as ActivityInstanceFilters["muscleGroups"]),
      () => equipments(v as ActivityInstanceFilters["equipments"]),
      () => onlyLiked(v as ActivityInstanceFilters["onlyLiked"]),
      () => minAlignment(v as ActivityInstanceFilters["minAlignment"]),
    )(k)

export const isInstanceFilters = (
  filters: ActivityFilters,
): filters is ActivityInstanceFilters => "minAlignment" in filters

export const isBaseFilter = (
  filter: keyof ActivityInstanceFilters,
): filter is keyof ActivityFilters => filter !== "minAlignment"

export const foldInstanceFilters =
  <a extends ActivityFilters, b extends ActivityInstanceFilters, c>(
    onFail: () => c,
    onSuccess: (filters: b) => c,
  ) =>
  (filters: a) =>
    isInstanceFilters(filters) ? onSuccess(filters as any as b) : onFail()

export const foldBaseFilter =
  <a extends keyof ActivityInstanceFilters, b extends keyof ActivityFilters, c>(
    onFail: () => c,
    onSuccess: (filter: b, value: ActivityFilters[b]) => c,
  ) =>
  (filter: a, value: ActivityInstanceFilters[a]) =>
    isBaseFilter(filter)
      ? onSuccess(filter as any as b, value as ActivityFilters[b])
      : onFail()

export const filterActivityInstances =
  (activityName: (v: { id: ActivityId; def: string }) => string) =>
  (
    search: Opt.Option<string>,
    filters: ActivityInstanceFilters,
    activityListings: ReadonlyArray<
      WithId.WithId<ActivityId, ActivitySettingsExerciseListing>
    >,
  ) =>
  (activities: ReadonlyArray<ActivityInstanceNonGroup>) =>
    pipe(
      activities,
      Arr.filter((a) =>
        pipe(
          search,
          Opt.fold(constTrue, (v) =>
            pipe(
              a,
              activitiesFromActivityInstance.get,
              Arr.some((a) =>
                matches(
                  activityName({ id: a.id, def: a.value.value.title }),
                  v,
                ),
              ),
            ),
          ),
        ),
      ),
      Arr.filter(
        filters.muscleGroups.size > 0
          ? foldActivityInstanceNonGroup(
              (a) =>
                pipe(
                  filters.muscleGroups,
                  St.some((mg) =>
                    pipe(
                      a.activity.value.muscleShares,
                      Arr.map(
                        (ms) =>
                          muscleIdMap[muscleId_FromMuscleId.get(ms.muscleId)],
                      ),
                      Arr.uniq(muscleGroupId),
                      Arr.elem(muscleGroupId)(mg),
                    ),
                  ),
                ),
              constFalse,
              (a) =>
                pipe(
                  filters.muscleGroups,
                  St.some((mg) =>
                    pipe(a.muscleGroupIds, Arr.elem(muscleGroupId)(mg)),
                  ),
                ),
              constFalse,
            )
          : constTrue,
      ),
      Arr.filter(
        filters.equipments.size > 0
          ? ifActivityInstanceExercise((a) =>
              pipe(
                filters.equipments,
                St.some((eq) =>
                  St.elem(equipmentType)(eq, a.activity.value.equipments),
                ),
              ),
            )
          : constTrue,
      ),
      Arr.filter(
        Ord.gt(alignment)(filters.minAlignment, numberAsAlignmentUnsafe(0))
          ? foldActivityInstanceNonGroup(
              (a) => Ord.gt(alignment)(a.alignment, filters.minAlignment),
              constFalse,
              (a) =>
                pipe(
                  a.alignment,
                  Opt.fold(constFalse, (al) =>
                    Ord.gt(alignment)(al, filters.minAlignment),
                  ),
                ),
              constFalse,
            )
          : constTrue,
      ),
      Arr.filter(
        pipe(
          filters.onlyLiked,
          Bool.fold(
            () => constTrue,
            () =>
              ifActivityInstanceExercise((a) =>
                pipe(
                  activityListings,
                  Arr.findFirst((s) => s.id === a.activity.id),
                  Opt.map(
                    (s) => s.value === ActivitySettingsExerciseListing.liked,
                  ),
                  Opt.getOrElse(constFalse),
                ),
              ),
          ),
        ),
      ),
    )

import { useCallback, useEffect, useState } from "react"
import {
  DeepPartial,
  FieldValues,
  UnpackNestedValue,
  UseFormReturn,
} from "react-hook-form"

import * as Eq from "fp-ts/Eq"

import { useDebouncedCallback } from "use-debounce"

/** Run effect when leaving current route. */
export const useRouteLeaveEffect = (onLeave: () => void) => {
  const [leaving, setLeaving] = useState(false)

  useEffect(() => {
    return () => {
      if (!leaving) {
        setLeaving(true)
        console.debug("usePageLeaveEffect onLeave")
        onLeave()
      }
    }
  }, [])
}

/**
 * Calls `onChange` on any changes of the `form` as well
 * as when leaving the current route.
 * Uses `eq` to check for actual changes.
 **/
export const useFormChangeEffect = <FormData extends FieldValues>(
  { getValues, watch }: Pick<UseFormReturn<FormData>, "getValues" | "watch">,
  eq: Eq.Eq<FormData>,
  onChange: () => void,
  debounce = 3000,
) => {
  const [previous, setPrevious] = useState(() => getValues())
  const [leaving, setLeaving] = useState(false)

  const onChangeDebounced = useDebouncedCallback(onChange, debounce)

  // debounce slightly to avoid loops
  const handleChange = useDebouncedCallback((data: FormData) => {
    if (!eq.equals(previous, data)) {
      setPrevious(data)
      onChangeDebounced()
    }
  }, 100)

  useEffect(() => {
    // assume we always get full FormData not DeepPartial<FormData>
    const subscription = watch((data) => handleChange(data as FormData))
    return () => subscription.unsubscribe()
  }, [handleChange, watch])

  useRouteLeaveEffect(() => {
    const data = getValues()
    if (!eq.equals(previous, data)) {
      onChange()
    }
  })
}

import { FC, useCallback, useMemo, useState } from "react"
import { connect, ConnectedProps } from "react-redux"
import styled from "@emotion/styled"

import * as Bool from "fp-ts/es6/boolean"
import { flow, pipe } from "fp-ts/es6/function"
import * as Func from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import { dateAsTime, dayOfTime } from "time-ts/es6"
import {
  bisequencePair,
  mapPair,
  nullablePrism,
  optionLensToOptional,
} from "@fitnesspilot/data-common"

import { EventChangeType } from "@fitnesspilot/api-combined"
import { Content } from "@fitnesspilot/components-common/dist/molecules/Content/Content"
import { Calendar } from "@fitnesspilot/components-event/dist/organisms/Calendar/Calendar"
import { CalendarEventSelectionContainer } from "@fitnesspilot/components-event/dist/organisms/CalendarEventSelection/CalendarEventSelection"
import {
  EventChangeAction,
  EventChangeModal,
} from "@fitnesspilot/components-event/dist/organisms/EventChangeModal/EventChangeModal"
import * as Event from "@fitnesspilot/data-event/dist/calendar/Event"
import * as EventOrRecommendation from "@fitnesspilot/data-event/dist/calendar/EventOrRecommendation"
import * as Recurrence from "@fitnesspilot/data-event/dist/calendar/Recurrence"
import { maxPreCreationHistory } from "@fitnesspilot/data-event/dist/calendarBounds"
import * as EventData from "@fitnesspilot/data-event/dist/store"
import {
  Action,
  calendarSelect,
  calendarUnselect,
  deleteEvent,
  fetchEventsOrRecommendationsBetweenIfNeeded,
  saveEvent,
  startEditingEventById,
} from "@fitnesspilot/data-event/dist/store"
import * as CalendarSelection from "@fitnesspilot/data-event/dist/store/CalendarSelection"
import * as UserData from "@fitnesspilot/data-user/dist/store"
import * as User from "@fitnesspilot/data-user/dist/User"

import { MainTemplate } from "../../templates/Main/Main"

import { Dispatch } from "redux"
import { RouterActions } from "redux-first-history"

const mapState = (state: EventData.ParentState & UserData.ParentState) => ({
  userCreated: UserData.selectors.state
    .composeOptional(optionLensToOptional(UserData.selectors.user))
    .composeOptional(optionLensToOptional(User._created))
    .getOption(state),
  events: EventData.selectors.state
    .compose(EventData.selectors.eventsOrRecommendations)
    .get(state),
})

const mapDispatch = (dispatch: Dispatch<Action | RouterActions>) => {
  const dispatch_ =
    (act: Action | RouterActions): IO.IO<void> =>
    () =>
      pipe(act, dispatch, Func.constVoid)

  return {
    onDatesSet: flow(fetchEventsOrRecommendationsBetweenIfNeeded, dispatch_),
    onSelect: flow(calendarSelect, dispatch_),
    onUnselect: pipe(calendarUnselect(), dispatch_),
    onChangeEvent: flow(saveEvent, dispatch_),
    onDeleteEvent: flow(deleteEvent, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type OwnProps = Record<string, never>

export type CalendarProps = PropsFromRedux & OwnProps

type EventChange = {
  event: Event.EventWithId
  action: EventChangeAction
}

const RelativeContent = styled(Content)`
  position: relative;
  padding: 0 !important;
`

export const CalendarPage: FC<CalendarProps> = ({
  userCreated,
  events,
  onDatesSet,
  onSelect,
  onUnselect,
  onChangeEvent,
  onDeleteEvent,
}) => {
  const [eventEl, setEventEl] = useState<HTMLElement | null>(null)

  /* To stop selection events from propagating out and thus triggering onUnselect */
  const outerCalendarRef = useCallback(
    (ref: HTMLElement | null) =>
      ref?.addEventListener?.(
        "click",
        (e) =>
          (e.target as HTMLElement | null)?.classList?.contains?.(
            "fc-timegrid-body",
          ) && e.stopPropagation(),
      ),
    [],
  )

  const [eventChange, setEventChange] = useState<Opt.Option<EventChange>>(
    Opt.none,
  )

  const showEventChangeModal = ({ event, action }: EventChange): IO.IO<void> =>
    pipe(
      event,
      Event._eventWithId._value.composeLens(Event._recurrence).get,
      Recurrence.isRecurring,
      Bool.fold(
        () =>
          action === EventChangeAction.change
            ? onChangeEvent({ event, changeType: EventChangeType.Single })
            : onDeleteEvent({ event, changeType: EventChangeType.Single }),
        () => () => pipe({ event, action }, Opt.some, setEventChange),
      ),
    )

  const handleChangeEvent =
    (
      action: (v: {
        event: Event.EventWithId
        changeType: EventChangeType
      }) => IO.IO<void>,
    ) =>
    (v: { changeType: EventChangeType }) =>
      pipe(
        eventChange,
        Opt.fold(
          () => IO.of(undefined),
          ({ event }) =>
            pipe(
              action({ event, ...v }),
              IO.chain(() => () => setEventChange(Opt.none)),
            ),
        ),
      )

  const cal = useMemo(
    () => (
      <Calendar
        data-help-mode="calendar"
        editable
        selectable
        selectMirror
        dayMaxEvents
        events={events}
        validRange={{
          start: pipe(
            userCreated,
            Opt.fold(Func.constUndefined, (created) =>
              pipe(maxPreCreationHistory(created)(), dateAsTime.reverseGet),
            ),
          ),
        }}
        datesSet={({ start, end }) =>
          pipe(
            [start, end],
            mapPair(dateAsTime.getOption),
            bisequencePair(Opt.Monad),
            Opt.fold(
              () => IO.of(undefined),
              (between) => onDatesSet({ between }),
            ),
          )()
        }
        select={({ start, end, jsEvent }) => {
          jsEvent?.stopPropagation?.()
          const el = document.querySelector<HTMLElement>(".fc-event-mirror")
          setEventEl(el)

          return pipe(
            [start, end],
            mapPair(dateAsTime.getOption),
            bisequencePair(Opt.Monad),
            Opt.fold(
              () => onUnselect,
              flow(
                CalendarSelection._CalendarSelectionTimeRange.reverseGet,
                onSelect,
              ),
            ),
            IO.apFirst(() => jsEvent?.stopPropagation?.()),
          )()
        }}
        eventChange={({ event }) =>
          pipe(
            [event.start, event.end] as const,
            mapPair(
              nullablePrism<Date | null>().composePrism(dateAsTime).getOption,
            ),
            bisequencePair(Opt.Monad),
            Opt.map(Event._eventWithId._value.composeLens(Event._between).set),
            Opt.ap(
              pipe(
                event.extendedProps as EventOrRecommendation.EventOrRecommendation,
                EventOrRecommendation._EventWithId.getOption,
              ),
            ),
            Opt.fold(
              () => IO.of(undefined),
              (event) =>
                showEventChangeModal({
                  event,
                  action: EventChangeAction.change,
                }),
            ),
          )()
        }
        eventClick={({ event, el, jsEvent }) =>
          pipe(
            () => {
              setEventEl(el)
              jsEvent.stopPropagation()
            },
            IO.chain(() =>
              pipe(
                event.extendedProps as EventOrRecommendation.EventOrRecommendation,
                EventOrRecommendation.foldEventOrRecommendation(
                  CalendarSelection._CalendarSelectionEvent.reverseGet,
                  CalendarSelection._CalendarSelectionRecommendation.reverseGet,
                ),
                onSelect,
              ),
            ),
          )()
        }
      />
    ),
    [events],
  )

  return (
    <MainTemplate header={null}>
      <RelativeContent>
        <div ref={outerCalendarRef}>{cal}</div>

        <CalendarEventSelectionContainer
          onDeleteEvent={(evt) =>
            showEventChangeModal({
              event: evt,
              action: EventChangeAction.delete,
            })
          }
          target={eventEl ?? document.body}
          onClose={onUnselect}
        />
      </RelativeContent>

      <EventChangeModal
        id="modals-eventChange"
        action={pipe(
          eventChange,
          Opt.map((e) => e.action),
          Opt.getOrElse<EventChangeAction>(() => EventChangeAction.change),
        )}
        isOpen={Opt.isSome(eventChange)}
        onChange={handleChangeEvent(onChangeEvent)}
        onDelete={handleChangeEvent(onDeleteEvent)}
        onCancel={() => setEventChange(Opt.none)}
      />
    </MainTemplate>
  )
}

export const CalendarPageContainer = connector(CalendarPage)

import * as Bool from "fp-ts/es6/boolean"
import { flow, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"

import { errorForUser } from "../error"
import { optionLensToOptional } from "../type"
import * as actions from "./actions"
import { Action } from "./actions"
import * as selectors from "./selectors"
import { initialState, State } from "./state"

import { createReducer } from "typesafe-actions"

export default createReducer<State, Action>(initialState)
  .handleAction(
    actions.setAvailableUpdate,
    (state, { payload: { newWorker } }) =>
      selectors.availableUpdate.set(Opt.some(newWorker))(state),
  )
  .handleAction(
    actions.skipWaitingForUpdate,
    selectors.availableUpdate.set(Opt.none),
  )
  .handleAction(actions.addError, (state, { payload: { error } }) =>
    flow(selectors.errors.modify(Arr.append(error)))(state),
  )
  .handleAction(
    actions.addErrorSentryEventId,
    (state, { payload: { error, sentryEventId } }) =>
      flow(
        selectors.errors.modify((arr) =>
          pipe(
            arr,
            Arr.findIndex((err) => errorForUser.equals(err.error, error)),
            Opt.chain((i) =>
              pipe(
                arr,
                Arr.updateAt(i, {
                  error,
                  sentryEventId: Opt.some(sentryEventId),
                }),
              ),
            ),
            Opt.getOrElse(() => arr),
          ),
        ),
        selectors.shownError.modify(
          flow(
            Opt.map((err) =>
              pipe(
                errorForUser.equals(err.error, error),
                Bool.fold(
                  () => err,
                  () => ({
                    error,
                    sentryEventId: Opt.some(sentryEventId),
                  }),
                ),
              ),
            ),
          ),
        ),
      )(state),
  )
  .handleAction(actions.setShownError, (state, { payload: { error } }) =>
    flow(optionLensToOptional(selectors.shownError).set(error))(state),
  )
  .handleAction(actions.clearShownError, selectors.shownError.set(Opt.none))
  .handleAction(
    actions.toggleErrorDetailsAreOpen,
    (state, { payload: isOpen }) =>
      selectors.errorDetailsAreOpen.set(isOpen)(state),
  )

import * as Opt from "fp-ts/es6/Option"

import { Token } from "../auth"

export const stateKey = "api"

export type State = {
  token: Opt.Option<Token>
  baseURL: URL
}
export const initialState: State = {
  token: Opt.none,
  baseURL: new URL(
    process.env.REACT_APP_API_BASE_URL ?? "",
    window.location.toString(),
  ),
}

export type ParentState = { [k in typeof stateKey]: State }

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from "../runtime";
import { Operation1PhotoDownloadFromJSON, } from "./Operation1PhotoDownload";
/**
 * Check if a given object implements the JsonPatchDocument1PhotoDownload interface.
 */
export function instanceOfJsonPatchDocument1PhotoDownload(value) {
    const isInstance = true;
    return isInstance;
}
export function JsonPatchDocument1PhotoDownloadFromJSON(json) {
    return JsonPatchDocument1PhotoDownloadFromJSONTyped(json, false);
}
export function JsonPatchDocument1PhotoDownloadFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        operations: !exists(json, "operations")
            ? undefined
            : json.operations === null
                ? null
                : json.operations.map(Operation1PhotoDownloadFromJSON),
        contractResolver: !exists(json, "contractResolver")
            ? undefined
            : json.contractResolver,
    };
}
export function JsonPatchDocument1PhotoDownloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        contractResolver: value.contractResolver,
    };
}

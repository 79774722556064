import { ReactNode, useCallback, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import styled from "@emotion/styled"

import * as Bool from "fp-ts/es6/boolean"
import {
  constFalse,
  constNull,
  constUndefined,
  flow,
  pipe,
} from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as Str from "fp-ts/es6/string"

import { DenylistButton } from "@fitnesspilot/components-common/dist/molecules/DenylistButton/DenylistButton"
import { LikeButton } from "@fitnesspilot/components-common/dist/molecules/LikeButton/LikeButton"
import { Mode as BodyMapMode } from "@fitnesspilot/components-human-body/dist/atoms/MuscleShape/MuscleShape"
import { BodyMapAltContainer } from "@fitnesspilot/components-human-body/dist/organisms/BodyMapAlt/BodyMapAlt"
import {
  _ActivityExercise,
  Activity as ActivityType,
  ActivityWithId,
  foldActivity,
} from "@fitnesspilot/data-activity/dist/activity/Activity"
import { _muscleShares } from "@fitnesspilot/data-activity/dist/activity/ActivityExercise"
import {
  ActivityId,
  activityId,
} from "@fitnesspilot/data-activity/dist/activity/ActivityId"
import { _withId } from "@fitnesspilot/data-activity/dist/activity/WithId"
import { ActivitySettingsExerciseListing } from "@fitnesspilot/data-activity/dist/activitySettings/ActivitySettingsExercise"
import { Alignment } from "@fitnesspilot/data-human-body/dist/Alignment"
import { Dorsoventral } from "@fitnesspilot/data-human-body/dist/anatomicalAxes"
import { MuscleId_ } from "@fitnesspilot/data-human-body/dist/Muscle"

import { ActivityImage } from "../../atoms/ActivityImage/ActivityImage"
import { ActivityName } from "../../atoms/ActivityName/ActivityName"
import { Activity as Activity_ } from "../../molecules/Activity/Activity"

type ButtonsProps = {
  isLiked: boolean
  isDenylisted: boolean
  onSetLike: (isActive: boolean) => IO.IO<void>
  onSetDenylist: (isActive: boolean) => IO.IO<void>
}

const Buttons = ({
  isLiked,
  isDenylisted,
  onSetLike,
  onSetDenylist,
}: ButtonsProps) => (
  <>
    <LikeButton className="like" isActive={isLiked} onSetActive={onSetLike}>
      <FormattedMessage defaultMessage="Like" />
    </LikeButton>
    <DenylistButton
      className="denylist"
      isActive={isDenylisted}
      onSetActive={onSetDenylist}
    >
      <FormattedMessage defaultMessage="Denylist" />
    </DenylistButton>
  </>
)

const Content = styled.div`
  display: flex;
`

const ContentMain = styled.div`
  flex-grow: 1;
`

const ActivityBodyMap = styled.div`
  display: none;
  width: 100%;
  max-width: 220px;

  @media (${({ theme }) => theme.media.md}) {
    display: flex;
  }

  & > svg {
    overflow: initial;
    width: 50%;
  }
`

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
`

const BottomRight = styled.div`
  display: flex;
  align-items: end;
`

export type ActivityProps = {
  activity: ActivityWithId
  activityListing: Opt.Option<ActivitySettingsExerciseListing>
  alignment: Opt.Option<Alignment>
  topRight?: ReactNode
  bottomLeft?: ReactNode
  muscleLevels: Opt.Option<
    Rec.ReadonlyRecord<MuscleId_, number | false | undefined>
  >
  overrideTitle: Opt.Option<ReactNode>
  children?: ReactNode
  onSetActivityListing: (
    listing: ActivitySettingsExerciseListing,
  ) => IO.IO<void>
}

export const Activity = ({
  activity,
  activityListing,
  alignment,
  topRight,
  bottomLeft,
  muscleLevels,
  overrideTitle,
  children,
  onSetActivityListing,
}: ActivityProps) => {
  const [alignmentDescriptionIsOpen, setAlignmentDescriptionIsOpen] =
    useState(false)
  const [bodyMapMode, setBodyMapMode] = useState(BodyMapMode.shade)

  const onMouseEnter = useCallback(
    () => setBodyMapMode(BodyMapMode.colour),
    [setBodyMapMode],
  )
  const onMouseLeave = useCallback(
    () => setBodyMapMode(BodyMapMode.shade),
    [setBodyMapMode],
  )

  return (
    <Activity_
      image={<ActivityImage value={activity.id} />}
      title={pipe(
        overrideTitle,
        Opt.getOrElse<ReactNode>(() => (
          <ActivityName id={activity.id} def={activity.value.value.title} />
        )),
      )}
      {...{ alignment }}
      alignmentDescriptionIsOpen={alignmentDescriptionIsOpen}
      onAlignmentDescriptionIsOpenChange={(v) => () =>
        setAlignmentDescriptionIsOpen(v)
      }
      {...{ topRight }}
    >
      <Content>
        <ContentMain>{children}</ContentMain>
        <ActivityBodyMap data-help-mode="bodyMap">
          {pipe(
            muscleLevels,
            Opt.map(
              flow(
                (muscleShares) => ({
                  [Dorsoventral.ventral]: muscleShares,
                  [Dorsoventral.dorsal]: muscleShares,
                }),
                Rec.collect(Str.Ord)((i, vs) => (
                  <BodyMapAltContainer
                    key={i}
                    mode={bodyMapMode}
                    dorsoventralSide={i}
                    muscleLevels={pipe(vs)}
                    invertLevels={true}
                    onMuscleClick={constUndefined}
                    toggleMusclePopover={constUndefined}
                    {...{ onMouseEnter, onMouseLeave }}
                  />
                )),
              ),
            ),
            Opt.getOrElse<ReadonlyArray<ReactNode>>(() => []),
          )}
        </ActivityBodyMap>
      </Content>
      <Bottom>
        <div>{bottomLeft}</div>
        <BottomRight data-help-mode="likeAndDenylist">
          {pipe(
            activity,
            _withId<ActivityId, ActivityType>(activityId)._value.get,
            foldActivity(
              () => (
                <Buttons
                  isLiked={pipe(
                    activityListing,
                    Opt.map((s) => s === ActivitySettingsExerciseListing.liked),
                    Opt.getOrElse(constFalse),
                  )}
                  isDenylisted={pipe(
                    activityListing,
                    Opt.map(
                      (s) => s === ActivitySettingsExerciseListing.denylisted,
                    ),
                    Opt.getOrElse(constFalse),
                  )}
                  onSetLike={flow(
                    Bool.fold(
                      () => ActivitySettingsExerciseListing.default,
                      () => ActivitySettingsExerciseListing.liked,
                    ),
                    onSetActivityListing,
                  )}
                  onSetDenylist={flow(
                    Bool.fold(
                      () => ActivitySettingsExerciseListing.default,
                      () => ActivitySettingsExerciseListing.denylisted,
                    ),
                    onSetActivityListing,
                  )}
                />
              ),
              constNull,
              constNull,
              constNull,
            ),
          )}
        </BottomRight>
      </Bottom>
    </Activity_>
  )
}

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivitiesResponseFromJSON, ActivitySettingsFromJSON, ActivitySettingsToJSON, ActivityToJSON, SportsActivitiesResponseFromJSON, } from "../models";
import * as runtime from "../runtime";
/**
 *
 */
export class ActivitiesApi extends runtime.BaseAPI {
    /**
     * Handles GET requests for Activities.Controllers.Internal.ActivitiesController.
     */
    async activitiesApiInternalUsersUserIdActivitiesGetRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling activitiesApiInternalUsersUserIdActivitiesGet.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activities/api/internal/users/{userId}/activities`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ActivitiesResponseFromJSON(jsonValue));
    }
    /**
     * Handles GET requests for Activities.Controllers.Internal.ActivitiesController.
     */
    async activitiesApiInternalUsersUserIdActivitiesGet(requestParameters, initOverrides) {
        const response = await this.activitiesApiInternalUsersUserIdActivitiesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Handles GET requests for Activities.Controllers.ActivitiesController.
     */
    async activitiesApiUsersUserIdActivitiesGetRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling activitiesApiUsersUserIdActivitiesGet.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activities/api/users/{userId}/activities`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SportsActivitiesResponseFromJSON(jsonValue));
    }
    /**
     * Handles GET requests for Activities.Controllers.ActivitiesController.
     */
    async activitiesApiUsersUserIdActivitiesGet(requestParameters, initOverrides) {
        const response = await this.activitiesApiUsersUserIdActivitiesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async activitiesApiUsersUserIdActivitiesIdSettingsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling activitiesApiUsersUserIdActivitiesIdSettingsGet.");
        }
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling activitiesApiUsersUserIdActivitiesIdSettingsGet.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activities/api/users/{userId}/activities/{id}/settings`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ActivitySettingsFromJSON(jsonValue));
    }
    /**
     */
    async activitiesApiUsersUserIdActivitiesIdSettingsGet(requestParameters, initOverrides) {
        const response = await this.activitiesApiUsersUserIdActivitiesIdSettingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async activitiesApiUsersUserIdActivitiesIdSettingsPutRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling activitiesApiUsersUserIdActivitiesIdSettingsPut.");
        }
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling activitiesApiUsersUserIdActivitiesIdSettingsPut.");
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters["Content-Type"] = "application/json-patch+json";
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activities/api/users/{userId}/activities/{id}/settings`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
            body: ActivitySettingsToJSON(requestParameters.activitySettings),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async activitiesApiUsersUserIdActivitiesIdSettingsPut(requestParameters, initOverrides) {
        await this.activitiesApiUsersUserIdActivitiesIdSettingsPutRaw(requestParameters, initOverrides);
    }
    /**
     * Handles POST requests for Activities.Controllers.ActivitiesController.
     */
    async activitiesApiUsersUserIdActivitiesPostRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling activitiesApiUsersUserIdActivitiesPost.");
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters["Content-Type"] = "application/json-patch+json";
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activities/api/users/{userId}/activities`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.activity.map((a) => ActivityToJSON(a)),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SportsActivitiesResponseFromJSON(jsonValue));
    }
    /**
     * Handles POST requests for Activities.Controllers.ActivitiesController.
     */
    async activitiesApiUsersUserIdActivitiesPost(requestParameters, initOverrides) {
        const response = await this.activitiesApiUsersUserIdActivitiesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the AboutMeSummaryResponse interface.
 */
export function instanceOfAboutMeSummaryResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "answersProvided" in value;
    return isInstance;
}
export function AboutMeSummaryResponseFromJSON(json) {
    return AboutMeSummaryResponseFromJSONTyped(json, false);
}
export function AboutMeSummaryResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        answersProvided: json.answersProvided,
    };
}
export function AboutMeSummaryResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        answersProvided: value.answersProvided,
    };
}

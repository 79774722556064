import {
  Alternative,
  Alternative1,
  Alternative2,
  Alternative2C,
  Alternative3,
} from "fp-ts/es6/Alternative"
import * as Apply from "fp-ts/es6/Apply"
import * as E from "fp-ts/es6/Either"
import * as Eq from "fp-ts/es6/Eq"
import { Foldable, Foldable1, Foldable2, Foldable3 } from "fp-ts/es6/Foldable"
import { flow, pipe } from "fp-ts/es6/function"
import * as Func from "fp-ts/es6/function"
import {
  HKT,
  Kind,
  Kind2,
  Kind3,
  Kind4,
  URIS,
  URIS2,
  URIS3,
  URIS4,
} from "fp-ts/es6/HKT"
import * as IO from "fp-ts/es6/IO"
import * as Magma from "fp-ts/es6/Magma"
import { NonEmptyArray } from "fp-ts/es6/NonEmptyArray"
import * as Num from "fp-ts/es6/number"
import * as Opt from "fp-ts/es6/Option"
import { Option, Some } from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { ReadonlyNonEmptyArray } from "fp-ts/es6/ReadonlyNonEmptyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as Pair from "fp-ts/es6/ReadonlyTuple"
import * as TE from "fp-ts/es6/TaskEither"
import { Traversable1 } from "fp-ts/es6/Traversable"
import {
  At,
  fromTraversable,
  Getter,
  Index,
  Lens,
  Optional as LensOptional,
  Prism,
  Traversal,
} from "monocle-ts"
import { Newtype } from "newtype-ts"

import { Observable } from "rxjs"
import * as O2 from "rxjs"
import * as rxjs from "rxjs/operators"

// taken from https://github.com/microsoft/TypeScript/issues/31153
type KnownKeys<T> = {
  [K in keyof T as string extends K
    ? never
    : number extends K
    ? never
    : K]: T[K]
}
export type OmitFromKnownKeys<
  T,
  K extends keyof T,
> = KnownKeys<T> extends infer U
  ? keyof U extends keyof T
    ? Pick<T, Exclude<keyof U, K>> &
        Pick<T, Exclude<keyof T, keyof KnownKeys<T>>>
    : never
  : never

export type DeepPartial<a> = a extends any
  ? any
  : a extends NonEmptyArray<infer b>
  ? NonEmptyArray<b>
  : a extends Array<infer b>
  ? Array<b>
  : a extends ReadonlyNonEmptyArray<infer b>
  ? ReadonlyNonEmptyArray<b>
  : a extends ReadonlyArray<infer b>
  ? ReadonlyArray<b>
  : {
      [b in keyof a]?: DeepPartial<a[b]>
    }

export type PartialPartial<a, k extends keyof a> = Partial<Pick<a, k>> &
  Omit<a, k>
export type PartialRequired<a, k extends keyof a> = Pick<a, k> &
  Partial<Omit<a, k>>

export type Optional<a extends Record<string, unknown>> = {
  [k in keyof a]: Option<a[k]>
}
export type PartialOptional<a, k extends keyof a> = Optional<Pick<a, k>> &
  Omit<a, k>
export type PartialNotOptional<a, k extends keyof a> = Pick<a, k> &
  Optional<Omit<a, k>>
export type OptionValue<a> = a extends Some<infer v> ? v : never

export type Eithers<e, a extends Record<string, unknown>> = {
  [k in keyof a]: E.Either<e, a[k]>
}
export type PartialEithers<e, a, k extends keyof a> = Eithers<e, Pick<a, k>> &
  Omit<a, k>
export type PartialNotEithers<e, a, k extends keyof a> = Pick<a, k> &
  Eithers<e, Omit<a, k>>
export type EitherRight<a> = a extends E.Right<infer v> ? v : never
export type EitherLeft<a> = a extends E.Left<infer v> ? v : never

export const liftGetter2 =
  <s, a>(getter: Getter<s, a>) =>
  <c>(f: (a: a, b: a) => c) =>
  (a: s, b: s) =>
    f(getter.get(a), getter.get(b))
export const liftGetter2C =
  <s, a>(getter: Getter<s, a>) =>
  <c>(f: (a: a) => (b: a) => c) =>
  (a: s) =>
  (b: s) =>
    f(getter.get(a))(getter.get(b))
export const liftGetterEq =
  <s, a>(getter: Getter<s, a>) =>
  (eq: Eq.Eq<a>): Eq.Eq<s> => ({
    equals: liftGetter2(getter)(eq.equals),
  })
export const prismToGetter = <s, a>(prism: Prism<s, a>) =>
  new Getter(prism.reverseGet)

type PromiseValue<a> = a extends Promise<infer v> ? v : never
export const wrapTaskEither =
  <f extends (...args: Array<any>) => Promise<any>, e>(
    f: f,
    e: (v: unknown) => e,
  ) =>
  (...args: Parameters<f>) =>
    TE.tryCatch<e, PromiseValue<ReturnType<f>>>(() => f(...args), e)

export const wrapEither =
  <f extends (...args: Array<any>) => any, e>(f: f, e: (v: unknown) => e) =>
  (...args: Parameters<f>) =>
    E.tryCatch<e, PromiseValue<ReturnType<f>>>(() => f(...args), e)
export const unsafeFromLeft = <e, a>(v: E.Either<e, a>) => (v as E.Left<e>).left
export const unsafeFromRight = <e, a>(v: E.Either<e, a>) =>
  (v as E.Right<a>).right
export const catchEither = <e, a>(f: (e: e) => a) =>
  E.fold<e, a, a>(f, Func.identity)

export const wrapOption =
  <f extends (...args: Array<any>) => any>(f: f) =>
  (...args: Parameters<f>) =>
    Opt.tryCatch<ReturnType<f>>(() => f(...args))
export const unsafeFromSome = <a>(v: Opt.Option<a>) => (v as Opt.Some<a>).value
export const catchOption = <a>(f: () => a) => Opt.fold<a, a>(f, Func.identity)

/** unwraps any newtype */
export const unwrap = <T>(newtype: Newtype<any, T>) => newtype as any as T

/** returns value as enum value if it appears in the enum */
export const valueAsEnum =
  <a extends number | string, enum_ extends { [x in string]: a }>(
    enum_: enum_,
  ) =>
  (value: a): Opt.Option<enum_[keyof enum_]> =>
    pipe(
      Object.values(enum_),
      Arr.findFirst((v) => v === value),
      (a) => a as Option<enum_[keyof enum_]>,
    )

export const withLatestFrom =
  <b>(b: Observable<b>) =>
  <a>(a: Observable<a>) =>
    a.pipe(rxjs.withLatestFrom(b))

export const arrayToObservable = <a>(as: ReadonlyArray<a>): O2.Observable<a> =>
  O2.from(as)

// (Foldable t, Alternative f) => t (f a) -> f a
export function asum<T extends URIS3, F extends URIS3>(
  T: Foldable3<T>,
  F: Alternative3<F>,
): <R1, E1, R2, E2, A>(
  fa: Kind3<T, R1, E1, Kind3<F, R2, E2, A>>,
) => Kind3<F, R2, E2, A>
export function asum<T extends URIS2, F extends URIS3>(
  T: Foldable2<T>,
  F: Alternative3<F>,
): <E1, R, E2, A>(fa: Kind2<T, E1, Kind3<F, R, E2, A>>) => Kind3<F, R, E2, A>
export function asum<T extends URIS, F extends URIS3>(
  T: Foldable<T>,
  F: Alternative3<F>,
): <R, E, A>(fa: Kind<T, Kind3<F, R, E, A>>) => Kind3<F, R, E, A>
export function asum<T extends URIS2, F extends URIS2>(
  T: Foldable2<T>,
  F: Alternative2<F>,
): <E1, E2, A>(fa: Kind2<T, E1, Kind2<F, E2, A>>) => Kind2<F, E2, A>
export function asum<T extends URIS2, F extends URIS2, E2>(
  T: Foldable2<T>,
  F: Alternative2C<F, E2>,
): <E1, A>(fa: Kind2<T, E1, Kind2<F, E2, A>>) => Kind2<F, E2, A>
export function asum<T extends URIS, F extends URIS2>(
  T: Foldable1<T>,
  F: Alternative2<F>,
): <E, A>(fa: Kind<T, Kind2<F, E, A>>) => Kind2<F, E, A>
export function asum<T extends URIS, F extends URIS2, E>(
  T: Foldable1<T>,
  F: Alternative2C<F, E>,
): <A>(fa: Kind<T, Kind2<F, E, A>>) => Kind2<F, E, A>
export function asum<T extends URIS, F extends URIS>(
  T: Foldable1<T>,
  F: Alternative1<F>,
): <A>(fa: Kind<T, Kind<F, A>>) => Kind<F, A>
export function asum<T, F>(T: Foldable<T>, F: Alternative<F>) {
  return <A>(fa: HKT<T, HKT<F, A>>): HKT<F, A> =>
    T.reduce(fa, F.zero(), (b, a) => F.alt(a, () => b))
}

// .uanai Not sure what's happening here, something about the
// RO Array is just broken.
export const arrTrav = <a>(): Traversal<ReadonlyArray<a>, a> =>
  fromTraversable(Arr.Traversable as any as Traversable1<any>)<never, a>()

export const composeLensAt = <s, i, a, b>(lens: Lens<s, a>, at: At<a, i, b>) =>
  new At<s, i, b>((i: i) => lens.composeLens(at.at(i)))

export const composeLensIndex = <s, i, a, b>(
  lens: Lens<s, a>,
  index: Index<a, i, b>,
) => new Index<s, i, b>((i: i) => lens.composeOptional(index.index(i)))

export const optionLensToOptional = <s, a>(lens: Lens<s, Opt.Option<a>>) =>
  new LensOptional<s, a>(lens.get, flow(Opt.some, lens.set))

export const mapPair = <a, b>(f: (a: a) => b) => Pair.bimap(f, f)

export function bisequencePair<f extends URIS>(
  F: Apply.Apply1<f>,
): <a, b>([a, b]: readonly [Kind<f, a>, Kind<f, b>]) => Kind<f, [a, b]>
export function bisequencePair<f extends URIS2>(
  F: Apply.Apply2<f>,
): <e, a, b>([a, b]: readonly [Kind2<f, e, a>, Kind2<f, e, b>]) => Kind2<
  f,
  e,
  [a, b]
>
export function bisequencePair<f>(F: Apply.Apply<f>) {
  return <a, b>([a, b]: readonly [HKT<f, a>, HKT<f, b>]) =>
    Apply.sequenceT(F)(a, b)
}

export function bitraversePair<f extends URIS>(
  F: Apply.Apply1<f>,
): <a, b, c, d>(
  f: (v: a) => Kind<f, c>,
  g: (v: b) => Kind<f, d>,
) => ([a, b]: readonly [a, b]) => Kind<f, [c, d]>
export function bitraversePair<f extends URIS2>(
  F: Apply.Apply2<f>,
): <e, a, b, c, d>(
  f: (v: a) => Kind2<f, e, c>,
  g: (v: b) => Kind2<f, e, d>,
) => ([a, b]: readonly [a, b]) => Kind2<f, e, [c, d]>
export function bitraversePair<f>(F: Apply.Apply<f>) {
  return <a, b, c, d>(f: (v: a) => HKT<f, c>, g: (v: b) => HKT<f, d>) =>
    ([a, b]: readonly [a, b]) =>
      Apply.sequenceT(F)(f(a), g(b))
}

export const runIO = <a>(a: IO.IO<a>) => a()

export const explicitOrder = <a>(as: ReadonlyArray<a>): Ord.Ord<a> =>
  Ord.fromCompare((a, b) => Num.Ord.compare(as.indexOf(a), as.indexOf(b)))

export const mapKeys =
  <k extends string, k2 extends string, v>(
    M: Magma.Magma<v>,
    fk: (k: k) => k2,
  ) =>
  (v: Rec.ReadonlyRecord<k, v>): Rec.ReadonlyRecord<k2, v> =>
    pipe(
      v,
      Rec.toReadonlyArray,
      Arr.map(([k, v]) => [fk(k), v] as const),
      Rec.fromFoldable(M, Arr.Foldable),
    )

export const filterMapKeys =
  <k extends string, k2 extends string, v>(
    M: Magma.Magma<v>,
    fk: (k: k) => Opt.Option<k2>,
  ) =>
  (v: Rec.ReadonlyRecord<k, v>): Rec.ReadonlyRecord<k2, v> =>
    pipe(
      v,
      Rec.toReadonlyArray,
      Arr.filterMap(([k, v]) =>
        bisequencePair(Opt.Apply)([fk(k), Opt.some(v)]),
      ),
      Rec.fromFoldable(M, Arr.Foldable),
    )

export type SetFirstArgType<
  a,
  f extends (a: a, ...as: Array<any>) => any,
  b,
> = f extends (a: any, ...as: infer as) => infer r
  ? (a: b, ...as: as) => r
  : never

export const atPair_ = <a>() =>
  new At<readonly [a, a], 0 | 1, a>(
    (i) =>
      new Lens<readonly [a, a], a>(
        (s) => s[i],
        (a) =>
          ([b, b2]) =>
            i === 0 ? [a, b2] : [b, a],
      ),
  )
export const atPair = <a>() =>
  new At<readonly [a, a], 0 | 1, a>(
    (i) =>
      new Lens(
        (s) => s[i],
        (a) =>
          ([b, b2]) =>
            i === 0 ? [a, b2] : [b, a],
      ),
  )

export const nullablePrism = <a>() =>
  new Prism<a, NonNullable<a>>(Opt.fromNullable, Func.identity)

// export const tap = <A>((a: A) => void): (a: (a: A) => A) => {
export const inspect =
  <A>(fn: (a: A) => void) =>
  (a: A): A => {
    fn(a)
    return a
  }

/** Get `T` as numeric literal, if possible. */
export type NumLit<T extends number | string> = T extends number
  ? `${T}` extends `${infer T2 extends number}`
    ? T2
    : never
  : T extends string
  ? T extends `${infer T2 extends number}`
    ? T2
    : never
  : never

/** Turns given value into number with numeric literal type, if possible. */
export const numLit = <T extends number | string>(value: T): NumLit<T> =>
  Number(value) as unknown as NumLit<T>

/** Get union type for enum. */
export type EnumUnion<T extends number | string> =
  `${T}` extends `${infer T2 extends number}`
    ? T2
    : `${T}` extends `${infer T3 extends string}`
    ? T3
    : never

/** Checks if value is known member of enum. */
const isEnumUnionMember =
  <EnumType extends Record<string, number | string>>(enumObj: EnumType) =>
  (value: number | string): value is EnumUnion<EnumType[keyof EnumType]> =>
    Object.values(enumObj).includes(value)

/** Match on numeric enum including match for unknown values. */
export const matchEnum =
  <EnumType extends Record<string, number | string>>(enumObj: EnumType) =>
  (value: EnumType[keyof EnumType]) =>
  <R>(
    onKnown: (value: EnumUnion<EnumType[keyof EnumType]>) => R,
    onUnknown: (value: number | string) => R,
  ) =>
    isEnumUnionMember(enumObj)(value) ? onKnown(value) : onUnknown(value)

export const matchEnum2 =
  <EnumType extends Record<string, number | string>>(enumObj: EnumType) =>
  (value: EnumType[keyof EnumType]) =>
  <R>(
    onKnown: (value: EnumUnion<EnumType[keyof EnumType]>) => R,
    onUnknown: (value: number | string) => R,
  ) =>
    isEnumUnionMember(enumObj)(value) ? onKnown(value) : onUnknown(value)

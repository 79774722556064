import { FC } from "react"

import { flow } from "fp-ts/es6/function"
import { Day, isoStringAsDay } from "time-ts/es6"

import { DayDuration } from "@fitnesspilot/data-common/dist/time"

import { input, InputProps } from "./input"

export type DayInputProps = InputProps<Day, DayDuration>

export const DayInput: FC<DayInputProps> = input(
  "date",
  isoStringAsDay,
  flow((v: DayDuration) => v.days, String),
)

import { ComponentProps } from "react"
import { ButtonGroup } from "reactstrap"
import styled from "@emotion/styled"

export type ClearButtonGroupProps = ComponentProps<typeof ClearButtonGroup>

export const ClearButtonGroup = styled(ButtonGroup)`
  & > .btn:not(:last-of-type)::before {
    position: absolute;
    content: "";
    top: 0;
    right: -2px;
    width: 2px;
    height: calc(100% - 12px);
    margin: 6px 0;
    background: ${({ theme }) => theme.colours.grey[200].toString()};
  }

  & > .btn:active,
  & > .btn.active {
    box-shadow: none;
  }
`

import * as Bool from "fp-ts/es6/boolean"
import * as Eq from "fp-ts/es6/Eq"
import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import { Lens } from "monocle-ts"
import {
  isoStringAsLocalTimeOfDayDuration,
  LocalTimeOfDay,
  localTimeOfDay,
  LocalTimeOfDayDuration,
  localTimeOfDayDuration,
  mkLocalTimeOfDay,
} from "time-ts/es6"
import { atPair_, unsafeFromSome } from "@fitnesspilot/data-common"

import * as DayOfWeek from "@fitnesspilot/data-common/dist/DayOfWeek"

import { defaultJob, Job, job } from "./Job"

export type Work = {
  job: Job
  mayEatAtWork: boolean
  lunchtime: {
    duration: LocalTimeOfDayDuration
    within: readonly [LocalTimeOfDay, LocalTimeOfDay]
  }
  time: Rec.ReadonlyRecord<
    DayOfWeek.DayOfWeek,
    Opt.Option<readonly [LocalTimeOfDay, LocalTimeOfDay]>
  >
}
export const work: Eq.Eq<Work> = Eq.struct({
  job,
  mayEatAtWork: Bool.Eq,
  lunchtime: Eq.struct({
    duration: localTimeOfDayDuration,
    within: Eq.tuple(localTimeOfDay, localTimeOfDay),
  }),
  time: Rec.getEq(Opt.getEq(Eq.tuple(localTimeOfDay, localTimeOfDay))),
})
export const _job = Lens.fromProp<Work>()("job")
export const _mayEatAtWork = Lens.fromProp<Work>()("mayEatAtWork")
export const _lunchtime = Lens.fromProp<Work>()("lunchtime")
export const _lunchtimeDuration = Lens.fromPath<Work>()([
  "lunchtime",
  "duration",
])
export const _lunchtimeWithin = Lens.fromPath<Work>()(["lunchtime", "within"])
export const _lunchtimeStart = _lunchtimeWithin.composeLens(
  atPair_<LocalTimeOfDay>().at(0),
)
export const _lunchtimeEnd = _lunchtimeWithin.composeLens(
  atPair_<LocalTimeOfDay>().at(1),
)
export const _time = Lens.fromProp<Work>()("time")

export const defaultWorkTime: [LocalTimeOfDay, LocalTimeOfDay] = [
  pipe(mkLocalTimeOfDay(9, 0, 0), unsafeFromSome),
  pipe(mkLocalTimeOfDay(17, 30, 0), unsafeFromSome),
]
export const defaultWorkTimeDays: ReadonlySet<DayOfWeek.DayOfWeek> = new Set([
  DayOfWeek.DayOfWeek.monday,
  DayOfWeek.DayOfWeek.tuesday,
  DayOfWeek.DayOfWeek.wednesday,
  DayOfWeek.DayOfWeek.thursday,
  DayOfWeek.DayOfWeek.friday,
])
export const defaultWork: Work = {
  job: defaultJob,
  mayEatAtWork: false,
  lunchtime: {
    duration: pipe(
      "PT30M",
      isoStringAsLocalTimeOfDayDuration.getOption,
      unsafeFromSome,
    ),
    within: [
      pipe(mkLocalTimeOfDay(12, 0, 0), unsafeFromSome),
      pipe(mkLocalTimeOfDay(12, 30, 0), unsafeFromSome),
    ],
  },
  time: pipe(
    DayOfWeek.DayOfWeek,
    Rec.map((k) =>
      defaultWorkTimeDays.has(k) ? Opt.some(defaultWorkTime) : Opt.none,
    ),
  ),
}

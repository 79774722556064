import * as Func from "fp-ts/es6/function"
import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Str from "fp-ts/es6/string"
import { Iso } from "monocle-ts"
import { catchOption } from "@fitnesspilot/data-common"

import { DayOfWeek } from "@fitnesspilot/data-common/dist/DayOfWeek"

export * from "@fitnesspilot/data-common/dist/DayOfWeek"

export enum DaysShort {
  mo = "mo",
  tu = "tu",
  we = "we",
  th = "th",
  fr = "fr",
  sa = "sa",
  su = "su",
}

export const dayPropertyMap = {
  [DaysShort.mo]: "Monday",
  [DaysShort.tu]: "Tuesday",
  [DaysShort.we]: "Wednesday",
  [DaysShort.th]: "Thursday",
  [DaysShort.fr]: "Friday",
  [DaysShort.sa]: "Saturday",
  [DaysShort.su]: "Sunday",
}

export enum DaySelector {
  "mo-th" = "mo-th",
  "wday" = "wday",
  "wend" = "wend",
  "day" = "day",
  "mo" = "mo",
  "tu" = "tu",
  "we" = "we",
  "th" = "th",
  "fr" = "fr",
  "sa" = "sa",
  "su" = "su",
}

export const daySelectorDays = {
  [DaySelector.day]: [
    DaysShort.mo,
    DaysShort.tu,
    DaysShort.we,
    DaysShort.th,
    DaysShort.fr,
    DaysShort.sa,
    DaysShort.su,
  ],
  [DaySelector.wday]: [
    DaysShort.mo,
    DaysShort.tu,
    DaysShort.we,
    DaysShort.th,
    DaysShort.fr,
  ],
  [DaySelector["mo-th"]]: [
    DaysShort.mo,
    DaysShort.tu,
    DaysShort.we,
    DaysShort.th,
  ],
  [DaySelector.wend]: [DaysShort.sa, DaysShort.su],
  [DaySelector.mo]: [DaysShort.mo],
  [DaySelector.tu]: [DaysShort.tu],
  [DaySelector.we]: [DaysShort.we],
  [DaySelector.th]: [DaysShort.th],
  [DaySelector.fr]: [DaysShort.fr],
  [DaySelector.sa]: [DaysShort.sa],
  [DaySelector.su]: [DaysShort.su],
}

export const dayOfWeekDaysShortMap = {
  [DayOfWeek.monday]: DaysShort.mo,
  [DayOfWeek.tuesday]: DaysShort.tu,
  [DayOfWeek.wednesday]: DaysShort.we,
  [DayOfWeek.thursday]: DaysShort.th,
  [DayOfWeek.friday]: DaysShort.fr,
  [DayOfWeek.saturday]: DaysShort.sa,
  [DayOfWeek.sunday]: DaysShort.su,
}
export const daysShortDayOfWeekMap = {
  [DaysShort.mo]: DayOfWeek.monday,
  [DaysShort.tu]: DayOfWeek.tuesday,
  [DaysShort.we]: DayOfWeek.wednesday,
  [DaysShort.th]: DayOfWeek.thursday,
  [DaysShort.fr]: DayOfWeek.friday,
  [DaysShort.sa]: DayOfWeek.saturday,
  [DaysShort.su]: DayOfWeek.sunday,
}
export const dayOfWeekDaysShort = new Iso<DayOfWeek, DaysShort>(
  (dw) => dayOfWeekDaysShortMap[dw],
  (ds) => daysShortDayOfWeekMap[ds],
)

export const matchesDay = (selector: DaySelector, day: DaysShort) =>
  pipe(
    daySelectorDays[selector],
    Opt.fromNullable,
    Opt.map((arr) => Arr.elem(Str.Eq)(day, arr)),
    catchOption(Func.constFalse),
  )

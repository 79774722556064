import * as Eq from "fp-ts/es6/Eq"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as Str from "fp-ts/es6/string"
import { Lens } from "monocle-ts"

export enum ActivityHabits {
  lazy = "lazy",
  moderate = "moderate",
  active = "active",
}
export const activityHabits: Eq.Eq<ActivityHabits> = Str.Eq

export enum EatingHabits {
  untilFull = "untilFull",
  untilNotHungry = "untilNotHungry",
  untilSomewhatHungry = "untilSomewhatHungry",
}
export const eatingHabits: Eq.Eq<EatingHabits> = Str.Eq

export enum DrinkingHabits {
  sufficient = "sufficient",
  insufficient = "insufficient",
  grosslyInsufficient = "grosslyInsufficient",
}
export const drinkingHabits: Eq.Eq<DrinkingHabits> = Str.Eq

export enum ConsumptionType {
  alcohol = "alcohol",
  smoking = "smoking",
}
export const consumptionType: Eq.Eq<ConsumptionType> = Str.Eq

export enum ConsumptionLevel {
  never = "never",
  occasionally = "occasionally",
  frequently = "frequently",
}
export const consumptionLevel: Eq.Eq<ConsumptionLevel> = Str.Eq

export type Habits = {
  activity: ActivityHabits
  eating: EatingHabits
  drinking: DrinkingHabits
  consumption: Rec.ReadonlyRecord<ConsumptionType, ConsumptionLevel>
}
export const habits: Eq.Eq<Habits> = Eq.struct({
  activity: activityHabits,
  eating: eatingHabits,
  drinking: drinkingHabits,
  consumption: Rec.getEq(consumptionLevel),
})

export const _activity = Lens.fromProp<Habits>()("activity")
export const _eating = Lens.fromProp<Habits>()("eating")
export const _drinking = Lens.fromProp<Habits>()("drinking")
export const _consumption = Lens.fromProp<Habits>()("consumption")
export const _consumptionAlcohol = Lens.fromPath<Habits>()([
  "consumption",
  ConsumptionType.alcohol,
])
export const _consumptionSmoking = Lens.fromPath<Habits>()([
  "consumption",
  ConsumptionType.smoking,
])

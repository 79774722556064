/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ExerciseSlot interface.
 */
export function instanceOfExerciseSlot(value) {
    let isInstance = true;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "end" in value;
    return isInstance;
}
export function ExerciseSlotFromJSON(json) {
    return ExerciseSlotFromJSONTyped(json, false);
}
export function ExerciseSlotFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        start: new Date(json.start),
        end: new Date(json.end),
    };
}
export function ExerciseSlotToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        start: value.start.toISOString(),
        end: value.end.toISOString(),
    };
}

import { FC, ReactNode } from "react"
import { Button } from "reactstrap"
import styled from "@emotion/styled"

import { pipe } from "fp-ts/es6/function"
import * as Arr from "fp-ts/es6/ReadonlyArray"

import {
  CombinedFitnessLevel,
  FitnessLevel as FitnessLevel_,
} from "@fitnesspilot/data-human-body/dist/fitnessLevel"

import { FitnessLevelWithImage } from "../../molecules/FitnessLevelWithImage/FitnessLevelWithImage"
import { FitnessLevelGrid } from "../FitnessLevelGrid/FitnessLevelGrid"

type Triple<a> = [a, a, a]

const FitnessLevelButton = styled(Button)`
  padding: 0;
`

export type FitnessLevelButtonGridProps = {
  onChange: (v: CombinedFitnessLevel) => void
}

export const FitnessLevelButtonGrid: FC<FitnessLevelButtonGridProps> = ({
  onChange,
}) => (
  <FitnessLevelGrid>
    {
      pipe(
        Object.values(FitnessLevel_),
        Arr.map((musculature) =>
          pipe(
            Object.values(FitnessLevel_),
            Arr.reverse,
            Arr.map((bodyFat) => (
              <FitnessLevelButton
                key={`${musculature}-${bodyFat}`}
                onClick={() =>
                  onChange({
                    musculature,
                    bodyFat,
                  })
                }
                color="primary"
              >
                <FitnessLevelWithImage
                  autoHeight
                  fitnessLevel={{ bodyFat, musculature }}
                />
              </FitnessLevelButton>
            )),
          ),
        ),
      ) as Triple<Triple<ReactNode>>
    }
  </FitnessLevelGrid>
)

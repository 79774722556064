import { memo, ReactNode, useRef, useState } from "react"
import { Tooltip as Tooltip_, TooltipProps as TooltipProps_ } from "reactstrap"
import { TooltipChildren } from "reactstrap/lib/Tooltip"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

export type TooltipProps = Omit<
  TooltipProps_,
  "isOpen" | "toggle" | "target" | "title" | "children"
> & {
  title: TooltipChildren
  children: ReactNode
}

const TooltipInner = styled(({ children, title, ...props }: TooltipProps) => {
  const targetRef = useRef<HTMLDivElement>(null)
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false)

  return (
    <>
      <div ref={targetRef}>{children}</div>
      {targetRef && (
        <Tooltip_
          container="#popover-container"
          isOpen={tooltipIsOpen}
          toggle={() => setTooltipIsOpen(!tooltipIsOpen)}
          target={targetRef}
          {...props}
        >
          {title}
        </Tooltip_>
      )}
    </>
  )
})<{ minWidth?: number; mobileFullWidth?: boolean }>`
  & > .tooltip {
    & .tooltip-inner {
      background-color: ${({ theme }) => theme.colours.white.toString()};
      color: ${({ theme }) => theme.colours.black.toString()};
      border: none;
      border-radius: 5px;
      box-shadow: 0px 0px 20px #cccccc;
      ${({ minWidth }) =>
        minWidth &&
        css`
          min-width: ${minWidth}px;
        `};
    }

    &.bs-tooltip-auto[x-placement^="top"] .arrow::before,
    &.bs-tooltip-top .arrow::before {
      border-top-color: ${({ theme }) => theme.colours.white.toString()};
    }
    &.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
    &.bs-tooltip-bottom .arrow::before {
      border-bottom-color: ${({ theme }) => theme.colours.white.toString()};
    }
    &.bs-tooltip-auto[x-placement^="left"] .arrow::before,
    &.bs-tooltip-left .arrow::before {
      border-left-color: ${({ theme }) => theme.colours.white.toString()};
    }
    &.bs-tooltip-auto[x-placement^="right"] .arrow::before,
    &.bs-tooltip-right .arrow::before {
      border-right-color: ${({ theme }) => theme.colours.white.toString()};
    }
  }

  ${({ mobileFullWidth }) =>
    mobileFullWidth &&
    css`
      & > .tooltip {
        @media not screen and (min-width: 576px) {
          position: fixed !important;
          transform: initial !important;
          right: 0;
          margin: 1.75rem auto;

          & .arrow {
            display: none;
          }

          & .tooltip-inner {
            max-height: calc(100vh - 36px - 3.5rem);
            overflow-y: auto;
            overflow-x: hidden;
          }
        }
      }
    `}
`

export const Tooltip = memo(TooltipInner)

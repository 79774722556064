import { ReactNode } from "react"
import { FaInfoCircle } from "react-icons/fa"
import styled from "@emotion/styled"

import { Tooltip } from "../Tooltip/Tooltip"

const InfoContainer = styled.div`
  display: flex;
`

const InfoContent = styled.a`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: ${({ theme }) => theme.colours.grey[900].toString()};
  margin-bottom: 2px;
`

export type InfoProps = {
  children: ReactNode
  tooltipContent: ReactNode
}

export const Info = ({ children, tooltipContent }: InfoProps) => (
  <InfoContainer>
    <Tooltip placement="bottom" minWidth={300} title={tooltipContent}>
      <InfoContent>
        <FaInfoCircle />
        {children}
      </InfoContent>
    </Tooltip>
  </InfoContainer>
)

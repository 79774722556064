import * as Opt from "fp-ts/es6/Option"
import * as Tree from "fp-ts/es6/Tree"

import { AnyAPIError } from "@fitnesspilot/data-api"
import { MuscleId_ } from "@fitnesspilot/data-human-body/dist/Muscle"
import * as MuscleGroup from "@fitnesspilot/data-human-body/dist/muscleGroups"

import * as ActivityId from "../activity/ActivityId"
import { ActivityFilters } from "../activityFilters/ActivityFilters"
import { ActivitySorting } from "../activityFilters/ActivitySorting"
import { ActivitySettingsWithId } from "../activitySettings/ActivitySettings"
import * as ActivitySettingsExercise from "../activitySettings/ActivitySettingsExercise"
import { ActivityWithSettingsWithId } from "../activitySettings/ActivityWithSettings"
import * as GeneralSportsSettings from "../sport/GeneralSportsSettings"
import * as MuscleSettings from "../sport/MuscleSettings"
import * as Sport from "../sport/Sport"
import * as SportId from "../sport/SportId"

import { ActionType, createAction, createAsyncAction } from "typesafe-actions"

export const resetState = createAction("event/resetState", () => ({}))()

export const fetchMuscleSettingsAsync = createAsyncAction(
  "event/fetchMuscleSettings/request",
  "event/fetchMuscleSettings/success",
  "event/fetchMuscleSettings/failure",
)<void, Tree.Forest<MuscleSettings.MuscleSettings>, AnyAPIError>()
export const fetchMuscleSettings = fetchMuscleSettingsAsync.request

export const fetchActivitiesWithSettingsAsync = createAsyncAction(
  "event/fetchActivitiesWithSettings/request",
  "event/fetchActivitiesWithSettings/success",
  "event/fetchActivitiesWithSettings/failure",
)<void, ReadonlyArray<ActivityWithSettingsWithId>, AnyAPIError>()
export const fetchActivitiesWithSettings =
  fetchActivitiesWithSettingsAsync.request

export const fetchSportsAsync = createAsyncAction(
  "event/fetchSports/request",
  "event/fetchSports/success",
  "event/fetchSports/failure",
)<void, ReadonlyArray<Sport.SportWithId>, AnyAPIError>()
export const fetchSports = fetchSportsAsync.request

export const saveActivitySettingsAsync = createAsyncAction(
  "event/saveActivitySettings/request",
  "event/saveActivitySettings/success",
  "event/saveActivitySettings/failure",
)<ActivitySettingsWithId, ActivitySettingsWithId, AnyAPIError>()
export const saveActivitySettings = saveActivitySettingsAsync.request

export const saveMuscleSettingsAsync = createAsyncAction(
  "event/saveMuscleSettings/request",
  "event/saveMuscleSettings/success",
  "event/saveMuscleSettings/failure",
)<
  Tree.Forest<MuscleSettings.MuscleSettings>,
  Tree.Forest<MuscleSettings.MuscleSettings>,
  AnyAPIError
>()
export const saveMuscleSettings = saveMuscleSettingsAsync.request

export const saveSportAsync = createAsyncAction(
  "event/saveSport/request",
  "event/saveSport/success",
  "event/saveSport/failure",
)<Sport.SportWithId, Sport.SportWithId, AnyAPIError>()
export const saveSport = saveSportAsync.request

export const setSportIsLikedAsync = createAsyncAction(
  "event/setSportIsLiked/request",
  "event/setSportIsLiked/success",
  "event/setSportIsLiked/failure",
)<
  { id: SportId.SportId; value: boolean },
  { id: SportId.SportId; value: boolean },
  AnyAPIError
>()
export const setSportIsLiked = setSportIsLikedAsync.request

export const setActivityListingAsync = createAsyncAction(
  "event/setActivityListing/request",
  "event/setActivityListing/success",
  "event/setActivityListing/failure",
)<
  {
    activity: ActivityId.ActivityId
    listing: ActivitySettingsExercise.ActivitySettingsExerciseListing
  },
  {
    activity: ActivityId.ActivityId
    listing: ActivitySettingsExercise.ActivitySettingsExerciseListing
  },
  AnyAPIError
>()
export const setActivityListing = setActivityListingAsync.request

export const fetchGeneralSportsSettingsAsync = createAsyncAction(
  "event/fetchGeneralSportsSettings/request",
  "event/fetchGeneralSportsSettings/success",
  "event/fetchGeneralSportsSettings/failure",
)<void, GeneralSportsSettings.GeneralSportsSettings, AnyAPIError>()
export const fetchGeneralSportsSettings =
  fetchGeneralSportsSettingsAsync.request

export const setGeneralSportsSettingsAsync = createAsyncAction(
  "event/setGeneralSportsSettings/request",
  "event/setGeneralSportsSettings/success",
  "event/setGeneralSportsSettings/failure",
)<
  GeneralSportsSettings.GeneralSportsSettings,
  GeneralSportsSettings.GeneralSportsSettings,
  AnyAPIError
>()
export const setGeneralSportsSettings = setGeneralSportsSettingsAsync.request

export const setExerciseListSearch = createAction(
  "event/setExerciseListSearch",
  (value: Opt.Option<string>) => ({ value }),
)()
export const setExerciseListSorting = createAction(
  "event/setExerciseListSorting",
  (sorting: ActivitySorting) => ({ sorting }),
)()
export const setExerciseListFilters = createAction(
  "event/setExerciseListFilters",
  (filters: Partial<ActivityFilters>) => ({ filters }),
)()

export const setMuscleSettings = createAction(
  "event/setMuscleSettings",
  (muscles: Record<MuscleId_, MuscleSettings.MuscleSettingsBase>) => ({
    muscles,
  }),
)()

export const toggleMuscleGroup = createAction(
  "event/toggleMuscleGroup",
  (muscleGroup: MuscleGroup.MuscleGroups) => ({ muscleGroup }),
)()

export type Action = ActionType<
  | typeof resetState
  | (typeof fetchMuscleSettingsAsync)["request" | "success" | "failure"]
  | (typeof fetchActivitiesWithSettingsAsync)["request" | "success" | "failure"]
  | (typeof fetchSportsAsync)["request" | "success" | "failure"]
  | (typeof saveActivitySettingsAsync)["request" | "success" | "failure"]
  | (typeof saveMuscleSettingsAsync)["request" | "success" | "failure"]
  | (typeof saveSportAsync)["request" | "success" | "failure"]
  | (typeof setSportIsLikedAsync)["request" | "success" | "failure"]
  | (typeof setActivityListingAsync)["request" | "success" | "failure"]
  | (typeof fetchGeneralSportsSettingsAsync)["request" | "success" | "failure"]
  | (typeof setGeneralSportsSettingsAsync)["request" | "success" | "failure"]
  | typeof setExerciseListSearch
  | typeof setExerciseListSorting
  | typeof setExerciseListFilters
  | typeof setMuscleSettings
  | typeof toggleMuscleGroup
>

import { ReactNode } from "react"

import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Mp from "fp-ts/es6/ReadonlyMap"
import { Lens, Prism } from "monocle-ts"
import { explicitOrder } from "@fitnesspilot/data-common"

import { Step } from "./Step"

export type StepSet = {
  id: KnownStepSet
  title: ReactNode
  steps: ReadonlyArray<Step>
  showNext?: boolean
  startOn: Opt.Option<string>
  href: string
}

export enum KnownStepSet {
  help = "help",
  dashboard = "dashboard",
  calendar = "calendar",
  sports = "sports",
  aboutMe = "aboutMe",
}
export const allKnownStepSets = [
  KnownStepSet.help,
  KnownStepSet.dashboard,
  KnownStepSet.calendar,
  KnownStepSet.sports,
  KnownStepSet.aboutMe,
]
export const knownStepSet = explicitOrder(allKnownStepSets)
export type StepSelection = {
  set: KnownStepSet
  step: number
}
export const stepSelectionSet = Lens.fromProp<StepSelection>()("set")
export const stepSelectionStep = Lens.fromProp<StepSelection>()("step")

export const stepSetPaths: ReadonlyMap<
  KnownStepSet,
  Opt.Option<string>
> = new Map([
  [KnownStepSet.help, Opt.none],
  [KnownStepSet.dashboard, Opt.some("/dashboard")],
  [KnownStepSet.calendar, Opt.some("/calendar")],
  [KnownStepSet.sports, Opt.some("/sports")],
  [KnownStepSet.aboutMe, Opt.some("/aboutMe")],
])

export enum StepMode {
  step = "step",
  stepOverview = "stepOverview",
}
export type StepState =
  | { type: StepMode.step; value: StepSelection }
  | { type: StepMode.stepOverview }
export const _StepStateStep = new Prism<StepState, StepSelection>(
  (a) => (a.type === StepMode.step ? Opt.some(a.value) : Opt.none),
  (value) => ({ type: StepMode.step, value }),
)
export const _StepStateStepOverview = new Prism<StepState, void>(
  (a) => (a.type === StepMode.stepOverview ? Opt.some(undefined) : Opt.none),
  () => ({ type: StepMode.stepOverview }),
)

export type StepSets = Record<KnownStepSet, StepSet>
export const stepSetsToArray = <a>(stepSets: Record<KnownStepSet, a>) =>
  pipe(
    allKnownStepSets,
    Arr.map((k) => stepSets[k]),
  )

import { FC, Fragment, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { FormattedMessage } from "react-intl"
import { Button, FormGroup } from "reactstrap"

import { flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"

import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import {
  ControllerPlus,
  formGroupRender,
  noTransform,
  switchRender,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"
import {
  _importActivities,
  GoogleFit,
} from "@fitnesspilot/data-user/dist/GoogleFit"

export type FormData = GoogleFit

type InnerFormProps = {
  id: string
  defaultValues: FormData
  onSubmit: (v: FormData) => void
}

const InnerForm = ({ id, defaultValues, onSubmit }: InnerFormProps) => {
  const { handleSubmit, ...form } = useForm<FormData>({
    defaultValues,
  })

  useEffect(() => {
    form.reset(defaultValues)
  }, [defaultValues])

  return (
    <FormProvider {...{ handleSubmit }} {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Fieldset>
          <ControllerPlus<FormData, "importActivities", boolean>
            render={formGroupRender(switchRender)({
              id: `${id}-importActivities`,
              name: "importActivities",
              label: <FormattedMessage defaultMessage="Import activities" />,
            })}
            name="importActivities"
            transform={noTransform<FormData, "importActivities">()}
          />

          {form.formState.isDirty && (
            <FormGroup>
              <Button type="submit" color="primary">
                <FormattedMessage defaultMessage="Save" />
              </Button>
            </FormGroup>
          )}
        </Fieldset>
      </form>
    </FormProvider>
  )
}

export type GoogleFitFieldsetProps = {
  id: string
  onConnect: IO.IO<void>
  value: Opt.Option<GoogleFit>
  onSave: (v: Opt.Option<GoogleFit>) => IO.IO<void>
}

export const GoogleFitFieldset: FC<GoogleFitFieldsetProps> = ({
  id,
  onConnect,
  value,
  onSave,
}) => (
  <>
    <h2>
      <FormattedMessage defaultMessage="Google Fit" />
    </h2>

    {pipe(
      value,
      Opt.fold(
        () => (
          <Fragment>
            <p>
              <FormattedMessage defaultMessage="Connecting your Google Fit account allows the import of activity & weight measurements." />
            </p>

            <FormGroup>
              <Button type="button" onClick={() => onConnect()} color="primary">
                <FormattedMessage defaultMessage="Connect" />
              </Button>
            </FormGroup>
          </Fragment>
        ),
        (defaultValues) => (
          <Fragment>
            <InnerForm
              {...{ id, defaultValues }}
              onSubmit={(v) => pipe(v, Opt.some, onSave)()}
            />
          </Fragment>
        ),
      ),
    )}
  </>
)

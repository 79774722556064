import { FC, ReactNode } from "react"
import { FaBalanceScale, FaShieldAlt } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import { Button } from "reactstrap"
import styled from "@emotion/styled"

import { AppWrapper } from "@fitnesspilot/components-common/dist/atoms/Wrapper/Wrapper"
import { MainFooter } from "@fitnesspilot/components-common/dist/molecules/MainFooter/MainFooter"
import { Page } from "@fitnesspilot/components-common/dist/molecules/Page/Page"
import { ErrorContainer } from "@fitnesspilot/components-common/dist/organisms/Error/Error"
import { LoadingBarContainer } from "@fitnesspilot/components-common/dist/organisms/LoadingBar/LoadingBar"
import { LoadingTemplateHeaderContainer } from "@fitnesspilot/components-common/dist/organisms/LoadingTemplateHeader/LoadingTemplateHeader"

const StyledLoadingBarContainer = styled(LoadingBarContainer)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
`

const StyledPage = styled(Page)`
  display: flex;
  flex-direction: column;
  max-height: 100%;
`

const Contents = styled.main`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const InnerContents = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 875px;
  width: 100%;
  /* 10px = min page offset, 61px = min app bar height */
  min-height: calc(min(700px, 100vh - var(--template-header--height) - 10px));
`

const StyledErrorContainer = styled(ErrorContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media not (${({ theme }) => theme.breakpoints.sm}) {
    position: fixed;
    top: 0;
    left: 58px;
    right: 10px;
    z-index: 1;
    min-height: var(--template-header--height);
    padding-top: calc((var(--template-header--height) - 1px * 2 - 35.5px) / 2);
    padding-bottom: calc(
      (var(--template-header--height) - 1px * 2 - 35.5px) / 2
    );
  }
`

export type LoggedOutTemplateProps = {
  header?: ReactNode
  children?: ReactNode
  className?: string
}

export const LoggedOutTemplate: FC<LoggedOutTemplateProps> = ({
  header,
  children,
  className,
}) => (
  <AppWrapper {...{ className }}>
    <StyledLoadingBarContainer />

    <StyledPage>
      <LoadingTemplateHeaderContainer>
        {header}
        <StyledErrorContainer />
      </LoadingTemplateHeaderContainer>

      <Contents>
        <InnerContents>
          {children}
          <MainFooter>
            <div>
              <FormattedMessage
                defaultMessage="© {year} <strong>Fitnesspilot</strong>"
                values={{ year: new Date().getFullYear() }}
              />
            </div>

            <div>
              <Button tag={Link} to={"/legal/notice"} color="inline">
                <FaBalanceScale />{" "}
                <FormattedMessage defaultMessage="Legal Notice" />
              </Button>
              <Button tag={Link} to={"/legal/privacy"} color="inline">
                <FaShieldAlt />{" "}
                <FormattedMessage defaultMessage="Privacy Policy" />
              </Button>
            </div>
          </MainFooter>
        </InnerContents>
      </Contents>
    </StyledPage>
  </AppWrapper>
)

import { FC, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { FaArrowRight } from "react-icons/fa"
import { FormattedMessage, useIntl } from "react-intl"
import { Link } from "react-router-dom"
import { FormGroup } from "reactstrap"
import styled from "@emotion/styled"

import { pipe } from "fp-ts/es6/function"
import * as Func from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { Prism } from "monocle-ts"
import { stringAsEmailAddress } from "@fitnesspilot/data-common"

import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import {
  ButtonWithIcon,
  IconSide,
} from "@fitnesspilot/components-common/dist/molecules/ButtonWithIcon/ButtonWithIcon"
import {
  ControllerPrism,
  formGroupRender,
  textRender,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"
import { UserLogin } from "@fitnesspilot/data-user/dist/UserLogin"

import "firebase/compat/auth"
import firebase from "firebase/compat/app"

const ButtonRow = styled(FormGroup)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;

  .c-buttonWithIcon {
    font-size: 1em;
  }
`

export type FormData = UserLogin

const Error = styled.div`
  color: ${({ theme }) => theme.colours.danger.toString()};
`

type LoginErrorProps = {
  code: string
}

const LoginError = ({ code }: LoginErrorProps) => {
  switch (code) {
    case "auth/invalid-email":
      return <FormattedMessage defaultMessage="The email address is invalid." />
    case "auth/user-disabled":
      return <FormattedMessage defaultMessage="The account is disabled." />
    case "auth/user-not-found":
      return (
        <FormattedMessage defaultMessage="No account found for the given email address." />
      )
    case "auth/wrong-password":
      return <FormattedMessage defaultMessage="Incorrect password." />
    case "auth/too-many-requests":
      return (
        <FormattedMessage defaultMessage="Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later." />
      )
    default:
      return <FormattedMessage defaultMessage="Login failed." />
  }
}

const isFirebaseAuthError = (e: Error): e is firebase.FirebaseError =>
  "code" in e && ((e as any).code as string).startsWith("auth/")

export type LoginFormProps = {
  id: string
  errors: ReadonlyArray<Error>
  onSubmit: (v: FormData) => void
  className?: string
}

export const LoginForm: FC<LoginFormProps> = ({
  id,
  className,
  errors,
  onSubmit,
}) => {
  const intl = useIntl()
  const { handleSubmit, ...form } = useForm<FormData>({})

  const loginError = useMemo(
    () =>
      pipe(
        errors,
        Arr.filter(isFirebaseAuthError),
        Arr.last,
        Opt.map(({ code }) => (
          <Error key={code}>
            <LoginError {...{ code }} />
          </Error>
        )),
        Opt.toNullable,
      ),
    [errors],
  )

  return (
    <FormProvider {...{ handleSubmit }} {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Fieldset {...{ className }}>
          <FormGroup tag="header">
            <h1 className="h4">
              <FormattedMessage defaultMessage="Log in" />
            </h1>
          </FormGroup>

          <ControllerPrism<FormData, "email">
            render={formGroupRender(textRender)({
              id: `${id}-email`,
              autoComplete: "email",
              type: "email",
              required: true,
              label: <FormattedMessage defaultMessage="Email address" />,
              fullWidth: true,
            })}
            name="email"
            prism={stringAsEmailAddress}
            prismError={intl.formatMessage({
              defaultMessage: "is invalid",
            })}
            rules={{
              required: true,
            }}
          />

          <ControllerPrism<FormData, "password">
            render={formGroupRender(textRender)({
              id: `${id}-password`,
              autoComplete: "current-password",
              type: "password",
              required: true,
              label: <FormattedMessage defaultMessage="Password" />,
              fullWidth: true,
            })}
            name="password"
            prism={new Prism(Opt.some, Func.identity)}
            prismError={intl.formatMessage({
              defaultMessage: "is invalid",
            })}
            rules={{
              required: true,
              min: 8,
            }}
          />

          <ButtonRow>
            <Link to="/login/passwordReset">
              <FormattedMessage defaultMessage="Forgot password?" />
            </Link>

            <ButtonWithIcon
              icon={<FaArrowRight />}
              iconSide={IconSide.right}
              type="submit"
              color="success"
            >
              <FormattedMessage defaultMessage="Login" />
            </ButtonWithIcon>
          </ButtonRow>

          {loginError}
        </Fieldset>
      </form>
    </FormProvider>
  )
}

import { FC, ReactNode } from "react"
import { PropsOf } from "@emotion/react"
import styled from "@emotion/styled"

const ContentBox = styled.div`
  display: flow-root;
  border: 1px solid #e8e8e8;
  padding: 10px 30px 50px;
  border-top: 0;
  border-bottom: 0;

  & .row {
    position: relative;
  }

  @media (max-width: 767px) {
    padding: 10px 15px 50px;
  }
`

export type ContentProps = {
  children: ReactNode
} & PropsOf<typeof ContentBox>

// @TODO Should really be called PageContent
export const Content: FC<ContentProps> = ({ children, ...props }) => (
  <ContentBox {...props}>{children}</ContentBox>
)

import { PropsWithChildren } from "react"
import styled from "@emotion/styled"

import { ReadonlyNonEmptyArray } from "fp-ts/es6/ReadonlyNonEmptyArray"

import { Checkbox, CheckboxStatus } from "../../atoms/Checkbox/Checkbox"
import { Clickable } from "../../atoms/Clickable/Clickable"
import {
  Cookie,
  CookieCategory,
  CookieInfo_,
  Enum,
} from "../../molecules/CookieInfo/CookieInfo"

const CollapseButton = styled(Clickable)`
  margin-left: auto;
  border: none;
  background: none;
  color: ${({ theme }) => theme.button.colour.toString()};
`

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 100%;
`

const Content = styled.div`
  flex-basis: 100%;
`

const Section = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: start;

  > .c-cookieInfo {
    margin-bottom: 10px;
  }
`

export type CookieCategoryInfoProps<T extends Enum> = {
  className: string
  category: CookieCategory
  cookies: ReadonlyNonEmptyArray<Cookie<T>>
  checked: CheckboxStatus
  onChange: () => void
  expanded: boolean
  onToggle: () => void
}

const CookieCategoryInfo_ = <T extends Enum>({
  className,
  category,
  cookies,
  checked,
  onChange,
  children,
  expanded,
  onToggle,
}: PropsWithChildren<CookieCategoryInfoProps<T>>) => (
  <Section className={`c-cookieCategoryInfo ${className ?? ""}`}>
    <Header>
      <Checkbox
        status={checked}
        disabled={category === "necessary"}
        onChange={onChange}
      />
      <span>
        {category} ({cookies.length})
      </span>
      <CollapseButton onClick={onToggle}>{expanded ? "▲" : "▼"}</CollapseButton>
    </Header>
    <Content>{expanded && children}</Content>
  </Section>
)

export const CookieCategoryInfo = styled(CookieCategoryInfo_)``

import { HTMLAttributes } from "react"
import styled from "@emotion/styled"

export type ClickableProps = {
  onClick: () => void
} & Omit<HTMLAttributes<HTMLButtonElement>, "onClick">

export const Clickable_ = ({ onClick, children, ...props }: ClickableProps) => (
  <button onClick={onClick} {...props}>
    {children}
  </button>
)

export const Clickable = styled(Clickable_)`
  cursor: pointer;
  outline: none;
`

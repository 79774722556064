import { FC } from "react"
import { FormattedMessage } from "react-intl"

import {
  CombinedFitnessLevel,
  FitnessLevel as FitnessLevel_,
} from "@fitnesspilot/data-human-body/dist/fitnessLevel"

export type FitnessLevelProps = {
  fitnessLevel: CombinedFitnessLevel
}

export const FitnessLevel: FC<FitnessLevelProps> = ({
  fitnessLevel: { bodyFat, musculature },
}) =>
  ({
    [FitnessLevel_.high]: {
      [FitnessLevel_.high]: <FormattedMessage defaultMessage="Overweight" />,
      [FitnessLevel_.medium]: <FormattedMessage defaultMessage="Corpulent" />,
      [FitnessLevel_.low]: <FormattedMessage defaultMessage="Obese" />,
    },
    [FitnessLevel_.medium]: {
      [FitnessLevel_.high]: <FormattedMessage defaultMessage="Strong" />,
      [FitnessLevel_.medium]: <FormattedMessage defaultMessage="Average" />,
      [FitnessLevel_.low]: <FormattedMessage defaultMessage="Weak" />,
    },
    [FitnessLevel_.low]: {
      [FitnessLevel_.high]: <FormattedMessage defaultMessage="Athletic" />,
      [FitnessLevel_.medium]: <FormattedMessage defaultMessage="Sportive" />,
      [FitnessLevel_.low]: <FormattedMessage defaultMessage="Skinny" />,
    },
  })[bodyFat][musculature]

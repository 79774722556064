import { FC } from "react"
import { FaUser } from "react-icons/fa"
/* import styled from "@emotion/styled" */
import { FormattedMessage } from "react-intl"
import { connect, ConnectedProps } from "react-redux"

import { constVoid, flow } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"

import { Content } from "@fitnesspilot/components-common/dist/molecules/Content/Content"
import { FileButton } from "@fitnesspilot/components-common/dist/molecules/FileButton/FileButton"
import { Header } from "@fitnesspilot/components-common/dist/molecules/Header/Header"
import { PhotoGallery } from "@fitnesspilot/components-user/dist/organisms/PhotoGallery/PhotoGallery"
import {
  Action,
  changePhoto,
  closePhoto,
  editPhoto,
  openPhoto,
  ParentState,
  savePhoto,
  selectors,
  setProfilePhoto,
  sharePhoto,
  uploadPhotos,
} from "@fitnesspilot/data-user"

import { MainTemplate } from "../../templates/Main/Main"

import { Dispatch } from "redux"

const mapState = (state: ParentState) => ({
  editingPhoto: selectors.state.compose(selectors.editingPhoto).get(state),
  photos: selectors.state.compose(selectors.photos).get(state),
  openPhoto: selectors.state.compose(selectors.openPhoto).get(state),
  profilePhoto: selectors.state.compose(selectors.profilePhoto).get(state),
})

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ = flow(dispatch, constVoid)

  return {
    onClose: flow(closePhoto, dispatch_),
    onStartEdit: flow(editPhoto, dispatch_),
    onChange: flow(changePhoto, dispatch_),
    onOpen: flow(openPhoto, dispatch_),
    onSave: flow(savePhoto, dispatch_),
    onSetProfilePhoto: flow(setProfilePhoto, dispatch_),
    onShare: flow(sharePhoto, dispatch_),
    onFilesChange: flow(uploadPhotos, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type OwnProps = Record<string, never>

export type PhotoGalleryPageProps = PropsFromRedux & OwnProps

export const PhotoGalleryPage: FC<PhotoGalleryPageProps> = ({
  onFilesChange,
  onShare,
  ...props
}) => (
  <MainTemplate
    header={
      <Header
        icon={<FaUser />}
        title={<FormattedMessage defaultMessage="Photo Timeline" />}
      >
        <FileButton
          color="primary"
          onChange={onFilesChange}
          accept={["image/*"]}
        >
          <FormattedMessage defaultMessage="Add photo" />
        </FileButton>
      </Header>
    }
  >
    <Content>
      <PhotoGallery onShare={Opt.some(onShare)} {...props} />
    </Content>
  </MainTemplate>
)

export const PhotoGalleryPageContainer = connector(PhotoGalleryPage)

import { FC } from "react"
import { FormattedMessage } from "react-intl"

import { BodyCompositionParts } from "@fitnesspilot/data-human-body/dist/bodyComposition"

export type BodyCompositionPartLabelProps = {
  bodyCompositionPart: BodyCompositionParts
}

export const BodyCompositionPartLabel: FC<BodyCompositionPartLabelProps> = ({
  bodyCompositionPart,
}) =>
  ({
    [BodyCompositionParts.adipose]: <FormattedMessage defaultMessage="Fat" />,
    [BodyCompositionParts.muscle]: (
      <FormattedMessage defaultMessage="Fat-free mass" />
    ),
    [BodyCompositionParts.water]: (
      <FormattedMessage defaultMessage="Body water" />
    ),
  })[bodyCompositionPart]

import { forwardRef, MutableRefObject, ReactNode } from "react"
import styled from "@emotion/styled"

import { IconCoach, IconCoachProps } from "../IconCoach/IconCoach"

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Message = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colours.primary.toString()};
  color: ${({ theme }) => theme.colours.white.toString()};
  padding: 15px;
  width: 90%;
  margin-right: 20px;
  border-radius: 3px;

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${({ theme }) => theme.colours.white.toString()};
  }

  &::after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-left-color: ${({ theme }) => theme.colours.primary.toString()};
    border-width: 10px;
    margin-top: -10px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`

const MessageOverflow = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`

const Icon = styled(IconCoach)`
  font-size: 4em;
  color: #86a3bf;
`

export type CoachMessageProps = {
  children: ReactNode
  className?: string
}

const InternalCoachMessage = (
  { children, className }: CoachMessageProps,
  ref:
    | ((instance: HTMLDivElement | null) => void)
    | MutableRefObject<HTMLDivElement | null>
    | null,
) => (
  <Container ref={ref} {...{ className }}>
    <Message>
      <MessageOverflow>{children}</MessageOverflow>
    </Message>

    <Icon />
  </Container>
)

export const CoachMessage = forwardRef(InternalCoachMessage)

import { FieldPathValue, FieldValues, Path } from "react-hook-form"
import { connect, ConnectedProps } from "react-redux"

import { pipe } from "fp-ts/es6/function"
import {
  Dimension,
  OmitFromKnownKeys,
  Unit,
  unitFromSelectedUnitAndDimension,
  UnitNoDim,
} from "@fitnesspilot/data-common"

import {
  ControllerDimensionalNumber as ControllerDimensionalNumber_,
  ControllerDimensionalNumberProps as ControllerDimensionalNumberProps_,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"
import { ParentState, selectors } from "@fitnesspilot/data-user"

const mapState = (state: ParentState) => ({
  unitSelection: pipe(
    state,
    selectors.state.composeLens(selectors.unitSelection).get,
  ),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type ControllerDimensionalNumberContainerProps<
  formData extends FieldValues,
  name extends Path<formData>,
  d extends Dimension,
  renderTransformProps extends Record<any, any> = Record<string, never>,
> = OmitFromKnownKeys<
  ControllerDimensionalNumberProps_<formData, name, d, renderTransformProps>,
  "unit"
>

type ControllerDimensionalNumberProps<
  formData extends FieldValues,
  name extends Path<formData>,
  d extends Dimension,
  renderTransformProps extends Record<any, any> = Record<string, never>,
> = PropsFromRedux &
  ControllerDimensionalNumberContainerProps<
    formData,
    name,
    d,
    renderTransformProps
  >

export const ControllerDimensionalNumber = <
  formData extends FieldValues,
  name extends Path<formData>,
  d extends Dimension,
  renderTransformProps extends Record<any, any> = Record<string, never>,
>({
  unitSelection,
  dimension,
  ...props
}: ControllerDimensionalNumberProps<
  formData,
  name,
  d,
  renderTransformProps
>) => (
  <ControllerDimensionalNumber_<formData, name, d, renderTransformProps>
    unit={unitFromSelectedUnitAndDimension(dimension)(unitSelection)}
    dimension={dimension}
    {...props}
  />
)

// The typing isn't _entirely_ correct, seeing that we're losing the `ConnectedComponent` stuff (and dunno whether it even is an FC)
export const ControllerDimensionalNumberContainer = connector(
  ControllerDimensionalNumber,
) as any as <
  formData extends FieldValues,
  name extends Path<formData>,
  d extends Dimension,
  renderTransformProps extends Record<any, any> = Record<string, never>,
>(
  props: ControllerDimensionalNumberContainerProps<
    formData,
    name,
    d,
    renderTransformProps
  >,
) => JSX.Element

import { Container, ContainerProps } from "reactstrap"
import styled from "@emotion/styled"

export type CardProps = ContainerProps

export const Card = styled(Container)`
  border: 1px solid hsla(0, 0%, 46.7%, 0.25);
  border-radius: 3px;
  padding: 15px;
  background-color: ${({ theme }) => theme.colours.white.toString()};
`

import * as Foldable from "fp-ts/es6/Foldable"
import * as IO from "fp-ts/es6/IO"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Str from "fp-ts/es6/string"

import { sendGoogleAuthCode } from "@fitnesspilot/data-user"
import { GoogleApi } from "@fitnesspilot/data-user/dist/GoogleApi"

import * as config from "./config"
import { store } from "./reduxStore"

const redirectUri = `${location.origin}/aboutMe`

export const requestPermissions =
  (scopes: GoogleApi["scopes"]): IO.IO<void> =>
  () => {
    const baseUrl = "https://accounts.google.com/o/oauth2/v2/auth"
    const clientId = config.googleApi.clientId
    const responseType = "code"
    const scope = Foldable.intercalate(Str.Monoid, Arr.Foldable)(" ", scopes)
    const accessType = "offline"
    const url = `${baseUrl}?client_id=${clientId}&response_type=${responseType}&scope=${scope}&redirect_uri=${redirectUri}&access_type=${accessType}&include_granted_scopes=true`

    window.open(url, "google-oauth", "width=500,height=500")
  }

type GoogleApiEvent = MessageEvent<{ code: string; scopes: Array<string> }>

const isGoogleApiEvent = (e: MessageEvent<any>): e is GoogleApiEvent =>
  e.data.code && e.data.scopes

// handle Google OAuth redirect
if (location.search) {
  const params = new URLSearchParams(location.search)
  const code = params.get("code")
  const scopes = params.get("scope")?.split(" ")

  if (scopes) {
    window.opener?.postMessage({ code, scopes }, location.origin)
    window.close()
  }
}

// handle Google OAuth response
window.addEventListener(
  "message",
  (event) => {
    if (event.origin === location.origin && isGoogleApiEvent(event)) {
      console.log("GOOGLE API EVENT", event)
      store.dispatch(
        sendGoogleAuthCode({
          code: event.data.code,
          scopes: event.data.scopes,
          redirectUri: redirectUri,
        }),
      )
    }
  },
  false,
)

import { FC, useMemo } from "react"
import { FormattedMessage } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import { constVoid, flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { arrTrav, prismToGetter } from "@fitnesspilot/data-common"

import { CancelButton } from "@fitnesspilot/components-common/dist/molecules/CancelButton/CancelButton"
import { Modal } from "@fitnesspilot/components-common/dist/organisms/Modal/Modal"
import { EventConfirmationContainer } from "@fitnesspilot/components-event/dist/organisms/EventConfirmation/EventConfirmation"
import { stringAsCoachTaskId } from "@fitnesspilot/data-coach-task/dist/CoachTask"
import * as CoachTask from "@fitnesspilot/data-coach-task/dist/CoachTask"
import * as CoachTaskData from "@fitnesspilot/data-coach-task/dist/store"
import * as EventData from "@fitnesspilot/data-event/dist/store"

import { Dispatch } from "redux"

const _calendarCoachTasks = arrTrav<CoachTask.CoachTask>()
  .filter(flow(CoachTask._dismissedOn.get, Opt.isNone))
  .filter(flow(CoachTask._completedOn.get, Opt.isNone))
  .composePrism(CoachTask._CalendarCoachTask)

const mapState = (state: CoachTaskData.ParentState) => {
  return {
    isOpen: pipe(
      state,
      CoachTaskData.selectors.state.compose(
        CoachTaskData.selectors.eventConfirmationIsOpen,
      ).get,
    ),
    coachTasks: pipe(
      state,
      CoachTaskData.selectors.state.composeLens(
        CoachTaskData.selectors.coachTasks,
      ).get,
    ),
  }
}

const mapDispatch = (
  dispatch: Dispatch<CoachTaskData.Action | EventData.Action>,
) => {
  const dispatch_ =
    (act: CoachTaskData.Action | EventData.Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, constVoid)

  return {
    onSetIsOpen: flow(CoachTaskData.setEventConfirmationIsOpen, dispatch_),
    onCancel: flow(CoachTaskData.cancelEventConfirmation, dispatch_),
    onDismiss: flow(CoachTaskData.dismissCoachTask, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

export type EventConfirmationModalProps = PropsFromRedux & {
  id: string
}

export const EventConfirmationModal: FC<EventConfirmationModalProps> = ({
  id,
  isOpen,
  coachTasks,
  onDismiss,
  onSetIsOpen,
  onCancel,
}) => {
  const calendarCoachTasks = useMemo(
    () => _calendarCoachTasks.asFold().getAll(coachTasks),
    [coachTasks],
  )

  return (
    <Modal toggle={onSetIsOpen(false)} {...{ isOpen }}>
      <ModalHeader tag="h1" toggle={onSetIsOpen(false)}>
        <FormattedMessage defaultMessage="Confirm events" />
      </ModalHeader>

      <ModalBody>
        {pipe(
          calendarCoachTasks,
          Arr.map((coachTask) => (
            <EventConfirmationContainer
              key={pipe(
                coachTask,
                CoachTask._id.composeGetter(prismToGetter(stringAsCoachTaskId))
                  .get,
              )}
              eventId={coachTask.eventId}
            >
              <CancelButton type="button" onClick={onDismiss(coachTask)}>
                <FormattedMessage defaultMessage="Dismiss" />
              </CancelButton>
            </EventConfirmationContainer>
          )),
        )}
      </ModalBody>

      <ModalFooter>
        <CancelButton
          type="button"
          onClick={pipe(
            onCancel(),
            IO.chain(() => onSetIsOpen(false)),
          )}
        />
      </ModalFooter>
    </Modal>
  )
}

export const EventConfirmationModalContainer = connector(EventConfirmationModal)

import { FC, useMemo } from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import * as Bool from "fp-ts/es6/boolean"
import { constFalse, constUndefined, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { Getter } from "monocle-ts"
import { dateAsTime } from "time-ts/es6"
import { prismToGetter } from "@fitnesspilot/data-common"

import { Colour } from "@fitnesspilot/components-common/dist/atoms/ThemeProvider/Theme"
import {
  Calendar as Calendar_,
  CalendarProps as CalendarProps_,
} from "@fitnesspilot/components-common/dist/organisms/Calendar/Calendar"
import * as Event from "@fitnesspilot/data-event/dist/calendar/Event"
import * as EventOrRecommendation from "@fitnesspilot/data-event/dist/calendar/EventOrRecommendation"

import { EventInput } from "@fullcalendar/common"

const type = EventOrRecommendation._EventWithId
  .composeLens(Event._eventWithId._value)
  .composeLens(Event._type)

const styledEvent = (c: Colour) => css`
  border-top: 5px solid ${c.toString()};

  &::before {
    top: -5px;
  }
`

const CalendarStyles = styled.div`
  .fc-direction-ltr .fc-timegrid-col-events {
    margin-left: 0;
    margin-right: 0;
  }
  .fc-daygrid-event-dot {
    visibility: hidden;
  }
  .fc-event {
    background: ${({ theme }) => theme.colours.white.toString()};
    border: 1px solid ${({ theme }) => theme.colours.grey[200].toString()};
    margin-left: 5px;
    margin-right: 5px;
    font-weight: ${({ theme }) => theme.font.weights.bold};

    & .fc-event-main {
      color: ${({ theme }) => theme.colours.grey[900].toString()};
      padding: 0 4px;
    }

    & .fc-event-time {
      color: ${({ theme }) => theme.colours.grey[500].toString()};
    }

    & .fc-event-title {
      font-style: normal;
    }

    &::before {
      position: absolute;
      top: -1px;
      right: 10px;
      transform: translateY(-50%);
      color: ${({ theme }) => theme.colours.grey[300].toString()};
      font-size: 1.5em;
      background: white;
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: "dj-icons" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
    }

    &.fc-daygrid-event::before {
      display: none;
    }
  }
  .evt-activity {
    ${({ theme }) => styledEvent(theme.colours.blue[500])}

    &::before {
      content: "\u{e634}";
      color: #0d6a98;
    }
  }
  .fc-bg-event {
    margin: 0;
    border: 0;
    border-left: 5px solid ${({ theme }) => theme.colours.grey[500].toString()};
    border-radius: 0;

    & .fc-event-main {
      color: ${({ theme }) => theme.colours.grey[500].toString()};
    }

    &.fc-daygrid-event {
      border-left: 0;
    }
  }
  .evt-rec {
    & .fc-event-time {
      display: none;
    }

    & .fc-event-main {
      color: ${({ theme }) => theme.colours.grey[500].toString()};
      padding-left: 10px;
    }

    & .fc-event-title {
      &::before {
        content: "+";
        display: inline-block;
        margin-right: 0.5em;
        font-size: 1.5em;
        line-height: 1;
        transform: translateY(12.5%);
        color: ${({ theme }) => theme.colours.grey[300].toString()};
      }
    }

    &::before {
      content: "\u{e634}";
    }
  }
`

export type CalendarProps = {
  events: ReadonlyArray<EventOrRecommendation.EventOrRecommendation>
} & Omit<CalendarProps_, "events">

export const Calendar: FC<CalendarProps> = ({ events, ...props }) => {
  const cal = useMemo(
    () => (
      <Calendar_
        events={pipe(
          events,
          Arr.map((evt): EventInput => {
            const isBackgroundEvent = pipe(
              evt,
              type.getOption,
              Opt.fold(constFalse, (type) =>
                pipe(
                  [Event.EventType.sleep, Event.EventType.work],
                  Arr.elem(Event.eventType)(type),
                ),
              ),
            )

            return {
              editable: pipe(evt, EventOrRecommendation.isEventWithId),
              title: pipe(
                evt,
                EventOrRecommendation.foldEventOrRecommendation(
                  Event._eventWithId._value.composeLens(Event._title).get,
                  () => "Activity",
                ),
                (t) =>
                  `${t}${pipe(
                    evt,
                    (a) =>
                      EventOrRecommendation._EventWithId
                        .composeLens(Event._eventWithId._value)
                        .composeLens(Event._confirmed)
                        .composeGetter(
                          new Getter(
                            Opt.fold(
                              () => "",
                              () => " ✓",
                            ),
                          ),
                        )
                        .headOption(a),
                    Opt.getOrElse(() => ""),
                  )}`,
              ),
              start: pipe(
                evt,
                EventOrRecommendation._start.composeGetter(
                  prismToGetter(dateAsTime),
                ).get,
              ),
              end: pipe(
                evt,
                EventOrRecommendation._end.composeGetter(
                  prismToGetter(dateAsTime),
                ).get,
              ),
              display: pipe(
                isBackgroundEvent,
                Bool.fold(constUndefined, () => "background"),
              ),
              extendedProps: evt,
              classNames: pipe(
                evt,
                type.getOption,
                Opt.fold(
                  () => `evt-rec`,
                  (type) => `evt-${type}`,
                ),
              ),
            }
          }),
          Arr.toArray,
        )}
        {...props}
      />
    ),
    [events],
  )

  return <CalendarStyles>{cal}</CalendarStyles>
}

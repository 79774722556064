import { ReactNode } from "react"
import { FaCheck, FaHeart, FaRunning, FaWeight } from "react-icons/fa"
import { useIntl } from "react-intl"
import styled from "@emotion/styled"

import { constFalse, constNull, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as St from "fp-ts/es6/ReadonlySet"

import {
  ActivityTagName,
  activityTagName,
} from "@fitnesspilot/components-activity/dist/atoms/ActivityTagName/ActivityTagName"
import {
  EquipmentName,
  equipmentName,
} from "@fitnesspilot/components-activity/dist/atoms/EquipmentName/EquipmentName"
import { Clickable } from "@fitnesspilot/components-common/dist/atoms/Clickable/Clickable"
import {
  StringInput,
  StringInputProps,
} from "@fitnesspilot/components-common/dist/atoms/input/StringInput"
import {
  MuscleGroupName,
  muscleGroupName,
} from "@fitnesspilot/components-human-body/dist/atoms/MuscleGroupName/MuscleGroupName"
import {
  ActivityTag,
  activityTag,
} from "@fitnesspilot/data-activity/dist/activity/ActivityTag"
import {
  EquipmentType,
  equipmentType,
} from "@fitnesspilot/data-activity/dist/activity/Equipment"
import { ActivityFilters } from "@fitnesspilot/data-activity/dist/activityFilters/ActivityFilters"
import {
  ActivityInstanceFilters,
  foldInstanceFilters,
} from "@fitnesspilot/data-activity/dist/activityFilters/ActivityInstanceFilters"
import {
  alignment,
  numberAsAlignmentUnsafe,
} from "@fitnesspilot/data-human-body/dist/Alignment"
import {
  MuscleGroupId,
  muscleGroupId,
} from "@fitnesspilot/data-human-body/dist/muscleGroups"

const matches = (a: string, b: string) =>
  a.toLowerCase().includes(b.toLowerCase())

type SuggestionProps = {
  className?: string
  children: ReactNode
  onSelect: IO.IO<void>
}

const Suggestion = styled(
  ({ children, onSelect, className }: SuggestionProps) => (
    <Clickable
      className={`c-suggestion ${className ?? ""}`}
      {...{ children }}
      onClick={onSelect}
    />
  ),
)`
  box-sizing: border-box;
  width: 100%;
  & > * {
    margin: 0 8px;
  }
`

const Suggestions = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: ${({ theme }) => theme.colours.white.toString()};
  border-radius: 4px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);

  & .c-suggestion {
    padding: 4px;
  }
`

export type ActivitySearchInputProps<a extends ActivityFilters> =
  StringInputProps & {
    id: string
    filters: a
    onSelectFilter: <b extends keyof a>(filter: b, value: a[b]) => IO.IO<void>
  }

export const ActivitySearchInput_ = <a extends ActivityFilters>({
  className,
  id,
  value,
  filters,
  onSelectFilter,
  ...props
}: ActivitySearchInputProps<a>) => {
  const intl = useIntl()
  const alignmentText = intl.formatMessage({
    defaultMessage: "alignment",
  })
  const likedText = intl.formatMessage({
    defaultMessage: "liked",
  })
  return (
    <div {...{ className }}>
      <StringInput {...{ value, ...props }} type="search" />
      <Suggestions>
        {pipe(
          Object.values(EquipmentType),
          Arr.filter((eq) => !St.elem(equipmentType)(eq, filters.equipments)),
          Arr.filter((eq) =>
            pipe(
              value,
              Opt.map((v) => matches(equipmentName(intl)({ id: eq }), v)),
              Opt.getOrElse<boolean>(constFalse),
            ),
          ),
          Arr.map((eq) => (
            <Suggestion
              key={eq}
              onSelect={onSelectFilter(
                "equipments",
                St.fromReadonlyArray(equipmentType)([eq]),
              )}
            >
              <FaWeight />
              <EquipmentName id={eq} />
            </Suggestion>
          )),
        )}
        {pipe(
          Object.values(ActivityTag),
          Arr.filter((t) => !St.elem(activityTag)(t, filters.tags)),
          Arr.filter((t) =>
            pipe(
              value,
              Opt.map((v) => matches(activityTagName(intl)({ id: t }), v)),
              Opt.getOrElse<boolean>(constFalse),
            ),
          ),
          Arr.map((tag) => (
            <Suggestion
              key={tag}
              onSelect={onSelectFilter(
                "tags",
                St.fromReadonlyArray(activityTag)([tag]),
              )}
            >
              <FaRunning />
              <ActivityTagName id={tag} />
            </Suggestion>
          )),
        )}
        {pipe(
          Object.values(MuscleGroupId),
          Arr.filter((mg) => !St.elem(muscleGroupId)(mg, filters.muscleGroups)),
          Arr.filter((mg) =>
            pipe(
              value,
              Opt.map((v) => matches(muscleGroupName(intl)({ id: mg }), v)),
              Opt.getOrElse<boolean>(constFalse),
            ),
          ),
          Arr.map((muscleGroup) => (
            <Suggestion
              key={muscleGroup}
              onSelect={onSelectFilter(
                "muscleGroups",
                St.fromReadonlyArray(muscleGroupId)([muscleGroup]),
              )}
            >
              💪
              <MuscleGroupName id={muscleGroup} />
            </Suggestion>
          )),
        )}
        {pipe(
          filters,
          foldInstanceFilters(
            constNull,
            (filters) =>
              pipe(
                value,
                Opt.map((v) => alignmentText.includes(v)),
                Opt.getOrElse(constFalse),
              ) &&
              Ord.leq(alignment)(
                filters.minAlignment,
                numberAsAlignmentUnsafe(0),
              ) && (
                <Suggestion
                  onSelect={onSelectFilter<any>(
                    "minAlignment",
                    numberAsAlignmentUnsafe(60),
                  )}
                >
                  <FaCheck />
                  {alignmentText} 60+
                </Suggestion>
              ),
          ),
        )}
        {pipe(
          value,
          Opt.map((v) => likedText.includes(v)),
          Opt.getOrElse(constFalse),
        ) &&
          !filters.onlyLiked && (
            <Suggestion onSelect={onSelectFilter("onlyLiked", true)}>
              <FaHeart />
              {likedText}
            </Suggestion>
          )}
      </Suggestions>
    </div>
  )
}

export const activitySearchInput = <a extends ActivityFilters>() => styled(
  (props: ActivitySearchInputProps<a>) => (
    <ActivitySearchInput_<a> {...props} />
  ),
)`
  position: relative;
`

export const ActivitySearchInput = activitySearchInput<ActivityFilters>()

export const ActivityInstanceSearchInput =
  activitySearchInput<ActivityInstanceFilters>()

import * as Eq from "fp-ts/es6/Eq"
import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Str from "fp-ts/es6/string"
import { Getter, Iso, Lens } from "monocle-ts"
import { Negative } from "newtype-ts/es6/Negative"
import { NonNegative } from "newtype-ts/es6/NonNegative"
import { NonPositive } from "newtype-ts/es6/NonPositive"
import { NonZero } from "newtype-ts/es6/NonZero"
import { Positive } from "newtype-ts/es6/Positive"
import { Duration, mkDuration } from "time-ts/es6"

import { numberAsValidNumber, ValidNumber } from "./number"

export enum Dimension {
  noDim = "noDim",
  mass = "mass",
  lengthHeight = "lengthHeight",
  lengthDistance = "lengthDistance",
  speed = "speed",
}

export enum UnitNoDim {
  one = "one",
  percent = "percent",
}

export enum UnitMass {
  kilogram = "kilogram",
  pound = "pound",
}

export enum UnitLengthHeight {
  centimetre = "centimetre",
  footAndInch = "footAndInch",
}

export enum UnitLengthDistance {
  kilometre = "kilometre",
  mile = "mile",
}

export enum UnitSpeed {
  kmh = "kmh",
  mih = "mih",
}

export type Unit<d extends Dimension> = d extends Dimension.noDim
  ? UnitNoDim
  : d extends Dimension.mass
  ? UnitMass
  : d extends Dimension.lengthHeight
  ? UnitLengthHeight
  : d extends Dimension.lengthDistance
  ? UnitLengthDistance
  : d extends Dimension.speed
  ? UnitSpeed
  : never
export const unitsOfDimensions: { [d in Dimension]: { [u in Unit<d>]: u } } = {
  [Dimension.noDim]: UnitNoDim,
  [Dimension.mass]: UnitMass,
  [Dimension.lengthHeight]: UnitLengthHeight,
  [Dimension.lengthDistance]: UnitLengthDistance,
  [Dimension.speed]: UnitSpeed,
}

export type CanonicalUnit<d extends Dimension> = d extends Dimension.noDim
  ? UnitNoDim.one
  : d extends Dimension.mass
  ? UnitMass.kilogram
  : d extends Dimension.lengthHeight
  ? UnitLengthHeight.centimetre
  : d extends Dimension.lengthDistance
  ? UnitLengthDistance.kilometre
  : d extends Dimension.speed
  ? UnitSpeed.kmh
  : never
export const canonicalUnitsOfDimensions: {
  [d in Dimension]: CanonicalUnit<d>
} = {
  [Dimension.noDim]: UnitNoDim.one,
  [Dimension.mass]: UnitMass.kilogram,
  [Dimension.lengthHeight]: UnitLengthHeight.centimetre,
  [Dimension.lengthDistance]: UnitLengthDistance.kilometre,
  [Dimension.speed]: UnitSpeed.kmh,
}
export const canonicalUnitOfDimension = <d extends Dimension>(dimension: d) =>
  canonicalUnitsOfDimensions[dimension] as CanonicalUnit<d>

export type ValueWithUnit<
  d extends Dimension,
  u extends Unit<d>,
  v = ValidNumber,
> = {
  dimension: d
  unit: u
  value: v
}
export const valueWithUnit = <
  d extends Dimension,
  u extends Unit<d>,
  v = ValidNumber,
>(
  _: d,
  __: u,
  ordValue: Ord.Ord<v>,
): Ord.Ord<ValueWithUnit<d, u, v>> => ({
  ...Eq.struct({
    dimension: Str.Eq,
    unit: Str.Eq,
    value: ordValue,
  }),
  compare: (a, b) => ordValue.compare(a.value, b.value),
})
export type ValueWithCanonicalUnit<
  d extends Dimension,
  v = ValidNumber,
> = ValueWithUnit<d, CanonicalUnit<d>, v>
export const valueWithCanonicalUnit = <d extends Dimension, v = ValidNumber>(
  dimension: d,
  ordValue: Ord.Ord<v>,
) => valueWithUnit(dimension, canonicalUnitOfDimension(dimension), ordValue)
export const valueInUnit = <
  d extends Dimension,
  u extends Unit<d>,
  v = ValidNumber,
>(
  dimension: d,
  unit: u,
) =>
  new Iso<v, ValueWithUnit<d, u, v>>(
    (value) => ({ dimension, unit, value }),
    ({ value }) => value,
  )
export const valueInCanonicalUnit = <d extends Dimension, v = ValidNumber>(
  dimension: d,
) =>
  valueInUnit<d, CanonicalUnit<d>, v>(
    dimension,
    canonicalUnitOfDimension(dimension),
  )
export const valueInAnyUnit = <d extends Dimension, v = ValidNumber>(_: d) =>
  new Getter<ValueWithUnit<d, Unit<d>, v>, v>((s) => s.value)
export const dimension = <
  d extends Dimension,
  u extends Unit<d>,
  v = ValidNumber,
>(
  _: d,
  __: u,
) => Lens.fromProp<ValueWithUnit<d, u, v>>()("dimension").asGetter()
export const unit = <d extends Dimension, u extends Unit<d>, v = ValidNumber>(
  _: d,
  __: u,
) => Lens.fromProp<ValueWithUnit<d, u, v>>()("unit").asGetter()
export const value = <d extends Dimension, u extends Unit<d>, v = ValidNumber>(
  _: d,
  __: u,
) => Lens.fromProp<ValueWithUnit<d, u, v>>()("value")

const lengthDistanceFactors: Record<Unit<Dimension.lengthDistance>, number> = {
  [UnitLengthDistance.kilometre]: 1,
  [UnitLengthDistance.mile]: 1.609344,
}
const unitFactors: { [d in Dimension]: Record<Unit<d>, number> } = {
  [Dimension.noDim]: {
    [UnitNoDim.one]: 1,
    [UnitNoDim.percent]: 0.01,
  },
  [Dimension.mass]: {
    [UnitMass.kilogram]: 1,
    [UnitMass.pound]: 0.453592,
  },
  [Dimension.lengthHeight]: {
    [UnitLengthHeight.centimetre]: 1,
    [UnitLengthHeight.footAndInch]: 2.54,
  },
  [Dimension.lengthDistance]: lengthDistanceFactors,
  [Dimension.speed]: {
    [UnitSpeed.kmh]: lengthDistanceFactors[UnitLengthDistance.kilometre],
    [UnitSpeed.mih]: lengthDistanceFactors[UnitLengthDistance.mile],
  },
}

// The likes of Positive and Negative only work because all unit factors
// are positive and because there's currently no offset (which will be
// required for temperature units)
export type ReunitableTypes =
  | number
  | ValidNumber
  | Positive
  | NonPositive
  | Negative
  | NonNegative
  | NonZero

export const reunit = <
  d extends Dimension,
  su extends Unit<d>,
  du extends Unit<d>,
  v extends ReunitableTypes = ValidNumber,
>(
  dimension: d,
  srcUnit: su,
  dstUnit: du,
) =>
  new Iso<ValueWithUnit<d, su, v>, ValueWithUnit<d, du, v>>(
    ({ value }) => ({
      dimension,
      unit: dstUnit,
      value: (((value as number) /
        (unitFactors[dimension] as Record<su | du, number>)[dstUnit]) *
        (unitFactors[dimension] as Record<su | du, number>)[srcUnit]) as v,
    }),
    ({ value }) => ({
      dimension,
      unit: srcUnit,
      value: (((value as number) *
        (unitFactors[dimension] as Record<su | du, number>)[dstUnit]) /
        (unitFactors[dimension] as Record<su | du, number>)[srcUnit]) as v,
    }),
  )
export const canonicaliseDimValue = <
  d extends Dimension,
  u extends Unit<d>,
  v extends ReunitableTypes = ValidNumber,
>(
  dimension: d,
  unit: u,
) =>
  reunit<d, u, CanonicalUnit<d>, v>(
    dimension,
    unit,
    canonicalUnitOfDimension(dimension),
  )
export const canonicaliseAnyDimValue = <
  d extends Dimension,
  v extends ReunitableTypes = ValidNumber,
>(
  dimension: d,
) =>
  new Getter<ValueWithUnit<d, Unit<d>, v>, ValueWithCanonicalUnit<d, v>>(
    ({ unit, value }) => ({
      dimension,
      unit: canonicalUnitOfDimension(dimension),
      value: ((value as number) /
        (unitFactors[dimension] as Record<Unit<d>, number>)[unit]) as v,
    }),
  )
export const valueWithAnyUnit = <
  d extends Dimension,
  v extends ReunitableTypes = ValidNumber,
>(
  dimension: d,
  ordValue: Ord.Ord<v>,
): Ord.Ord<ValueWithUnit<d, Unit<d>, v>> => ({
  ...Eq.struct({
    dimension: Str.Eq,
    unit: Str.Eq,
    value: ordValue,
  }),
  compare: (a, b) =>
    ordValue.compare(
      canonicaliseAnyDimValue<d, v>(dimension).get(a).value,
      canonicaliseAnyDimValue<d, v>(dimension).get(b).value,
    ),
})

export type UnitSelection = {
  [Dimension.mass]: Unit<Dimension.mass>
  [Dimension.lengthHeight]: Unit<Dimension.lengthHeight>
  [Dimension.lengthDistance]: Unit<Dimension.lengthDistance>
}
export const unitSelection = Eq.struct<UnitSelection>({
  [Dimension.mass]: Str.Eq,
  [Dimension.lengthHeight]: Str.Eq,
  [Dimension.lengthDistance]: Str.Eq,
})
export const mass = Lens.fromProp<UnitSelection>()(Dimension.mass)
export const lengthHeight = Lens.fromProp<UnitSelection>()(
  Dimension.lengthHeight,
)
export const lengthDistance = Lens.fromProp<UnitSelection>()(
  Dimension.lengthDistance,
)

export const unitFromSelectedUnitAndDimension =
  <d extends Dimension>(dimension: d) =>
  (selection: UnitSelection): Unit<d> =>
    ({
      ...(selection as Record<Dimension, Unit<d>>),
      [Dimension.noDim]: UnitNoDim.one as Unit<d>,
      [Dimension.speed]: (selection[Dimension.lengthDistance] ===
      UnitLengthDistance.kilometre
        ? UnitSpeed.kmh
        : UnitSpeed.mih) as Unit<d>,
    })[dimension]

export const unitSymbols: {
  [d in Dimension]: Omit<Record<Unit<d>, string>, UnitLengthHeight.footAndInch>
} = {
  [Dimension.noDim]: {
    [UnitNoDim.one]: "",
    [UnitNoDim.percent]: "%",
  },
  [Dimension.mass]: {
    [UnitMass.kilogram]: "kg",
    [UnitMass.pound]: "lb",
  },
  [Dimension.lengthHeight]: {
    [UnitLengthHeight.centimetre]: "cm",
  },
  [Dimension.lengthDistance]: {
    [UnitLengthDistance.kilometre]: "km",
    [UnitLengthDistance.mile]: "mi",
  },
  [Dimension.speed]: {
    [UnitSpeed.kmh]: "km/h",
    [UnitSpeed.mih]: "mi/h",
  },
}

export const canonicalSpeedFromDistanceAndDuration = (
  distance: ValueWithCanonicalUnit<Dimension.lengthDistance, ValidNumber>,
  duration: Duration,
): Opt.Option<ValueWithCanonicalUnit<Dimension.speed, ValidNumber>> => {
  const km = pipe(
    distance,
    valueInCanonicalUnit(Dimension.lengthDistance).reverseGet,
  )
  const hours = pipe(duration, (d) => d.total({ unit: "hours" }))
  return pipe(
    km / hours,
    numberAsValidNumber.getOption,
    Opt.map(valueInCanonicalUnit(Dimension.speed).get),
  )
}

export const canonicalDistanceFromSpeedAndDuration = (
  speed: ValueWithCanonicalUnit<Dimension.speed, ValidNumber>,
  duration: Duration,
): Opt.Option<
  ValueWithCanonicalUnit<Dimension.lengthDistance, ValidNumber>
> => {
  const kmh = pipe(speed, valueInCanonicalUnit(Dimension.speed).reverseGet)
  const hours = pipe(duration, (d) => d.total({ unit: "hours" }))
  return pipe(
    kmh * hours,
    numberAsValidNumber.getOption,
    Opt.map(valueInCanonicalUnit(Dimension.lengthDistance).get),
  )
}

export const canonicalDurationFromSpeedAndDistance = (
  speed: ValueWithCanonicalUnit<Dimension.speed, ValidNumber>,
  distance: ValueWithCanonicalUnit<Dimension.lengthDistance, ValidNumber>,
): Opt.Option<Duration> => {
  const kmh = pipe(speed, valueInCanonicalUnit(Dimension.speed).reverseGet)
  const km = pipe(
    distance,
    valueInCanonicalUnit(Dimension.lengthDistance).reverseGet,
  )
  return pipe(
    kmh / km,
    numberAsValidNumber.getOption,
    Opt.map((hours) => mkDuration(hours, 0, 0)),
  )
}

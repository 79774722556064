import { FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { flow } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import { Prism } from "monocle-ts"
import { EmailAddress, stringAsEmailAddress } from "@fitnesspilot/data-common"

import {
  ControllerPrism,
  formGroupRender,
  textRender,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"

export type FormData = {
  email: EmailAddress
  password: Opt.Option<string>
  confirmPassword: Opt.Option<string>
}

export type GeneralAccountInformationFieldsetProps = {
  id: string
}

export const GeneralAccountInformationFieldset: FC<
  GeneralAccountInformationFieldsetProps
> = ({ id }) => {
  const intl = useIntl()
  return (
    <>
      <h2>
        <FormattedMessage defaultMessage="General account information" />
      </h2>

      <ControllerPrism<FormData, "email">
        render={formGroupRender(textRender)({
          id: `${id}-email`,
          autoComplete: "email",
          type: "email",
          required: true,
          label: <FormattedMessage defaultMessage="Email address" />,
        })}
        name="email"
        prism={stringAsEmailAddress}
        prismError={intl.formatMessage({
          defaultMessage: "is invalid",
        })}
        rules={{
          required: true,
        }}
      />

      <ControllerPrism<FormData, "password">
        render={formGroupRender(textRender)({
          id: `${id}-password`,
          autoComplete: "new-password",
          type: "password",
          required: false,
          label: <FormattedMessage defaultMessage="New Password" />,
        })}
        name="password"
        prism={
          new Prism(flow(Opt.some, Opt.some), flow(Opt.getOrElse(() => "")))
        }
        prismError={intl.formatMessage({
          defaultMessage: "is invalid",
        })}
      />

      <ControllerPrism<FormData, "confirmPassword">
        render={formGroupRender(textRender)({
          id: `${id}-confirmPassword`,
          autoComplete: "new-password",
          type: "password",
          required: false,
          label: <FormattedMessage defaultMessage="Confirm Password" />,
        })}
        name="confirmPassword"
        prism={
          new Prism(flow(Opt.some, Opt.some), flow(Opt.getOrElse(() => "")))
        }
        prismError={intl.formatMessage({
          defaultMessage: "is invalid",
        })}
      />
    </>
  )
}

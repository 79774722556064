import * as Opt from "fp-ts/es6/Option"
import { Lens } from "monocle-ts"

import { SportWithId } from "../sport/Sport"

export enum SportPreferencesTab {
  statistics = "statistics",
  settings = "settings",
  instructions = "instructions",
  exercises = "exercises",
}

export type SportPreferences = {
  currentTab: SportPreferencesTab
  sport: Opt.Option<SportWithId>
}

export const _currentTab = Lens.fromProp<SportPreferences>()("currentTab")
export const _sport = Lens.fromProp<SportPreferences>()("sport")

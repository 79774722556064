import { KnownStepSet, StepSets } from "@fitnesspilot/data-help"

import { aboutMe } from "./aboutMe"
import { calendar } from "./calendar"
import { dashboard } from "./dashboard"
import { help } from "./help"
import { sports } from "./sports"

export const stepSets: StepSets = {
  [KnownStepSet.aboutMe]: aboutMe,
  [KnownStepSet.calendar]: calendar,
  [KnownStepSet.dashboard]: dashboard,
  [KnownStepSet.help]: help,
  [KnownStepSet.sports]: sports,
}

import { ReactNode } from "react"
import { FaTimes } from "react-icons/fa"
import { FormattedMessage } from "react-intl"

import * as IO from "fp-ts/es6/IO"

import colours from "../../atoms/ThemeProvider/colours"
import { ButtonWithIcon, IconSide } from "../ButtonWithIcon/ButtonWithIcon"

export type CancelButtonProps = {
  className?: string
  iconSide?: IconSide
  type: "button" | "reset"
  children?: ReactNode
  onClick?: IO.IO<void>
}

export const CancelButton = ({
  className,
  type,
  iconSide = IconSide.right,
  children = <FormattedMessage defaultMessage="Cancel" />,
  onClick,
}: CancelButtonProps) => (
  <ButtonWithIcon
    icon={<FaTimes color={colours.danger.hex()} />}
    {...{ className, type, iconSide, onClick }}
    color="discard"
  >
    {children}
  </ButtonWithIcon>
)

import { memo, ReactNode, useMemo, useState } from "react"
import { FaEllipsisH } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import { Button } from "reactstrap"
import styled from "@emotion/styled"

import * as Bool from "fp-ts/es6/boolean"
import { constNull, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import { dateAsTime } from "time-ts/es6"
import { prismToGetter } from "@fitnesspilot/data-common"

import { ActivityName } from "@fitnesspilot/components-activity/dist/atoms/ActivityName/ActivityName"
import { Card } from "@fitnesspilot/components-common/dist/atoms/Card/Card"
import { Alignment } from "@fitnesspilot/components-human-body/dist/molecules/Alignment/Alignment"
import {
  ActivityInstance,
  flattenActivityInstances,
} from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"
import {
  _start,
  EventWithId,
  stringAsEventId,
} from "@fitnesspilot/data-event/dist/calendar/Event"

import { ActivityMessage } from "../../atoms/ActivityMessage/ActivityMessage"

const Header = styled.div``

const Title = styled.strong`
  display: flex;
  justify-content: space-between;
`

const Time = styled.div`
  font-size: 0.9em;
`

const StyledCard = styled(Card)`
  border-top: 3px solid ${({ theme }) => theme.colours.primary.toString()};
  min-width: 180px;
`

const ActivityInfo = styled.div`
  font-size: 0.9em;
`

export type EventCardProps = {
  className?: string
  event: EventWithId
  activityLimit?: number
  showTime?: boolean
  children?: ReactNode
}

const EventCard_ = ({
  className,
  event: eventWithId,
  activityLimit,
  showTime = false,
  children,
}: EventCardProps) => {
  const [descriptionIsOpen, setDescriptionIsOpen] = useState(false)

  const eventId = eventWithId.id
  const event = eventWithId.value

  const [activities, moreActivities] = useMemo(
    () =>
      pipe(
        activityLimit,
        Opt.fromNullable,
        Opt.fold<
          number,
          readonly [
            ReadonlyArray<ActivityInstance>,
            ReadonlyArray<ActivityInstance>,
          ]
        >(
          () => [event.activities, []],
          (limit) => pipe(event.activities, Arr.splitAt(limit)),
        ),
      ),
    [event, activityLimit],
  )

  return (
    <StyledCard {...{ className }}>
      <Header>
        <Title>
          {event.title}
          {pipe(event.alignment, (value) => (
            <Alignment
              {...{ value, descriptionIsOpen }}
              onDescriptionIsOpenChange={(v) => () => setDescriptionIsOpen(v)}
            />
          ))}
        </Title>
        <Time>
          {pipe(
            showTime,
            Bool.fold(constNull, () =>
              pipe(
                event,
                _start.composeGetter(prismToGetter(dateAsTime)).get,
                (start) => (
                  <FormattedMessage
                    defaultMessage="{start, date, ::yMdHm}"
                    values={{ start }}
                  />
                ),
              ),
            ),
          )}
        </Time>
      </Header>
      {pipe(
        activities,
        flattenActivityInstances,
        Arr.mapWithIndex((i, activity) => (
          <ActivityInfo key={i}>
            <ActivityMessage {...{ activity }} />{" "}
            <ActivityName
              id={activity.value.activity.id}
              def={activity.value.activity.value.title}
            />
          </ActivityInfo>
        )),
      )}
      {pipe(
        moreActivities,
        NonEmpty.fromReadonlyArray,
        Opt.fold(constNull, (as) => (
          <ActivityInfo>
            <FormattedMessage
              defaultMessage="And {count} more {count, plural, one {activity} other {activities}}"
              values={{ count: as.length }}
            />
          </ActivityInfo>
        )),
      )}
      <Button
        tag={Link}
        to={`/calendar/events/${pipe(eventId, stringAsEventId.reverseGet)}`}
        color="link"
      >
        <FaEllipsisH /> <FormattedMessage defaultMessage="Details" />
      </Button>
      {children}
    </StyledCard>
  )
}

export const EventCard = memo(EventCard_)

import { useFormContext } from "react-hook-form"
import { FormattedMessage } from "react-intl"
import { Alert } from "reactstrap"

import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Rec from "fp-ts/es6/ReadonlyRecord"

export type AnswersProvidedProps = {
  at: number
  of: number
}

export const AnswersProvided = ({ at, of }: AnswersProvidedProps) => (
  <Alert color={at < of ? "warning" : "success"}>
    <FormattedMessage
      defaultMessage="{at} of {of, plural, one {# answer} other {# answers}} provided"
      values={{ at, of }}
    />
  </Alert>
)

export type FormAnswersProvidedProps<key extends string> = {
  keys: Rec.ReadonlyRecord<key, null>
}

export const FormAnswersProvided = <
  formData extends Rec.ReadonlyRecord<key, Opt.Option<any>>,
  key extends string = formData extends Rec.ReadonlyRecord<
    infer key,
    Opt.Option<any>
  >
    ? key
    : never,
>({
  keys,
}: FormAnswersProvidedProps<key>) => {
  const form = useFormContext<formData>()

  const values = form.getValues()

  return (
    <AnswersProvided
      at={pipe(
        values,
        Rec.filterWithIndex((k) => k in keys),
        Rec.filter(Opt.isSome),
        Rec.toReadonlyArray,
        (as) => as.length,
      )}
      of={pipe(keys, Rec.keys, (as) => as.length)}
    />
  )
}

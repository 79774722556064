import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import { optionLensToOptional } from "@fitnesspilot/data-common"

import { _StepStateStep, _StepStateStepOverview } from "../StepSet"
import * as actions from "./actions"
import { Action } from "./actions"
import * as selectors from "./selectors"
import { initialState, State } from "./state"

import { createReducer } from "typesafe-actions"

export default createReducer<State, Action>(initialState)
  .handleAction(actions.resetState, () => initialState)
  .handleAction(actions.showIndex, selectors.showIndex.set(true))
  .handleAction(actions.hideIndex, selectors.showIndex.set(false))
  .handleAction(actions.close, selectors.step.set(Opt.none))
  .handleAction(
    actions.gotoStepAsync.success,
    (state, { payload: stepSelection }) =>
      pipe(
        state,
        optionLensToOptional(selectors.step).set(
          _StepStateStep.reverseGet(stepSelection),
        ),
      ),
  )
  .handleAction(
    actions.fetchHelpStatusAsync.success,
    (state, { payload: status }) =>
      selectors.status.set(Opt.some(status))(state),
  )
  .handleAction(
    actions.saveHelpStatusAsync.success,
    (state, { payload: status }) =>
      selectors.status.set(Opt.some(status))(state),
  )
  .handleAction(actions.showSteps, (state) =>
    pipe(
      state,
      optionLensToOptional(selectors.step).set(
        _StepStateStepOverview.reverseGet(undefined),
      ),
    ),
  )

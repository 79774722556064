import { memo, ReactNode, useCallback, useMemo } from "react"
import { useIntl } from "react-intl"

import { flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"

import { ActivityInstanceName } from "@fitnesspilot/components-activity/dist/atoms/ActivityInstanceName/ActivityInstanceName"
import { Activity } from "@fitnesspilot/components-activity/dist/organisms/Activity/Activity"
import {
  _ActivityExercise,
  _ActivityJob,
  _ActivityMuscles,
  _ActivitySleep,
  ActivityWithId,
} from "@fitnesspilot/data-activity/dist/activity/Activity"
import { ActivityExercise } from "@fitnesspilot/data-activity/dist/activity/ActivityExercise"
import {
  ActivityId,
  activityId,
} from "@fitnesspilot/data-activity/dist/activity/ActivityId"
import { ActivityJob } from "@fitnesspilot/data-activity/dist/activity/ActivityJob"
import * as ActivityMuscleInfo from "@fitnesspilot/data-activity/dist/activity/ActivityMuscleInfo"
import { ActivityMuscles } from "@fitnesspilot/data-activity/dist/activity/ActivityMuscles"
import { ActivitySleep } from "@fitnesspilot/data-activity/dist/activity/ActivitySleep"
import * as WithId from "@fitnesspilot/data-activity/dist/activity/WithId"
import {
  _ActivityInstanceExercise,
  _ActivityInstanceNonGroup,
  ActivityInstanceNonGroup,
  foldActivityInstanceNonGroup,
  getAlignment,
} from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"
import * as ActivityInstanceExercise from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceExercise"
import * as ActivityInstanceJob from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceJob"
import * as ActivityInstanceMuscles from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceMuscles"
import * as ActivityInstanceSleep from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceSleep"
import { ActivitySettingsExerciseListing } from "@fitnesspilot/data-activity/dist/activitySettings/ActivitySettingsExercise"
import { validNumberAsWorkload } from "@fitnesspilot/data-human-body/dist/Workload"

export type EventActivityProps = {
  activity: ActivityInstanceNonGroup
  activityListing: Opt.Option<ActivitySettingsExerciseListing>
  topRight?: ReactNode
  bottomLeft?: ReactNode
  children?: ReactNode
  onSetActivityListing: (
    listing: ActivitySettingsExerciseListing,
  ) => IO.IO<void>
}

const EventActivity_ = ({ activity, ...props }: EventActivityProps) => {
  const intl = useIntl()

  return (
    <Activity
      muscleLevels={pipe(
        activity,
        _ActivityInstanceNonGroup
          .compose(_ActivityInstanceExercise)
          .composeLens(ActivityInstanceExercise._muscles).getOption,
        Opt.map(
          Rec.map(
            flow(
              ActivityMuscleInfo._workload.get,
              validNumberAsWorkload.reverseGet,
            ),
          ),
        ),
      )}
      overrideTitle={Opt.some(<ActivityInstanceName {...{ activity }} />)}
      alignment={pipe(activity, getAlignment)}
      activity={pipe(
        activity,
        foldActivityInstanceNonGroup(
          flow(
            ActivityInstanceExercise._activity.compose(
              WithId._withId<ActivityId, ActivityExercise>(activityId)._value,
            ).get,
            _ActivityExercise.reverseGet,
          ),
          flow(
            ActivityInstanceJob._activity.compose(
              WithId._withId<ActivityId, ActivityJob>(activityId)._value,
            ).get,
            _ActivityJob.reverseGet,
          ),
          flow(
            ActivityInstanceMuscles._activity.compose(
              WithId._withId<ActivityId, ActivityMuscles>(activityId)._value,
            ).get,
            _ActivityMuscles.reverseGet,
          ),
          flow(
            ActivityInstanceSleep._activity.compose(
              WithId._withId<ActivityId, ActivitySleep>(activityId)._value,
            ).get,
            _ActivitySleep.reverseGet,
          ),
        ),
        (value): ActivityWithId => ({ id: activity.value.activity.id, value }),
      )}
      {...props}
    />
  )
}

export const EventActivity = memo(EventActivity_)

export type EventActivityWrapperProps = Omit<
  EventActivityProps,
  "activityListing" | "onSetActivityListing"
> & {
  activityListings: ReadonlyArray<
    WithId.WithId<ActivityId, ActivitySettingsExerciseListing>
  >
  handleSetActivityListing: (
    activity: ActivityInstanceNonGroup,
  ) => (listing: ActivitySettingsExerciseListing) => IO.IO<void>
}

/** wrapper around EventActivity to help with callback references */
export const EventActivityWrapper = ({
  handleSetActivityListing,
  ...props
}: EventActivityWrapperProps) => {
  const { activity, activityListings } = props
  const onSetActivityListing = useCallback(handleSetActivityListing(activity), [
    activity,
    handleSetActivityListing,
  ])
  const activityListing = useMemo(
    () =>
      pipe(
        activityListings,
        Arr.findFirst((s) => s.id === activity.value.activity.id),
        Opt.map((s) => s.value),
      ),
    [activity, activityListings],
  )

  return (
    <EventActivity {...props} {...{ activityListing, onSetActivityListing }} />
  )
}

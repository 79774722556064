import { FormattedMessage } from "react-intl"
import { css } from "@emotion/react"

import * as Opt from "fp-ts/es6/Option"

import { KnownStepSet, StepSet } from "@fitnesspilot/data-help"

import { HelpBeacon } from "../molecules/HelpBeacon/HelpBeacon"

export const help: StepSet = {
  id: KnownStepSet.help,
  title: <FormattedMessage defaultMessage="Intro" />,
  steps: [
    {
      target: '[data-help-mode="launchHelp"]',
      title: <FormattedMessage defaultMessage="Intro" />,
      content: (
        <>
          <FormattedMessage defaultMessage="Click here to restart the help mode in case you're stuck." />

          <section>
            <h4>
              <FormattedMessage defaultMessage="Help Guide Indicators" />
            </h4>

            <p>
              <FormattedMessage defaultMessage="When in help mode, click these indicators to go to a specific help step." />

              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 20px;
                `}
              >
                <HelpBeacon
                  continuous
                  index={0}
                  isLastStep={false}
                  setTooltipRef={() => undefined}
                  size={2}
                  step={{ content: "", target: "" }}
                />
              </div>
            </p>
          </section>

          <section>
            <h4>
              <FormattedMessage defaultMessage="Help Table of Contents" />
            </h4>

            <p>
              <FormattedMessage defaultMessage="Click on this icon to go to the help mode index:" />

              {/* <HelpIndexLink /> */}

              <FormattedMessage defaultMessage="Then, you may click any of the index items to go to any specific help mode steps." />

              {/* <HelpIndex /> */}
            </p>
          </section>
        </>
      ),
    },
  ],
  showNext: false,
  startOn: Opt.some("/"),
  href: "/",
}

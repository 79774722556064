/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const UnitDistance = {
    Kilometres: "kilometres",
    Miles: "miles",
};
export function UnitDistanceFromJSON(json) {
    return UnitDistanceFromJSONTyped(json, false);
}
export function UnitDistanceFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function UnitDistanceToJSON(value) {
    return value;
}

import { FC, Fragment } from "react"
/* import styled from "@emotion/styled" */
import { FormattedMessage } from "react-intl"

import * as Opt from "fp-ts/es6/Option"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import {
  CanonicalUnit,
  canonicalUnitOfDimension,
  Dimension,
  ValidNumber,
  ValueWithCanonicalUnit,
} from "@fitnesspilot/data-common"

import { FormAnswersProvided } from "@fitnesspilot/components-common/dist/atoms/AnswersProvided/AnswersProvided"
import { FieldsetHeader } from "@fitnesspilot/components-common/dist/atoms/FieldsetHeader/FieldsetHeader"
import {
  formGroupRender,
  FormGroupRenderProps,
  wrapPrismOpt,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"
import { ControllerDimensionalNumberContainer } from "@fitnesspilot/components-user/dist/organisms/ControllerDimensionalNumber/ControllerDimensionalNumber"

export type FormData = {
  bodyHeight: Opt.Option<
    ValueWithCanonicalUnit<Dimension.lengthHeight, ValidNumber>
  >
  bodyMass: Opt.Option<ValueWithCanonicalUnit<Dimension.mass, ValidNumber>>
}
const FormDataKeys: Rec.ReadonlyRecord<keyof FormData, null> = {
  bodyHeight: null,
  bodyMass: null,
}

export type HeightAndMassFieldsetProps = {
  id: string
  showAnswersProvided?: boolean
}

export const HeightAndMassFieldset: FC<HeightAndMassFieldsetProps> = ({
  id,
  showAnswersProvided,
}) => (
  <Fragment>
    <FieldsetHeader
      title={<FormattedMessage defaultMessage="My Body" />}
      titleRight={
        showAnswersProvided && (
          <FormAnswersProvided<FormData> keys={FormDataKeys} />
        )
      }
      description={
        <FormattedMessage defaultMessage="Height and weight are needed to understand your proportions and calculate your BMI. The Coach will regularly ask you whether your weight has changed to gain a better sense for your development." />
      }
    />

    <ControllerDimensionalNumberContainer<
      FormData,
      "bodyHeight",
      Dimension.lengthHeight,
      FormGroupRenderProps
    >
      name="bodyHeight"
      dimension={Dimension.lengthHeight}
      transform={wrapPrismOpt}
      renderTransform={formGroupRender}
      renderTransformProps={{
        id: `${id}-height`,
        label: <FormattedMessage defaultMessage="Height" />,
      }}
    />

    <ControllerDimensionalNumberContainer<
      FormData,
      "bodyMass",
      Dimension.mass,
      FormGroupRenderProps
    >
      name="bodyMass"
      dimension={Dimension.mass}
      transform={wrapPrismOpt}
      renderTransform={formGroupRender}
      renderTransformProps={{
        id: `${id}-weight`,
        label: <FormattedMessage defaultMessage="Weight" />,
      }}
    />
  </Fragment>
)

import { Dispatch, FC, ReactNode, useEffect } from "react"
import { FaArrowRight, FaDumbbell } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import { Link } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"
import styled from "@emotion/styled"

import { constant, constVoid, flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { fromTraversable, Traversal } from "monocle-ts"
import {
  addLocalDuration,
  compareLocalTime,
  currentTimeZone,
  dayOfLocalTime,
  dayOfTime,
  localTimeAsDayAndLocalTimeOfDayPair,
  localTimeOfDayOfLocalTime,
  mkLocalDuration,
  mkLocalTimeOfDayDuration,
  Time,
  timeAsLocalTime,
} from "time-ts/es6"
import { optionLensToOptional } from "@fitnesspilot/data-common"

import { DayInput } from "@fitnesspilot/components-common/dist/atoms/input/DayInput"
import { LocalTimeOfDayInput as LocalTimeOfDayInput_ } from "@fitnesspilot/components-common/dist/atoms/input/LocalTimeOfDayInput"
import { CancelButton } from "@fitnesspilot/components-common/dist/molecules/CancelButton/CancelButton"
import { Content } from "@fitnesspilot/components-common/dist/molecules/Content/Content"
import { Header } from "@fitnesspilot/components-common/dist/molecules/Header/Header"
import { StickyFooter } from "@fitnesspilot/components-common/dist/molecules/StickyFooter/StickyFooter"
import { RecurrenceInput } from "@fitnesspilot/components-event/dist/molecules/RecurrenceInput/RecurrenceInput"
import * as WithId from "@fitnesspilot/data-activity/dist/activity/WithId"
import { normaliseInterval } from "@fitnesspilot/data-common/dist/time"
import { maxNewEventHistory } from "@fitnesspilot/data-event/dist/calendarBounds"
import {
  Action,
  cancelEditingEvent,
  initNewEvent,
  ParentState,
  selectors,
  setEventBetween,
  setEventRecurrence,
} from "@fitnesspilot/data-event/dist/store"
import * as EditingEvent from "@fitnesspilot/data-event/dist/store/EditingEvent"

import { MainTemplate } from "../../templates/Main/Main"

import mem from "memoize-one"

type StepProps = {
  className?: string
  title: ReactNode
  children: ReactNode
}
const Step = styled(({ title, children, ...props }: StepProps) => (
  <div {...props}>
    <h1>{title}</h1>
    <Content>{children}</Content>
  </div>
))`
  > h1 {
    background: #eeeef8;
    text-align: center;
    padding: 40px 0 20px;
  }

  > * {
    padding: 30px !important;
  }
`

const H2 = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  padding: 16px 0;
  font-size: 24px !important;
`

const LocalTimeOfDayInput = styled(LocalTimeOfDayInput_)`
  max-width: 200px;
  font-size: 32px;
`

const flippedSet =
  <s, a>(l: { set: (a: a) => (s: s) => s }) =>
  (s: s) =>
  (a: a) =>
    l.set(a)(s)

const mapState = (state: ParentState) => ({
  event: pipe(
    state,
    mem(
      selectors.state
        .composeOptional(optionLensToOptional(selectors.editingEvent))
        .composeLens(WithId._value()).getOption,
    ),
  ),
})

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ =
    (act: Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, constVoid)

  return {
    onSetBetween: flow(setEventBetween, dispatch_),
    onSetRecurrence: flow(setEventRecurrence, dispatch_),
    onCancel: pipe(true, cancelEditingEvent, dispatch_),
    initNewEvent: flow(initNewEvent, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

export type AddEventPageProps = PropsFromRedux

const betweenTitles = [
  <FormattedMessage key={0} defaultMessage="Start:" />,
  <FormattedMessage key={1} defaultMessage="End:" />,
] as const

const traversalPair = fromTraversable(Arr.Traversable) as any as <
  a,
>() => Traversal<readonly [a, a], a>

export const AddEventPage: FC<AddEventPageProps> = ({
  event,
  onSetBetween,
  onSetRecurrence,
  onCancel,
  initNewEvent,
}) => {
  useEffect(() => {
    if (Opt.isNone(event)) {
      initNewEvent({ between: [Opt.none, Opt.none] })()
    }
  }, [event, initNewEvent])

  return (
    <MainTemplate
      header={
        <Header
          icon={<FaDumbbell />}
          title={<FormattedMessage defaultMessage="Create event" />}
        />
      }
      data-help-mode="createEvent"
    >
      {pipe(
        event,
        Opt.fold(
          () => null,
          (event) => (
            <>
              <Step
                title={<FormattedMessage defaultMessage="1. Pick the date" />}
                data-help-mode="date"
              >
                {([EditingEvent._start] as const).map((baseLens, i) => {
                  const lens = baseLens
                    .composeIso(timeAsLocalTime(currentTimeZone()))
                    .composeLens(dayOfLocalTime)

                  return (
                    <DayInput
                      hideClear
                      key={i}
                      min={pipe(
                        maxNewEventHistory(),
                        timeAsLocalTime(currentTimeZone()).get,
                        dayOfLocalTime.get,
                        Opt.some,
                      )}
                      max={Opt.none}
                      step={Opt.none}
                      value={pipe(event, lens.get, Opt.some)}
                      onChange={flow(
                        Opt.map((day) =>
                          pipe(
                            event,
                            EditingEvent._between.get,
                            traversalPair<Time>()
                              .composeIso(timeAsLocalTime(currentTimeZone()))
                              .composeLens(dayOfLocalTime)
                              .set(day),
                            normaliseInterval(currentTimeZone()),
                            onSetBetween,
                          ),
                        ),
                        Opt.getOrElse(() => IO.of<void>(undefined)),
                      )}
                    />
                  )
                })}
              </Step>

              <Step
                title={<FormattedMessage defaultMessage="2. Set the time" />}
                data-help-mode="time"
              >
                {([EditingEvent._start, EditingEvent._end] as const).map(
                  (baseLens, i) => {
                    const lens = baseLens.composeIso(
                      timeAsLocalTime(currentTimeZone()),
                    )
                    const startTime = event.between[0]
                    const localStartTime = timeAsLocalTime(
                      currentTimeZone(),
                    ).get(startTime)
                    const startDay = dayOfTime.get(startTime)
                    const isEndTime = i === 1

                    return (
                      <Row key={i}>
                        <Col md={{ size: 1, offset: 4 }}>
                          <H2>{betweenTitles[i]}</H2>
                        </Col>

                        <Col md={{ size: 3 }}>
                          <LocalTimeOfDayInput
                            hideClear={true}
                            min={Opt.none}
                            max={Opt.none}
                            step={Opt.some(mkLocalTimeOfDayDuration(0, 1, 0))}
                            value={pipe(
                              event,
                              lens.composeLens(localTimeOfDayOfLocalTime).get,
                              Opt.some,
                            )}
                            onChange={(tod) =>
                              pipe(
                                event,
                                baseLens.set(
                                  pipe(
                                    tod,
                                    Opt.fold(constant(startTime), (tod) =>
                                      pipe(
                                        localTimeAsDayAndLocalTimeOfDayPair.reverseGet(
                                          [startDay, tod],
                                        ),
                                        (localTime) =>
                                          isEndTime &&
                                          compareLocalTime(localTime)(
                                            localStartTime,
                                          ) == 1
                                            ? addLocalDuration(
                                                mkLocalDuration({ days: 1 }),
                                              )(localTime)
                                            : localTime,
                                        timeAsLocalTime(currentTimeZone())
                                          .reverseGet,
                                      ),
                                    ),
                                  ),
                                ),
                                EditingEvent._between.get,
                                normaliseInterval(currentTimeZone()),
                                onSetBetween,
                              )
                            }
                          />
                        </Col>
                      </Row>
                    )
                  },
                )}
              </Step>

              <Step
                title={
                  <FormattedMessage defaultMessage="3. Choose the recurrence" />
                }
                data-help-mode="recurrence"
              >
                <Row>
                  <Col md={{ size: 6, offset: 3 }}>
                    <RecurrenceInput
                      recurrence={event.recurrence}
                      onChange={onSetRecurrence}
                      start={event.between[0]}
                    />
                  </Col>
                </Row>
              </Step>
            </>
          ),
        ),
      )}

      <StickyFooter>
        <CancelButton type="button" onClick={onCancel} />
        <Button
          tag={Link}
          to={"/calendar/events/new"}
          color="success"
          data-help-mode="next"
        >
          <FormattedMessage defaultMessage="Next" /> <FaArrowRight />
        </Button>
      </StickyFooter>
    </MainTemplate>
  )
}

export const AddEventPageContainer = connector(AddEventPage)

import { flow, pipe } from "fp-ts/es6/function";
import * as IO from "fp-ts/es6/IO";
import * as Opt from "fp-ts/es6/Option";
import { Iso, Lens, Prism } from "monocle-ts";
import { iso } from "newtype-ts";
import { ioTsPrism } from "../internal";
import { dayOfTime, today } from "./Day";
import { Temporal } from "@js-temporal/polyfill";
import * as t from "io-ts";
const isoTimeOfDay = iso();
export const _hour = isoTimeOfDay
    .composeLens(Lens.fromProp()("hour"))
    .asGetter();
export const _minute = isoTimeOfDay
    .composeLens(Lens.fromProp()("minute"))
    .asGetter();
export const _second = isoTimeOfDay
    .composeLens(Lens.fromProp()("second"))
    .asGetter();
export const timeOfDay = {
    equals: (a, b) => isoTimeOfDay.get(a).equals(isoTimeOfDay.get(b)),
    compare: (a, b) => Temporal.PlainTime.compare(isoTimeOfDay.get(a), isoTimeOfDay.get(b)),
};
export const equals = (d2) => (d) => timeOfDay.equals(d, d2);
export const compare = (d2) => (d) => timeOfDay.compare(d, d2);
export const timeOfDayOfTime = new Lens((s) => isoTimeOfDay.reverseGet(s.toZonedDateTimeISO("UTC").toPlainTime()), (a) => (s) => s.toZonedDateTimeISO("UTC").withPlainTime(isoTimeOfDay.get(a)).toInstant());
export const currentTimeOfDay = pipe(() => Temporal.Now.plainTimeISO("UTC"), IO.map(isoTimeOfDay.reverseGet));
export const mkTimeOfDayWrapped = (hour, minute, second) => pipe(Temporal.PlainTime.from({
    hour,
    minute,
    microsecond: second * 1000000,
}), isoTimeOfDay.reverseGet);
export const mkTimeOfDay = (hour, minute, second) => Opt.tryCatch(() => pipe(Temporal.PlainTime.from({
    hour,
    minute,
    microsecond: second * 1000000,
}, { overflow: "reject" }), isoTimeOfDay.reverseGet));
const regex = /^(\d{2}):(\d{2})(?::(\d{2}(?:\.(\d+))?))?Z$/;
export const isoStringAsTimeOfDay = new Prism(flow(Opt.fromPredicate((s) => regex.test(s)), Opt.chain((s) => Opt.tryCatch(() => Temporal.PlainTime.from(s))), Opt.map(isoTimeOfDay.reverseGet)), (s) => String(s));
export const timeOfDayT = t.string.pipe(ioTsPrism("TimeOfDay", 
// @TODO
(a) => a instanceof Temporal.PlainTime, isoStringAsTimeOfDay));
export const timeFromDayAndTimeOfDay = (d, tod) => d
    .toZonedDateTime({ timeZone: "UTC", plainTime: isoTimeOfDay.get(tod) })
    .toInstant();
export const timeAsDayAndTimeOfDayPair = new Iso((t) => [dayOfTime.get(t), timeOfDayOfTime.get(t)], ([d, tod]) => timeFromDayAndTimeOfDay(d, tod));
const zero = mkTimeOfDayWrapped(0, 0, 0);
export const startOfDay = (d) => timeFromDayAndTimeOfDay(d, zero);
export const startOfToday = pipe(today, IO.map(startOfDay));

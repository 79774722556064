import { FC } from "react"
import styled from "@emotion/styled"

import { MuscleId_ } from "@fitnesspilot/data-human-body/dist/Muscle"

import def from "./imgs/default.jpg"

const imgs = {
  [MuscleId_.upperTrapezium]: def,
  [MuscleId_.middleTrapezium]: def,
  [MuscleId_.lowerTrapezium]: def,
  [MuscleId_.latissimus]: def,
  [MuscleId_.backExtensor]: def,
  [MuscleId_.upperChest]: def,
  [MuscleId_.middleChest]: def,
  [MuscleId_.lowerChest]: def,
  [MuscleId_.frontShoulder]: def,
  [MuscleId_.middleShoulder]: def,
  [MuscleId_.backShoulder]: def,
  [MuscleId_.diagonalAbdominal]: def,
  [MuscleId_.straightAbdominal]: def,
  [MuscleId_.biceps]: def,
  [MuscleId_.triceps]: def,
  [MuscleId_.forearm]: def,
  [MuscleId_.quadriceps]: def,
  [MuscleId_.adductors]: def,
  [MuscleId_.abductors]: def,
  [MuscleId_.hamstring]: def,
  [MuscleId_.calves]: def,
  [MuscleId_.glutes]: def,
  [MuscleId_.cardiovascularSystem]: def,
}

const Img = styled.img`
  border: 1px solid hsla(0, 0%, 46.7%, 0.25);
  border-radius: 3px;
`

export type MuscleImageProps = {
  value: MuscleId_
  className?: string
}

export const MuscleImage: FC<MuscleImageProps> = ({ value, className }) => (
  <Img src={imgs[value]} {...{ className }} />
)

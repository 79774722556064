/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserSleepScheduleFromJSON, UserSleepScheduleToJSON, } from "./UserSleepSchedule";
/**
 * Check if a given object implements the UserSleep interface.
 */
export function instanceOfUserSleep(value) {
    let isInstance = true;
    isInstance = isInstance && "schedule" in value;
    return isInstance;
}
export function UserSleepFromJSON(json) {
    return UserSleepFromJSONTyped(json, false);
}
export function UserSleepFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        schedule: UserSleepScheduleFromJSON(json.schedule),
    };
}
export function UserSleepToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        schedule: UserSleepScheduleToJSON(value.schedule),
    };
}

import { ReactNode, useEffect } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { FaEdit } from "react-icons/fa"
import { FormattedMessage, useIntl } from "react-intl"
import FormattedDuration from "react-intl-formatted-duration"
import { connect, ConnectedProps } from "react-redux"
import { FormGroup as FormGroup_ } from "reactstrap"

import * as Apply from "fp-ts/es6/Apply"
import * as Bool from "fp-ts/es6/boolean"
import { identity, pipe } from "fp-ts/es6/function"
import * as Func from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import {
  currentTimeZone,
  dateAsTime,
  isoStringAsLocalTimeOfDayDuration,
  localTimeAsZonedTime,
  localTimeFromDayAndLocalTimeOfDay,
  LocalTimeOfDay,
  secondsAsLocalTimeOfDayDuration,
  today,
  zonedTimeToTime,
} from "time-ts/es6"
import { unsafeFromSome, useStateIO } from "@fitnesspilot/data-common"

import { ActivityName } from "@fitnesspilot/components-activity/dist/atoms/ActivityName/ActivityName"
import { FormAnswersProvided } from "@fitnesspilot/components-common/dist/atoms/AnswersProvided/AnswersProvided"
import { dayOfWeekName } from "@fitnesspilot/components-common/dist/atoms/DayOfWeekName/DayOfWeekName"
import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import { FieldsetHeader } from "@fitnesspilot/components-common/dist/atoms/FieldsetHeader/FieldsetHeader"
import {
  ButtonWithIcon,
  IconSide,
} from "@fitnesspilot/components-common/dist/molecules/ButtonWithIcon/ButtonWithIcon"
import { FormGroup } from "@fitnesspilot/components-common/dist/molecules/FormGroup/FormGroup"
import * as DayOfWeek from "@fitnesspilot/data-common/dist/DayOfWeek"
import * as UserData from "@fitnesspilot/data-user"
import { initialState } from "@fitnesspilot/data-user"
import * as Job from "@fitnesspilot/data-user/dist/Job"
import * as Work from "@fitnesspilot/data-user/dist/Work"

import { WorkTimeModal } from "../WorkTimeModal/WorkTimeModal"
import {
  WorkTypeModal,
  WorkTypeModalProps,
} from "../WorkTypeModal/WorkTypeModal"

import { Dispatch } from "redux"

export type FormData = {
  work: {
    job: Job.Job
    mayEatAtWork: boolean
    lunchtime: {
      duration: string
      within: [Date, Date]
    }
    time: Rec.ReadonlyRecord<DayOfWeek.DayOfWeek, Opt.Option<[Date, Date]>>
  }
}
const FormDataKeys: Rec.ReadonlyRecord<keyof FormData, null> = {
  work: null,
}

const mapState = (state: UserData.ParentState) => {
  return {
    predefinedJobs: pipe(
      state,
      UserData.selectors.state.composeLens(UserData.selectors.jobs).get,
    ),
  }
}

const mapDispatch = (dispatch: Dispatch<UserData.Action>) => {
  const dispatch_ =
    (act: UserData.Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, Func.constVoid)

  return {
    loadPredefinedJobs: pipe(UserData.fetchJobs(), dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type WorkFieldsetContainerProps = {
  id: string
  showAnswersProvided?: boolean
}

export type WorkFieldsetProps = PropsFromRedux & WorkFieldsetContainerProps

export const WorkFieldset = ({
  id,
  showAnswersProvided,
  predefinedJobs,
  loadPredefinedJobs,
}: WorkFieldsetProps) => {
  const intl = useIntl()
  const [isJobModalOpen, setIsJobModalOpen] = useStateIO(() => false)()
  const [isTimeModalOpen, setIsTimeModalOpen] = useStateIO(() => false)()
  const form = useFormContext<FormData>()
  useEffect(() => loadPredefinedJobs(), [loadPredefinedJobs])

  const { job, time, lunchtime, mayEatAtWork } = useWatch({
    control: form.control,
    name: "work",
  })

  return (
    <>
      <FieldsetHeader
        title={<FormattedMessage defaultMessage="Work" />}
        titleRight={
          showAnswersProvided && (
            <FormAnswersProvided<FormData> keys={FormDataKeys} />
          )
        }
        description={
          <FormattedMessage defaultMessage="Your profession will help us to prepare better recommendations regarding nutrition and physical activities." />
        }
      />

      <FormGroup_>
        <FormGroup
          inputId={`${id}-job`}
          label={<FormattedMessage defaultMessage="Job" />}
        >
          <div>
            <ActivityName
              id={pipe(job.id, Job.jobIdAsActivityId.get)}
              def={job.name}
            />
          </div>
        </FormGroup>

        <ButtonWithIcon
          color="primary"
          icon={<FaEdit />}
          iconSide={IconSide.left}
          onClick={setIsJobModalOpen(true)}
        >
          <FormattedMessage defaultMessage="Edit" />
        </ButtonWithIcon>
      </FormGroup_>

      <FormGroup_>
        <FormGroup
          inputId={`${id}-time`}
          label={<FormattedMessage defaultMessage="Time" />}
        >
          <table>
            <tbody>
              {pipe(
                time,
                Rec.filterMap(identity),
                Rec.mapWithIndex((dow: DayOfWeek.DayOfWeek, time) => (
                  <tr key={dow}>
                    <FormattedMessage
                      defaultMessage="<th>{dayOfWeek}:</th><td>{start, date, ::Hm}—{end, date, ::Hm}</td>"
                      values={{
                        th: (children: ReactNode) => (
                          <th scope="row">{children}</th>
                        ),
                        td: (children: ReactNode) => <td>{children}</td>,
                        dayOfWeek: dayOfWeekName(intl)({ id: dow }),
                        start: time[0],
                        end: time[1],
                      }}
                    />
                  </tr>
                )),
                Rec.toReadonlyArray,
                Arr.sort(
                  pipe(
                    DayOfWeek.dayOfWeek,
                    Ord.contramap(
                      ([a]: readonly [DayOfWeek.DayOfWeek, JSX.Element]) => a,
                    ),
                  ),
                ),
                Arr.map(([, a]) => a),
              )}
            </tbody>
          </table>
          <div>
            <FormattedMessage
              defaultMessage="{duration} lunch break"
              values={{
                duration: (
                  <FormattedDuration
                    unitDisplay="short"
                    seconds={pipe(
                      lunchtime.duration,
                      isoStringAsLocalTimeOfDayDuration.composeIso(
                        secondsAsLocalTimeOfDayDuration.reverse(),
                      ).getOption,
                      unsafeFromSome,
                    )}
                  />
                ),
              }}
            />
          </div>
          <div>
            {pipe(
              mayEatAtWork,
              Bool.fold(
                () => (
                  <FormattedMessage defaultMessage="Eating at work is allowed" />
                ),
                () => (
                  <FormattedMessage defaultMessage="Eating at work is not allowed" />
                ),
              ),
            )}
          </div>
        </FormGroup>

        <ButtonWithIcon
          color="primary"
          icon={<FaEdit />}
          iconSide={IconSide.left}
          onClick={setIsTimeModalOpen(true)}
        >
          <FormattedMessage defaultMessage="Edit" />
        </ButtonWithIcon>
      </FormGroup_>

      <WorkTypeModal
        id={`${id}-workType`}
        isOpen={isJobModalOpen}
        onSetIsOpen={setIsJobModalOpen}
        defaultValues={{
          job: form.getValues("work.job"),
        }}
        onSubmit={({ job }) =>
          pipe(
            job,
            (v) => () => form.setValue("work.job", v),
            IO.chainFirst(() => setIsJobModalOpen(false)),
          )()
        }
        predefinedValues={predefinedJobs}
      />

      <WorkTimeModal
        id={`${id}-workTime`}
        isOpen={isTimeModalOpen}
        onSetIsOpen={setIsTimeModalOpen}
        defaultValues={{
          mayEatAtWork: form.getValues("work.mayEatAtWork"),
          lunchtime: form.getValues("work.lunchtime"),
          time: pipe(
            form.getValues("work.time"),
            Rec.map(
              Opt.fold(
                () =>
                  [Opt.none, Opt.none] as [Opt.Option<Date>, Opt.Option<Date>],
                ([a, b]) =>
                  [Opt.some(a), Opt.some(b)] as [
                    Opt.Option<Date>,
                    Opt.Option<Date>,
                  ],
              ),
            ),
          ),
        }}
        onSubmit={({ mayEatAtWork, lunchtime, time }) =>
          pipe(
            time,
            Rec.map(([a, b]) => Apply.sequenceT(Opt.option)(a, b)),
            (v) => () => form.setValue("work.time", v),
            IO.chainFirst(
              () => () => form.setValue("work.mayEatAtWork", mayEatAtWork),
            ),
            IO.chainFirst(
              () => () => form.setValue("work.lunchtime", lunchtime),
            ),
            IO.chainFirst(() => setIsTimeModalOpen(false)),
          )()
        }
      />
    </>
  )
}

export const WorkFieldsetContainer = connector(WorkFieldset)

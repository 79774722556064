import * as E from "fp-ts/es6/Either";
import * as Func from "fp-ts/es6/function";
import { flow, pipe } from "fp-ts/es6/function";
import * as Num from "fp-ts/es6/number";
import * as Opt from "fp-ts/es6/Option";
import * as Ordering from "fp-ts/es6/Ordering";
import * as Arr from "fp-ts/es6/ReadonlyArray";
import { Getter } from "monocle-ts";
import * as t from "io-ts";
export const on = (f) => (g) => (a, a2) => g(f(a), f(a2));
export const liftIso = (iso) => (f) => flow(iso.get, f, iso.reverseGet);
export const liftIso2 = (iso) => (f) => flow(on(iso.get)(f), iso.reverseGet);
export const wrapOption = (f) => (...args) => Opt.tryCatch(() => f(...args));
export const compareNumKeys = (ks) => (a, a2) => flow(Arr.map((k) => Num.Ord.compare(a[k], a2[k])), (vs) => Arr.Foldable.foldMap(Ordering.Monoid)(vs, Func.identity))(ks);
export const prismToGetter = (prism) => new Getter(prism.reverseGet);
export const ioTsPrism = (name, is, prism) => new t.Type(name, is, (value, context) => pipe(value, prism.getOption, E.fromOption(() => [{ value, context }])), prism.reverseGet);

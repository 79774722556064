import React from "react"
import { Nav, NavProps } from "reactstrap"
import styled from "@emotion/styled"

import classNames from "classnames"

export type SupraNavSectionProps = NavProps

export const SupraNavSection = styled(
  ({ className, ...props }: SupraNavSectionProps) => (
    <Nav className={classNames("c-supraNavSection", className)} {...props} />
  ),
)`
  border-radius: 3px;

  & + & {
    border-top: 1px solid #0000001a;
  }

  p {
    font-size: 15px;
  }
`

import * as Eq from "fp-ts/es6/Eq"
import * as Func from "fp-ts/es6/function"
import { flow, pipe } from "fp-ts/es6/function"
import * as Num from "fp-ts/es6/number"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Str from "fp-ts/es6/string"
import { Getter, Lens } from "monocle-ts"
import { Newtype, prism } from "newtype-ts"
import { unsafeFromSome } from "@fitnesspilot/data-common"

import {
  ActivityId,
  stringAsActivityId,
} from "@fitnesspilot/data-activity/dist/activity/ActivityId"

export type JobId = Newtype<{ readonly JobId: unique symbol }, string>
export const stringAsJobId = prism<JobId>(Func.constTrue)
export const jobId: Ord.Ord<JobId> = Str.Ord as any

export type Job = {
  id: JobId
  name: string
  instructions: Opt.Option<string>
  activity: {
    physical: number
    mental: number
  }
}
export const job: Eq.Eq<Job> = Eq.struct({
  id: jobId,
  name: Str.Eq,
  instructions: Opt.getEq(Str.Eq),
  activity: Eq.struct({
    physical: Num.Eq,
    mental: Num.Eq,
  }),
})
export const _id = Lens.fromProp<Job>()("id")
export const _name = Lens.fromProp<Job>()("name")
export const _instructions = Lens.fromProp<Job>()("instructions")
export const _activity = Lens.fromProp<Job>()("activity")
export const _physicalActivity = Lens.fromPath<Job>()(["activity", "physical"])
export const _mentalActivity = Lens.fromPath<Job>()(["activity", "mental"])

export const defaultJob: Job = {
  id: pipe("activity-defaultJob", stringAsJobId.getOption, unsafeFromSome),
  name: "Default job",
  instructions: Opt.some("A default job for usage in MVP"),
  activity: {
    physical: 0,
    mental: 0,
  },
}

export const jobIdAsActivityId = new Getter<JobId, ActivityId>(
  flow(stringAsJobId.reverseGet, stringAsActivityId.getOption, unsafeFromSome),
)

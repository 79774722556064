import * as Opt from "fp-ts/es6/Option"
import { Prism } from "monocle-ts"
import { Time } from "time-ts/es6"

import * as Event from "../calendar/Event"
import * as Recommendation from "../calendar/Recommendation"

enum CalendarSelectionType {
  event = "event",
  recommendation = "recommendation",
  recommendationWithActivities = "recommendationWithActivities",
  timeRange = "timeRange",
}

export type CalendarSelection =
  | { type: CalendarSelectionType.event; value: Event.EventWithId }
  | {
      type: CalendarSelectionType.recommendation
      value: Recommendation.Recommendation
    }
  | {
      type: CalendarSelectionType.recommendationWithActivities
      value: Event.Event
    }
  | { type: CalendarSelectionType.timeRange; value: [Time, Time] }
export const _CalendarSelectionEvent = new Prism(
  (v: CalendarSelection) =>
    v.type === CalendarSelectionType.event ? Opt.some(v.value) : Opt.none,
  (value): CalendarSelection => ({
    type: CalendarSelectionType.event as const,
    value,
  }),
)
export const _CalendarSelectionRecommendation = new Prism(
  (v: CalendarSelection) =>
    v.type === CalendarSelectionType.recommendation
      ? Opt.some(v.value)
      : Opt.none,
  (value): CalendarSelection => ({
    type: CalendarSelectionType.recommendation as const,
    value,
  }),
)
export const _CalendarSelectionRecommendationWithActivities = new Prism(
  (v: CalendarSelection) =>
    v.type === CalendarSelectionType.recommendationWithActivities
      ? Opt.some(v.value)
      : Opt.none,
  (value): CalendarSelection => ({
    type: CalendarSelectionType.recommendationWithActivities as const,
    value,
  }),
)
export const _CalendarSelectionTimeRange = new Prism(
  (v: CalendarSelection) =>
    v.type === CalendarSelectionType.timeRange ? Opt.some(v.value) : Opt.none,
  (value): CalendarSelection => ({
    type: CalendarSelectionType.timeRange as const,
    value,
  }),
)

export const fold =
  <a>(
    onEvent: (v: Event.EventWithId) => a,
    onRecommendation: (v: Recommendation.Recommendation) => a,
    onRecommendationWithActivities: (v: Event.Event) => a,
    onTimeRange: (v: [Time, Time]) => a,
  ) =>
  (v: CalendarSelection) =>
    v.type === CalendarSelectionType.event
      ? onEvent(v.value)
      : v.type === CalendarSelectionType.recommendation
      ? onRecommendation(v.value)
      : v.type === CalendarSelectionType.recommendationWithActivities
      ? onRecommendationWithActivities(v.value)
      : v.type === CalendarSelectionType.timeRange
      ? onTimeRange(v.value)
      : (null as never)

import { ReadonlyNonEmptyArray } from "fp-ts/es6/ReadonlyNonEmptyArray"

import { CookieId, FpCookie } from "../cookieConsent"

// TODO translate this
export const cookies: ReadonlyNonEmptyArray<FpCookie> = [
  {
    id: CookieId.core,
    name: "Fitnesspilot",
    company: "upsquared GmbH",
    description: "Cookies for core functionality.",
    category: "necessary",
  },
  {
    id: CookieId.sentry,
    name: "Sentry",
    company: "Functional Software",
    description:
      "Collects errors & performance metrics to improve website reliability.",
    category: "necessary",
  },
  {
    id: CookieId.gtm,
    name: "Google Tag Manager",
    company: "Google",
    description: "Collects statistical data on how visitors use the website.",
    category: "statistics",
  },
  {
    id: CookieId.ga,
    name: "Google Analytics",
    company: "Google",
    description: "Collects statistical data on how visitors use the website.",
    category: "statistics",
  },
]

import * as Eq from "fp-ts/es6/Eq"
import * as Str from "fp-ts/es6/string"

export enum FitnessLevel {
  high = "high",
  medium = "medium",
  low = "low",
}
export enum CombinedFitnessLevelParts {
  bodyFat = "bodyFat",
  musculature = "musculature",
}
export type CombinedFitnessLevel = {
  [k in CombinedFitnessLevelParts]: FitnessLevel
}
export const combinedFitnessLevel: Eq.Eq<CombinedFitnessLevel> = Eq.struct({
  [CombinedFitnessLevelParts.bodyFat]: Str.Eq,
  [CombinedFitnessLevelParts.musculature]: Str.Eq,
})

import * as Bool from "fp-ts/es6/boolean"
import * as Eq from "fp-ts/es6/Eq"
import * as Func from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Str from "fp-ts/es6/string"
import { Getter, Lens, Optional } from "monocle-ts"
import { Newtype, prism } from "newtype-ts"
import { duration, Time, time } from "time-ts/es6"
import { atPair } from "@fitnesspilot/data-common"

import {
  _withId,
  WithId,
  withIdEq,
} from "@fitnesspilot/data-activity/dist/activity/WithId"
import {
  ActivityInstance,
  activityInstance,
} from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"
import {
  Alignment,
  alignment,
} from "@fitnesspilot/data-human-body/dist/Alignment"

import { WorkoutVideo, workoutVideo } from "../video/WorkoutVideo"
import { EventSource, eventSource } from "./EventSource"
import { Recurrence, recurrence } from "./Recurrence"

export type EventId = Newtype<{ readonly EventId: unique symbol }, string>
export const stringAsEventId = prism<EventId>(Func.constTrue)
export const eventId: Ord.Ord<EventId> = Str.Ord as any

export enum EventType {
  activity = "activity",
  menu = "menu",
  work = "work",
  sleep = "sleep",
  catalog = "catalog",
}
export const eventType: Ord.Ord<EventType> = Str.Ord

export type Event = {
  type: EventType
  activities: ReadonlyArray<ActivityInstance>
  between: readonly [Time, Time]
  alignment: Alignment
  recurrence: Recurrence
  title: string
  source: Opt.Option<EventSource>
  video: Opt.Option<WorkoutVideo>
  isRecommendation: boolean
  confirmed: Opt.Option<Time>
}
export const event: Eq.Eq<Event> = Eq.struct({
  type: eventType,
  activities: Arr.getEq(activityInstance),
  between: Eq.tuple(time, time),
  alignment,
  recurrence,
  title: Str.Eq,
  source: Opt.getEq(eventSource),
  video: Opt.getEq(workoutVideo),
  isRecommendation: Bool.Eq,
  confirmed: Opt.getEq(time),
})
export const _type = Lens.fromProp<Event>()("type")
export const _activities = Lens.fromProp<Event>()("activities")
export const _between = Lens.fromProp<Event>()("between")
export const _start = _between.composeLens(atPair<Time>().at(0))
export const _end = _between.composeLens(atPair<Time>().at(1))
export const _duration = _between.composeGetter(
  new Getter(([a, b]) => duration.difference(a, b)),
)
export const _alignment = Lens.fromProp<Event>()("alignment")
export const _recurrence = Lens.fromProp<Event>()("recurrence")
export const _title = Lens.fromProp<Event>()("title")
export const _source = Lens.fromProp<Event>()("source")
export const _video = Lens.fromProp<Event>()("video")
export const _videoOpt = Optional.fromOptionProp<Event>()("video")
export const _isRecommendation = Lens.fromProp<Event>()("isRecommendation")
export const _confirmed = Lens.fromProp<Event>()("confirmed")

export type EventWithId = WithId<EventId, Event>
export const eventWithId = withIdEq<EventId, Event>(eventId, event)
export const _eventWithId = _withId<EventId, Event>(eventId)

export type RecommendationEvent = Omit<Event, "recurrence">

import { FC, Fragment, useEffect } from "react"

export type PrivacyPolicyProps = {
  title?: string
}

const useScript = () => {
  const script = document.createElement("script")
  script.src = "https://cdn.iubenda.com/iubenda.js"
  script.async = true

  document.body.appendChild(script)

  return () => {
    document.body.removeChild(script)
  }
}

export const PrivacyPolicy: FC<PrivacyPolicyProps> = ({
  title = "Privacy Policy",
}) => {
  useEffect(useScript)

  return (
    <Fragment>
      <a
        href="https://www.iubenda.com/privacy-policy/85006364"
        className="iubenda-white iubenda-embed"
        // className="iubenda-white iub-body-embed iub-no-markup iubenda-embed" // direct text embedding
        title={title}
      >
        {title}
      </a>
    </Fragment>
  )
}

import { FC, ReactNode } from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import * as Arr from "fp-ts/es6/Array"

export type Position = Array<"left" | "top" | "right" | "bottom">

type SectionProps = {
  readonly position?: Position
}

const Section = styled.section<SectionProps>`
  ${({ position }: SectionProps) =>
    position &&
    Arr.cons(
      css`
        position: fixed;
      `,
      position.map(
        (p) => css`
          ${p}: 0;
        `,
      ),
    )}
  margin: 10px;
  padding: 10px;
  background-color: ${({ theme }) => theme.card.backgroundColour.toString()};
  border-radius: 8px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  ${({ theme }) => theme.card.customCss}
`

export type CardProps = {
  position?: Position
  children: ReactNode
}

export const Card: FC<CardProps> = ({ position, children, ...props }) => (
  <Section position={position} {...props}>
    {children}
  </Section>
)

import { flow, pipe } from "fp-ts/es6/function"
import { Iso, Prism } from "monocle-ts"
import { dateAsTime, Day, Duration, mkDay } from "time-ts/es6"

import * as PROTO from "@fitnesspilot/proto/dist/index"

import { Temporal } from "@js-temporal/polyfill"

export const timestampAsTime = new Prism<PROTO.Timestamp, Temporal.Instant>(
  (a) => pipe(a.toDate(), dateAsTime.getOption),
  flow(dateAsTime.reverseGet, (d) => PROTO.Timestamp.fromDate(d)),
)

// NOTE: loss of precision
export const durationAsDuration = new Iso<PROTO.Duration, Duration>(
  (a) => {
    /** divisor to get from seconds to days */
    const divisor = 60n * 60n * 24n

    return Temporal.Duration.from({
      days: Math.trunc(Number(a.seconds / divisor)),
      milliseconds: Number(a.seconds % divisor),
      nanoseconds: a.nanos,
    }) as Duration
  },
  (a) =>
    new PROTO.Timestamp({
      seconds:
        BigInt(Math.trunc(a.total("day"))) * 60n * 60n * 24n +
        BigInt(Math.trunc(a.total("second"))),
      nanos: a.total("nanosecond"),
    }),
)

export const dateAsDay = new Prism<PROTO.Date, Day>(
  ({ year, month, day }) => mkDay(year, month, day),
  ({ year, month, day }) => new PROTO.Date({ year, month, day }),
)

// NOTE: loss of precision
/* export const timeOfDayAsTimeOfDay = new Prism<PROTO.TimeOfDay, TimeOfDay>(
  ({ hours, minutes, seconds, nanos }) => mkTimeOfDay(hours, minutes, seconds),
  (a) => pipe(unwrap(a), ({ hour, minute, second, microsecond, nanosecond }) => new PROTO.TimeOfDay({
    hours: hour,
    minutes: minute,
    seconds: second,
  })),
) */

import { ReactNode } from "react"
import { FaTimesCircle } from "react-icons/fa"
import styled from "@emotion/styled"

import * as IO from "fp-ts/es6/IO"

import { Clickable as Clickable_ } from "../Clickable/Clickable"

export type ChipProps = {
  className?: string
  icon?: ReactNode
  children: ReactNode
  selected: boolean
  showDelete: boolean
  onClick: IO.IO<void>
  onDelete: IO.IO<void>
}

type SpanProps = {
  selected: boolean
  children: ReactNode
  onClick: IO.IO<void>
}

const Span = styled.span<SpanProps>`
  all: unset;
  user-select: none;
  display: inline-flex;
  align-items: center;
  padding: 3px;
  font-size: 16px;
  border-radius: 14px;
  cursor: pointer;
  background-color: ${({ theme, selected }) =>
    selected
      ? theme.colours.grey["200"].toString()
      : theme.colours.grey["100"].toString()};
`

const Icon = styled.span`
  svg {
    display: block;
    font-size: 1.25em;
  }
`

const Value = styled.span`
  padding: 0 8px;
  font-size: 0.875em;
`

const Clickable = styled(Clickable_)`
  display: inline;
  font-size: 0.875em;
  margin-right: 5px;
`

export const Chip = styled(
  ({
    className,
    icon,
    children,
    selected,
    showDelete,
    onClick,
    onDelete,
  }: ChipProps) => (
    <Span className={`c-chip ${className ?? ""}`} {...{ selected, onClick }}>
      <Icon>{icon}</Icon>
      <Value>{children}</Value>
      {showDelete && (
        <Clickable onClick={onDelete}>
          <FaTimesCircle />
        </Clickable>
      )}
    </Span>
  ),
)``

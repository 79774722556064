import { ReactNode, useState } from "react"
import { FaPlus } from "react-icons/fa"
import { FormattedMessage, useIntl } from "react-intl"
import { Col, FormGroup } from "reactstrap"
import styled from "@emotion/styled"

import { constNull, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import { optionLensToOptional } from "@fitnesspilot/data-common"

import { Label } from "@fitnesspilot/components-common/dist/atoms/Label/Label"
import {
  Popover,
  PopoverBody,
} from "@fitnesspilot/components-common/dist/atoms/Popover/Popover"
import { Range as Range_ } from "@fitnesspilot/components-common/dist/atoms/Range/Range"
import {
  _ActivityInstanceExercise,
  _ActivityInstanceMuscles,
  ActivityInstance,
  ActivityInstanceNonGroup,
  foldActivityInstanceNonGroup,
} from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"
import * as ActivityInstanceExercise from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceExercise"
import * as ActivityInstanceMuscles from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstanceMuscles"
import { empty } from "@fitnesspilot/data-activity/dist/sport/Sport"
import { numberAsIntensity } from "@fitnesspilot/data-human-body/dist/Intensity"

const intensityOpt = foldActivityInstanceNonGroup(
  () =>
    Opt.some(
      _ActivityInstanceExercise.composeOptional(
        optionLensToOptional(ActivityInstanceExercise._intensity),
      ),
    ),
  () => Opt.none,
  () =>
    Opt.some(
      _ActivityInstanceMuscles.composeOptional(
        optionLensToOptional(ActivityInstanceMuscles._intensity),
      ),
    ),
  () => Opt.none,
)

const hintLabelFontSizeEm = 0.75
const HintLabel = styled.div`
  font-size: ${hintLabelFontSizeEm}em;
  display: flex;
  justify-content: center;
`

const Range = styled(Range_)`
  .rangeslider__label-item {
    min-width: ${hintLabelFontSizeEm * 3}em;
  }
`

const PlusIcon = styled(FaPlus)`
  color: ${({ theme }) => theme.colours.primary.toString()};
`

type HintProps = {
  children: ReactNode
  description: ReactNode
}

const Hint = ({ children, description }: HintProps) => {
  // local state to prevent ref leaking
  const [target, setTarget] = useState<HTMLDivElement | null>(null)
  const [popoverIsOpen, setPopoverIsOpen] = useState<boolean>(false)

  return (
    <>
      <HintLabel
        ref={setTarget}
        onMouseEnter={() => setPopoverIsOpen(true)}
        onMouseLeave={() => setPopoverIsOpen(false)}
      >
        {children}
      </HintLabel>
      {target && (
        <Popover
          placement="bottom"
          isOpen={popoverIsOpen}
          toggle={() => setPopoverIsOpen(!popoverIsOpen)}
          {...{ target }}
        >
          <PopoverBody>{description}</PopoverBody>
        </Popover>
      )}
    </>
  )
}

export type ActivityIntensityProps = {
  id: string
  activity: ActivityInstanceNonGroup
  onChange: (activity: ActivityInstanceNonGroup) => IO.IO<void>
}
export const ActivityIntensity = ({
  id,
  activity,
  onChange,
}: ActivityIntensityProps) => {
  const _intensityOpt = intensityOpt(activity)
  const intl = useIntl()

  return pipe(
    _intensityOpt,
    Opt.fold(constNull, (_intensityOpt) =>
      pipe(
        activity,
        _intensityOpt.getOption,
        Opt.getOrElse(() => empty.defaultIntensity),
        numberAsIntensity.reverseGet,
        (intensity) => (
          <FormGroup row>
            <Label for={id} xs={3} md={3}>
              <FormattedMessage defaultMessage="Intensity" />
            </Label>

            <Col xs={9} xl={8}>
              <Range
                value={intensity}
                labels={[
                  <Hint
                    key={0}
                    description={
                      <FormattedMessage defaultMessage="Avoid working out with intensities lower than 40 %. You can still track those activities if you want." />
                    }
                  >
                    <PlusIcon />
                  </Hint>,
                  <Hint
                    key={1}
                    description={
                      <FormattedMessage defaultMessage="Track an intensity of 40-60 % for easy activities and 60 % or more for a challenging one." />
                    }
                  >
                    <PlusIcon />
                    <PlusIcon />
                  </Hint>,
                  <Hint
                    key={2}
                    description={
                      <FormattedMessage defaultMessage="A good workout should target an 80-90 % intensity. Only go higher if you have enough experience with your limits." />
                    }
                  >
                    <PlusIcon />
                    <PlusIcon />
                    <PlusIcon />
                  </Hint>,
                ]}
                min={0}
                max={1}
                step={0.01}
                onChange={(newIntensity) =>
                  pipe(
                    activity,
                    _intensityOpt.set(
                      pipe(
                        newIntensity,
                        numberAsIntensity.getOption,
                        Opt.getOrElse(() => empty.defaultIntensity),
                      ),
                    ),
                    onChange,
                  )()
                }
                format={(value) =>
                  intl.formatMessage(
                    {
                      defaultMessage: "{value, number, percent}",
                    },
                    { value },
                  )
                }
              />
              <Label noColon>
                {intensity < 0.6 ? (
                  <FormattedMessage defaultMessage="Easy: talking and singing while exercising" />
                ) : intensity < 0.8 ? (
                  <FormattedMessage defaultMessage="Challenging: singing no longer possible" />
                ) : intensity < 0.9 ? (
                  <FormattedMessage defaultMessage="Exhausting: no more than saying a few words is possible" />
                ) : (
                  <FormattedMessage defaultMessage="Ludicrous: talking and moving not possible for a while" />
                )}
              </Label>
            </Col>
          </FormGroup>
        ),
      ),
    ),
  )
}

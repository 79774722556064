import { HTMLAttributes } from "react"
import styled from "@emotion/styled"

export type StickyFooterProps = HTMLAttributes<HTMLDivElement>

export const StickyFooter = styled.footer<{ bottom?: number }>`
  display: flex;
  justify-content: center;
  position: sticky;
  z-index: 1;
  bottom: ${({ bottom }) => (bottom ? `-${bottom}px` : 0)};
  padding: 10px;
  background: ${({ theme }) => String(theme.colours.white)};
  border-top: 1px solid ${({ theme }) => theme.colours.grey[100].toString()};
  gap: 10px;
`

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EventType = {
    Activity: "activity",
    Menu: "menu",
    Catalog: "catalog",
    Work: "work",
    Sleep: "sleep",
};
export function EventTypeFromJSON(json) {
    return EventTypeFromJSONTyped(json, false);
}
export function EventTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EventTypeToJSON(value) {
    return value;
}

import * as Eq from "fp-ts/es6/Eq"
import { Lens } from "monocle-ts"
import { Duration, duration } from "time-ts/es6"

import { ActivityId, activityId } from "../activity/ActivityId"
import { ActivitySleep, activitySleep } from "../activity/ActivitySleep"
import { WithId, withIdEq } from "../activity/WithId"

export type ActivityInstanceSleep = {
  activity: WithId<ActivityId, ActivitySleep>
  duration: Duration
}
export const activityInstanceSleep: Eq.Eq<ActivityInstanceSleep> = Eq.struct({
  activity: withIdEq(activityId, activitySleep),
  duration: duration,
})
export const fromActivitySleep = (
  activity: WithId<ActivityId, ActivitySleep>,
  duration: Duration,
): ActivityInstanceSleep => ({
  activity,
  duration,
})
export const _activity = Lens.fromProp<ActivityInstanceSleep>()("activity")
export const _duration = Lens.fromProp<ActivityInstanceSleep>()("duration")

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from "../runtime";
import { ActivityFromJSONTyped, ActivityToJSON, } from "./Activity";
import { ActivityCategoryFromJSON, ActivityCategoryToJSON, } from "./ActivityCategory";
import { ActivityTagFromJSON, ActivityTagToJSON, } from "./ActivityTag";
import { ActivityTypeFromJSON, ActivityTypeToJSON, } from "./ActivityType";
import { EquipmentFromJSON, EquipmentToJSON, } from "./Equipment";
import { MuscleShareFromJSON, MuscleShareToJSON, } from "./MuscleShare";
/**
 * Check if a given object implements the ActivityExercise interface.
 */
export function instanceOfActivityExercise(value) {
    let isInstance = true;
    isInstance = isInstance && "$type" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "tags" in value;
    return isInstance;
}
export function ActivityExerciseFromJSON(json) {
    return ActivityExerciseFromJSONTyped(json, false);
}
export function ActivityExerciseFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        ...ActivityFromJSONTyped(json, ignoreDiscriminator),
        $type: ActivityTypeFromJSON(json.$type),
        category: ActivityCategoryFromJSON(json.category),
        tags: json.tags.map(ActivityTagFromJSON),
        muscleShares: !exists(json, "muscleShares")
            ? undefined
            : json.muscleShares === null
                ? null
                : json.muscleShares.map(MuscleShareFromJSON),
        equipments: !exists(json, "equipments")
            ? undefined
            : json.equipments === null
                ? null
                : json.equipments.map(EquipmentFromJSON),
        isLiked: !exists(json, "isLiked") ? undefined : json.isLiked,
        isDenylisted: !exists(json, "isDenylisted") ? undefined : json.isDenylisted,
        lastInstance: !exists(json, "lastInstance")
            ? undefined
            : json.lastInstance === null
                ? null
                : new Date(json.lastInstance),
        frequency: !exists(json, "frequency") ? undefined : json.frequency,
    };
}
export function ActivityExerciseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...ActivityToJSON(value, true),
        $type: ActivityTypeToJSON(value.$type),
        category: ActivityCategoryToJSON(value.category),
        tags: value.tags.map(ActivityTagToJSON),
        muscleShares: value.muscleShares === undefined
            ? undefined
            : value.muscleShares === null
                ? null
                : value.muscleShares.map(MuscleShareToJSON),
        equipments: value.equipments === undefined
            ? undefined
            : value.equipments === null
                ? null
                : value.equipments.map(EquipmentToJSON),
        isLiked: value.isLiked,
        isDenylisted: value.isDenylisted,
        lastInstance: value.lastInstance === undefined
            ? undefined
            : value.lastInstance === null
                ? null
                : value.lastInstance.toISOString(),
        frequency: value.frequency,
    };
}

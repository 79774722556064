/* eslint @typescript-eslint/no-unsafe-argument: "warn"
   -- TODO eslint thinks some types are `any` even though they are not. */

import * as Arr_ from "fp-ts/es6/Array"
import * as Bool from "fp-ts/es6/boolean"
import { flow, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as St from "fp-ts/es6/ReadonlySet"
import * as Rec from "fp-ts/es6/Record"
import * as Str from "fp-ts/es6/string"
import * as Tree from "fp-ts/es6/Tree"
import { fromTraversable, Getter, Iso, Lens, Optional, Prism } from "monocle-ts"
import { prismPositive } from "newtype-ts/es6/Positive"
import {
  PositiveInteger,
  prismPositiveInteger,
} from "newtype-ts/es6/PositiveInteger"
import {
  dateAsTime,
  Duration,
  emptyDuration,
  isoLocalDuration,
  LocalDuration,
  mkDuration,
  mkLocalDuration,
} from "time-ts/es6"
import { match } from "ts-pattern"
import {
  Dimension,
  numberAsValidNumber,
  optionLensToOptional,
  prismToGetter,
  unsafeFromSome,
  validNumberAsPositive,
  valueInAnyUnit,
  valueInCanonicalUnit,
} from "@fitnesspilot/data-common"

import * as API from "@fitnesspilot/api-combined"
import * as Alignment from "@fitnesspilot/data-human-body/dist/Alignment"
import * as Intensity from "@fitnesspilot/data-human-body/dist/Intensity"
import * as Muscle from "@fitnesspilot/data-human-body/dist/Muscle"
import { muscleId_FromString } from "@fitnesspilot/data-human-body/dist/Muscle"
import * as MuscleGroup from "@fitnesspilot/data-human-body/dist/muscleGroups"
import { numberAsPriority } from "@fitnesspilot/data-human-body/dist/Priority"
import * as Workload from "@fitnesspilot/data-human-body/dist/Workload"

import * as Activity from "../activity/Activity"
import * as ActivityCategory from "../activity/ActivityCategory"
import * as ActivityExercise from "../activity/ActivityExercise"
import * as ActivityId from "../activity/ActivityId"
import * as ActivityJob from "../activity/ActivityJob"
import * as ActivityMuscleInfo from "../activity/ActivityMuscleInfo"
import * as ActivityMuscles from "../activity/ActivityMuscles"
import * as ActivitySleep from "../activity/ActivitySleep"
import * as ActivityTag from "../activity/ActivityTag"
import * as ActivityType from "../activity/ActivityType"
import * as Equipment from "../activity/Equipment"
import * as ExerciseSet from "../activity/ExerciseSet"
import * as MuscleShare from "../activity/MuscleShare"
import * as WithId from "../activity/WithId"
import * as ActivityInstance from "../activityInstance/ActivityInstance"
import * as ActivityInstanceExercise from "../activityInstance/ActivityInstanceExercise"
import * as ActivityInstanceGroup from "../activityInstance/ActivityInstanceGroup"
import * as ActivityInstanceJob from "../activityInstance/ActivityInstanceJob"
import * as ActivityInstanceMuscles from "../activityInstance/ActivityInstanceMuscles"
import * as ActivityInstanceSleep from "../activityInstance/ActivityInstanceSleep"
import * as ActivitySettingsExercise from "../activitySettings/ActivitySettingsExercise"
import * as ActivitySettingsJob from "../activitySettings/ActivitySettingsJob"
import * as ActivitySettingsMuscles from "../activitySettings/ActivitySettingsMuscles"
import * as ActivitySettingsSleep from "../activitySettings/ActivitySettingsSleep"
import * as ActivityWithSettings from "../activitySettings/ActivityWithSettings"
import * as ExerciseSlot from "../sport/ExerciseSlot"
import * as GeneralSportsSettings from "../sport/GeneralSportsSettings"
import * as MuscleSettings from "../sport/MuscleSettings"
import * as Sport from "../sport/Sport"
import * as SportId from "../sport/SportId"

const minutesAsDuration = new Iso<number, Duration>(
  (minutes) => mkDuration(0, minutes, 0),
  (s) => s.total({ unit: "minutes" }),
)

const equipmentType = new Iso<API.Equipment, Equipment.EquipmentType>(
  (s) => pipe(s.id, Equipment.stringAsEquipmentType.getOption, unsafeFromSome),
  (id) => ({ id, name: id }), // API should ignore name
)

const muscleShare = new Iso<API.MuscleShare, MuscleShare.MuscleShare>(
  (s) => ({
    muscleId: pipe(
      s.muscleId,
      Muscle.stringAsMuscleId.getOption,
      unsafeFromSome,
    ),
    intensity: pipe(
      s.intensity,
      Intensity.numberAsIntensity.getOption,
      unsafeFromSome,
    ),
  }),
  (a) => ({
    muscleId: pipe(a.muscleId, Muscle.stringAsMuscleId.reverseGet),
    intensity: pipe(a.intensity, Intensity.numberAsIntensity.reverseGet),
  }),
)

export const activityCategory = new Iso<
  API.ActivityCategory,
  ActivityCategory.ActivityCategory
>(
  (s) =>
    ({
      [API.ActivityCategory.RepsBodyWeight]:
        ActivityCategory.ActivityCategory.repsBodyWeight,
      [API.ActivityCategory.RepsExtraWeight]:
        ActivityCategory.ActivityCategory.repsExtraWeight,
      [API.ActivityCategory.Isometric]:
        ActivityCategory.ActivityCategory.isometric,
      [API.ActivityCategory.Generic]: ActivityCategory.ActivityCategory.generic,
      [API.ActivityCategory.Distance]:
        ActivityCategory.ActivityCategory.distance,
      [API.ActivityCategory.Stretching]:
        ActivityCategory.ActivityCategory.stretching,
    })[s],
  (a) =>
    ({
      [ActivityCategory.ActivityCategory.repsBodyWeight]:
        API.ActivityCategory.RepsBodyWeight,
      [ActivityCategory.ActivityCategory.repsExtraWeight]:
        API.ActivityCategory.RepsExtraWeight,
      [ActivityCategory.ActivityCategory.isometric]:
        API.ActivityCategory.Isometric,
      [ActivityCategory.ActivityCategory.generic]: API.ActivityCategory.Generic,
      [ActivityCategory.ActivityCategory.distance]:
        API.ActivityCategory.Distance,
      [ActivityCategory.ActivityCategory.stretching]:
        API.ActivityCategory.Stretching,
    })[a],
)

export const activityTag = new Iso<API.ActivityTag, ActivityTag.ActivityTag>(
  (s) =>
    ({
      [API.ActivityTag.Calisthenics]: ActivityTag.ActivityTag.calisthenics,
      [API.ActivityTag.Bodybuilding]: ActivityTag.ActivityTag.bodybuilding,
      [API.ActivityTag.Strength]: ActivityTag.ActivityTag.strength,
      [API.ActivityTag.Endurance]: ActivityTag.ActivityTag.endurance,
      [API.ActivityTag.StrengthEndurance]:
        ActivityTag.ActivityTag.strengthEndurance,
      [API.ActivityTag.Cardio]: ActivityTag.ActivityTag.cardio,
      [API.ActivityTag.Isometric]: ActivityTag.ActivityTag.isometric,
      [API.ActivityTag.Isotonic]: ActivityTag.ActivityTag.isotonic,
      [API.ActivityTag.Sport]: ActivityTag.ActivityTag.sport,
      [API.ActivityTag.Other]: ActivityTag.ActivityTag.other,
      [API.ActivityTag.Yoga]: ActivityTag.ActivityTag.yoga,
      [API.ActivityTag.Bodyshaping]: ActivityTag.ActivityTag.bodyshaping,
      [API.ActivityTag.Plyometric]: ActivityTag.ActivityTag.plyometric,
      [API.ActivityTag.Stretching]: ActivityTag.ActivityTag.stretching,
      [API.ActivityTag.Balance]: ActivityTag.ActivityTag.balance,
      [API.ActivityTag.Stability]: ActivityTag.ActivityTag.stability,
      [API.ActivityTag.Mobility]: ActivityTag.ActivityTag.mobility,
      [API.ActivityTag.Fascia]: ActivityTag.ActivityTag.fascia,
      [API.ActivityTag.Powerlifting]: ActivityTag.ActivityTag.powerlifting,
      [API.ActivityTag.Strongman]: ActivityTag.ActivityTag.strongman,
      [API.ActivityTag.Weightlifting]: ActivityTag.ActivityTag.weightlifting,
      [API.ActivityTag.PushUpVariations]:
        ActivityTag.ActivityTag.pushUpVariations,
      [API.ActivityTag.Core]: ActivityTag.ActivityTag.core,
      [API.ActivityTag.FullBody]: ActivityTag.ActivityTag.fullBody,
      [API.ActivityTag.Beginner]: ActivityTag.ActivityTag.beginner,
      [API.ActivityTag.Intermediate]: ActivityTag.ActivityTag.intermediate,
      [API.ActivityTag.Advanced]: ActivityTag.ActivityTag.advanced,
    })[s],
  (a) =>
    ({
      [ActivityTag.ActivityTag.calisthenics]: API.ActivityTag.Calisthenics,
      [ActivityTag.ActivityTag.bodybuilding]: API.ActivityTag.Bodybuilding,
      [ActivityTag.ActivityTag.strength]: API.ActivityTag.Strength,
      [ActivityTag.ActivityTag.endurance]: API.ActivityTag.Endurance,
      [ActivityTag.ActivityTag.strengthEndurance]:
        API.ActivityTag.StrengthEndurance,
      [ActivityTag.ActivityTag.cardio]: API.ActivityTag.Cardio,
      [ActivityTag.ActivityTag.isometric]: API.ActivityTag.Isometric,
      [ActivityTag.ActivityTag.isotonic]: API.ActivityTag.Isotonic,
      [ActivityTag.ActivityTag.sport]: API.ActivityTag.Sport,
      [ActivityTag.ActivityTag.other]: API.ActivityTag.Other,
      [ActivityTag.ActivityTag.yoga]: API.ActivityTag.Yoga,
      [ActivityTag.ActivityTag.bodyshaping]: API.ActivityTag.Bodyshaping,
      [ActivityTag.ActivityTag.plyometric]: API.ActivityTag.Plyometric,
      [ActivityTag.ActivityTag.stretching]: API.ActivityTag.Stretching,
      [ActivityTag.ActivityTag.balance]: API.ActivityTag.Balance,
      [ActivityTag.ActivityTag.stability]: API.ActivityTag.Stability,
      [ActivityTag.ActivityTag.mobility]: API.ActivityTag.Mobility,
      [ActivityTag.ActivityTag.fascia]: API.ActivityTag.Fascia,
      [ActivityTag.ActivityTag.powerlifting]: API.ActivityTag.Powerlifting,
      [ActivityTag.ActivityTag.strongman]: API.ActivityTag.Strongman,
      [ActivityTag.ActivityTag.weightlifting]: API.ActivityTag.Weightlifting,
      [ActivityTag.ActivityTag.pushUpVariations]:
        API.ActivityTag.PushUpVariations,
      [ActivityTag.ActivityTag.core]: API.ActivityTag.Core,
      [ActivityTag.ActivityTag.fullBody]: API.ActivityTag.FullBody,
      [ActivityTag.ActivityTag.beginner]: API.ActivityTag.Beginner,
      [ActivityTag.ActivityTag.intermediate]: API.ActivityTag.Intermediate,
      [ActivityTag.ActivityTag.advanced]: API.ActivityTag.Advanced,
    })[a],
)

const activityWithId = <a extends API.Activity>() =>
  new Iso<a, WithId.WithId<ActivityId.ActivityId, Omit<a, "activityId">>>(
    (s) => ({
      id: pipe(
        s,
        Lens.fromProp<API.Activity>()("activityId").composePrism(
          ActivityId.stringAsActivityId,
        ).getOption,
        unsafeFromSome,
      ),
      value: s,
    }),
    (a) =>
      ({
        ...a.value,
        activityId: pipe(
          a,
          WithId._id<
            ActivityId.ActivityId,
            Omit<API.Activity, "activityId">
          >().composeGetter(prismToGetter(ActivityId.stringAsActivityId)).get,
        ),
      }) as any,
  )

export const activityExercise = new Iso<
  Omit<API.ActivityExercise, "activityId" | "isLiked" | "isDenylisted">,
  ActivityExercise.ActivityExercise
>(
  (s) => ({
    createdBy: pipe(s.createdBy, Opt.fromNullable),
    title: s.title,
    instructions: pipe(s.instructions, Opt.fromNullable),
    category: pipe(s.category, activityCategory.get),
    tags: pipe(
      s.tags,
      Arr.map(activityTag.get),
      St.fromArray(ActivityTag.activityTag),
    ),
    muscleShares: pipe(
      s.muscleShares,
      Opt.fromNullable,
      Opt.getOrElse((): Array<API.MuscleShare> => []),
      Arr.map(muscleShare.get),
    ),
    equipments: pipe(
      s.equipments,
      Opt.fromNullable,
      Opt.getOrElse((): ReadonlyArray<API.Equipment> => []),
      Arr.map(equipmentType.get),
      St.fromArray(Equipment.equipmentType),
    ),
    lastInstance: pipe(
      s.lastInstance,
      Opt.fromNullable,
      Opt.chain(dateAsTime.getOption),
    ),
    frequency: pipe(
      s.frequency,
      Opt.fromNullable,
      Opt.chain(prismPositiveInteger.getOption),
    ),
  }),
  (a) => ({
    $type: API.ActivityType.Exercise,
    createdBy: pipe(a.createdBy, Opt.toUndefined),
    title: pipe(a, ActivityExercise._title.get),
    instructions: pipe(a, ActivityExercise._instructions.get, Opt.toNullable),
    muscleShares: pipe(
      a,
      ActivityExercise._muscleShares
        .composeTraversal(fromTraversable(Arr.Traversable)())
        .composeIso(muscleShare.reverse())
        .asFold().getAll,
    ),
    category: pipe(
      a,
      ActivityExercise._category.composeIso(activityCategory.reverse()).get,
    ),
    tags: [],
    // @TODO
    // tags: pipe(
    //   a,
    //   ActivityExercise._tags
    //     .composeTraversal(fromTraversable(St.ge))
    //     .composeIso(tag.reverse())
    //     .asFold().getAll,
    // ),
    lastInstance: pipe(
      a,
      ActivityExercise._lastInstance.get,
      Opt.map(dateAsTime.reverseGet),
      Opt.toUndefined,
    ),
    frequency: pipe(
      a,
      ActivityExercise._frequency.get,
      Opt.map(prismPositive.reverseGet),
      Opt.toUndefined,
    ),
  }),
)

export const activityJob = new Iso<
  Omit<API.ActivityJob, "activityId">,
  ActivityJob.ActivityJob
>(
  (s) => ({
    title: s.title,
    instructions: pipe(s.instructions, Opt.fromNullable),
    muscleShares: pipe(
      s.muscleShares,
      Opt.fromNullable,
      Opt.getOrElse((): Array<API.MuscleShare> => []),
      Arr.map(muscleShare.get),
    ),
  }),
  (a) => ({
    $type: API.ActivityType.Job,
    title: pipe(a, ActivityJob._title.get),
    instructions: pipe(a, ActivityJob._instructions.get, Opt.toNullable),
    muscleShares: pipe(
      a,
      ActivityJob._muscleShares
        .composeTraversal(fromTraversable(Arr.Traversable)())
        .composeIso(muscleShare.reverse())
        .asFold().getAll,
    ),
  }),
)

export const activitySleep = new Iso<
  Omit<API.ActivitySleep, "activityId">,
  ActivitySleep.ActivitySleep
>(
  (s) => ({
    title: s.title,
    instructions: pipe(s.instructions, Opt.fromNullable),
  }),
  (a) => ({
    $type: API.ActivityType.Sleep,
    title: pipe(a, ActivitySleep._title.get),
    instructions: pipe(a, ActivitySleep._instructions.get, Opt.toNullable),
  }),
)

export const activityMuscles = new Iso<
  Omit<API.ActivityMuscles, "activityId">,
  ActivityMuscles.ActivityMuscles
>(
  (s) => ({
    title: s.title,
    instructions: pipe(s.instructions, Opt.fromNullable),
  }),
  (a) => ({
    $type: API.ActivityType.Muscles,
    title: pipe(a, ActivityMuscles._title.get),
    instructions: pipe(a, ActivityMuscles._instructions.get, Opt.toNullable),
  }),
)

const modifyWithIdValue =
  <a, b>(f: (a: a) => b) =>
  <id>({ id, value }: WithId.WithId<id, a>) => ({
    id,
    value: f(value),
  })

export const activity = new Getter<
  API.Activity,
  WithId.WithId<ActivityId.ActivityId, Activity.Activity>
>(
  flow(
    activityWithId().get,
    modifyWithIdValue((s) =>
      s.$type === API.ActivityType.Exercise
        ? (
            activityExercise.composeGetter(
              prismToGetter(Activity._ActivityExercise),
            ) as Getter<Omit<API.Activity, "activityId">, Activity.Activity>
          ).get(s)
        : s.$type === API.ActivityType.Job
        ? activityJob
            .composeGetter(prismToGetter(Activity._ActivityJob))
            .get(s as API.ActivityJob)
        : s.$type === API.ActivityType.Muscles
        ? activityMuscles
            .composeGetter(prismToGetter(Activity._ActivityMuscles))
            .get(s as API.ActivityMuscles)
        : activitySleep
            .composeGetter(prismToGetter(Activity._ActivitySleep))
            .get(s as API.ActivitySleep),
    ),
  ),
)

export const exerciseSlot = new Iso<
  API.ExerciseSlot,
  ExerciseSlot.ExerciseSlot
>(
  (s) => ({
    start: pipe(s.start, dateAsTime.getOption, unsafeFromSome),
    end: pipe(s.end, dateAsTime.getOption, unsafeFromSome),
  }),
  (a) => ({
    start: dateAsTime.reverseGet(a.start),
    end: dateAsTime.reverseGet(a.end),
  }),
)

export type APIActivitySettingsExerciseSubtype = Pick<
  API.ActivityExercise,
  "isLiked" | "isDenylisted"
>
export const activitySettingsExercise = new Iso<
  APIActivitySettingsExerciseSubtype,
  ActivitySettingsExercise.ActivitySettingsExercise
>(
  (a) => ({
    listing: pipe(
      a,
      Optional.fromNullableProp<APIActivitySettingsExerciseSubtype>()("isLiked")
        .getOption,
      Opt.map(
        Bool.fold(
          () =>
            ActivitySettingsExercise.ActivitySettingsExerciseListing.default,
          () => ActivitySettingsExercise.ActivitySettingsExerciseListing.liked,
        ),
      ),
      Opt.alt(() =>
        pipe(
          a,
          Optional.fromNullableProp<APIActivitySettingsExerciseSubtype>()(
            "isDenylisted",
          ).getOption,
          Opt.map(
            Bool.fold(
              () =>
                ActivitySettingsExercise.ActivitySettingsExerciseListing
                  .default,
              () =>
                ActivitySettingsExercise.ActivitySettingsExerciseListing
                  .denylisted,
            ),
          ),
        ),
      ),
      Opt.getOrElse(
        (): ActivitySettingsExercise.ActivitySettingsExerciseListing =>
          ActivitySettingsExercise.ActivitySettingsExerciseListing.default,
      ),
    ),
  }),
  (s) => ({
    isLiked:
      s.listing ===
      ActivitySettingsExercise.ActivitySettingsExerciseListing.liked,
    isDenylisted:
      s.listing ===
      ActivitySettingsExercise.ActivitySettingsExerciseListing.denylisted,
  }),
)

export const muscleSettings = new Iso<
  Pick<API.MuscleGroup, "priority" | "enabled" | "name" | "id">,
  MuscleSettings.MuscleSettings
>(
  ({ priority, enabled, name, id }) => ({
    priority: pipe(
      priority,
      Opt.fromNullable,
      Prism.some<number>().composePrism(numberAsPriority).getOption,
      Opt.getOrElse(() => pipe(5, numberAsPriority.getOption, unsafeFromSome)),
    ),
    enabled: pipe(
      enabled,
      Opt.fromNullable,
      Opt.getOrElse((): boolean => false),
    ),
    name: name,
    id: pipe(
      id,
      (a) => a,
      muscleId_FromString.getOption,
      Opt.fold<
        Muscle.MuscleId_,
        Opt.Option<MuscleGroup.MuscleGroupId | Muscle.MuscleId_>
      >(() => MuscleGroup.stringAsMuscleGroupId.getOption(id), Opt.some),
      unsafeFromSome,
    ),
  }),
  ({ priority, enabled, name, id }) => ({
    priority: pipe(priority, numberAsPriority.reverseGet),
    enabled,
    name,
    id: id as string,
  }),
)

export const muscleSettingsTree = new Iso<
  ReadonlyArray<API.MuscleGroup>,
  Tree.Forest<MuscleSettings.MuscleSettings>
>(
  (ms) =>
    pipe(
      ms as Array<API.MuscleGroup>,
      Arr_.filter((m) => pipe(m.majorgroup, Opt.fromNullable, Opt.isNone)),
      Arr_.map((m) => m.id),
      (ids) =>
        Tree.unfoldForest(ids, (id) => [
          pipe(
            ms,
            Arr.findFirst((m) => m.id === id),
            // We already knows that it's in ms as all the IDs are from there in the first place
            unsafeFromSome,
          ),
          pipe(
            ms as Array<API.MuscleGroup>,
            Arr_.filter((m) => m.majorgroup === id),
            Arr_.map((m) => m.id),
          ),
        ]),
      Arr_.map(Tree.map(muscleSettings.get)),
    ),
  flow(
    Arr_.map(
      flow(
        Tree.map(muscleSettings.reverseGet),
        Tree.fold<
          Pick<API.MuscleGroup, "priority" | "enabled" | "name" | "id">,
          Array<
            Pick<API.MuscleGroup, "priority" | "enabled" | "name" | "id"> & {
              majorgroup: Opt.Option<string>
            }
          >
        >((a, as) =>
          pipe(
            as,
            Arr_.flatten,
            Arr_.map(({ majorgroup, ...vs }) => ({
              ...vs,
              majorgroup: pipe(
                majorgroup,
                Opt.alt(() => Opt.some(a.id)),
              ),
            })),
            Arr_.cons({
              ...a,
              majorgroup: Opt.none as Opt.Option<string>,
            }),
          ),
        ),
      ),
    ),
    Arr_.flatten,
    Arr_.map(({ majorgroup, ...a }) => ({
      ...a,
      majorgroup: pipe(majorgroup, Opt.toUndefined),
    })),
  ),
)

export const sport = new Iso<Omit<API.Sport, "id">, Sport.Sport>(
  (s) =>
    pipe(
      s,
      ({
        defaultIntensity,
        frequency,
        workoutMethod,
        workoutTarget,
        fixedSchedule,
        exerciseSlots,
      }) => ({
        name: pipe(s.name, Opt.fromNullable),
        instructions: pipe(s.instructions, Opt.fromNullable),
        isLiked: s.isLiked,
        defaultIntensity: pipe(
          defaultIntensity,
          Intensity.numberAsIntensity.getOption,
          unsafeFromSome,
        ),
        frequency,
        workoutMethod: pipe(workoutMethod, Opt.fromNullable),
        workoutTarget: pipe(workoutTarget, Opt.fromNullable),
        fixedSchedule,
        exerciseSlots: pipe(
          exerciseSlots,
          Opt.fromNullable,
          Prism.some<ReadonlyArray<API.ExerciseSlot>>()
            .composeTraversal(fromTraversable(Arr.Traversable)())
            .composeIso(exerciseSlot)
            .asFold().getAll,
        ),
      }),
    ),
  ({
    name,
    instructions,
    isLiked,
    defaultIntensity,
    frequency,
    workoutMethod,
    workoutTarget,
    fixedSchedule,
    exerciseSlots,
  }) => ({
    name: pipe(name, Opt.toUndefined),
    instructions: pipe(instructions, Opt.toUndefined),
    isLiked: isLiked,
    defaultIntensity: pipe(
      defaultIntensity,
      Intensity.numberAsIntensity.reverseGet,
    ),
    frequency,
    workoutMethod: pipe(workoutMethod, Opt.toUndefined),
    workoutTarget: pipe(workoutTarget, Opt.toUndefined),
    fixedSchedule,
    exerciseSlots: pipe(
      exerciseSlots,
      fromTraversable(Arr.Traversable)<ExerciseSlot.ExerciseSlot>()
        .composeIso(exerciseSlot.reverse())
        .asFold().getAll,
    ),
  }),
)

export const sportWithId = new Iso<API.Sport, Sport.SportWithId>(
  (s) => ({
    id: pipe(s.id, SportId.stringAsSportId.getOption, unsafeFromSome),
    value: sport.get(s),
  }),
  (a) => ({
    id: pipe(a.id, SportId.stringAsSportId.reverseGet),
    ...pipe(a.value, sport.reverseGet),
  }),
)

type APIActivitySettingsJobSubtype = Record<string, any>
export const activitySettingsJob = new Iso<
  APIActivitySettingsJobSubtype,
  ActivitySettingsJob.ActivitySettingsJob
>(
  () => ({}),
  () => ({}),
)

type APIActivitySettingsMusclesSubtype = Record<string, any>
export const activitySettingsMuscles = new Iso<
  APIActivitySettingsMusclesSubtype,
  ActivitySettingsMuscles.ActivitySettingsMuscles
>(
  () => ({}),
  () => ({}),
)

type APIActivitySettingsSleepSubtype = Record<string, any>
export const activitySettingsSleep = new Iso<
  APIActivitySettingsSleepSubtype,
  ActivitySettingsSleep.ActivitySettingsSleep
>(
  () => ({}),
  () => ({}),
)

export const activityWithSettingsWithId = new Iso<
  API.Activity,
  ActivityWithSettings.ActivityWithSettingsWithId
>(
  (a) =>
    pipe(
      a,
      activity.get,
      modifyWithIdValue(
        Activity.foldActivity<ActivityWithSettings.ActivityWithSettings>(
          (activity) => ({
            $type: ActivityType.ActivityType.exercise,
            activity,
            settings: activitySettingsExercise.get(
              a as any as API.ActivityExercise,
            ),
          }),
          (activity) => ({
            $type: ActivityType.ActivityType.job,
            activity,
            settings: activitySettingsJob.get(a),
          }),
          (activity) => ({
            $type: ActivityType.ActivityType.muscles,
            activity,
            settings: activitySettingsMuscles.get(a),
          }),
          (activity) => ({
            $type: ActivityType.ActivityType.sleep,
            activity,
            settings: activitySettingsSleep.get(a),
          }),
        ),
      ),
    ),
  (s) =>
    pipe(
      s,
      modifyWithIdValue(
        ActivityWithSettings.foldActivity<Omit<API.Activity, "activityId">>(
          (a, s) => ({
            ...activityExercise.reverseGet(a),
            ...activitySettingsExercise.reverseGet(s),
          }),
          (a, s) => ({
            ...activityJob.reverseGet(a),
            ...activitySettingsJob.reverseGet(s),
          }),
          (a, s) => ({
            ...activityMuscles.reverseGet(a),
            ...activitySettingsMuscles.reverseGet(s),
          }),
          (a, s) => ({
            ...activitySleep.reverseGet(a),
            ...activitySettingsSleep.reverseGet(s),
          }),
        ),
      ),
      activityWithId().reverseGet,
    ),
)

export const exerciseSet = new Iso<API.ModelSet, ExerciseSet.ExerciseSet>(
  (s) => ({
    repetitions: pipe(
      s,
      Optional.fromNullableProp<API.ModelSet>()("repetitions").composePrism(
        prismPositiveInteger,
      ).getOption,
      Opt.getOrElse(() => 1 as any as PositiveInteger),
    ),
    equipments: pipe(
      s,
      Optional.fromNullableProp<API.ModelSet>()("weight")
        .composePrism(
          numberAsValidNumber.composeIso(valueInCanonicalUnit(Dimension.mass)),
        )
        .composeGetter(prismToGetter(Equipment._AnyWeightMass)).getAll,
    ),
  }),
  (a) => ({
    repetitions: pipe(
      a,
      ExerciseSet._repetitions.composeGetter(
        prismToGetter(prismPositiveInteger),
      ).get,
    ),
    value: pipe(
      a,
      (a) =>
        ExerciseSet._equipments
          .composeTraversal(fromTraversable(Arr.Traversable)())
          .composePrism(Equipment._AnyWeightMass)
          .composeGetter(valueInAnyUnit(Dimension.mass))
          .composeGetter(prismToGetter(numberAsValidNumber))
          .headOption(a),
      Opt.toNullable,
    ),
  }),
)

export const activityMuscleInfo = new Iso<
  API.ActivityMuscleInfo,
  ActivityMuscleInfo.ActivityMuscleInfo
>(
  (s) => ({
    workload: pipe(
      s,
      Optional.fromNullableProp<API.ActivityMuscleInfo>()(
        "workload",
      ).composePrism(Workload.numberAsWorkload).getOption,
      unsafeFromSome,
    ),
    alignment: pipe(
      s,
      (e) =>
        Lens.fromProp<API.ActivityMuscleInfo>()("alignment")
          .composeGetter(new Getter((a) => a * 100))
          .composePrism(Alignment.numberAsAlignment)
          .headOption(e),
      unsafeFromSome,
    ),
  }),
  (a) => ({
    workload: pipe(
      a,
      ActivityMuscleInfo._workload.composeGetter(
        prismToGetter(Workload.numberAsWorkload),
      ).get,
    ),
    alignment: pipe(
      a,
      ActivityMuscleInfo._alignment
        .composeGetter(prismToGetter(Alignment.numberAsAlignment))
        .composeGetter(new Getter((a) => a / 100)).get,
    ),
  }),
)

export const activityMuscleInfoRecord = new Iso<
  { [x in string]: API.ActivityMuscleInfo },
  Record<Muscle.MuscleId_, ActivityMuscleInfo.ActivityMuscleInfo>
>(
  Rec.reduceWithIndex(Str.Ord)(
    {} as Record<Muscle.MuscleId_, ActivityMuscleInfo.ActivityMuscleInfo>,
    (k, r, mi) => ({
      ...r,
      [pipe(k, muscleId_FromString.getOption, unsafeFromSome)]:
        activityMuscleInfo.get(mi),
    }),
  ),
  Rec.reduceWithIndex(Str.Ord)(
    {} as Record<string, API.ActivityMuscleInfo>,
    (k, r, mi) => ({
      ...r,
      [pipe(k, muscleId_FromString.reverseGet)]:
        activityMuscleInfo.reverseGet(mi),
    }),
  ),
)

export const activityInstanceExercise = new Iso<
  API.ActivityInstanceExercise,
  ActivityInstanceExercise.ActivityInstanceExercise
>(
  (s) => ({
    activity: pipe(
      s,
      Lens.fromProp<API.ActivityInstanceExercise>()("activity").composeIso(
        activityWithId(),
      ).get,
      modifyWithIdValue(activityExercise.get),
    ),
    alignment: pipe(
      s,
      (e) =>
        Lens.fromProp<API.ActivityInstanceExercise>()("alignment")
          .composeGetter(new Getter((a) => a * 100))
          .composePrism(Alignment.numberAsAlignment)
          .headOption(e),
      unsafeFromSome,
    ),
    distance: pipe(s, (v) =>
      Optional.fromNullableProp<API.ActivityInstanceExercise>()("distance")
        .composePrism(numberAsValidNumber)
        .composePrism(validNumberAsPositive)
        .composeGetter(new Getter(validNumberAsPositive.reverseGet))
        .composeIso(valueInCanonicalUnit(Dimension.lengthDistance))
        .headOption(v),
    ),
    duration: pipe(
      s,
      Optional.fromNullableProp<API.ActivityInstanceExercise>()(
        "duration",
      ).composeIso(minutesAsDuration).getOption,
    ),
    // @TODO This prolly should have a default value
    importanceFactor: pipe(
      s,
      Optional.fromNullableProp<API.ActivityInstanceExercise>()(
        "importanceFactor",
      ).getOption,
    ),
    intensity: pipe(
      s,
      Optional.fromNullableProp<API.ActivityInstanceExercise>()(
        "intensity",
      ).composePrism(Intensity.numberAsIntensity).getOption,
    ),
    sets: pipe(
      s,
      Optional.fromNullableProp<API.ActivityInstanceExercise>()("sets")
        .composeTraversal(fromTraversable(Arr_.array)())
        .composeIso(exerciseSet)
        .asFold().getAll,
    ),
    muscles: pipe(
      s,
      Lens.fromProp<API.ActivityInstanceExercise>()("muscles").composeIso(
        activityMuscleInfoRecord,
      ).get,
    ),
  }),
  (a) => ({
    $type: API.ActivityType.Exercise,
    activity: pipe(
      a,
      ActivityInstanceExercise._activity.get,
      modifyWithIdValue(activityExercise.reverseGet),
      activityWithId<API.ActivityExercise>().reverseGet,
    ),
    alignment: pipe(
      a,
      ActivityInstanceExercise._alignment
        .composeGetter(prismToGetter(Alignment.numberAsAlignment))
        .composeGetter(new Getter((a) => a / 100)).get,
    ),
    distance: pipe(
      a,
      (v) =>
        optionLensToOptional(ActivityInstanceExercise._distance)
          .composeIso(valueInCanonicalUnit(Dimension.lengthDistance).reverse())
          .composeGetter(prismToGetter(numberAsValidNumber))
          .headOption(v),
      Opt.toNullable,
    ),
    duration: pipe(
      a,
      optionLensToOptional(ActivityInstanceExercise._duration).composeIso(
        minutesAsDuration.reverse(),
      ).getOption,
      Opt.toNullable,
    ),
    importanceFactor: pipe(
      a,
      optionLensToOptional(ActivityInstanceExercise._importanceFactor)
        .getOption,
      Opt.toNullable,
    ),
    intensity: pipe(
      a,
      (v) =>
        optionLensToOptional(ActivityInstanceExercise._intensity)
          .composeGetter(prismToGetter(Intensity.numberAsIntensity))
          .headOption(v),
      Opt.toNullable,
    ),
    sets: pipe(
      a,
      ActivityInstanceExercise._sets
        .composeTraversal(fromTraversable(Arr.Traversable)())
        .composeIso(exerciseSet.reverse())
        .asFold().getAll,
    ),
    muscles: pipe(
      a,
      ActivityInstanceExercise._muscles.composeIso(
        activityMuscleInfoRecord.reverse(),
      ).get,
    ),
  }),
)

export const activityInstanceSleep = new Iso<
  API.ActivityInstanceSleep,
  ActivityInstanceSleep.ActivityInstanceSleep
>(
  (s) => ({
    activity: pipe(
      s,
      Lens.fromProp<API.ActivityInstanceSleep>()("activity").composeIso(
        activityWithId(),
      ).get,
      modifyWithIdValue(activitySleep.get),
    ),
    duration: pipe(
      s,
      Lens.fromProp<API.ActivityInstanceSleep>()("duration").composeIso(
        minutesAsDuration,
      ).get,
    ),
  }),
  (a) => ({
    $type: API.ActivityType.Sleep,
    activity: pipe(
      a,
      ActivityInstanceSleep._activity.get,
      modifyWithIdValue(activitySleep.reverseGet),
      activityWithId<API.ActivitySleep>().reverseGet,
    ),
    duration: pipe(
      a,
      ActivityInstanceSleep._duration.composeIso(minutesAsDuration.reverse())
        .get,
    ),
  }),
)

export const activityInstanceMuscles = new Iso<
  API.ActivityInstanceMuscles,
  ActivityInstanceMuscles.ActivityInstanceMuscles
>(
  (s) => ({
    activity: pipe(
      s,
      Lens.fromProp<API.ActivityInstanceMuscles>()("activity").composeIso(
        activityWithId(),
      ).get,
      modifyWithIdValue(activityMuscles.get),
    ),
    alignment: pipe(s, (e) =>
      Optional.fromNullableProp<API.ActivityInstanceMuscles>()("alignment")
        .composeGetter(new Getter((a) => a * 100))
        .composePrism(Alignment.numberAsAlignment)
        .headOption(e),
    ),
    duration: pipe(
      s,
      Optional.fromNullableProp<API.ActivityInstanceMuscles>()(
        "duration",
      ).composeIso(minutesAsDuration).getOption,
    ),
    intensity: pipe(
      s,
      Optional.fromNullableProp<API.ActivityInstanceMuscles>()(
        "intensity",
      ).composePrism(Intensity.numberAsIntensity).getOption,
    ),
    muscleGroupIds: pipe(
      s,
      Optional.fromNullableProp<API.ActivityInstanceMuscles>()("muscleGroupIds")
        .composeTraversal(fromTraversable(Arr_.array)())
        .composePrism(MuscleGroup.stringAsMuscleGroupId)
        .asFold().getAll,
    ),
  }),
  (a) => ({
    $type: API.ActivityType.Muscles,
    activity: pipe(
      a,
      ActivityInstanceMuscles._activity.get,
      modifyWithIdValue(activityMuscles.reverseGet),
      activityWithId<API.ActivityMuscles>().reverseGet,
    ),
    alignment: pipe(
      a,
      (v) =>
        optionLensToOptional(ActivityInstanceMuscles._alignment)
          .composeGetter(prismToGetter(Alignment.numberAsAlignment))
          .composeGetter(new Getter((a) => a / 100))
          .headOption(v),
      Opt.toNullable,
    ),
    duration: pipe(
      a,
      optionLensToOptional(ActivityInstanceMuscles._duration).composeIso(
        minutesAsDuration.reverse(),
      ).getOption,
      Opt.toNullable,
    ),
    intensity: pipe(
      a,
      (v) =>
        optionLensToOptional(ActivityInstanceMuscles._intensity)
          .composeGetter(prismToGetter(Intensity.numberAsIntensity))
          .headOption(v),
      Opt.toNullable,
    ),
    muscleGroupIds: pipe(
      a,
      ActivityInstanceMuscles._muscleGroupIds
        .composeTraversal(fromTraversable(Arr.Traversable)())
        .composeGetter(prismToGetter(MuscleGroup.stringAsMuscleGroupId)).getAll,
    ),
  }),
)

export const activityInstanceJob = new Iso<
  API.ActivityInstanceJob,
  ActivityInstanceJob.ActivityInstanceJob
>(
  (s) => ({
    activity: pipe(
      s,
      Lens.fromProp<API.ActivityInstanceJob>()("activity").composeIso(
        activityWithId(),
      ).get,
      modifyWithIdValue(activityJob.get),
    ),
    duration: pipe(
      s,
      Lens.fromProp<API.ActivityInstanceJob>()("duration").composeIso(
        minutesAsDuration,
      ).get,
    ),
  }),
  (a) => ({
    $type: API.ActivityType.Job,
    activity: pipe(
      a,
      ActivityInstanceJob._activity.get,
      modifyWithIdValue(activityJob.reverseGet),
      activityWithId<API.ActivityJob>().reverseGet,
    ),
    duration: pipe(
      a,
      ActivityInstanceJob._duration.composeIso(minutesAsDuration.reverse()).get,
    ),
  }),
)

export const activityInstanceGroup: Iso<
  API.ActivityInstanceGroup,
  ActivityInstanceGroup.ActivityInstanceGroup
> = new Iso<
  API.ActivityInstanceGroup,
  ActivityInstanceGroup.ActivityInstanceGroup
>(
  (a) => ({
    activities: pipe(
      a.activities,
      Arr.fromArray,
      Arr.map(activityInstance.get),
    ),
    repetitions: a.repetitions,
    breakBetweenRepetitions: pipe(
      a.breakBetweenRepetitions,
      Opt.fromNullable,
      Opt.map(minutesAsDuration.get),
      Opt.getOrElse(() => emptyDuration),
    ),
  }),
  (a) => ({
    $type: API.ActivityType.Group,
    activities: pipe(
      a.activities,
      Arr.map(activityInstance.reverseGet),
      Arr.toArray,
    ),
    repetitions: a.repetitions,
    breakBetweenRepetitions: pipe(
      a.breakBetweenRepetitions,
      minutesAsDuration.reverseGet,
    ),
  }),
)

export const activityInstance = new Iso<
  API.ActivityInstance,
  ActivityInstance.ActivityInstance
>(
  (s) =>
    match(s)
      .with({ $type: API.ActivityType.Exercise }, () =>
        activityInstanceExercise
          .composeGetter(
            prismToGetter(ActivityInstance._ActivityInstanceExercise),
          )
          .get(s as API.ActivityInstanceExercise),
      )
      .with({ $type: API.ActivityType.Job }, () =>
        activityInstanceJob
          .composeGetter(prismToGetter(ActivityInstance._ActivityInstanceJob))
          .get(s as API.ActivityInstanceJob),
      )
      .with({ $type: API.ActivityType.Muscles }, () =>
        activityInstanceMuscles
          .composeGetter(
            prismToGetter(ActivityInstance._ActivityInstanceMuscles),
          )
          .get(s as API.ActivityInstanceMuscles),
      )
      .with({ $type: API.ActivityType.Sleep }, () =>
        activityInstanceSleep
          .composeGetter(prismToGetter(ActivityInstance._ActivityInstanceSleep))
          .get(s as API.ActivityInstanceSleep),
      )
      .with({ $type: API.ActivityType.Group }, () =>
        activityInstanceGroup
          .composeGetter(prismToGetter(ActivityInstance._ActivityInstanceGroup))
          .get(s as API.ActivityInstanceGroup),
      )
      .exhaustive(),
  ActivityInstance.foldActivityInstance<API.ActivityInstance>(
    activityInstanceExercise.reverseGet,
    activityInstanceJob.reverseGet,
    activityInstanceMuscles.reverseGet,
    activityInstanceSleep.reverseGet,
    activityInstanceGroup.reverseGet,
  ),
)

export const generalSportsSettings = new Iso<
  API.GeneralSportsPreferences,
  GeneralSportsSettings.GeneralSportsSettings
>(
  ({ enabled, maxSportsDays, maxCardioDays }) => ({
    enableExerciseRecommendations: enabled,
    maxExerciseDaysPerP1W: maxSportsDays,
    maxCardioDaysPerP1W: maxCardioDays,
  }),
  ({
    enableExerciseRecommendations,
    maxExerciseDaysPerP1W,
    maxCardioDaysPerP1W,
  }) => ({
    enabled: enableExerciseRecommendations,
    maxSportsDays: maxExerciseDaysPerP1W,
    maxCardioDays: maxCardioDaysPerP1W,
  }),
)

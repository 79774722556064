import * as Func from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Str from "fp-ts/es6/string"
import { Lens, Prism } from "monocle-ts"
import { Newtype, prism } from "newtype-ts"
import { Time } from "time-ts/es6"
import { explicitOrder } from "@fitnesspilot/data-common"

import { EventId } from "@fitnesspilot/data-event/dist/calendar/Event"

import * as CoachTaskType from "./CoachTaskType"

export enum CoachTaskImportance {
  info = "info",
  optional = "optional",
  recommended = "recommended",
  important = "important",
}
export const coachTaskImportance: Ord.Ord<CoachTaskImportance> = explicitOrder([
  CoachTaskImportance.important,
  CoachTaskImportance.recommended,
  CoachTaskImportance.optional,
  CoachTaskImportance.info,
])

export type CoachTaskId = Newtype<
  { readonly CoachTaskId: unique symbol },
  string
>
export const stringAsCoachTaskId = prism<CoachTaskId>(Func.constTrue)
export const coachTaskId: Ord.Ord<CoachTaskId> = Str.Ord as any

type BaseCoachTask = {
  id: CoachTaskId
  importance: CoachTaskImportance
  completedOn: Opt.Option<Time>
  dismissedOn: Opt.Option<Time>
}
export const _id = Lens.fromProp<BaseCoachTask>()("id")
export const _importance = Lens.fromProp<BaseCoachTask>()("importance")
export const _completedOn = Lens.fromProp<BaseCoachTask>()("completedOn")
export const _dismissedOn = Lens.fromProp<BaseCoachTask>()("dismissedOn")

export type CalendarCoachTask = BaseCoachTask & {
  type: CoachTaskType.CalendarCoachTaskType
  eventId: EventId
}
export const _calendarType = Lens.fromProp<CalendarCoachTask>()("type")
export const _calendarEventId = Lens.fromProp<CalendarCoachTask>()("eventId")

export type UserCoachTask = BaseCoachTask & {
  type: CoachTaskType.UserCoachTaskType
}
export const _userType = Lens.fromProp<UserCoachTask>()("type")

export type CoachTask = CalendarCoachTask | UserCoachTask
export const _type: Lens<CoachTask, CoachTaskType.CoachTaskType> =
  Lens.fromProp<CoachTask>()("type")
export const _CalendarCoachTask = Prism.fromPredicate(
  (ct: CoachTask): ct is CalendarCoachTask =>
    ct.type === CoachTaskType.CalendarCoachTaskType.eventConfirmation,
)
export const _UserCoachTask = Prism.fromPredicate(
  (ct: CoachTask): ct is UserCoachTask =>
    ct.type !== CoachTaskType.CalendarCoachTaskType.eventConfirmation,
)

import { MessageDescriptor } from "react-intl"

import * as Bool from "fp-ts/es6/boolean"
import { flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import { not } from "fp-ts/es6/Predicate"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as T from "fp-ts/es6/Task"
import * as TE from "fp-ts/es6/TaskEither"
import * as O from "fp-ts-reactive/es6/Observable"
import { arrayToObservable, withLatestFrom } from "@fitnesspilot/data-common"
import * as CommonData from "@fitnesspilot/data-common"

import {
  Action as APIAction,
  ParentState as APIParentState,
  setToken,
} from "@fitnesspilot/data-api"
import { UserError } from "@fitnesspilot/data-common/dist/error"
import { currentLocale } from "@fitnesspilot/data-common/dist/Locale"
import {
  AnyErrorAction,
  isAnyErrorAction,
} from "@fitnesspilot/data-common/dist/store/actions"
import * as UserData from "@fitnesspilot/data-user"

import * as fcm from "../fcm"
import { requestPermissions as requestGoogleApiPermissions } from "../googleApi"
import { Action, loadTranslationsAsync, setLocale } from "./actions"
import * as selectors from "./selectors"
import { ParentState } from "./state"

import "firebase/compat/auth"
import * as Sentry from "@sentry/react"
import { ActionCreator, AnyAction } from "redux"
import { RouterActions } from "redux-first-history"
import { combineEpics, Epic } from "redux-observable"
import { isActionOf, PayloadAction } from "typesafe-actions"

type AppAction = Action | CommonData.Action | UserData.Action

type ParentAPIState = ParentState & APIParentState

type EpicWithoutAPI = Epic<
  AppAction | APIAction | RouterActions | AnyErrorAction | AnyAction,
  AppAction | APIAction,
  ParentAPIState
>

export const initFlow: EpicWithoutAPI = (action$, state$) =>
  pipe(
    O.of(null),
    O.chain(() => O.fromIO(currentLocale)),
    O.map(setLocale),
  )

export const setLocaleFlow: EpicWithoutAPI = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(setLocale)),
    O.map((a) => a.payload),
    O.chain((locale) =>
      pipe(
        IO.Do,
        IO.map(() => Sentry.setTag("page.locale", locale)),
        IO.map(() => O.of(loadTranslationsAsync.request())),
      )(),
    ),
  )

export const loadTranslationsFlow: EpicWithoutAPI = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(loadTranslationsAsync.request)),
    O.map((a) => a.payload),
    withLatestFrom(state$),
    O.chain(([, state]) =>
      pipe(
        state,
        selectors.state.composeLens(selectors.locale).get,
        (locale) =>
          TE.tryCatch(
            () => import(`../translations/${locale}.json`),
            (a) => a as Error,
          ),
        TE.map((v) => v.default),
        TE.fold<Error, Record<string, MessageDescriptor>, Action>(
          flow(loadTranslationsAsync.failure, T.of),
          flow(loadTranslationsAsync.success, T.of),
        ),
        O.fromTask,
      ),
    ),
  )

export const setTokenFlow: EpicWithoutAPI = (
  action$,
  // state$,
) =>
  pipe(
    action$,
    O.filter(isActionOf(setToken)),
    O.map((a) => a.payload),
    O.map(
      Opt.fold<any, ReadonlyArray<UserData.Action>>(
        () => [],
        () => [UserData.requestFcmTokenAsync.request()],
      ),
    ),
    O.chain(arrayToObservable),
  )

export const requestFcmTokenFlow: EpicWithoutAPI = (
  action$,
  // state$,
) =>
  pipe(
    action$,
    O.filter(isActionOf(UserData.requestFcmTokenAsync.request)),
    O.map((a) => a.payload),
    O.chain(() =>
      pipe(
        TE.tryCatch(
          () => fcm.getToken(),
          (e) => e as Error,
        ),
        TE.fold<Error, string, ReadonlyArray<UserData.Action>>(
          flow(UserData.requestFcmTokenAsync.failure, Arr.of, T.of),
          flow(UserData.requestFcmTokenAsync.success, Arr.of, T.of),
        ),
        O.fromTask,
        O.chain(arrayToObservable),
      ),
    ),
  )

export const showGoogleOAuthPopupFlow: EpicWithoutAPI = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(UserData.showGoogleOAuthPopupAsync.request)),
    O.map((a) => a.payload),
    O.chain(
      flow(
        requestGoogleApiPermissions,
        T.fromIO,
        O.fromTask,
        O.chain(() =>
          pipe(UserData.showGoogleOAuthPopupAsync.success(), T.of, O.fromTask),
        ),
      ),
    ),
  )

const isRelevantErrorAction = (action: PayloadAction<string, any>) =>
  isAnyErrorAction(action) &&
  pipe(
    [
      loadTranslationsAsync.failure,
      UserData.requestFcmTokenAsync.failure,
      UserData.loginAsync.failure,
      UserData.signupAsync.failure,
      UserData.changePasswordAsync.failure,
    ],
    not(Arr.some((a) => isActionOf(a)(action))),
  )

export const catchErrors: Epic<
  CommonData.Action | AnyErrorAction,
  CommonData.Action,
  ParentAPIState
> = (
  action$,
  // state$,
) =>
  flow(
    O.filter(isAnyErrorAction),
    O.chain((action) =>
      pipe(
        action,
        isRelevantErrorAction,
        Bool.fold(
          () =>
            pipe(
              [
                CommonData.addError({
                  error: action.payload,
                  sentryEventId: Opt.none,
                }),
              ],
              arrayToObservable,
            ),
          () =>
            pipe(
              IO.Do,
              IO.map(() =>
                pipe(
                  action.payload,
                  Sentry.captureException,
                  Opt.fromNullable,
                  T.of,
                  O.fromTask,
                  O.chain((sentryEventId) =>
                    pipe(
                      [
                        CommonData.addError,
                        CommonData.setShownError,
                      ] as ReadonlyArray<
                        (error: UserError) => CommonData.Action
                      >,
                      Arr.map((f) =>
                        f({
                          error: action.payload,
                          sentryEventId,
                        }),
                      ),
                      arrayToObservable,
                    ),
                  ),
                ),
              ),
            )(),
        ),
      ),
    ),
  )(action$)

export const showErrorFeedbackDialogFlow: EpicWithoutAPI = (
  action$,
  // state$,
) =>
  flow(
    O.filter(isActionOf(CommonData.showErrorFeedbackDialog)),
    O.map((a) => a.payload),
    O.chain(({ sentryEventId }) =>
      pipe(
        pipe(
          IO.Do,
          IO.map(() => [] as ReadonlyArray<CommonData.Action>),
          IO.apFirst(() =>
            Sentry.showReportDialog({
              eventId: sentryEventId,
            }),
          ),
        )(),
        arrayToObservable,
      ),
    ),
  )(action$)

const epic = combineEpics<
  AppAction | APIAction | RouterActions | AnyAction,
  AppAction | APIAction | RouterActions,
  ParentAPIState
>(
  // @TODO remove cast if possible
  catchErrors as Epic<AppAction | AnyAction, AppAction, ParentAPIState>,
  initFlow,
  setLocaleFlow,
  loadTranslationsFlow,
  setTokenFlow,
  requestFcmTokenFlow,
  showGoogleOAuthPopupFlow,
  showErrorFeedbackDialogFlow,
)
export default epic

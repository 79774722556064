/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from "../runtime";
import { VideoSourceFromJSON, VideoSourceToJSON, } from "./VideoSource";
/**
 * Check if a given object implements the Video interface.
 */
export function instanceOfVideo(value) {
    let isInstance = true;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "channelId" in value;
    isInstance = isInstance && "channelTitle" in value;
    isInstance = isInstance && "publishedAt" in value;
    return isInstance;
}
export function VideoFromJSON(json) {
    return VideoFromJSONTyped(json, false);
}
export function VideoFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        source: VideoSourceFromJSON(json.source),
        id: json.id,
        title: json.title,
        description: json.description,
        channelId: json.channelId,
        channelTitle: json.channelTitle,
        publishedAt: new Date(json.publishedAt),
        thumbnailUrl: !exists(json, "thumbnailUrl") ? undefined : json.thumbnailUrl,
    };
}
export function VideoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        source: VideoSourceToJSON(value.source),
        id: value.id,
        title: value.title,
        description: value.description,
        channelId: value.channelId,
        channelTitle: value.channelTitle,
        publishedAt: value.publishedAt.toISOString(),
        thumbnailUrl: value.thumbnailUrl,
    };
}

// @generated by protoc-gen-es v1.3.0 with parameter "target=ts"
// @generated from file video/v1/workout_videos.proto (package video.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { WorkoutVideo } from "./workout_video_pb.js";

/**
 * @generated from message video.v1.ListVideosRequest
 */
export class ListVideosRequest extends Message<ListVideosRequest> {
  /**
   * search string to filter by
   *
   * @generated from field: string query = 1;
   */
  query = "";

  constructor(data?: PartialMessage<ListVideosRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "video.v1.ListVideosRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListVideosRequest {
    return new ListVideosRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListVideosRequest {
    return new ListVideosRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListVideosRequest {
    return new ListVideosRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListVideosRequest | PlainMessage<ListVideosRequest> | undefined, b: ListVideosRequest | PlainMessage<ListVideosRequest> | undefined): boolean {
    return proto3.util.equals(ListVideosRequest, a, b);
  }
}

/**
 * @generated from message video.v1.ListVideosResponse
 */
export class ListVideosResponse extends Message<ListVideosResponse> {
  /**
   * @generated from field: repeated video.v1.WorkoutVideo videos = 1;
   */
  videos: WorkoutVideo[] = [];

  constructor(data?: PartialMessage<ListVideosResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "video.v1.ListVideosResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "videos", kind: "message", T: WorkoutVideo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListVideosResponse {
    return new ListVideosResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListVideosResponse {
    return new ListVideosResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListVideosResponse {
    return new ListVideosResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListVideosResponse | PlainMessage<ListVideosResponse> | undefined, b: ListVideosResponse | PlainMessage<ListVideosResponse> | undefined): boolean {
    return proto3.util.equals(ListVideosResponse, a, b);
  }
}

/**
 * @generated from message video.v1.GetVideoRequest
 */
export class GetVideoRequest extends Message<GetVideoRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetVideoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "video.v1.GetVideoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetVideoRequest {
    return new GetVideoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetVideoRequest {
    return new GetVideoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetVideoRequest {
    return new GetVideoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetVideoRequest | PlainMessage<GetVideoRequest> | undefined, b: GetVideoRequest | PlainMessage<GetVideoRequest> | undefined): boolean {
    return proto3.util.equals(GetVideoRequest, a, b);
  }
}

/**
 * @generated from message video.v1.GetVideoResponse
 */
export class GetVideoResponse extends Message<GetVideoResponse> {
  /**
   * @generated from field: video.v1.WorkoutVideo video = 1;
   */
  video?: WorkoutVideo;

  constructor(data?: PartialMessage<GetVideoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "video.v1.GetVideoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "video", kind: "message", T: WorkoutVideo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetVideoResponse {
    return new GetVideoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetVideoResponse {
    return new GetVideoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetVideoResponse {
    return new GetVideoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetVideoResponse | PlainMessage<GetVideoResponse> | undefined, b: GetVideoResponse | PlainMessage<GetVideoResponse> | undefined): boolean {
    return proto3.util.equals(GetVideoResponse, a, b);
  }
}

/**
 * @generated from message video.v1.AddVideoRequest
 */
export class AddVideoRequest extends Message<AddVideoRequest> {
  /**
   * @generated from field: video.v1.WorkoutVideo video = 1;
   */
  video?: WorkoutVideo;

  constructor(data?: PartialMessage<AddVideoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "video.v1.AddVideoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "video", kind: "message", T: WorkoutVideo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddVideoRequest {
    return new AddVideoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddVideoRequest {
    return new AddVideoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddVideoRequest {
    return new AddVideoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddVideoRequest | PlainMessage<AddVideoRequest> | undefined, b: AddVideoRequest | PlainMessage<AddVideoRequest> | undefined): boolean {
    return proto3.util.equals(AddVideoRequest, a, b);
  }
}

/**
 * @generated from message video.v1.AddVideoResponse
 */
export class AddVideoResponse extends Message<AddVideoResponse> {
  /**
   * @generated from field: video.v1.WorkoutVideo video = 1;
   */
  video?: WorkoutVideo;

  constructor(data?: PartialMessage<AddVideoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "video.v1.AddVideoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "video", kind: "message", T: WorkoutVideo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddVideoResponse {
    return new AddVideoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddVideoResponse {
    return new AddVideoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddVideoResponse {
    return new AddVideoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddVideoResponse | PlainMessage<AddVideoResponse> | undefined, b: AddVideoResponse | PlainMessage<AddVideoResponse> | undefined): boolean {
    return proto3.util.equals(AddVideoResponse, a, b);
  }
}


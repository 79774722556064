import * as Eq from "fp-ts/es6/Eq"
import * as Opt from "fp-ts/es6/Option"
import * as Str from "fp-ts/es6/string"
import { Lens } from "monocle-ts"
import { url } from "@fitnesspilot/data-common"

export type Person = {
  image: Opt.Option<URL>
  givenName: Opt.Option<string>
  familyName: Opt.Option<string>
  name: Opt.Option<string>
}
export const person = Eq.struct<Person>({
  image: Opt.getEq(url),
  givenName: Opt.getEq(Str.Eq),
  familyName: Opt.getEq(Str.Eq),
  name: Opt.getEq(Str.Eq),
})
export const _image = Lens.fromProp<Person>()("image")
export const _givenName = Lens.fromProp<Person>()("givenName")
export const _familyName = Lens.fromProp<Person>()("familyName")
export const _name = Lens.fromProp<Person>()("name")

import { css, useTheme } from "@emotion/react"
import styled from "@emotion/styled"

import { PartialPartial } from "@fitnesspilot/data-common"

import { Colour } from "../ThemeProvider/Theme"

import colour from "color"

type Colours = {
  foreground: Colour
  background: Colour
  border: Colour
  icon: Colour
}

type ButtonProps = {
  name: string
  normal: PartialPartial<Colours, "border" | "icon">
  hover?: Partial<Colours>
  active?: Partial<Colours>
}

type ButtonSetProps = ButtonProps & {
  outline?: PartialPartial<
    Colours,
    "foreground" | "background" | "border" | "icon"
  >
}

type AlertProps = {
  name: string
  colour: Colour
  lighten?: number
}

const button = ({
  name,
  normal: { foreground, background, border = background, icon = foreground },
  hover: {
    foreground: hoverForeground = foreground,
    background: hoverBackground = background.alpha(0.8),
    border: hoverBorder = hoverBackground,
    icon: hoverIcon = icon,
  } = {},
  active: {
    foreground: activeForeground = hoverForeground,
    background: activeBackground = hoverBackground.alpha(0.9).darken(0.1),
    border: activeBorder = activeBackground,
    icon: activeIcon = icon,
  } = {},
}: ButtonProps) => css`
  .btn-${name} {
    color: ${foreground.toString()};
    background-color: ${background.toString()};
    border-color: ${border.toString()};

    & svg {
      color: ${icon.toString()};
    }

    &:hover,
    &:focus,
    &.focus {
      color: ${hoverForeground.toString()};
      background: ${hoverBackground.toString()};
      border-color: ${hoverBorder.toString()};

      & svg {
        color: ${hoverIcon.toString()};
      }
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: ${activeForeground.toString()};
        background: ${activeBackground.toString()};
        border-color: ${activeBorder.toString()};

        & svg {
          color: ${activeIcon.toString()};
        }
      }
    }
  }
`

const ButtonSet = ({ outline: outlineOverride, ...props }: ButtonSetProps) => {
  const theme = useTheme()
  const { name, normal } = props

  const outline: Colours = {
    foreground: outlineOverride?.foreground ?? theme.colours.grey[900],
    background: outlineOverride?.background ?? theme.colours.white,
    border: outlineOverride?.border ?? theme.colours.grey[200],
    icon: outlineOverride?.icon ?? normal.icon ?? normal.background,
  }

  return css`
    ${button(props)}
    ${button({
      name: `outline-${name}`,
      normal: outline,
      hover: {
        foreground: outline.foreground.alpha(0.8),
        background: outline.background,
        border: outline.border.alpha(0.8),
        icon: outline.icon.alpha(0.8),
      },
    })}
  `
}

const alert = ({ name, colour, lighten }: AlertProps) => css`
  .alert-${name} {
    font-weight: bold;
    color: ${colour.toString()};
    background-color: ${colour.lighten(lighten ?? 0.7).toString()};
    border-color: ${colour.toString()};
    margin-bottom: 0.5rem;
  }
`

export const BootstrapTheme = styled.div`
  display: contents;
  font-family: ${({ theme }) => theme.font.families.sansSerif};

  .btn {
    font-size: 13px;
    font-weight: 600;

    border-width: 2px;
    border-radius: 3px;
  }

  .btn:focus {
    box-shadow: none;
  }

  .btn:active,
  .btn.active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  ${({ theme }) =>
    ButtonSet({
      name: "primary",
      normal: {
        foreground: theme.colours.white,
        background: theme.colours.primary,
      },
      hover: {
        background: colour("#1298d9"),
      },
    })}

  ${({ theme }) =>
    ButtonSet({
      name: "secondary",
      normal: {
        foreground: theme.colours.grey[900],
        background: theme.colours.grey[200],
        border: theme.colours.grey[200],
        icon: theme.colours.primary,
      },
      hover: {
        background: theme.colours.grey[200].alpha(0.8),
      },
      outline: {
        foreground: theme.colours.grey[500],
      },
    })}

  ${({ theme }) =>
    ButtonSet({
      name: "success",
      normal: {
        foreground: theme.colours.white,
        background: theme.colours.success,
      },
      hover: {
        background: colour("#49d39d"),
      },
    })}

  ${({ theme }) =>
    ButtonSet({
      name: "warning",
      normal: {
        foreground: theme.colours.white,
        background: theme.colours.warning,
      },
      hover: {
        background: theme.colours.warning.alpha(0.8),
      },
    })}

  ${({ theme }) =>
    ButtonSet({
      name: "cancel",
      normal: {
        foreground: theme.colours.danger,
        background: colour("transparent"),
        border: colour("transparent"),
      },
      hover: {
        background: colour("transparent"),
      },
    })}

  ${({ theme }) =>
    ButtonSet({
      name: "grey",
      normal: {
        foreground: theme.colours.white,
        background: theme.colours.grey[200],
      },
      hover: {
        background: theme.colours.grey[200].alpha(0.8),
      },
      outline: {
        foreground: theme.colours.grey[900],
      },
    })}

  ${({ theme }) =>
    ButtonSet({
      name: "clear",
      normal: {
        foreground: theme.colours.grey[700],
        background: colour("transparent"),
        border: colour("transparent"),
      },
      active: {
        foreground: theme.colours.primary,
        background: colour("transparent"),
        border: colour("transparent"),
      },
      hover: {
        background: colour("transparent"),
      },
    })}

  .alert {
    padding: 0.4rem 1.25rem;
  }

  ${({ theme }) =>
    alert({
      name: "primary",
      colour: theme.colours.primary,
    })}

  ${({ theme }) =>
    alert({
      name: "success",
      colour: theme.colours.success,
    })}

  ${({ theme }) =>
    alert({
      name: "warning",
      colour: theme.colours.warning,
    })}

  ${({ theme }) =>
    alert({
      name: "danger",
      colour: theme.colours.danger,
      lighten: 0.55,
    })}

  .is-invalid + .invalid-feedback {
    position: absolute;
    right: 0px;
    transform: translateX(100%);
    text-align: center;
    top: 0px;
    background: #f9dede;
    border: 1px solid #dd5b57;
    border-radius: 0.25em;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;

    &::before {
      transform: rotate(45deg);
      position: absolute;
      background-color: #f9dede;
      border-radius: 0 0 0 4px;
      border: 1px solid #dd5b57;
      border-top: none;
      border-right: none;
      content: "";
      width: 14px;
      height: 14px;
      top: 9px;
      left: -7px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #22425c;
    font-weight: ${({ theme }) => theme.font.weights.bold};
  }

  h1 {
    font-size: 21px;
  }

  h2 {
    font-size: 18px;
  }

  .nav-link {
    padding: 0.5rem 0.5rem;

    @media (${({ theme }) => theme.breakpoints.sm}) {
      padding: 0.5rem 1rem;
    }
  }
`

import { FC, Fragment, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { FormattedMessage } from "react-intl"
import styled from "@emotion/styled"

import * as E from "fp-ts/es6/Either"
import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as Semigroup from "fp-ts/es6/Semigroup"
import { prismToGetter } from "@fitnesspilot/data-common"

import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import { CommonModal } from "@fitnesspilot/components-common/dist/organisms/CommonModal/CommonModal"
import {
  ControllerPlus,
  selectRender,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"
import { unsafeFromSome } from "@fitnesspilot/data-common/dist/type"
import * as Job from "@fitnesspilot/data-user/dist/Job"

const Activity = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 10px;
`

export type FormData = {
  job: Job.Job
}

export type WorkTypeModalProps = {
  id: string
  className?: string
  isOpen: boolean
  onSetIsOpen: (isOpen: boolean) => IO.IO<void>
  defaultValues: FormData
  onSubmit: (v: FormData) => void
  predefinedValues: NonEmpty.ReadonlyNonEmptyArray<Job.Job>
}

export const WorkTypeModal: FC<WorkTypeModalProps> = ({
  id,
  isOpen,
  onSetIsOpen,
  defaultValues,
  onSubmit,
  predefinedValues,
  ...props
}) => {
  const { handleSubmit, ...form } = useForm<FormData>({
    defaultValues,
  })

  useEffect(() => {
    if (!form.formState.isDirty) {
      form.reset(defaultValues)
    }
  }, [defaultValues])

  return (
    <FormProvider {...{ handleSubmit }} {...form}>
      <CommonModal
        onToggle={(open) => onSetIsOpen(open)()}
        onSave={Opt.some(handleSubmit(onSubmit))}
        title={<FormattedMessage defaultMessage="Job" />}
        {...{ isOpen }}
        {...props}
      >
        <p>
          <FormattedMessage defaultMessage="Your profession will help us to prepare better recommendations regarding nutrition and physical activities." />
        </p>

        <Fieldset>
          <ControllerPlus<FormData, "job", string>
            render={selectRender<string, "radio-multi-line">()({
              id: `${id}-job`,
              type: "radio-multi-line",
              values: pipe(
                predefinedValues,
                NonEmpty.map((v) => {
                  const vId = pipe(
                    v,
                    Job._id.composeGetter(prismToGetter(Job.stringAsJobId)).get,
                  )
                  const inpName = `${id}-predefinedWorkType`

                  return [
                    vId,
                    <Fragment key={1}>
                      <div>
                        <strong>{Job._name.get(v)}</strong>
                      </div>
                      {pipe(
                        v,
                        Job._instructions.get,
                        Opt.map((v) => <div key={0}>{v}</div>),
                        Opt.toNullable,
                      )}
                      <Activity>
                        <FormattedMessage
                          defaultMessage="Physical activity: {value, number}"
                          values={{
                            value: Job._physicalActivity.get(v),
                          }}
                          tagName="div"
                        />
                        <FormattedMessage
                          defaultMessage="Mental activity: {value, number}"
                          values={{
                            value: Job._mentalActivity.get(v),
                          }}
                          tagName="div"
                        />
                      </Activity>
                    </Fragment>,
                  ] as const
                }),
                Rec.fromFoldable(Semigroup.first(), NonEmpty.Foldable),
              ),
            })}
            name="job"
            transform={{
              input: Job._id.composeGetter(prismToGetter(Job.stringAsJobId))
                .get,
              output: (id) =>
                pipe(
                  predefinedValues,
                  Arr.findFirst((j) =>
                    pipe(
                      j,
                      Job._id.composeGetter(prismToGetter(Job.stringAsJobId))
                        .get,
                      (id2) => id === id2,
                    ),
                  ),
                  unsafeFromSome,
                  E.right,
                ),
            }}
          />
        </Fieldset>
      </CommonModal>
    </FormProvider>
  )
}

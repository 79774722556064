import { FC } from "react"
import { IntlShape, useIntl } from "react-intl"

import {
  Dimension,
  Unit,
  UnitLengthDistance,
  UnitLengthHeight,
  UnitMass,
  UnitNoDim,
  UnitSpeed,
} from "@fitnesspilot/data-common/dist/dimensionalAnalysis"

import { formatDynamicMessage } from "../IntlProvider/IntlProvider"

const names: { [d in Dimension]: Record<Unit<d>, string> } = {
  [Dimension.noDim]: {
    [UnitNoDim.one]: "",
    [UnitNoDim.percent]: "Percent",
  },
  [Dimension.mass]: {
    [UnitMass.kilogram]: "Kilogram",
    [UnitMass.pound]: "Pound",
  },
  [Dimension.lengthHeight]: {
    [UnitLengthHeight.centimetre]: "Centimetre",
    [UnitLengthHeight.footAndInch]: "Foot and inch",
  },
  [Dimension.lengthDistance]: {
    [UnitLengthDistance.kilometre]: "Kilometre",
    [UnitLengthDistance.mile]: "Mile",
  },
  [Dimension.speed]: {
    [UnitSpeed.kmh]: "km/h",
    [UnitSpeed.mih]: "mi/h",
  },
}

export type UnitNameProps<d extends Dimension> = {
  dimension: d
  unit: Unit<d>
}

export const unitName =
  (intl: IntlShape) =>
  <d extends Dimension>({ dimension, unit }: UnitNameProps<d>) =>
    formatDynamicMessage(intl)({
      id: `UnitName.${dimension}.${unit}`,
      defaultMessage: (names[dimension] as Record<Unit<d>, string>)[unit],
    })

export const UnitName: FC<UnitNameProps<Dimension>> = (props) => (
  <>{unitName(useIntl())(props)}</>
)

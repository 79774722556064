/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JsonPatchDocument1PhotoDownloadToJSON, PhotoDownloadFromJSON, } from "../models";
import * as runtime from "../runtime";
/**
 *
 */
export class PhotosApi extends runtime.BaseAPI {
    /**
     */
    async userManagementApiUsersUserIdPhotosGetRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling userManagementApiUsersUserIdPhotosGet.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/users/{userId}/photos`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PhotoDownloadFromJSON));
    }
    /**
     */
    async userManagementApiUsersUserIdPhotosGet(requestParameters, initOverrides) {
        const response = await this.userManagementApiUsersUserIdPhotosGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async userManagementApiUsersUserIdPhotosPhotoIdGetRaw(requestParameters, initOverrides) {
        if (requestParameters.photoId === null ||
            requestParameters.photoId === undefined) {
            throw new runtime.RequiredError("photoId", "Required parameter requestParameters.photoId was null or undefined when calling userManagementApiUsersUserIdPhotosPhotoIdGet.");
        }
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling userManagementApiUsersUserIdPhotosPhotoIdGet.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/users/{userId}/photos/{photoId}`
                .replace(`{${"photoId"}}`, encodeURIComponent(String(requestParameters.photoId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoDownloadFromJSON(jsonValue));
    }
    /**
     */
    async userManagementApiUsersUserIdPhotosPhotoIdGet(requestParameters, initOverrides) {
        const response = await this.userManagementApiUsersUserIdPhotosPhotoIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async userManagementApiUsersUserIdPhotosPhotoIdPatchRaw(requestParameters, initOverrides) {
        if (requestParameters.photoId === null ||
            requestParameters.photoId === undefined) {
            throw new runtime.RequiredError("photoId", "Required parameter requestParameters.photoId was null or undefined when calling userManagementApiUsersUserIdPhotosPhotoIdPatch.");
        }
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling userManagementApiUsersUserIdPhotosPhotoIdPatch.");
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters["Content-Type"] = "application/json";
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/users/{userId}/photos/{photoId}`
                .replace(`{${"photoId"}}`, encodeURIComponent(String(requestParameters.photoId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "PATCH",
            headers: headerParameters,
            query: queryParameters,
            body: JsonPatchDocument1PhotoDownloadToJSON(requestParameters.jsonPatchDocument1PhotoDownload),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoDownloadFromJSON(jsonValue));
    }
    /**
     */
    async userManagementApiUsersUserIdPhotosPhotoIdPatch(requestParameters, initOverrides) {
        const response = await this.userManagementApiUsersUserIdPhotosPhotoIdPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async userManagementApiUsersUserIdPhotosPostRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling userManagementApiUsersUserIdPhotosPost.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const consumes = [
            { contentType: "multipart/form-data" },
        ];
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.file !== undefined) {
            formParams.append("File", requestParameters.file);
        }
        if (requestParameters.dateCreated !== undefined) {
            formParams.append("DateCreated", requestParameters.dateCreated);
        }
        const response = await this.request({
            path: `/userManagement/api/users/{userId}/photos`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoDownloadFromJSON(jsonValue));
    }
    /**
     */
    async userManagementApiUsersUserIdPhotosPost(requestParameters, initOverrides) {
        const response = await this.userManagementApiUsersUserIdPhotosPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async userManagementApiUsersUserIdPhotosProfilePhotoDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling userManagementApiUsersUserIdPhotosProfilePhotoDelete.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/users/{userId}/photos/profilePhoto`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async userManagementApiUsersUserIdPhotosProfilePhotoDelete(requestParameters, initOverrides) {
        await this.userManagementApiUsersUserIdPhotosProfilePhotoDeleteRaw(requestParameters, initOverrides);
    }
    /**
     */
    async userManagementApiUsersUserIdPhotosProfilePhotoGetRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling userManagementApiUsersUserIdPhotosProfilePhotoGet.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/users/{userId}/photos/profilePhoto`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        if (this.isJsonMime(response.headers.get("content-type"))) {
            return new runtime.JSONApiResponse(response);
        }
        else {
            return new runtime.TextApiResponse(response);
        }
    }
    /**
     */
    async userManagementApiUsersUserIdPhotosProfilePhotoGet(requestParameters, initOverrides) {
        const response = await this.userManagementApiUsersUserIdPhotosProfilePhotoGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async userManagementApiUsersUserIdPhotosProfilePhotoPutRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling userManagementApiUsersUserIdPhotosProfilePhotoPut.");
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters["Content-Type"] = "application/json";
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/userManagement/api/users/{userId}/photos/profilePhoto`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async userManagementApiUsersUserIdPhotosProfilePhotoPut(requestParameters, initOverrides) {
        await this.userManagementApiUsersUserIdPhotosProfilePhotoPutRaw(requestParameters, initOverrides);
    }
}

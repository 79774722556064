/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from "../runtime";
import { BodyDataFromJSON, BodyDataToJSON, } from "./BodyData";
import { SexFromJSON, SexToJSON } from "./Sex";
import { UserHabitsFromJSON, UserHabitsToJSON, } from "./UserHabits";
import { UserSleepFromJSON, UserSleepToJSON, } from "./UserSleep";
import { UserWorkFromJSON, UserWorkToJSON, } from "./UserWork";
/**
 * Check if a given object implements the ApiUserData interface.
 */
export function instanceOfApiUserData(value) {
    const isInstance = true;
    return isInstance;
}
export function ApiUserDataFromJSON(json) {
    return ApiUserDataFromJSONTyped(json, false);
}
export function ApiUserDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        sex: !exists(json, "sex") ? undefined : SexFromJSON(json.sex),
        birthDate: !exists(json, "birthDate")
            ? undefined
            : json.birthDate === null
                ? null
                : new Date(json.birthDate),
        bodyData: !exists(json, "bodyData")
            ? undefined
            : BodyDataFromJSON(json.bodyData),
        work: !exists(json, "work") ? undefined : UserWorkFromJSON(json.work),
        sleep: !exists(json, "sleep") ? undefined : UserSleepFromJSON(json.sleep),
        habits: !exists(json, "habits")
            ? undefined
            : UserHabitsFromJSON(json.habits),
    };
}
export function ApiUserDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        sex: SexToJSON(value.sex),
        birthDate: value.birthDate === undefined
            ? undefined
            : value.birthDate === null
                ? null
                : value.birthDate.toISOString().substr(0, 10),
        bodyData: BodyDataToJSON(value.bodyData),
        work: UserWorkToJSON(value.work),
        sleep: UserSleepToJSON(value.sleep),
        habits: UserHabitsToJSON(value.habits),
    };
}

import { FC, ReactNode } from "react"

import { Range, RangeProps } from "../../atoms/Range/Range"

type MapConst<as, b> = { [k in keyof as]: b }
export type EnumRangeProps<as extends Array<any>> = {
  value: as[number]
  onChange: (value: as[number]) => void
} & Omit<
  RangeProps,
  "min" | "max" | "step" | "value" | "tooltip" | "labels" | "onChange"
>

export const enumRange =
  <as extends Array<any>>(
    values: as,
    labels: MapConst<as, ReactNode>,
  ): FC<EnumRangeProps<as>> =>
  ({ value, onChange, ...props }) => (
    <Range
      min={0}
      max={values.length - 1}
      step={1}
      value={values.indexOf(value)}
      tooltip={false}
      onChange={(v: keyof as) => onChange(values[v])}
      {...{ labels }}
      {...props}
    />
  )

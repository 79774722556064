import { flow, pipe } from "fp-ts/es6/function";
import * as IO from "fp-ts/es6/IO";
import * as Opt from "fp-ts/es6/Option";
import { Getter, Iso, Lens, Prism } from "monocle-ts";
import { ioTsPrism } from "../internal";
import { currentTimeZone, isoStringAsLocalTimeOfDay, localTimeFromDayAndLocalTimeOfDay, localTimeOfDay, localTimeOfDayDuration, localTimeOfDayT, mkLocalTimeOfDay, mkLocalTimeOfDayWrapped, } from "../Local";
import { today } from "../UTC";
import * as ZonedTime from "./Time";
import * as Zoned from "./Zoned";
import { Temporal } from "@js-temporal/polyfill";
import * as t from "io-ts";
export const _value = Zoned._value();
export const _timeZone = new Getter((s) => s.timeZone);
export const mkZonedLocalTimeOfDay = (timeZone) => (value) => Zoned.mkZoned(timeZone)(value);
export const mkZonedTimeOfDayWrapped = (timeZone) => flow(mkLocalTimeOfDayWrapped, mkZonedLocalTimeOfDay(timeZone));
export const mkZonedTimeOfDay = (timeZone) => flow(mkLocalTimeOfDay, Opt.map(mkZonedLocalTimeOfDay(timeZone)));
export const zonedTimeOfDay = Zoned.zoned(localTimeOfDay);
export const equals = Zoned.equals(localTimeOfDay);
export const compare = Zoned.compare(localTimeOfDay);
export const zonedTimeOfDayOfZonedTime = new Lens((s) => Zoned.mkZoned(s.getTimeZone())(s.toPlainTime()), (timeOfDay) => (time) => time.withTimeZone(timeOfDay.timeZone).withPlainTime(timeOfDay.value));
export const currentZonedTimeOfDay = (timeZone) => IO.io.map(ZonedTime.zonedNow(timeZone), zonedTimeOfDayOfZonedTime.get);
export const stringAsZonedTimeOfDay = new Prism(flow((s) => /^(?<time>.*) *\[(?<timeZone>.*?)\]$/.exec(s), Opt.fromNullable, Opt.chain(({ groups: { time, timeZone } }) => pipe(timeZone, (tz) => Opt.tryCatch(() => new Temporal.TimeZone(tz)), Opt.map(Zoned.mkZoned), Opt.ap(pipe(time, isoStringAsLocalTimeOfDay.getOption))))), ({ value, timeZone }) => `${isoStringAsLocalTimeOfDay.reverseGet(value)}[${timeZone.id}]`);
export const zonedTimeOfDayT = t.string.pipe(ioTsPrism("ZonedTimeOfDay", 
// @TODO
(a) => localTimeOfDayT.is(a), stringAsZonedTimeOfDay));
export const zonedTimeFromDayAndZonedTimeOfDay = (d, tod) => pipe(localTimeFromDayAndLocalTimeOfDay(d, tod.value), ZonedTime.mkZonedLocalTime(tod.timeZone));
export const zonedTimeAsDayAndZonedTimeOfDayPair = new Iso((t) => [ZonedTime.dayOfZonedTime.get(t), zonedTimeOfDayOfZonedTime.get(t)], ([d, tod]) => zonedTimeFromDayAndZonedTimeOfDay(d, tod));
const zero = (timeZone) => mkZonedTimeOfDayWrapped(timeZone)(0, 0, 0);
export const zonedStartOfDay = (timeZone) => (d) => zonedTimeFromDayAndZonedTimeOfDay(d, zero(timeZone));
export const zonedStartOfToday = (timeZone) => pipe(today, IO.map(zonedStartOfDay(timeZone)));
export const zonedStartOfDayInCurrentTimeZone = (d) => pipe(currentTimeZone, IO.map((tz) => zonedStartOfDay(tz)(d)));
export const zonedStartOfTodayInCurrentTimeZone = pipe(today, IO.chain(zonedStartOfDayInCurrentTimeZone));
export const zonedTimeOfDayDuration = Object.assign(Object.assign({}, localTimeOfDayDuration), { add: (t, d) => Zoned.mkZoned(t.timeZone)(localTimeOfDayDuration.add(t.value, d)), difference: (t, t2) => {
        // @TODO Is considering the tz even possible here?
        console.warn("zonedTimeOfDayDuration.difference: currently ignoring the time zone");
        return localTimeOfDayDuration.difference(t.value, t2.value);
    } });

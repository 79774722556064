import { flow } from "fp-ts/es6/function"
import * as O from "fp-ts-reactive/es6/Observable"

import {
  acceptCookieConsent,
  Action,
  hideCookieConsent,
  setCookiesChecked,
  showCookieConsent,
} from "./actions"
import { ParentState } from "./state"

import { combineEpics, Epic } from "redux-observable"
import { isActionOf } from "typesafe-actions"

type AnEpic = Epic<Action, Action, ParentState>

export const acceptCookiesFlow: AnEpic = (
  action$,
  // state$,
) =>
  flow(
    O.filter(isActionOf(acceptCookieConsent)),
    O.map(() => hideCookieConsent()),
  )(action$)

export const editCookiesFlow: AnEpic = (action$, state$) =>
  flow(
    O.filter(isActionOf(showCookieConsent)),
    O.map(() =>
      setCookiesChecked(state$.value.legal.cookieConsent.acceptedCookies, true),
    ),
  )(action$)

const epic: AnEpic = combineEpics(acceptCookiesFlow, editCookiesFlow)
export default epic

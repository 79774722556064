/* eslint @typescript-eslint/no-unsafe-argument: "warn"
   -- TODO eslint thinks some types are `any` even though they are not. */

import * as Arr_ from "fp-ts/es6/Array"
import * as Foldable from "fp-ts/es6/Foldable"
import { constFalse, flow, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as Semigroup from "fp-ts/es6/Semigroup"
import * as Str from "fp-ts/es6/string"
import { fromTraversable, Getter, Iso, Lens, Optional } from "monocle-ts"
import { Positive, prismPositive } from "newtype-ts/es6/Positive"
import { prismPositiveInteger } from "newtype-ts/es6/PositiveInteger"
import {
  _localDurationDays,
  _localDurationMonths,
  _localDurationWeeks,
  dateAsTime,
  Duration,
  isoStringAsTime,
  mkLocalDuration,
} from "time-ts/es6"
import { match } from "ts-pattern"
import {
  Dimension,
  mapKeys,
  numberAsValidNumber,
  optionLensToOptional,
  prismToGetter,
  UnitNoDim,
  unsafeFromSome,
  unwrap,
  valueInUnit,
} from "@fitnesspilot/data-common"

import * as API from "@fitnesspilot/api-combined"
import * as WithId from "@fitnesspilot/data-activity/dist/activity/WithId"
import { activityInstance } from "@fitnesspilot/data-activity/dist/store/api"
import * as DayOfWeek from "@fitnesspilot/data-common/dist/DayOfWeek"
import * as Alignment from "@fitnesspilot/data-human-body/dist/Alignment"
import * as Muscle from "@fitnesspilot/data-human-body/dist/Muscle"
import * as MuscleGroup from "@fitnesspilot/data-human-body/dist/muscleGroups"

import * as Event from "../calendar/Event"
import { RecommendationEvent } from "../calendar/Event"
import * as EventOrRecommendation from "../calendar/EventOrRecommendation"
import * as EventSource from "../calendar/EventSource"
import * as Recommendation from "../calendar/Recommendation"
import * as Recurrence from "../calendar/Recurrence"
import { Timeslot } from "../calendar/Timeslot"
import { Video, VimeoVideo, YoutubeVideo } from "../video/Video"
import { stringAsVimeoVideoId, stringAsYoutubeVideoId } from "../video/VideoId"
import { VideoSource } from "../video/VideoSource"
import {
  stringAsWorkoutVideoId,
  WorkoutVideo,
  workoutVideoIdFromVideo,
} from "../video/WorkoutVideo"
import * as EditingEvent from "./EditingEvent"

import { Temporal } from "@js-temporal/polyfill"
import * as ytSearch from "youtube-search"

const recurrencePatternAsNumber = new Getter<
  Recurrence.RecurrencePattern,
  number
>(
  Recurrence.foldRecurrencePattern(
    Recurrence._repeatEveryRecurrencePatternDaily.composeGetter(
      _localDurationDays.asGetter(),
    ).get,
    Recurrence._repeatEveryRecurrencePatternWeekly.composeGetter(
      _localDurationWeeks.asGetter(),
    ).get,
    Recurrence._repeatEveryRecurrencePatternMonthly.composeGetter(
      _localDurationMonths.asGetter(),
    ).get,
  ),
)

const unsafeDateToTime = flow(dateAsTime.getOption, unsafeFromSome)
export const recurrence = new Getter<API.Event, Recurrence.Recurrence>(
  (api: API.Event) =>
    ({
      [API.RecurringPattern.Single]: () =>
        Recurrence._RecurrenceSingle.reverseGet(undefined),
      [API.RecurringPattern.Daily]: () =>
        Recurrence._RecurrencePattern.reverseGet(
          Recurrence._RecurrencePatternDaily.reverseGet({
            between: [
              pipe(
                api.recurrenceStart,
                Opt.fromNullable,
                Opt.chain(dateAsTime.getOption),
                unsafeFromSome,
              ),
              pipe(
                api.recurrenceEnd,
                Opt.fromNullable,
                Opt.map(unsafeDateToTime),
              ),
            ],
            repeatEvery: pipe(
              api.recurringInterval,
              Opt.fromNullable,
              Opt.getOrElse(() => 1),
              (days) => mkLocalDuration({ days }) as any,
            ),
          }),
        ),
      [API.RecurringPattern.Workdays]: () =>
        Recurrence._RecurrencePattern.reverseGet(
          Recurrence._RecurrencePatternWeekly.reverseGet({
            between: [
              pipe(
                api.recurrenceStart,
                Opt.fromNullable,
                Opt.chain(dateAsTime.getOption),
                unsafeFromSome,
              ),
              pipe(
                api.recurrenceEnd,
                Opt.fromNullable,
                Opt.map(unsafeDateToTime),
              ),
            ],
            repeatEvery: pipe(
              api.recurringInterval,
              Opt.fromNullable,
              Opt.getOrElse(() => 1),
              (weeks) => mkLocalDuration({ weeks }) as any,
            ),
            occursOn: [
              DayOfWeek.DayOfWeek.monday,
              DayOfWeek.DayOfWeek.tuesday,
              DayOfWeek.DayOfWeek.wednesday,
              DayOfWeek.DayOfWeek.thursday,
              DayOfWeek.DayOfWeek.friday,
            ],
          }),
        ),
      [API.RecurringPattern.Weekends]: () =>
        Recurrence._RecurrencePattern.reverseGet(
          Recurrence._RecurrencePatternWeekly.reverseGet({
            between: [
              pipe(
                api.recurrenceStart,
                Opt.fromNullable,
                Opt.chain(dateAsTime.getOption),
                unsafeFromSome,
              ),
              pipe(
                api.recurrenceEnd,
                Opt.fromNullable,
                Opt.map(unsafeDateToTime),
              ),
            ],
            repeatEvery: pipe(
              api.recurringInterval,
              Opt.fromNullable,
              Opt.getOrElse(() => 1),
              (weeks) => mkLocalDuration({ weeks }) as any,
            ),
            occursOn: [
              DayOfWeek.DayOfWeek.saturday,
              DayOfWeek.DayOfWeek.sunday,
            ],
          }),
        ),
      [API.RecurringPattern.Weekly]: () =>
        Recurrence._RecurrencePattern.reverseGet(
          Recurrence._RecurrencePatternWeekly.reverseGet({
            between: [
              pipe(
                api.recurrenceStart,
                Opt.fromNullable,
                Opt.chain(dateAsTime.getOption),
                unsafeFromSome,
              ),
              pipe(
                api.recurrenceEnd,
                Opt.fromNullable,
                Opt.map(unsafeDateToTime),
              ),
            ],
            repeatEvery: pipe(
              api.recurringInterval,
              Opt.fromNullable,
              Opt.getOrElse(() => 1),
              (weeks) => mkLocalDuration({ weeks }) as any,
            ),
            occursOn: pipe(
              api.daysOfWeek,
              Opt.fromNullable,
              Opt.getOrElse(() => "1,2,3,4,5,6,7"),
              (v) => v.split(","),
              NonEmpty.fromReadonlyArray,
              unsafeFromSome,
              NonEmpty.map((v) =>
                DayOfWeek.dayOfWeekAsDayOfWeekIso.reverseGet(Number(v)),
              ),
            ),
          }),
        ),
      [API.RecurringPattern.Monthly]: () =>
        Recurrence._RecurrencePattern.reverseGet(
          Recurrence._RecurrencePatternMonthly.reverseGet({
            between: [
              pipe(
                api.recurrenceStart,
                Opt.fromNullable,
                Opt.chain(dateAsTime.getOption),
                unsafeFromSome,
              ),
              pipe(
                api.recurrenceEnd,
                Opt.fromNullable,
                Opt.map(unsafeDateToTime),
              ),
            ],
            repeatEvery: pipe(
              api.recurringInterval,
              Opt.fromNullable,
              Opt.getOrElse(() => 1),
              (months) => mkLocalDuration({ months }) as any,
            ),
            occursOn: pipe(
              pipe(
                api.dayOfMonth,
                Opt.fromNullable,
                Opt.map(
                  (dayOfMonth): Recurrence.MonthlyOccurence => ({
                    type: Recurrence.MonthlyOccurenceType.dayOfMonth,
                    value: {
                      dayOfMonth: unsafeFromSome(
                        prismPositiveInteger.getOption(dayOfMonth),
                      ),
                    },
                  }),
                ),
              ),
              Opt.alt(() =>
                pipe(
                  api.dayofweek,
                  Opt.fromNullable,
                  Opt.map(
                    (dayOfWeek) =>
                      (weekOfMonth: number): Recurrence.MonthlyOccurence => ({
                        type: Recurrence.MonthlyOccurenceType.weekdayOfMonth,
                        value: {
                          weekOfMonth: weekOfMonth as any,
                          dayOfWeek:
                            DayOfWeek.dayOfWeekAsDayOfWeekIso.reverseGet(
                              dayOfWeek,
                            ),
                        },
                      }),
                  ),
                  Opt.ap(Opt.fromNullable(api.weekOfMonth)),
                ),
              ),
              unsafeFromSome,
              NonEmpty.of,
            ),
          }),
        ),
    })[
      pipe(
        api.recurringPattern,
        Opt.fromNullable,
        Opt.getOrElse<API.RecurringPattern>(() => API.RecurringPattern.Single),
      )
    ](),
)

export const eventSource = new Iso<API.EventSource, EventSource.EventSource>(
  (s) => s,
  (a) => a,
)

export const scheduledMuscle = new Iso<
  API.ScheduledMuscle,
  readonly [Muscle.MuscleId, Positive]
>(
  (s) =>
    [
      pipe(
        s,
        Optional.fromNullableProp<API.ScheduledMuscle>()("id").composePrism(
          Muscle.stringAsMuscleId,
        ).getOption,
        unsafeFromSome,
      ),
      pipe(
        s,
        Lens.fromProp<API.ScheduledMuscle>()("regenerationSlope").composePrism(
          prismPositive,
        ).getOption,
        unsafeFromSome,
      ),
    ] as const,
  (a) => ({
    id: pipe(a[0], Muscle.stringAsMuscleId.reverseGet),
    regenerationSlope: pipe(a[1], prismPositive.reverseGet),
  }),
)

const eventType = new Iso<Event.EventType, API.EventType>(
  (a) =>
    ({
      [Event.EventType.activity]: API.EventType.Activity,
      [Event.EventType.menu]: API.EventType.Menu,
      [Event.EventType.work]: API.EventType.Work,
      [Event.EventType.sleep]: API.EventType.Sleep,
      [Event.EventType.catalog]: API.EventType.Catalog,
    })[a],
  (s) =>
    ({
      [API.EventType.Activity]: Event.EventType.activity,
      [API.EventType.Menu]: Event.EventType.menu,
      [API.EventType.Work]: Event.EventType.work,
      [API.EventType.Sleep]: Event.EventType.sleep,
      [API.EventType.Catalog]: Event.EventType.catalog,
    })[s],
)

const eventWithoutId = new Getter<Event.Event, Omit<API.Event, "id">>((a) => ({
  daysOfWeek: pipe(
    a,
    Event._recurrence
      .composePrism(Recurrence._RecurrencePattern)
      .composePrism(Recurrence._RecurrencePatternWeekly)
      .composeLens(Recurrence._occursOnRecurrencePatternWeekly)
      .composeTraversal(fromTraversable(NonEmpty.Traversable)())
      .composeIso(DayOfWeek.dayOfWeekAsDayOfWeekIso)
      .asFold().getAll,
    Arr.map(String),
    (vs) => Foldable.intercalate(Str.Monoid, Arr.Foldable)(",", vs),
  ),
  weekOfMonth: pipe(
    a,
    (v) =>
      Event._recurrence
        .composePrism(Recurrence._RecurrencePattern)
        .composePrism(Recurrence._RecurrencePatternMonthly)
        .composeLens(Recurrence._occursOnRecurrencePatternMonthly)
        .composeTraversal(fromTraversable(NonEmpty.Traversable)())
        .composePrism(Recurrence._MonthlyOccurenceWeekdayOfMonth)
        .composeLens(Recurrence._monthlyOccurenceWeekdayOfMonthWeekOfMonth)
        .composeGetter(prismToGetter(prismPositive))
        .headOption(v),
    Opt.toNullable,
  ),
  start: pipe(a, Event._start.composeGetter(prismToGetter(dateAsTime)).get),
  activities: pipe(
    a,
    Event._activities
      .composeTraversal(fromTraversable(Arr.Traversable)())
      .composeIso(activityInstance.reverse())
      .asFold().getAll,
  ),
  recurrenceEnd: pipe(
    a,
    (v) =>
      Event._recurrence
        .composePrism(Recurrence._RecurrencePattern)
        .composeOptional(optionLensToOptional(Recurrence._end))
        .composeGetter(prismToGetter(dateAsTime))
        .headOption(v),
    Opt.toNullable,
  ),
  confirmed: pipe(
    a,
    Event._confirmed.get,
    Opt.map(dateAsTime.reverseGet),
    Opt.toNullable,
  ),
  isRecommendation: pipe(a, Event._isRecommendation.get),
  isBestMatch: false,
  // TODO This must actually be nullable
  recurringInterval: pipe(
    a,
    (a) =>
      Event._recurrence
        .composePrism(Recurrence._RecurrencePattern)
        .composeGetter(recurrencePatternAsNumber)
        .headOption(a),
    Opt.getOrElse(() => 1),
  ),
  recurrenceStart: pipe(
    a,
    (v) =>
      Event._recurrence
        .composePrism(Recurrence._RecurrencePattern)
        .composeLens(Recurrence._start)
        .composeGetter(prismToGetter(dateAsTime))
        .headOption(v),
    Opt.toNullable,
  ),
  alignment: pipe(
    a,
    Event._alignment.composeGetter(prismToGetter(Alignment.numberAsAlignment))
      .get,
    (a) => a / 100,
  ),
  end: pipe(a, Event._end.composeGetter(prismToGetter(dateAsTime)).get),
  source: pipe(
    a,
    optionLensToOptional(Event._source).composeIso(eventSource.reverse())
      .getOption,
    Opt.toNullable,
  ),
  video: pipe(a.video, Opt.map(workoutVideo.reverseGet), Opt.toNullable),
  dayOfMonth: pipe(
    a,
    (v) =>
      Event._recurrence
        .composePrism(Recurrence._RecurrencePattern)
        .composePrism(Recurrence._RecurrencePatternMonthly)
        .composeLens(Recurrence._occursOnRecurrencePatternMonthly)
        .composeTraversal(fromTraversable(NonEmpty.Traversable)())
        .composePrism(Recurrence._MonthlyOccurenceDayOfMonth)
        .composeLens(Recurrence._monthlyOccurenceDayOfMonthDayOfMonth)
        .composeGetter(prismToGetter(prismPositive))
        .headOption(v),
    Opt.toNullable,
  ),
  title: pipe(a, Event._title.get),
  type: eventType.get(a.type),
  dayOfWeek: pipe(
    a,
    (v) =>
      Event._recurrence
        .composePrism(Recurrence._RecurrencePattern)
        .composePrism(Recurrence._RecurrencePatternMonthly)
        .composeLens(Recurrence._occursOnRecurrencePatternMonthly)
        .composeTraversal(fromTraversable(NonEmpty.Traversable)())
        .composePrism(Recurrence._MonthlyOccurenceWeekdayOfMonth)
        .composeLens(Recurrence._monthlyOccurenceWeekdayOfMonthDayOfWeek)
        .composeIso(DayOfWeek.dayOfWeekAsDayOfWeekIso)
        .asFold()
        .headOption(v),
    Opt.toNullable,
  ),
  recurringPattern: pipe(a, Event._recurrence.get, (rec) =>
    rec.type === Recurrence.RecurrenceType.single
      ? API.RecurringPattern.Single
      : rec.value.type === Recurrence.RecurrencePatternType.daily
      ? API.RecurringPattern.Daily
      : rec.value.type === Recurrence.RecurrencePatternType.weekly
      ? NonEmpty.getEq(DayOfWeek.dayOfWeek).equals(rec.value.value.occursOn, [
          DayOfWeek.DayOfWeek.monday,
          DayOfWeek.DayOfWeek.tuesday,
          DayOfWeek.DayOfWeek.wednesday,
          DayOfWeek.DayOfWeek.thursday,
          DayOfWeek.DayOfWeek.friday,
        ])
        ? API.RecurringPattern.Workdays
        : NonEmpty.getEq(DayOfWeek.dayOfWeek).equals(rec.value.value.occursOn, [
            DayOfWeek.DayOfWeek.saturday,
            DayOfWeek.DayOfWeek.sunday,
          ])
        ? API.RecurringPattern.Weekends
        : API.RecurringPattern.Weekly
      : API.RecurringPattern.Monthly,
  ),
}))

const isRecommendation = (e: API.Event) => !e.id && e.isRecommendation

export const event = new Iso<
  API.Event,
  EventOrRecommendation.EventOrRecommendation
>(
  (s) =>
    isRecommendation(s)
      ? EventOrRecommendation._Recommendation.reverseGet({
          between: [
            pipe(
              s,
              Lens.fromProp<API.Event>()("start").composePrism(dateAsTime)
                .getOption,
              unsafeFromSome,
            ),
            pipe(
              s,
              Lens.fromProp<API.Event>()("end").composePrism(dateAsTime)
                .getOption,
              unsafeFromSome,
            ),
          ] as const,
          recurrence: pipe(s, recurrence.get),
          scheduledMuscles: pipe(
            s,
            Optional.fromNullableProp<API.Event>()("scheduledMuscles")
              .composeTraversal(fromTraversable(Arr_.array)())
              .composeIso(scheduledMuscle)
              .asFold().getAll,
          ),
        })
      : EventOrRecommendation._EventWithId.reverseGet({
          id: pipe(
            s,
            Optional.fromNullableProp<API.Event>()("id").composePrism(
              Event.stringAsEventId,
            ).getOption,
            unsafeFromSome,
          ),
          value: {
            title: pipe(
              s,
              Optional.fromNullableProp<API.Event>()("title").getOption,
              unsafeFromSome,
            ),
            type: eventType.reverseGet(s.type),
            activities: pipe(
              s,
              Optional.fromNullableProp<API.Event>()("activities")
                .composeTraversal(fromTraversable(Arr_.array)())
                .composeIso(activityInstance)
                .asFold().getAll,
            ),
            between: [
              pipe(
                s,
                Lens.fromProp<API.Event>()("start").composePrism(dateAsTime)
                  .getOption,
                unsafeFromSome,
              ),
              pipe(
                s,
                Lens.fromProp<API.Event>()("end").composePrism(dateAsTime)
                  .getOption,
                unsafeFromSome,
              ),
            ] as const,
            alignment: pipe(
              s,
              (e) =>
                Lens.fromProp<API.Event>()("alignment")
                  .composeGetter(new Getter((a) => a * 100))
                  .composePrism(Alignment.numberAsAlignment)
                  .headOption(e),
              unsafeFromSome,
            ),
            recurrence: pipe(s, recurrence.get),
            source: pipe(
              s,
              Optional.fromNullableProp<API.Event>()("source").composeIso(
                eventSource,
              ).getOption,
            ),
            video: pipe(s.video, Opt.fromNullable, Opt.map(workoutVideo.get)),
            isRecommendation: pipe(
              s,
              Lens.fromProp<API.Event>()("isRecommendation").get,
            ),
            confirmed: pipe(
              s,
              Lens.fromProp<API.Event>()("confirmed").get,
              Opt.fromNullable,
              Opt.chain(dateAsTime.getOption),
            ),
          },
        }),
  EventOrRecommendation.foldEventOrRecommendation<API.Event>(
    (a) => ({
      ...pipe(
        a,
        WithId._value<Event.EventId, Event.Event>().composeGetter(
          eventWithoutId,
        ).get,
      ),
      id: pipe(
        a,
        WithId._id<Event.EventId, Event.Event>().composeGetter(
          prismToGetter(Event.stringAsEventId),
        ).get,
      ),
    }),
    (a) => ({
      isRecommendation: true,
      recurringInterval: undefined as number | void as number,
      alignment: undefined as number | void as number,
      isBestMatch: false,
      start: pipe(
        a,
        Recommendation._start.composeGetter(prismToGetter(dateAsTime)).get,
      ),
      end: pipe(
        a,
        Recommendation._end.composeGetter(prismToGetter(dateAsTime)).get,
      ),
      daysOfWeek: pipe(
        a,
        Recommendation._recurrence
          .composePrism(Recurrence._RecurrencePattern)
          .composePrism(Recurrence._RecurrencePatternWeekly)
          .composeLens(Recurrence._occursOnRecurrencePatternWeekly)
          .composeTraversal(fromTraversable(NonEmpty.Traversable)())
          .composeIso(DayOfWeek.dayOfWeekAsDayOfWeekIso)
          .asFold().getAll,
        Arr.map(String),
        NonEmpty.fromReadonlyArray,
        Opt.map((vs) =>
          Foldable.intercalate(Str.Monoid, NonEmpty.Foldable)(",", vs),
        ),
        Opt.toNullable,
      ),
      weekOfMonth: pipe(
        a,
        (v) =>
          Recommendation._recurrence
            .composePrism(Recurrence._RecurrencePattern)
            .composePrism(Recurrence._RecurrencePatternMonthly)
            .composeLens(Recurrence._occursOnRecurrencePatternMonthly)
            .composeTraversal(fromTraversable(NonEmpty.Traversable)())
            .composePrism(Recurrence._MonthlyOccurenceWeekdayOfMonth)
            .composeLens(Recurrence._monthlyOccurenceWeekdayOfMonthWeekOfMonth)
            .composeGetter(prismToGetter(prismPositive))
            .headOption(v),
        Opt.toNullable,
      ),
      recurrenceEnd: pipe(
        a,
        (v) =>
          Recommendation._recurrence
            .composePrism(Recurrence._RecurrencePattern)
            .composeOptional(optionLensToOptional(Recurrence._end))
            .composeGetter(prismToGetter(dateAsTime))
            .headOption(v),
        Opt.toNullable,
      ),
      recurrenceInterval: pipe(
        a,
        (a) =>
          Recommendation._recurrence
            .composePrism(Recurrence._RecurrencePattern)
            .composeGetter(recurrencePatternAsNumber)
            .headOption(a),
        Opt.toNullable,
      ),
      recurrenceStart: pipe(
        a,
        (v) =>
          Recommendation._recurrence
            .composePrism(Recurrence._RecurrencePattern)
            .composeLens(Recurrence._start)
            .composeGetter(prismToGetter(dateAsTime))
            .headOption(v),
        Opt.toNullable,
      ),
      dayOfMonth: pipe(
        a,
        (v) =>
          Recommendation._recurrence
            .composePrism(Recurrence._RecurrencePattern)
            .composePrism(Recurrence._RecurrencePatternMonthly)
            .composeLens(Recurrence._occursOnRecurrencePatternMonthly)
            .composeTraversal(fromTraversable(NonEmpty.Traversable)())
            .composePrism(Recurrence._MonthlyOccurenceDayOfMonth)
            .composeLens(Recurrence._monthlyOccurenceDayOfMonthDayOfMonth)
            .composeGetter(prismToGetter(prismPositive))
            .headOption(v),
        Opt.toNullable,
      ),
      type: API.EventType.Activity,
      dayOfWeek: pipe(
        a,
        (v) =>
          Recommendation._recurrence
            .composePrism(Recurrence._RecurrencePattern)
            .composePrism(Recurrence._RecurrencePatternMonthly)
            .composeLens(Recurrence._occursOnRecurrencePatternMonthly)
            .composeTraversal(fromTraversable(NonEmpty.Traversable)())
            .composePrism(Recurrence._MonthlyOccurenceWeekdayOfMonth)
            .composeLens(Recurrence._monthlyOccurenceWeekdayOfMonthDayOfWeek)
            .composeIso(DayOfWeek.dayOfWeekAsDayOfWeekIso)
            .asFold()
            .headOption(v),
        Opt.toNullable,
      ),
      recurringPattern: pipe(a, Recommendation._recurrence.get, (rec) =>
        rec.type === Recurrence.RecurrenceType.single
          ? API.RecurringPattern.Single
          : rec.value.type === Recurrence.RecurrencePatternType.daily
          ? API.RecurringPattern.Daily
          : rec.value.type === Recurrence.RecurrencePatternType.weekly
          ? NonEmpty.getEq(DayOfWeek.dayOfWeek).equals(
              rec.value.value.occursOn,
              [
                DayOfWeek.DayOfWeek.monday,
                DayOfWeek.DayOfWeek.tuesday,
                DayOfWeek.DayOfWeek.wednesday,
                DayOfWeek.DayOfWeek.thursday,
                DayOfWeek.DayOfWeek.friday,
              ],
            )
            ? API.RecurringPattern.Workdays
            : NonEmpty.getEq(DayOfWeek.dayOfWeek).equals(
                rec.value.value.occursOn,
                [DayOfWeek.DayOfWeek.saturday, DayOfWeek.DayOfWeek.sunday],
              )
            ? API.RecurringPattern.Weekends
            : API.RecurringPattern.Weekly
          : API.RecurringPattern.Monthly,
      ),
    }),
  ),
)

export const editingEventToApiEvent = new Getter<
  EditingEvent.EditingEventWithId,
  API.Event
>(
  ({
    id,
    value: {
      title,
      selectedActivities,
      catalog,
      recommendations,
      addActivity,
      ...value
    },
  }) => ({
    id: pipe(id, Opt.map(Event.stringAsEventId.reverseGet), Opt.toUndefined),
    ...eventWithoutId.get({
      title: pipe(
        title,
        Opt.getOrElse(() => ""),
      ),
      ...value,
    }),
    title: pipe(title, Opt.toNullable),
  }),
)

export const recommendationEvent = new Getter<API.Event, RecommendationEvent>(
  (s) => ({
    title: pipe(
      s,
      Optional.fromNullableProp<API.Event>()("title").getOption,
      unsafeFromSome,
    ),
    type: eventType.reverseGet(s.type),
    activities: pipe(
      s,
      Optional.fromNullableProp<API.Event>()("activities")
        .composeTraversal(fromTraversable(Arr_.array)())
        .composeIso(activityInstance)
        .asFold().getAll,
    ),
    between: [
      pipe(
        s,
        Lens.fromProp<API.Event>()("start").composePrism(dateAsTime).getOption,
        unsafeFromSome,
      ),
      pipe(
        s,
        Lens.fromProp<API.Event>()("end").composePrism(dateAsTime).getOption,
        unsafeFromSome,
      ),
    ] as const,
    alignment: pipe(
      s,
      (e) =>
        Lens.fromProp<API.Event>()("alignment")
          .composeGetter(new Getter((a) => a * 100))
          .composePrism(Alignment.numberAsAlignment)
          .headOption(e),
      unsafeFromSome,
    ),
    source: pipe(
      s,
      Optional.fromNullableProp<API.Event>()("source").composeIso(eventSource)
        .getOption,
    ),
    video: pipe(s.video, Opt.fromNullable, Opt.map(workoutVideo.get)),
    isRecommendation: pipe(
      s,
      Lens.fromProp<API.Event>()("isRecommendation").get,
    ),
    confirmed: Opt.none,
  }),
)

export const timeslot = new Getter<API.TimeslotApi, Timeslot>(
  ({ time, outdated, recoveryStatuses }) => ({
    time: pipe(time, dateAsTime.getOption, unsafeFromSome),
    outdated: pipe(outdated, Opt.fromNullable, Opt.getOrElse(constFalse)),
    recoveryStatus: pipe(
      recoveryStatuses as
        | Rec.ReadonlyRecord<MuscleGroup.MuscleGroupId, number>
        | undefined
        | null,
      Opt.fromNullable,
      unsafeFromSome,
      mapKeys(Semigroup.first(), MuscleGroup.muscleGroupIdAsMuscleGroups.get),
      (mgs) => ({
        ...mgs,
        [MuscleGroup.DorsalMuscleGroups.hipAndEverythingInferiorToIt]:
          mgs[MuscleGroup.VentralMuscleGroups.hipAndEverythingInferiorToIt],
      }),
      Rec.map(
        flow(
          numberAsValidNumber.composeIso(
            valueInUnit(Dimension.noDim, UnitNoDim.percent),
          ).getOption,
          unsafeFromSome,
        ),
      ),
    ),
  }),
)

export const videoSource = new Iso<API.VideoSource, VideoSource>(
  (a) =>
    match(a)
      .with(API.VideoSource.Youtube, () => VideoSource.youtube)
      .with(API.VideoSource.Vimeo, () => VideoSource.vimeo)
      .exhaustive(),
  (a) =>
    match(a)
      .with(VideoSource.youtube, () => API.VideoSource.Youtube)
      .with(VideoSource.vimeo, () => API.VideoSource.Vimeo)
      .exhaustive(),
)

export const video = new Iso<API.Video, Video>(
  (a) => {
    const source = pipe(a.source, videoSource.get)
    return match(source)
      .with(
        VideoSource.youtube,
        (source): YoutubeVideo => ({
          source,
          id: pipe(a.id, stringAsYoutubeVideoId.getOption, unsafeFromSome),
          title: a.title,
          description: a.description,
          channelId: a.channelId,
          channelTitle: a.channelTitle,
          publishedAt: pipe(
            a.publishedAt,
            dateAsTime.getOption,
            unsafeFromSome,
          ),
          thumbnailUrl: pipe(a.thumbnailUrl, Opt.fromNullable),
        }),
      )
      .with(
        VideoSource.vimeo,
        (source): VimeoVideo => ({
          source,
          id: pipe(a.id, stringAsVimeoVideoId.getOption, unsafeFromSome),
          title: a.title,
          description: a.description,
          channelId: a.channelId,
          channelTitle: a.channelTitle,
          publishedAt: pipe(
            a.publishedAt,
            dateAsTime.getOption,
            unsafeFromSome,
          ),
          thumbnailUrl: pipe(a.thumbnailUrl, Opt.fromNullable),
        }),
      )
      .exhaustive()
  },
  (a) => ({
    source: pipe(a.source, videoSource.reverseGet),
    id: unwrap(a.id),
    title: a.title,
    description: a.description,
    channelId: a.channelId,
    channelTitle: a.channelTitle,
    publishedAt: pipe(a.publishedAt, dateAsTime.reverseGet),
    thumbnailUrl: pipe(a.thumbnailUrl, Opt.toNullable),
  }),
)

export const workoutVideo = new Iso<API.WorkoutVideo, WorkoutVideo>(
  (a) => ({
    id: pipe(a.id, stringAsWorkoutVideoId.getOption, unsafeFromSome),
    video: pipe(a.video, video.get),
    title: pipe(a.title, Opt.fromNullable),
    activities: [],
  }),
  (a) => ({
    id: pipe(a.id, unwrap),
    video: pipe(a.video, video.reverseGet),
    title: pipe(a.title, Opt.toUndefined),
  }),
)

export const youtubeWorkoutVideo = new Getter<
  ytSearch.YouTubeSearchResults,
  WorkoutVideo
>((a) => {
  const video: Video = {
    source: VideoSource.youtube,
    id: pipe(a.id, stringAsYoutubeVideoId.getOption, unsafeFromSome),
    title: a.title,
    description: a.description,
    channelId: a.channelId,
    channelTitle: a.channelTitle,
    publishedAt: pipe(a.publishedAt, isoStringAsTime.getOption, unsafeFromSome),
    thumbnailUrl: pipe(
      a.thumbnails.maxres ??
        a.thumbnails.high ??
        a.thumbnails.medium ??
        a.thumbnails.standard ??
        a.thumbnails.default,
      Opt.fromNullable,
      Opt.map((t) => t.url),
    ),
  }
  return {
    id: pipe(video, workoutVideoIdFromVideo.get),
    title: Opt.none,
    video,
    activities: [],
  }
})

export { activityInstance }

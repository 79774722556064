import { FC, useRef } from "react"
import { FaCheck, FaExclamation } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import styled from "@emotion/styled"

import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import {
  Dimension,
  numberAsValidNumber,
  reunit,
  UnitNoDim,
  valueInUnit,
} from "@fitnesspilot/data-common"

import {
  Popover,
  PopoverBody,
  PopoverHeader,
} from "@fitnesspilot/components-common/dist/atoms/Popover/Popover"
import * as alignment from "@fitnesspilot/data-human-body/dist/Alignment"
import { numberAsAlignment } from "@fitnesspilot/data-human-body/dist/Alignment"

const percentToOneNumber = numberAsValidNumber
  .composeIso(valueInUnit(Dimension.noDim, UnitNoDim.one))
  .composeIso(reunit(Dimension.noDim, UnitNoDim.one, UnitNoDim.percent))

const foldAlignment =
  <a,>(onBad: () => a, onMedium: () => a, onGood: () => a) =>
  (alignment: alignment.Alignment) => {
    const a = pipe(alignment, numberAsAlignment.reverseGet)
    return a > 50 ? onGood() : a > 20 ? onMedium() : onBad()
  }

type AlignmentIconProps = Pick<AlignmentProps, "value"> & { className?: string }
const AlignmentIcon = styled(({ className, value }: AlignmentIconProps) =>
  pipe(
    value,
    foldAlignment(
      () => <FaExclamation {...{ className }} />,
      () => <FaExclamation {...{ className }} />,
      () => <FaCheck {...{ className }} />,
    ),
  ),
)`
  font-size: 0.75em;
  color: ${({ theme, value }) =>
    pipe(
      value,
      foldAlignment(
        () => theme.colours.danger.toString(), // TODO green["500"]
        () => theme.colours.warning.toString(),
        () => theme.colours.success.toString(),
      ),
    )};
`

const AlignmentValue = styled.span`
  color: ${({ theme }) => theme.colours.grey[500].toString()};
  margin-left: 0.25em;
`

const AlignmentContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: start;
`

export type AlignmentProps = {
  value: alignment.Alignment
  descriptionIsOpen: boolean
  onDescriptionIsOpenChange: (v: boolean) => IO.IO<void>
  className?: string
}

export const Alignment: FC<AlignmentProps> = ({
  value,
  descriptionIsOpen,
  onDescriptionIsOpenChange,
  ...props
}) => {
  const targetRef = useRef<HTMLDivElement>(null)

  return (
    <AlignmentContainer
      ref={targetRef}
      onMouseEnter={onDescriptionIsOpenChange(true)}
      onMouseLeave={onDescriptionIsOpenChange(false)}
      {...props}
    >
      <AlignmentIcon {...{ value }} />

      <AlignmentValue>
        <FormattedMessage
          defaultMessage="{value, number, percent}"
          values={{
            value: percentToOneNumber
              .composePrism(alignment.percentAsAlignment)
              .reverseGet(value),
          }}
        />
      </AlignmentValue>

      {targetRef.current && (
        <Popover
          isOpen={descriptionIsOpen}
          target={targetRef.current}
          placement="auto"
        >
          <PopoverHeader>
            <FormattedMessage defaultMessage="Alignment" />
          </PopoverHeader>

          <PopoverBody>
            <FormattedMessage defaultMessage="The alignment is a percentage value showing how well an activity fits to your current muscle recovery and personal goals." />
          </PopoverBody>
        </Popover>
      )}
    </AlignmentContainer>
  )
}

import { FC, ReactNode } from "react"

import { Dorsoventral } from "@fitnesspilot/data-human-body/dist/anatomicalAxes"
import {
  DorsalMuscleGroups,
  MuscleGroupsByDorsoventralSide,
  VentralMuscleGroups,
} from "@fitnesspilot/data-human-body/dist/muscleGroups"

const pos: {
  [k in keyof MuscleGroupsByDorsoventralSide]: Record<
    MuscleGroupsByDorsoventralSide[k],
    [number, number]
  >
} = {
  [Dorsoventral.dorsal]: {
    [DorsalMuscleGroups.tricepsBrachii]: [139.05, 127.4],
    [DorsalMuscleGroups.dorsalThorax]: [91.05, 86.4],
    [DorsalMuscleGroups.forearmAndHand]: [148.05, 170.4],
    // 'Leg' is, anatomically, synonym to 'crus', the part of the hindlimbs distal to the knee and proximal to the ankle.
    [DorsalMuscleGroups.hipAndEverythingInferiorToIt]: [109.05, 255.4],
  },
  [Dorsoventral.ventral]: {
    [VentralMuscleGroups.cardiovascular]: [104.11, 102.4],
    [VentralMuscleGroups.abdomen]: [88.11, 158.4],
    [VentralMuscleGroups.shoulder]: [59.11, 86.4],
    // A _lot_ (about 9) of the upper arm muscles are missing.
    [VentralMuscleGroups.bicepsBrachii]: [44.11, 127.4],
    [VentralMuscleGroups.ventralThorax]: [76.11, 102.4],
    // 'Leg' is, anatomically, synonym to 'crus', the part of the hindlimbs distal to the knee and proximal to the ankle.
    [VentralMuscleGroups.hipAndEverythingInferiorToIt]: [70.11, 257.4],
  },
}

type Side<a extends Dorsoventral> = {
  dorsoventralSide: a
  muscleGroup: MuscleGroupsByDorsoventralSide[a]
}

export type MuscleGroupPositionProps = {
  children: ReactNode
} & (Side<Dorsoventral.dorsal> | Side<Dorsoventral.ventral>)

export const MuscleGroupPosition: FC<MuscleGroupPositionProps> = ({
  dorsoventralSide,
  muscleGroup,
  children,
}) => (
  <g
    transform={`translate(${String(
      (pos[dorsoventralSide] as any)[muscleGroup].join(", "),
    )})`}
  >
    {children}
  </g>
)

import { FC, Fragment, ReactNode } from "react"
import { FaBars } from "react-icons/fa"
import Media from "react-media"
import { Link } from "react-router-dom"
import { withTheme } from "@emotion/react"
import styled from "@emotion/styled"

import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"

import { Clickable } from "../../atoms/Clickable/Clickable"
import { Logo } from "../../atoms/Logo/Logo"
import { LogoText } from "../../atoms/LogoText/LogoText"
import { Theme } from "../../atoms/ThemeProvider/ThemeProvider"

const StyledLogo = styled(Logo)`
  width: 50%;
  height: 50%;
`

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--template-header--height);
  height: var(--template-header--height);
  background: ${({ theme }) => theme.colours.primary.toString()};
`

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  flex-grow: 1;

  height: var(--template-header--height);
`

const ContentStart = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:nth-child(2)) {
    margin-right: 10px;
  }
`

const MenuButton = styled(Clickable)`
  padding: 10px;
  margin-left: -10px;
`

const LogoTextContainer = styled(Link)`
  font-size: 21px;

  &:hover {
    text-decoration: none;
  }
`

const Container = styled.header`
  display: flex;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
`

export type TemplateHeaderProps = {
  children?: ReactNode
  isLoading: boolean
  showNavToggle?: boolean
  className?: string
  onToggleNav?: IO.IO<void>
}

export const TemplateHeader: FC<TemplateHeaderProps> = withTheme(
  ({
    children,
    isLoading,
    showNavToggle,
    onToggleNav,
    theme,
    ...props
  }: TemplateHeaderProps & { theme: Theme }) => (
    <Container {...props}>
      <Media queries={theme.media}>
        {({ lg }: Record<"lg", boolean>) => (
          <Fragment>
            {lg && (
              <LogoContainer to="/">
                <StyledLogo {...{ isLoading }} />
              </LogoContainer>
            )}

            <ContentContainer>
              <ContentStart>
                {!lg && showNavToggle && (
                  <MenuButton
                    onClick={() =>
                      pipe(
                        onToggleNav,
                        Opt.fromNullable,
                        Opt.getOrElse(() => IO.of<void>(undefined)),
                      )()
                    }
                  >
                    <FaBars />
                  </MenuButton>
                )}

                <LogoTextContainer to="/">
                  <LogoText />
                </LogoTextContainer>
              </ContentStart>

              <div>{children}</div>
            </ContentContainer>
          </Fragment>
        )}
      </Media>
    </Container>
  ),
)

import * as Bool from "fp-ts/es6/boolean"
import * as Eq from "fp-ts/es6/Eq"
import { flow, pipe } from "fp-ts/es6/function"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as Str from "fp-ts/es6/string"
import { Lens } from "monocle-ts"
import { unsafeFromSome } from "@fitnesspilot/data-common"

import { MuscleId_, muscleId_ } from "@fitnesspilot/data-human-body/dist/Muscle"
import {
  MuscleGroupId,
  muscleIdsOfGroup,
} from "@fitnesspilot/data-human-body/dist/muscleGroups"
import * as Priority from "@fitnesspilot/data-human-body/dist/Priority"

export type MuscleSettingsBase = {
  priority: Priority.Priority
  enabled: boolean
}
export const muscleSettingsBase = Eq.struct({
  priority: Priority.priority,
  enabled: Bool.Eq,
})
export const _priority = <a extends MuscleSettingsBase>() =>
  Lens.fromProp<a>()("priority")
export const _enabled = <a extends MuscleSettingsBase>() =>
  Lens.fromProp<a>()("enabled")

export type MuscleSettings = MuscleSettingsBase & {
  // AFAICT it's never set
  // status?: number | null
  name: string
  id: MuscleGroupId | MuscleId_
}
export const muscleSettings = Eq.struct({
  priority: Priority.priority,
  enabled: Bool.Eq,
  name: Str.Eq,
  id: Str.Eq,
})
export const _name = Lens.fromProp<MuscleSettings>()("name")
export const _id = Lens.fromProp<MuscleSettings>()("id")

export const getGroupSettings: (
  muscleGroupId: MuscleGroupId,
) => (values: Record<MuscleId_, MuscleSettingsBase>) => MuscleSettingsBase = (
  muscleGroupId,
) => {
  const muscleIds = muscleIdsOfGroup(muscleGroupId)

  return flow(
    Rec.filterWithIndex((id) => pipe(muscleIds, Arr.elem(muscleId_)(id))),
    Rec.reduce(muscleId_ as Ord.Ord<string>)(
      {
        enabled: false,
        priority: pipe(5, Priority.numberAsPriority.getOption, unsafeFromSome),
      } as MuscleSettingsBase,
      (result, v) => ({
        enabled: result.enabled || v.enabled,
        priority: result.priority,
      }),
    ),
  )
}

import { ErrorForUser, UserError } from "../error"

import { AnyAction } from "redux"
import { ActionType, createAction, PayloadAction } from "typesafe-actions"

export const setAvailableUpdate = createAction(
  "common/setAvailableUpdate",
  (newWorker: ServiceWorker) => ({ newWorker }),
)()
export const skipWaitingForUpdate = createAction(
  "common/skipWaitingForUpdate",
  () => ({}),
)()
export const addError = createAction("common/addError", (error: UserError) => ({
  error,
}))()
export const addErrorSentryEventId = createAction(
  "common/addErrorSentryId",
  (error: ErrorForUser, sentryEventId: string) => ({
    error,
    sentryEventId,
  }),
)()
export const setShownError = createAction(
  "common/setShownError",
  (error: UserError) => ({
    error,
  }),
)()
export const clearShownError = createAction(
  "common/clearShownError",
  () => ({}),
)()
export const showErrorFeedbackDialog = createAction(
  "common/showErrorFeedbackDialog",
  (sentryEventId: string) => ({
    sentryEventId,
  }),
)()
export const toggleErrorDetailsAreOpen = createAction(
  "common/toggleErrorDetailsAreOpen",
  (isOpen: boolean) => isOpen,
)()

export type Action = ActionType<
  | typeof setAvailableUpdate
  | typeof skipWaitingForUpdate
  | typeof addError
  | typeof addErrorSentryEventId
  | typeof setShownError
  | typeof clearShownError
  | typeof showErrorFeedbackDialog
  | typeof toggleErrorDetailsAreOpen
>

export const isPayloadAction = <t extends string, p>(
  action: AnyAction,
): action is PayloadAction<t, p> => "payload" in action

export type AnyErrorAction = PayloadAction<string, Error>

export const isAnyErrorAction = (
  action: PayloadAction<string, any>,
): action is AnyErrorAction => action.payload instanceof Error

import { FC, ReactNode, useMemo } from "react"
import { FormattedMessage } from "react-intl"
import { Input } from "reactstrap"

import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"

import {
  ActivityInstanceSorting,
  ActivitySorting,
  AnyActivitySorting,
} from "@fitnesspilot/data-activity/dist/activityFilters/ActivitySorting"

export type ActivitySortingInputProps<a extends AnyActivitySorting> = {
  className?: string
  value: a
  onChange: (sorting: a) => IO.IO<void>
}

type ActivitySortingInputInternalProps<a extends AnyActivitySorting> =
  ActivitySortingInputProps<a> & {
    extraOptions?: ReactNode
  }

export const ActivitySortingInput_ = <a extends AnyActivitySorting>({
  className,
  value,
  extraOptions,
  onChange,
}: ActivitySortingInputInternalProps<a>) => (
  <Input
    type="select"
    {...{ className }}
    value={value as any}
    onChange={(e) => pipe(e.target.value as any as a, onChange)()}
  >
    <FormattedMessage defaultMessage="Sort by">
      {(v) => <option disabled>{String(v)}</option>}
    </FormattedMessage>
    <FormattedMessage defaultMessage="Default">
      {(v) => <option value={ActivitySorting.default}>{String(v)}</option>}
    </FormattedMessage>
    <FormattedMessage defaultMessage="Alphabet">
      {(v) => <option value={ActivitySorting.alphabet}>{String(v)}</option>}
    </FormattedMessage>
    <FormattedMessage defaultMessage="Recently performed">
      {(v) => <option value={ActivitySorting.lastInstance}>{String(v)}</option>}
    </FormattedMessage>
    <FormattedMessage defaultMessage="Frequently performed">
      {(v) => <option value={ActivitySorting.frequency}>{String(v)}</option>}
    </FormattedMessage>
    <FormattedMessage defaultMessage="Muscle group">
      {(v) => <option value={ActivitySorting.muscleGroup}>{String(v)}</option>}
    </FormattedMessage>
    {extraOptions}
  </Input>
)

export const activitySortingInput =
  <a extends AnyActivitySorting>() =>
  (props: ActivitySortingInputInternalProps<a>) => (
    <ActivitySortingInput_<a> {...props} />
  )

export const ActivitySortingInput: FC<
  ActivitySortingInputProps<ActivitySorting>
> = activitySortingInput<ActivitySorting>()

const ActivityInstanceSortingInput_ =
  activitySortingInput<ActivityInstanceSorting>()
export const ActivityInstanceSortingInput: FC<
  ActivitySortingInputProps<ActivityInstanceSorting>
> = (props) => (
  <ActivityInstanceSortingInput_
    {...props}
    extraOptions={
      <FormattedMessage defaultMessage="Alignment">
        {(v) => (
          <option value={ActivityInstanceSorting.alignment}>{String(v)}</option>
        )}
      </FormattedMessage>
    }
  />
)

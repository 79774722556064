import { FC } from "react"
import { FaShieldAlt } from "react-icons/fa"
import { FormattedMessage, useIntl } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import { Button as Button_ } from "reactstrap"
import styled from "@emotion/styled"

import { constVoid, flow } from "fp-ts/es6/function"

import { Content } from "@fitnesspilot/components-common/dist/molecules/Content/Content"
import { Header } from "@fitnesspilot/components-common/dist/molecules/Header/Header"
import { PrivacyPolicy } from "@fitnesspilot/components-legal/dist/molecules/PrivacyPolicy/PrivacyPolicy"
import { Action, showCookieConsent } from "@fitnesspilot/data-legal"

import { MainTemplate } from "../../templates/Main/Main"

import { Dispatch } from "redux"

const mapState = () => ({})

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ = flow(dispatch, constVoid)

  return {
    onEditCookieConsent: flow(showCookieConsent, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

const Button = styled(Button_)`
  display: block;
`

type PropsFromRedux = ConnectedProps<typeof connector>

type OwnProps = {
  noLogin?: boolean
}

export type PrivacyPolicyPageProps = PropsFromRedux & OwnProps

export const PrivacyPolicyPage: FC<PrivacyPolicyPageProps> = ({
  noLogin,
  onEditCookieConsent,
}) => {
  const intl = useIntl()
  const title = intl.formatMessage({
    defaultMessage: "Privacy policy",
  })

  return (
    <MainTemplate
      {...{ noLogin }}
      header={<Header icon={<FaShieldAlt />} {...{ title }} />}
    >
      <Content>
        <PrivacyPolicy {...{ title }} />

        <Button onClick={onEditCookieConsent}>
          <FormattedMessage defaultMessage="Edit cookie consent" />
        </Button>
      </Content>
    </MainTemplate>
  )
}

export const PrivacyPolicyPageContainer = connector(PrivacyPolicyPage)

import * as Eq from "fp-ts/es6/Eq"
import * as Rec from "fp-ts/es6/Record"

import * as MuscleGroup from "./muscleGroups"

// @TODO Change this to a sum type and replace `number` with percent or so
export type MuscleGroupStatus = Record<MuscleGroup.MuscleGroups, false | number>
export const muscleGroupStatus: Eq.Eq<MuscleGroupStatus> = Rec.getEq(
  Eq.eqStrict,
)

export const defaultMuscleGroupStatus: MuscleGroupStatus = {
  [MuscleGroup.DorsalMuscleGroups.dorsalThorax]: false,
  [MuscleGroup.DorsalMuscleGroups.forearmAndHand]: false,
  [MuscleGroup.DorsalMuscleGroups.hipAndEverythingInferiorToIt]: false,
  [MuscleGroup.DorsalMuscleGroups.tricepsBrachii]: false,
  [MuscleGroup.VentralMuscleGroups.abdomen]: false,
  [MuscleGroup.VentralMuscleGroups.bicepsBrachii]: false,
  [MuscleGroup.VentralMuscleGroups.cardiovascular]: false,
  [MuscleGroup.VentralMuscleGroups.hipAndEverythingInferiorToIt]: false,
  [MuscleGroup.VentralMuscleGroups.shoulder]: false,
  [MuscleGroup.VentralMuscleGroups.ventralThorax]: false,
}

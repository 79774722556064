import { FC, HTMLAttributes, ReactNode } from "react"
import styled from "@emotion/styled"

const headerIconSize = "1em"

const TimelineHeader = styled(
  ({ className, ...props }: HTMLAttributes<HTMLElement>) => (
    <header className={`c-timelineHeader ${className ?? ""}`} {...props} />
  ),
)`
  padding-right: calc(${headerIconSize} + 10px);

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 2px;
    box-sizing: border-box;
    width: calc(${headerIconSize} + 6px);
    height: calc(${headerIconSize} + 6px);
    border: 3px solid ${({ theme }) => theme.colours.primary.toString()};
    border-radius: 100%;
    transform: translateY(-50%);
    background: ${({ theme }) => theme.colours.white.alpha(0.5).toString()};
  }
`

export type TimelineItemProps = {
  className?: string
  header: ReactNode
  children: ReactNode
}

const InnerTimelineItem: FC<TimelineItemProps> = ({
  className,
  header,
  children,
}) => (
  <li {...{ className }}>
    <TimelineHeader>{header}</TimelineHeader>

    {children}
  </li>
)

export const TimelineItem = styled(InnerTimelineItem)`
  position: relative;
  padding: 10px 10px 10px calc(80px + ${headerIconSize} + 10px);
  list-style-type: none;

  .c-timelineHeader {
    position: absolute;
    top: 10px;
    right: calc(100% - 10px - 80px - ${headerIconSize});
    max-width: calc(80px + ${headerIconSize});
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(10px + 80px + ${headerIconSize} / 2 - 6px);
    width: 2px;
    background: ${({ theme }) => theme.colours.grey[300].toString()};
  }
`

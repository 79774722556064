import * as App from "fp-ts/es6/Apply"
import * as E from "fp-ts/es6/Either"
import { intercalate } from "fp-ts/es6/Foldable"
import { flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as IOE from "fp-ts/es6/IOEither"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/Record"
import * as Str from "fp-ts/es6/string"

type EnvError = {
  $type: "missing"
  name: string
}

const formatEnvError = (e: EnvError) =>
  e.$type === "missing" ? `Missing entry: ${e.name}` : (null as never)

const getEnv =
  (k: string): IOE.IOEither<EnvError, string> =>
  () =>
    pipe(process.env[k], E.fromNullable({ $type: "missing", name: k }))

// I'd normally not add that extra callback around it, but it's needed for type inference.
const throwImpure =
  <a>() =>
  (e: any): IO.IO<a> =>
  () => {
    throw e
  }

class ValidationError extends Error {
  constructor(es: ReadonlyArray<EnvError>) {
    super(
      `Env validation errors occured:\n * ${pipe(
        es,
        Arr.map(formatEnvError),
        (as) => intercalate(Str.Monoid, Arr.Foldable)("\n * ", as),
      )}`,
    )
  }
}

const mkNew =
  <A extends new (...args: any) => any>(F: A) =>
  (...args: ConstructorParameters<A>) =>
    new F(...args)

const mapEnv = <a extends string | number | symbol>(rec: Record<a, string>) =>
  pipe(
    rec,
    Rec.map(flow(getEnv, IOE.mapLeft(Arr.of))),
    App.sequenceS(IOE.getApplicativeIOValidation(Arr.getMonoid<EnvError>())),
    IOE.getOrElse(flow(mkNew(ValidationError), throwImpure())),
    (a) => a as IO.IO<Record<a, string>>,
  )()

const cfg = {
  sentry: {
    enable:
      pipe(
        getEnv("REACT_APP_SENTRY_ENABLE"),
        IOE.getOrElse(flow(Arr.of, mkNew(ValidationError), throwImpure())),
      )() === "true",
    debug:
      pipe(
        getEnv("REACT_APP_SENTRY_DEBUG"),
        IOE.getOrElse(flow(Arr.of, mkNew(ValidationError), throwImpure())),
      )() === "true",
    ...mapEnv({
      dsn: "REACT_APP_SENTRY_DSN",
    }),
  },
  firebase: mapEnv({
    apiKey: "REACT_APP_FIREBASE_API_KEY",
    projectId: "REACT_APP_FIREBASE_PROJECT_ID",
    databaseURL: "REACT_APP_FIREBASE_DATABASE_URL",
    authDomain: "REACT_APP_FIREBASE_AUTH_DOMAIN",
    storageBucket: "REACT_APP_FIREBASE_STORAGE_BUCKET",
    messagingSenderId: "REACT_APP_FIREBASE_MESSAGING_SENDER_ID",
    appId: "REACT_APP_FIREBASE_APP_ID",
    measurementId: "REACT_APP_FIREBASE_MEASUREMENT_ID",
  }),
  fcm: mapEnv({
    vapidKey: "REACT_APP_FCM_VAPID_KEY",
  }),
  googleApi: mapEnv({
    clientId: "REACT_APP_GOOGLEAPI_CLIENTID",
    apiKey: "REACT_APP_GOOGLE_API_KEY",
  }),
}

export const { sentry, firebase, fcm, googleApi } = cfg
export const env = pipe(
  getEnv("NODE_ENV"),
  IOE.getOrElse(flow(Arr.of, mkNew(ValidationError), throwImpure())),
)()
export const stage = pipe(
  getEnv("REACT_APP_STAGE"),
  IOE.getOrElse(flow(Arr.of, mkNew(ValidationError), throwImpure())),
)()
export const version = pipe(
  getEnv("REACT_APP_VERSION"),
  IOE.getOrElse(flow(Arr.of, mkNew(ValidationError), throwImpure())),
)()
export const apiBaseUrl = pipe(
  getEnv("REACT_APP_API_BASE_URL"),
  IOE.getOrElse(flow(Arr.of, mkNew(ValidationError), throwImpure())),
)()

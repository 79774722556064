import { FC, ReactNode } from "react"
import styled from "@emotion/styled"

export type HeaderProps = {
  icon: ReactNode
  title: ReactNode
  children?: ReactNode
}

const PartialHeader = styled.header`
  position: relative;
  display: flex;
  box-shadow: 0 3px 10px -4px rgba(0, 0, 0, 0.24);
`
const HeadIcon = styled.div`
  --size: 56px;
  min-width: var(--size);
  min-height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colours.primary.toString()};
  color: #fff;
  font-size: 21px;

  @media screen and (min-width: 576px) {
    --size: 70px;
  }
`
const HeadNav = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
`
const ContentTitle = styled.div`
  display: contents;
`
const Title = styled.h1`
  margin: 0;
`

export const Header: FC<HeaderProps> = ({ icon, title, children }) => (
  <PartialHeader>
    <HeadIcon>{icon}</HeadIcon>
    <HeadNav>
      <ContentTitle>
        <Title>{title}</Title>
      </ContentTitle>

      <div>{children}</div>
    </HeadNav>
  </PartialHeader>
)

import { ReactNode } from "react"
import { FaCheck, FaHeart, FaRunning, FaWeight } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import styled from "@emotion/styled"

import { constVoid, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as St from "fp-ts/es6/ReadonlySet"

import { ActivityTagName } from "@fitnesspilot/components-activity/dist/atoms/ActivityTagName/ActivityTagName"
import { EquipmentName } from "@fitnesspilot/components-activity/dist/atoms/EquipmentName/EquipmentName"
import {
  Chip as Chip_,
  ChipProps,
} from "@fitnesspilot/components-common/dist/atoms/Chip/Chip"
import { MuscleGroupName } from "@fitnesspilot/components-human-body/dist/atoms/MuscleGroupName/MuscleGroupName"
import { activityTag } from "@fitnesspilot/data-activity/dist/activity/ActivityTag"
import { equipmentType } from "@fitnesspilot/data-activity/dist/activity/Equipment"
import { ActivityFilters } from "@fitnesspilot/data-activity/dist/activityFilters/ActivityFilters"
import {
  ActivityInstanceFilters,
  foldActivityInstanceFilterValue,
} from "@fitnesspilot/data-activity/dist/activityFilters/ActivityInstanceFilters"
import {
  alignment,
  numberAsAlignment,
  numberAsAlignmentUnsafe,
} from "@fitnesspilot/data-human-body/dist/Alignment"
import { muscleGroupId } from "@fitnesspilot/data-human-body/dist/muscleGroups"

const Chip = (
  props: Omit<ChipProps, "selected" | "showDelete" | "onClick">,
) => <Chip_ selected={false} showDelete={true} onClick={constVoid} {...props} />

export type FilterProps = {
  children: ReactNode
}

export const Filter = ({ children }: FilterProps) => (
  <div style={{ backgroundColor: "red" }}>{children}</div>
)

export type ActivityFilterListProps<a extends ActivityFilters> = {
  className?: string
  filters: a
  onDelete: <b extends keyof a>(filter: b, value: a[b]) => IO.IO<void>
}
export const ActivityFilterList_ = <a extends ActivityFilters>({
  className,
  filters,
  onDelete,
}: ActivityFilterListProps<a>) => (
  <div {...{ className }}>
    {Object.entries(filters).map(([key, value]) =>
      foldActivityInstanceFilterValue<ReactNode, keyof ActivityInstanceFilters>(
        (tags) =>
          pipe(
            tags,
            St.toReadonlyArray(activityTag),
            Arr.map((tag) => (
              <Chip
                icon={<FaRunning />}
                onDelete={onDelete(
                  "tags",
                  St.fromReadonlyArray(activityTag)([tag]),
                )}
                key={`${key}-${tag}`}
              >
                <ActivityTagName id={tag} />
              </Chip>
            )),
          ),
        (muscleGroups) =>
          pipe(
            muscleGroups,
            St.toReadonlyArray(muscleGroupId),
            Arr.map((mg) => (
              <Chip
                icon={<>💪</>}
                onDelete={onDelete(
                  "muscleGroups",
                  St.fromReadonlyArray(muscleGroupId)([mg]),
                )}
                key={`${key}-${mg}`}
              >
                <MuscleGroupName id={mg} />
              </Chip>
            )),
          ),
        (equipments) =>
          pipe(
            equipments,
            St.toReadonlyArray(equipmentType),
            Arr.map((eq) => (
              <Chip
                icon={<FaWeight />}
                onDelete={onDelete(
                  "equipments",
                  St.fromReadonlyArray(equipmentType)([eq]),
                )}
                key={`${key}-${eq}`}
              >
                <EquipmentName id={eq} />
              </Chip>
            )),
          ),
        (onlyLiked) =>
          onlyLiked && (
            <Chip
              icon={<FaHeart />}
              onDelete={onDelete<"onlyLiked">("onlyLiked", true)}
              key={`${key}-onlyLiked`}
            >
              <FormattedMessage defaultMessage="liked" />
            </Chip>
          ),
        (minAlignment) =>
          Ord.gt(alignment)(minAlignment, numberAsAlignmentUnsafe(0)) && (
            <Chip
              icon={<FaCheck />}
              onDelete={onDelete("minAlignment" as any, minAlignment)}
              key={`${key}-minAlignment`}
            >
              <FormattedMessage defaultMessage="alignment" />{" "}
              {pipe(minAlignment, numberAsAlignment.reverseGet)}% +
            </Chip>
          ),
      )(key as keyof ActivityInstanceFilters, value),
    )}
  </div>
)

export const ActivityFilterList = <a extends ActivityFilters>(
  props: ActivityFilterListProps<a>,
) => {
  const Component = styled((props: ActivityFilterListProps<a>) => (
    <ActivityFilterList_<a> {...props} />
  ))`
    display: inline-flex;
    padding: 10px 0;

    & > .c-chip {
      margin-right: 4px;
    }
  `
  return <Component {...props} />
}

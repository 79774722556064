import { ComponentType, FC, ReactNode } from "react"
import { FaCheck } from "react-icons/fa"
import { FormattedMessage, IntlShape, useIntl } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import { Link } from "react-router-dom"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import { flow, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import { arrTrav, explicitOrder } from "@fitnesspilot/data-common"

import { SupraNavLink } from "@fitnesspilot/components-common/dist/molecules/SupraNav/SupraNavLink"
import { CoachTaskImportance, selectors } from "@fitnesspilot/data-coach-task"
import * as CoachTask from "@fitnesspilot/data-coach-task/dist/CoachTask"

import { RootState } from "./reduxStore"

const Badge = styled(
  ({
    className,
    children,
  }: {
    colour: "danger" | "warning" | "primary" | "success" | "transparent"
    className?: string
    children: ReactNode
  }) => <div {...{ className, children }} />,
)`
  display: inline-block;
  text-align: center;
  width: 1.5em;
  ${({ theme, colour }) =>
    colour !== "transparent" &&
    css`
      color: ${theme.colours.white.toString()};
      background-color: ${{
        danger: "#dd5b57",
        warning: "#f8c514",
        primary: "#309edb",
        success: "#53c095",
      }[colour]};
      border-radius: 3px;
    `}
`

const SupraBadgeNavLink = styled(
  ({
    value,
    children,
    colour,
    className,
  }: {
    value: number
    children: ReactNode
    colour: "danger" | "warning" | "primary" | "success"
    className?: string
  }) => (
    <SupraNavLink tag={Link} to="/" {...{ className }}>
      {value === 0 ? (
        <Badge colour="transparent">
          <FaCheck />
        </Badge>
      ) : (
        <Badge colour={colour}>{value}</Badge>
      )}{" "}
      {children}
    </SupraNavLink>
  ),
)`
  ${({ value }) =>
    value === 0 &&
    css`
      color: #53c095;
    `}
`

export enum ShownCoachTaskImportance {
  perfect = "perfect",
  optional = "optional",
  recommended = "recommended",
  important = "important",
}
export const shownCoachTaskImportance: Ord.Ord<ShownCoachTaskImportance> =
  explicitOrder([
    ShownCoachTaskImportance.important,
    ShownCoachTaskImportance.recommended,
    ShownCoachTaskImportance.optional,
    ShownCoachTaskImportance.perfect,
  ])

const coachTaskImportanceStepMessages = (
  intl: IntlShape,
): Record<
  ShownCoachTaskImportance,
  {
    journey: string
    Name: ComponentType<{ count: number }>
    colour: Parameters<typeof SupraBadgeNavLink>[0]["colour"]
  }
> => ({
  [ShownCoachTaskImportance.perfect]: {
    journey: intl.formatMessage({
      defaultMessage:
        "Fulfilling all these tasks allows us to give you the best recommendations possible.",
    }),
    Name: ({ count }) => (
      <FormattedMessage
        defaultMessage="{count, plural,
                          one {Task}
                          other {Tasks}
                        } to perfect"
        values={{ count }}
      />
    ),
    colour: "primary",
  },
  [ShownCoachTaskImportance.optional]: {
    journey: intl.formatMessage({
      defaultMessage: "Optional tasks are optional",
    }),
    Name: ({ count }) => (
      <FormattedMessage
        defaultMessage="Optional {count, plural,
                          one {task}
                          other {tasks}
                        }"
        values={{ count }}
      />
    ),
    colour: "success",
  },
  [ShownCoachTaskImportance.recommended]: {
    journey: intl.formatMessage({
      defaultMessage:
        "Recommended tasks improve the accuracy of our algorithms",
    }),
    Name: ({ count }) => (
      <FormattedMessage
        defaultMessage="Recommended {count, plural,
                          one {task}
                          other {tasks}
                        }"
        values={{ count }}
      />
    ),
    colour: "warning",
  },
  [ShownCoachTaskImportance.important]: {
    journey: intl.formatMessage({
      defaultMessage:
        "Important tasks have a large influence on statistics and recommendations",
    }),
    Name: ({ count }) => (
      <FormattedMessage
        defaultMessage="Important {count, plural,
                          one {task}
                          other {tasks}
                        }"
        values={{ count }}
      />
    ),
    colour: "danger",
  },
})

const mapState = (
  state: RootState,
  { importance }: CoachTaskImportanceStepContainerProps,
) => ({
  count: pipe(
    state,
    selectors.state
      .composeLens(selectors.coachTasks)
      .composeTraversal(arrTrav<CoachTask.CoachTask>())
      .filter(flow(CoachTask._dismissedOn.get, Opt.isNone))
      .filter(flow(CoachTask._completedOn.get, Opt.isNone))
      .filter(
        flow(
          CoachTask._importance.get,
          (a) =>
            importance === ShownCoachTaskImportance.perfect ||
            a === (importance as unknown as CoachTaskImportance),
        ),
      )
      .asFold().getAll,
    (a) => a.length,
  ),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

export type CoachTaskImportanceStepContainerProps = {
  importance: ShownCoachTaskImportance
}

export type CoachTaskImportanceStepProps = PropsFromRedux &
  CoachTaskImportanceStepContainerProps

export const CoachTaskImportanceStep: FC<CoachTaskImportanceStepProps> = ({
  importance,
  count,
}) => {
  const intl = useIntl()
  const msg = coachTaskImportanceStepMessages(intl)[importance]

  return (
    <SupraBadgeNavLink value={count} colour={msg.colour}>
      <msg.Name {...{ count }} />
    </SupraBadgeNavLink>
  )
}

export const CoachTaskImportanceStepContainer = connector(
  CoachTaskImportanceStep,
)

import * as Eq from "fp-ts/es6/Eq"
import { flow, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { Index, Prism } from "monocle-ts"
import { atReadonlySet } from "monocle-ts/lib/At/ReadonlySet"
import { indexReadonlyArray } from "monocle-ts/lib/Index/ReadonlyArray"
import { now, time } from "time-ts/es6"
import { composeLensAt, optionLensToOptional } from "@fitnesspilot/data-common"

import * as WithId from "@fitnesspilot/data-activity/dist/activity/WithId"
import * as ActivityInstance from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"

import * as Event from "../calendar/Event"
import * as EventOrRecommendation from "../calendar/EventOrRecommendation"
import * as EventsOrRecommendations from "../calendar/EventsOrRecommendations"
import * as Timeslot from "../calendar/Timeslot"
import * as actions from "./actions"
import { Action } from "./actions"
import * as AddActivity from "./AddActivity"
import * as EditingEvent from "./EditingEvent"
import * as selectors from "./selectors"
import { initialState, State } from "./state"

import { createReducer } from "typesafe-actions"

const editingEventOpt = optionLensToOptional(selectors.editingEvent)
const _eventsAt = composeLensAt(
  selectors.eventsOrRecommendations,
  EventsOrRecommendations._eventsAt,
)

export default createReducer<State, Action>(initialState)
  .handleAction(actions.resetState, () => initialState)
  .handleAction(
    actions.fetchEventsOrRecommendationsBetweenAsync.success,
    (
      state,
      {
        payload: {
          between: [start, end],
          eventsOrRecommendations,
          timeslots,
        },
      },
    ) =>
      pipe(
        state,
        selectors.eventsOrRecommendations.modify(
          flow(
            Arr.filter(
              (eventOrRecommendation) =>
                !(
                  pipe(
                    EventOrRecommendation._start.get(eventOrRecommendation),
                    Ord.between(time)(start, end),
                  ) ||
                  pipe(
                    EventOrRecommendation._end.get(eventOrRecommendation),
                    Ord.between(time)(start, end),
                  )
                ),
            ),
            (arr) =>
              Arr.getMonoid<EventOrRecommendation.EventOrRecommendation>().concat(
                arr,
                eventsOrRecommendations,
              ),
          ),
        ),
        selectors.timeslots.modify(
          flow(
            Arr.filter(
              (timeslot) =>
                !pipe(
                  Timeslot._time.get(timeslot),
                  Ord.between(time)(start, end),
                ),
            ),
            (arr) => Arr.getMonoid<Timeslot.Timeslot>().concat(arr, timeslots),
          ),
        ),
        selectors.loadedRanges
          .composeLens(atReadonlySet(Eq.tuple(time, time)).at([start, end]))
          .set(true),
      ),
  )
  // .handleAction(actions.fetchEventsBetweenAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(
    actions.fetchEventByIdAsync.success,
    (state, { payload: { event } }) =>
      pipe(
        state,
        selectors.eventsOrRecommendations.modify(
          flow(
            Arr.filter(
              (eventOrRecommendation) =>
                !pipe(
                  eventOrRecommendation,
                  EventOrRecommendation._EventWithId.composeLens(
                    Event._eventWithId._id,
                  ).getOption,
                  (a) =>
                    Opt.getEq(Event.eventId).equals(
                      a,
                      pipe(event, Event._eventWithId._id.get, Opt.some),
                    ),
                ),
            ),
            (arr) =>
              Arr.getMonoid<EventOrRecommendation.EventOrRecommendation>().concat(
                arr,
                pipe(
                  event,
                  EventOrRecommendation._EventWithId.reverseGet,
                  Arr.of,
                ),
              ),
          ),
        ),
      ),
  )
  // .handleAction(actions.fetchEventByIdAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(actions.startEditingEvent, (state, { payload: { event } }) =>
    pipe(state, editingEventOpt.set(event)),
  )
  .handleAction(actions.cancelEditingEvent, (state) =>
    pipe(state, selectors.editingEvent.set(Opt.none)),
  )
  .handleAction(actions.setEventTitle, (state, { payload: { title } }) =>
    pipe(
      state,
      selectors.editingEventValOpt.composeLens(EditingEvent._title).set(title),
    ),
  )
  .handleAction(actions.setEventBetween, (state, { payload: { between } }) =>
    pipe(
      state,
      selectors.editingEventValOpt
        .composeLens(EditingEvent._between)
        .set(between),
    ),
  )
  .handleAction(
    actions.setEventRecurrence,
    (state, { payload: { recurrence } }) =>
      pipe(
        state,
        selectors.editingEventValOpt
          .composeLens(EditingEvent._recurrence)
          .set(recurrence),
      ),
  )
  .handleAction(actions.setEventVideo, (state, { payload: { workoutVideo } }) =>
    pipe(
      state,
      selectors.editingEventValOpt
        .composeLens(EditingEvent._video)
        .set(workoutVideo),
      selectors.editingEventValOpt.composeLens(EditingEvent._activities).set(
        pipe(
          workoutVideo,
          Opt.fold(
            () => Arr.empty,
            (video) => video.activities,
          ),
        ),
      ),
    ),
  )
  .handleAction(actions.setAddActivityStep, (state, { payload: { step } }) =>
    pipe(
      state,
      selectors.editingEventValOpt
        .composeLens(EditingEvent._addActivity)
        .composeLens(AddActivity._step)
        .set(step),
      selectors.editingEventValOpt
        .composeLens(EditingEvent._addActivity)
        .composeLens(AddActivity._search)
        .set(Opt.none),
    ),
  )
  .handleAction(actions.addActivities, (state, { payload: { activities } }) =>
    pipe(
      state,
      selectors.editingEvent
        .composePrism(Prism.some())
        .composeLens(WithId._value())
        .composeLens(EditingEvent._activities)
        .modify((acts) => acts.concat(activities)),
    ),
  )
  .handleAction(
    actions.removeActivity,
    (state, { payload: { activityIndex } }) =>
      pipe(
        state,
        selectors.editingEvent
          .composePrism(Prism.some())
          .composeLens(WithId._value())
          .composeLens(EditingEvent._activities)
          .modify((as) => Arr.unsafeDeleteAt(activityIndex, as)),
      ),
  )
  .handleAction(
    actions.updateActivity,
    (state, { payload: { activityIndex, activity } }) =>
      pipe(
        state,
        selectors.editingEvent
          .composePrism(Prism.some())
          .composeLens(WithId._value())
          .composeLens(EditingEvent._activities)
          .composeOptional(
            indexReadonlyArray<ActivityInstance.ActivityInstance>().index(
              activityIndex,
            ),
          )
          .set(activity),
      ),
  )
  .handleAction(
    actions.moveActivity,
    (state, { payload: { oldActivityIndex, newActivityIndex } }) =>
      pipe(
        state,
        selectors.editingEvent
          .composePrism(Prism.some())
          .composeLens(WithId._value())
          .composeLens(EditingEvent._activities)
          .modify((acts) =>
            pipe(
              acts,
              Arr.lookup(oldActivityIndex),
              Opt.fold(
                () => acts,
                (act) =>
                  pipe(
                    acts,
                    (as) => Arr.unsafeDeleteAt(oldActivityIndex, as),
                    (as) => Arr.unsafeInsertAt(newActivityIndex, act, as),
                  ),
              ),
            ),
          ),
      ),
  )
  .handleAction(actions.selectActivity, (state, { payload: { activity } }) =>
    pipe(
      state,
      selectors.editingEventValOpt
        .composeLens(EditingEvent._selectedActivities)
        .modify(Arr.append(activity)),
    ),
  )
  .handleAction(
    actions.unselectActivity,
    (state, { payload: { activityIndex } }) =>
      pipe(
        state,
        selectors.editingEventValOpt
          .composeLens(EditingEvent._selectedActivities)
          .modify((as) => Arr.unsafeDeleteAt(activityIndex, as)),
      ),
  )
  .handleAction(
    actions.updateSelectedActivity,
    (state, { payload: { activityIndex, activity } }) =>
      pipe(
        state,
        selectors.editingEventValOpt
          .composeLens(EditingEvent._selectedActivities)
          .modify((as) => Arr.unsafeUpdateAt(activityIndex, activity, as)),
      ),
  )
  .handleAction(actions.saveSelectedActivities, (state) =>
    pipe(
      state,
      selectors.editingEventValOpt.modify((evt) =>
        pipe(
          evt,
          EditingEvent._activities.modify((as) =>
            as.concat(EditingEvent._selectedActivities.get(evt)),
          ),
          EditingEvent._selectedActivities.set([]),
        ),
      ),
      selectors.editingEventValOpt
        .composeLens(EditingEvent._addActivity)
        .set(AddActivity.empty),
    ),
  )
  .handleAction(actions.cancelSelectedActivities, (state) =>
    pipe(
      state,
      selectors.editingEventValOpt
        .composeLens(EditingEvent._selectedActivities)
        .set([]),
      selectors.editingEventValOpt
        .composeLens(EditingEvent._addActivity)
        .set(AddActivity.empty),
    ),
  )
  .handleAction(actions.setCatalogSearch, (state, { payload: { value } }) =>
    pipe(
      state,
      selectors.editingEventValOpt
        .composeLens(EditingEvent._addActivity)
        .composeLens(AddActivity._search)
        .set(value),
    ),
  )
  .handleAction(actions.setCatalogSorting, (state, { payload: { sorting } }) =>
    pipe(
      state,
      selectors.editingEventValOpt
        .composeLens(EditingEvent._addActivity)
        .composeLens(AddActivity._sorting)
        .set(sorting),
    ),
  )
  .handleAction(actions.setCatalogFilters, (state, { payload: { filters } }) =>
    pipe(
      state,
      selectors.editingEventValOpt
        .composeLens(EditingEvent._addActivity)
        .composeLens(AddActivity._filters)
        .set(filters),
      selectors.editingEventValOpt
        .composeLens(EditingEvent._addActivity)
        .composeLens(AddActivity._search)
        .set(Opt.none),
    ),
  )
  .handleAction(
    actions.saveEventAsync.success,
    (state, { payload: { event } }) =>
      pipe(state, Index.fromAt(_eventsAt).index(event.id).set(event.value)),
  )
  // .handleAction(actions.saveEventAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(
    actions.confirmEventAsync.success,
    (state, { payload: eventId }) =>
      pipe(
        state,
        Index.fromAt(_eventsAt)
          .index(eventId)
          .modify(Event._confirmed.set(pipe(now(), Opt.some))),
      ),
  )
  // .handleAction(actions.confirmEventAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(
    actions.saveEditingEventAsync.success,
    (state, { payload: { event } }) =>
      pipe(
        state,
        Index.fromAt(_eventsAt).index(event.id).set(event.value),
        selectors.editingEvent.set(Opt.none),
      ),
  )
  // .handleAction(actions.saveEditingEventAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(
    actions.deleteEventAsync.success,
    (state, { payload: { event } }) =>
      pipe(state, _eventsAt.at(event.id).set(Opt.none)),
  )
  // .handleAction(actions.deleteEventAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(actions.deleteEditingEventAsync.success, (state) =>
    pipe(
      state,
      selectors.editingEventOpt
        .composeLens(
          WithId._id<Opt.Option<Event.EventId>, EditingEvent.EditingEvent>(),
        )
        .composePrism(Prism.some()).getOption,
      Opt.fold(
        () => state,
        (id) => _eventsAt.at(id).set(Opt.none)(state),
      ),
      selectors.editingEvent.set(Opt.none),
    ),
  )
  // .handleAction(actions.deleteEditingEventAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(
    actions.fetchCatalogForEditingEventAsync.success,
    (state, { payload: { catalog } }) =>
      pipe(
        state,
        selectors.editingEventOpt
          .composeLens(
            WithId._value<
              Opt.Option<Event.EventId>,
              EditingEvent.EditingEvent
            >(),
          )
          .composeLens(EditingEvent._catalog)
          .set(catalog),
      ),
  )
  // .handleAction(actions.fetchCatalogForEditingEventAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  // @TODO
  .handleAction(
    actions.fetchRecommendationsForCalendarAsync.success,
    (state) => state,
  )
  // .handleAction(actions.fetchRecommendationsForCalendarAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(
    actions.fetchRecommendationsForEditingEventAsync.success,
    (
      state,
      {
        payload: {
          recommendations: [{ title, ...value }],
        },
      },
    ) =>
      pipe(
        state,
        selectors.editingEventOpt
          .composeLens(
            WithId._value<
              Opt.Option<Event.EventId>,
              EditingEvent.EditingEvent
            >(),
          )
          .modify(({ recurrence }) => ({
            title: Opt.some(title),
            selectedActivities: [],
            catalog: [],
            recommendations: [],
            addActivity: AddActivity.empty,
            recurrence,
            ...value,
          })),
      ),
  )
  // .handleAction(actions.fetchRecommendationsForEditingEventAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(
    actions.fetchVideosForEditingEventAsync.success,
    (state, { payload: { videos } }) =>
      pipe(
        state,
        selectors.editingEventOpt
          .composeLens(
            WithId._value<
              Opt.Option<Event.EventId>,
              EditingEvent.EditingEvent
            >(),
          )
          .composeLens(EditingEvent._addActivity)
          .composeLens(AddActivity._videos)
          .set(videos),
      ),
  )
  // .handleAction(actions.fetchVideosForEditingEventAsync.failure, (state, { payload }) =>
  //   selectors.error.set(payload)(state),
  // )
  .handleAction(actions.calendarSelect, (state, { payload: sel }) =>
    pipe(state, optionLensToOptional(selectors.calendarSelection).set(sel)),
  )
  .handleAction(actions.calendarUnselect, (state) =>
    pipe(state, selectors.calendarSelection.set(Opt.none)),
  )

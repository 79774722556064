import { ReactNode, useEffect } from "react"
import { connect, ConnectedProps, useStore } from "react-redux"
import { useLocation, useMatch } from "react-router-dom"

import { constVoid, flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as St from "fp-ts/es6/ReadonlySet"

import { setExerciseListFilters } from "@fitnesspilot/data-activity"
import { activityTag } from "@fitnesspilot/data-activity/dist/activity/ActivityTag"
import { equipmentType } from "@fitnesspilot/data-activity/dist/activity/Equipment"
import { ActivityFilters } from "@fitnesspilot/data-activity/dist/activityFilters/ActivityFilters"
import {
  boolToString,
  setToString,
  stringToBool,
  stringToSet,
} from "@fitnesspilot/data-activity/dist/activityFilters/searchString"
import * as EventData from "@fitnesspilot/data-event/dist/store"
import { muscleGroupId } from "@fitnesspilot/data-human-body/dist/muscleGroups"

import { RootAction, RootState } from "./reduxStore"

import "./App.css"
import { Dispatch } from "redux"
import ReduxQuerySync, {
  ReduxQuerySyncParamOptions,
  ReduxQuerySyncParams,
} from "redux-query-sync"

const mkFilterOptions =
  <k extends keyof ActivityFilters>(filter: k) =>
  (
    valueToString?: (v: ActivityFilters[k]) => string,
    stringToValue?: (v: string | undefined) => ActivityFilters[k],
    defaultValue?: ActivityFilters[k] | undefined,
  ): ReduxQuerySyncParamOptions<RootState, ActivityFilters[k]> => ({
    selector: (s) => s.activity.exerciseList.filters[filter],
    action: (v) => setExerciseListFilters({ [filter]: v }),
    valueToString,
    stringToValue,
    defaultValue,
  })

const mapState = () => ({})

const mapDispatch = (dispatch: Dispatch<EventData.Action>) => {
  const dispatch_ =
    (act: EventData.Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, constVoid)

  return {
    cancelEditingEvent: flow(EventData.cancelEditingEvent, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type RouteEffectsProps = PropsFromRedux & {
  children?: ReactNode
}

let unsubscribeQuerySync: () => void
const querySyncParamsByPath: Rec.ReadonlyRecord<string, ReduxQuerySyncParams> =
  {
    "/sports/exercises": {
      tags: mkFilterOptions("tags")(
        setToString(activityTag),
        stringToSet(activityTag),
        St.empty,
      ),
      muscleGroups: mkFilterOptions("muscleGroups")(
        setToString(muscleGroupId),
        stringToSet(muscleGroupId),
        St.empty,
      ),
      equipments: mkFilterOptions("equipments")(
        setToString(equipmentType),
        stringToSet(equipmentType),
        St.empty,
      ),
      onlyLiked: mkFilterOptions("onlyLiked")(
        boolToString,
        stringToBool,
        false,
      ),
    },
  }

const RouteEffects_ = ({ cancelEditingEvent, children }: RouteEffectsProps) => {
  const store = useStore<RootState, RootAction>()
  const location = useLocation()
  const matchQuerySyncPaths = Rec.has(location.pathname, querySyncParamsByPath)
  useEffect(() => {
    unsubscribeQuerySync?.()
    if (matchQuerySyncPaths) {
      unsubscribeQuerySync = ReduxQuerySync({
        store,
        params: querySyncParamsByPath[location.pathname],
        initialTruth: "location",
      })
    }
  }, [location.pathname, store, matchQuerySyncPaths])

  const matchEventDetails = !!useMatch("/calendar/events/:eventId")
  useEffect(() => {
    if (!matchEventDetails) {
      cancelEditingEvent(false)()
    }
  }, [matchEventDetails, cancelEditingEvent])

  return <>{children}</>
}

export const RouteEffects = connector(RouteEffects_)

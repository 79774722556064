import { Ref, useState } from "react"
import { FaDumbbell, FaRegClock } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import Media from "react-media"
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"

import { constNull, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import { dateAsTime, Time } from "time-ts/es6"

import { Clickable } from "@fitnesspilot/components-common/dist/atoms/Clickable/Clickable"
import { StringInput } from "@fitnesspilot/components-common/dist/atoms/input/StringInput"
import { DeleteButton } from "@fitnesspilot/components-common/dist/molecules/DeleteButton/DeleteButton"
import { Header } from "@fitnesspilot/components-common/dist/molecules/Header/Header"
import { Alignment } from "@fitnesspilot/components-human-body/dist/molecules/Alignment/Alignment"
import * as alignment from "@fitnesspilot/data-human-body/dist/Alignment"

const Title = styled.div`
  display: flex;
  gap: 10px;
`

const StyledDeleteButton = styled(DeleteButton)`
  margin-left: 10px;
`

const ClockIcon = styled(FaRegClock)`
  color: ${({ theme }) => theme.colours.primary.toString()};
`

const ClickableTime = styled(Clickable)`
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 10px;
`

const NoWrap = styled.span`
  white-space: nowrap;
`

export type EventHeaderProps = {
  title: Opt.Option<string>
  alignment: Opt.Option<alignment.Alignment>
  between: readonly [Time, Time]
  showDelete: boolean
  timeButtonRef?: Ref<HTMLButtonElement>
  onChangeTitle: (title: Opt.Option<string>) => IO.IO<void>
  onClickTime: IO.IO<void>
  onDelete: IO.IO<void>
}

export const EventHeader = ({
  title,
  alignment,
  between,
  showDelete,
  timeButtonRef,
  onChangeTitle,
  onClickTime,
  onDelete,
}: EventHeaderProps) => {
  const [descriptionIsOpen, onDescriptionIsOpenChange] = useState(false)

  const theme = useTheme()

  return (
    <Header
      icon={<FaDumbbell />}
      title={
        <Title>
          <FormattedMessage defaultMessage="Event">
            {(placeholder) => (
              <StringInput
                value={title}
                {...{ placeholder }}
                onChange={onChangeTitle}
                data-help-mode="name"
              />
            )}
          </FormattedMessage>

          {pipe(
            alignment,
            Opt.fold(constNull, (a) => (
              <Alignment
                value={a}
                {...{ descriptionIsOpen }}
                onDescriptionIsOpenChange={(v) => () =>
                  onDescriptionIsOpenChange(v)
                }
                data-help-mode="eventAlignment"
              />
            )),
          )}

          <ClickableTime
            ref={timeButtonRef}
            onClick={onClickTime}
            data-help-mode="time"
          >
            <Media queries={theme.media}>
              {({ sm }) =>
                sm && (
                  <NoWrap>
                    <FormattedMessage
                      defaultMessage="{start, date, ::yMdHm}–{end, date, ::Hm}"
                      values={{
                        start: pipe(between[0], dateAsTime.reverseGet),
                        end: pipe(between[1], dateAsTime.reverseGet),
                      }}
                    />
                  </NoWrap>
                )
              }
            </Media>
            <ClockIcon />
          </ClickableTime>
        </Title>
      }
    >
      {showDelete && (
        <StyledDeleteButton onClick={onDelete}>
          <Media queries={theme.media}>
            {({ xl }) =>
              xl && <FormattedMessage defaultMessage="Delete event" />
            }
          </Media>
        </StyledDeleteButton>
      )}
    </Header>
  )
}

import { FC } from "react"
import { IntlShape, useIntl } from "react-intl"

import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"

import { stringAsActivityId } from "@fitnesspilot/data-activity/dist/activity/ActivityId"
import {
  foldSportId,
  SportId,
} from "@fitnesspilot/data-activity/dist/sport/SportId"

import { activityName } from "../ActivityName/ActivityName"
import { activityTagName } from "../ActivityTagName/ActivityTagName"

export type SportNameProps = {
  id: SportId
  def?: string
}

export const sportName =
  (intl: IntlShape) =>
  ({ id, def }: SportNameProps) =>
    pipe(
      id,
      foldSportId(
        (id) => activityTagName(intl)({ id }),
        (id) =>
          activityName(intl)({
            id,
            def: pipe(
              def,
              Opt.fromNullable,
              Opt.getOrElse(() => pipe(id, stringAsActivityId.reverseGet)),
            ),
          }),
      ),
    )

export const SportName: FC<SportNameProps> = (props) => (
  <>{sportName(useIntl())(props)}</>
)

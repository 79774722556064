import { FC, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import { Button } from "reactstrap"
import styled from "@emotion/styled"

import * as Func from "fp-ts/es6/function"
import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { now, time } from "time-ts/es6"
import { optionLensToOptional } from "@fitnesspilot/data-common"

import { ClearButtonGroup } from "@fitnesspilot/components-common/dist/atoms/ClearButtonGroup/ClearButtonGroup"
import * as ActivityData from "@fitnesspilot/data-activity"
import * as Timeslot from "@fitnesspilot/data-event/dist/calendar/Timeslot"
import * as EventData from "@fitnesspilot/data-event/dist/store"
import { Dorsoventral } from "@fitnesspilot/data-human-body/dist/anatomicalAxes"
import * as Body from "@fitnesspilot/data-human-body/dist/humanBody"
import { MuscleGroups } from "@fitnesspilot/data-human-body/dist/muscleGroups"
import * as MuscleGroupStatus from "@fitnesspilot/data-human-body/dist/MuscleGroupStatus"
import { Sex } from "@fitnesspilot/data-human-body/dist/sex"
import * as UserData from "@fitnesspilot/data-user"

import { BodyMap } from "../BodyMap/BodyMap"

import { Dispatch } from "redux"
import { push as pushHistory, RouterActions } from "redux-first-history"

const body = UserData.selectors.state.composeLens(UserData.selectors.body)

const leqTime = Ord.leq(time)

const mapState = (
  state: UserData.ParentState &
    EventData.ParentState &
    ActivityData.ParentState,
) => ({
  sex: pipe(
    state,
    body.composeOptional(optionLensToOptional(Body.sex)).getOption,
    Opt.getOrElse(() => Sex.male),
  ),
  muscleGroupLevels: pipe(
    state,
    EventData.selectors.state.compose(EventData.selectors.timeslots).get,
    Arr.findLast((timeslot) => leqTime(timeslot.time, now())),
    Opt.fold(
      () => MuscleGroupStatus.defaultMuscleGroupStatus,
      ({ recoveryStatus }) =>
        pipe(
          state,
          ActivityData.selectors.state.composeLens(
            ActivityData.selectors.muscleSettings,
          ).get,
          Timeslot._RecoveryStatusAsMuscleGroupStatus,
        ).get(recoveryStatus),
    ),
  ),
})

const mapDispatch = (dispatch: Dispatch<UserData.Action | RouterActions>) => {
  const dispatch_ =
    (act: UserData.Action | RouterActions): IO.IO<void> =>
    () =>
      pipe(act, dispatch, Func.constVoid)

  return {
    onMuscleGroupClick: (muscleGroup: MuscleGroups) =>
      pipe(pushHistory(`/sports/muscle-preferences/${muscleGroup}`), dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

export type BodyMapToggleSideContainerProps = {
  className?: string
}

export type BodyMapToggleSideProps = PropsFromRedux &
  BodyMapToggleSideContainerProps

const Center = styled.div`
  margin: auto;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const BodyMapToggleSide: FC<BodyMapToggleSideProps> = ({
  sex,
  muscleGroupLevels,
  onMuscleGroupClick,
  className,
}) => {
  const intl = useIntl()

  const [dorsoventralSide, onDorsoventralSideChange] = useState(
    Dorsoventral.ventral,
  )
  const [openMuscleGroupPopover, setOpenMuscleGroupPopover] =
    useState<MuscleGroups>()

  return (
    <Container {...{ className }}>
      <BodyMap
        {...{
          sex,
          dorsoventralSide,
          muscleGroupLevels,
        }}
        openMuscleGroupPopover={openMuscleGroupPopover as any}
        onMuscleGroupClick={(muscleGroup: MuscleGroups) =>
          onMuscleGroupClick(muscleGroup)()
        }
        toggleMuscleGroupPopover={
          ((muscleGroup: MuscleGroups, force: boolean) =>
            force === false
              ? openMuscleGroupPopover === muscleGroup
                ? setOpenMuscleGroupPopover(undefined)
                : undefined
              : setOpenMuscleGroupPopover(muscleGroup)) as any
        }
      />

      <Center>
        <ClearButtonGroup>
          <Button
            color="clear"
            onClick={() => onDorsoventralSideChange(Dorsoventral.ventral)}
            active={dorsoventralSide === Dorsoventral.ventral}
          >
            <FormattedMessage defaultMessage="Front" />
          </Button>

          <Button
            color="clear"
            onClick={() => onDorsoventralSideChange(Dorsoventral.dorsal)}
            active={dorsoventralSide === Dorsoventral.dorsal}
          >
            <FormattedMessage defaultMessage="Back" />
          </Button>
        </ClearButtonGroup>
      </Center>
    </Container>
  )
}

export const BodyMapToggleSideContainer = connector(BodyMapToggleSide)

import React, { ReactNode } from "react"
import {
  Controller,
  ControllerFieldState,
  ControllerProps,
  ControllerRenderProps,
  FieldPathValue,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
  UnpackNestedValue,
  UseFormStateReturn,
  Validate,
  ValidateResult,
} from "react-hook-form"
import {
  IMaskInputProps as ExtraIMaskInputProps,
  IMaskMixin,
} from "react-imask/esm"
import { useIntl } from "react-intl"
import {
  CustomInput,
  FormFeedback,
  FormGroup as BsFormGroup,
  Input,
  InputProps,
} from "reactstrap"
import styled from "@emotion/styled"

import * as E from "fp-ts/es6/Either"
import * as Func from "fp-ts/es6/function"
import { flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Num from "fp-ts/es6/number"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as Pair from "fp-ts/es6/ReadonlyTuple"
import * as Reg from "fp-ts-contrib/es6/RegExp"
import { Prism } from "monocle-ts"
import {
  isoStringAsLocalTimeOfDayDuration,
  LocalTimeOfDayDuration,
} from "time-ts/es6"
import {
  canonicaliseDimValue,
  Dimension,
  numberAsValidNumber,
  OmitFromKnownKeys,
  Unit,
  UnitLengthHeight,
  unitSymbols,
  unsafeFromSome,
  useStateIO,
  valueInUnit,
  ValueWithCanonicalUnit,
} from "@fitnesspilot/data-common"

import {
  LocalTimeOfDayDurationInput,
  LocalTimeOfDayDurationInputProps,
} from "../../atoms/input/LocalTimeOfDayDurationInput"
import { Range, RangeProps } from "../../atoms/Range/Range"
import { FormGroup } from "../../molecules/FormGroup/FormGroup"
import { Switch, SwitchProps } from "../../molecules/Switch/Switch"

export type FieldRender<
  formData extends FieldValues,
  name extends Path<formData>,
  rawValue,
> = ({
  field,
  fieldState,
  formState,
}: {
  field: Omit<ControllerRenderProps<formData, name>, "onChange" | "value"> & {
    onChange: (rawValue: rawValue) => void
    value: rawValue
  }
  fieldState: ControllerFieldState
  formState: UseFormStateReturn<formData>
}) => React.ReactElement

export type FieldRenderer<rawValue, baseProps extends Record<any, any>> = <
  formData extends FieldValues,
  name extends Path<formData>,
>(
  props: OmitFromKnownKeys<
    baseProps,
    "onChange" | "onBlur" | "value" | "checked"
  >,
) => FieldRender<formData, name, rawValue>

export type ControllerPlusProps<
  formData extends FieldValues,
  name extends Path<formData>,
  rawValue = string,
> = OmitFromKnownKeys<
  ControllerProps<formData, name>,
  "render" | "rules" | "defaultValue"
> & {
  transform: {
    input: (value: FieldPathValue<formData, name>) => rawValue
    output: (
      e: rawValue,
    ) => E.Either<ValidateResult, FieldPathValue<formData, name>>
  }
  rules?: Omit<
    RegisterOptions<formData, name>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "validate"
  > & { validate?: Validate<FieldPathValue<formData, name>, formData> }
  render: FieldRender<formData, name, rawValue>
  defaultValue?: FieldPathValue<formData, name>
}

export const ControllerPlus = <
  formData extends FieldValues,
  name extends Path<formData>,
  rawValue = string,
>({
  transform,
  render,
  rules: { validate = Func.constUndefined, ...rules } = {},
  defaultValue,
  ...props
}: ControllerPlusProps<formData, name, rawValue>) => {
  const [rawValue, setRawValue] = useStateIO<Opt.Option<rawValue>>(() =>
    pipe(defaultValue, Opt.fromNullable, Opt.map(transform.input)),
  )()
  const [error, setError] = useStateIO<Opt.Option<ValidateResult>>(
    () => Opt.none,
  )()

  return (
    <Controller<formData, name>
      render={({ field, fieldState, formState }) =>
        render({
          field: {
            ...field,
            onChange: (v) =>
              pipe(
                v,
                transform.output,
                E.fold(
                  (e) =>
                    pipe(
                      IO.Do,
                      IO.chainFirst(() => setRawValue(Opt.some(v))),
                      IO.chainFirst(() => setError(Opt.some(e))),
                    ),
                  (v) =>
                    pipe(
                      IO.Do,
                      IO.chain(() => () => field.onChange(v)),
                      IO.chainFirst(() => setRawValue(Opt.none)),
                      IO.chainFirst(() => setError(Opt.none)),
                    ),
                ),
              )(),
            value: pipe(
              rawValue,
              Opt.alt(() =>
                pipe(field.value, Opt.fromNullable, Opt.map(transform.input)),
              ),
              // @TODO
              Opt.getOrElse(() => "" as any),
            ),
          },
          fieldState,
          formState,
        })
      }
      {...props}
      rules={{
        ...rules,
        validate: (v, d) =>
          pipe(
            error,
            Opt.fold(() => validate(v, d), Func.identity),
          ),
      }}
      defaultValue={
        defaultValue as UnpackNestedValue<PathValue<formData, name>> | undefined
      }
    />
  )
}

export type Transform<
  formData extends FieldValues,
  name extends Path<formData>,
  rawValue,
  value extends FieldPathValue<formData, name> = FieldPathValue<formData, name>,
> = {
  input: (value: value) => rawValue
  output: (e: rawValue) => E.Either<ValidateResult, value>
}

export const noTransform = <
  formData extends FieldValues,
  name extends Path<formData>,
>(): Transform<formData, name, FieldPathValue<formData, name>> => ({
  input: Func.identity,
  output: E.right,
})

export enum BoolString {
  true = "true",
  false = "false",
}

export const boolTransform = <
  formData extends FieldValues,
  name extends Path<formData>,
  value extends FieldPathValue<formData, name> & boolean = FieldPathValue<
    formData,
    name
  > &
    boolean,
>(): Transform<formData, name, BoolString, value> => ({
  input: (v) => (v ? BoolString.true : BoolString.false),
  output: (v) =>
    v === "true"
      ? E.right(true as value)
      : v === "false"
      ? E.right(false as value)
      : E.left(v),
})

export type ControllerPrismProps<
  formData extends FieldValues,
  name extends Path<formData>,
  rawValue = string,
> = OmitFromKnownKeys<
  ControllerPlusProps<formData, name, rawValue>,
  "transform"
> & {
  prism: Prism<rawValue, FieldPathValue<formData, name>>
  prismError: ValidateResult
}

export const ControllerPrism = <
  formData extends FieldValues,
  name extends Path<formData>,
  rawValue = string,
>({
  prism,
  prismError,
  ...props
}: ControllerPrismProps<formData, name, rawValue>) => (
  <ControllerPlus
    transform={{
      input: prism.reverseGet,
      output: flow(
        prism.getOption,
        E.fromOption(() => prismError),
      ),
    }}
    {...props}
  />
)

type RenderTransform<additionalProps extends Record<any, any>> = <
  rawValue,
  baseProps extends Record<any, any>,
>(
  render: FieldRenderer<rawValue, baseProps>,
) => FieldRenderer<rawValue, additionalProps & baseProps>

export type FormGroupRenderProps = {
  label: ReactNode
  fullWidth?: boolean
  id: string
}

export const formGroupRender = <rawValue, baseProps extends Record<any, any>>(
  render: FieldRenderer<rawValue, baseProps>,
): FieldRenderer<rawValue, FormGroupRenderProps & baseProps> =>
  (<formData extends FieldValues, name extends Path<formData>>(
    renderProps: {
      id: string
      label: ReactNode
      fullWidth?: boolean
    } & OmitFromKnownKeys<
      baseProps,
      "onChange" | "onBlur" | "value" | "checked"
    >,
  ): FieldRender<formData, name, rawValue> => {
    const { label, id, fullWidth } = renderProps

    return ({ field, fieldState, formState }) => (
      <FormGroup inputId={id} {...{ label, fullWidth }}>
        {render<formData, name>({ ...renderProps, label: undefined })({
          field,
          fieldState,
          formState,
        })}
        {fieldState.error && (
          <FormFeedback>
            {label} {fieldState.error.message}
          </FormFeedback>
        )}
      </FormGroup>
    )
  }) as any

const inputRenderProps = <
  formData extends FieldValues,
  name extends Path<formData>,
>({
  field,
  fieldState,
}: Parameters<FieldRender<formData, name, string>>[0]): Pick<
  InputProps,
  "valid" | "invalid" | "value" | "onChange" | "onBlur"
> => ({
  valid: fieldState.isTouched ? !fieldState.error : undefined,
  invalid: fieldState.isTouched ? !!fieldState.error : undefined,
  value: field.value,
  onChange: (e) => field.onChange(e.target.value),
  onBlur: field.onBlur,
})

export const textRender: FieldRenderer<string, InputProps> =
  (props) => (params) => <Input {...inputRenderProps(params)} {...props} />

type InnerIMaskInputProps = {
  inputRef: InputProps["innerRef"]
} & OmitFromKnownKeys<InputProps, "innerRef">

type IMaskInputProps = OmitFromKnownKeys<InputProps, "innerRef"> &
  ExtraIMaskInputProps

const IMaskInput = IMaskMixin(
  ({ inputRef, ...props }: InnerIMaskInputProps) => (
    <Input {...props} innerRef={inputRef} />
  ),
)

export const maskRender: FieldRenderer<string, IMaskInputProps> =
  (props) => (params) => {
    const { onChange, ...inputProps } = inputRenderProps(params)
    return (
      <IMaskInput
        inputRef={Func.constVoid}
        unmask={false}
        lazy={false}
        onAccept={params.field.onChange}
        {...inputProps}
        {...props}
      />
    )
  }

export const switchRender: FieldRenderer<boolean, SwitchProps> =
  ({ id, ...props }) =>
  ({ field, fieldState }) => (
    <Switch
      valid={fieldState.isTouched ? !fieldState.error : undefined}
      invalid={fieldState.isTouched ? !!fieldState.error : undefined}
      checked={field.value}
      onChange={(v) => field.onChange(v)}
      onBlur={field.onBlur}
      {...{ id }}
      {...props}
    />
  )

export const rangeRender: FieldRenderer<number, RangeProps> =
  ({ ...props }) =>
  ({ field, fieldState }) => (
    <Range
      valid={fieldState.isTouched ? !fieldState.error : undefined}
      invalid={fieldState.isTouched ? !!fieldState.error : undefined}
      value={field.value}
      onChange={(v) => field.onChange(v)}
      {...props}
    />
  )

const StyledLabel = styled.label`
  display: contents;
`

export const selectRender =
  <
    k extends string,
    t extends "select" | "radio" | "radio-multi-line" | "switch" = "select",
  >(): FieldRenderer<
    k,
    {
      id: string
      values: Record<Exclude<k, "">, t extends "select" ? string : ReactNode>
      type: t
    }
  > =>
  ({ id, values, type }) =>
  ({ field, fieldState }) =>
    type === "select" ? (
      <CustomInput
        valid={fieldState.isTouched ? !fieldState.error : undefined}
        invalid={fieldState.isTouched ? !!fieldState.error : undefined}
        type="select"
        value={field.value}
        onChange={(e) =>
          field.onChange(
            // @TODO k or k | ""?
            e.target.value as k,
          )
        }
        onBlur={field.onBlur}
        {...{ id }}
      >
        {pipe(
          values,
          Rec.toReadonlyArray,
          Arr.map(([v, l]) => (
            <option key={v} value={v}>
              {l}
            </option>
          )),
        )}
      </CustomInput>
    ) : type === "radio-multi-line" ? (
      pipe(
        values,
        Rec.toReadonlyArray,
        Arr.map(([v, l]) => (
          <BsFormGroup key={v} check>
            <CustomInput
              id={`${id}-${v}`}
              valid={fieldState.isTouched ? !fieldState.error : undefined}
              invalid={fieldState.isTouched ? !!fieldState.error : undefined}
              type="radio"
              value={v}
              checked={v === field.value}
              onChange={(e) => e.target.checked && field.onChange(v)}
              onBlur={field.onBlur}
              inline
            >
              <StyledLabel htmlFor={`${id}-${v}`}>{l}</StyledLabel>
            </CustomInput>
          </BsFormGroup>
        )),
        (a) => <>{a}</>,
      )
    ) : (
      pipe(
        values,
        Rec.toReadonlyArray,
        Arr.map(([v, l]) => (
          <CustomInput
            key={v}
            id={`${id}-${v}`}
            valid={fieldState.isTouched ? !fieldState.error : undefined}
            invalid={fieldState.isTouched ? !!fieldState.error : undefined}
            type={type as Exclude<t, "select" | "radio-multi-line">}
            value={v}
            checked={v === field.value}
            onChange={(e) => e.target.checked && field.onChange(v)}
            onBlur={field.onBlur}
            inline
          >
            <StyledLabel htmlFor={`${id}-${v}`}>{l}</StyledLabel>
          </CustomInput>
        )),
        (a) => <>{a}</>,
      )
    )

const prismFootAndInch = <d extends Dimension.lengthHeight>(
  _dimension: d,
  _unit: UnitLengthHeight.footAndInch,
): Prism<string, ValueWithCanonicalUnit<d>> =>
  new Prism<string, number>(
    (v) =>
      pipe(
        v,
        Reg.match(/(\d+)' (\d+)\s*''/) as (
          v: string,
        ) => Opt.Option<[string, string, string]>,
        Opt.map(
          flow(
            ([_, b, c]) => [b, c] as const,
            Pair.bimap(Number, (v) => Number(v) * 12),
            NonEmpty.foldMap(Num.MonoidSum)(Func.identity),
          ),
        ),
      ),
    (n) => `${(n / 12) | 0}′ ${n % 12 | 0}″`,
  )
    .composePrism(numberAsValidNumber)
    .composeIso(
      valueInUnit(Dimension.lengthHeight, UnitLengthHeight.footAndInch),
    )
    .composeIso(
      canonicaliseDimValue(
        Dimension.lengthHeight,
        UnitLengthHeight.footAndInch,
      ),
    ) as any

const prismOtherUnit = <d extends Dimension>(
  dimension: d,
  unit: Unit<d>,
): Prism<string, ValueWithCanonicalUnit<d>> =>
  new Prism<string, number>(
    (v) =>
      pipe(
        v,
        Reg.split(/\s/),
        NonEmpty.init,
        (vs) => vs.join(""),
        Opt.fromPredicate((v) => v.length > 0),
        Opt.map(flow(Reg.sub(/,/, "."), Number)),
      ),
    (v) => `${v} ${String((unitSymbols[dimension] as any)[unit])}`,
  )
    .composePrism(numberAsValidNumber)
    .composeIso(valueInUnit(dimension, unit))
    .composeIso(canonicaliseDimValue(dimension, unit)) as any

export type ControllerDimensionalNumberProps<
  formData extends FieldValues,
  name extends Path<formData>,
  d extends Dimension,
  renderTransformProps extends Record<any, any> = Record<string, never>,
> = OmitFromKnownKeys<
  ControllerPrismProps<formData, name, string>,
  "prism" | "prismError" | "render"
> & {
  dimension: d
  unit: Unit<d>
  transform: (
    prism: Prism<string, ValueWithCanonicalUnit<d>>,
  ) => Prism<string, FieldPathValue<formData, name>>
  renderTransform: RenderTransform<renderTransformProps>
  renderTransformProps: renderTransformProps
}

export const ControllerDimensionalNumber = <
  formData extends FieldValues,
  name extends Path<formData>,
  d extends Dimension,
  renderTransformProps extends Record<any, any> = Record<string, never>,
>({
  dimension,
  unit,
  transform,
  renderTransform,
  renderTransformProps,
  ...props
}: ControllerDimensionalNumberProps<
  formData,
  name,
  d,
  renderTransformProps
>) => {
  const intl = useIntl()

  return (
    <ControllerPrism<formData, name, string>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      render={renderTransform(maskRender)({
        placeholderChar: "\u{2002}",
        mask:
          unit === UnitLengthHeight.footAndInch
            ? "0' {0}0''"
            : `# ${String((unitSymbols[dimension] as any)[unit])}`,

        overwrite: unit === UnitLengthHeight.footAndInch ? true : undefined,
        blocks:
          unit === UnitLengthHeight.footAndInch
            ? undefined
            : {
                "#": {
                  mask: Number,
                },
              },
        // @TODO fix the typing
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        ...(renderTransformProps as any),
      })}
      prism={transform(
        dimension === Dimension.lengthHeight &&
          unit === UnitLengthHeight.footAndInch
          ? (prismFootAndInch(
              dimension as Dimension.lengthHeight,
              unit as UnitLengthHeight.footAndInch,
            ) as Prism<string, ValueWithCanonicalUnit<any>> as Prism<
              string,
              ValueWithCanonicalUnit<d>
            >)
          : prismOtherUnit<d>(dimension, unit),
      )}
      prismError={intl.formatMessage({
        defaultMessage: "is invalid",
      })}
      {...props}
    />
  )
}

export const localTimeOfDayDurationRender: FieldRenderer<
  LocalTimeOfDayDuration,
  LocalTimeOfDayDurationInputProps
> =
  (props) =>
  ({ field, fieldState }) => (
    <LocalTimeOfDayDurationInput
      valid={fieldState.isTouched ? !fieldState.error : undefined}
      invalid={fieldState.isTouched ? !!fieldState.error : undefined}
      value={Opt.some(field.value)}
      required
      onChange={(v) => () =>
        pipe(
          v,
          // @TODO
          unsafeFromSome,
          field.onChange,
        )
      }
      {...props}
    />
  )

export const localTimeOfDayDurationStringRender: FieldRenderer<
  string,
  LocalTimeOfDayDurationInputProps
> =
  (props) =>
  ({ field: { onChange, value, ...field }, ...props2 }) =>
    localTimeOfDayDurationRender(props)({
      field: {
        ...(field as any),
        onChange: (v) =>
          pipe(v, isoStringAsLocalTimeOfDayDuration.reverseGet, onChange),
        value: pipe(
          value,
          isoStringAsLocalTimeOfDayDuration.getOption,
          unsafeFromSome,
        ),
      },
      ...props2,
      fieldState: props2.fieldState as any,
    })

export const wrapPrismOpt = <rawValue extends string, value>(
  prism: Prism<rawValue, value>,
) =>
  new Prism<rawValue | "", Opt.Option<value>>(
    flow(
      Opt.fromPredicate((s): s is rawValue => s !== ""),
      Opt.fold(
        () => Opt.some(Opt.none),
        flow(prism.getOption, Opt.map(Opt.some)),
      ),
    ),
    Opt.fold((): rawValue | "" => "", prism.reverseGet),
  )

/**
 * Same as wrapPrismOpt except if prism.getOption returns `None`
 * it'll return `Some<None>` instead.
 * */
export const wrapPrismOptFallback = <rawValue extends string, value>(
  prism: Prism<rawValue, value>,
) =>
  new Prism<rawValue | "", Opt.Option<value>>(
    flow(
      wrapPrismOpt(prism).getOption,
      Opt.getOrElse<Opt.Option<value>>(() => Opt.none),
      Opt.some,
    ),
    wrapPrismOpt(prism).reverseGet,
  )

export const optionPrism = <rawValue extends string>() =>
  wrapPrismOpt(
    new Prism<Exclude<rawValue, "">, Exclude<rawValue, "">>(
      Opt.some,
      Func.identity,
    ),
  )

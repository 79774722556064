import { ReactNode } from "react"
import { FaHeart } from "react-icons/fa"
import { Link } from "react-router-dom"
import styled from "@emotion/styled"

import { flow, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"

import {
  BackgroundWithDetails,
  BackgroundWithDetailsProps,
} from "@fitnesspilot/components-common/dist/molecules/BackgroundWithDetails/BackgroundWithDetails"
import * as ActivityExercise from "@fitnesspilot/data-activity/dist/activity/ActivityExercise"
import * as ActivityId from "@fitnesspilot/data-activity/dist/activity/ActivityId"
import * as WithId from "@fitnesspilot/data-activity/dist/activity/WithId"
import * as ActivityWithSettings from "@fitnesspilot/data-activity/dist/activitySettings/ActivityWithSettings"
import * as Sport from "@fitnesspilot/data-activity/dist/sport/Sport"
import * as SportId from "@fitnesspilot/data-activity/dist/sport/SportId"

import { ActivityName } from "../../atoms/ActivityName/ActivityName"
import { ActivityTagName } from "../../atoms/ActivityTagName/ActivityTagName"
import { SportImage as SportImage_ } from "../../atoms/SportImage/SportImage"

const SportImage = styled(SportImage_)`
  max-width: 100%;
`

const LikeIcon = styled(FaHeart)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em;
  box-sizing: content-box;
  color: ${({ theme }) => theme.colours.primary.toString()};
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colours.white.toString()} !important;
  margin: 0;
  padding: 10px 20px;
`

export type SportTileProps = {
  sportId: SportId.SportId
  title: ReactNode
  liked: boolean
} & Omit<BackgroundWithDetailsProps, "background" | "title">

export const SportTile_ = ({
  sportId,
  title,
  liked,
  ...props
}: SportTileProps) => (
  <Link
    to={`/sports/sport-preferences/${SportId.stringAsSportId.reverseGet(
      sportId,
    )}`}
  >
    <BackgroundWithDetails
      background={<SportImage value={sportId} />}
      extra={liked && <LikeIcon />}
      {...props}
    >
      <Title>{title}</Title>
    </BackgroundWithDetails>
  </Link>
)

export const SportTile = styled(SportTile_)`
  display: grid;
`

export type SportTileSportProps = {
  sport: Sport.SportWithId
  activity: Opt.Option<ActivityWithSettings.ActivityWithSettingsWithId>
} & Omit<SportTileProps, "sportId" | "title">

export const SportTileSport = ({
  sport,
  activity,
  ...props
}: SportTileSportProps) => (
  <SportTile
    sportId={WithId._id<SportId.SportId, Sport.Sport>().get(sport)}
    title={pipe(
      sport.id,
      SportId.foldSportId(
        (id) => Opt.some(<ActivityTagName {...{ id }} />),
        (id) =>
          pipe(
            activity,
            Opt.chain(
              flow(
                WithId._value<
                  ActivityId.ActivityId,
                  ActivityWithSettings.ActivityWithSettings
                >()
                  .composePrism(ActivityWithSettings._ActivityExercise)
                  .composeLens(ActivityExercise._title).getOption,
                Opt.map((t) => (
                  <ActivityName key="activityName" {...{ id }} def={t} />
                )),
              ),
            ),
          ),
      ),
      Opt.getOrElse<ReactNode>(() => null),
    )}
    {...props}
  />
)

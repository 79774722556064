import * as Bool from "fp-ts/es6/boolean"
import * as Eq from "fp-ts/es6/Eq"
import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as Str from "fp-ts/es6/string"
import { Iso, Lens, Prism } from "monocle-ts"

import { Alignment, alignment } from "./Alignment"
import { Dorsoventral } from "./anatomicalAxes"
import { MuscleId_, MuscleWithMetadata, muscleWithMetadata } from "./Muscle"

export enum DorsalMuscleGroups {
  dorsalThorax = "dorsalThorax",
  forearmAndHand = "forearmAndHand",
  hipAndEverythingInferiorToIt = "dorsalHipAndEverythingInferiorToIt",
  // A _lot_ (about 9) of the upper arm muscles are missing.
  tricepsBrachii = "tricepsBrachii",
}
const dorsalMuscleGroups: Eq.Eq<DorsalMuscleGroups> = Str.Eq

export enum VentralMuscleGroups {
  abdomen = "abdomen",
  // A _lot_ (about 9) of the upper arm muscles are missing.
  bicepsBrachii = "bicepsBrachii",
  // Not really vental nor a muscle group, but shown to be
  cardiovascular = "cardiovascular",
  // 'Leg' is, anatomically, synonymous to 'crus', the part of the hindlimbs distal to the knee and proximal to the ankle.
  hipAndEverythingInferiorToIt = "ventralHipAndEverythingInferiorToIt",
  shoulder = "shoulder",
  ventralThorax = "ventralThorax",
}
const ventralMuscleGroups: Eq.Eq<VentralMuscleGroups> = Str.Eq

export type MuscleGroups = DorsalMuscleGroups | VentralMuscleGroups
export const _DorsalMuscleGroups = Prism.fromPredicate(
  (mg: MuscleGroups): mg is DorsalMuscleGroups =>
    pipe(
      DorsalMuscleGroups,
      Rec.elem(dorsalMuscleGroups)(mg as DorsalMuscleGroups),
    ),
)
export const _VentralMuscleGroups = Prism.fromPredicate(
  (mg: MuscleGroups): mg is VentralMuscleGroups =>
    pipe(
      VentralMuscleGroups,
      Rec.elem(ventralMuscleGroups)(mg as VentralMuscleGroups),
    ),
)
export const muscleGroups: Eq.Eq<MuscleGroups> = Str.Eq

export enum MuscleGroupId {
  abdomen = "muscleGroup-abdominal",
  bicepsBrachii = "muscleGroup-biceps",
  cardiovascular = "muscleGroup-cardiovascularSystem",
  dorsalThorax = "muscleGroup-back",
  forearmAndHand = "muscleGroup-forearm",
  hipAndEverythingInferiorToIt = "muscleGroup-legs",
  shoulder = "muscleGroup-shoulder",
  tricepsBrachii = "muscleGroup-triceps",
  ventralThorax = "muscleGroup-chest",
}
export const muscleGroupId: Ord.Ord<MuscleGroupId> = Str.Ord as any
export const stringAsMuscleGroupId = Prism.fromPredicate(
  (v: string): v is MuscleGroupId =>
    (Object.values(MuscleGroupId) as ReadonlyArray<string>).includes(v),
)
export const muscleGroupIdAsMuscleGroups = new Iso<MuscleGroupId, MuscleGroups>(
  (s) =>
    ({
      [MuscleGroupId.abdomen]: VentralMuscleGroups.abdomen,
      [MuscleGroupId.bicepsBrachii]: VentralMuscleGroups.bicepsBrachii,
      [MuscleGroupId.cardiovascular]: VentralMuscleGroups.cardiovascular,
      [MuscleGroupId.dorsalThorax]: DorsalMuscleGroups.dorsalThorax,
      [MuscleGroupId.forearmAndHand]: DorsalMuscleGroups.forearmAndHand,
      [MuscleGroupId.hipAndEverythingInferiorToIt]:
        VentralMuscleGroups.hipAndEverythingInferiorToIt,
      [MuscleGroupId.shoulder]: VentralMuscleGroups.shoulder,
      [MuscleGroupId.tricepsBrachii]: DorsalMuscleGroups.tricepsBrachii,
      [MuscleGroupId.ventralThorax]: VentralMuscleGroups.ventralThorax,
    })[s],
  (s) =>
    ({
      [DorsalMuscleGroups.dorsalThorax]: MuscleGroupId.dorsalThorax,
      [DorsalMuscleGroups.forearmAndHand]: MuscleGroupId.forearmAndHand,
      [DorsalMuscleGroups.tricepsBrachii]: MuscleGroupId.tricepsBrachii,
      [DorsalMuscleGroups.hipAndEverythingInferiorToIt]:
        MuscleGroupId.hipAndEverythingInferiorToIt,
      [VentralMuscleGroups.abdomen]: MuscleGroupId.abdomen,
      [VentralMuscleGroups.bicepsBrachii]: MuscleGroupId.bicepsBrachii,
      [VentralMuscleGroups.cardiovascular]: MuscleGroupId.cardiovascular,
      [VentralMuscleGroups.hipAndEverythingInferiorToIt]:
        MuscleGroupId.hipAndEverythingInferiorToIt,
      [VentralMuscleGroups.shoulder]: MuscleGroupId.shoulder,
      [VentralMuscleGroups.ventralThorax]: MuscleGroupId.ventralThorax,
    })[s],
)

export type MuscleGroupWithMetadata = {
  id: MuscleGroupId
  alignment: Alignment
  title: string
  description: Opt.Option<string>
  enabled: boolean
  muscles: ReadonlyArray<MuscleWithMetadata>
}
export const muscleGroupWithMetadata: Ord.Ord<MuscleGroupWithMetadata> = {
  equals: Eq.struct({
    id: muscleGroupId,
    alignment,
    title: Str.Eq,
    description: Opt.getEq(Str.Eq),
    enabled: Bool.Eq,
    muscles: Arr.getEq(muscleWithMetadata),
  }).equals,
  compare: (a, b) => muscleGroupId.compare(a.id, b.id),
}

export type MuscleGroupsByDorsoventralSide = {
  [Dorsoventral.dorsal]: DorsalMuscleGroups
  [Dorsoventral.ventral]: VentralMuscleGroups
}
export const MuscleGroupsByDorsoventralSide = {
  [Dorsoventral.dorsal]: DorsalMuscleGroups,
  [Dorsoventral.ventral]: VentralMuscleGroups,
}
export const _id = Lens.fromProp<MuscleGroupWithMetadata>()("id")
export const _alignment = Lens.fromProp<MuscleGroupWithMetadata>()("alignment")
export const _title = Lens.fromProp<MuscleGroupWithMetadata>()("title")
export const _description =
  Lens.fromProp<MuscleGroupWithMetadata>()("description")
export const _enabled = Lens.fromProp<MuscleGroupWithMetadata>()("enabled")
export const _muscles = Lens.fromProp<MuscleGroupWithMetadata>()("muscles")

export const muscleIdMap: Record<MuscleId_, MuscleGroupId> = {
  [MuscleId_.abductors]: MuscleGroupId.hipAndEverythingInferiorToIt,
  [MuscleId_.adductors]: MuscleGroupId.hipAndEverythingInferiorToIt,
  [MuscleId_.backExtensor]: MuscleGroupId.dorsalThorax,
  [MuscleId_.backShoulder]: MuscleGroupId.dorsalThorax,
  [MuscleId_.biceps]: MuscleGroupId.bicepsBrachii,
  [MuscleId_.calves]: MuscleGroupId.hipAndEverythingInferiorToIt,
  [MuscleId_.cardiovascularSystem]: MuscleGroupId.cardiovascular,
  [MuscleId_.diagonalAbdominal]: MuscleGroupId.abdomen,
  [MuscleId_.forearm]: MuscleGroupId.forearmAndHand,
  [MuscleId_.frontShoulder]: MuscleGroupId.shoulder,
  [MuscleId_.glutes]: MuscleGroupId.hipAndEverythingInferiorToIt,
  [MuscleId_.hamstring]: MuscleGroupId.hipAndEverythingInferiorToIt,
  [MuscleId_.latissimus]: MuscleGroupId.dorsalThorax,
  [MuscleId_.lowerChest]: MuscleGroupId.ventralThorax,
  [MuscleId_.lowerTrapezium]: MuscleGroupId.dorsalThorax,
  [MuscleId_.middleChest]: MuscleGroupId.ventralThorax,
  [MuscleId_.middleShoulder]: MuscleGroupId.shoulder,
  [MuscleId_.middleTrapezium]: MuscleGroupId.dorsalThorax,
  [MuscleId_.quadriceps]: MuscleGroupId.hipAndEverythingInferiorToIt,
  [MuscleId_.straightAbdominal]: MuscleGroupId.abdomen,
  [MuscleId_.triceps]: MuscleGroupId.tricepsBrachii,
  [MuscleId_.upperChest]: MuscleGroupId.ventralThorax,
  [MuscleId_.upperTrapezium]: MuscleGroupId.dorsalThorax,
}

export const muscleIdsOfGroup = (muscleGroupId: MuscleGroupId) =>
  pipe(
    Object.entries(muscleIdMap) as Array<[MuscleId_, MuscleGroupId]>,
    Arr.reduce({} as Record<MuscleGroupId, Array<MuscleId_>>, (r, [m, mg]) => ({
      ...r,
      [mg]: [...(r[mg] ?? []), m],
    })),
    (a) => a[muscleGroupId],
  )

import * as Ord from "fp-ts/es6/Ord"
import * as Ordering from "fp-ts/es6/Ordering"
import { Lens } from "monocle-ts"

import {
  Intensity,
  intensity,
} from "@fitnesspilot/data-human-body/dist/Intensity"
import { MuscleId, muscleId } from "@fitnesspilot/data-human-body/dist/Muscle"

export type MuscleShare = {
  muscleId: MuscleId
  intensity: Intensity
}
export const muscleShare = Ord.fromCompare<MuscleShare>((a, b) =>
  Ordering.Monoid.concat(
    intensity.compare(a.intensity, b.intensity),
    muscleId.compare(a.muscleId, b.muscleId),
  ),
)
export const _muscleId = Lens.fromProp<MuscleShare>()("muscleId")
export const _intensity = Lens.fromProp<MuscleShare>()("intensity")

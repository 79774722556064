import { FC } from "react"
import { FormattedMessage } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import { Button } from "reactstrap"
import styled from "@emotion/styled"

import { constVoid, flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import {
  Action,
  ParentState,
  selectors,
  skipWaitingForUpdate,
} from "@fitnesspilot/data-common"

import { Dispatch } from "redux"

const mapState = (state: ParentState) => ({
  availableUpdate: selectors.state
    .compose(selectors.availableUpdate)
    .get(state),
})

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ =
    (act: Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, constVoid)

  return {
    onClick: flow(skipWaitingForUpdate, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

export type UpdateNoticeContainerProps = PropsFromRedux

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  margin: 10px;
  border-radius: 8px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
`

const UpdateNoticeContainer_: FC<UpdateNoticeContainerProps> = ({
  availableUpdate,
  onClick,
}) =>
  pipe(
    availableUpdate,
    Opt.fold(
      () => null,
      (newWorker) => (
        <Container>
          <Button
            color="primary"
            onClick={pipe(
              onClick(),
              IO.chain(
                () => () => newWorker.postMessage({ type: "SKIP_WAITING" }),
              ),
            )}
          >
            <FormattedMessage defaultMessage="A new version is available. Click here to update." />
          </Button>
        </Container>
      ),
    ),
  )

export const UpdateNoticeContainer = connector(UpdateNoticeContainer_)

import * as Bool from "fp-ts/es6/boolean"
import { flow, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Mp from "fp-ts/es6/ReadonlyMap"
import * as T from "fp-ts/es6/Task"
import * as TE from "fp-ts/es6/TaskEither"
import * as O from "fp-ts-reactive/es6/Observable"
import { Prism } from "monocle-ts"
import {
  arrayToObservable,
  unsafeFromSome,
  withLatestFrom,
} from "@fitnesspilot/data-common"

import * as API from "@fitnesspilot/api-combined"
import api, {
  Action as APIAction,
  AnyAPIError,
  ParentState as APIParentState,
  setAccountReady,
} from "@fitnesspilot/data-api"

import {
  _StepStateStep,
  allKnownStepSets,
  KnownStepSet,
  knownStepSet,
  stepSelectionSet,
  stepSetPaths,
} from "../StepSet"
import { Action } from "./actions"
import * as actions from "./actions"
import * as selectors from "./selectors"
import { ParentState, stateKey } from "./state"

import { AnyAction } from "redux"
import { push as pushHistory, RouterActions } from "redux-first-history"
import { combineEpics, Epic } from "redux-observable"
import { isActionOf } from "typesafe-actions"

type ParentAPIState = ParentState & APIParentState

type AnEpic = Epic<
  Action | APIAction | RouterActions,
  Action | APIAction | RouterActions,
  ParentAPIState
>

export const gotoStepSetFlow: AnEpic = (
  action$,
  // state$,
) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.gotoStepSet)),
    O.map((a) => a.payload),
    O.map(({ set }) => actions.gotoStep({ set, step: 0 })),
  )

export const setAccountReadyFlow: AnEpic = (
  action$,
  // state$,
) =>
  pipe(
    action$,
    O.filter(isActionOf(setAccountReady)),
    O.map((a) => a.payload),
    O.map(
      Bool.fold(
        () => [],
        () => [actions.fetchHelpStatus()],
      ),
    ),
    O.chain(arrayToObservable),
  )

export const fetchHelpStatusFlow: AnEpic = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.fetchHelpStatusAsync.request)),
    withLatestFrom(O.map(api)(state$)),
    O.chain(([, api]) =>
      pipe(
        api.help.getHelp("me"),
        TE.fold<AnyAPIError, API.Help, Action>(
          flow(actions.fetchHelpStatusAsync.failure, T.of),
          flow(
            ({ progress }) => progress as Partial<Record<KnownStepSet, Date>>,
            actions.fetchHelpStatusAsync.success,
            T.of,
          ),
        ),
        O.fromTask,
      ),
    ),
  )

export const saveHelpStatusFlow: AnEpic = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.saveHelpStatusAsync.request)),
    O.map((a) => a.payload),
    withLatestFrom(O.map(api)(state$)),
    O.chain(([progress, api]) =>
      pipe(
        api.help.setHelp({
          progress: progress as Record<string, Date>,
        })("me"),
        TE.fold<AnyAPIError, API.Help, Action>(
          flow(actions.saveHelpStatusAsync.failure, T.of),
          flow(
            ({ progress }) => progress as Partial<Record<KnownStepSet, Date>>,
            actions.saveHelpStatusAsync.success,
            T.of,
          ),
        ),
        O.fromTask,
      ),
    ),
  )

export const closeFlow: AnEpic = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.close)),
    withLatestFrom(state$),
    O.filter(([, state]) => Opt.isSome(state[stateKey].step)),
    O.chain(([, state]) =>
      pipe(
        state,
        selectors.state
          .composeLens(selectors.step)
          .composePrism(Prism.some())
          .composePrism(_StepStateStep)
          .composeLens(stepSelectionSet).getOption,
        Opt.map((k) =>
          actions.saveHelpStatus({
            ...pipe(
              state[stateKey].status,
              Opt.getOrElse(() => ({})),
            ),
            [k]: new Date(),
          }),
        ),
        O.fromOption,
      ),
    ),
  )

export const gotoStepFlow: AnEpic = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(actions.gotoStepAsync.request)),
    withLatestFrom(state$),
    O.chain(([act, state]) =>
      pipe(
        state,
        selectors.state
          .composeLens(selectors.step)
          .composePrism(Prism.some())
          .composePrism(_StepStateStep)
          .composeLens(stepSelectionSet).getOption,
        // change route when next step is on different page
        // (first step of a set doesn't require navigation since it's in the sidebar)
        Opt.chain((a) =>
          act.payload.set !== a && act.payload.step > 0
            ? pipe(
                stepSetPaths,
                Mp.lookup(knownStepSet)(act.payload.set),
                Opt.flatten,
              )
            : Opt.none,
        ),
        Opt.fold(
          () => [],
          (path) => [pushHistory(path)],
        ),
        Arr.concat<Action | RouterActions>([
          actions.gotoStepAsync.success(act.payload),
        ]),
        Arr.concat<Action | RouterActions>([
          actions.saveHelpStatus({
            ...pipe(
              state[stateKey].status,
              Opt.getOrElse(() => ({})),
            ),
            [act.payload.set]: new Date(),
          }),
        ]),
        arrayToObservable,
      ),
    ),
  )

const epic: AnEpic = combineEpics(
  gotoStepSetFlow,
  setAccountReadyFlow,
  fetchHelpStatusFlow,
  saveHelpStatusFlow,
  closeFlow,
  gotoStepFlow,
)
export default epic

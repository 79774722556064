import * as Bool from "fp-ts/es6/boolean"
import * as Eq from "fp-ts/es6/Eq"
import { constFalse, pipe } from "fp-ts/es6/function"
import * as Num from "fp-ts/es6/number"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import * as Str from "fp-ts/es6/string"
import { Positive } from "newtype-ts/es6/Positive"
import { time } from "time-ts/es6"

import {
  alignment,
  numberAsAlignmentUnsafe,
} from "@fitnesspilot/data-human-body/dist/Alignment"
import { numberAsIntensity } from "@fitnesspilot/data-human-body/dist/Intensity"
import { muscleId_FromMuscleId } from "@fitnesspilot/data-human-body/dist/Muscle"
import {
  MuscleGroupId,
  muscleGroupId,
  muscleIdMap,
} from "@fitnesspilot/data-human-body/dist/muscleGroups"

import { ActivityWithId, foldActivityExercise } from "../activity/Activity"
import * as ActivityExercise from "../activity/ActivityExercise"
import { ActivityId } from "../activity/ActivityId"
import * as WithId from "../activity/WithId"
import {
  activitiesFromActivityInstance,
  ActivityInstanceNonGroup,
  foldActivityInstanceExercise,
} from "../activityInstance/ActivityInstance"
import { ActivitySettingsExerciseListing } from "../activitySettings/ActivitySettingsExercise"

export enum ActivitySorting {
  "default" = "default",
  "alphabet" = "alphabet",
  "lastInstance" = "lastInstance",
  "frequency" = "frequency",
  "muscleGroup" = "muscleGroup",
}
export const activitySorting: Eq.Eq<ActivitySorting> = Str.Eq

export enum ActivityInstanceSorting {
  "default" = "default",
  "alphabet" = "alphabet",
  "alignment" = "alignment",
  "lastInstance" = "lastInstance",
  "frequency" = "frequency",
  "muscleGroup" = "muscleGroup",
}
export const activityInstanceSorting: Eq.Eq<ActivityInstanceSorting> = Str.Eq

export type AnyActivitySorting = ActivitySorting | ActivityInstanceSorting

const sortByLastInstance = pipe(
  Opt.getOrd(time),
  Ord.contramap((a: ActivityWithId) =>
    pipe(
      a.value,
      foldActivityExercise(() => Opt.none, ActivityExercise._lastInstance.get),
    ),
  ),
  Ord.reverse,
)

const sortByFrequency = pipe(
  Opt.getOrd(Num.Ord as any as Ord.Ord<Positive>),
  Ord.contramap((a: ActivityWithId) =>
    pipe(
      a.value,
      foldActivityExercise(() => Opt.none, ActivityExercise._frequency.get),
    ),
  ),
  Ord.reverse,
)

const sortByAlignment = pipe(
  alignment,
  Ord.contramap((a: ActivityInstanceNonGroup) =>
    pipe(
      a,
      foldActivityInstanceExercise(
        () => numberAsAlignmentUnsafe(0),
        (a) => a.alignment,
      ),
    ),
  ),
  Ord.reverse,
)

const sortByMuscleGroup = pipe(
  muscleGroupId,
  Opt.getOrd,
  Ord.contramap((a: ActivityWithId) =>
    pipe(
      a.value,
      foldActivityExercise(
        () => Opt.none,
        (ex) =>
          pipe(
            ex.muscleShares,
            Arr.reduce({} as Record<MuscleGroupId, number>, (r, m) => {
              const group = muscleIdMap[muscleId_FromMuscleId.get(m.muscleId)]
              return {
                ...r,
                [group]:
                  (r[group] ?? 0) + numberAsIntensity.reverseGet(m.intensity),
              }
            }),
            Rec.reduceWithIndex(muscleGroupId as Ord.Ord<string>)<
              MuscleGroupId,
              number,
              [MuscleGroupId, number]
            >([MuscleGroupId.abdomen, 0], (k, [rk, rv], v) =>
              v > rv ? [k, v] : [rk, rv],
            ),
            ([mg]) => mg,
            Opt.some,
          ),
      ),
    ),
  ),
  Ord.reverse,
)

const sortByLiked = (
  activityListings: ReadonlyArray<
    WithId.WithId<ActivityId, ActivitySettingsExerciseListing>
  >,
) =>
  pipe(
    Bool.Ord,
    Ord.contramap((a: ActivityWithId) =>
      pipe(
        a.value,
        foldActivityExercise(constFalse, () =>
          pipe(
            activityListings,
            Arr.some((l) => l.id === a.id),
          ),
        ),
      ),
    ),
  )

const sortByName = (
  activityName: (v: { id: ActivityId; def: string }) => string,
) =>
  pipe(
    Str.Ord,
    Ord.contramap(({ id, value }: ActivityWithId) =>
      activityName({ id, def: value.value.title }),
    ),
  )

const sortActivity = (
  ord: Ord.Ord<ActivityWithId>,
): Ord.Ord<ActivityInstanceNonGroup> =>
  pipe(ord, Arr.getOrd, Ord.contramap(activitiesFromActivityInstance.get))

type SortMap<a, sorting extends AnyActivitySorting> = Record<
  sorting,
  (a: ReadonlyArray<a>) => ReadonlyArray<a>
>

const sortMap =
  <a, sorting extends AnyActivitySorting>(map: SortMap<a, sorting>) =>
  (sorting: sorting) =>
    map[sorting]

export const sortActivities =
  (activityName: (v: { id: ActivityId; def: string }) => string) =>
  (
    sorting: ActivitySorting,
    activityListings: ReadonlyArray<
      WithId.WithId<ActivityId, ActivitySettingsExerciseListing>
    >,
  ) =>
  (activities: ReadonlyArray<ActivityWithId>) =>
    pipe(
      activities,
      sortMap({
        [ActivitySorting.default]: Arr.sortBy([
          pipe(activityListings, sortByLiked),
          pipe(activityName, sortByName),
        ]),
        [ActivitySorting.lastInstance]: Arr.sortBy([
          sortByLastInstance,
          pipe(activityName, sortByName),
        ]),
        [ActivitySorting.frequency]: Arr.sortBy([
          sortByFrequency,
          pipe(activityName, sortByName),
        ]),
        [ActivitySorting.alphabet]: Arr.sortBy([
          pipe(activityName, sortByName),
        ]),
        [ActivitySorting.muscleGroup]: Arr.sortBy([sortByMuscleGroup]),
      })(sorting),
    )

export const sortActivityInstances =
  (activityName: (v: { id: ActivityId; def: string }) => string) =>
  (
    sorting: ActivityInstanceSorting,
    activityListings: ReadonlyArray<
      WithId.WithId<ActivityId, ActivitySettingsExerciseListing>
    >,
  ) =>
  (activities: ReadonlyArray<ActivityInstanceNonGroup>) =>
    pipe(
      activities,
      sortMap({
        [ActivityInstanceSorting.default]: Arr.sortBy([
          sortByAlignment,
          pipe(activityListings, sortByLiked, sortActivity),
          pipe(activityName, sortByName, sortActivity),
        ]),
        [ActivityInstanceSorting.alphabet]: Arr.sortBy([
          pipe(activityName, sortByName, sortActivity),
        ]),
        [ActivityInstanceSorting.lastInstance]: Arr.sortBy([
          pipe(sortByLastInstance, sortActivity),
          pipe(activityName, sortByName, sortActivity),
        ]),
        [ActivityInstanceSorting.frequency]: Arr.sortBy([
          pipe(sortByFrequency, sortActivity),
          pipe(activityName, sortByName, sortActivity),
        ]),
        [ActivityInstanceSorting.muscleGroup]: Arr.sortBy([
          pipe(sortByMuscleGroup, sortActivity),
        ]),
        [ActivityInstanceSorting.alignment]: Arr.sortBy([sortByAlignment]),
      })(sorting),
    )

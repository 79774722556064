import React, { ComponentProps, ComponentType, FC } from "react"
import {
  FormatNumberOptions,
  FormattedMessage,
  IntlProvider as IntlProvider_,
  IntlShape,
  MessageDescriptor,
} from "react-intl"

import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import {
  UnitLengthDistance,
  UnitLengthHeight,
  UnitMass,
  UnitSelection,
} from "@fitnesspilot/data-common"

type IntlProviderProps_ = typeof IntlProvider_ extends ComponentType<
  infer props
>
  ? props
  : never

/**
 * wraps `intl.formatMessage` in a way that prevents extraction.
 */
export const formatDynamicMessage =
  ({ formatMessage }: IntlShape) =>
  (descr: MessageDescriptor) =>
    formatMessage(descr)

/**
 * wraps `<FormattedMessage>` in a way that prevents extraction.
 */
export const DynamicFormattedMessage: FC<
  ComponentProps<typeof FormattedMessage>
> = (props) => <FormattedMessage {...props} />

export type IntlProviderProps = {
  units: UnitSelection
} & Omit<IntlProviderProps_, "defaultLocale">

export type NumberFormats =
  | "mass"
  | "lengthDistance"
  | "lengthHeight"
  | "lengthFoot"
  | "lengthInch"

export const UnitContext = React.createContext<UnitSelection>({
  mass: UnitMass.kilogram,
  lengthDistance: UnitLengthDistance.kilometre,
  lengthHeight: UnitLengthHeight.centimetre,
})
UnitContext.displayName = "UnitContext"

export const IntlProvider: FC<IntlProviderProps> = ({
  units,
  defaultFormats,
  ...props
}) => (
  <UnitContext.Provider value={units}>
    <IntlProvider_
      defaultFormats={{
        number: pipe(
          {
            mass: {
              style: "unit",
              unit: units.mass,
              maximumFractionDigits: 0,
            },
            lengthDistance: {
              style: "unit",
              unit:
                // Dunno why there's a typo in the standard's units
                pipe(
                  { [UnitLengthDistance.kilometre]: "kilometer" },
                  Rec.lookup(units.lengthDistance),
                  Opt.getOrElse(() => units.lengthDistance as string),
                ),
              maximumFractionDigits: 0,
            },
            // footAndInch must be treated separately as it splits the value across two different units.
            lengthHeight:
              units.lengthHeight === UnitLengthHeight.footAndInch
                ? undefined
                : {
                    style: "unit",
                    // FYI the official name _is_ centimetre
                    unit: pipe(
                      { [UnitLengthHeight.centimetre]: "centimeter" },
                      Rec.lookup(units.lengthHeight),
                      Opt.getOrElse(() => units.lengthHeight as string),
                    ),
                    maximumFractionDigits: 0,
                  },
            lengthFoot:
              units.lengthHeight === UnitLengthHeight.footAndInch
                ? {
                    style: "unit",
                    unit: "foot",
                    maximumFractionDigits: 0,
                    unitDisplay: "narrow",
                  }
                : undefined,
            lengthInch:
              units.lengthHeight === UnitLengthHeight.footAndInch
                ? {
                    style: "unit",
                    unit: "inch",
                    maximumFractionDigits: 0,
                    unitDisplay: "narrow",
                  }
                : undefined,
          } as Record<NumberFormats, FormatNumberOptions | undefined>,
          Rec.map<
            FormatNumberOptions | undefined,
            Opt.Option<FormatNumberOptions>
          >(Opt.fromNullable),
          Rec.compact,
        ),
        ...defaultFormats,
      }}
      defaultLocale="en"
      defaultRichTextElements={{
        strong: (v) => <strong>{v}</strong>,
        span: (v) => <span>{v}</span>,
        br: () => <br />,
      }}
      {...props}
    />
  </UnitContext.Provider>
)

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityInstanceFromJSONTyped, ActivityInstanceToJSON, } from "./ActivityInstance";
import { ActivitySleepFromJSON, ActivitySleepToJSON, } from "./ActivitySleep";
import { ActivityTypeFromJSON, ActivityTypeToJSON, } from "./ActivityType";
/**
 * Check if a given object implements the ActivityInstanceSleep interface.
 */
export function instanceOfActivityInstanceSleep(value) {
    let isInstance = true;
    isInstance = isInstance && "activity" in value;
    isInstance = isInstance && "$type" in value;
    isInstance = isInstance && "duration" in value;
    return isInstance;
}
export function ActivityInstanceSleepFromJSON(json) {
    return ActivityInstanceSleepFromJSONTyped(json, false);
}
export function ActivityInstanceSleepFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        ...ActivityInstanceFromJSONTyped(json, ignoreDiscriminator),
        activity: ActivitySleepFromJSON(json.activity),
        $type: ActivityTypeFromJSON(json.$type),
        duration: json.duration,
    };
}
export function ActivityInstanceSleepToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...ActivityInstanceToJSON(value, true),
        activity: ActivitySleepToJSON(value.activity),
        $type: ActivityTypeToJSON(value.$type),
        duration: value.duration,
    };
}

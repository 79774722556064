import * as Eq from "fp-ts/es6/Eq"
import { flow, pipe } from "fp-ts/es6/function"
import * as Num from "fp-ts/es6/number"
import * as Opt from "fp-ts/es6/Option"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import { Getter } from "monocle-ts"
import {
  Dimension,
  numberAsValidNumber,
  UnitMass,
  ValidNumber,
  valueInUnit,
  ValueWithCanonicalUnit,
} from "@fitnesspilot/data-common"

import {
  CombinedFitnessLevel,
  combinedFitnessLevel,
  FitnessLevel,
} from "./fitnessLevel"

export enum BodyCompositionParts {
  adipose = "adipose",
  muscle = "muscle",
  water = "water",
}

export type BodyComposition = Partial<{
  [K in BodyCompositionParts]: number
}>
export const bodyComposition: Eq.Eq<BodyComposition> = Eq.struct({
  [BodyCompositionParts.adipose]: Num.Eq,
  [BodyCompositionParts.muscle]: Num.Eq,
  [BodyCompositionParts.water]: Num.Eq,
}) as Eq.Eq<BodyComposition>

export enum SumBodyCompositionTypes {
  relative = "relative",
  absolute = "absolute",
  fitnessLevel = "fitnessLevel",
}
export type SumBodyComposition = (
  | {
      type: SumBodyCompositionTypes.relative
    }
  | {
      type: SumBodyCompositionTypes.absolute
    }
  | {
      type: SumBodyCompositionTypes.fitnessLevel
      fitnessLevel: CombinedFitnessLevel
    }
) &
  BodyComposition
export const sumBodyComposition: Eq.Eq<SumBodyComposition> = {
  equals: (a, b) =>
    a.type === b.type &&
    bodyComposition.equals(a, b) &&
    (a.type !== SumBodyCompositionTypes.fitnessLevel ||
      b.type !== SumBodyCompositionTypes.fitnessLevel ||
      combinedFitnessLevel.equals(a.fitnessLevel, b.fitnessLevel)),
}

export const sumBodyCompositionAsBodyComposition = (
  bodyMass: ValueWithCanonicalUnit<Dimension.mass, ValidNumber>,
) =>
  new Getter<SumBodyComposition, BodyComposition>(
    ({ water, adipose, muscle, ...props }) =>
      props.type === SumBodyCompositionTypes.relative
        ? { water, adipose, muscle }
        : props.type === SumBodyCompositionTypes.absolute
        ? pipe(
            { water, adipose, muscle },
            Rec.map(
              flow(
                Opt.fromNullable,
                Opt.map(
                  (v) =>
                    v /
                    pipe(
                      bodyMass,
                      valueInUnit(Dimension.mass, UnitMass.kilogram).reverseGet,
                      numberAsValidNumber.reverseGet,
                    ),
                ),
                Opt.toUndefined,
              ),
            ),
          )
        : props.type === SumBodyCompositionTypes.fitnessLevel
        ? fitnessLevelToBodyComposition(props.fitnessLevel)
        : fitnessLevelToBodyComposition({
            bodyFat: FitnessLevel.medium,
            musculature: FitnessLevel.medium,
          }),
  )

export const fitnessLevelToBodyComposition = (
  fitnessLevel: CombinedFitnessLevel,
): BodyComposition => ({
  adipose: {
    [FitnessLevel.high]: 0.32,
    [FitnessLevel.medium]: 0.2,
    [FitnessLevel.low]: 0.13,
  }[fitnessLevel.bodyFat],
  muscle: {
    [FitnessLevel.high]: 0.55,
    [FitnessLevel.medium]: 0.45,
    [FitnessLevel.low]: 0.35,
  }[fitnessLevel.musculature],
  water: 0.5,
})

import * as Eq from "fp-ts/es6/Eq"
import * as Opt from "fp-ts/es6/Option"
import * as String from "fp-ts/es6/string"
import { Lens, Optional } from "monocle-ts"

import {
  ActivityFilters,
  activityFilters,
} from "../activityFilters/ActivityFilters"
import {
  ActivitySorting,
  activitySorting,
} from "../activityFilters/ActivitySorting"

export type ExerciseList = {
  search: Opt.Option<string>
  sorting: ActivitySorting
  filters: ActivityFilters
}
export const exerciseList: Eq.Eq<ExerciseList> = Eq.struct({
  search: Opt.getEq(String.Eq),
  sorting: activitySorting,
  filters: activityFilters,
})
export const _search = Lens.fromProp<ExerciseList>()("search")
export const _searchOpt = Optional.fromOptionProp<ExerciseList>()("search")
export const _sorting = Lens.fromProp<ExerciseList>()("sorting")
export const _filters = Lens.fromProp<ExerciseList>()("filters")

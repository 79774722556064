import LoadingBar, { LoadingBarContainerProps } from "react-redux-loading-bar"
import styled from "@emotion/styled"

export { LoadingBarContainerProps }

export const LoadingBarContainer = styled(LoadingBar)`
  height: 5px;
  background: ${({ theme }) => theme.colours.primary.toString()};
  position: relative;
  /* Must be above 1050 so that it overlays the modal backdrop */
  z-index: 1100;
`

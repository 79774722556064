/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Option1StringFromJSON, Option1StringToJSON, } from "./Option1String";
/**
 * Check if a given object implements the SportsOptions interface.
 */
export function instanceOfSportsOptions(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "workoutMethod" in value;
    isInstance = isInstance && "workoutTarget" in value;
    return isInstance;
}
export function SportsOptionsFromJSON(json) {
    return SportsOptionsFromJSONTyped(json, false);
}
export function SportsOptionsFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: json.id,
        workoutMethod: json.workoutMethod.map(Option1StringFromJSON),
        workoutTarget: json.workoutTarget.map(Option1StringFromJSON),
    };
}
export function SportsOptionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        workoutMethod: value.workoutMethod.map(Option1StringToJSON),
        workoutTarget: value.workoutTarget.map(Option1StringToJSON),
    };
}

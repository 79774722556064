import * as Opt from "fp-ts/es6/Option"
import * as Tree from "fp-ts/es6/Tree"

import * as ActivityFilters from "../activityFilters/ActivityFilters"
import { ActivitySorting } from "../activityFilters/ActivitySorting"
import { ActivityWithSettingsWithId } from "../activitySettings/ActivityWithSettings"
import * as GeneralSportsSettings from "../sport/GeneralSportsSettings"
import * as MuscleSettings from "../sport/MuscleSettings"
import * as Sport from "../sport/Sport"
import { ExerciseList } from "./ExerciseList"
import { MySports, MySportsTab } from "./MySports"
import { SportPreferences, SportPreferencesTab } from "./SportPreferences"

export const stateKey = "activity"

export type State = {
  activitiesWithSettings: ReadonlyArray<ActivityWithSettingsWithId>
  sports: ReadonlyArray<Sport.SportWithId>
  muscleSettings: Tree.Forest<MuscleSettings.MuscleSettings>
  generalSportsSettings: GeneralSportsSettings.GeneralSportsSettings
  mySports: MySports
  sportPreferences: SportPreferences
  exerciseList: ExerciseList
}
export const initialState: State = {
  activitiesWithSettings: [],
  sports: [],
  muscleSettings: [],
  generalSportsSettings: GeneralSportsSettings.empty,
  mySports: {
    currentTab: MySportsTab.sports,
  },
  sportPreferences: {
    currentTab: SportPreferencesTab.settings,
    sport: Opt.none,
  },
  exerciseList: {
    filters: ActivityFilters.empty,
    search: Opt.none,
    sorting: ActivitySorting.default,
  },
}

export type ParentState = { [k in typeof stateKey]: State }

import { FC } from "react"
import { FaCheck, FaEdit, FaShareAlt, FaUser } from "react-icons/fa"
import { FormattedMessage, IntlShape, useIntl } from "react-intl"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import {
  dateAsTime,
  Day,
  dayOfTime,
  mkTimeOfDay,
  timeFromDayAndTimeOfDay,
} from "time-ts/es6"

import { Clickable } from "@fitnesspilot/components-common/dist/atoms/Clickable/Clickable"
import { DayInput } from "@fitnesspilot/components-common/dist/atoms/input/DayInput"
import { ImageWithDetails } from "@fitnesspilot/components-common/dist/molecules/ImageWithDetails/ImageWithDetails"
import { ToggleButton } from "@fitnesspilot/components-common/dist/molecules/ToggleButton/ToggleButton"
import { unsafeFromSome } from "@fitnesspilot/data-common/dist/type"
import * as UserPhoto from "@fitnesspilot/data-user/dist/photo"

const editSaveDateTitle = ({
  intl,
  isEditing,
}: {
  intl: IntlShape
  isEditing: boolean
}) =>
  isEditing
    ? intl.formatMessage({
        defaultMessage: "Save date",
      })
    : intl.formatMessage({
        defaultMessage: "Save date",
      })

const DateCreated = css`
  align-self: center;
  flex-grow: 0;
  white-space: nowrap;
  margin-left: 10px;
`

const DateCreatedSpan = styled.span`
  ${DateCreated}
`
const DateCreatedInput = styled(DayInput)`
  ${DateCreated}
`

const ClickableCss = css`
  align-self: stretch;
  width: 61px;
  text-align: center;
  font-size: 14px;
`

const StyledClickable = styled(Clickable)`
  ${ClickableCss}
`

const StyledToggleButton = styled(ToggleButton)`
  ${ClickableCss}
`

const container = css`
  display: contents;
  font-size: 14px;
`

const DateContainer = styled.div`
  ${container}
`

const ActionContainer = styled.menu`
  ${container}

  & > :first-of-type {
    margin-left: auto;
  }
`

export type PhotoProps = {
  className?: string
  isEditing: boolean
  isProfilePhoto: boolean
  onChange: (v: UserPhoto.Photo) => IO.IO<void>
  onSave: (v: UserPhoto.Photo) => void
  onShare: Opt.Option<(v: UserPhoto.Photo) => void>
  onStartEdit: (v: UserPhoto.Photo) => void
  onSetProfilePhoto: (v: Opt.Option<UserPhoto.Photo>) => void
  photo: UserPhoto.Photo
}

export const Photo: FC<PhotoProps> = ({
  className,
  photo,
  isEditing,
  isProfilePhoto,
  onChange,
  onStartEdit,
  onSave,
  onSetProfilePhoto,
  onShare,
}) => {
  const intl = useIntl()

  const title = intl.formatMessage({
    defaultMessage: "Date taken",
  })

  return (
    <ImageWithDetails
      image={{
        ...photo,
        dateCreated: Opt.some(photo.dateCreated),
      }}
      autoHeight={false}
      {...{ className }}
    >
      <DateContainer>
        {isEditing ? (
          <DateCreatedInput
            hideClear={false}
            required
            onChange={(v: Opt.Option<Day>) =>
              pipe(
                v,
                unsafeFromSome,
                (d) =>
                  timeFromDayAndTimeOfDay(
                    d,
                    unsafeFromSome(mkTimeOfDay(0, 0, 0)),
                  ),
                (v) => pipe(photo, UserPhoto.dateCreated.set(v)),
                onChange,
              )
            }
            min={Opt.none}
            max={Opt.none}
            step={Opt.none}
            value={Opt.some(dayOfTime.get(photo.dateCreated))}
            aria-label={title}
          />
        ) : (
          <DateCreatedSpan aria-label={title}>
            <FormattedMessage
              defaultMessage="{dateCreated, date}"
              values={{
                dateCreated: pipe(photo.dateCreated, dateAsTime.reverseGet),
              }}
            />
          </DateCreatedSpan>
        )}

        <StyledClickable
          onClick={() => (isEditing ? onSave(photo) : onStartEdit(photo))}
          title={editSaveDateTitle({ intl, isEditing })}
        >
          {isEditing ? <FaCheck /> : <FaEdit />}
        </StyledClickable>
      </DateContainer>

      <ActionContainer as="menu">
        {Opt.isSome(onShare) && (
          <StyledClickable
            onClick={() => onShare.value(photo)}
            title="Share image"
          >
            <FaShareAlt />
          </StyledClickable>
        )}

        <StyledToggleButton
          name="isProfilePhoto"
          type="radio"
          onClick={() =>
            onSetProfilePhoto(isProfilePhoto ? Opt.none : Opt.some(photo))
          }
          isSelected={isProfilePhoto}
          titleUnselected="Use as profile image"
          titleSelected="Remove profile image"
          css={{}}
        >
          <FaUser />
        </StyledToggleButton>
      </ActionContainer>
    </ImageWithDetails>
  )
}

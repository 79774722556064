import { HTMLAttributes, ReactNode } from "react"
import Media from "react-media"
import { Col, ColProps, Container, Row } from "reactstrap"
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"

import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"

import { OmitFromKnownKeys } from "@fitnesspilot/data-common/dist/type"

const OuterRow = styled(Row)`
  flex-wrap: nowrap;
`

const ImageCol = styled(Col)`
  > * {
    width: 100px;
  }
`

const VerticalRows = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const Title = styled(Row)`
  gap: 0.25em;
  font-weight: ${({ theme }) => theme.font.weights.bold};
  font-size: 1.5em;
`

const TopRight = styled(({ className, ...props }: ColProps) => (
  <Col className={`c-topRight ${className ?? ""}`} {...props} />
))`
  display: flex;
  align-items: center;
`

export type ImageWithTextBesideItProps = {
  image: ReactNode
  title: ReactNode
  topRight: Opt.Option<ReactNode>
  children: ReactNode
} & OmitFromKnownKeys<HTMLAttributes<HTMLDivElement>, "title">

export const ImageWithTextBesideIt = styled(
  ({
    image,
    title,
    topRight,
    children,
    ...props
  }: ImageWithTextBesideItProps) => {
    const theme = useTheme()

    return (
      <Media queries={theme.media}>
        {({ md }) =>
          md ? (
            <OuterRow {...props}>
              <ImageCol xs="auto">{image}</ImageCol>

              <Col>
                <VerticalRows>
                  <Container fluid={true}>
                    <Row>
                      <Col>
                        <Title>{title}</Title>
                      </Col>

                      {pipe(
                        topRight,
                        Opt.fold(
                          () => null,
                          (topRight) => (
                            <TopRight xs="auto">{topRight}</TopRight>
                          ),
                        ),
                      )}
                    </Row>
                  </Container>

                  <div>{children}</div>
                </VerticalRows>
              </Col>
            </OuterRow>
          ) : (
            <>
              <Row {...props}>
                <ImageCol xs="auto">{image}</ImageCol>

                <Col>
                  <Container fluid={true}>
                    <Row>
                      <Col>
                        <Title>{title}</Title>
                      </Col>

                      {pipe(
                        topRight,
                        Opt.fold(
                          () => null,
                          (topRight) => (
                            <TopRight xs="auto">{topRight}</TopRight>
                          ),
                        ),
                      )}
                    </Row>
                  </Container>
                </Col>
              </Row>

              <Row>
                <Col className="pt-4">{children}</Col>
              </Row>
            </>
          )
        }
      </Media>
    )
  },
)``

import { ReactNode } from "react"
import styled from "@emotion/styled"

import { Clickable, ClickableProps } from "../../atoms/Clickable/Clickable"
import { Icon } from "../../atoms/Icon/Icon"

const Contents = styled.div``

export type ClickableWithIconProps = {
  icon: ReactNode
} & ClickableProps

export const ClickableWithIcon = styled(
  ({ children, icon, ...props }: ClickableWithIconProps) => (
    <Clickable {...props}>
      <Icon>{icon}</Icon>
      <Contents>{children}</Contents>
    </Clickable>
  ),
)`
  display: flex;
  padding: 0.2em;

  .c-icon {
    margin-right: 0.3em;
  }
`

import * as Bool from "fp-ts/es6/boolean"
import * as Eq from "fp-ts/es6/Eq"
import * as Func from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Str from "fp-ts/es6/string"
import { Lens, Prism } from "monocle-ts"
import { Time, time } from "time-ts/es6"
import {
  emailAddress,
  liftGetterEq,
  prismToGetter,
} from "@fitnesspilot/data-common"

import { _isEnabled, _scopes, GoogleApi, googleApi } from "./GoogleApi"
import { Person, person } from "./Person"
import { _email as __email, UserAuth } from "./UserAuth"

// We should prolly make the UserId isomorphic to the user's URI
export type UserId = string & { readonly __tag: unique symbol }
export const stringAsUserId = new Prism(
  // @TODO
  Opt.some as (v: string) => Opt.Option<UserId>,
  Func.identity as (v: UserId) => string,
)
export const userId = liftGetterEq(prismToGetter(stringAsUserId))(Str.Eq)

export type User = UserAuth & {
  id: UserId
  created: Opt.Option<Time>
  person: Person
  hasCompletedSetup: boolean
  applyDemoData: boolean
  googleApi: GoogleApi
}
export const user = Eq.struct<User>({
  id: userId,
  created: Opt.getEq(time),
  email: emailAddress,
  person: person,
  hasCompletedSetup: Bool.Eq,
  applyDemoData: Bool.Eq,
  googleApi: googleApi,
})
export { _email } from "./UserAuth"
export const _id = Lens.fromProp<User>()("id")
export const _created = Lens.fromProp<User>()("created")
export const _person = Lens.fromProp<User>()("person")
export const _image = Lens.fromPath<User>()(["person", "image"])
export const _givenName = Lens.fromPath<User>()(["person", "givenName"])
export const _familyName = Lens.fromPath<User>()(["person", "familyName"])
export const _name = Lens.fromPath<User>()(["person", "name"])
export const _hasCompletedSetup = Lens.fromProp<User>()("hasCompletedSetup")
export const _applyDemoData = Lens.fromProp<User>()("applyDemoData")
export const _googleApi = Lens.fromProp<User>()("googleApi")
export const _googleApiIsEnabled = _googleApi.compose(_isEnabled)
export const _googleApiScopes = _googleApi.compose(_scopes)

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SportFromJSON, SportsOptionsFromJSON, SportToJSON, } from "../models";
import * as runtime from "../runtime";
/**
 *
 */
export class SportsApi extends runtime.BaseAPI {
    /**
     */
    async activitiesApiUsersUserIdSportsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling activitiesApiUsersUserIdSportsGet.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activities/api/users/{userId}/sports`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SportFromJSON));
    }
    /**
     */
    async activitiesApiUsersUserIdSportsGet(requestParameters, initOverrides) {
        const response = await this.activitiesApiUsersUserIdSportsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async activitiesApiUsersUserIdSportsIdIsLikedAddPutRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling activitiesApiUsersUserIdSportsIdIsLikedAddPut.");
        }
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling activitiesApiUsersUserIdSportsIdIsLikedAddPut.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activities/api/users/{userId}/sports/{id}/isLiked/add`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async activitiesApiUsersUserIdSportsIdIsLikedAddPut(requestParameters, initOverrides) {
        await this.activitiesApiUsersUserIdSportsIdIsLikedAddPutRaw(requestParameters, initOverrides);
    }
    /**
     */
    async activitiesApiUsersUserIdSportsIdIsLikedRemovePutRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling activitiesApiUsersUserIdSportsIdIsLikedRemovePut.");
        }
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling activitiesApiUsersUserIdSportsIdIsLikedRemovePut.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activities/api/users/{userId}/sports/{id}/isLiked/remove`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async activitiesApiUsersUserIdSportsIdIsLikedRemovePut(requestParameters, initOverrides) {
        await this.activitiesApiUsersUserIdSportsIdIsLikedRemovePutRaw(requestParameters, initOverrides);
    }
    /**
     */
    async activitiesApiUsersUserIdSportsOptionsRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling activitiesApiUsersUserIdSportsOptions.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activities/api/users/{userId}/sports`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "OPTIONS",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SportsOptionsFromJSON));
    }
    /**
     */
    async activitiesApiUsersUserIdSportsOptions(requestParameters, initOverrides) {
        const response = await this.activitiesApiUsersUserIdSportsOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async activitiesApiUsersUserIdSportsPutRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling activitiesApiUsersUserIdSportsPut.");
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters["Content-Type"] = "application/json-patch+json";
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activities/api/users/{userId}/sports`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
            body: SportToJSON(requestParameters.sport),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SportFromJSON(jsonValue));
    }
    /**
     */
    async activitiesApiUsersUserIdSportsPut(requestParameters, initOverrides) {
        const response = await this.activitiesApiUsersUserIdSportsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
}

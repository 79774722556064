import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd"
import {
  FaCheck,
  FaDumbbell,
  FaPlus,
  FaRegThumbsUp,
  FaVideo,
} from "react-icons/fa"
import { FormattedMessage, useIntl } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import { useParams, useSearchParams } from "react-router-dom"
import { Badge, Button as Button_, ButtonProps } from "reactstrap"
import styled from "@emotion/styled"

import * as Bool from "fp-ts/es6/boolean"
import {
  constFalse,
  constNull,
  constTrue,
  constVoid,
  flow,
  pipe,
} from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { Getter, Prism } from "monocle-ts"
import { isoStringAsTime } from "time-ts/es6"
import { match, P } from "ts-pattern"
import { arrTrav, mapPair, runIO, useStateIO } from "@fitnesspilot/data-common"

import { EventChangeType } from "@fitnesspilot/api-combined"
import { ActivityName } from "@fitnesspilot/components-activity/dist/atoms/ActivityName/ActivityName"
import { CancelButton } from "@fitnesspilot/components-common/dist/molecules/CancelButton/CancelButton"
import { DeleteButton } from "@fitnesspilot/components-common/dist/molecules/DeleteButton/DeleteButton"
import { Header } from "@fitnesspilot/components-common/dist/molecules/Header/Header"
import { Markdown } from "@fitnesspilot/components-common/dist/molecules/Markdown/Markdown"
import { StickyFooter } from "@fitnesspilot/components-common/dist/molecules/StickyFooter/StickyFooter"
import { tabs } from "@fitnesspilot/components-common/dist/templates/Tabs/Tabs"
import { defaultEventTitle } from "@fitnesspilot/components-event/dist/atoms/DefaultEventTitle/DefaultEventTitle"
import { ActivityInputs } from "@fitnesspilot/components-event/dist/molecules/ActivityInputs/ActivityInputs"
import { EventHeader } from "@fitnesspilot/components-event/dist/molecules/EventHeader/EventHeader"
import { AddActivityModalContainer } from "@fitnesspilot/components-event/dist/organisms/AddActivityModal/AddActivityModal"
import { EventActivity } from "@fitnesspilot/components-event/dist/organisms/EventActivity/EventActivity"
import {
  EventChangeAction,
  EventChangeModal,
} from "@fitnesspilot/components-event/dist/organisms/EventChangeModal/EventChangeModal"
import { EventTimeModalContainer } from "@fitnesspilot/components-event/dist/organisms/EventTimeModal/EventTimeModal"
import { VideoPlayer } from "@fitnesspilot/components-event/dist/organisms/VideoPlayer/VideoPlayer"
import {
  ActivityId,
  stringAsActivityId,
} from "@fitnesspilot/data-activity/dist/activity/ActivityId"
import { ActivityType } from "@fitnesspilot/data-activity/dist/activity/ActivityType"
import * as WithId from "@fitnesspilot/data-activity/dist/activity/WithId"
import {
  flattenActivityInstances,
  foldActivityInstanceExercise,
} from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"
import * as ActivitySettingsExerciseListing from "@fitnesspilot/data-activity/dist/activitySettings/ActivitySettingsExercise"
import * as ActivityWithSettings from "@fitnesspilot/data-activity/dist/activitySettings/ActivityWithSettings"
import * as ActivityData from "@fitnesspilot/data-activity/dist/store"
import { stringAsEventId } from "@fitnesspilot/data-event/dist/calendar/Event"
import { RecurrenceType } from "@fitnesspilot/data-event/dist/calendar/Recurrence"
import * as EventData from "@fitnesspilot/data-event/dist/store"
import { AddActivityStep } from "@fitnesspilot/data-event/dist/store/AddActivity"
import { _activities } from "@fitnesspilot/data-event/dist/store/EditingEvent"

import { RootState } from "../../reduxStore"
import { MainTemplate } from "../../templates/Main/Main"

import { Dispatch } from "redux"
import { push as pushHistory, RouterActions } from "redux-first-history"

const mapState = (state: RootState) => ({
  editingEvent: pipe(
    state,
    EventData.selectors.state.composeLens(EventData.selectors.editingEvent).get,
  ),
  activitiesWithSettings: pipe(
    state,
    ActivityData.selectors.state.composeLens(
      ActivityData.selectors.activitiesWithSettings,
    ).get,
  ),
})

const mapDispatch = (
  dispatch: Dispatch<RouterActions | EventData.Action | ActivityData.Action>,
) => {
  const dispatch_ =
    (
      act: RouterActions | EventData.Action | ActivityData.Action,
    ): IO.IO<void> =>
    () =>
      pipe(act, dispatch, constVoid)

  return {
    onSetTitle: flow(EventData.setEventTitle, dispatch_),
    onUpdateActivity: flow(EventData.updateActivity, dispatch_),
    onMoveActivity: flow(EventData.moveActivity, dispatch_),
    onRemoveActivity: flow(EventData.removeActivity, dispatch_),
    onSave: flow(
      EventData.saveEditingEvent,
      dispatch_,
      IO.chain(() => pipe(pushHistory("/calendar"), dispatch_)),
    ),
    onGetRecommendation: flow(
      EventData.fetchRecommendationsForEditingEvent,
      dispatch_,
    ),
    onDelete: flow(EventData.deleteEditingEvent, dispatch_),
    onCancel: pipe(true, EventData.cancelEditingEvent, dispatch_),
    onSetActivityListing: flow(ActivityData.setActivityListing, dispatch_),
    onSetAddActivityStep: flow(EventData.setAddActivityStep, dispatch_),
    startEditingEventById: flow(EventData.startEditingEventById, dispatch_),
    initNewEvent: flow(EventData.initNewEvent, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

const Button = styled(({ className, ...props }: ButtonProps) => (
  <Button_ className={`c-button ${className ?? ""}`} {...props} />
))``

const ActivityList = styled.div`
  & > * {
    margin: 20px 0;
  }
`

const EmptyEventInfo = styled.div`
  width: fit-content;
  margin: 100px auto;
  text-align: center;
`

const EventButtons = styled.div`
  display: flex;

  & .c-button {
    margin: 4px;
  }
`

const EventInstructions = styled.div`
  margin: 20px 0;
`

const instructionsId = (activityId: ActivityId) =>
  `${pipe(activityId, stringAsActivityId.reverseGet)}-instructions`

export enum Tab {
  details = "details",
  instructions = "instructions",
}

const Tabs = styled(tabs<Tab>())`
  padding-top: 10px;
  background: #eeeef8;
`

const isNonDraggable =
  (root: HTMLElement | null) =>
  (el: HTMLElement | null): boolean =>
    pipe(
      el,
      Opt.fromNullable,
      Opt.map((el) =>
        pipe(
          el.getAttribute("data-non-draggable"),
          Opt.fromNullable,
          Opt.fold(
            () =>
              pipe(
                el.parentElement,
                (parent) => (parent === root ? Opt.none : Opt.some(parent)),
                Opt.map(isNonDraggable(root)),
                Opt.getOrElse(constFalse),
              ),
            constTrue,
          ),
        ),
      ),
      Opt.getOrElse(constFalse),
    )

export type EventDetailsPageProps = PropsFromRedux

export const EventDetailsPage: FC<EventDetailsPageProps> = ({
  editingEvent,
  activitiesWithSettings,
  onSetTitle,
  onDelete,
  onUpdateActivity,
  onMoveActivity,
  onRemoveActivity,
  onSetActivityListing,
  onSetAddActivityStep,
  onSave: onSave_,
  onCancel,
  onGetRecommendation,
  startEditingEventById,
  initNewEvent,
}) => {
  const params = useParams<{ eventId: string }>()
  const eventId = pipe(
    params.eventId,
    Opt.fromNullable,
    Opt.chain((id) => (id === "new" ? Opt.none : Opt.some(id))),
    Opt.chain(stringAsEventId.getOption),
  )

  const [searchParams, _] = useSearchParams()
  const between = pipe(
    ["start", "end"] as const,
    mapPair((k: "start" | "end") =>
      pipe(
        searchParams.get(k),
        Opt.fromNullable,
        Opt.chain(isoStringAsTime.getOption),
      ),
    ),
  )

  const intl = useIntl()
  const event = useMemo(
    () =>
      pipe(
        editingEvent,
        Prism.some<
          typeof editingEvent extends Opt.Option<infer a> ? a : never
        >().composeLens(WithId._value()).getOption,
      ),
    [editingEvent],
  )
  const activityListings = useMemo(
    () =>
      pipe(
        activitiesWithSettings,
        arrTrav<
          typeof activitiesWithSettings extends ReadonlyArray<infer a>
            ? a
            : never
        >()
          .composeGetter(
            new Getter((a) =>
              WithId._value<
                ActivityId,
                ActivityWithSettings.ActivityWithSettings
              >()
                .composeOptional(ActivityWithSettings._ActivitySettingsExercise)
                .composeLens(ActivitySettingsExerciseListing._listing)
                .composeGetter<
                  WithId.WithId<
                    ActivityId,
                    ActivitySettingsExerciseListing.ActivitySettingsExerciseListing
                  >
                >(
                  new Getter((s) => ({
                    id: a.id,
                    value: s,
                  })),
                )
                .headOption(a),
            ),
          )
          .composePrism(Prism.some()).getAll,
      ),
    [activitiesWithSettings],
  )
  const [addActivitiesIsOpen, setAddActivitiesIsOpen] = useState(false)
  const [eventTimeIsOpen, setEventTimeIsOpen] = useState(false)
  const [eventChangeAction, setEventChangeAction] = useState<
    Opt.Option<EventChangeAction>
  >(Opt.none)
  const [isDragDisabled, setIsDragDisabled] = useState(false)
  const [currentTab, onChangeTab] = useStateIO(() => Tab.details)()

  const onSetAddActivityIsOpen = useCallback(
    (v: boolean) => () => setAddActivitiesIsOpen(v),
    [setAddActivitiesIsOpen],
  )
  const onAddActivities = useCallback(() => {
    onSetAddActivityStep(AddActivityStep.activities)()
    setAddActivitiesIsOpen(true)
  }, [onSetAddActivityStep, setAddActivitiesIsOpen])
  const onStartWorkoutVideo = useCallback(() => {
    onSetAddActivityStep(AddActivityStep.searchVideo)()
    setAddActivitiesIsOpen(true)
  }, [onSetAddActivityStep, setAddActivitiesIsOpen])
  const onSetEventTimeIsOpen = useCallback(
    (v: boolean) => () => setEventTimeIsOpen(v),
    [setEventTimeIsOpen],
  )

  const onDragEnd = ({ source, destination }: DropResult) =>
    pipe(
      destination,
      Opt.fromNullable,
      Opt.traverse(IO.Monad)((destination) =>
        pipe(
          source.index !== destination.index,
          Bool.fold(
            () => IO.of(undefined),
            () => onMoveActivity(source.index, destination.index),
          ),
        ),
      ),
      runIO,
    )

  const onSave = (payload: { changeType: EventChangeType }): IO.IO<void> =>
    pipe(
      event,
      Opt.fold(
        () => IO.of(undefined),
        flow(defaultEventTitle(intl), Opt.some, onSetTitle),
      ),
      IO.chain(() => onSave_(payload)),
    )

  const showEventChangeModal = (a: EventChangeAction): IO.IO<void> =>
    pipe(
      event,
      Opt.map((e) => e.recurrence.type === RecurrenceType.recurring),
      Opt.getOrElse(constFalse),
      Bool.fold(
        () =>
          a === EventChangeAction.change
            ? onSave({ changeType: EventChangeType.Single })
            : onDelete({ changeType: EventChangeType.Single }),
        () => () => pipe(a, Opt.some, setEventChangeAction),
      ),
    )

  const activityRoots: Array<HTMLDivElement | null> = []

  useEffect(
    () =>
      pipe(
        eventId,
        Opt.fold(
          () =>
            pipe(
              editingEvent,
              Opt.fold(
                () => initNewEvent({ between })(),
                () => IO.of(undefined),
              ),
            ),
          (eventId) =>
            pipe(
              editingEvent,
              Opt.chain((e) => e.id),
              Opt.fold(constTrue, (id) => id !== eventId),
              Bool.fold(IO.of(undefined), () =>
                startEditingEventById({ eventId })(),
              ),
            ),
        ),
      ),
    [editingEvent, eventId, between, initNewEvent, startEditingEventById],
  )

  const timeButtonRef = useRef<HTMLButtonElement>(null)

  return (
    <MainTemplate
      header={pipe(
        event,
        Opt.fold(
          () => (
            <Header
              icon={<FaDumbbell />}
              title={<FormattedMessage defaultMessage="Event" />}
            />
          ),
          (event) => (
            <EventHeader
              title={event.title}
              between={event.between}
              alignment={Opt.some(event.alignment)}
              showDelete={Opt.isSome(eventId)}
              onChangeTitle={onSetTitle}
              onClickTime={() => setEventTimeIsOpen(true)}
              onDelete={showEventChangeModal(EventChangeAction.delete)}
              {...{ timeButtonRef }}
            />
          ),
        ),
      )}
      data-help-mode="eventDetails"
    >
      <Tabs
        tabTexts={{
          [Tab.details]: <FormattedMessage defaultMessage="Details" />,
          [Tab.instructions]: (
            <>
              <FormattedMessage defaultMessage="Instructions" />
              {pipe(
                event,
                Opt.chain((e) => e.video),
                Opt.fold(
                  () => null,
                  () => (
                    <>
                      {" "}
                      <Badge>
                        <FaVideo />
                      </Badge>
                    </>
                  ),
                ),
              )}
            </>
          ),
        }}
        {...{ currentTab, onChangeTab }}
      >
        {{
          [Tab.details]: pipe(
            event,
            Opt.map(_activities.get),
            Opt.fold(
              () => <FormattedMessage defaultMessage="Loading..." />,
              (as) =>
                pipe(
                  as,
                  Arr.isEmpty,
                  Bool.fold(
                    () => (
                      <>
                        <DragDropContext {...{ onDragEnd }}>
                          <Droppable
                            droppableId="activities"
                            direction="vertical"
                          >
                            {(provided) => (
                              <ActivityList
                                ref={(el) => provided.innerRef(el)}
                                {...provided.droppableProps}
                              >
                                {pipe(
                                  as,
                                  Arr.mapWithIndex((i, activity) => (
                                    <div
                                      key={i}
                                      ref={(el) => (activityRoots[i] = el)}
                                      onPointerDown={(e) =>
                                        pipe(
                                          e.target as HTMLElement,
                                          isNonDraggable(activityRoots[i]),
                                          setIsDragDisabled,
                                        )
                                      }
                                      onPointerUp={() =>
                                        setIsDragDisabled(false)
                                      }
                                    >
                                      <Draggable
                                        key={i}
                                        draggableId={`${i}`}
                                        index={i}
                                        {...{ isDragDisabled }}
                                      >
                                        {(provided) => (
                                          <div
                                            ref={(el) => provided.innerRef(el)}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            {match(activity)
                                              .with(
                                                {
                                                  $type: P.not(
                                                    ActivityType.group,
                                                  ),
                                                },
                                                (activity) => (
                                                  <EventActivity
                                                    key={i}
                                                    {...{ activity }}
                                                    activityListing={pipe(
                                                      activityListings,
                                                      Arr.findFirst(
                                                        (s) =>
                                                          s.id ===
                                                          activity.value
                                                            .activity.id,
                                                      ),
                                                      Opt.map((s) => s.value),
                                                    )}
                                                    onSetActivityListing={(
                                                      listing,
                                                    ) =>
                                                      pipe(
                                                        activity,
                                                        foldActivityInstanceExercise(
                                                          () =>
                                                            IO.of(undefined),
                                                          (a) =>
                                                            onSetActivityListing(
                                                              {
                                                                activity:
                                                                  a.activity.id,
                                                                listing,
                                                              },
                                                            ),
                                                        ),
                                                      )
                                                    }
                                                    topRight={
                                                      <DeleteButton
                                                        onClick={onRemoveActivity(
                                                          i,
                                                        )}
                                                      />
                                                    }
                                                  >
                                                    <ActivityInputs
                                                      {...{ activity }}
                                                      onChange={(a) =>
                                                        onUpdateActivity(i, a)
                                                      }
                                                    />
                                                  </EventActivity>
                                                ),
                                              )
                                              .with(
                                                { $type: ActivityType.group },
                                                // TODO add support for `ActivityInstanceGroup`
                                                (activity) => null,
                                              )
                                              .exhaustive()}
                                          </div>
                                        )}
                                      </Draggable>
                                    </div>
                                  )),
                                )}
                                {provided.placeholder}
                              </ActivityList>
                            )}
                          </Droppable>
                        </DragDropContext>
                        <Button onClick={onAddActivities} color="primary">
                          <FaPlus />{" "}
                          <FormattedMessage defaultMessage="Add activities" />
                        </Button>
                      </>
                    ),
                    () => (
                      <EmptyEventInfo>
                        <h2>
                          <FormattedMessage defaultMessage="No data present yet.<br></br>Add activities to calendar." />
                        </h2>
                        <EventButtons>
                          <Button
                            onClick={onGetRecommendation()}
                            color="primary"
                            data-help-mode="getRecommendations"
                          >
                            <FaRegThumbsUp type="outline" />{" "}
                            <FormattedMessage defaultMessage="Get recommendation" />
                          </Button>
                          <Button
                            onClick={onAddActivities}
                            color="primary"
                            data-help-mode="addActivities"
                          >
                            <FaPlus />{" "}
                            <FormattedMessage defaultMessage="Add activities" />
                          </Button>
                          <Button
                            onClick={onStartWorkoutVideo}
                            color="primary"
                            data-help-mode="startWorkoutVideo"
                          >
                            <FaVideo />{" "}
                            <FormattedMessage defaultMessage="Start video workout" />
                          </Button>
                        </EventButtons>
                      </EmptyEventInfo>
                    ),
                  ),
                ),
            ),
          ),
          [Tab.instructions]: (
            <>
              {pipe(
                event,
                Opt.chain((e) => e.video),
                Opt.fold(
                  () => null,
                  ({ video }) => (
                    <VideoPlayer {...{ video }} width={560} height={315} />
                  ),
                ),
              )}

              {pipe(
                event,
                Opt.map(flow(_activities.get, flattenActivityInstances)),
                Opt.fold(
                  constNull,
                  flow(
                    Arr.mapWithIndex((i, activity) => (
                      <EventInstructions key={i}>
                        <h2>
                          <ActivityName
                            id={activity.value.activity.id}
                            def={activity.value.activity.value.title}
                          />
                        </h2>
                        {pipe(
                          activity.value.activity.value.instructions,
                          Opt.fold(
                            () => null,
                            (instructions) => (
                              <Markdown
                                id={instructionsId(activity.value.activity.id)}
                              >
                                {instructions}
                              </Markdown>
                            ),
                          ),
                        )}
                      </EventInstructions>
                    )),
                  ),
                ),
              )}
            </>
          ),
        }}
      </Tabs>

      <StickyFooter>
        <CancelButton type="button" onClick={onCancel} />
        {pipe(
          eventId,
          Opt.fold(
            () => (
              <Button
                onClick={onSave({ changeType: EventChangeType.All })}
                color="success"
                data-help-mode="save"
              >
                <FormattedMessage defaultMessage="Create" /> <FaCheck />
              </Button>
            ),
            () => (
              <Button
                onClick={showEventChangeModal(EventChangeAction.change)}
                color="success"
              >
                <FormattedMessage defaultMessage="Save" /> <FaCheck />
              </Button>
            ),
          ),
        )}
      </StickyFooter>

      <EventChangeModal
        id="modals-eventChange"
        action={pipe(
          eventChangeAction,
          Opt.getOrElse<EventChangeAction>(() => EventChangeAction.change),
        )}
        isOpen={Opt.isSome(eventChangeAction)}
        onChange={flow(
          onSave,
          IO.chain(() => () => setEventChangeAction(Opt.none)),
        )}
        onDelete={flow(
          onDelete,
          IO.chain(() => () => setEventChangeAction(Opt.none)),
        )}
        onCancel={() => setEventChangeAction(Opt.none)}
      />

      <AddActivityModalContainer
        id="modals-addActivity"
        isOpen={addActivitiesIsOpen}
        onSetIsOpen={onSetAddActivityIsOpen}
      />
      <EventTimeModalContainer
        id="modals-eventTime"
        isOpen={eventTimeIsOpen}
        target={timeButtonRef}
        onSetIsOpen={onSetEventTimeIsOpen}
      />
    </MainTemplate>
  )
}

export const EventDetailsPageContainer = connector(EventDetailsPage)

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ActivityMuscleInfo interface.
 */
export function instanceOfActivityMuscleInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "alignment" in value;
    isInstance = isInstance && "workload" in value;
    return isInstance;
}
export function ActivityMuscleInfoFromJSON(json) {
    return ActivityMuscleInfoFromJSONTyped(json, false);
}
export function ActivityMuscleInfoFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        alignment: json.alignment,
        workload: json.workload,
    };
}
export function ActivityMuscleInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        alignment: value.alignment,
        workload: value.workload,
    };
}

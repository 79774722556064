/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ReferralCode interface.
 */
export function instanceOfReferralCode(value) {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "usedBy" in value;
    return isInstance;
}
export function ReferralCodeFromJSON(json) {
    return ReferralCodeFromJSONTyped(json, false);
}
export function ReferralCodeFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        userId: json.userId,
        code: json.code,
        usedBy: json.usedBy,
    };
}
export function ReferralCodeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        userId: value.userId,
        code: value.code,
        usedBy: value.usedBy,
    };
}

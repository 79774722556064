/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HabitActivityFromJSON, HabitActivityToJSON, } from "./HabitActivity";
import { HabitAddictionFromJSON, HabitAddictionToJSON, } from "./HabitAddiction";
import { HabitFoodFromJSON, HabitFoodToJSON, } from "./HabitFood";
/**
 * Check if a given object implements the UserHabits interface.
 */
export function instanceOfUserHabits(value) {
    let isInstance = true;
    isInstance = isInstance && "activityLevel" in value;
    isInstance = isInstance && "drinkingWater" in value;
    isInstance = isInstance && "drinkingAlcohol" in value;
    isInstance = isInstance && "smoking" in value;
    isInstance = isInstance && "eating" in value;
    return isInstance;
}
export function UserHabitsFromJSON(json) {
    return UserHabitsFromJSONTyped(json, false);
}
export function UserHabitsFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        activityLevel: HabitActivityFromJSON(json.activityLevel),
        drinkingWater: HabitFoodFromJSON(json.drinkingWater),
        drinkingAlcohol: HabitAddictionFromJSON(json.drinkingAlcohol),
        smoking: HabitAddictionFromJSON(json.smoking),
        eating: HabitFoodFromJSON(json.eating),
    };
}
export function UserHabitsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        activityLevel: HabitActivityToJSON(value.activityLevel),
        drinkingWater: HabitFoodToJSON(value.drinkingWater),
        drinkingAlcohol: HabitAddictionToJSON(value.drinkingAlcohol),
        smoking: HabitAddictionToJSON(value.smoking),
        eating: HabitFoodToJSON(value.eating),
    };
}

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityInstanceFromJSON, ActivityInstanceToJSON, } from "./ActivityInstance";
import { OutputMuscleGroupFromJSON, OutputMuscleGroupToJSON, } from "./OutputMuscleGroup";
/**
 * Check if a given object implements the ActivityInstancesResponse interface.
 */
export function instanceOfActivityInstancesResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "muscleGroups" in value;
    isInstance = isInstance && "activities" in value;
    return isInstance;
}
export function ActivityInstancesResponseFromJSON(json) {
    return ActivityInstancesResponseFromJSONTyped(json, false);
}
export function ActivityInstancesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        muscleGroups: json.muscleGroups.map(OutputMuscleGroupFromJSON),
        activities: json.activities.map(ActivityInstanceFromJSON),
    };
}
export function ActivityInstancesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        muscleGroups: value.muscleGroups.map(OutputMuscleGroupToJSON),
        activities: value.activities.map((a) => ActivityInstanceToJSON(a)),
    };
}

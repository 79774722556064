import { pipe } from "fp-ts/es6/function"
import * as Ord from "fp-ts/es6/Ord"
import { Newtype, prism } from "newtype-ts"
import {
  Dimension,
  numberAsValidNumber,
  UnitNoDim,
  unsafeFromSome,
  validNumber,
  valueInUnit,
  ValueWithUnit,
  valueWithUnit,
} from "@fitnesspilot/data-common"

const validNumberAsPercent = valueInUnit(Dimension.noDim, UnitNoDim.percent)
const numberAsPercent = numberAsValidNumber.composeIso(validNumberAsPercent)
const percent = valueWithUnit(Dimension.noDim, UnitNoDim.percent, validNumber)

export type Workload = Newtype<
  { readonly Workload: unique symbol },
  ValueWithUnit<Dimension.noDim, UnitNoDim.percent>
>
export const workload: Ord.Ord<Workload> = percent as any
export const percentAsWorkload = prism<Workload>(
  Ord.between(percent)(
    pipe(0, numberAsPercent.getOption, unsafeFromSome),
    pipe(100, numberAsPercent.getOption, unsafeFromSome),
  ),
)
export const validNumberAsWorkload =
  validNumberAsPercent.composePrism(percentAsWorkload)
export const numberAsWorkload = numberAsPercent.composePrism(percentAsWorkload)

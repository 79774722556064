import * as Opt from "fp-ts/es6/Option"
import { ParentState as CommonParentState } from "@fitnesspilot/data-common"

import { KnownStepSet, StepState } from "../StepSet"

export const stateKey = "help"

export type State = {
  step: Opt.Option<StepState>
  showHints: boolean
  showIndex: boolean
  status: Opt.Option<Partial<Record<KnownStepSet, Date>>>
}
export const initialState: State = {
  step: Opt.none,
  showHints: false,
  showIndex: false,
  status: Opt.none,
}

export type ParentState = CommonParentState & { [k in typeof stateKey]: State }

import { FormattedMessage } from "react-intl"

import * as Opt from "fp-ts/es6/Option"

import { KnownStepSet, StepSet } from "@fitnesspilot/data-help"

export const calendar: StepSet = {
  id: KnownStepSet.calendar,
  title: <FormattedMessage defaultMessage="Your Personal Calendar" />,
  steps: [
    {
      target: '[data-help-mode="mainNav"] [data-help-mode="calendarLink"]',
      continueOnClick: true,
      title: <FormattedMessage defaultMessage="Calendar" />,
      content: (
        <>
          <p>
            <FormattedMessage defaultMessage="The calendar gives day, month, and week overviews for events, and allows creating new events." />
          </p>

          <p>
            <FormattedMessage defaultMessage="Now please click here to continue to the calendar." />
          </p>
        </>
      ),
      showNext: false,
    },
    {
      target: '[data-help-mode="calendar"]',
      availableOn: ["/calendar"],
      alignment: "top",
      title: <FormattedMessage defaultMessage="Pick a Date to add an Event" />,
      content: (
        <FormattedMessage defaultMessage="You can select the day and time directly in the calendar to add a new sports event." />
      ),
    },
    {
      target: '[data-help-mode="addEvent"]',
      availableOn: ["/calendar"],
      title: <FormattedMessage defaultMessage="Add Event via Menu" />,
      content: (
        <FormattedMessage defaultMessage="You can also add an event via the menu." />
      ),
      showNext: false,
      continueOnClick: true,
    },
    {
      target: '[data-help-mode="createEvent"] [data-help-mode="date"]',
      availableOn: ["/calendar/add-event"],
      title: <FormattedMessage defaultMessage="Set the Time" />,
      content: (
        <FormattedMessage defaultMessage="Select the date for your event." />
      ),
    },
    {
      target: '[data-help-mode="createEvent"] [data-help-mode="time"]',
      availableOn: ["/calendar/add-event"],
      title: <FormattedMessage defaultMessage="Set the Time" />,
      content: (
        <FormattedMessage defaultMessage="Select the start and end time for your event." />
      ),
    },
    {
      target: '[data-help-mode="createEvent"] [data-help-mode="recurrence"]',
      availableOn: ["/calendar/add-event"],
      title: <FormattedMessage defaultMessage="Choose the Recurrence" />,
      content: (
        <FormattedMessage defaultMessage="You may choose to have your event repeat in specified intervals; for example, it might repeat weekly, and end on a specified date." />
      ),
    },
    {
      target: '[data-help-mode="createEvent"] [data-help-mode="next"]',
      availableOn: ["/calendar/add-event"],
      title: <FormattedMessage defaultMessage="Add Event Data" />,
      content: (
        <FormattedMessage defaultMessage="Go to the next event details step to actually specify which activities are within this event." />
      ),
      continueOnClick: true,
    },
    {
      target: '[data-help-mode="eventDetails"] [data-help-mode="name"]',
      availableOn: false,
      title: <FormattedMessage defaultMessage="Set Event Name" />,
      content: (
        <FormattedMessage defaultMessage="You may choose a name for your event; if you leave this blank, then we'll automatically choose one for you, based on the activities." />
      ),
    },
    {
      target:
        '[data-help-mode="eventDetails"] [data-help-mode="eventAlignment"]',
      availableOn: false,
      title: <FormattedMessage defaultMessage="Event Alignment" />,
      content: (
        <FormattedMessage defaultMessage="This is the alignment of the entire event, which shows how well it fits your body status and goals at the time." />
      ),
    },
    {
      target: '[data-help-mode="eventDetails"] [data-help-mode="time"]',
      availableOn: false,
      title: <FormattedMessage defaultMessage="Set Event Time" />,
      content: (
        <FormattedMessage defaultMessage="If you want, click here to change the time and recurrence of your event." />
      ),
    },
    {
      target:
        '[data-help-mode="eventDetails"] [data-help-mode="getRecommendations"]',
      availableOn: false,
      title: <FormattedMessage defaultMessage="Add Recommendations" />,
      content: (
        <FormattedMessage defaultMessage="If you want we'll recommend you activities in accordance with your goals, status, and settings; or you may continue to the next step to add your activities manually." />
      ),
    },
    {
      target:
        '[data-help-mode="eventDetails"] [data-help-mode="addActivities"]',
      availableOn: false,
      title: <FormattedMessage defaultMessage="Add Activities" />,
      content: (
        <FormattedMessage defaultMessage="Click here to manually add activities, or go back to the previous step to get recommendations." />
      ),
      showNext: false,
      continueOnClick: true,
    },
    {
      target: '[data-help-mode="selectActivities"] [data-help-mode="filter"]',
      availableOn: false,
      title: <FormattedMessage defaultMessage="Filter Activities" />,
      content: (
        <FormattedMessage defaultMessage="Here you may filter the available activities by name, equipment, muscle group, and whether you've liked them." />
      ),
    },
    {
      target: '[data-help-mode="selectActivities"] [data-help-mode="sort"]',
      availableOn: false,
      title: <FormattedMessage defaultMessage="Sort Activities" />,
      content: (
        <FormattedMessage defaultMessage="Here you may sort activities either by the default sorting (@TODO explain default or rename it), alphabet, muscle group, or alignment (how well the activity fits your goals and body status)." />
      ),
    },
    {
      target:
        '[data-help-mode="selectActivities"] [data-help-mode="activityInstance"]:nth-child(1) [data-help-mode="alignment"]',
      availableOn: false,
      title: <FormattedMessage defaultMessage="Alignment" />,
      content: (
        <FormattedMessage defaultMessage="The alignment is a percentage value showing how well an activity fits to your current muscle recovery and personal goals." />
      ),
    },
    {
      target:
        '[data-help-mode="selectActivities"] [data-help-mode="activityInstance"]:nth-child(1) [data-help-mode="bodyMap"]',
      availableOn: false,
      title: <FormattedMessage defaultMessage="Activity Body Map" />,
      content: (
        <FormattedMessage defaultMessage="The activity body map shows how much workload the activity has on specific muscles; hover over it to see it in colour, ranging from dark blue (high workload) through light blue (low workload) and grey (effectively no workload). The workload shows how much the activity tires the muscles and how well they are targeted." />
      ),
    },
    {
      target:
        '[data-help-mode="selectActivities"] [data-help-mode="activityInstance"]:nth-child(1) [data-help-mode="likeAndDenylist"]',
      availableOn: false,
      title: <FormattedMessage defaultMessage="Like" />,
      content: (
        <FormattedMessage defaultMessage="Liking an activity will cause it to be sorted first and appear in the 'liked' filter. Denylisting it will hide it completely from the activity selection, but it can be undone in the sports section." />
      ),
    },
    {
      target:
        '[data-help-mode="selectActivities"] [data-help-mode="activityInstance"]:nth-child(1) [data-help-mode="select"]',
      continueOnClick: true,
      availableOn: false,
      title: <FormattedMessage defaultMessage="Selecting an Activity" />,
      content: (
        <FormattedMessage defaultMessage="Click 'select' to select an activity; this allows you to set activity-specific properties like sets, intensity, distance, time, etc." />
      ),
      showNext: false,
    },
    {
      target:
        '[data-help-mode="selectActivities"] [data-help-mode="activityInstance"]:nth-child(1) [data-help-mode="addToExerciseSelection"]',
      continueOnClick: true,
      availableOn: false,
      title: <FormattedMessage defaultMessage="Add to Selection" />,
      content: (
        <FormattedMessage defaultMessage="Add an activity to your Exercise Selection once you're done setting its properties." />
      ),
      showNext: false,
    },
    {
      target:
        '[data-help-mode="selectActivities"] [data-help-mode="goToExerciseSelection"]',
      availableOn: false,
      title: <FormattedMessage defaultMessage="Go to Exercise Selection" />,
      content: (
        <FormattedMessage defaultMessage="View your selected activities." />
      ),
      showNext: false,
    },
    {
      target:
        '[data-help-mode="selectActivities"] [data-help-mode="saveOrGoBack"]',
      availableOn: false,
      title: <FormattedMessage defaultMessage="Save or Go Back" />,
      content: (
        <FormattedMessage defaultMessage="You can now save your selected activities or go back and select more." />
      ),
      showNext: false,
    },
    {
      target: '[data-help-mode="eventDetails"] [data-help-mode="save"]',
      continueOnClick: true,
      availableOn: false,
      title: <FormattedMessage defaultMessage="Create the Event" />,
      content: (
        <FormattedMessage defaultMessage="Click the 'create' button to create your event." />
      ),
      showNext: false,
    },
  ],
  startOn: Opt.none,
  href: "/calendar",
}

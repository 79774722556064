import { flow, pipe } from "fp-ts/es6/function"
import * as Func from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import { Getter, Prism } from "monocle-ts"
import {
  dateAsTime,
  Day,
  dayOfTime,
  dayOfZonedTime,
  localDuration,
  mkLocalDuration,
  Time,
  time,
  timeAsLocalTime,
  zonedDuration,
  zonedStartOfDayInCurrentTimeZone,
} from "time-ts/es6"
import { TimeZone } from "time-ts/es6/TimeZone"

import { runIO, unsafeFromSome } from "./type"

import * as dateFns from "date-fns"
import { Duration } from "duration-fns"

/** @deprecated */
export type DayDuration = Record<keyof Omit<Duration, "days">, 0> &
  Record<keyof Pick<Duration, "days">, number>
/** @deprecated */
export const dayDuration = (v: Pick<DayDuration, "days">): DayDuration => ({
  years: 0,
  months: 0,
  weeks: 0,
  ...v,
  hours: 0,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
})
/** @deprecated */
export const durationAsDayDuration = new Prism<Duration, DayDuration>(
  Opt.fromPredicate((v: Duration): v is DayDuration =>
    [
      v.years,
      v.months,
      v.weeks,
      v.hours,
      v.minutes,
      v.seconds,
      v.milliseconds,
    ].every((v) => v === 0),
  ),
  Func.identity,
)
/** @deprecated */
export const extractDayDuration = new Getter<Duration, DayDuration>(dayDuration)

const unsafeDateAsDay = flow(
  dateAsTime.getOption,
  unsafeFromSome,
  dayOfTime.get,
)
export const dayAsDate = (d: Day) => new Date(d.toString())
export const week = flow(dayAsDate, dateFns.getWeek)
export const startOfWeek = flow(dayAsDate, dateFns.startOfWeek, unsafeDateAsDay)
export const endOfWeek = flow(dayAsDate, dateFns.endOfWeek, unsafeDateAsDay)

export const addDays = (days: number) =>
  flow(
    zonedStartOfDayInCurrentTimeZone,
    // @TODO
    runIO,
    (t) => zonedDuration.add(t, mkLocalDuration({ days })),
    dayOfZonedTime.get,
  )

export const normaliseInterval =
  (timeZone: TimeZone) =>
  ([start, end]: readonly [Time, Time]): readonly [Time, Time] =>
    Ord.leq(time)(end, start)
      ? ([
          start,
          pipe(
            end,
            timeAsLocalTime(timeZone).modify((s) =>
              localDuration.add(s, mkLocalDuration({ days: 1 })),
            ),
          ),
        ] as const)
      : ([start, end] as const)

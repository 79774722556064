import { FC } from "react"
import styled from "@emotion/styled"

import { MuscleGroupId } from "@fitnesspilot/data-human-body/dist/muscleGroups"

import def from "./imgs/default.jpg"
import ventralThorax from "./imgs/ventralThorax.jpg"

const imgs = {
  [MuscleGroupId.abdomen]: def,
  [MuscleGroupId.bicepsBrachii]: def,
  [MuscleGroupId.cardiovascular]: def,
  [MuscleGroupId.dorsalThorax]: def,
  [MuscleGroupId.forearmAndHand]: def,
  [MuscleGroupId.hipAndEverythingInferiorToIt]: def,
  [MuscleGroupId.shoulder]: def,
  [MuscleGroupId.tricepsBrachii]: def,
  [MuscleGroupId.ventralThorax]: ventralThorax,
}

const Img = styled.img`
  border: 1px solid hsla(0, 0%, 46.7%, 0.25);
  border-radius: 3px;
`

export type MuscleGroupImageProps = {
  value: MuscleGroupId
  className?: string
}

export const MuscleGroupImage: FC<MuscleGroupImageProps> = ({
  value,
  className,
}) => <Img src={imgs[value]} {...{ className }} />

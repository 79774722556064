import { ReactNode } from "react"
import { Col, Row } from "reactstrap"
import styled from "@emotion/styled"

const TitleCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: end;
`

export type FieldsetHeaderProps = {
  title: ReactNode
  titleRight?: ReactNode
  description: ReactNode
}

export const FieldsetHeader = ({
  title,
  titleRight,
  description,
}: FieldsetHeaderProps) => (
  <>
    <Row>
      <TitleCol>
        <h2>{title}</h2>
        <div>{titleRight}</div>
      </TitleCol>
    </Row>
    <Row>
      <Col xs={10}>
        <p>{description}</p>
      </Col>
    </Row>
  </>
)

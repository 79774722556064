/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from "../runtime";
import { MusclePreferencesFromJSON, MusclePreferencesToJSON, } from "./MusclePreferences";
/**
 * Check if a given object implements the OutputMuscleGroup interface.
 */
export function instanceOfOutputMuscleGroup(value) {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    return isInstance;
}
export function OutputMuscleGroupFromJSON(json) {
    return OutputMuscleGroupFromJSONTyped(json, false);
}
export function OutputMuscleGroupFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? undefined : json.id,
        title: json.title,
        description: json.description,
        enabled: !exists(json, "enabled") ? undefined : json.enabled,
        alignment: !exists(json, "alignment") ? undefined : json.alignment,
        importanceFactor: !exists(json, "importanceFactor")
            ? undefined
            : json.importanceFactor,
        musclePreferences: !exists(json, "musclePreferences")
            ? undefined
            : json.musclePreferences === null
                ? null
                : json.musclePreferences.map(MusclePreferencesFromJSON),
    };
}
export function OutputMuscleGroupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        title: value.title,
        description: value.description,
        enabled: value.enabled,
        alignment: value.alignment,
        importanceFactor: value.importanceFactor,
        musclePreferences: value.musclePreferences === undefined
            ? undefined
            : value.musclePreferences === null
                ? null
                : value.musclePreferences.map(MusclePreferencesToJSON),
    };
}

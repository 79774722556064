import * as Eq from "fp-ts/es6/Eq"
import * as Opt from "fp-ts/es6/Option"
import { Prism } from "monocle-ts"

import { ActivityId } from "../activity/ActivityId"
import { ActivityType } from "../activity/ActivityType"
import { WithId } from "../activity/WithId"
import {
  ActivitySettingsExercise,
  activitySettingsExercise,
} from "./ActivitySettingsExercise"
import { ActivitySettingsJob, activitySettingsJob } from "./ActivitySettingsJob"
import {
  ActivitySettingsMuscles,
  activitySettingsMuscles,
} from "./ActivitySettingsMuscles"
import {
  ActivitySettingsSleep,
  activitySettingsSleep,
} from "./ActivitySettingsSleep"

export type ActivitySettings =
  | {
      $type: ActivityType.exercise
      settings: ActivitySettingsExercise
    }
  | {
      $type: ActivityType.job
      settings: ActivitySettingsJob
    }
  | {
      $type: ActivityType.muscles
      settings: ActivitySettingsMuscles
    }
  | {
      $type: ActivityType.sleep
      settings: ActivitySettingsSleep
    }
export const activitySettings = Eq.fromEquals<ActivitySettings>((a, b) =>
  a.$type === ActivityType.exercise
    ? b.$type === ActivityType.exercise
      ? activitySettingsExercise.equals(a.settings, b.settings)
      : false
    : a.$type === ActivityType.job
    ? b.$type === ActivityType.job
      ? activitySettingsJob.equals(a.settings, b.settings)
      : false
    : a.$type === ActivityType.muscles
    ? b.$type === ActivityType.muscles
      ? activitySettingsMuscles.equals(a.settings, b.settings)
      : false
    : a.$type === ActivityType.sleep
    ? b.$type === ActivityType.sleep
      ? activitySettingsSleep.equals(a.settings, b.settings)
      : false
    : (false as never),
)
export const foldActivitySettings =
  <a>(
    exercise: (s: ActivitySettingsExercise) => a,
    job: (s: ActivitySettingsJob) => a,
    muscles: (s: ActivitySettingsMuscles) => a,
    sleep: (s: ActivitySettingsSleep) => a,
  ) =>
  (v: ActivitySettings) =>
    v.$type === ActivityType.exercise
      ? exercise(v.settings)
      : v.$type === ActivityType.job
      ? job(v.settings)
      : v.$type === ActivityType.muscles
      ? muscles(v.settings)
      : sleep(v.settings)

export const _ActivitySettingsExercise = new Prism<
  ActivitySettings,
  ActivitySettingsExercise
>(
  (s) => (s.$type === ActivityType.exercise ? Opt.some(s.settings) : Opt.none),
  (settings) => ({ $type: ActivityType.exercise, settings }),
)
export const _ActivitySettingsJob = new Prism<
  ActivitySettings,
  ActivitySettingsJob
>(
  (s) => (s.$type === ActivityType.job ? Opt.some(s.settings) : Opt.none),
  (settings) => ({ $type: ActivityType.job, settings }),
)
export const _ActivitySettingsMuscles = new Prism<
  ActivitySettings,
  ActivitySettingsMuscles
>(
  (s) => (s.$type === ActivityType.muscles ? Opt.some(s.settings) : Opt.none),
  (settings) => ({ $type: ActivityType.muscles, settings }),
)
export const _ActivitySettingsSleep = new Prism<
  ActivitySettings,
  ActivitySettingsSleep
>(
  (s) => (s.$type === ActivityType.sleep ? Opt.some(s.settings) : Opt.none),
  (settings) => ({ $type: ActivityType.sleep, settings }),
)

export type ActivitySettingsWithId = WithId<ActivityId, ActivitySettings>

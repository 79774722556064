import * as Eq from "fp-ts/es6/Eq"
import * as Func from "fp-ts/es6/function"
import * as Ord from "fp-ts/es6/Ord"
import * as Str from "fp-ts/es6/string"
import { Newtype, prism } from "newtype-ts"
import { match } from "ts-pattern"

import { VideoSource } from "./VideoSource"

export type YoutubeVideoId = Newtype<
  { readonly EventId: unique symbol },
  string
>
export const stringAsYoutubeVideoId = prism<YoutubeVideoId>(Func.constTrue)
export const youtubeVideoId: Ord.Ord<YoutubeVideoId> = Str.Ord as any

export type VimeoVideoId = Newtype<{ readonly EventId: unique symbol }, string>
export const stringAsVimeoVideoId = prism<VimeoVideoId>(Func.constTrue)
export const vimeoVideoId: Ord.Ord<VimeoVideoId> = Str.Ord as any

export type VideoId = YoutubeVideoId | VimeoVideoId
export const videoId: Ord.Ord<VideoId> = Str.Ord as any

import { FC, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { FaUser } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import { connect, ConnectedProps } from "react-redux"

import * as Bool from "fp-ts/es6/boolean"
import * as Eq from "fp-ts/es6/Eq"
import * as Func from "fp-ts/es6/function"
import { flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Str from "fp-ts/es6/string"
import {
  lengthDistance,
  lengthHeight,
  mass,
  optionLensToOptional,
  UnitLengthDistance,
  UnitLengthHeight,
  UnitMass,
  unsafeFromSome,
} from "@fitnesspilot/data-common"

import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import { CancelButton } from "@fitnesspilot/components-common/dist/molecules/CancelButton/CancelButton"
import { Content } from "@fitnesspilot/components-common/dist/molecules/Content/Content"
import { Header } from "@fitnesspilot/components-common/dist/molecules/Header/Header"
import { SaveButton } from "@fitnesspilot/components-common/dist/molecules/SaveButton/SaveButton"
import {
  FormData as UnitFormData,
  UnitFieldset,
} from "@fitnesspilot/components-common/dist/organisms/UnitFieldset/UnitFieldset"
import { useFormChangeEffect } from "@fitnesspilot/components-common/dist/react"
import { AccountDeletionFieldset } from "@fitnesspilot/components-user/dist/molecules/AccountDeletionFieldset/AccountDeletionFieldset"
import {
  FormData as GeneralAccountInformationFormData,
  GeneralAccountInformationFieldset,
} from "@fitnesspilot/components-user/dist/molecules/GeneralAccountInformationFieldset/GeneralAccountInformationFieldset"
import { GoogleFitFieldset } from "@fitnesspilot/components-user/dist/molecules/GoogleFitFieldset/GoogleFitFieldset"
import { InviteFriendsFieldset } from "@fitnesspilot/components-user/dist/molecules/InviteFriendsFieldset/InviteFriendsFieldset"
import {
  FormData as PersonalInformationFormData,
  PersonalInformationFieldset,
} from "@fitnesspilot/components-user/dist/molecules/PersonalInformationFieldset/PersonalInformationFieldset"
import { ReferFriendsFieldset } from "@fitnesspilot/components-user/dist/molecules/ReferFriendsFieldset/ReferFriendsFieldset"
import {
  Action,
  addInviteCode,
  changePassword,
  deleteAccount,
  ParentState,
  selectors,
  setGoogleFit,
  setUnitSelection,
  setUser,
  share,
  showDeleteAccountConfirmation,
  showGoogleOAuthPopup,
} from "@fitnesspilot/data-user"
import * as GoogleApi from "@fitnesspilot/data-user/dist/GoogleApi"
import * as GoogleFit from "@fitnesspilot/data-user/dist/GoogleFit"
import * as User from "@fitnesspilot/data-user/dist/User"

import { MainTemplate } from "../../templates/Main/Main"

import { Dispatch } from "redux"

const userOpt = selectors.state.composeOptional(
  optionLensToOptional(selectors.user),
)
const unitSelection = selectors.state.composeLens(selectors.unitSelection)

type GeneralFormData = GeneralAccountInformationFormData

type InfoFormData = PersonalInformationFormData & UnitFormData
const infoFormData = Eq.struct<InfoFormData>({
  unitMass: Str.Eq as Eq.Eq<UnitMass>,
  givenName: Opt.getEq(Str.Eq),
  familyName: Opt.getEq(Str.Eq),
  alternateName: Opt.getEq(Str.Eq),
  unitLengthHeight: Str.Eq as Eq.Eq<UnitLengthHeight>,
  unitLengthDistance: Str.Eq as Eq.Eq<UnitLengthDistance>,
})

const mapState = (state: ParentState) => {
  const defaultValues: Partial<GeneralFormData & InfoFormData> = {
    email: pipe(
      state,
      (a) => userOpt.composeGetter(User._email.asGetter()).headOption(a),
      Opt.toUndefined,
    ),
    alternateName: pipe(
      state,
      userOpt.composeOptional(optionLensToOptional(User._name)).getOption,
    ),
    givenName: pipe(
      state,
      userOpt.composeOptional(optionLensToOptional(User._givenName)).getOption,
    ),
    familyName: pipe(
      state,
      userOpt.composeOptional(optionLensToOptional(User._familyName)).getOption,
    ),
    unitMass: pipe(state, unitSelection.composeLens(mass).get),
    unitLengthHeight: pipe(state, unitSelection.composeLens(lengthHeight).get),
    unitLengthDistance: pipe(
      state,
      unitSelection.composeLens(lengthDistance).get,
    ),
  }
  return {
    user: pipe(state, userOpt.getOption),
    userClaims: pipe(state, selectors.state.compose(selectors.userClaims).get),
    googleApi: pipe(state, userOpt.composeLens(User._googleApi).getOption),
    googleFitSettings: pipe(
      state,
      selectors.state.composeOptional(optionLensToOptional(selectors.googleFit))
        .getOption,
    ),
    referralCode: pipe(
      state,
      selectors.state.compose(selectors.referralCode).get,
    ),
    inviteCodes: pipe(
      state,
      selectors.state.compose(selectors.inviteCodes).get,
    ),
    isDeleteAccountConfirmationShown: pipe(
      state,
      selectors.state.compose(selectors.isDeleteAccountConfirmationShown).get,
    ),
    defaultValues,
  }
}

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ =
    (act: Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, Func.constVoid)

  return {
    onSubmitGeneral: ({
      user,
      form: { email, password },
    }: {
      user: User.User
      form: GeneralFormData
    }) =>
      pipe(
        Arr.Traversable.traverse(IO.Applicative)(
          [
            ...pipe(
              password,
              Opt.fromNullable,
              Opt.flatten,
              Opt.fold(
                () => [],
                (password) => [changePassword({ password })],
              ),
            ),
            setUser({ ...user, email }),
          ],
          dispatch_,
        ),
        IO.map(Func.constVoid),
      )(),
    onSubmitInfo: ({
      user,
      form: {
        alternateName,
        familyName,
        givenName,
        unitMass,
        unitLengthHeight,
        unitLengthDistance,
      },
    }: {
      user: User.User
      form: InfoFormData
    }) =>
      pipe(
        Arr.Traversable.traverse(IO.Applicative)(
          [
            setUser({
              ...user,
              person: {
                image: user.person.image,
                name: alternateName,
                givenName,
                familyName,
              },
            }),
            setUnitSelection({
              mass: unitMass,
              lengthHeight: unitLengthHeight,
              lengthDistance: unitLengthDistance,
            }),
          ],
          dispatch_,
        ),
        IO.map(Func.constVoid),
      )(),
    onGoogleApiConnect: flow(showGoogleOAuthPopup, dispatch_),
    onGoogleFitSave: flow(setGoogleFit, dispatch_),
    onShare: flow(share, dispatch_),
    onAddInviteCode: flow(addInviteCode, dispatch_),
    onShowDeleteAccountConfirmation: flow(
      showDeleteAccountConfirmation,
      dispatch_,
    ),
    onDeleteAccount: flow(deleteAccount, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type OwnProps = {
  id: string
}

export type MyAccountPageProps = PropsFromRedux & OwnProps

export const MyAccountPage: FC<MyAccountPageProps> = ({
  id,
  user,
  userClaims,
  googleApi,
  googleFitSettings,
  referralCode,
  inviteCodes,
  isDeleteAccountConfirmationShown,
  defaultValues,
  onShare,
  onAddInviteCode,
  onShowDeleteAccountConfirmation,
  onDeleteAccount,
  onSubmitGeneral,
  onSubmitInfo,
  onGoogleApiConnect,
  onGoogleFitSave,
}) => {
  const generalForm = useForm<GeneralAccountInformationFormData>({
    defaultValues,
  })

  const infoForm = useForm<InfoFormData>({
    defaultValues,
  })

  useEffect(() => {
    if (!generalForm.formState.isDirty) {
      generalForm.reset(defaultValues)
    }
    if (!infoForm.formState.isDirty) {
      infoForm.reset(defaultValues)
    }
  }, [defaultValues])

  const submitGeneral = generalForm.handleSubmit((form) =>
    onSubmitGeneral({ user: unsafeFromSome(user), form }),
  )

  const submitInfo = infoForm.handleSubmit((form) =>
    onSubmitInfo({ user: unsafeFromSome(user), form }),
  )
  useFormChangeEffect(infoForm, infoFormData, submitInfo)

  return (
    <MainTemplate
      header={
        <Header
          icon={<FaUser />}
          title={<FormattedMessage defaultMessage="My account" />}
        />
      }
    >
      <Content>
        <FormProvider {...generalForm}>
          <form onSubmit={submitGeneral}>
            <Fieldset>
              <GeneralAccountInformationFieldset
                id={`${id}-generalAccountInformation`}
              />
            </Fieldset>

            <SaveButton type="submit">
              <FormattedMessage defaultMessage="Save changes" />
            </SaveButton>
          </form>
        </FormProvider>

        <FormProvider {...infoForm}>
          <form onSubmit={submitInfo}>
            <Fieldset>
              <Fieldset>
                <PersonalInformationFieldset id={`${id}-personalInformation`} />
              </Fieldset>

              <Fieldset>
                <UnitFieldset id={`${id}-units`} />
              </Fieldset>

              <Fieldset>
                <GoogleFitFieldset
                  id={`${id}-googleFit`}
                  value={pipe(
                    googleApi,
                    Opt.chain((g) =>
                      pipe(
                        g.isEnabled &&
                          pipe(
                            GoogleApi.requiredScopes.googleFit,
                            Arr.reduce(
                              true,
                              (r, s) => r && Arr.elem(Str.Eq)(s)(g.scopes),
                            ),
                          ),
                        Bool.fold(
                          () => Opt.none,
                          () =>
                            pipe(
                              googleFitSettings,
                              Opt.getOrElse(() => GoogleFit.initialState),
                              Opt.some,
                            ),
                        ),
                      ),
                    ),
                  )}
                  onConnect={pipe(
                    GoogleApi.requiredScopes.googleFit,
                    onGoogleApiConnect,
                  )}
                  onSave={onGoogleFitSave}
                />
              </Fieldset>

              <Fieldset>
                <ReferFriendsFieldset
                  id={`${id}-referFriends`}
                  {...{ referralCode, onShare }}
                />
              </Fieldset>

              {pipe(
                userClaims,
                Opt.fold(
                  () => false,
                  (claims) => claims.admin,
                ),
                Bool.fold(
                  () => null,
                  () => (
                    <Fieldset>
                      <InviteFriendsFieldset
                        id={`${id}-inviteFriends`}
                        {...{ inviteCodes, onShare }}
                        onAddCode={onAddInviteCode()}
                      />
                    </Fieldset>
                  ),
                ),
              )}

              <Fieldset>
                <AccountDeletionFieldset
                  value={{
                    showConfirmation: isDeleteAccountConfirmationShown,
                  }}
                  onDelete={() =>
                    pipe(
                      isDeleteAccountConfirmationShown,
                      Bool.fold(
                        onShowDeleteAccountConfirmation(),
                        onDeleteAccount(),
                      ),
                    )
                  }
                />
              </Fieldset>
            </Fieldset>
          </form>
        </FormProvider>
      </Content>
    </MainTemplate>
  )
}

export const MyAccountPageContainer = connector(MyAccountPage)

import * as Ord from "fp-ts/es6/Ord"
import * as Str from "fp-ts/es6/string"

import { Cookie } from "@fitnesspilot/components-cookie-consent"

export enum CookieId {
  core = "core",
  sentry = "sentry",
  ga = "ga",
  gtm = "gtm",
}

export type FpCookie = Cookie<CookieId>

export const ordCookieId: Ord.Ord<CookieId> = Str.Ord

import * as Eq from "fp-ts/es6/Eq"
import { Lens } from "monocle-ts"
import { EmailAddress, emailAddress } from "@fitnesspilot/data-common"

export type UserAuth = {
  /** Is text on schema.org, but we have a more specific type.
   *
   * @see https://schema.org/email
   */
  email: EmailAddress
}
export const userAuth = Eq.struct<UserAuth>({
  email: emailAddress,
})
export const _email = Lens.fromProp<UserAuth>()("email")

import { FC, HTMLAttributes, useEffect } from "react"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { Table } from "reactstrap"
import styled from "@emotion/styled"

import * as Apply from "fp-ts/es6/Apply"
import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"
import { Prism } from "monocle-ts"
import {
  currentTimeZone,
  dateAsTime,
  isoStringAsLocalTimeOfDay,
  localTimeFromDayAndLocalTimeOfDay,
  localTimeOfDayOfLocalTime,
  timeAsLocalTime,
  today,
} from "time-ts/es6"
import {
  timeAsLocalTimeOfDay,
  timeAsLocalTimeOfDayInCurrentEnv,
} from "time-ts/es6/Local/TimeOfDay"
import {
  OmitFromKnownKeys,
  prismToGetter,
  unsafeFromSome,
} from "@fitnesspilot/data-common"

import { DayOfWeekName } from "@fitnesspilot/components-common/dist/atoms/DayOfWeekName/DayOfWeekName"
import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import { CommonModal } from "@fitnesspilot/components-common/dist/organisms/CommonModal/CommonModal"
import {
  ControllerPrism,
  textRender,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"
import * as DayOfWeek from "@fitnesspilot/data-common/dist/DayOfWeek"

import {
  DaysToEdit,
  TimeBatchModifyFieldset,
  TimeBatchModifyFieldsetProps,
} from "../TimeBatchModifyFieldset/TimeBatchModifyFieldset"

export const daysToEditToDaysOfWeek = (dte: DaysToEdit) =>
  ({
    [DaysToEdit.allDays]: Object.values(DayOfWeek.DayOfWeek),
    [DaysToEdit.weekdays]: [
      DayOfWeek.DayOfWeek.monday,
      DayOfWeek.DayOfWeek.tuesday,
      DayOfWeek.DayOfWeek.wednesday,
      DayOfWeek.DayOfWeek.thursday,
      DayOfWeek.DayOfWeek.friday,
    ],
    [DaysToEdit.weekend]: [
      DayOfWeek.DayOfWeek.saturday,
      DayOfWeek.DayOfWeek.sunday,
    ],
  })[dte]

const IntervalSeparator = styled(
  ({ className, ...props }: HTMLAttributes<HTMLSpanElement>) => (
    <span className={`c-intervalSeparator ${className ?? ""}`} {...props} />
  ),
)``

const Interval = styled.div`
  display: flex;
  align-items: center;

  .c-intervalSeparator {
    margin: 0 5px;
  }
`

const SleepTimeBatchModifyFieldset = (
  props: OmitFromKnownKeys<TimeBatchModifyFieldsetProps, "onSubmit">,
) => {
  const { setValue } = useFormContext<FormData>()

  return (
    <TimeBatchModifyFieldset
      onSubmit={({ daysToEdit, start, end }) =>
        pipe(
          daysToEdit,
          daysToEditToDaysOfWeek,
          Arr.traverse(IO.io)((dow) =>
            pipe(
              Apply.sequenceT(IO.io)(
                () => setValue(`time.${dow}.0` as any, start),
                () => setValue(`time.${dow}.1` as any, end),
              ),
            ),
          ),
        )()
      }
      {...props}
    />
  )
}

const stringAsLocalTimeOfDayDate = new Prism<string, Date>(
  (a) =>
    isoStringAsLocalTimeOfDay
      .composeGetter(prismToGetter(timeAsLocalTimeOfDayInCurrentEnv()))
      .composeGetter(prismToGetter(dateAsTime))
      .headOption(a),
  (d) =>
    pipe(
      d,
      (a) =>
        dateAsTime
          .composePrism(timeAsLocalTimeOfDayInCurrentEnv())
          .composeGetter(prismToGetter(isoStringAsLocalTimeOfDay))
          .headOption(a),
      unsafeFromSome,
    ),
)

export type FormData = {
  time: Rec.ReadonlyRecord<DayOfWeek.DayOfWeek, [Date, Date]>
}

export type SleepTimeModalProps = {
  id: string
  className?: string
  isOpen: boolean
  onSetIsOpen: (isOpen: boolean) => IO.IO<void>
  defaultValues: FormData
  onSubmit: (v: FormData) => void
}

export const SleepTimeModal: FC<SleepTimeModalProps> = ({
  id,
  isOpen,
  onSetIsOpen,
  defaultValues,
  onSubmit,
  ...props
}) => {
  const intl = useIntl()
  const { handleSubmit, ...form } = useForm<FormData>({
    defaultValues,
  })

  useEffect(() => {
    if (!form.formState.isDirty) {
      form.reset(defaultValues)
    }
  }, [defaultValues])

  return (
    <FormProvider {...{ handleSubmit }} {...form}>
      <CommonModal
        onToggle={(open) => onSetIsOpen(open)()}
        onSave={Opt.some(handleSubmit((v) => onSubmit(v)))}
        title={<FormattedMessage defaultMessage="Sleep hours" />}
        size="xl"
        {...{ isOpen }}
        {...props}
      >
        <p>
          <FormattedMessage defaultMessage="Rest is important. Improve your physical and mental progress and boost your abilities by giving yourself the recovery you need. Setting it here will make us also consider your sleep in recovery calculations and recommendations." />
        </p>

        <Fieldset>
          <SleepTimeBatchModifyFieldset
            id={`${id}-sleepTimeBatchModify`}
            defaultValues={{ daysToEdit: DaysToEdit.allDays }}
          />
        </Fieldset>

        <Fieldset>
          <Table>
            <tbody>
              {pipe(
                DayOfWeek.DayOfWeek,
                Rec.toReadonlyArray,
                Arr.map(([k, v]) => v),
                Arr.sort(DayOfWeek.dayOfWeek),
                Arr.map((dow) => (
                  <tr key={dow}>
                    <th scope="row">
                      <label htmlFor={`${id}-${dow}Start`}>
                        <DayOfWeekName id={dow} />
                      </label>
                    </th>

                    <td>
                      <Interval>
                        <ControllerPrism<
                          FormData,
                          `time.${DayOfWeek.DayOfWeek.monday}.0`
                        >
                          render={textRender({
                            id: `${id}-${dow}Start`,
                            type: "time",
                            required: true,
                            step: 60,
                          })}
                          name={
                            `time.${dow}.0` as any as `time.${DayOfWeek.DayOfWeek.monday}.0`
                          }
                          prism={
                            // This _should_ work without `as any`, but the expected type is somehow Option<never>
                            stringAsLocalTimeOfDayDate as any
                          }
                          prismError={intl.formatMessage({
                            defaultMessage: "is invalid",
                          })}
                          rules={{
                            required: true,
                          }}
                        />
                        <IntervalSeparator>–</IntervalSeparator>
                        <ControllerPrism<
                          FormData,
                          `time.${DayOfWeek.DayOfWeek.monday}.1`
                        >
                          render={textRender({
                            id: `${id}-${dow}End`,
                            type: "time",
                            required: true,
                            step: 60,
                          })}
                          name={
                            `time.${dow}.1` as any as `time.${DayOfWeek.DayOfWeek.monday}.1`
                          }
                          prism={stringAsLocalTimeOfDayDate as any}
                          prismError={intl.formatMessage({
                            defaultMessage: "is invalid",
                          })}
                          rules={{
                            required: true,
                          }}
                        />
                      </Interval>
                    </td>
                  </tr>
                )),
              )}
            </tbody>
          </Table>
        </Fieldset>
      </CommonModal>
    </FormProvider>
  )
}

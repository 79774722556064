/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from "../runtime";
/**
 * Check if a given object implements the UserSleepSchedule interface.
 */
export function instanceOfUserSleepSchedule(value) {
    const isInstance = true;
    return isInstance;
}
export function UserSleepScheduleFromJSON(json) {
    return UserSleepScheduleFromJSONTyped(json, false);
}
export function UserSleepScheduleFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        none: !exists(json, "none") ? undefined : json.none,
        monday: !exists(json, "monday") ? undefined : json.monday,
        tuesday: !exists(json, "tuesday") ? undefined : json.tuesday,
        wednesday: !exists(json, "wednesday") ? undefined : json.wednesday,
        thursday: !exists(json, "thursday") ? undefined : json.thursday,
        friday: !exists(json, "friday") ? undefined : json.friday,
        saturday: !exists(json, "saturday") ? undefined : json.saturday,
        sunday: !exists(json, "sunday") ? undefined : json.sunday,
    };
}
export function UserSleepScheduleToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        none: value.none,
        monday: value.monday,
        tuesday: value.tuesday,
        wednesday: value.wednesday,
        thursday: value.thursday,
        friday: value.friday,
        saturday: value.saturday,
        sunday: value.sunday,
    };
}

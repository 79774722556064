/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GeneralSportsPreferences interface.
 */
export function instanceOfGeneralSportsPreferences(value) {
    let isInstance = true;
    isInstance = isInstance && "enabled" in value;
    isInstance = isInstance && "maxSportsDays" in value;
    isInstance = isInstance && "maxCardioDays" in value;
    return isInstance;
}
export function GeneralSportsPreferencesFromJSON(json) {
    return GeneralSportsPreferencesFromJSONTyped(json, false);
}
export function GeneralSportsPreferencesFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        enabled: json.enabled,
        maxSportsDays: json.maxSportsDays,
        maxCardioDays: json.maxCardioDays,
    };
}
export function GeneralSportsPreferencesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        enabled: value.enabled,
        maxSportsDays: value.maxSportsDays,
        maxCardioDays: value.maxCardioDays,
    };
}

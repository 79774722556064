import { FC } from "react"
import styled from "@emotion/styled"

import {
  Clickable,
  Clickable_,
  ClickableProps,
} from "../../atoms/Clickable/Clickable"

export type ButtonProps = ClickableProps

// TODO is missing theme prop
export const Button_ = Clickable_

export const Button = styled(({ className, ...props }: ClickableProps) => (
  <Clickable className={`c-cookieButton ${className ?? ""}`} {...props} />
))`
  font-size: ${({ theme }) => theme.fontSize};
  color: ${({ theme }) => theme.button.colour.toString()};
  background-color: ${({ theme }) => theme.button.backgroundColour.toString()};
  border: 0;
  border-radius: 2px;
  ${({ theme }) => theme.button.customCss}

  &:hover {
    background-color: ${({ theme }) =>
      theme.button.backgroundColour.darken(0.2).toString()};
  }
`

import { FC } from "react"
import { FaQuestion } from "react-icons/fa"
import { Button, ButtonProps } from "reactstrap"
import styled from "@emotion/styled"

import * as IO from "fp-ts/es6/IO"
import { OmitFromKnownKeys } from "@fitnesspilot/data-common"

const HelpButtonContainer = styled(Button)`
  display: block;
  position: relative;
  overflow: hidden;
  width: 48px;
  height: 48px;

  & > * {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);
  }

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`

export type HelpButtonProps = OmitFromKnownKeys<
  ButtonProps,
  "children" | "onClick"
> & {
  active: boolean
  onStart: IO.IO<void>
  onStop: IO.IO<void>
}

export const HelpButton: FC<HelpButtonProps> = ({
  active,
  onStart,
  onStop,
  ...props
}) => (
  <HelpButtonContainer
    type="button"
    color={active ? "warning" : "light"}
    {...props}
    onClick={() => (active ? onStop() : onStart())}
  >
    <FaQuestion />
  </HelpButtonContainer>
)

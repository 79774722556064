/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from "../runtime";
import { CoachTaskActionFromJSON, CoachTaskActionToJSON, } from "./CoachTaskAction";
import { ImportanceFromJSON, ImportanceToJSON, } from "./Importance";
/**
 * Check if a given object implements the CoachTask interface.
 */
export function instanceOfCoachTask(value) {
    let isInstance = true;
    isInstance = isInstance && "isTodayTask" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "refId" in value;
    isInstance = isInstance && "importance" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "action" in value;
    return isInstance;
}
export function CoachTaskFromJSON(json) {
    return CoachTaskFromJSONTyped(json, false);
}
export function CoachTaskFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        isTodayTask: json.isTodayTask,
        type: json.type,
        refId: json.refId,
        importance: ImportanceFromJSON(json.importance),
        description: json.description,
        action: CoachTaskActionFromJSON(json.action),
        id: !exists(json, "id") ? undefined : json.id,
        dismissed: !exists(json, "dismissed")
            ? undefined
            : json.dismissed === null
                ? null
                : new Date(json.dismissed),
        completed: !exists(json, "completed")
            ? undefined
            : json.completed === null
                ? null
                : new Date(json.completed),
    };
}
export function CoachTaskToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        isTodayTask: value.isTodayTask,
        type: value.type,
        refId: value.refId,
        importance: ImportanceToJSON(value.importance),
        description: value.description,
        action: CoachTaskActionToJSON(value.action),
        id: value.id,
        dismissed: value.dismissed === undefined
            ? undefined
            : value.dismissed === null
                ? null
                : value.dismissed.toISOString(),
        completed: value.completed === undefined
            ? undefined
            : value.completed === null
                ? null
                : value.completed.toISOString(),
    };
}

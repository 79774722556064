// @generated by protoc-gen-es v1.3.0 with parameter "target=ts"
// @generated from file activity/v1/activity_settings.proto (package activity.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message activity.v1.ActivitySettings
 */
export class ActivitySettings extends Message<ActivitySettings> {
  /**
   * @generated from field: bool is_liked = 1;
   */
  isLiked = false;

  /**
   * @generated from field: bool is_denylisted = 2;
   */
  isDenylisted = false;

  constructor(data?: PartialMessage<ActivitySettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "activity.v1.ActivitySettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_liked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "is_denylisted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivitySettings {
    return new ActivitySettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivitySettings {
    return new ActivitySettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActivitySettings {
    return new ActivitySettings().fromJsonString(jsonString, options);
  }

  static equals(a: ActivitySettings | PlainMessage<ActivitySettings> | undefined, b: ActivitySettings | PlainMessage<ActivitySettings> | undefined): boolean {
    return proto3.util.equals(ActivitySettings, a, b);
  }
}


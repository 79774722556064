import { FC } from "react"
import { IntlShape, useIntl } from "react-intl"

import { pipe } from "fp-ts/es6/function"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Str from "fp-ts/es6/string"
import { match, P } from "ts-pattern"
import { humanList } from "@fitnesspilot/data-common"

import { muscleGroupName } from "@fitnesspilot/components-human-body/dist/atoms/MuscleGroupName/MuscleGroupName"
import { ActivityType } from "@fitnesspilot/data-activity/dist/activity/ActivityType"
import {
  ActivityInstance,
  flattenActivityInstances,
} from "@fitnesspilot/data-activity/dist/activityInstance/ActivityInstance"

import { activityName } from "../ActivityName/ActivityName"

export type ActivityInstanceNameProps = {
  activity: ActivityInstance
  /** limit of activity names to list */
  limit?: number
}

/** Returns name for `ActivityInstance` by using `activityName` on underlying `Activity`. */
export const activityInstanceName: (
  intl: IntlShape,
) => (props: ActivityInstanceNameProps) => string =
  (intl: IntlShape) =>
  ({ activity, limit }: ActivityInstanceNameProps) =>
    match(activity)
      .with({ $type: ActivityType.muscles }, ({ value }) =>
        pipe(
          value.muscleGroupIds,
          Arr.map((mg) => muscleGroupName(intl)({ id: mg })),
          humanList(limit),
        ),
      )
      .with({ $type: P.not(ActivityType.group) }, ({ value: { activity } }) =>
        activityName(intl)({ id: activity.id, def: activity.value.title }),
      )
      .with({ $type: ActivityType.group }, ({ value: { activities } }) =>
        pipe(
          activities,
          flattenActivityInstances,
          Arr.map((activity) => activityInstanceName(intl)({ activity })),
          humanList(limit),
        ),
      )
      .exhaustive()

export const ActivityInstanceName: FC<ActivityInstanceNameProps> = (props) => (
  <>{activityInstanceName(useIntl())(props)}</>
)

import { FC, useCallback } from "react"
import { connect, ConnectedProps } from "react-redux"

import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"

import { Dorsoventral } from "@fitnesspilot/data-human-body/dist/anatomicalAxes"
import * as HumanBody from "@fitnesspilot/data-human-body/dist/humanBody"
import { MuscleId_ } from "@fitnesspilot/data-human-body/dist/Muscle"
import { Sex } from "@fitnesspilot/data-human-body/dist/sex"
import { ParentState, selectors } from "@fitnesspilot/data-user/dist/store"

import { Mode, MuscleShape } from "../../atoms/MuscleShape/MuscleShape"
import { Body, BodyProps } from "../../molecules/Body/Body"

const mapState = (state: ParentState) => ({
  sex: selectors.state
    .composeLens(selectors.body)
    .composeLens(HumanBody.sex)
    .get(state),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type Side<a extends Dorsoventral> = {
  dorsoventralSide: a
  muscleLevels: Record<MuscleId_, number | false | undefined>
  invertLevels?: boolean
  onMuscleClick: (v: MuscleId_) => void
  toggleMusclePopover: (v: MuscleId_, force: boolean) => void
  openMusclePopover?: MuscleId_
}

type MuscleProps = {
  dorsoventralSide: Dorsoventral
  muscle: MuscleId_
  mode?: Mode
  level: number | false | undefined
  invertLevel?: boolean
  popoverIsOpen: boolean
  onToggleMusclePopover: (muscleGroup: MuscleId_, force: boolean) => void
}

const Muscle = ({
  dorsoventralSide,
  muscle,
  mode,
  level,
  invertLevel,
  popoverIsOpen,
  onToggleMusclePopover,
}: MuscleProps) => {
  const togglePopover = useCallback(
    () => onToggleMusclePopover(muscle, false),
    [muscle, onToggleMusclePopover],
  )

  return (
    <MuscleShape
      {...{
        dorsoventralSide,
        muscle,
        mode,
        level,
        invertLevel,
        popoverIsOpen,
        togglePopover,
      }}
    />
  )
}

export type BodyMapAltProps = PropsFromRedux & {
  mode?: Mode
} & Omit<BodyProps, "sex" | "dorsoventralSide"> &
  (Side<Dorsoventral.dorsal> | Side<Dorsoventral.ventral>)

export const BodyMapAlt: FC<BodyMapAltProps> = ({
  sex,
  mode,
  dorsoventralSide,
  muscleLevels,
  invertLevels,
  onMuscleClick,
  toggleMusclePopover,
  openMusclePopover,
  dispatch,
  ...props
}) => (
  <Body
    alt
    sex={pipe(
      sex,
      Opt.getOrElse<Sex>(() => Sex.male),
    )}
    {...{ dorsoventralSide }}
    {...props}
  >
    {pipe(
      Object.values(MuscleId_),
      Arr.map((muscle) => (
        <a
          key={muscle}
          onMouseEnter={() => toggleMusclePopover(muscle, true)}
          onMouseLeave={() => toggleMusclePopover(muscle, false)}
          onClick={() => onMuscleClick(muscle)}
        >
          <Muscle
            level={
              (
                muscleLevels as Record<
                  typeof muscle,
                  number | false | undefined
                >
              )[muscle]
            }
            invertLevel={invertLevels}
            popoverIsOpen={openMusclePopover === muscle}
            onToggleMusclePopover={toggleMusclePopover}
            {...{ dorsoventralSide, muscle, mode }}
          />
        </a>
      )),
    )}
  </Body>
)

export const BodyMapAltContainer = connector(BodyMapAlt)

import * as A from "fp-ts/es6/Array"
import * as D from "fp-ts/es6/Date"
import * as E from "fp-ts/es6/Either"
import { flow, pipe } from "fp-ts/es6/function"
import * as F from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as T from "fp-ts/es6/Task"
import * as TE from "fp-ts/es6/TaskEither"
import { Index, Optional } from "monocle-ts"
import { asum } from "@fitnesspilot/data-common"

import * as exif from "exifreader"

const exifIndex = new Index<
  ReturnType<typeof exif.load>,
  keyof ReturnType<typeof exif.load>,
  ReturnType<typeof exif.load>[keyof ReturnType<typeof exif.load>]["value"][0]
>((i) => Optional.fromPath<ReturnType<typeof exif.load>>()([i, "value", 0]))

export const flip =
  <as extends Array<any>, bs extends Array<any>, c>(
    f: (...as: as) => (...bs: bs) => c,
  ) =>
  (...bs: bs) =>
  (...as: as) =>
    f(...as)(...bs)
export const fromExifStr = (v: string) => {
  const [d, t] = v.split(" ")
  return new Date(`${d.replace(/:/g, "-")}T${t}Z`)
}
export const dateFromExif = (v: ReturnType<typeof exif.load>) =>
  pipe(
    ["DateTime", "DateTimeOriginal", "DateTimeDigitized"],
    A.map((k) => (v2: typeof v) => exifIndex.index(k).getOption(v2)),
    A.ap(pipe(v, A.of)),
    asum(A.array, Opt.option),
    Opt.map(fromExifStr),
  )
export const getExifDate = (v: ArrayBuffer) =>
  E.tryCatch<Error, Opt.Option<Date>>(
    () => dateFromExif(exif.load(v)),
    F.identity as (e: unknown) => Error,
  )
export const fileToArrayBuffer = (f: File) =>
  TE.tryCatch<Error, ArrayBuffer>(
    () => (f as any).arrayBuffer(),
    F.identity as (e: unknown) => Error,
  )

export const fileToPhotoUpload = (file: File) =>
  flow(
    fileToArrayBuffer,
    TE.chain(flow(getExifDate, T.of)),
    T.chain(
      flow(
        Opt.fromEither,
        Opt.flatten,
        Opt.fold(() => T.fromIO(D.create), T.of),
        // workaround for format missmatch in openapi-generator client
        T.map((d) => d.toISOString() as any),
      ),
    ),
    T.map((dateCreated: Date) => ({ file, dateCreated })),
  )(file)

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from "../runtime";
import { UnitDistanceFromJSON, UnitDistanceToJSON, } from "./UnitDistance";
import { UnitHeightFromJSON, UnitHeightToJSON, } from "./UnitHeight";
import { UnitMassFromJSON, UnitMassToJSON, } from "./UnitMass";
/**
 * Check if a given object implements the UnitsOverride interface.
 */
export function instanceOfUnitsOverride(value) {
    const isInstance = true;
    return isInstance;
}
export function UnitsOverrideFromJSON(json) {
    return UnitsOverrideFromJSONTyped(json, false);
}
export function UnitsOverrideFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        distance: !exists(json, "distance")
            ? undefined
            : UnitDistanceFromJSON(json.distance),
        height: !exists(json, "height")
            ? undefined
            : UnitHeightFromJSON(json.height),
        mass: !exists(json, "mass") ? undefined : UnitMassFromJSON(json.mass),
    };
}
export function UnitsOverrideToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        distance: UnitDistanceToJSON(value.distance),
        height: UnitHeightToJSON(value.height),
        mass: UnitMassToJSON(value.mass),
    };
}

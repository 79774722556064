import { FC } from "react"
import { IntlShape, useIntl } from "react-intl"

import { DayOfWeek } from "@fitnesspilot/data-common/dist/DayOfWeek"

import { formatDynamicMessage } from "../IntlProvider/IntlProvider"

const names: Record<DayOfWeekLength, Record<DayOfWeek, string>> = {
  ["long"]: {
    [DayOfWeek.monday]: "Monday",
    [DayOfWeek.tuesday]: "Tuesdays",
    [DayOfWeek.wednesday]: "Wednesday",
    [DayOfWeek.thursday]: "Thursday",
    [DayOfWeek.friday]: "Friday",
    [DayOfWeek.saturday]: "Saturday",
    [DayOfWeek.sunday]: "Sunday",
  },
  ["short"]: {
    [DayOfWeek.monday]: "Mon",
    [DayOfWeek.tuesday]: "Tue",
    [DayOfWeek.wednesday]: "Wed",
    [DayOfWeek.thursday]: "Thu",
    [DayOfWeek.friday]: "Fri",
    [DayOfWeek.saturday]: "Sat",
    [DayOfWeek.sunday]: "Sun",
  },
}

export type DayOfWeekLength = "long" | "short"

export type DayOfWeekNameProps = {
  id: DayOfWeek
  length?: DayOfWeekLength
}

export const dayOfWeekName =
  (intl: IntlShape) =>
  ({ id, length = "long" }: DayOfWeekNameProps) =>
    formatDynamicMessage(intl)({
      id: `DayOfWeekName.${id}`,
      defaultMessage: names[length][id],
    })

export const DayOfWeekName: FC<DayOfWeekNameProps> = (props) => (
  <>{dayOfWeekName(useIntl())(props)}</>
)

import { FC } from "react"
import styled from "@emotion/styled"

import { Clickable, ClickableProps } from "../../atoms/Clickable/Clickable"

export type ToggleButtonProps = {
  type?: "checkbox" | "radio"
  name?: string
  isSelected: boolean
} & (
  | {
      titleSelected: string
      titleUnselected: string
    }
  | {
      titleSelected?: undefined
      titleUnselected?: undefined
    }
) &
  Omit<ClickableProps, "role" | "aria-checked" | "aria-label" | "title">

const BaseToggleButton = ({
  isSelected,
  titleSelected,
  titleUnselected,
  type,
  ...props
}: ToggleButtonProps) => {
  const title = isSelected ? titleSelected : titleUnselected

  return (
    <Clickable
      role={type}
      aria-checked={isSelected}
      aria-label={title}
      {...{ title }}
      {...props}
    />
  )
}

export const ToggleButton = styled(BaseToggleButton)`
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.colours.primary.toString() : "inherit"};
`

import * as Ord from "fp-ts/es6/Ord"
import * as Ordering from "fp-ts/es6/Ordering"
import { Lens } from "monocle-ts"

import {
  Alignment,
  alignment,
} from "@fitnesspilot/data-human-body/dist/Alignment"
import { Workload, workload } from "@fitnesspilot/data-human-body/dist/Workload"

export type ActivityMuscleInfo = {
  workload: Workload
  alignment: Alignment
}
export const activityMuscleInfo = Ord.fromCompare<ActivityMuscleInfo>((a, b) =>
  Ordering.Monoid.concat(
    workload.compare(a.workload, b.workload),
    alignment.compare(a.alignment, b.alignment),
  ),
)
export const _workload = Lens.fromProp<ActivityMuscleInfo>()("workload")
export const _alignment = Lens.fromProp<ActivityMuscleInfo>()("alignment")

/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EventFromJSON, EventResponseFromJSON, EventToJSON, } from "../models";
import * as runtime from "../runtime";
/**
 *
 */
export class EventsApi extends runtime.BaseAPI {
    /**
     * Returns events for given range.
     * Handles GET requests for Calendar.Controllers.Calendars.EventsController.
     */
    async calendarApiUsersUserIdCalendarEventsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling calendarApiUsersUserIdCalendarEventsGet.");
        }
        const queryParameters = {};
        if (requestParameters.start !== undefined) {
            queryParameters.start = requestParameters.start.toISOString();
        }
        if (requestParameters.end !== undefined) {
            queryParameters.end = requestParameters.end.toISOString();
        }
        if (requestParameters.includeTimeslots !== undefined) {
            queryParameters.includeTimeslots = requestParameters.includeTimeslots;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/calendar/api/users/{userId}/calendar/events`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => EventResponseFromJSON(jsonValue));
    }
    /**
     * Returns events for given range.
     * Handles GET requests for Calendar.Controllers.Calendars.EventsController.
     */
    async calendarApiUsersUserIdCalendarEventsGet(requestParameters, initOverrides) {
        const response = await this.calendarApiUsersUserIdCalendarEventsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Confirm an event as being done according to specification.
     */
    async calendarApiUsersUserIdCalendarEventsIdConfirmPutRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling calendarApiUsersUserIdCalendarEventsIdConfirmPut.");
        }
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling calendarApiUsersUserIdCalendarEventsIdConfirmPut.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/calendar/api/users/{userId}/calendar/events/{id}/confirm`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Confirm an event as being done according to specification.
     */
    async calendarApiUsersUserIdCalendarEventsIdConfirmPut(requestParameters, initOverrides) {
        await this.calendarApiUsersUserIdCalendarEventsIdConfirmPutRaw(requestParameters, initOverrides);
    }
    /**
     * Handles DELETE requests for Calendar.Controllers.Calendars.EventsController.
     */
    async calendarApiUsersUserIdCalendarEventsIdDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling calendarApiUsersUserIdCalendarEventsIdDelete.");
        }
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling calendarApiUsersUserIdCalendarEventsIdDelete.");
        }
        const queryParameters = {};
        if (requestParameters.change !== undefined) {
            queryParameters.change = requestParameters.change;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/calendar/api/users/{userId}/calendar/events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Handles DELETE requests for Calendar.Controllers.Calendars.EventsController.
     */
    async calendarApiUsersUserIdCalendarEventsIdDelete(requestParameters, initOverrides) {
        await this.calendarApiUsersUserIdCalendarEventsIdDeleteRaw(requestParameters, initOverrides);
    }
    /**
     * Handles GET {id} requests for Calendar.Controllers.Calendars.EventsController.
     */
    async calendarApiUsersUserIdCalendarEventsIdGetRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling calendarApiUsersUserIdCalendarEventsIdGet.");
        }
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling calendarApiUsersUserIdCalendarEventsIdGet.");
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/calendar/api/users/{userId}/calendar/events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => EventFromJSON(jsonValue));
    }
    /**
     * Handles GET {id} requests for Calendar.Controllers.Calendars.EventsController.
     */
    async calendarApiUsersUserIdCalendarEventsIdGet(requestParameters, initOverrides) {
        const response = await this.calendarApiUsersUserIdCalendarEventsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Handles PUT requests for Calendar.Controllers.Calendars.EventsController.
     */
    async calendarApiUsersUserIdCalendarEventsIdPutRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError("id", "Required parameter requestParameters.id was null or undefined when calling calendarApiUsersUserIdCalendarEventsIdPut.");
        }
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling calendarApiUsersUserIdCalendarEventsIdPut.");
        }
        const queryParameters = {};
        if (requestParameters.change !== undefined) {
            queryParameters.change = requestParameters.change;
        }
        const headerParameters = {};
        headerParameters["Content-Type"] = "application/json-patch+json";
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/calendar/api/users/{userId}/calendar/events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
            body: EventToJSON(requestParameters.event),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => EventFromJSON(jsonValue));
    }
    /**
     * Handles PUT requests for Calendar.Controllers.Calendars.EventsController.
     */
    async calendarApiUsersUserIdCalendarEventsIdPut(requestParameters, initOverrides) {
        const response = await this.calendarApiUsersUserIdCalendarEventsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Handles POST requests for Calendar.Controllers.Calendars.EventsController.
     */
    async calendarApiUsersUserIdCalendarEventsPostRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null ||
            requestParameters.userId === undefined) {
            throw new runtime.RequiredError("userId", "Required parameter requestParameters.userId was null or undefined when calling calendarApiUsersUserIdCalendarEventsPost.");
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters["Content-Type"] = "application/json-patch+json";
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/calendar/api/users/{userId}/calendar/events`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: EventToJSON(requestParameters.event),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => EventFromJSON(jsonValue));
    }
    /**
     * Handles POST requests for Calendar.Controllers.Calendars.EventsController.
     */
    async calendarApiUsersUserIdCalendarEventsPost(requestParameters, initOverrides) {
        const response = await this.calendarApiUsersUserIdCalendarEventsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}

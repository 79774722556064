/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UnitHeightFromJSON, UnitHeightToJSON, } from "./UnitHeight";
/**
 * Check if a given object implements the Option1UnitHeight interface.
 */
export function instanceOfOption1UnitHeight(value) {
    let isInstance = true;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    return isInstance;
}
export function Option1UnitHeightFromJSON(json) {
    return Option1UnitHeightFromJSONTyped(json, false);
}
export function Option1UnitHeightFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        value: UnitHeightFromJSON(json.value),
        name: json.name,
        description: json.description,
    };
}
export function Option1UnitHeightToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        value: UnitHeightToJSON(value.value),
        name: value.name,
        description: value.description,
    };
}

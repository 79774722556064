import { flow, pipe } from "fp-ts/es6/function";
import * as IO from "fp-ts/es6/IO";
import * as Opt from "fp-ts/es6/Option";
import { Iso, Lens, Prism } from "monocle-ts";
import { ioTsPrism } from "../internal";
import { day, timeFromDayAndTimeOfDay, timeOfDayOfTime, today, } from "../UTC";
import { currentTimeZone } from "./Local";
import { dayOfLocalTime, timeAsLocalTime } from "./Time";
import { Temporal } from "@js-temporal/polyfill";
import * as t from "io-ts";
export const localTimeOfDay = {
    equals: (a, b) => a.equals(b),
    compare: (a, b) => Temporal.PlainTime.compare(a, b),
};
export const equals = (d2) => (d) => localTimeOfDay.equals(d, d2);
export const compare = (d2) => (d) => localTimeOfDay.compare(d, d2);
export const localTimeOfDayOfLocalTime = new Lens((s) => s.toZonedDateTime("UTC").toPlainTime(), (a) => (s) => s.toZonedDateTime("UTC").withPlainTime(a).toPlainDateTime());
export const currentLocalTimeOfDay = (timeZone) => () => Temporal.Now.plainTimeISO(timeZone);
export const mkLocalTimeOfDayWrapped = (hour, minute, second) => Temporal.PlainTime.from({
    hour,
    minute,
    microsecond: second * 1000000,
});
export const mkLocalTimeOfDay = (hour, minute, second) => Opt.tryCatch(() => Temporal.PlainTime.from({
    hour,
    minute,
    microsecond: second * 1000000,
}, { overflow: "reject" }));
const regex = /^(\d{2}):(\d{2})(?::(\d{2}(?:\.(\d+))?))?$/;
export const isoStringAsLocalTimeOfDay = new Prism(flow(Opt.fromPredicate((s) => regex.test(s)), Opt.chain((s) => Opt.tryCatch(() => Temporal.PlainTime.from(s)))), (s) => s.toString());
export const localTimeOfDayT = t.string.pipe(ioTsPrism("LocalTimeOfDay", (a) => a instanceof Temporal.PlainTime, isoStringAsLocalTimeOfDay));
export const localTimeFromDayAndLocalTimeOfDay = (d, tod) => d.toZonedDateTime({ timeZone: "UTC", plainTime: tod }).toPlainDateTime();
export const localTimeAsDayAndLocalTimeOfDayPair = new Iso((t) => [dayOfLocalTime.get(t), localTimeOfDayOfLocalTime.get(t)], ([d, tod]) => localTimeFromDayAndLocalTimeOfDay(d, tod));
const zero = mkLocalTimeOfDayWrapped(0, 0, 0);
export const localStartOfDay = (d) => localTimeFromDayAndLocalTimeOfDay(d, zero);
export const localStartOfToday = pipe(today, IO.map(localStartOfDay));
export const timeAsLocalTimeOfDay = (d, timeZone) => new Prism((utc) => pipe(utc, timeAsLocalTime(timeZone).get, Opt.fromPredicate((local) => day.equals(dayOfLocalTime.get(local), d)), Opt.map(localTimeOfDayOfLocalTime.get)), (local) => pipe(localTimeFromDayAndLocalTimeOfDay(d, local), timeAsLocalTime(timeZone).reverseGet));
export const timeAsLocalTimeOfDayInCurrentEnv = () => timeAsLocalTimeOfDay(today(), currentTimeZone());
export const timeOfDayAsLocalTimeOfDay = (d, timeZone) => new Iso((utc) => pipe(timeFromDayAndTimeOfDay(d, utc), timeAsLocalTime(timeZone).composeLens(localTimeOfDayOfLocalTime).get), (local) => pipe(localTimeFromDayAndLocalTimeOfDay(d, local), timeAsLocalTime(timeZone).reverse().composeLens(timeOfDayOfTime).get));
export const timeOfDayAsLocalTimeOfDayInCurrentEnv = () => timeOfDayAsLocalTimeOfDay(today(), currentTimeZone());

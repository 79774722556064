import { FC } from "react"
import { IntlShape, useIntl } from "react-intl"

import { formatDynamicMessage } from "@fitnesspilot/components-common/dist/atoms/IntlProvider/IntlProvider"
import { ActivityTag } from "@fitnesspilot/data-activity/dist/activity/ActivityTag"

const names = {
  [ActivityTag.bodybuilding]: "Bodybuilding",
  [ActivityTag.calisthenics]: "Calisthenics",
  [ActivityTag.cardio]: "Cardio",
  [ActivityTag.endurance]: "Endurance",
  [ActivityTag.isometric]: "Isometric",
  [ActivityTag.isotonic]: "Isotonic",
  [ActivityTag.other]: "Other",
  [ActivityTag.sport]: "Sport",
  [ActivityTag.strength]: "Strength",
  [ActivityTag.strengthEndurance]: "Strength-Endurance",
  [ActivityTag.yoga]: "Yoga",
  [ActivityTag.bodyshaping]: "Bodyshaping",
  [ActivityTag.plyometric]: "Plyometric",
  [ActivityTag.stretching]: "Stretching",
  [ActivityTag.balance]: "Balance",
  [ActivityTag.stability]: "Stability",
  [ActivityTag.mobility]: "Mobility",
  [ActivityTag.fascia]: "Fascia",
  [ActivityTag.powerlifting]: "Powerlifting",
  [ActivityTag.strongman]: "Strongman",
  [ActivityTag.weightlifting]: "Weightlifting",
  [ActivityTag.pushUpVariations]: "Push-up variations",
  [ActivityTag.core]: "Core",
  [ActivityTag.fullBody]: "Full body",
  [ActivityTag.beginner]: "Beginner",
  [ActivityTag.intermediate]: "Intermediate",
  [ActivityTag.advanced]: "Advanced",
}

export type ActivityTagNameProps = {
  id: ActivityTag
}

export const activityTagName =
  (intl: IntlShape) =>
  ({ id }: ActivityTagNameProps) =>
    formatDynamicMessage(intl)({
      id: `ActivityTagName.${id}`,
      defaultMessage: names[id],
    })

export const ActivityTagName: FC<ActivityTagNameProps> = (props) => (
  <>{activityTagName(useIntl())(props)}</>
)

import * as Bool from "fp-ts/es6/boolean"
import { flow, pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import * as T from "fp-ts/es6/Task"
import * as TE from "fp-ts/es6/TaskEither"
import * as O from "fp-ts-reactive/es6/Observable"
import { Getter } from "monocle-ts"
import { dateAsTime, now } from "time-ts/es6"
import {
  arrayToObservable,
  arrTrav,
  unsafeFromSome,
  withLatestFrom,
} from "@fitnesspilot/data-common"

import * as API from "@fitnesspilot/api-combined"
import api, {
  Action as APIAction,
  ParentState as APIParentState,
  setAccountReady,
  setToken,
} from "@fitnesspilot/data-api"
import * as EventData from "@fitnesspilot/data-event"
import * as Event from "@fitnesspilot/data-event/dist/calendar/Event"
import { fetchEventById } from "@fitnesspilot/data-event/dist/store/actions"
import * as UserData from "@fitnesspilot/data-user"

import * as CoachTask from "../CoachTask"
import { CalendarCoachTaskType, UserCoachTaskType } from "../CoachTaskType"
import {
  Action,
  confirmCoachTaskAsync,
  dismissCoachTaskAsync,
  dismissCoachTasksAsync,
  fetchCoachTasksAsync,
  resetState,
  setEventConfirmationIsOpen,
} from "./actions"
import * as selectors from "./selectors"
import { ParentState } from "./state"

import { combineEpics, Epic } from "redux-observable"
import { isActionOf } from "typesafe-actions"

type ParentAPIState = ParentState & APIParentState

type EpicWithoutAPI = Epic<
  Action | UserData.Action | EventData.Action | APIAction,
  Action | UserData.Action | EventData.Action,
  ParentAPIState
>

const generalise =
  <a>() =>
  <b extends a>(v: b): a =>
    v

export const setTokenFlow: EpicWithoutAPI = (
  action$,
  // state$,
) =>
  pipe(
    action$,
    O.filter(isActionOf(setToken)),
    O.map((a) => a.payload),
    O.map(
      Opt.fold(
        () => [resetState()],
        () => [],
      ),
    ),
    O.chain(arrayToObservable),
  )

export const setAccountReadyFlow: EpicWithoutAPI = (
  action$,
  // state$,
) =>
  pipe(
    action$,
    O.filter(isActionOf(setAccountReady)),
    O.map((a) => a.payload),
    O.map(
      Bool.fold(
        () => [],
        () => [fetchCoachTasksAsync.request()],
      ),
    ),
    O.chain(arrayToObservable),
  )

const apiCoachTaskImportance = new Getter<
  API.Importance,
  CoachTask.CoachTaskImportance
>(
  (a) =>
    ({
      [API.Importance.Info]: CoachTask.CoachTaskImportance.info,
      [API.Importance.Optional]: CoachTask.CoachTaskImportance.optional,
      [API.Importance.Recommended]: CoachTask.CoachTaskImportance.recommended,
      [API.Importance.Important]: CoachTask.CoachTaskImportance.important,
    })[a],
)

const toCoachTask = ({
  id,
  completed,
  dismissed,
  type,
  refId,
  importance,
}: API.CoachTask): CoachTask.CoachTask => ({
  id: pipe(
    id,
    Opt.fromNullable,
    Opt.chain(CoachTask.stringAsCoachTaskId.getOption),
    unsafeFromSome,
  ),
  importance: apiCoachTaskImportance.get(importance),
  completedOn: pipe(
    completed,
    Opt.fromNullable,
    Opt.chain(dateAsTime.getOption),
  ),
  dismissedOn: pipe(
    dismissed,
    Opt.fromNullable,
    Opt.chain(dateAsTime.getOption),
  ),
  ...(type === "eventConfirmation"
    ? {
        type: CalendarCoachTaskType.eventConfirmation,
        eventId: pipe(
          refId,
          Opt.fromNullable,
          Opt.chain(Event.stringAsEventId.getOption),
          unsafeFromSome,
        ),
      }
    : {
        type: (
          {
            userMass: UserCoachTaskType.mass,
            userSex: UserCoachTaskType.sex,
            userHeight: UserCoachTaskType.height,
            userBodyType: UserCoachTaskType.bodyType,
            userAge: UserCoachTaskType.age,
          } as any
        )[type],
      }),
})

export const fetchCoachTasksFlow: EpicWithoutAPI = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(fetchCoachTasksAsync.request)),
    withLatestFrom(O.map(api)(state$)),
    O.chain(([, api]) =>
      pipe(
        api.coachTask.getCoachTasks("me"),
        TE.fold(
          flow(fetchCoachTasksAsync.failure, generalise<Action>(), T.of),
          flow(Arr.map(toCoachTask), fetchCoachTasksAsync.success, T.of),
        ),
        O.fromTask,
      ),
    ),
  )

export const dismissCoachTaskFlow: EpicWithoutAPI = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(dismissCoachTaskAsync.request)),
    withLatestFrom(O.map(api)(state$)),
    O.chain(([action, api]) =>
      pipe(
        api.coachTask.dismissCoachTask(
          CoachTask.stringAsCoachTaskId.reverseGet(action.payload.id),
        )("me"),
        TE.fold(
          flow(dismissCoachTaskAsync.failure, generalise<Action>(), T.of),
          () =>
            pipe(
              { ...action.payload, dismissedOn: Opt.some(now()) },
              dismissCoachTaskAsync.success,
              T.of,
            ),
        ),
        O.fromTask,
      ),
    ),
  )

export const dismissCoachTasksFlow: EpicWithoutAPI = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(dismissCoachTasksAsync.request)),
    O.chain(({ payload }) =>
      pipe(
        payload,
        NonEmpty.map(flow(dismissCoachTaskAsync.request, generalise<Action>())),
        arrayToObservable,
      ),
    ),
  )

export const confirmCoachTaskFlow: EpicWithoutAPI = (action$, state$) =>
  pipe(
    action$,
    O.filter(isActionOf(confirmCoachTaskAsync.request)),
    withLatestFrom(O.map(api)(state$)),
    O.chain(([action, api]) =>
      pipe(
        api.coachTask.confirmCoachTask(
          CoachTask.stringAsCoachTaskId.reverseGet(action.payload.id),
        )("me"),
        TE.fold(
          flow(confirmCoachTaskAsync.failure, generalise<Action>(), T.of),
          () =>
            pipe(
              { ...action.payload, completedOn: Opt.some(now()) },
              confirmCoachTaskAsync.success,
              T.of,
            ),
        ),
        O.fromTask,
      ),
    ),
  )

export const setEventConfirmationIsOpenFlow: EpicWithoutAPI = (
  action$,
  state$,
) =>
  pipe(
    action$,
    O.filter(isActionOf(setEventConfirmationIsOpen)),
    withLatestFrom(state$),
    O.chain(([, state]) =>
      pipe(
        state,
        selectors.state
          .composeLens(selectors.coachTasks)
          .composeTraversal(arrTrav())
          .filter(flow(CoachTask._dismissedOn.get, Opt.isNone))
          .filter(flow(CoachTask._completedOn.get, Opt.isNone))
          .composePrism(CoachTask._CalendarCoachTask)
          .composeLens(CoachTask._calendarEventId)
          .composeGetter(new Getter((id) => fetchEventById({ id }))).getAll,
        arrayToObservable,
      ),
    ),
  )

export const confirmEventSuccessFlow: EpicWithoutAPI = (
  action$,
  // state$,
) =>
  pipe(
    action$,
    O.filterMap((act) =>
      pipe(
        act,
        Opt.fromPredicate(isActionOf(EventData.confirmEventAsync.success)),
        Opt.map((act) => act.payload),
        Opt.alt(() =>
          pipe(
            act,
            Opt.fromPredicate(
              isActionOf([
                EventData.deleteEventAsync.success,
                EventData.saveEventAsync.success,
                EventData.deleteEditingEventAsync.success,
                EventData.saveEditingEventAsync.success,
              ]),
            ),
            Opt.map((act) => act.payload.event.id),
          ),
        ),
      ),
    ),
    O.map((id) =>
      // @TODO instead of fetching all coach tasks again
      // only mark the relevant coach task as completed
      fetchCoachTasksAsync.request(),
    ),
  )

export const setUserDataSuccessFlow: EpicWithoutAPI = (
  action$,
  // state$,
) =>
  pipe(
    action$,
    O.filter(isActionOf(UserData.setUserDataAsync.success)),
    O.map((a) => a.payload),
    O.chain(() => O.of(fetchCoachTasksAsync.request())),
  )

const epic: EpicWithoutAPI = combineEpics(
  setTokenFlow,
  setAccountReadyFlow,
  fetchCoachTasksFlow,
  dismissCoachTaskFlow,
  dismissCoachTasksFlow,
  confirmCoachTaskFlow,
  setEventConfirmationIsOpenFlow,
  confirmEventSuccessFlow,
  setUserDataSuccessFlow,
)
export default epic

import { FormattedMessage, useIntl } from "react-intl"

import * as Bool from "fp-ts/boolean"
import * as Eq from "fp-ts/Eq"

import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import {
  BoolString,
  boolTransform,
  ControllerPlus,
  ControllerPrism,
  formGroupRender,
  rangeRender,
  selectRender,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"
import {
  Intensity,
  intensity,
  numberAsIntensity,
} from "@fitnesspilot/data-human-body/dist/Intensity"

export type FormData = {
  intensity: Intensity
  fixedSchedule: boolean
}
export const formData = Eq.struct<FormData>({
  intensity: intensity,
  fixedSchedule: Bool.Eq,
})

export type SportPreferencesFieldsetsProps = {
  id: string
  className?: string
}

export const SportPreferencesFieldsets = ({
  id,
  className,
}: SportPreferencesFieldsetsProps) => {
  const intl = useIntl()

  const fixedScheduleTexts = {
    true: intl.formatMessage({ defaultMessage: "Available at any time" }),
    false: intl.formatMessage({ defaultMessage: "Restricted availability" }),
  }

  return (
    <>
      <Fieldset>
        <h1>
          <FormattedMessage defaultMessage="Intensity" />
        </h1>
        <FormattedMessage
          defaultMessage={`Please let us know how intense you perform this sport.<br></br>
0 % = I keep my effort and speed as low as I can<br></br>
25 % = Relaxed, casual<br></br>
50 % = normal/average<br></br>
75 % = I strongly engage and show a tough performance<br></br>
100 % = I am completely going to my limits, I couldn't do any more.`}
        />
        <ControllerPrism<FormData, "intensity", number>
          render={formGroupRender(rangeRender)({
            id: `${id}-intensity`,
            min: 0,
            max: 1,
            step: 0.1,
            label: <FormattedMessage defaultMessage="Intensity" />,
            format: (value) =>
              intl.formatMessage(
                {
                  defaultMessage: "{value, number, percent}",
                },
                { value },
              ),
          })}
          name="intensity"
          prism={numberAsIntensity}
          prismError={intl.formatMessage({
            defaultMessage: "is invalid",
          })}
          rules={{
            required: true,
          }}
        />
      </Fieldset>
      <Fieldset>
        <h1>
          <FormattedMessage defaultMessage="Availability" />
        </h1>
        <FormattedMessage defaultMessage="Is this sport only possible at certain sessions (courses or limited access)?" />
        <ControllerPlus<FormData, "fixedSchedule", BoolString>
          render={formGroupRender(selectRender<BoolString, "radio">())({
            id: `${id}-fixedSchedule`,
            type: "radio",
            label: <FormattedMessage defaultMessage="Fixed schedule" />,
            values: {
              true: fixedScheduleTexts.true,
              false: fixedScheduleTexts.false,
            },
          })}
          name="fixedSchedule"
          transform={boolTransform<FormData, "fixedSchedule">()}
        />
      </Fieldset>
    </>
  )
}

import { FC, ReactNode } from "react"
import { FaEllipsisH } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import { Link } from "react-router-dom"
import { Button } from "reactstrap"

import { constVoid, flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import { Index } from "monocle-ts"
import { prismToGetter } from "@fitnesspilot/data-common"

import { SaveButton } from "@fitnesspilot/components-common/dist/molecules/SaveButton/SaveButton"
import * as Event from "@fitnesspilot/data-event/dist/calendar/Event"
import * as EventsOrRecommendations from "@fitnesspilot/data-event/dist/calendar/EventsOrRecommendations"
import * as EventData from "@fitnesspilot/data-event/dist/store"

import { EventSummary } from "../../molecules/EventSummary/EventSummary"

import { Dispatch } from "redux"

const _eventsWithIdIndex = Index.fromAt(EventsOrRecommendations._eventsWithIdAt)

const mapState = (state: EventData.ParentState) => {
  return {
    eventsOrRecommendations: EventData.selectors.state
      .composeLens(EventData.selectors.eventsOrRecommendations)
      .get(state),
  }
}

const mapDispatch = (dispatch: Dispatch<EventData.Action>) => {
  const dispatch_ =
    (act: EventData.Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, constVoid)

  return {
    onConfirm: flow(EventData.confirmEvent, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type OwnProps = {
  eventId: Event.EventId
  children: ReactNode
}

export type EventConfirmationProps = PropsFromRedux & OwnProps

export const EventConfirmation: FC<EventConfirmationProps> = ({
  eventId,
  eventsOrRecommendations,
  onConfirm,
  children,
}) =>
  pipe(
    eventsOrRecommendations,
    _eventsWithIdIndex.index(eventId).getOption,
    Opt.fold(
      () => <FormattedMessage defaultMessage="Event could not be found" />,
      (event) => (
        <EventSummary
          showTime
          event={pipe(event, Event._eventWithId._value.get)}
        >
          <SaveButton
            type="button"
            onClick={pipe(event, Event._eventWithId._id.get, onConfirm)}
          >
            <FormattedMessage defaultMessage="Confirm" />
          </SaveButton>

          <Button
            tag={Link}
            to={`/calendar/events/${pipe(
              event,
              Event._eventWithId._id.composeGetter(
                prismToGetter(Event.stringAsEventId),
              ).get,
            )}`}
            color="link"
          >
            <FaEllipsisH /> <FormattedMessage defaultMessage="Edit" />
          </Button>

          {children}
        </EventSummary>
      ),
    ),
  )

export const EventConfirmationContainer = connector(EventConfirmation)

import { FC, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { FaArrowLeft, FaEnvelope } from "react-icons/fa"
import { FormattedMessage, useIntl } from "react-intl"
import { Link } from "react-router-dom"
import { Button, FormGroup } from "reactstrap"
import styled from "@emotion/styled"

import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import { stringAsEmailAddress } from "@fitnesspilot/data-common"

import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import {
  ControllerPrism,
  formGroupRender,
  textRender,
} from "@fitnesspilot/components-common/dist/organisms/Field/Controller"
import { UserAuth } from "@fitnesspilot/data-user/dist/UserAuth"

import type _firebase from "firebase/compat"

export type FormData = UserAuth

const StyledFormGroup = styled(FormGroup)`
  @media not (${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`

const Error = styled.div`
  color: ${({ theme }) => theme.colours.danger.toString()};
`

type PasswordResetErrorProps = {
  code: string
}

const PasswordResetError = ({ code }: PasswordResetErrorProps) => {
  switch (code) {
    case "auth/invalid-email":
      return <FormattedMessage defaultMessage="The email address is invalid." />
    case "auth/user-not-found":
      return (
        <FormattedMessage defaultMessage="No account found for the given email address." />
      )
    default:
      return <FormattedMessage defaultMessage="Password reset failed." />
  }
}

const isFirebaseAuthError = (e: Error): e is _firebase.FirebaseError =>
  "code" in e && ((e as any).code as string).startsWith("auth/")

export type PasswordResetFormProps = {
  id: string
  className?: string
  errors: ReadonlyArray<Error>
  onSubmit: (v: FormData) => void
}

export const PasswordResetForm: FC<PasswordResetFormProps> = ({
  id,
  onSubmit,
  className,
  errors,
}) => {
  const intl = useIntl()
  const { handleSubmit, ...form } = useForm<FormData>({})

  const passwordResetError = useMemo(
    () =>
      pipe(
        errors,
        Arr.filter(isFirebaseAuthError),
        Arr.last,
        Opt.map(({ code }) => (
          <Error key={code}>
            <PasswordResetError {...{ code }} />
          </Error>
        )),
        Opt.toNullable,
      ),
    [errors],
  )

  return (
    <FormProvider {...{ handleSubmit }} {...form}>
      <form onSubmit={handleSubmit(onSubmit)} {...{ className }}>
        <Fieldset>
          <FormGroup tag="header">
            <h1 className="h4">
              <FormattedMessage defaultMessage="Reset password" />
            </h1>
          </FormGroup>

          <ControllerPrism<FormData, "email">
            render={formGroupRender(textRender)({
              id: `${id}-email`,
              autoComplete: "email",
              type: "email",
              required: true,
              label: <FormattedMessage defaultMessage="Email address" />,
              fullWidth: true,
            })}
            name="email"
            prism={stringAsEmailAddress}
            prismError={intl.formatMessage({
              defaultMessage: "is invalid",
            })}
            rules={{
              required: true,
            }}
          />

          <StyledFormGroup>
            <Button tag={Link} to="/login" color="secondary">
              <FaArrowLeft />{" "}
              <FormattedMessage defaultMessage="Return to login" />
            </Button>

            <Button type="submit" color="success" className="float-right">
              <FaEnvelope />{" "}
              <FormattedMessage defaultMessage="Send reset instructions via email" />
            </Button>
          </StyledFormGroup>

          {passwordResetError}
        </Fieldset>
      </form>
    </FormProvider>
  )
}

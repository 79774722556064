import { Prism } from "monocle-ts"

export enum CalendarCoachTaskType {
  eventConfirmation = "eventConfirmation",
}
export const _eventConfirmation = Prism.fromPredicate(
  (v: CalendarCoachTaskType): v is CalendarCoachTaskType.eventConfirmation =>
    v === CalendarCoachTaskType.eventConfirmation,
)

export enum UserCoachTaskType {
  sex = "sex",
  age = "age",
  mass = "mass",
  height = "height",
  bodyType = "bodyType",
}
export const _sex = Prism.fromPredicate(
  (v: UserCoachTaskType): v is UserCoachTaskType.sex =>
    v === UserCoachTaskType.sex,
)
export const _age = Prism.fromPredicate(
  (v: UserCoachTaskType): v is UserCoachTaskType.age =>
    v === UserCoachTaskType.age,
)
export const _weight = Prism.fromPredicate(
  (v: UserCoachTaskType): v is UserCoachTaskType.mass =>
    v === UserCoachTaskType.mass,
)
export const _height = Prism.fromPredicate(
  (v: UserCoachTaskType): v is UserCoachTaskType.height =>
    v === UserCoachTaskType.height,
)
export const _bodyType = Prism.fromPredicate(
  (v: UserCoachTaskType): v is UserCoachTaskType.bodyType =>
    v === UserCoachTaskType.bodyType,
)

export type CoachTaskType = CalendarCoachTaskType | UserCoachTaskType
export const _CalendarCoachTaskType = Prism.fromPredicate(
  (v: CoachTaskType): v is CalendarCoachTaskType =>
    v === CalendarCoachTaskType.eventConfirmation,
)
export const _UserCoachTaskType = Prism.fromPredicate(
  (v: CoachTaskType): v is UserCoachTaskType =>
    v !== CalendarCoachTaskType.eventConfirmation,
)

// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file gateway/v1/workout_videos.proto (package gateway.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ListVideosRequest } from "../../video/v1/workout_videos_pb.js";
import { ListVideosResponse } from "./workout_videos_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service gateway.v1.WorkoutVideos
 */
export const WorkoutVideos = {
  typeName: "gateway.v1.WorkoutVideos",
  methods: {
    /**
     * Combines video.v1.ListVideos & activity.v1.ListActivities.
     *
     * @generated from rpc gateway.v1.WorkoutVideos.ListVideos
     */
    listVideos: {
      name: "ListVideos",
      I: ListVideosRequest,
      O: ListVideosResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;


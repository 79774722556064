import * as Eq from "fp-ts/es6/Eq"
import * as Str from "fp-ts/es6/string"
import { emailAddress } from "@fitnesspilot/data-common"

import { UserAuth } from "./UserAuth"
import { UserPassword } from "./UserPassword"

export type UserLogin = UserAuth & UserPassword
export const userLogin = Eq.struct<UserLogin>({
  email: emailAddress,
  password: Str.Eq,
})
export { _email } from "./UserAuth"
export { _password } from "./UserPassword"

import { FC, MouseEventHandler, ReactNode, useEffect } from "react"
import { FaListOl } from "react-icons/fa"
import { FormattedMessage, useIntl } from "react-intl"
import { TooltipRenderProps } from "react-joyride"
import { connect, ConnectedProps } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { Button } from "reactstrap"
import styled from "@emotion/styled"

import { flow, pipe } from "fp-ts/es6/function"
import * as Func from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as Rec from "fp-ts/es6/ReadonlyRecord"

import { BootstrapTheme } from "@fitnesspilot/components-common/dist/atoms/BootstrapTheme/BootstrapTheme"
import {
  Action,
  gotoStep,
  gotoStepSet,
  hideIndex,
  ParentState,
  selectors,
  showIndex,
  Step,
  StepSet,
  stepSetPaths,
  stepSetsToArray,
} from "@fitnesspilot/data-help"

import { stepSets } from "../../help"
import { TooltipBody } from "../../molecules/TooltipBody/TooltipBody"
import { TooltipContent } from "../../molecules/TooltipContent/TooltipContent"
import { TooltipFooter } from "../../molecules/TooltipFooter/TooltipFooter"
import { TooltipTitle } from "../../molecules/TooltipTitle/TooltipTitle"

import { Dispatch } from "redux"

const ButtonRow = styled.div`
  display: flex;
  gap: 20px;
`

const ButtonContinue = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`

const StyledTooltipTitle = styled(TooltipTitle)`
  display: flex;
  gap: 10px;
  position: relative;
  padding: 0.75rem 1.25rem;
`
const IndexTitle = styled(StyledTooltipTitle)`
  display: block;
  text-align: center;
`

const StyledTooltipBody = styled(TooltipBody)`
  max-width: 600px;
`

const Close = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 1.25rem;
  bottom: 0.75rem;
`

const mapState = (state: ParentState) => ({
  isOnIndex: pipe(state, selectors.state.composeLens(selectors.showIndex).get),
})

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ =
    (act: Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, Func.constVoid)

  return {
    showIndex: pipe(showIndex(), dispatch_),
    hideIndex: pipe(hideIndex(), dispatch_),
    gotoStep: flow(gotoStep, dispatch_),
    gotoStepSet: flow(gotoStepSet, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type TooltipContainerProps = TooltipRenderProps & {
  step: TooltipRenderProps["step"] & {
    indexInSet: number
    continueOnClick?: true
    set: StepSet
    nextSet: Opt.Option<StepSet>
    link?: Step["link"]
    showNext?: boolean
    nextText?: ReactNode
  }
}

export type TooltipProps = PropsFromRedux & TooltipContainerProps

const preventDefault =
  (a: IO.IO<void>): MouseEventHandler<HTMLElement> =>
  (e) => {
    e.preventDefault()
    a()
  }

const IndexTooltipContent = styled(TooltipContent)`
  max-height: min(90vh, 400px);
  overflow-y: auto;
`

export const Tooltip: FC<TooltipProps> = ({
  isOnIndex,
  showIndex,
  hideIndex,
  gotoStep,
  gotoStepSet,
  continuous,
  index,
  size,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
}) => {
  const intl = useIntl()

  const navigate = useNavigate()

  const { continueOnClick = false } = step

  useEffect(() => {
    const listener: MouseEventHandler<HTMLElement> = (evt) => {
      const target = evt.target as Element

      if (
        continueOnClick &&
        (typeof step.target === "string"
          ? target.matches(`${step.target}, ${step.target} *`)
          : target === step.target || target.contains(step.target))
      ) {
        // `setImmediate` mostly works, but sometimes reflows cause the tooltip
        // of the next step to be positioned incorrectly
        setTimeout(() => {
          primaryProps.onClick(evt)
        }, 500)
      }
    }

    document.body.addEventListener(
      "click",
      listener as any as (
        this: HTMLElement,
        ev: MouseEvent,
      ) => React.MouseEvent<HTMLElement>,
    )

    return () => {
      document.body.removeEventListener(
        "click",
        listener as any as (
          this: HTMLElement,
          ev: MouseEvent,
        ) => React.MouseEvent<HTMLElement>,
      )
    }
  }, [step.target, continueOnClick, primaryProps.onClick])

  const { showNext: showNextSet = true } = step.set
  const { showNext = showNextSet } = step

  return (
    <BootstrapTheme>
      {isOnIndex ? (
        <StyledTooltipBody {...tooltipProps}>
          <IndexTitle>
            <FormattedMessage defaultMessage="Index" />

            <Close>
              <button type="button" className="close" {...closeProps}>
                <span aria-hidden="true">×</span>
              </button>
            </Close>
          </IndexTitle>
          <IndexTooltipContent>
            <ol>
              {pipe(
                stepSets,
                Rec.mapWithIndex((k, set) => (
                  <li key={k}>
                    <Button
                      color="link"
                      type="button"
                      onClick={pipe(
                        () => navigate(step.set.href),
                        IO.apSecond(gotoStepSet(k)),
                        IO.apFirst(hideIndex),
                        preventDefault,
                      )}
                    >
                      {set.title}
                    </Button>

                    <ol>
                      {pipe(
                        set.steps,
                        Arr.mapWithIndex((i, step) => (
                          <li key={i}>
                            <Button
                              color="link"
                              type="button"
                              onClick={pipe(
                                step.availableOn
                                  ? () =>
                                      navigate(
                                        (step.availableOn as Array<string>)[0],
                                      )
                                  : () => navigate(set.href),
                                IO.apSecond(gotoStep({ set: k, step: i })),
                                IO.apFirst(hideIndex),
                                preventDefault,
                              )}
                            >
                              {step.title}
                            </Button>
                          </li>
                        )),
                      )}
                    </ol>
                  </li>
                )),
                stepSetsToArray,
              )}
            </ol>
          </IndexTooltipContent>
          <TooltipFooter>
            <Button
              type="button"
              color="link"
              onClick={preventDefault(hideIndex)}
            >
              <FormattedMessage defaultMessage="Back" />
            </Button>
          </TooltipFooter>
        </StyledTooltipBody>
      ) : (
        <StyledTooltipBody {...tooltipProps}>
          {step.title && (
            <StyledTooltipTitle>
              <button
                type="button"
                onClick={showIndex}
                style={{ all: "unset", cursor: "pointer" }}
              >
                <FaListOl />
              </button>
              {step.title}
              <Close>
                <button type="button" className="close" {...closeProps}>
                  <span aria-hidden="true">×</span>
                </button>
              </Close>
            </StyledTooltipTitle>
          )}
          <TooltipContent>{step.content}</TooltipContent>
          <TooltipFooter>
            <ButtonRow>
              {index > 0 && (
                <Button color="link" type="button" {...backProps}>
                  <FormattedMessage defaultMessage="Back" />
                </Button>
              )}
              {step.link && (
                <Button color="primary" as={Link} to={step.link.to}>
                  {step.link?.text ?? null}
                </Button>
              )}
              {continuous &&
                (index === size - 1
                  ? null
                  : pipe(
                      step.nextSet,
                      Opt.fold(
                        () => null,
                        (ss) => (
                          <ButtonContinue>
                            <Button
                              color="link"
                              type="button"
                              onClick={gotoStepSet(ss.id)}
                            >
                              {step.indexInSet === step.set.steps.length - 1 ? (
                                <FormattedMessage
                                  defaultMessage="Go to {title}"
                                  values={{ title: ss.title }}
                                />
                              ) : (
                                <FormattedMessage
                                  defaultMessage="Skip to {title}"
                                  values={{ title: ss.title }}
                                />
                              )}
                            </Button>
                            {showNext &&
                              showNextSet &&
                              step.indexInSet !== step.set.steps.length - 1 && (
                                <Button
                                  color="primary"
                                  type="button"
                                  {...primaryProps}
                                >
                                  {step.nextText ?? (
                                    <FormattedMessage defaultMessage="Next" />
                                  )}
                                </Button>
                              )}
                          </ButtonContinue>
                        ),
                      ),
                    ))}
            </ButtonRow>
          </TooltipFooter>
        </StyledTooltipBody>
      )}
    </BootstrapTheme>
  )
}

export const TooltipContainer = connector(Tooltip)

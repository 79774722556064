import { FC, ReactNode } from "react"
import { css, Global, ThemeProvider as ThemeProvider_ } from "@emotion/react"

import { pipe } from "fp-ts/es6/function"
import * as Opt from "fp-ts/es6/Option"
import * as Rec from "fp-ts/es6/ReadonlyRecord"

import colours, { schemeColours } from "./colours"
import pRegular from "./p_regular.woff2"
import { BaseFont, ColourScheme, prefersColourScheme, Theme } from "./Theme"

export { ColourScheme, Theme }

export type ThemeProviderProps = {
  mode: Theme["mode"]
  children: ReactNode
}

const PRegular = () => (
  <Global
    styles={css`
      @font-face {
        font-family: p_regular;
        src: url(${pRegular}) format("woff2");
        font-weight: 400;
        font-style: normal;
      }
    `}
  />
)

const Joyride = () => (
  <Global
    styles={css`
      .react-joyride__overlay,
      .__floater,
      .react-joyride__beacon {
        z-index: 1100 !important;
      }
    `}
  />
)

export const ThemeProvider: FC<ThemeProviderProps> = ({
  mode: mode_,
  children,
  ...props
}) => {
  const mode = Opt.alt(prefersColourScheme)(mode_)

  const baseFont: BaseFont = {
    families: {
      monospace: `SFMono-Regular, Menlo, Monaco, Consolas,
        "Liberation Mono", "Courier New", monospace`,
      sansSerif: `"p_regular", -apple-system, BlinkMacSystemFont,
        "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
        "Apple Colour Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Colour Emoji"`,
    },
    heights: {
      base: "1.5",
    },
    sizes: {
      base: "1rem",
      lg: "1.25rem", // base * 1.25
      sm: "0.875rem", // base * 0.875
    },
    weights: {
      bold: "600",
      bolder: "bolder",
      light: "300",
      lighter: "lighter",
      normal: "400",
    },
  }
  const font: Theme["font"] = {
    ...baseFont,
    families: { ...baseFont.families, base: baseFont.families.sansSerif },
    weights: { ...baseFont.weights, base: baseFont.weights.normal },
  }
  const breakpoints: Theme["breakpoints"] = {
    sm: "min-width: 576px",
    md: "min-width: 768px",
    lg: "min-width: 992px",
    xl: "min-width: 1200px",
  }
  const media: Theme["media"] = Rec.map((m: string) => `(${m})`)(breakpoints)

  const theme: Theme = {
    colours: pipe(
      mode,
      Opt.fold(() => colours, schemeColours),
    ),
    font,
    mode,
    breakpoints,
    media,
  }

  return (
    <ThemeProvider_ {...props} {...{ theme }}>
      <PRegular />
      <Joyride />
      {children}
    </ThemeProvider_>
  )
}

import { FormattedMessage } from "react-intl"

import * as Opt from "fp-ts/es6/Option"

import { KnownStepSet, StepSet } from "@fitnesspilot/data-help"

export const aboutMe: StepSet = {
  id: KnownStepSet.aboutMe,
  title: <FormattedMessage defaultMessage="About Me" />,
  steps: [
    {
      target: '[data-help-mode="mainNav"] [data-help-mode="aboutMeLink"]',
      continueOnClick: true,
      title: <FormattedMessage defaultMessage="About Me" />,
      content: (
        <>
          <p>
            <FormattedMessage defaultMessage="The about me section allows you to change the data you've given during registration and gives you an overview over your photos." />
          </p>

          <p>
            <FormattedMessage defaultMessage="Now please click here to continue to about me." />
          </p>
        </>
      ),
      showNext: false,
    },
  ],
  startOn: Opt.none,
  href: "/aboutMe",
}

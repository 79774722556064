export enum Ateroposterior {
  anterior = "anterior",
  posterior = "posterior",
}

export enum Craniocaudal {
  caudal = "caudal",
  cranial = "cranial",
}

export enum DextroSinister {
  dexter = "dexter",
  sinister = "sinister",
}

export enum Dorsoventral {
  dorsal = "dorsal",
  ventral = "ventral",
}

export enum Mediolateral {
  lateral = "lateral",
  medial = "medial",
}

export enum ProximalDistal {
  distal = "distal",
  proximal = "proximal",
}

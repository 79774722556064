import { flow, pipe } from "fp-ts/es6/function";
import * as Opt from "fp-ts/es6/Option";
import { Lens, Prism } from "monocle-ts";
import { ioTsPrism } from "../internal";
import { Temporal } from "@js-temporal/polyfill";
import * as t from "io-ts";
export const day = {
    equals: (a, b) => a.equals(b),
    compare: (a, b) => Temporal.PlainDate.compare(a, b),
    empty: 0,
    concat: (a, b) => a + b,
    inverse: (a) => -a,
    add: (day, days) => day.add({ days }),
    difference: (a, b) => pipe(a.until(b, { largestUnit: "days" }), ({ days }) => days),
};
export const equals = (d2) => (d) => day.equals(d, d2);
export const compare = (d2) => (d) => day.compare(d, d2);
export const dayOfTime = new Lens((s) => s.toZonedDateTimeISO("UTC").toPlainDate(), (a) => (s) => s.toZonedDateTimeISO("UTC").withPlainDate(a).toInstant());
export const today = () => Temporal.Now.plainDateISO();
export const mkDay = (year, month, day) => Opt.tryCatch(() => Temporal.PlainDate.from({ year, month, day }, { overflow: "reject" }));
const regex = /^-?\d{4,}-\d{2}-\d{2}$/;
export const isoStringAsDay = new Prism(flow(Opt.fromPredicate((s) => regex.test(s)), Opt.chain((s) => Opt.tryCatch(() => Temporal.PlainDate.from(s, { overflow: "reject" })))), (s) => s.toString({ calendarName: "never" }));
export const dayT = t.string.pipe(ioTsPrism("Day", (a) => a instanceof Temporal.PlainDate, isoStringAsDay));

import {
  Modal as Modal_,
  ModalBody,
  ModalProps as ModalProps_,
} from "reactstrap"
import styled from "@emotion/styled"

import { OmitFromKnownKeys } from "@fitnesspilot/data-common"

export type ModalProps = OmitFromKnownKeys<ModalProps_, "container">

export const Modal = styled((props: ModalProps) => (
  <Modal_ {...props} container="#root #modal-container" />
))`
  &.modal-xl {
    @media not screen and (min-width: 1200px) {
      min-width: 75vw;
    }
  }

  & .modal-body {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 12rem);
    overflow-y: auto;
    overflow-x: hidden;
  }
`

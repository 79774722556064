import { createRef, RefObject, useEffect, useMemo, useRef } from "react"
import { FormattedMessage } from "react-intl"
import styled from "@emotion/styled"

import { pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"
import * as Ord from "fp-ts/es6/Ord"
import * as Arr from "fp-ts/es6/ReadonlyArray"
import * as NonEmpty from "fp-ts/es6/ReadonlyNonEmptyArray"
import { now, time } from "time-ts/es6"

import { Fieldset } from "@fitnesspilot/components-common/dist/atoms/Fieldset/Fieldset"
import { EventWithId } from "@fitnesspilot/data-event/dist/calendar/Event"

import { EventCard } from "../../molecules/EventCard/EventCard"

const EventTimeline = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
`

const EventContainer = styled.div`
  margin: 10px;
`

type EventProps = {
  event: EventWithId
  ref?: RefObject<HTMLDivElement>
}

const Event = ({ event, ref }: EventProps) => (
  <EventContainer {...{ ref }}>
    <EventCard {...{ event }} activityLimit={2} showTime />
  </EventContainer>
)

const NowIndicator = styled.div`
  min-width: 2px;
  height: auto;
  margin: 10px 0;
  background-color: ${({ theme }) => theme.colours.primary.toString()};
`

export type SportStatisticsProps = {
  events: ReadonlyArray<EventWithId>
  onView: IO.IO<void>
}

export const SportStatistics = ({ events, onView }: SportStatisticsProps) => {
  const timelineRef = useRef<HTMLDivElement>(null)
  const nowIndicatorRef = useRef<HTMLDivElement>(null)

  useEffect(() => onView?.(), [])

  const futureIndex = useMemo(
    () =>
      pipe(
        events,
        Arr.sort(
          pipe(
            time,
            Ord.contramap((event: EventWithId) => event.value.between[1]),
          ),
        ),
        () =>
          pipe(
            events,
            Arr.findIndex((e) => Ord.gt(time)(e.value.between[1], now())),
          ),
      ),
    [events],
  )

  useEffect(() => {
    const timelineEl = timelineRef.current
    const nowIndicatorEl = nowIndicatorRef.current
    if (timelineEl && nowIndicatorEl) {
      timelineEl.scrollTo({
        left:
          nowIndicatorEl.offsetLeft -
          timelineEl.offsetLeft -
          timelineEl.clientWidth / 2,
      })
    }
  }, [events, timelineRef, nowIndicatorRef])

  return (
    <>
      <Fieldset>
        <h2>
          <FormattedMessage defaultMessage="Event timeline:" />
        </h2>
        <EventTimeline ref={timelineRef}>
          {pipe(
            futureIndex,
            Opt.fold(
              () => [events, []] as const,
              (index) => pipe(events, Arr.splitAt(index)),
            ),
            ([left, right]) => (
              <>
                {pipe(
                  left,
                  Arr.mapWithIndex((i, event) => (
                    <Event key={i} {...{ event }} />
                  )),
                )}
                <NowIndicator ref={nowIndicatorRef} />
                {pipe(
                  right,
                  NonEmpty.fromReadonlyArray,
                  Opt.fold(
                    () => null,
                    (right) =>
                      pipe(
                        right,
                        NonEmpty.mapWithIndex((i, event) => (
                          <Event key={i} {...{ event }} />
                        )),
                      ),
                  ),
                )}
              </>
            ),
          )}
        </EventTimeline>
      </Fieldset>

      <Fieldset>
        <FormattedMessage defaultMessage="We'll give you insightful statistics about your activities soon. Keep an eye on this space 😉" />
      </Fieldset>
    </>
  )
}

import { ComponentProps, FC, Fragment } from "react"
import { ControllerRenderProps } from "react-hook-form"
import { FormattedMessage } from "react-intl"
import Media from "react-media"
import { connect, ConnectedProps } from "react-redux"
import {
  Button,
  Col,
  ColProps,
  Input,
  InputGroup,
  InputGroupAddon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import styled from "@emotion/styled"

import * as Func from "fp-ts/es6/function"
import { flow, pipe } from "fp-ts/es6/function"
import * as IO from "fp-ts/es6/IO"
import * as Opt from "fp-ts/es6/Option"

import { FormGroup } from "@fitnesspilot/components-common/dist/molecules/FormGroup/FormGroup"
import { FieldRenderer } from "@fitnesspilot/components-common/dist/organisms/Field/Controller"
import { Modal } from "@fitnesspilot/components-common/dist/organisms/Modal/Modal"
import {
  BodyCompositionParts,
  fitnessLevelToBodyComposition,
  SumBodyComposition,
  SumBodyCompositionTypes,
} from "@fitnesspilot/data-human-body/dist/bodyComposition"
import {
  CombinedFitnessLevel,
  CombinedFitnessLevelParts,
  FitnessLevel,
} from "@fitnesspilot/data-human-body/dist/fitnessLevel"
import {
  Action,
  ParentState,
  selectFitnessLevel,
  selectors,
} from "@fitnesspilot/data-user"

import { BodyCompositionPartLabel } from "../../atoms/BodyCompositionPartLabel/BodyCompositionPartLabel"
import { FitnessLevelButtonGrid } from "../../molecules/FitnessLevelButtonGrid/FitnessLevelButtonGrid"
import { FitnessLevelRange } from "../../molecules/FitnessLevelRange/FitnessLevelRange"
import { FitnessLevelWithImage } from "../../molecules/FitnessLevelWithImage/FitnessLevelWithImage"

import { Dispatch } from "redux"

const fitnessLevelToSumBodyComposition = (
  fitnessLevel: CombinedFitnessLevel,
): SumBodyComposition => ({
  ...fitnessLevelToBodyComposition(fitnessLevel),
  type: SumBodyCompositionTypes.fitnessLevel,
  fitnessLevel,
})

const FitnessLevelButtonCol = styled(
  ({
    hasSelectedLevel: _hasSelectedLevel,
    ...props
  }: ColProps & { hasSelectedLevel: boolean }) => <Col {...props} />,
)`
  display: flex;
  ${({ hasSelectedLevel }) =>
    !hasSelectedLevel &&
    `
    align-items: center;
  `}
`

const FitnessLevelButton = styled(Button)`
  max-width: 260px;
  margin-bottom: 1rem;
`

const mapState = (state: ParentState) => ({
  selected: selectors.state.compose(selectors.fitnessLevel).get(state).selected,
})

const mapDispatch = (dispatch: Dispatch<Action>) => {
  const dispatch_ =
    (act: Action): IO.IO<void> =>
    () =>
      pipe(act, dispatch, Func.constVoid)

  return {
    onSelect: flow(selectFitnessLevel, dispatch_),
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type BaseBodyCompositionInputProps = {
  id: string
}

export type BodyCompositionInputProps = BaseBodyCompositionInputProps & {
  field: Omit<ControllerRenderProps<any>, "value" | "onBlur" | "onChange"> & {
    value: Opt.Option<SumBodyComposition>
    onBlur: (value: Opt.Option<SumBodyComposition>) => void
    onChange: (value: Opt.Option<SumBodyComposition>) => void
  }
  onModalToggle: (v: boolean) => IO.IO<void>
  modalIsOpen: boolean
} & PropsFromRedux

export const BodyCompositionInput: FC<BodyCompositionInputProps> = ({
  field: { value = Opt.none, onChange, onBlur },
  id,
  onModalToggle,
  onSelect,
  modalIsOpen,
  selected = value,
  ...props
}) => {
  const valueD = pipe(
    value,
    Opt.getOrElse<SumBodyComposition>(() => ({
      type: SumBodyCompositionTypes.relative,
    })),
  )
  const selectedU = Opt.toUndefined(selected)

  return (
    <Row>
      <Col xs={12}>
        {Object.values(BodyCompositionParts).map((key) => (
          <FormGroup
            inputId={`${id}-${key}`}
            key={key}
            label={<BodyCompositionPartLabel bodyCompositionPart={key} />}
          >
            <InputGroup>
              <Input
                id={`${id}-${key}`}
                type="number"
                min={0}
                max={100}
                value={pipe(
                  valueD[key],
                  Opt.fromNullable,
                  Opt.map((v) => (v * 100).toFixed(0)),
                  Opt.toUndefined,
                )}
                onChange={(e) =>
                  pipe(
                    (valueD.type === SumBodyCompositionTypes.fitnessLevel
                      ? {
                          ...valueD,
                          type: SumBodyCompositionTypes.relative,
                          fitnessLevel: undefined,
                          [key]:
                            e.target.value === ""
                              ? null
                              : Number(e.target.value) / 100,
                        }
                      : {
                          ...valueD,
                          [key]:
                            e.target.value === ""
                              ? null
                              : Number(e.target.value) / 100,
                        }) as SumBodyComposition,
                    Opt.some,
                    onChange,
                  )
                }
                onBlur={() => onBlur(value)}
                {...props}
              />
              <InputGroupAddon addonType="append">
                {valueD.type === SumBodyCompositionTypes.absolute ? "kg" : "%"}
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        ))}
      </Col>

      <FitnessLevelButtonCol
        xs={7}
        hasSelectedLevel={valueD.type === SumBodyCompositionTypes.fitnessLevel}
      >
        <FitnessLevelButton color="primary" onClick={onModalToggle(true)}>
          <FormattedMessage defaultMessage="Select preset" />
        </FitnessLevelButton>
      </FitnessLevelButtonCol>

      <Modal
        size="xl"
        toggle={onModalToggle(!modalIsOpen)}
        isOpen={modalIsOpen}
      >
        <ModalHeader toggle={onModalToggle(!modalIsOpen)}>
          <FormattedMessage defaultMessage="Body Shape Presets" />
        </ModalHeader>

        <Media queries={{ small: { maxWidth: 749 } }}>
          {
            ((matches: { small: boolean }) =>
              matches.small ? (
                <Fragment>
                  <ModalBody>
                    <FitnessLevelRange
                      id={id}
                      value={
                        selectedU?.type === SumBodyCompositionTypes.fitnessLevel
                          ? selectedU.fitnessLevel
                          : undefined
                      }
                      onChange={(v) =>
                        pipe(v, fitnessLevelToSumBodyComposition, onSelect)()
                      }
                    />

                    <FitnessLevelWithImage
                      autoHeight
                      fitnessLevel={
                        selectedU?.type === SumBodyCompositionTypes.fitnessLevel
                          ? selectedU.fitnessLevel
                          : {
                              [CombinedFitnessLevelParts.bodyFat]:
                                FitnessLevel.medium,
                              [CombinedFitnessLevelParts.musculature]:
                                FitnessLevel.medium,
                            }
                      }
                    />
                  </ModalBody>

                  <ModalFooter>
                    <Button color="primary" onClick={() => onChange(selected)}>
                      <FormattedMessage defaultMessage="Save" />
                    </Button>
                  </ModalFooter>
                </Fragment>
              ) : (
                <ModalBody>
                  <FitnessLevelButtonGrid
                    onChange={flow(
                      fitnessLevelToSumBodyComposition,
                      Opt.some,
                      onChange,
                    )}
                  />
                </ModalBody>
              )) as any
          }
        </Media>
      </Modal>
    </Row>
  )
}

const InnerBodyCompositionInputContainer = connector(BodyCompositionInput)

type RenderProps = Omit<
  ComponentProps<typeof InnerBodyCompositionInputContainer>,
  "field"
>

export const renderBodyComposition: FieldRenderer<
  Opt.Option<SumBodyComposition>,
  RenderProps
> =
  ({ ...props }) =>
  // @TODO I'd like to not disable this, but setting the display name breaks type inference
  // eslint-disable-next-line react/display-name
  ({ field }) => (
    <InnerBodyCompositionInputContainer {...{ field }} {...props} />
  )
